export const formatChange = (startTime) => {
  var time = null;
  if (!containsColon(startTime)) {
    time = formatTimeTo24Hour(startTime);
  } else {
    time = startTime;
  }
  const strt = formatTimeTo24Hour(startTime);
  const formatedTime = formatTime(strt);
  const timeValidate = validateTime(formatedTime);
  return timeValidate;
};

const validateTime = (time) => {
  const regex = /^([01]\d|2[0-3]):([0-5]\d)$/;
  return regex.test(time);
};

const formatTime = (time) => {
  let [hours, minutes] = time.split(":");
  hours = hours.padStart(2, "0");
  minutes = minutes.padStart(2, "0");
  return `${hours}:${minutes}`;
};

const containsColon = (input) => {
  return input.includes(":");
};

// export const formatTimeTo24Hr = (input) => {
//     let hours = input.toString().padStart(2, '0');
//     let formattedTime = `${hours}:00`;
//     return formattedTime;
// }

export const formatTimeTo24Hr = (input) => {
  let hours, minutes;
  if (!input.includes(":")) {
    hours = input.slice(0, -2).padStart(2, "0");
    minutes = input.slice(-2).padStart(2, "0");
  } else {
    [hours, minutes] = input.split(":");
    hours = hours.padStart(2, "0");
    minutes = minutes ? minutes.padStart(2, "0") : "00";
  }
  return `${hours}:${minutes}`;
};

// const formatTimeTo24Hour = (input) => {
//     let hours = input.toString().padStart(2, '0');
//     let formattedTime = `${hours}:00`;
//     return formattedTime;
// }
const formatTimeTo24Hour = (input) => {
  const hours = input.slice(0, -2).padStart(2, "0");
  const minutes = input.slice(-2).padStart(2, "0");
  return `${hours}:${minutes}`;
};
export const formatChange2 = (startTime) => {
  return startTime;
};

// export const convertTo24HourFormat = (time) => {
//     // Split the input time into hours and minutes
//     let [hours, minutes] = time.split(':');

//     // Pad the hours and minutes with leading zeros if necessary
//     hours = hours.padStart(2, '0');
//     minutes = minutes ? minutes.padStart(2, '0') : '00';

//     // Return the formatted time
//     return `${hours}:${minutes}`;
// }

export const convertTo24HourFormat = (time) => {
  let hours, minutes;
  if (!time.includes(":")) {
    hours = time.slice(0, -2).padStart(2, "0");
    minutes = time.slice(-2).padStart(2, "0");
  } else {
    [hours, minutes] = time.split(":");
    hours = hours.padStart(2, "0");
    minutes = minutes ? minutes.padStart(2, "0") : "00";
  }

  return `${hours}:${minutes}`;
};

export const convertTo12HourFormat = (time) => {
  let hours, minutes;
  if (!time.includes(":")) {
    // Ensure single-digit hours are prefixed with '0' and add ':00'
    hours = time.slice(0, -2).padStart(2, "0");
    minutes = time.slice(-2).padStart(2, "0");
    time = `${hours}:${minutes}`;
  }
  //   [hours, minutes] = time.split(":");
  //   hours = hours.padStart(2, "0");
  //   minutes = minutes ? minutes.padStart(2, "0") : "00";
  //   time = `${hours}:${minutes}`;
  //   }

  [hours, minutes] = time.split(":");
  hours = parseInt(hours);
  let period = "AM";

  if (hours >= 12) {
    period = "PM";
    if (hours > 12) {
      hours -= 12;
    }
  } else if (hours === 0) {
    hours = 12;
  }

  // Ensure hours and minutes are two digits
  hours = hours.toString().padStart(2, "0");
  minutes = minutes.padStart(2, "0");

  return `${hours}:${minutes} ${period}`;
};

export const convertTo24HourFormatback = (time) => {
  let [timePart, period] = time.split(" ");
  let [hours, minutes] = timePart.split(":");
  hours = parseInt(hours);

  if (period === "PM" && hours !== 12) {
    hours += 12;
  } else if (period === "AM" && hours === 12) {
    hours = 0;
  }

  // Ensure hours and minutes are two digits
  hours = hours.toString().padStart(2, "0");
  minutes = minutes.padStart(2, "0");

  return `${hours}:${minutes}`;
};

export const convertToMinutes = (time) => {
  const [hours, minutes] = time.split(":").map(Number);
  return hours * 60 + minutes;
};

export const isEndTimeGreater = (startTime, endTime) => {
  const startMinutes = convertToMinutes(startTime);
  const endMinutes = convertToMinutes(endTime);
  return endMinutes > startMinutes;
};

export const formatTimeTo24HourCurrect = (time) => {
  const timePattern = /^([01]?\d|2[0-3]):?([0-5]?\d)?$/;

  if (timePattern.test(time)) {
    let [hours, minutes] = time.split(":");
    hours = hours.padStart(2, "0");
    minutes = (minutes || "00").padStart(2, "0");
    return `${hours}:${minutes}`;
  }

  throw new Error("Invalid time format");
};

export const addOneHourOn24Hourformat = (time) => {
  // Split the time into hours and minutes
  let [hours, minutes] = time.split(":").map(Number);

  // Add one hour
  hours += 1;

  // Handle the overflow of hours (24-hour format)
  if (hours >= 24) {
    hours -= 24;
  }

  // Format the hours and minutes back to "HH:MM"
  hours = hours.toString().padStart(2, "0");
  minutes = minutes.toString().padStart(2, "0");

  return `${hours}:${minutes}`;
};

export const convertTo12 = (time) => {
  let [hours, minutes] = time.split(":");
  hours = parseInt(hours);
  let period = "AM";

  if (hours >= 12) {
    period = "PM";
    if (hours > 12) {
      hours -= 12;
    }
  } else if (hours === 0) {
    hours = 12;
  }

  return `${hours}:${minutes} ${period}`;
};

export const isLessThan24 = (inputTime) => {
  // Split inputTime by ":"
  let timeParts = inputTime.split(":");

  // If only one part is provided, it represents the hour
  let hour, minute;
  if (timeParts.length === 1) {
    hour = parseInt(timeParts[0]);
    minute = 0; // Default minute value if not provided
  } else if (timeParts.length === 2) {
    hour = parseInt(timeParts[0]);
    minute = parseInt(timeParts[1]);
  } else {
    return false; // Invalid input format
  }

  // Check if hour and minute are within valid ranges
  if (hour >= 0 && hour < 24 && minute >= 0 && minute < 60) {
    return true;
  } else {
    return false;
  }
};

export const isValidTimeFormat = (inputTime) => {
  // Regular expression to match the format HH:MM
  let regex = /^([01]\d|2[0-3]):([0-5]\d)$/;

  // Test the input against the regular expression
  return regex.test(inputTime);
};

export const validateInput = (inputStr) => {
  var pattern = /^[0-9:]+$/;
  return pattern.test(inputStr);
};

export const convertTo24Hour = (time) => {
    if (!time) {
      return time;
    }
  
    // Extract the time and period (AM/PM)
    const parts = time.split(" ");
    if (parts.length < 2) {
      return time;
    }
  
    const [timePart, modifier] = parts;
  
    if (!timePart || !modifier) {
      return time;
    }
  
    // Split the time into hours and minutes
    let [hours, minutes] = timePart.split(":");
  
    if (!hours || !minutes) {
      return time;
    }
  
    // Convert hours to number
    hours = parseInt(hours, 10);
  
    // Convert the modifier to uppercase for consistent comparison
    const upperModifier = modifier.toUpperCase();
  
    // Convert the hours to 24-hour format
    if (upperModifier === "PM" && hours !== 12) {
      hours += 12;
    }
    if (upperModifier === "AM" && hours === 12) {
      hours = 0;
    }
  
    // Format hours and minutes to ensure two digits
    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes.length < 2 ? "0" + minutes : minutes;
  
    return `${hours}:${minutes}`;
  };

  export const validateNoAlphabets=(str)=> {
    // Regular expression to match any alphabetic character (both lowercase and uppercase)
    const regex = /[a-zA-Z]/;
    // Test the string against the regular expression
    return !regex.test(str);
}
  
