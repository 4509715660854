import React from "react";
import VendorOverview from "../../components/Vendors/VendorsOverview";



function VendorOverviewSection()  {
    return(
        <div className="react-outer">
            <VendorOverview />
            
        </div>
    )
}

export default VendorOverviewSection;