import axios from 'axios';
import moment from 'moment';
import { useMemo } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useQuery } from 'react-query';


function ConvertHelper(currencyFrom, currencyTo, amount, forex) {

    const currencyFromRate = forex && forex[currencyFrom];
    const currencyToRate = forex && forex[currencyTo];
    const amountConvertedUSD = amount / currencyFromRate;
    const amountConvertTo = amountConvertedUSD * currencyToRate;
    return amountConvertTo;

}

export function useFetchClientDashboardCurrency(token) {
    return useQuery('dashboardCurrency', async () => {
        const response = await axios.get('/api/client/dashboard/invoice-currency', {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    });
}

export function useFetchClientInvoiceCount(token, startDate, endDate, currencySum) {
    return useQuery('invoiceCount', async () => {
        const response = await axios.post(
            '/api/client/invoices/by-date-range',
            { startDate, endDate },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        return response.data;

    });
}

export function useFetchClientOverdue(token, startDate, endDate, currency, currencySum, forex) {
    const queryResult = useQuery('overdue', async () => {
        const response = await axios.post(
            '/api/client/dashboard/overdue',
            { startDate, endDate },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        const valueArray = Object.entries(response.data).map(([currency, amount]) => {
            return { currency, amount };
        });

        return valueArray;
    });

    const { data: rawData = [], isLoading: rawDataLoading } = queryResult;

    const filteredData = useMemo(() => {
        if (currencySum === false) {
            return rawData.filter((item) => item.currency === currency);
        }
        var tot = 0;
        rawData.map((item) => {
            tot = tot + ConvertHelper(item.currency, currency, item.amount, forex);
        });
        const final = [{ currency: currency, amount: tot }];

        return final;
    }, [rawData, currency, currencySum]);

    return {
        data: filteredData,
        isLoading: rawDataLoading,
        refetch: queryResult.refetch, // Return the refetch function
    };
}

export function useFetchTotalClientPayments(token, startDate, endDate, currency, currencySum, defaultCurrency, forex) {
    const queryResult = useQuery('totalPayments', async () => {
        const response = await axios.post(
            '/api/client/dashboard/total-payments',
            { startDate, endDate },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        const valueArray = Object.entries(response.data).map(([currency, amount]) => {
            return { currency, amount };
        });

        return valueArray;
    });

    const { data: rawData = [], isLoading: rawDataLoading } = queryResult;

    const filteredData = useMemo(() => {
        if (currencySum === false) {
            return rawData.filter((item) => item.currency === currency);
        }
        var tot = 0;
        rawData.map((item) => {
            tot = tot + ConvertHelper(item.currency, currency, item.amount, forex);
        });

        const final = [{ currency: currency, amount: tot }];

        return final;
    }, [rawData, currency, currencySum]);

    return {
        data: filteredData,
        isLoading: rawDataLoading,
        refetch: queryResult.refetch, // Return the refetch function
    };
}

export function useFetchOutstandingClientAmount(token, startDate, endDate, currency, currencySum, forex) {
    const queryResult = useQuery('outstandingAmount', async () => {
        const response = await axios.post(
            '/api/client/dashboard/outstanding-amount',
            { startDate, endDate },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        const valueArray = Object.entries(response.data).map(([currency, amount]) => {
            return { currency, amount };
        });

        return valueArray;
    });

    const { data: rawData = [], isLoading: rawDataLoading } = queryResult;

    const filteredData = useMemo(() => {
        if (currencySum === false) {
            return rawData.filter((item) => item.currency === currency);
        }
        var tot = 0;
        rawData.map((item) => {
            tot = tot + ConvertHelper(item.currency, currency, item.amount, forex);
        });
        const final = [{ currency: 'INR', amount: tot }];

        return final;
    }, [rawData, currency, currencySum]);

    return {
        data: filteredData,
        isLoading: rawDataLoading,
        refetch: queryResult.refetch, // Return the refetch function
    };
}

export function useFetchAllClientInvoices(token, startDate, endDate, dashboradCurrency, currencySum) {
    return useQuery(
        ['allClientInvoices', startDate, endDate, dashboradCurrency, currencySum], // Include dependencies in the query key
        async () => {
            const response = await axios.post(
                '/api/client/invoices/recent',
                { startDate, endDate, numberOfInvoices: 5, dashboradCurrency, currencySum },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            return response.data;
        },
        {
            enabled: !!token && !!startDate && !!endDate, // Only run if token, startDate, and endDate are available
        }
    );
}

export function useFetchRecentClientInvoices(token, startDate, endDate, numberOfInvoices, dashboradCurrency, currencySum) {
    const queryResult = useFetchAllClientInvoices(token, startDate, endDate, dashboradCurrency, currencySum);

    const { data: allInvoicesData = [], isLoading: allInvoicesLoading } = queryResult;

    const filteredData = useMemo(() => {
        if (currencySum === false) {
            return allInvoicesData.filter(item => item.cli_inv_currency === dashboradCurrency).slice(0, numberOfInvoices);
        }
        return allInvoicesData;
    }, [allInvoicesData, dashboradCurrency, numberOfInvoices, currencySum]);

    return {
        data: filteredData,
        isLoading: allInvoicesLoading,
        refetch: queryResult.refetch,
    };
}

export function useFetchClientDashboardChartData(userToken, dashboardCurrency, startDate, endDate, currencySum) {
    return useQuery(
        ['dashboardChartData', dashboardCurrency, startDate, endDate, currencySum], // Add dependencies here
        async () => {
            const response = await axios.post(
                '/api/client/dashboard/chart',
                {
                    startDate,
                    endDate,
                    currency: dashboardCurrency,
                    currencySum: currencySum
                },
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            );
            return response.data;
        },
        {
            enabled: !!userToken && !!startDate && !!endDate,
        }
    );
}