import React, { useEffect, useState } from "react";
import "./CompanyDetaislForm.css";
import { useSelector, useDispatch } from 'react-redux';
import { setOnboardingPersonalFirst, setOnboardUserType } from "../../../features/onBoard/onBoardSlice.js";
import { useNavigate } from "react-router-dom";
import { useParams } from 'react-router-dom';

function CompanyDetailsForm({ onFormSubmit, usertype }) {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [commercialName, setCommercialName] = useState("");
  const [legalEntityName, setLegalEntityName] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [taxId, setTaxId] = useState("");


  const [firstName, setFirstName] = useState(null);
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastName, setLastName] = useState(null);
  const [lastNameError, setLastNameError] = useState(null);
  const [passportId, setPassportId] = useState(null);
  const [passportIdError, setPassportIdError] = useState(null);
  const [dob, setDob] = useState(null);
  const [dobError, setDobError] = useState(null);
  const [isSubmitLoading, setIsSubmitLoading] = useState(true);
  const [isSubmitLoadingError, setIsSubmitLoadingError] = useState(true);
  const [fData, setFData] = useState(null);
  const userType = useSelector((state) => state.onBoard.userType);
  const  uType  = useParams();
  // console.log(uType.usertype);

  useEffect(() => {

    if (!userType) {

    }

    if (usertype === "personal") {

      if (firstName && lastName && passportId && dob && email) {

        setIsSubmitLoading(false);

      } else {

        setIsSubmitLoading(true);
      }

    }


  }, [usertype, firstName, lastName, passportId, dob, email]);

  const handleSubmitPersonalFirst = (event) => {
    event.preventDefault();

    const formData = { firstName, lastName, email, passportId }
    dispatch(setOnboardingPersonalFirst(formData));

    // return;

    // const submittedData = {
    //   first_name:firstName
    // }

    // setFData('sdsdsdds');

    // setCommercialName("");
    // setLegalEntityName("");
    // setEmail("");
    // setTaxId("");
    // if (usertype === "personal") {

    //   if (!firstName) {
    //     setFirstNameError(true);
    //   }
    //   if (!lastName) {
    //     setLastNameError(true);
    //   }
    //   if (!passportId) {
    //     setPassportIdError(true);
    //   }
    //   if (!dob) {
    //     setDobError(true);
    //   }
    //   if (!email) {
    //     setEmailError(true);
    //   }



    // }
    // console.log('formData', fData);
    onFormSubmit(); // Call the function passed down from the parent
  };

  const handleFormSubmit = () => {



    if (usertype === "personal") {

      if (!firstName) {
        setFirstNameError(true);
      }
      if (!lastName) {
        setLastNameError(true);
      }
      if (!passportId) {
        setPassportIdError(true);
      }
      if (!dob) {
        setDobError(true);
      }
      if (!email) {
        setEmailError(true);
      }

      // console.log('firstNameError', firstNameError);

    }


  }

  return (
    <div className="onboarding-detail-outer">
      {usertype === "business" &&
        (<>
          <div className="company-detail-title">Company Details // {usertype}</div>
          <form onSubmit={handleSubmitPersonalFirst}>


            <div className="form-group">
              <label htmlFor="commercial-name">Commercial Name*</label>
              <input
                type="text"
                id="commercial-name"
                name="commercial-name"
                value={commercialName}
                onChange={(e) => setCommercialName(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="legal-entity-name">Legal Entity Name*</label>
              <input
                type="text"
                id="legal-entity-name"
                name="legal-entity-name"
                value={legalEntityName}
                onChange={(e) => setLegalEntityName(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email*</label>
              <input
                type="email"
                id="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="tax-id">Tax ID number</label>
              <input
                type="text"
                id="tax-id"
                name="tax-id"
                value={taxId}
                onChange={(e) => setTaxId(e.target.value)}
                
              />
            </div>


            <div className="continue-button">
              <button type="submit">Continue</button>
            </div>

          </form>
        </>)}

      {usertype === "personal" &&
        (<>
          <div className="company-detail-title">Your Profile // {usertype}</div>
          <form onSubmit={handleSubmitPersonalFirst}>

            <div className="form-group">
              <label htmlFor="commercial-name">First Name*</label>
              <input
                type="text"
                id="commercial-name"
                className={firstNameError === true && "error-input"}
                name="commercial-name"
                value={firstName}
                onChange={(e) => { setFirstName(e.target.value); setFirstNameError(false); }}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="legal-entity-name">Last Name*</label>
              <input
                type="text"
                id="legal-entity-name"
                name="legal-entity-name"
                className={lastNameError && "error-input"}
                value={lastName}
                onChange={(e) => { setLastName(e.target.value); setLastNameError(false); }}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email*</label>
              <input
                type="email"
                id="email"
                name="email"
                className={emailError && "error-input"}
                value={email}
                onChange={(e) => { setEmail(e.target.value); setEmailError(false); }}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Date of Birth*</label>
              <input
                type="date"
                id="email"
                name="email"
                className={dobError && "error-input"}
                value={dob}
                onChange={(e) => { setDob(e.target.value); setDobError(false); }}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="tax-id">Passport or ID Number*</label>
              <input
                type="text"
                id="tax-id"
                name="tax-id"
                className={passportIdError && "error-input"}
                value={passportId}
                onChange={(e) => { setPassportId(e.target.value); setPassportIdError(false); }}
                required
              />
            </div>

            <div className="continue-button">
              <button type="submit" onClick={handleFormSubmit} >Continue</button>
            </div>

          </form>
        </>)}


    </div>
  );
}

export default CompanyDetailsForm;
