import axios from 'axios';
import { useQuery, useMutation } from 'react-query';


export const useFetchCompanyProfiles = (token) => {
    return useQuery('companyProfiles', async () => {
        const response = await axios.get(`/api/company-profile`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    });
};

export const useFetchClientPayment = (token) => {
    return useQuery('clientPayments', async () => {
        const response = await axios.get(`/api/app-owner-bank/get`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data.data;
    });
};

export const useFetchWiseProfiles = (token) => {
    return useQuery('wiseProfiles', async () => {
        const response = await axios.get(`/api/wise/profile`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        const businessProfiles = response.data
            .flat()
            .filter(profile => profile.type === "BUSINESS");
        return businessProfiles;
    });
};