import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";


const initialState = {
        dateRange: null,
        comparedTo: null,
        frequency: null
};

export const setDateRange = createAsyncThunk(
        "filter/setDateRange",
        async (dateRange) => {
                return dateRange;
        }
);

export const setComparedTo= createAsyncThunk(
        "filter/setComparedTo",
        async (comparedTo) => {
                return comparedTo;
        }
);


export const setFrequency = createAsyncThunk(
        "filter/setFrequency",
        async (frequency) => {
                return frequency;
        }
);

export const graphFilterSlice = createSlice({
        name: "graphFilter",
        initialState,
        reducers: {
                reset: (state) => {
                        state.dateRange = null;
                        state.comparedTo = null;
                        state.frequency = null;
                },
        },
        extraReducers: (builder) => {

                builder.addCase(setDateRange.fulfilled, (state, action) => {
                        state.dateRange = action.payload;
                });

                builder.addCase(setComparedTo.fulfilled, (state, action) => {
                        state.comparedTo = action.payload;
                });

                builder.addCase(setFrequency.fulfilled, (state, action) => {
                        state.frequency = action.payload;
                });
                
        },
});

export const { reset } = graphFilterSlice.actions;
export default graphFilterSlice.reducer;