import axios from "axios";
import "./PaymentOption.css";
import Modal from "react-modal";
import validator from 'validator';
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';
import { Column } from "primereact/column";
import Bac from "../../../images/logo/bac.svg";
import { DataTable } from "primereact/datatable";
import { ThreeDots } from "react-loader-spinner";
import Wise from "../../../images/logo/wise.svg";
import Citi from "../../../images/logo/citi.svg";
import Hsbc from "../../../images/logo/hsbc.svg";
import Bank from "../../../images/icons/bank.svg";
import Type from "../../../images/icons/type.svg";
import React, { useState, useRef, useEffect } from "react";
import Chase from "../../../images/logo/chase.svg";
import Close from "../../../images/icons/close.svg";
import Delete from "../../../images/icons/delete.svg";
import WebPage from "../../../images/icons/webpage.svg";
import crssrImage from "../../../images/icons/close.svg";
import Location from "../../../images/icons/location.svg";
import PlusIcon from "../../../images/icons/plus-icon.svg";
import WiseSmall from "../../../images/icons/wise-small.svg";
import CompanyType from "../../../images/icons/company-type.svg";
import BankAmerica from "../../../images/logo/bank-of-america.svg";
import { useQuery } from 'react-query';
import BankAccount from "../BankAccount/BankAccount";

const PaymentOption = () => {

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDetails, setIsLoadingDetails] = useState({});
  const [loadingProfiles, setLoadingProfiles] = useState({});
  const [profiles, setProfiles] = useState([]);
  // const [wiseSettings, setWiseSettings] = useState([]);
  const [filter, setFilter] = useState("");
  const [activeProfiles, setActiveProfiles] = useState([]);
  const [listUpdate, setListUpdaete] = useState(false);
  const [newBankModal, setNewBankModel] = useState(false);
  const [stepTwo, setStepTwo] = useState(false);
  const [setApiKey, setSetApiKey] = useState("");
  const [url, setUrl] = useState("https://api.transferwise.com");
  const [isValid, setIsValid] = useState(true);
  const [selectedGateways, setSelectedGateways] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const token = useSelector(
    (state) => state.auth.token
  );
  const appId = useSelector(
    (state) => state.appSettings.appSettings.appId
  );
  const { appSettings } = useSelector((state) => state.appSettings);
  const [perPage, setPerPage] = useState(10);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isButtonLoading, seIsButtonLoading] = useState(false);
  const [showingDropdownOpen, setShowingDropdownOpen] = useState(false);
  const showingDropdownRef = useRef(null);
  const handleCheckboxChange = (e) => {
    const value = e.target.value;
    if (e.target.checked) {
      setSelectedGateways((prevSelected) => [...prevSelected, value]);
    } else {
      setSelectedGateways((prevSelected) => prevSelected.filter((gateway) => gateway !== value));
    }
  };

  const isGatewaySelected = (gateway) => {
    return selectedGateways.includes(gateway);
  };
  const [activeChange, setActiveChange] = useState(false);

  async function getProfiles() {
    try {
      const response = await axios.get("/api/wise/profile", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const arr = [];
      response.data.map((item) => {
        if (item.type === "business") {
          arr.push(item);
        }
      });

      setProfiles(arr);
    } catch (err) {
      global.config.slackMessage(err.toString());
      console.log(err);
    }
  }

 
const [wiseSettings,setWiseSettings] = useState([]);
const [settingsLoading,setSettingsLoading] = useState(false);
  useEffect(() => {

    const fetchSettingsData = async () => {
      try {
        setSettingsLoading(true);
        setIsLoading(true);
        const response = await axios.get("/api/wise/settings", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
       
  
        console.log('response',response.data);
        setWiseSettings(response.data);
        setIsLoading(false);
        fetchSettingsData(false);
        setSettingsLoading(false);
        return response.data;
      } catch (error) {
        console.log(error);
        global.config.slackMessage(error.toString());
       
        setIsLoading(false);
      }
    };

    fetchSettingsData();
    
  },[])
  //const { data: wiseSettings = [], isLoading: settingsLoading, error, refetch: settingsRefetch } = useQuery('exampleQueryKey', fetchSettingsData);



  useEffect(() => {
    // async function fetchWiseSettings() {
    //   try {
    //     setIsLoading(true);
    //     const response = await axios.get("/api/wise/settings", {
    //       headers: {
    //         Authorization: `Bearer ${token}`,
    //       },
    //     });
    //     setWiseSettings(response.data);
    //     setIsLoading(false);
    //   } catch (error) {
    //     console.log(error);
    //     global.config.slackMessage(error.toString());
    //     alert("Something went wrong");
    //     setIsLoading(false);
    //   }
    // }

    // fetchWiseSettings();
    checkActiveProfile();
    getProfiles();
  }, []);

  const handleClickOutsideDropdown = (event) => {
    const isClickInsideShowingDropdown = showingDropdownRef.current && showingDropdownRef.current.contains(event.target);
    if (!isClickInsideShowingDropdown) {
      setShowingDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutsideDropdown);
    return () => {
      document.removeEventListener('click', handleClickOutsideDropdown);
    };
  }, []);

  const checkActiveProfile = async (profileId) => {
    try {
      const response = await axios.get("/api/wise/profile/active-list", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      var profiles = [];
      if (response.data.length > 0) {
        let filtered = response.data.map((item) => {
          return parseInt(item.wise_profile_id);
        });
        setActiveProfiles(filtered);
        return filtered;
      } else {
        return profiles;
      }
    } catch (error) {
      console.log(error);
      global.config.slackMessage(error.toString());
    }
  };

  const renderUserCTA = (rowData) => {
    const { profile_id, wise_status } = rowData;
    const isActive = !wise_status // activeProfiles.includes(parseInt(profile_id));
    const isLoading = loadingProfiles[profile_id] || false;

    return (
      <span
        className={`payment-status ${wise_status ? "active" : "inactive"}`}
        onClick={(e) => {
          e.preventDefault();

          if (!isLoading) {

          }

          if (!isLoading) {
            if (isActive) {
              wiseDeactivate(rowData._id);
              setActiveChange(!activeChange);

              // Delay settingsRefetch by 3 seconds
              setTimeout(() => {
               // settingsRefetch();
              }, 3000);
            } else {
              wiseDeactivate(rowData._id);
              setActiveChange(!activeChange);

              // Delay settingsRefetch by 3 seconds
              setTimeout(() => {
               // settingsRefetch();
              }, 3000);
            }
          }
        }}
        style={{ cursor: 'pointer' }}
      >
        {isLoading ?
          <div className="outter-load-table">
            <ThreeDots
              height="18"
              width="20"
              radius="9"
              color="#6479f9"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div> : (
            isActive ? global.config.locate((appSettings && appSettings.language), 'Active') : global.config.locate((appSettings && appSettings.language), 'Inactive')
          )}
      </span>
    );
  };

  const handleFilterChange = (e) => {
    setFilter(e.target.value)
  };

  const filteredData = wiseSettings.length > 0 && wiseSettings.filter((item) => {

    const searchTerm = filter.trim().toLowerCase();
    if (!isLoading && searchTerm) {
      const hasMatch =
        item.comapny_name?.toLowerCase().includes(searchTerm) ||
        item.comapny_account?.toLowerCase().includes(searchTerm);
      return hasMatch;
    } else {

      return item;
    }

  });

  const wiseActivate = async (profileId) => {
    try {
      setLoadingProfiles((prevLoadingProfiles) => ({
        ...prevLoadingProfiles,
        [profileId]: true,
      }));
      await axios.get(`/api/wise/profile/activate/${profileId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setActiveProfiles((prevActiveProfiles) => [
        ...prevActiveProfiles,
        parseInt(profileId),
      ]);

      setLoadingProfiles((prevLoadingProfiles) => ({
        ...prevLoadingProfiles,
        [profileId]: false,
      }));
      toast.success(`Wise profile ${profileId} activated successfully`, {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.error(error);
      global.config.slackMessage(error.toString());
      setLoadingProfiles((prevLoadingProfiles) => ({
        ...prevLoadingProfiles,
        [profileId]: false,
      }));
      toast.error(`Something went wrong while activation of wise profile ${profileId}`, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const wiseDeactivate = async (profileId) => {

    try {

      // setLoadingProfiles((prevLoadingProfiles) => ({
      //   ...prevLoadingProfiles,
      //   [profileId]: true,
      // }));

      const response = await axios.get(`/api/wise/settings/status/toggle/${profileId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // setActiveProfiles((prevActiveProfiles) =>
      //   prevActiveProfiles.filter((id) => id !== parseInt(profileId))
      // );

      // setLoadingProfiles((prevLoadingProfiles) => ({
      //   ...prevLoadingProfiles,
      //   [profileId]: false,
      // }));

      let msg = null;
      if (response.data.wise_status === true) {

        msg = "Activated";
      }

      if (response.data.wise_status === false) {

        msg = "Deactivated";

      }

      toast.success(`Wise profile ${msg} successfully`, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

    } catch (error) {
      console.error(error);
      global.config.slackMessage(error.toString());
      setLoadingProfiles((prevLoadingProfiles) => ({
        ...prevLoadingProfiles,
        [profileId]: false,
      }));
      toast.error(`Something went wrong while deactivation of wise profile ${profileId}`, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

    }
  };

  const handleOpenModal = () => {
    setNewBankModel(true);
    setSelectedGateways((prevSelected) => [...prevSelected, "wise"]);
  };

  // Function to open the modal and set the selected profile
  const openModal = async (profile) => {

    // setIsDetailsLoading(true);
    const profileId = profile.profile_id;
    setIsLoadingDetails((prevLoadingState) => ({
      ...prevLoadingState,
      [profileId]: true, // Set loading to true for the clicked profile
    }));
    const settingsId = profile._id;
    const filtered = profiles.map((item) => {
      if (item.id === parseInt(profile.profile_id)) {
        return item;
      }
    });
    try {
      const response = await axios.post(
        `/api/wise/profile/check-active-invoices`,
        {
          comapny_account: profile.comapny_account,
        }
      );

      const finalFilt = filtered.map((item) => {

        if (item !== undefined) {
          var profileData = item;
          if (profileData) {
            profileData.activeCount = response.data;
            profileData.settingsId = settingsId;
            setSelectedProfile(profileData);
            setIsModalOpen(true);
          }
          return item;
        }

      });

    } catch (error) {
      console.error(error);
      global.config.slackMessage(error.toString());
      alert("Something went wrong");
    } finally {
      setIsLoadingDetails((prevLoadingState) => ({
        ...prevLoadingState,
        [profileId]: false, // Set loading to false for the clicked profile after the operation is complete
      }));
    }
  };

  // Function to close the modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleCloseModal = () => {
    setNewBankModel(false);
    setSelectedGateways([]);
    setStepTwo(false);
  };

  const handleSubmit1 = (event) => {
    event.preventDefault();
    setErrorMessage(null);
    setStepTwo(true);
  };

  const handleSubmit2 = async (event) => {
    event.preventDefault();
    seIsButtonLoading(true);
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${url}/v1/profiles`,
      headers: {
        'Authorization': `Bearer ${setApiKey}`,
      }
    };

    axios.request(config)
      .then(async (response) => {

        await axios
          .post("/api/wise/add", {
            wise_api_key: setApiKey,
            wise_url: url,
            wise_status: true,
            appId: appId,
            comapny_account: null,
            comapny_name: null,
            profile_id: response.data[1].id
          })
          .then((response) => {

            if (response.data.status === "exists") {
              setErrorMessage(response.data.message);
            } else {
              seIsButtonLoading(false);
              setNewBankModel(false);
              setStepTwo(false);
              setSetApiKey(null);
              setListUpdaete(!listUpdate);
            }
          })
          .catch((error) => {
            console.log(error);
            global.config.slackMessage(error.toString());
            seIsButtonLoading(false);
            setErrorMessage("API Key already exist.");
          });

        seIsButtonLoading(false);
      })
      .catch((error) => {
        console.log(error);
        global.config.slackMessage(error.toString());
        seIsButtonLoading(false);
        setErrorMessage("Sorry!. Please check API details");
      });

  };

  const renderDetailButton = (rowData) => {
    const profileId = rowData.profile_id;
    const loading = isLoadingDetails[profileId];

    return (
      <button
        className="cta-link account-payment"
        onClick={() => openModal(rowData)}
      >
        {loading ?
          <div className="outter-load-table">
            <ThreeDots
              height="18"
              width="20"
              radius="9"
              color="white"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div> : global.config.locate(appSettings && appSettings.language, 'Details')}
      </button>
    );
  };

  const handleDelete = () => {
    setIsDeleteModalOpen(true);
  };

  const handleCancelDelete = () => {
    setIsDeleteModalOpen(false);
  };

  const handleInputChange = (event) => {
    const inputUrl = event.target.value;
    setUrl(inputUrl);
    setIsValid(validator.isURL(inputUrl));
  };

  const deleteAccount = (settingsId) => {

    alert(settingsId);
  }

  const totalResults = filteredData && filteredData.length;

  return (
    <div className="user-outer-overview add-bank-account bank-account-new-type">
      <div className="invoice-top">
        <div className="invoice-top-inner-left">
          <BankAccount />
          <div className="search-sec">
            <div class="search-container">
              <input
                type="text"
                onChange={handleFilterChange}
                value={filter}
                class="search-input"
                placeholder='Search by name'
              />
            </div>
          </div>
        </div>
        <div className="button-sec-invoice">
          <Link onClick={handleOpenModal}>
            <span className="icon-invoice">
              <img src={PlusIcon} alt="Add" />
            </span>
            {global.config.locate(appSettings && appSettings.language, 'New_Bank_Account')}
          </Link>

          <Modal
            isOpen={newBankModal}
            onRequestClose={handleCloseModal}
            className="client-modal sprint-modal add-bank-account-modal account-details-modal"
            contentLabel="Invite a User"
            style={{
              content: {
                height: "41%",
                width: "30%",
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              },
            }}
          >
            <div className="modal add-bank-account-content account-details-modal-inner">
              <div className="modal-close-invite outter-modal-main">
                <Link className="" onClick={handleCloseModal}>
                  <img src={Close} alt="Edit Icon" />{" "}
                </Link>
              </div>
              <div className="modal-invite-head">
                <h2 className=""> {global.config.locate(appSettings && appSettings.language, 'New_Bank_Account')} </h2>
              </div>
              <div className="progress-bar">
                {stepTwo === false ? (
                  <progress value="1" max="2" />
                ) : (
                  <progress value="2" max="2" />
                )}
              </div>

              {stepTwo === false ? (
                <form onSubmit={handleSubmit1}>
                  <div>
                    <div className="the-modal-inuser">
                      <label htmlFor="grid-password">
                        <p className="step-head">{global.config.locate(appSettings && appSettings.language, 'Step1')}</p>
                        <p className="step-description">{global.config.locate(appSettings && appSettings.language, 'Please_select_the_bank')}</p>
                      </label>
                      <div className="account-bank-outer">
                        <div className="account-bank-inner">
                          <label className="checkbox-container">
                            <input
                              type="radio"
                              name="gateway"
                              value="wise"
                              className="hidden-checkbox"
                              required
                              onChange={handleCheckboxChange}
                              checked={isGatewaySelected('wise')}
                            />
                            <span className="checkbox-logo" style={{ backgroundImage: `url(${Wise})` }}></span>
                          </label>
                        </div>
                        <div className="account-bank-inner">
                          <label className="checkbox-container">
                            <input
                              type="radio"
                              name="gateway"
                              value="another-gateway"
                              className="hidden-checkbox"
                              required
                              disabled
                              onChange={handleCheckboxChange}
                              checked={isGatewaySelected('another-gateway')}
                            />
                            <span className="checkbox-logo" style={{ backgroundImage: `url(${Chase})` }}></span>
                          </label>
                        </div>
                        <div className="account-bank-inner">
                          <label className="checkbox-container">
                            <input
                              type="radio"
                              name="gateway"
                              value="another-gateway"
                              className="hidden-checkbox"
                              required
                              disabled
                              onChange={handleCheckboxChange}
                              checked={isGatewaySelected('another-gateway')}
                            />
                            <span className="checkbox-logo" style={{ backgroundImage: `url(${BankAmerica})` }}></span>
                          </label>
                        </div>
                        <div className="account-bank-inner">
                          <label className="checkbox-container">
                            <input
                              type="radio"
                              name="gateway"
                              value="another-gateway"
                              className="hidden-checkbox"
                              required
                              disabled
                              onChange={handleCheckboxChange}
                              checked={isGatewaySelected('another-gateway')}
                            />
                            <span className="checkbox-logo" style={{ backgroundImage: `url(${Bac})` }}></span>
                          </label>
                        </div>
                        <div className="account-bank-inner">
                          <label className="checkbox-container">
                            <input
                              type="radio"
                              name="gateway"
                              value="another-gateway"
                              className="hidden-checkbox"
                              required
                              disabled
                              onChange={handleCheckboxChange}
                              checked={isGatewaySelected('another-gateway')}
                            />
                            <span className="checkbox-logo" style={{ backgroundImage: `url(${Hsbc})` }}></span>
                          </label>
                        </div>
                        <div className="account-bank-inner">
                          <label className="checkbox-container">
                            <input
                              type="radio"
                              name="gateway"
                              value="another-gateway"
                              className="hidden-checkbox"
                              required
                              disabled
                              onChange={handleCheckboxChange}
                              checked={isGatewaySelected('another-gateway')}
                            />
                            <span className="checkbox-logo" style={{ backgroundImage: `url(${Citi})` }}></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="invite-mail-popup-btn">
                    <button type="submit" className="">
                      {global.config.locate(appSettings && appSettings.language, 'Continue')}
                    </button>
                  </div>
                </form>
              ) : (
                <form onSubmit={handleSubmit2}>
                  <div>
                    <div className="the-modal-inuser">
                      <label htmlFor="grid-password" className="">
                        <p className="step-head">{global.config.locate(appSettings && appSettings.language, 'Step2')}</p>
                        <p className="step-description">{global.config.locate(appSettings && appSettings.language, 'Please_fill_out_the_following_information')}</p>
                      </label>
                      <div className="flex items-center">

                        <div className="form-step2">
                          <label>URL</label>
                          <input
                            type="text"
                            value={url}

                            placeholder="ex: https://api.sandbox.transferwise.tech"
                            required onChange={handleInputChange} />
                          <span style={{ color: "red" }}>{isValid ? '' : 'Invalid URL'}</span>
                        </div>
                        <div className="form-step2">
                          <label>API-Key</label>
                          <input
                            type="text"
                            name="gateway"
                            value={setApiKey}
                            className=""
                            required
                            placeholder="API Key"
                            onChange={(e) => setSetApiKey(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <span style={{ color: "red" }}>{errorMessage}</span>
                  </div>
                  <div className="invite-mail-popup-btn">
                    {isButtonLoading ?
                      (<button type="button" className="">
                        <ThreeDots
                          height="25"
                          width="35"
                          radius="9"
                          color="#fff"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClassName=""
                          visible={true}
                          style={{ paddingLeft: "163px" }}
                        />
                      </button>)
                      :
                      (<button type="submit" className="">
                        {global.config.locate(appSettings && appSettings.language, 'Continue')}
                      </button>)
                    }
                  </div>
                </form>
              )}
            </div>
          </Modal>

        </div>

      </div>
      <div className="showing-count">
        <div className="filter-inner showing-result">
          <label>{global.config.locate(appSettings && appSettings.language, 'Showing')}</label>
          <div ref={showingDropdownRef} className="select-modal-mainM custom-dropdown-user-new-outer">
            <div className="selected-option custom-dropdown-user-new" onClick={() => setShowingDropdownOpen(!showingDropdownOpen)}>
              {totalResults < 10 ? totalResults.toString() : perPage.toString()}
            </div>
            {showingDropdownOpen && (
              <div className="custom-dropdown-new">
                <ul className="showing-options-list">
                  {totalResults >= 10 && <li className={perPage === 10 ? 'selected-item' : ''} onClick={() => { setPerPage(10); setShowingDropdownOpen(false); }}>10</li>}
                  {totalResults >= 20 && <li className={perPage === 20 ? 'selected-item' : ''} onClick={() => { setPerPage(20); setShowingDropdownOpen(false); }}>20</li>}
                  {totalResults >= 30 && <li className={perPage === 30 ? 'selected-item' : ''} onClick={() => { setPerPage(30); setShowingDropdownOpen(false); }}>30</li>}
                  {totalResults >= 40 && <li className={perPage === 40 ? 'selected-item' : ''} onClick={() => { setPerPage(40); setShowingDropdownOpen(false); }}>40</li>}
                  {totalResults >= 10 && <li className={totalResults >= 10 && perPage === totalResults ? 'selected-item' : ''} onClick={() => { setPerPage(totalResults); setShowingDropdownOpen(false); }}>ALL</li>}
                  {/* Add more conditions as needed */}
                  {totalResults < 10 ? (
                    <li className={totalResults < 10 ? 'selected-item' : ''} onClick={() => { setPerPage(totalResults); setShowingDropdownOpen(false); }}>{totalResults}</li>
                  ) : null}
                </ul>
              </div>
            )}
          </div>
          <label>{global.config.locate(appSettings && appSettings.language, 'of')} {totalResults} {global.config.locate(appSettings && appSettings.language, 'Results')}</label>
        </div>
      </div>

      <div className="card-outer1">
        <div className="card-outer card-outer-account">
          <div className="card-outer-user">

          </div>
          <div className="modal-invite-main-outter modal-add-bank-account">
            <Modal
              isOpen={isModalOpen}
              onRequestClose={closeModal}
              className="client-modal sprint-modal add-bank-account-modal add-bank-account-modal-details"
              contentLabel="Invite a User"
              style={{
                content: {
                  height: "41%",
                  width: "30%",
                  position: "fixed",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                },
              }}
            >
              <div className="modal timeoff-review account-detail-inner-sec">
                <div className="modal-close-invite outter-modal-main">
                  <a className="" onClick={closeModal}>
                    <img src={crssrImage} alt="Edit Icon" />{" "}
                  </a>
                </div>
                <div className="modal-client-head">
                  <h2 className="">{global.config.locate(appSettings && appSettings.language, 'Account_Details')} </h2>
                </div>
                <div className="form-field-client-outer">
                  <div className="form-field-client">
                    <label className="form-label">
                      <div className="icon-before-time">
                        <img src={Bank} alt="bank" />
                      </div>
                      {global.config.locate(appSettings && appSettings.language, 'Bank')}
                    </label>
                    <div className="timeoff-user-inner">
                      <img src={WiseSmall} alt="Wise" />
                    </div>
                  </div>
                  <div className="form-field-client">
                    <label className="form-label" >
                      <div className="icon-before-time">
                        <img src={Type} alt="type" />
                      </div>
                      {global.config.locate(appSettings && appSettings.language, 'Name')}
                    </label>
                    {selectedProfile && selectedProfile.details.name}

                  </div>
                  <div className="form-field-client">
                    <label className="form-label" >
                      <div className="icon-before-time">
                        <img src={Type} alt="type" />
                      </div>
                      {global.config.locate(appSettings && appSettings.language, 'Registration_Number')}
                    </label>
                    {selectedProfile && selectedProfile.details.registrationNumber}
                  </div>
                  <div className="form-field-client">
                    <label className="form-label">
                      <div className="icon-before-time">
                        <img src={CompanyType} alt="CompanyType" />
                      </div>
                      {global.config.locate(appSettings && appSettings.language, 'Company_Type')}
                    </label>
                    {selectedProfile && selectedProfile.details.companyType}
                  </div>
                  <div className="form-field-client">
                    <label className="form-label" >
                      <div className="icon-before-time">
                        <img src={Type} alt="role" />
                      </div>
                      {global.config.locate(appSettings && appSettings.language, 'Company_Role')}
                    </label>
                    {selectedProfile && selectedProfile.details.companyRole}
                  </div>
                  <div className="form-field-client">
                    <label className="form-label" >
                      <div className="icon-before-time">
                        <img src={Type} alt="Type" />
                      </div>
                      {global.config.locate(appSettings && appSettings.language, 'Description_of_Business')}
                    </label>
                    {selectedProfile && selectedProfile.details.descriptionOfBusiness}
                  </div>
                  <div className="form-field-client">
                    <label className="form-label" >
                      <div className="icon-before-time">
                        <img src={Location} alt="Location" />
                      </div>
                      {global.config.locate(appSettings && appSettings.language, 'Primary_Address')}
                    </label>
                    {selectedProfile && selectedProfile.details.primaryAddress}
                  </div>
                  <div className="form-field-client">
                    <label className="form-label" >
                      <div className="icon-before-time">
                        <img src={WebPage} alt="Webpage" />
                      </div>
                      {global.config.locate(appSettings && appSettings.language, 'Webpage')}
                    </label>
                    {selectedProfile && selectedProfile.details.webpage}
                  </div>
                  <div className="form-field-client">
                    <label className="form-label" >
                      <div className="icon-before-time">
                        <img src={Type} alt="Description" />
                      </div>
                      {global.config.locate(appSettings && appSettings.language, 'Description_Category')}
                    </label>
                    {selectedProfile && selectedProfile.details.businessCategory}
                  </div>
                  <div className="form-field-client-btns delete-timeoff delete-account-details">
                    <button
                      type="button"
                      className="delete-client"
                      onClick={() => handleDelete(selectedProfile && selectedProfile.settingsId)}
                    >
                      <img src={Delete} alt="Delete" /> {global.config.locate(appSettings && appSettings.language, 'Delete_Account')}
                    </button>
                    <Modal
                      className="delete-modal"
                      isOpen={isDeleteModalOpen}
                      onRequestClose={handleCancelDelete}
                      contentLabel="Confirm Delete"
                      style={{
                        content: {
                          height: "41%",
                          width: "30%",
                          position: "fixed",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-17.5%, 0%)",
                          parent: document.querySelector(".admin-outer.time.tracker"),
                        },
                      }}
                    >
                      <div className="delete-modal-content">
                        <h2>{global.config.locate(appSettings && appSettings.language, 'Confirm_Delete')}</h2>
                        {
                          (selectedProfile && selectedProfile.activeCount === 0) ?
                            (<p>
                              {global.config.locate(appSettings && appSettings.language, 'Are_you_sure_you_want_to_delete_this_Account')}
                            </p>)
                            :
                            (<p>
                              {global.config.locate(appSettings && appSettings.language, 'Sorry_This_Account_have_unpaid_invoices_please_complete_payment_or_remove_that_invoices')}
                            </p>)
                        }
                        <div className="delete-modal-buttons">
                          {(selectedProfile && selectedProfile.activeCount === 0) &&
                            (
                              <button className="delete" onClick={() => { deleteAccount(selectedProfile && selectedProfile.settingsId) }} >
                                {global.config.locate(appSettings && appSettings.language, 'Delete')}
                              </button>)
                          }
                          <button onClick={handleCancelDelete}>
                            {global.config.locate(appSettings && appSettings.language, 'Cancel')}
                          </button>
                        </div>
                      </div>
                    </Modal>
                  </div>
                </div>
              </div>
            </Modal>
          </div>
          <div className="card">
            {settingsLoading === true ? (
              <div className="outter-load-table">
                <ThreeDots
                  height="80"
                  width="80"
                  radius="9"
                  color="#405BFF"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
              </div>
            ) : (
              <DataTable
                value={filteredData}
                removableSort
                tableStyle={{
                  minWidth: "50rem",
                  width: "100%",
                  textAlign: "left",
                }}
                paginator={filteredData && filteredData.length > perPage}
                paginatorClassName="recent-invoices-pagination"
                rows={perPage}
              >
                <Column
                  body={(rowData) => rowData.comapny_name || 'N/A'}
                  field="comapny_name"
                  sortField="id"
                  header={global.config.locate(appSettings && appSettings.language, 'Company_name')}
                  sortable
                  style={{ paddingRight: "44px", maxWidth: "297px" }}
                ></Column>
                <Column
                  body={(rowData) => rowData.comapny_account || 'N/A'}
                  field="comapny_account"
                  header={global.config.locate(appSettings && appSettings.language, 'Company_Account')}
                  sortable
                  style={{ paddingRight: "20px" }}
                ></Column>
                <Column
                  field="wise_api_key"
                  header="API Key"
                  sortable
                  className="blur"
                  style={{ paddingRight: '44px' }}
                ></Column>
                <Column
                  header={global.config.locate(appSettings && appSettings.language, 'Status')}
                  body={renderUserCTA}
                  sortable
                  style={{ paddingRight: "44px" }}
                ></Column>
                <Column
                  body={renderDetailButton}
                  style={{ width: "76px", textAlign: "center" }}
                ></Column>
              </DataTable>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default PaymentOption;