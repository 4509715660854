import axios from "axios";
import Modal from "react-modal";
import "./TimeTrackerClient.css";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ThreeDots } from "react-loader-spinner";
import React, { useEffect, useRef, useState } from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import Countries from "../../../constants/countries.js";
import { useSelector, useDispatch } from "react-redux";
import Plus from "../../../images/icons/plus-black.svg";
import PlusIcon from "../../../images/icons/plus-icon.svg";
import { logout, reset } from "../../../features/auth/authSlice.js";
import TimeTrackerClientListing from "./TimeTrackerClientListing.jsx";
import crssrImage from "../../../images/singleinvoice/cross-red.svg";
import { reset as invoiceReset } from "../../../features/invoice/invoiceSlice.js";
import { reset as headerReset } from "../../../features/headerText/headerTextSlice.js";
import { reset as appSettingsReset } from "../../../features/appSettings/appSettingsSlice.js";
import { useUserById } from "../../TimeOff/timeOffApi.jsx";

const TimeTrackerClient = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [clientName, setClientName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [accManager, setAccManager] = useState("");
  const [accManagerDropdownOpen, setAccManagerDropdownOpen] = useState(false);
  const [userSearchTerm, setUserSearchTerm] = useState("");
  const [accManagerIsValid, setAccManagerIsValid] = useState(false);
  const [contactPoint, setContactPoint] = useState("");
  const [contactPointDropdownOpen, setContactPointDropdownOpen] =
    useState(false);
  // const [contactPointSearchTerm, setContactPointSearchTerm] = useState('');
  const [contactPointIsValid, setContactPointIsValid] = useState(false);
  const [companyWebsite, setCompanyWebsite] = useState("");
  const [isValidUrl, setIsValidUrl] = useState(false);
  const [companyLogo, setCompanyLogo] = useState(null);
  const [companyLogoName, setCompanyLogoName] = useState("");
  const [country, setCountry] = useState("");
  const [address, setAddress] = useState("");
  const [additionalInfo, setAdditionalInfo] = useState("");
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [updateClientList, setUpdateClientList] = useState(false);
  const [countrySearchTerm, setCountrySearchTerm] = useState("");
  const [countryDropdownOpen, setCountryDropdownOpen] = useState(false);
  const [filter, setFilter] = useState("");
  const [countriesList, setCountriesList] = useState([]);
  const accManagerRef = useRef(null);
  const accManagerSelectDropdownRef = useRef(null);
  const contactPointRef = useRef(null);
  const contactPointSelectDropdownRef = useRef(null);
  const countryDropdownRef = useRef(null);
  let token = null;
  const { user } = useSelector((state) => state.auth);
  const { appSettings } = useSelector((state) => state.appSettings);
  if (user) {
    if (user && user.token) {
      token = user.token;
    }
  }
  const { data: userIds = [], isLoading: isLoadingUser } = useUserById(token);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setClientName("");
    setEmail("");
    setMobileNo("");
    setAccManager("");
    setUserSearchTerm("");
    setAccManagerDropdownOpen(false);
    setAccManagerIsValid(false);
    setContactPoint("");
    setContactPointDropdownOpen(false);
    setContactPointIsValid(false);
    setCompanyWebsite("");
    setCompanyLogo(null);
    setCompanyLogoName("");
    setCountry("");
    setAddress("");
    setAdditionalInfo("");
  };

  const handleLogoUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setCompanyLogo(e.target.files[0]);
      setCompanyLogoName(file.name);
    } else {
      setCompanyLogo(null);
      setCompanyLogoName("");
    }
  };
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        document.activeElement.blur();
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    const sortedCountries = Countries.slice().sort((a, b) =>
      a.country.localeCompare(b.country)
    );
    setCountriesList(sortedCountries);
  }, []);

  const handleToggleAccManagerDropdown = () => {
    setUserSearchTerm("");
    setAccManagerDropdownOpen((prevOpen) => !prevOpen);
  };

  const handleSelectAccManager = (selectedAccManager) => {
    setAccManager(selectedAccManager);
    setUserSearchTerm("");
    setAccManagerDropdownOpen(false);
  };

  const handleAccManagerSearchInputChange = (e) => {
    setUserSearchTerm(e.target.value);
  };

  const handleToggleContactPointDropdown = () => {
    setUserSearchTerm("");
    setContactPointDropdownOpen((prevOpen) => !prevOpen);
  };

  const handleSelectContactPoint = (selectedContactPoint) => {
    setContactPoint(selectedContactPoint);
    setUserSearchTerm("");
    setContactPointDropdownOpen(false);
  };

  const handleContactPointSearchInputChange = (e) => {
    setUserSearchTerm(e.target.value);
  };

  const filteredUsers = userIds.filter((user) => {
    const fullName = user.first_name
      ? `${user.first_name} ${user.middle_name || ""} ${user.last_name}`
      : `${
          user.company_first_name
            ? user.company_first_name +
              (user.company_last_name && user.company_last_name)
            : user.company_name
        }`;
    return fullName.toLowerCase().includes(userSearchTerm.toLowerCase());
  });

  const handleToggleCountryDropdown = () => {
    setCountrySearchTerm("");
    setCountryDropdownOpen((prevOpen) => !prevOpen);
  };

  const handleCountrySearchInputChange = (e) => {
    setCountrySearchTerm(e.target.value);
  };

  const filteredCountries = countriesList.filter((country) => {
    return country.country
      .toLowerCase()
      .includes(countrySearchTerm.toLowerCase());
  });

  const handleClickOutsideDropdown = (event) => {
    const isClickInsideCountryDropdown =
      countryDropdownRef.current &&
      countryDropdownRef.current.contains(event.target);
    const isClickInsideAccManagerSelectDropdown =
      accManagerSelectDropdownRef.current &&
      accManagerSelectDropdownRef.current.contains(event.target);
    const isClickInsideContactPointSelectDropdown =
      contactPointSelectDropdownRef.current &&
      contactPointSelectDropdownRef.current.contains(event.target);
    if (!isClickInsideCountryDropdown) {
      setCountryDropdownOpen(false);
    }
    if (!isClickInsideAccManagerSelectDropdown) {
      setAccManagerDropdownOpen(false);
    }
    if (!isClickInsideContactPointSelectDropdown) {
      setContactPointDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutsideDropdown);
    return () => {
      document.removeEventListener("click", handleClickOutsideDropdown);
    };
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setAccManagerIsValid(false);
    setContactPointIsValid(false);
    setIsValidUrl(false);
    const pattern = /^(ftp|http|https):\/\/[^ "]+$/;
    if (accManager === "" || contactPoint === "" || clientName === "") {
      if (accManager === "") {
        setAccManagerIsValid(true);
        accManagerRef && accManagerRef.current.focus();
        return;
      } else if (contactPoint === "") {
        setContactPointIsValid(true);
        contactPointRef && contactPointRef.current.focus();
        return;
      }

      toast.error("Please fill all required fields!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    if (!pattern.test(companyWebsite)) {
      toast.error("It's not a valid URL.", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setIsValidUrl(true);
      return;
    }
    const requestData = {
      client_name: clientName,
      email,
      mobile_no: mobileNo,
      address,
      country,
      acc_manager: accManager,
      contact_point: contactPoint,
      company_website: companyWebsite,
      additional_info: additionalInfo,
    };
    // Create a new FormData object
    const formData = new FormData();
    formData.append("company_logo", companyLogo);

    // Append the other request data to the form data
    for (let key in requestData) {
      formData.append(key, requestData[key]);
    }
    try {
      setIsSubmitLoading(true);
      const response = await axios.post(
        "/api/client-details/create",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = response.data;
      if (data.status === "exists") {
        toast.error("Client already exists", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (data.status === "error") {
        toast.error("Something went wrong", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.success("Client registered successfully", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        handleCloseModal();
        global.config.activityLog(
          window.location.href,
          "Client",
          `Client (${clientName}) Registered Successfully`
        );
      }
    } catch (error) {
      console.log(error);
      global.config.slackMessage(error.toString());
      if (error.response?.data?.status === "deleted") {
        localStorage.removeItem("user");
        dispatch(logout());
        dispatch(reset());
        dispatch(invoiceReset());
        dispatch(headerReset());
        dispatch(appSettingsReset());
        toast.error("This user is deleted!", {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/");
        return;
      }
      alert("Something went wrong");
    } finally {
      setIsSubmitLoading(false);
      if (updateClientList === false) {
        setUpdateClientList(true);
      } else {
        setUpdateClientList(false);
      }
    }
  };

  return (
    <div className="time-client-outer">
      <div className="time-client-top">
        <div className="button-sec-invoice">
          <a className="new-client" href="#" onClick={handleOpenModal}>
            <span className="icon-invoice">
              <img src={PlusIcon} alt="New Client" />
            </span>
            {global.config.locate(
              appSettings && appSettings.language,
              "New Client"
            )}
          </a>
          <div className="modal-invite-main-outter">
            <Modal
              className="client-modal"
              isOpen={isModalOpen}
              onRequestClose={handleCloseModal}
              contentLabel="New Client Account"
              style={{
                content: {
                  height: "41%",
                  width: "30%",
                  position: "fixed",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  parent: document.querySelector(".admin-outer.time.tracker"),
                },
              }}
            >
              <div className="modal">
                <div className="modal-close-invite outter-modal-main">
                  <a className="" onClick={handleCloseModal}>
                    <img src={crssrImage} alt="Edit Icon" />{" "}
                  </a>
                </div>
                <div className="modal-client-head">
                  <h2 className="">
                    {global.config.locate(
                      appSettings && appSettings.language,
                      "New Client Account"
                    )}
                  </h2>
                </div>
                <div className="form-field-client-outer profile-details-edit">
                  <form onSubmit={handleSubmit}>
                    <div className="form-field-client">
                      <label htmlFor="clientName" className="form-label">
                        {global.config.locate(
                          appSettings && appSettings.language,
                          "Client Name"
                        )}
                      </label>
                      <input
                        type="text"
                        id="clientName"
                        name="clientName"
                        className="form-input"
                        value={clientName}
                        onChange={(e) => setClientName(e.target.value)}
                        required
                      />
                    </div>
                    <div className="form-field-client">
                      <label htmlFor="emailAddress" className="form-label">
                        {global.config.locate(
                          appSettings && appSettings.language,
                          "Email Address"
                        )}
                      </label>
                      <input
                        type="email"
                        id="emailAddress"
                        name="emailAddress"
                        className="form-input"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                    </div>
                    <div className="inner-detail-inner form-field-client">
                      <label htmlFor="mobileNo" className="form-label">
                        {global.config.locate(
                          appSettings && appSettings.language,
                          "Mobile_No"
                        )}
                      </label>
                      <PhoneInput
                        placeholder="Enter phone number"
                        value={mobileNo}
                        onChange={setMobileNo}
                      />
                    </div>
                    <div className="form-field-client">
                      <label htmlFor="companyWebsite" className="form-label">
                        {global.config.locate(
                          appSettings && appSettings.language,
                          "Company Website"
                        )}
                      </label>
                      <input
                        type="text"
                        id="companyWebsite"
                        name="companyWebsite"
                        className="form-input"
                        value={companyWebsite}
                        onChange={(e) => setCompanyWebsite(e.target.value)}
                      />
                      {isValidUrl && (
                        <span className="text-red-500 text-xs italic">
                          Please enter a valid URL!!
                        </span>
                      )}
                    </div>
                    <div className="form-field-client">
                      <label htmlFor="companyLogo" className="form-label">
                        {global.config.locate(
                          appSettings && appSettings.language,
                          "Company Logo"
                        )}
                      </label>
                      <div className="file-input-wrapper">
                        <input
                          type="text"
                          className="form-input form-input-file"
                          value={companyLogoName}
                          readOnly
                        />
                        <input
                          type="file"
                          id="companyLogo"
                          name="companyLogo"
                          className="form-input hidden-input"
                          accept="image/*"
                          onChange={handleLogoUpload}
                          required
                        />
                        <label
                          htmlFor="companyLogo"
                          className="file-input-label"
                        >
                          <img src={Plus} alt="Browse" />
                          Browse
                        </label>
                      </div>
                    </div>
                    <div className="form-field-client">
                      <label htmlFor="client" className="form-label">
                        {global.config.locate(
                          appSettings && appSettings.language,
                          "Account Manager"
                        )}
                      </label>

                      <div
                        ref={accManagerSelectDropdownRef}
                        className={`cursor ${
                          accManagerDropdownOpen ? "open" : ""
                        }`}
                      >
                        <span
                          id="user-select"
                          onClick={handleToggleAccManagerDropdown}
                          className={`custom-dropdown-user-new custom-dropdown-user ${
                            accManagerIsValid ? "input-validation-error" : ""
                          }`}
                          ref={accManagerRef}
                        >
                          {accManager ? (
                            userIds?.find((user) => user._id === accManager)
                              ?.first_name ? (
                              <>
                                {
                                  userIds?.find(
                                    (user) => user._id === accManager
                                  )?.first_name
                                }{" "}
                                {userIds?.find(
                                  (user) => user._id === accManager
                                )?.middle_name &&
                                userIds?.find((user) => user._id === accManager)
                                  ?.middle_name !== "null"
                                  ? userIds?.find(
                                      (user) => user._id === accManager
                                    )?.middle_name + " "
                                  : ""}
                                {
                                  userIds?.find(
                                    (user) => user._id === accManager
                                  )?.last_name
                                }{" "}
                              </>
                            ) : (
                              <>
                                {userIds.find((user) => user._id === accManager)
                                  ?.company_first_name
                                  ? `${
                                      userIds.find(
                                        (user) => user._id === accManager
                                      )?.company_first_name
                                    } ${
                                      userIds.find(
                                        (user) => user._id === accManager
                                      )?.company_last_name
                                        ? userIds.find(
                                            (user) => user._id === accManager
                                          )?.company_last_name
                                        : ""
                                    }`
                                  : `${
                                      userIds.find(
                                        (user) => user._id === accManager
                                      )?.company_name || ""
                                    }(Company)`}
                              </>
                            )
                          ) : (
                            global.config.locate(
                              appSettings && appSettings.language,
                              "Select Account Manager"
                            )
                          )}
                        </span>
                        {accManagerDropdownOpen && (
                          <div className="custom-dropdown-new">
                            <input
                              type="text"
                              className="search-bar"
                              placeholder={global.config.locate(
                                appSettings && appSettings.language,
                                "Search"
                              )}
                              value={userSearchTerm}
                              onChange={handleAccManagerSearchInputChange}
                            />
                            <ul className="client-list">
                              {filteredUsers.length > 0 &&
                                filteredUsers
                                  .slice()
                                  .sort((a, b) => {
                                    const nameA = a.first_name
                                      ? `${a.first_name} ${
                                          a.middle_name || ""
                                        } ${a.last_name}`
                                      : `${
                                          a.company_first_name
                                            ? a.company_first_name +
                                              (a.company_last_name &&
                                                a.company_last_name)
                                            : a.company_name
                                        }`;
                                    const nameB = b.first_name
                                      ? `${b.first_name} ${
                                          b.middle_name || ""
                                        } ${b.last_name}`
                                      : `${
                                          b.company_first_name
                                            ? b.company_first_name +
                                              (b.company_last_name &&
                                                b.company_last_name)
                                            : b.company_name
                                        }`;
                                    return nameA.localeCompare(nameB);
                                  })
                                  .map((item) => (
                                    <li
                                      key={item._id}
                                      className={
                                        accManager === item._id
                                          ? "selected-item"
                                          : ""
                                      }
                                      onClick={() =>
                                        handleSelectAccManager(item._id)
                                      }
                                    >
                                      {item.first_name ? (
                                        <>
                                          {item.first_name}{" "}
                                          {item.middle_name &&
                                          item.middle_name !== "null"
                                            ? item.middle_name + " "
                                            : ""}
                                          {item.last_name}{" "}
                                        </>
                                      ) : (
                                        <>
                                          {item.company_first_name
                                            ? `${item.company_first_name} ${
                                                item.company_last_name
                                                  ? item.company_last_name
                                                  : ""
                                              }`
                                            : `${
                                                item.company_name || ""
                                              }(Company)`}
                                        </>
                                      )}
                                    </li>
                                  ))}
                            </ul>
                          </div>
                        )}
                      </div>

                      {accManagerIsValid && (
                        <span className="text-red-500 text-xs italic">
                          Account Manager needs to be selected
                        </span>
                      )}
                    </div>
                    <div className="form-field-client">
                      <label htmlFor="client" className="form-label">
                        Contact Point
                      </label>
                      <div
                        ref={contactPointSelectDropdownRef}
                        className={`cursor ${
                          contactPointDropdownOpen ? "open" : ""
                        }`}
                      >
                        <span
                          id="user-select"
                          onClick={handleToggleContactPointDropdown}
                          className={`custom-dropdown-user-new custom-dropdown-user ${
                            contactPointIsValid ? "input-validation-error" : ""
                          }`}
                          ref={contactPointRef}
                        >
                          {contactPoint ? (
                            userIds?.find((user) => user._id === contactPoint)
                              ?.first_name ? (
                              <>
                                {
                                  userIds?.find(
                                    (user) => user._id === contactPoint
                                  )?.first_name
                                }{" "}
                                {userIds?.find(
                                  (user) => user._id === contactPoint
                                )?.middle_name &&
                                userIds?.find(
                                  (user) => user._id === contactPoint
                                )?.middle_name !== "null"
                                  ? userIds?.find(
                                      (user) => user._id === contactPoint
                                    )?.middle_name + " "
                                  : ""}
                                {
                                  userIds?.find(
                                    (user) => user._id === contactPoint
                                  )?.last_name
                                }{" "}
                              </>
                            ) : (
                              <>
                                {userIds.find(
                                  (user) => user._id === contactPoint
                                )?.company_first_name
                                  ? `${
                                      userIds.find(
                                        (user) => user._id === contactPoint
                                      )?.company_first_name
                                    } ${
                                      userIds.find(
                                        (user) => user._id === contactPoint
                                      )?.company_last_name
                                        ? userIds.find(
                                            (user) => user._id === contactPoint
                                          )?.company_last_name
                                        : ""
                                    }`
                                  : `${
                                      userIds.find(
                                        (user) => user._id === contactPoint
                                      )?.company_name || ""
                                    }(Company)`}
                              </>
                            )
                          ) : (
                            "Select Contact Point Person"
                          )}
                        </span>
                        {contactPointDropdownOpen && (
                          <div className="custom-dropdown-new">
                            <input
                              type="text"
                              className="search-bar"
                              placeholder="Search..."
                              value={userSearchTerm}
                              onChange={handleContactPointSearchInputChange}
                            />
                            <ul className="client-list">
                              {filteredUsers.length > 0 &&
                                filteredUsers
                                  .slice()
                                  .sort((a, b) => {
                                    const nameA = a.first_name
                                      ? `${a.first_name} ${
                                          a.middle_name || ""
                                        } ${a.last_name}`
                                      : `${
                                          a.company_first_name
                                            ? a.company_first_name +
                                              (a.company_last_name &&
                                                a.company_last_name)
                                            : a.company_name
                                        }`;
                                    const nameB = b.first_name
                                      ? `${b.first_name} ${
                                          b.middle_name || ""
                                        } ${b.last_name}`
                                      : `${
                                          b.company_first_name
                                            ? b.company_first_name +
                                              (b.company_last_name &&
                                                b.company_last_name)
                                            : b.company_name
                                        }`;
                                    return nameA.localeCompare(nameB);
                                  })
                                  .map((item) => (
                                    <li
                                      key={item._id}
                                      className={
                                        contactPoint === item._id
                                          ? "selected-item"
                                          : ""
                                      }
                                      onClick={() =>
                                        handleSelectContactPoint(item._id)
                                      }
                                    >
                                      {item.first_name ? (
                                        <>
                                          {item.first_name}{" "}
                                          {item.middle_name &&
                                          item.middle_name !== "null"
                                            ? item.middle_name + " "
                                            : ""}
                                          {item.last_name}{" "}
                                        </>
                                      ) : (
                                        <>
                                          {item.company_first_name
                                            ? `${item.company_first_name} ${
                                                item.company_last_name
                                                  ? item.company_last_name
                                                  : ""
                                              }`
                                            : `${
                                                item.company_name || ""
                                              }(Company)`}
                                        </>
                                      )}
                                    </li>
                                  ))}
                            </ul>
                          </div>
                        )}
                      </div>

                      {contactPointIsValid && (
                        <span className="text-red-500 text-xs italic">
                          Contact Point needs to be selected
                        </span>
                      )}
                    </div>
                    <div className="form-field-client">
                      <label htmlFor="address" className="form-label">
                        {global.config.locate(
                          appSettings && appSettings.language,
                          "Address"
                        )}
                      </label>
                      <input
                        type="text"
                        id="address"
                        name="address"
                        className="form-input"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                      />
                    </div>
                    <div className="form-field-client">
                      <label htmlFor="country" className="form-label">
                        {global.config.locate(
                          appSettings && appSettings.language,
                          "Country"
                        )}
                      </label>

                      <div
                        ref={countryDropdownRef}
                        className="select-modal-mainM custom-dropdown-user-new-outer"
                      >
                        <span
                          id="country"
                          onClick={handleToggleCountryDropdown}
                          className="custom-dropdown-user-new"
                        >
                          {country
                            ? country
                            : appSettings && appSettings.language
                            ? global.config.locate(
                                appSettings.language,
                                "Select Country"
                              )
                            : null}
                        </span>
                        {countryDropdownOpen && (
                          <div className="custom-dropdown-new">
                            <input
                              type="text"
                              className="search-bar"
                              placeholder={global.config.locate(
                                appSettings && appSettings.language,
                                "Search"
                              )}
                              value={countrySearchTerm}
                              onChange={handleCountrySearchInputChange}
                            />
                            <ul className="country-list">
                              {filteredCountries.length > 0 &&
                                filteredCountries.map((c) => (
                                  <li
                                    key={c.country}
                                    className={
                                      country === c.country
                                        ? "selected-item"
                                        : ""
                                    }
                                    onClick={() => {
                                      setCountry(c.country);
                                      setCountryDropdownOpen(false);
                                    }}
                                  >
                                    {c.country}
                                  </li>
                                ))}
                            </ul>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="form-field-client">
                      <label
                        htmlFor="additionalInformation"
                        className="form-label"
                      >
                        {global.config.locate(
                          appSettings && appSettings.language,
                          "Additional Information"
                        )}
                      </label>
                      <textarea
                        id="additionalInformation"
                        name="additionalInformation"
                        className="form-textarea"
                        rows="4"
                        value={additionalInfo}
                        onChange={(e) => setAdditionalInfo(e.target.value)}
                      ></textarea>
                    </div>
                    <button
                      type="submit"
                      className="submit-client"
                      disabled={isSubmitLoading}
                    >
                      {isSubmitLoading ? (
                        <div className="outter-load-table">
                          <ThreeDots
                            height="18"
                            width="20"
                            radius="9"
                            color="white"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                          />
                        </div>
                      ) : (
                        global.config.locate(
                          appSettings && appSettings.language,
                          "Create Client"
                        )
                      )}
                    </button>
                  </form>
                </div>
              </div>
            </Modal>
          </div>
        </div>
        <div className="search-sec">
          <div className="search-container">
            <input
              type="text"
              className="search-input"
              placeholder={global.config.locate(
                appSettings && appSettings.language,
                "Search"
              )}
              onChange={(e) => setFilter(e.target.value)}
              value={filter}
            />
          </div>
        </div>
      </div>
      <div className="client-ls-sec">
        <TimeTrackerClientListing
          filter={filter}
          updateClientList={updateClientList}
        />
      </div>
    </div>
  );
};

export default TimeTrackerClient;
