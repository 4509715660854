import moment from 'moment-timezone';
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { ThreeDots } from "react-loader-spinner";
import React, { useEffect, useState } from 'react';
import payStatusConsts from "../../../constants/invPaymetConsts";
import invStatusConsts from '../../../constants/invStatusConsts';
import tikgImage from "../../../images/singleinvoice/tick-green.svg";

const PaymentStatus = ({ inv_payment_details, invoiceId, invData }) => {

    const [paymentDate, setPaymentDate] = useState(null);
    const [paymentData, setPaymentData] = useState(null);
    const [paymentStatus, setPaymentStatus] = useState(null);
    const [isloading, setIsLoading] = useState(false);
    let token = null;
    const { user } = useSelector((state) => state.auth);
    if (user) {
        if (user && user.token) {
            token = user.token;
        }
    }
    useEffect(() => {

        const fetchPaymentDetails = async () => {
            setIsLoading(true);
            try {
                const response = await fetch('/api/invoices/paymentDetails/status', {
                    method: 'POST',
                    body: JSON.stringify({ _id: inv_payment_details }),
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    setPaymentData(data);
                    const createdDate = data.created_at;
                    setPaymentDate(createdDate);
                } else {
                    // Handle error case
                    console.error('Failed to fetch payment details');
                }
            } catch (error) {
                console.error('Error fetching payment details:', error);
                global.config.slackMessage(error.toString());
            }
        };

        if (inv_payment_details) {
            fetchPaymentDetails();
        }

    }, [inv_payment_details]);

    useEffect(() => {

        const fetchData = async () => {
           
            const response = await fetch('/api/wise/payment/status-update', {
                method: 'POST',
                body: JSON.stringify(
                    {
                        profileId: paymentData.wise_profile_id,
                        accountId: paymentData.bank_details,
                        invoiceId: paymentData.inv_number,
                        date: paymentData.created_at
                    }
                ),
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.ok) {
                const data = await response.json();

                setPaymentStatus(data.status)
            } else {
                // Handle error case
                console.error('Failed to fetch payment details');
            }
        }

        if (paymentData && typeof paymentData.in_wise !== 'undefined') {
            fetchData();
        }
        setIsLoading(false);
    }, [paymentData]);

    let statusClass;
    let statusName;
    if (invData.inv_status === invStatusConsts.Rejected && isloading === false) {
        statusClass = "overdue";
        statusName = "Rejected";
    } else if (invData.inv_payment_status === payStatusConsts.Payment_Processing && isloading === false) {
        statusClass = "sucessfully-processed";
        statusName = "Processing";
    } else if (invData.inv_payment_status === payStatusConsts.Pending && isloading === false) {
        const startDate = moment().tz(global.config.getTimeZone());
        const endDate = moment(invData.inv_due_date).tz(global.config.getTimeZone());
        const diffInDays = endDate.diff(startDate, "days");
        if (diffInDays < 0) {
            statusClass = "overdue";
            statusName = "Overdue";
        } else {
            statusClass = "pending";
            statusName = "Pending";
        }
    } else if (invData.inv_payment_status === payStatusConsts.Manually_Processed && isloading === false) {
        statusClass = "manual";
        statusName = "Manually processed";
    } else if (invData.inv_payment_status === payStatusConsts.Sucessfully_Processed && isloading === false) {
        statusClass = "sucessfully-processed";
        statusName = (
            <>
                Paid <img src={tikgImage} alt="Edit Icon" />
            </>
        );
    }else if (invData.inv_payment_status === payStatusConsts.Refunded) {
       
        statusClass = "overdue";
        statusName = "Refunded";
    } if (invData.inv_payment_status === payStatusConsts.Cancelled && isloading === false) {
        statusClass = "overdue";
        statusName = "Cancelled";
    }

    return <Link className="Approve-singlebtn" style={{ color: (paymentStatus === "cancelled" || invData.inv_payment_status === payStatusConsts.Cancelled) && "red", borderColor: (paymentStatus === "cancelled" || invData.inv_payment_status === payStatusConsts.Cancelled) && "red" }}>
        {(paymentStatus === null && isloading === true) ? <ThreeDots
            height="18"
            width="20"
            radius="9"
            color="#6479f9"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
        /> : ((paymentStatus !== null && isloading === false) ? (paymentStatus === "incoming_payment_waiting" && "Processing" || paymentStatus === "outgoing_payment_sent" && "Paid" || paymentStatus === "cancelled" && "Cancelled"|| paymentStatus === "funds_refunded" && "Refunded") : statusName)} {paymentStatus !== null && (paymentStatus !== "cancelled" ) && <img src={tikgImage} alt="Edit Icon" />}</Link>;
};

export default PaymentStatus;