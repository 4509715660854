import React, { useEffect, useState } from "react";
import "./ReportingOverview.css"
import ReportOverviewFilter from "../../components/Reporting/ReportingOverview/ReprtingOverviewFilter";
import { useDispatch, useSelector } from "react-redux";
import { setHeaderText } from "../../features/headerText/headerTextSlice";
import { ThreeDots } from "react-loader-spinner";
import ReportOverviewTotalbilled from "../../components/Reporting/ReportingOverview/ReportingOverviewTotalbilled";
import ReportOverviewHighestRoi from "../../components/Reporting/ReportingOverview/ReprtingOverviewHighestRoi";
import ReportOverviewLowestRoi from "../../components/Reporting/ReportingOverview/ReprtingOverviewLowestRoi";
import ReportClientComparionGraph from "../../components/Reporting/ReportingOverview/ReportingOverviewClientComparisonGraph";
import ReportClientComparisonGraphFilter from "../../components/Reporting/ReportingOverview/GraphFilters/HorizontalBarChart/HorizontalBarChart";
import LineChart from "../../components/Reporting/ReportingOverview/GraphFilters/LineChart/LineChart";
import ReportOverviewRoiOverviewPerClient from "../../components/Reporting/ReportingOverview/ReportingOverviewRoiOverviewPerClient"
import ReportOverviewHoursPerClientGraph from "../../components/Reporting/ReportingOverview/ReportingOverviewHoursPerClientGraph"
import ReportOverviewHoursPerClientFilter from "../../components/Reporting/ReportingOverview/ReportingOverviewHoursPerClientFilter"
import ReportOverviewRoiOverviewPerProject from "../../components/Reporting/ReportingOverview/ReportingOverviewRoiOverviewPerProject"
import { useFetchProjectDetails } from "../../components/TimeTracker/TimeTrackerProject/useProjectDetails";
import { useGetClients } from "../../components/TimeTracker/TimeTrackerOverview/useTimeTracker";
import { useFetchTrackReportDetails, useFetchClientInvoices, useFetchExpenses, useFetchSprints, useFetchProjectCosts } from "../../features/reporting/reportingOverview/useReportingOverview";
import HorizontalBarChart from "../../components/Reporting/ReportingOverview/GraphFilters/HorizontalBarChart/HorizontalBarChart";

function ReportingOverview() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [isGraphLoaded, setIsGraphLoaded] = useState(false);
  let token = null;
  if (user) {
    if (user && user.token) {
      token = user.token;
    }
  }

  const { data: project = [], isLoading, refetch: refetchProjects } = useFetchProjectDetails(token);
  const { data: clients = [], isLoading: clientLoading, isError: clientError } = useGetClients(token);
  const { data: trackReport = [], isLoading: trackReportLoading, isError: trackReportError } = useFetchTrackReportDetails(token);
  const { data: sprints = [], isLoading: sprintLoading, isError: sprintError } = useFetchSprints(token);
  const { data: projectCost = [], isLoading: projectCostLoading, isError: projectCostError } = useFetchProjectCosts(token);
  const { data: clientInvoices = [], isLoading: clientInvoicesLoading, isError: clientInvoicesError } = useFetchClientInvoices(token);
  const { data: expenses = [], isLoading: expensesLoading, isError: expensesError } = useFetchExpenses(token);



  useEffect(() => {
    dispatch(setHeaderText("Reporting Overview"));
    const timer = setTimeout(() => {
      setIsGraphLoaded(true);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);
  return (
    <div className="react-outer">
      <div className="invoice-outer-invoice invoice-outer-invoice-reprt">
        <ReportOverviewFilter project={project} clients={clients} trackReport={trackReport} projectCost={projectCost} sprints={sprints} clientInvoices={clientInvoices} expenses={expenses} />
      </div>
      <div className="repot-tatal-box-sec">
        <ReportOverviewTotalbilled clientInvoices={clientInvoices} />
        <ReportOverviewHighestRoi trackReport={trackReport} projectCost={projectCost} clientInvoices={clientInvoices} expenses={expenses} />
        <ReportOverviewLowestRoi trackReport={trackReport} projectCost={projectCost} clientInvoices={clientInvoices} expenses={expenses} />
        <div></div>
      </div>
      <div className="outer-report-graph-piechart">
        <div className="graph-main-outer-time graph-main-outer-time-report border-line-bottom">
          <div className="top-graph">
            {/* <ReportClientComparisonGraphFilter clientInvoices={clientInvoices} /> */}
            <LineChart
              clientInvoices={clientInvoices}
              clientInvoicesLoading={clientInvoicesLoading}
              clientInvoicesError={clientInvoicesError}
            />
          </div>
          <ReportClientComparionGraph
            clientInvoices={clientInvoices}
            clientInvoicesLoading={clientInvoicesLoading}
            clientInvoicesError={clientInvoicesError}
          />
        </div>
        <div className="pie-chart-right">
          <ReportOverviewRoiOverviewPerClient trackReport={trackReport} projectCost={projectCost} clientInvoices={clientInvoices} expenses={expenses} />
        </div>
      </div>
      <div className="outer-report-graph-piechart">
        <div className="graph-main-outer-time graph-main-outer-time-report">
          <div className="top-graph">
            {/* <ReportOverviewHoursPerClientFilter /> */}
            <HorizontalBarChart
              trackReport={trackReport}
              trackReportLoading={trackReportLoading}
              trackReportError={trackReportError}
            />
          </div>
          {isGraphLoaded ? (
            <ReportOverviewHoursPerClientGraph
              trackReport={trackReport}
              trackReportLoading={trackReportLoading}
              trackReportError={trackReportError}
            />
          ) : (
            <div className="outter-load-table">
              <ThreeDots
                height="80"
                width="80"
                radius="9"
                color="#6479f9"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            </div>
          )}
        </div>
        <div className="pie-chart-right">
          <ReportOverviewRoiOverviewPerProject trackReport={trackReport} projectCost={projectCost} sprints={sprints} />
        </div>
      </div>
    </div>
  );
}

export default ReportingOverview;
