import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import notificationService from "./notificationService";

const initialState = {
  notificationState: null
};

export const setNotification = createAsyncThunk(
  "notification/setNotification",
  async (notification) => {
    return notification;
  }
);

export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    reset: (state) => {
      state.notificationState = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setNotification.fulfilled, (state, action) => {
      state.notificationState = action.payload;
    });
  },
});

export const { reset } = notificationSlice.actions;
export default notificationSlice.reducer;
