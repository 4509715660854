import "./GeneralSettings.css"
import React, { useEffect, useState, useRef } from "react";
import moment from 'moment-timezone';
import { getAllISOCodes } from 'iso-country-currency';
import axios from "axios";
import { useSelector, useDispatch } from 'react-redux';
import { useQuery } from 'react-query';
import { toast } from "react-toastify";
import { setAppSettings } from "../../../features/appSettings/appSettingsSlice.js";
import currencyCodes from "currency-codes";
import { ThreeDots } from "react-loader-spinner";

const GeneralSettings = (props) => {
    const [currencyDropdownOpen, setCurrencyDropdownOpen] = useState(false);
    const [selectedCurrency, setSelectedCurrency] = useState(null);
    const currencyDropdownRef = useRef(null);
    const [timezoneDropdownOpen, setTimezoneDropdownOpen] = useState(false);
    const [selectedTimezone, setSelectedTimezone] = useState(null);
    const timezoneDropdownRef = useRef(null);
    const [languageDropdownOpen, setLanguageDropdownOpen] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState(null);
    const languageDropdownRef = useRef(null);
    const [weekStartDropdownOpen, setWeekStartDropdownOpen] = useState(false);
    const [selectedWeekStart, setSelectedWeekStart] = useState(null);
    const weekStartDropdownRef = useRef(null);
    const [lockTimeDropdownOpen, setLockTimeDropdownOpen] = useState(false);
    const [selectedLockTime, setSelectedLockTime] = useState(null);
    const lockTimeDropdownRef = useRef(null);
    const [timeZoneSearchTerm, setTimeZoneSearchTerm] = useState('');
    const [timeZones, setTimeZones] = useState([]);
    const [currencySearchTerm, setCurrencySearchTerm] = useState('');
    const [currencies, setCurrencies] = useState([]);
    const [locktime, setLocktime] = useState(2);
    const [lockTimertimeDropdownOpen, setLockTimertimeDropdownOpen] = useState(false);
    const [selectedTime, setSelectedTime] = useState('11:00 p.m');
    const [seoTitle, setAppSeoTitle] = useState(null);
    const [seoDescription, setAppSeoDescription] = useState(null);
    const dispatch = useDispatch();
    const lockTimerTimeDropdownRef = useRef(null);
    // const locale = global.config.locate();

    const timeList = [
        {
            time_value: 0,
            time: "12:00 AM",
        },
        {
            time_value: 0.5,
            time: "12:30 AM",
        },
        {
            time_value: 1,
            time: "01:00 AM",
        },
        {
            time_value: 1.5,
            time: "01:30 AM",
        },
        {
            time_value: 2,
            time: "02:00 AM",
        },
        {
            time_value: 2.5,
            time: "02:30 AM",
        },
        {
            time_value: 3,
            time: "03:00 AM",
        },
        {
            time_value: 3.5,
            time: "03:30 AM",
        },
        {
            time_value: 4,
            time: "04:00 AM",
        },
        {
            time_value: 4.5,
            time: "04:30 AM",
        },
        {
            time_value: 5,
            time: "05:00 AM",
        },
        {
            time_value: 5.5,
            time: "05:30 AM",
        },
        {
            time_value: 6,
            time: "06:00 AM",
        },
        {
            time_value: 6.5,
            time: "06:30 AM",
        },
        {
            time_value: 7,
            time: "07:00 AM",
        },
        {
            time_value: 7.5,
            time: "07:30 AM",
        },
        {
            time_value: 8,
            time: "08:00 AM",
        },
        {
            time_value: 8.5,
            time: "08:30 AM",
        },
        {
            time_value: 9,
            time: "09:00 AM",
        },
        {
            time_value: 9.5,
            time: "09:30 AM",
        },
        {
            time_value: 10,
            time: "10:00 AM",
        },
        {
            time_value: 10.5,
            time: "10:30 AM",
        },
        {
            time_value: 11,
            time: "11:00 AM",
        },
        {
            time_value: 11.5,
            time: "11:30 AM",
        },
        {
            time_value: 12,
            time: "12:00 PM",
        },
        {
            time_value: 12.5,
            time: "12:30 PM",
        },
        {
            time_value: 13,
            time: "01:00 PM",
        },
        {
            time_value: 13.5,
            time: "01:30 PM",
        },
        {
            time_value: 14,
            time: "02:00 PM",
        },
        {
            time_value: 14.5,
            time: "02:30 PM",
        },
        {
            time_value: 15,
            time: "03:00 PM",
        },
        {
            time_value: 15.5,
            time: "03:30 PM",
        },
        {
            time_value: 16,
            time: "04:00 PM",
        },
        {
            time_value: 16.5,
            time: "04:30 PM",
        },
        {
            time_value: 17,
            time: "05:00 PM",
        },
        {
            time_value: 17.5,
            time: "05:30 PM",
        },
        {
            time_value: 18,
            time: "06:00 PM",
        },
        {
            time_value: 18.5,
            time: "06:30 PM",
        },
        {
            time_value: 19,
            time: "07:00 PM",
        },
        {
            time_value: 19.5,
            time: "07:30 PM",
        },
        {
            time_value: 20,
            time: "08:00 PM",
        },
        {
            time_value: 20.5,
            time: "08:30 PM",
        },
        {
            time_value: 21,
            time: "09:00 PM",
        },
        {
            time_value: 21.5,
            time: "09:30 PM",
        },
        {
            time_value: 22,
            time: "10:00 PM",
        },
        {
            time_value: 22.5,
            time: "10:30 PM",
        },
        {
            time_value: 23,
            time: "11:00 PM",
        },
        {
            time_value: 23.5,
            time: "11:30 PM",
        },
    ];
    let token = null;
    const { user } = useSelector((state) => state.auth);
    const { appSettings } = useSelector((state) => state.appSettings);
    if (user) {
        if (user && user.token) {
            token = user.token;
        }
    }

    const fetchData = async () => {
        const response = await axios.get(
            `/api/get/app-settings/${appSettings && appSettings.appId}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        return response.data;
    };

    const { data, isLoading, error, refetch } = useQuery('exampleQueryKey', fetchData);

    useEffect(() => {
        setSelectedCurrency(data && data.currency);
        setSelectedTimezone(data && data.timeZone);
        setSelectedLanguage(data && data.language);
        setSelectedLockTime(data && data.timerLockOption);
        setAppSeoTitle(data && data.seoTitle);
        setAppSeoDescription(data && data.metaDescription);
        setLocktime(data && data.timerLock);
        setSelectedTime(data && data.timerLockTime);
        setSelectedWeekStart(data && data.weekStartDay);
        refetch();
    }, [isLoading, dispatch, data]);

    useEffect(() => {
        refetch();
    }, [selectedCurrency, selectedTimezone, selectedLanguage, selectedLockTime, seoTitle, seoDescription, locktime])

    const toggleCurrencyDropdown = () => {
        setCurrencyDropdownOpen(!currencyDropdownOpen);
    };

    const selectCurrencyOption = async (selectedCurrency) => {
        setSelectedCurrency(selectedCurrency);

        const updatedSettings = { ...appSettings, currency: selectedCurrency };
        await dispatch(setAppSettings(updatedSettings));

        setCurrencyDropdownOpen(false);
        try {

            const response = await axios.post(
                `/api/post/app-settings`,
                {
                    timeZone: null,
                    currency: selectedCurrency,
                    language: null,
                    timerLock: null,
                    timerLockTime: null,
                    seoTitle: null,
                    metaDescription: null,
                    weekStartDay: null,
                    appId: appSettings && appSettings.appId,

                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            refetch();
        } catch (error) {
            console.log(error)
            global.config.slackMessage(error.toString());
        }

        toast.success("Settings updated successfully", {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    const toggleTimezoneDropdown = () => {
        setTimezoneDropdownOpen(!timezoneDropdownOpen);
    };

    const selectTimezoneOption = async (selectedTimezone) => {
        setSelectedTimezone(selectedTimezone);
        const updatedSettings = { ...appSettings, timeZone: selectedTimezone };
        await dispatch(setAppSettings(updatedSettings));
        setTimezoneDropdownOpen(false);
        try {

            const response = await axios.post(
                `/api/post/app-settings`,
                {
                    timeZone: selectedTimezone,
                    currency: null,
                    language: null,
                    timerLock: null,
                    timerLockTime: null,
                    seoTitle: null,
                    metaDescription: null,
                    weekStartDay: null,
                    appId: appSettings && appSettings.appId,

                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            toast.success("Settings updated successfully", {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            refetch();
        } catch (error) {
            console.log(error)
            global.config.slackMessage(error.toString());
        }


    };

    const toggleLanguageDropdown = () => {
        setLanguageDropdownOpen(!languageDropdownOpen);
    };

    const toggleWeekStartDropdown = () => {
        setWeekStartDropdownOpen(!weekStartDropdownOpen);
    };

    const selectLanguageOption = async (selectedLanguage) => {
        setSelectedLanguage(selectedLanguage);
        const updatedSettings = { ...appSettings, language: selectedLanguage };
        await dispatch(setAppSettings(updatedSettings));
        setLanguageDropdownOpen(false);

        try {

            const response = await axios.post(
                `/api/post/app-settings`,
                {
                    timeZone: null,
                    currency: null,
                    language: selectedLanguage,
                    timerLock: null,
                    timerLockTime: null,
                    seoTitle: null,
                    metaDescription: null,
                    timerLockOption: null,
                    weekStartDay: null,
                    appId: appSettings && appSettings.appId,

                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            toast.success("Settings updated successfully", {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            refetch();
        } catch (error) {
            console.log(error)
            global.config.slackMessage(error.toString());
        }

    };

    const selectWeekStartOption = async (selectedWeekStart) => {
        setSelectedWeekStart(selectedWeekStart);
        const updatedSettings = { ...appSettings, weekStartDay: selectedWeekStart };
        await dispatch(setAppSettings(updatedSettings));
        setWeekStartDropdownOpen(false);

        try {

            const response = await axios.post(
                `/api/post/app-settings`,
                {
                    timeZone: null,
                    currency: null,
                    language: null,
                    timerLock: null,
                    timerLockTime: null,
                    seoTitle: null,
                    metaDescription: null,
                    timerLockOption: null,
                    weekStartDay: selectedWeekStart,
                    appId: appSettings && appSettings.appId,

                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            toast.success("Settings updated successfully", {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            refetch();
        } catch (error) {
            console.log(error)
            global.config.slackMessage(error.toString());
        }

    };

    const toggleLockTimeDropdown = () => {
        setLockTimeDropdownOpen(!lockTimeDropdownOpen);
    };

    const selectLockTimeOption = async (selectedLockTime) => {
        setSelectedLockTime(selectedLockTime);
        const updatedSettings = { ...appSettings, timerLockOption: selectedLockTime };
        await dispatch(setAppSettings(updatedSettings));
        setLockTimeDropdownOpen(false);
        try {

            const response = await axios.post(
                `/api/post/app-settings`,
                {
                    timeZone: null,
                    currency: null,
                    language: null,
                    timerLock: null,
                    timerLockTime: null,
                    seoTitle: null,
                    metaDescription: null,
                    timerLockOption: selectedLockTime,
                    weekStartDay: null,
                    appId: appSettings && appSettings.appId,

                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            toast.success("Settings updated successfully", {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            refetch();
        } catch (error) {
            console.log(error)
            global.config.slackMessage(error.toString());
        }

    };


    useEffect(() => {
        const fetchTimeZones = () => {
            // Get the time zone names using the Intl.DateTimeFormat object
            const allTimeZones = moment.tz.names();
            const allCurrencies = getAllISOCodes();

            // Set the time zones in the state
            setTimeZones(allTimeZones);
            setCurrencies(allCurrencies);
        };

        // Call the fetchTimeZones function
        fetchTimeZones();
    }, []); // Empty dependency array to run the effect only once on mount

    const handleCurrencyFilterChange = (e) => {
        setCurrencySearchTerm(e.target.value);
    };

    const codeToCurrency = (code) => {
        var currencyCode = code ? code : 'USD';
        try {
            var currencyCode1 = currencyCodes.code(currencyCode).currency;
            return currencyCode1.toString(); // Ensure it's a string
        } catch (error) {
            return code.toString(); // Ensure it's a string
        }
    }

    const filteredCurrencies = currencies && currencies.filter((currency) => {
        const currencyName = codeToCurrency(currency.currency && currency.currency);
        return currencyName?.toLowerCase().includes(currencySearchTerm?.toLowerCase());
    });

    const handleTimeZoneFilterChange = (e) => {
        setTimeZoneSearchTerm(e.target.value);
    };

    const filteredTimeZone = timeZones && timeZones.filter((timeZone) => {
        return timeZone?.toLowerCase().includes(timeZoneSearchTerm?.toLowerCase());
    });

    const handleClickOutsideDropdown = (event) => {
        const isClickInsideTimezoneDropdown = timezoneDropdownRef.current && timezoneDropdownRef.current.contains(event.target);
        const isClickInsideCurrencyDropdown = currencyDropdownRef.current && currencyDropdownRef.current.contains(event.target);
        const isClickInsideLanguageDropdown = languageDropdownRef.current && languageDropdownRef.current.contains(event.target);
        const isClickInsideWeekStartDropdown = weekStartDropdownRef.current && weekStartDropdownRef.current.contains(event.target);
        const isClickInsideLockTimeDropdown = lockTimeDropdownRef.current && lockTimeDropdownRef.current.contains(event.target);
        const isClickInsideLockTimerTimeDropdown = lockTimerTimeDropdownRef.current && lockTimerTimeDropdownRef.current.contains(event.target);
        if (!isClickInsideTimezoneDropdown) {
            setTimezoneDropdownOpen(false);
        }
        if (!isClickInsideCurrencyDropdown) {
            setCurrencyDropdownOpen(false);
        }
        if (!isClickInsideLanguageDropdown) {
            setLanguageDropdownOpen(false);
        }
        if (!isClickInsideWeekStartDropdown) {
            setWeekStartDropdownOpen(false);
        }
        if (!isClickInsideLockTimeDropdown) {
            setLockTimeDropdownOpen(false);
        }
        if (!isClickInsideLockTimerTimeDropdown) {
            setLockTimertimeDropdownOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutsideDropdown);
        return () => {
            document.removeEventListener('click', handleClickOutsideDropdown);
        };
    }, []);



    const setSeoTitle = async (seoTitle) => {

        setAppSeoTitle(seoTitle)
        try {

            const response = await axios.post(
                `/api/post/app-settings`,
                {
                    timeZone: null,
                    currency: null,
                    language: null,
                    timerLock: null,
                    timerLockTime: null,
                    seoTitle: seoTitle,
                    metaDescription: null,
                    weekStartDay: null,
                    appId: appSettings && appSettings.appId,

                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            refetch();
        } catch (error) {
            console.log(error)
            global.config.slackMessage(error.toString());
        }
    }

    const setSeoDescription = async (seoDescription) => {
        setAppSeoDescription(seoDescription);

        try {

            const response = await axios.post(
                `/api/post/app-settings`,
                {
                    timeZone: null,
                    currency: null,
                    language: null,
                    timerLock: null,
                    timerLockTime: null,
                    seoTitle: null,
                    metaDescription: seoDescription,
                    weekStartDay: null,
                    appId: appSettings && appSettings.appId,

                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            refetch();
        } catch (error) {
            console.log(error)
            global.config.slackMessage(error.toString());
        }
    }

    const setLockTimeUpdate = async (lockTime) => {
        setLocktime(lockTime);
        if (lockTime) {
            const updatedSettings = { ...appSettings, timerLock: lockTime };
            await dispatch(setAppSettings(updatedSettings));
            try {
                const response = await axios.post(
                    `/api/post/app-settings`,
                    {
                        timeZone: null,
                        currency: null,
                        language: null,
                        timerLock: lockTime,
                        timerLockTime: null,
                        seoTitle: null,
                        metaDescription: null,
                        weekStartDay: null,
                        appId: appSettings && appSettings.appId,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );

                toast.success("Time lock has been updated successfully", {
                    position: "bottom-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                refetch();
            } catch (error) {
                console.log(error)
                global.config.slackMessage(error.toString());
            }
        }
    }

    const setLockTimerTimeUpdate = async (lockTime) => {
        setSelectedTime(lockTime);
        const updatedSettings = { ...appSettings, timerLockTime: selectedTime };
        await dispatch(setAppSettings(updatedSettings));
        if (lockTime) {

            try {
                const response = await axios.post(
                    `/api/post/app-settings`,
                    {
                        timeZone: null,
                        currency: null,
                        language: null,
                        timerLock: null,
                        timerLockTime: lockTime,
                        seoTitle: null,
                        metaDescription: null,
                        weekStartDay: null,
                        appId: appSettings && appSettings.appId,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );

                toast.success("Time lock has been updated successfully", {
                    position: "bottom-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                refetch();
            } catch (error) {
                console.log(error)
                global.config.slackMessage(error.toString());
            }
        }
    }

    const handleTimeSelection = (time) => {
        setSelectedTime(time);
        setLockTimertimeDropdownOpen(false); // Exit edit mode after selecting a time
        // Call function to update the time
        setLockTimerTimeUpdate(time); // This function should be defined elsewhere
    };

    const toggleLockTimer = () => {
        setLockTimertimeDropdownOpen(!lockTimertimeDropdownOpen);
    };

    const CodeToCurrency = (code) => {

        var currencyCode = code ? code : 'USD';

        if (currencyCode !== "undefined") {

            var currencyCode = currencyCodes.code(currencyCode);

        }

        return currencyCode;
    }

    const numberInputOnWheelPreventChange = (e) => {
        // Prevent the input value change
        e.target.blur()

        // Prevent the page/container scrolling
        e.stopPropagation()

        setTimeout(() => {
            e.target.focus()
        }, 0)
    }

    return (
        <div>
            <div>
                <div>
                    <div className="security-outer noti-outer">
                        <div class="security-inner noti-enable noti-head-noti">
                            <span class="custom-switch-description">{global.config.locate(appSettings && appSettings.language, 'Timezone_and_Language')}</span>
                        </div>

                        <div className="security-inner noti-enable">
                            <span className="custom-switch-description">
                                {global.config.locate(appSettings && appSettings.language, 'Default_Timezone')}
                            </span>
                            <div className="custom-dropdown">
                                {isLoading ?
                                    <div className="outter-load-table">
                                        <ThreeDots
                                            height="18"
                                            width="20"
                                            radius="9"
                                            color="#6479f9"
                                            ariaLabel="three-dots-loading"
                                            wrapperStyle={{}}
                                            wrapperClassName=""
                                            visible={true}
                                        />
                                    </div>
                                    :
                                    <div ref={timezoneDropdownRef} className={`custom-dropdown-user-new-outer custom-dropdown-select ${timezoneDropdownOpen ? 'open' : ''}`}>
                                        <div className="selected-option custom-dropdown-user-new" onClick={toggleTimezoneDropdown}>
                                            {selectedTimezone ? selectedTimezone : (appSettings && appSettings.language ? global.config.locate(appSettings.language, 'Select_Timezone') : null)}
                                        </div>
                                        {timezoneDropdownOpen && (
                                            <div className="custom-dropdown-new">
                                                <input
                                                    type="text"
                                                    className="search-bar"
                                                    placeholder={global.config.locate(appSettings && appSettings.language, 'Search_Timezone')}
                                                    value={timeZoneSearchTerm}
                                                    onChange={handleTimeZoneFilterChange}
                                                />
                                                <ul className="type-list">
                                                    {filteredTimeZone.sort((a, b) => a.localeCompare(b)).map((timeZone, index) => (
                                                        <li onClick={() => selectTimezoneOption(timeZone)} key={index}>{timeZone}</li>
                                                    ))}
                                                </ul>
                                            </div>
                                        )}
                                    </div>
                                }
                            </div>
                        </div>

                        <div className="security-inner noti-enable">
                            <span className="custom-switch-description">
                                {global.config.locate(appSettings && appSettings.language, 'Default_Currency')}
                            </span>
                            <div className="custom-dropdown">
                                {isLoading ?
                                    <div className="outter-load-table">
                                        <ThreeDots
                                            height="18"
                                            width="20"
                                            radius="9"
                                            color="#6479f9"
                                            ariaLabel="three-dots-loading"
                                            wrapperStyle={{}}
                                            wrapperClassName=""
                                            visible={true}
                                        />
                                    </div>
                                    :
                                    <div ref={currencyDropdownRef} className={`custom-dropdown-user-new-outer custom-dropdown-select ${currencyDropdownOpen ? 'open' : ''}`}>
                                        <div className="selected-option custom-dropdown-user-new" onClick={toggleCurrencyDropdown}>
                                            {selectedCurrency ? selectedCurrency : (appSettings && appSettings.language ? global.config.locate(appSettings.language, 'Select_Currency') : null)}
                                        </div>
                                        {currencyDropdownOpen && (
                                            <div className="custom-dropdown-new">
                                                <input
                                                    type="text"
                                                    className="search-bar"
                                                    placeholder={global.config.locate(appSettings && appSettings.language, 'Search_Currency')}
                                                    value={currencySearchTerm}
                                                    onChange={handleCurrencyFilterChange}
                                                />
                                                <ul className="type-list">
                                                    {filteredCurrencies
                                                        .reduce((uniqueCurrencies, currency) => {
                                                            const currencyName = codeToCurrency(currency.currency && currency.currency);
                                                            if (!uniqueCurrencies.find(item => item.name === currencyName)) {
                                                                uniqueCurrencies.push({ name: currencyName, currency: currency.currency }); // Push the currency object along with its name
                                                            }
                                                            return uniqueCurrencies;
                                                        }, [])
                                                        .sort((a, b) => a.name.localeCompare(b.name))
                                                        .map((currency, index) => (
                                                            <li onClick={() => selectCurrencyOption(currency.currency)} key={index}>
                                                                {currency.name}
                                                            </li>
                                                        ))}
                                                </ul>
                                            </div>
                                        )}
                                    </div>
                                }
                            </div>
                        </div>

                        <div className="security-inner noti-enable">
                            <span className="custom-switch-description">
                                {global.config.locate(appSettings && appSettings.language, 'Default_Language')}
                            </span>
                            <div className="custom-dropdown">
                                {isLoading ?
                                    <div className="outter-load-table">
                                        <ThreeDots
                                            height="18"
                                            width="20"
                                            radius="9"
                                            color="#6479f9"
                                            ariaLabel="three-dots-loading"
                                            wrapperStyle={{}}
                                            wrapperClassName=""
                                            visible={true}
                                        />
                                    </div>
                                    :
                                    <div ref={languageDropdownRef} className={`custom-dropdown-user-new-outer custom-dropdown-select ${languageDropdownOpen ? 'open' : ''}`}>
                                        <div className="selected-option custom-dropdown-user-new" onClick={toggleLanguageDropdown}>
                                            {selectedLanguage ? selectedLanguage : (appSettings && appSettings.language ? global.config.locate(appSettings.language, 'Select_Language') : null)}
                                        </div>
                                        {languageDropdownOpen && (
                                            <div className="custom-dropdown-new">
                                                <ul className="type-list">
                                                    <li onClick={() => selectLanguageOption('en')}>{global.config.locate(appSettings && appSettings.language, 'English')}</li>
                                                    <li onClick={() => selectLanguageOption('es')}>{global.config.locate(appSettings && appSettings.language, 'Spanish')}</li>
                                                </ul>
                                            </div>
                                        )}
                                    </div>
                                }
                                <span className="custom-arrow"></span>
                            </div>
                        </div>

                        <div className="security-inner noti-enable">
                            <span className="custom-switch-description">
                                {global.config.locate(appSettings && appSettings.language, 'Work week start day')}
                            </span>
                            <div className="custom-dropdown">
                                {isLoading ?
                                    <div className="outter-load-table">
                                        <ThreeDots
                                            height="18"
                                            width="20"
                                            radius="9"
                                            color="#6479f9"
                                            ariaLabel="three-dots-loading"
                                            wrapperStyle={{}}
                                            wrapperClassName=""
                                            visible={true}
                                        />
                                    </div>
                                    :
                                    <div ref={weekStartDropdownRef} className={`custom-dropdown-user-new-outer custom-dropdown-select ${weekStartDropdownOpen ? 'open' : ''}`}>
                                        <div className="selected-option custom-dropdown-user-new" onClick={toggleWeekStartDropdown}>
                                            {selectedWeekStart ? selectedWeekStart : (appSettings && appSettings.language ? global.config.locate(appSettings.language, 'Select_Week_Start_Day') : null)}
                                        </div>
                                        {weekStartDropdownOpen && (
                                            <div className="custom-dropdown-new">
                                                <ul className="type-list">
                                                    <li onClick={() => selectWeekStartOption('Sunday')}>{global.config.locate(appSettings && appSettings.language, 'Sunday')}</li>
                                                    <li onClick={() => selectWeekStartOption('Monday')}>{global.config.locate(appSettings && appSettings.language, 'Monday')}</li>
                                                </ul>
                                            </div>
                                        )}
                                    </div>
                                }
                                <span className="custom-arrow"></span>
                            </div>
                        </div>
                    </div>

                    <div className="security-outer noti-outer tracker-settings">
                        <div class="security-inner noti-enable noti-head-noti">
                            <span class="custom-switch-description">{global.config.locate(appSettings && appSettings.language, 'Time_Tracking')}</span>
                        </div>
                        <div className="security-inner noti-enable">
                            <span className="custom-switch-description">
                                {global.config.locate(appSettings && appSettings.language, 'Lock_Time')}
                            </span>
                            <div className="tracker-setting-outer">
                                <div className="custom-dropdown">
                                    <div ref={lockTimeDropdownRef} className="custom-dropdown-user-new-outer custom-dropdown-select default-time-lesser">
                                        <div className="selected-option custom-dropdown-user-new default-time-lesser-inner">
                                            {global.config.locate(appSettings && appSettings.language, 'Older_Than')}
                                        </div>
                                    </div>
                                </div>

                                <div className="number-of">
                                    <div className="number-of-input" >
                                        <input type="number" min="0" onChange={(e) => setLockTimeUpdate(e.target.value)} value={locktime} onWheel={numberInputOnWheelPreventChange} />
                                        <div className="day-count">{locktime > 1 ? global.config.locate((appSettings && appSettings.language), 'Days') : global.config.locate((appSettings && appSettings.language), 'Day')}</div>
                                        <div className="at">{global.config.locate(appSettings && appSettings.language, 'at')}</div>
                                        <div className="lock-time-hour">
                                            <div ref={lockTimerTimeDropdownRef} className={`custom-dropdown-user-new-outer custom-dropdown-select ${lockTimertimeDropdownOpen ? 'open' : ''}`}>
                                                <div className="selected-option custom-dropdown-user-new" onClick={toggleLockTimer}>
                                                    {selectedTime ? selectedTime : (appSettings && appSettings.language ? global.config.locate(appSettings.language, 'Select_Lock_Time') : null)}
                                                </div>
                                                {lockTimertimeDropdownOpen && (
                                                    <div className="custom-dropdown-new">
                                                        <ul className="time-list">
                                                            {timeList.map((time) => (
                                                                <li
                                                                    key={time.time_value}
                                                                    className={selectedTime === time.time ? 'selected-item' : ''}
                                                                    onClick={() => handleTimeSelection(time.time)}
                                                                >
                                                                    {time.time}
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="security-outer noti-outer seo-settings">
                        <div class="security-inner noti-enable noti-head-noti">
                            <span class="custom-switch-description">{global.config.locate(appSettings && appSettings.language, 'SEO')}</span>
                        </div>
                        <div className="security-inner noti-enable">
                            <span className="custom-switch-description">
                                {global.config.locate(appSettings && appSettings.language, 'SEO_Title')}
                            </span>
                            <div className="seo-input" >
                                <input type="text" style={{ border: seoTitle === "" ? "1px solid red" : "", backgroundColor: seoTitle === "" ? "#fff6f6" : "" }} placeholder="Text here" value={seoTitle} onPaste={(e) => setSeoTitle(e.clipboardData.getData('text'))} onChange={(e) => setSeoTitle(e.target.value)} />
                            </div>
                        </div>
                        <div className="security-inner noti-enable">
                            <span className="custom-switch-description">
                                {global.config.locate(appSettings && appSettings.language, 'Meta_Description')}
                            </span>
                            <div className="seo-input">
                                <textarea type="text" style={{ border: seoDescription === "" ? "1px solid red" : "", backgroundColor: seoDescription === "" ? "#fff6f6" : "" }} value={seoDescription} onPaste={(e) => setSeoDescription(e.clipboardData.getData('text'))} onChange={(e) => setSeoDescription(e.target.value)} placeholder="Text here" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GeneralSettings;