import React from "react";
import ClientProfile from "../../../components/Clients/ClientProfile/ClientProfile";

function ClientProfileSection()  {
    return(
        <div className="profile-section-outer">
            <ClientProfile />
        </div>
    )
}

export default ClientProfileSection;