import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ThreeDots } from "react-loader-spinner";
// import 'primereact/resources/themes/saga-blue/theme.css';
// import 'primereact/resources/primereact.min.css';
// import 'primeicons/primeicons.css';

const RecentExpense = ({ expenseData, expenseDataLoading, expenseDataError }) => {
    const reportingFinancialExpense = useSelector((state) => state.reportingExpense);
    const [filteredData, setFilteredData] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);

    useEffect(() => {
        if (expenseData && reportingFinancialExpense) {
            const filtered = expenseData.filter(expense =>
                !expense.is_delete &&
                reportingFinancialExpense.expCategory && reportingFinancialExpense.expCategory.includes(expense.exp_category._id) &&
                reportingFinancialExpense.expYear && new Date(expense.exp_date).getFullYear() === reportingFinancialExpense.expYear &&
                reportingFinancialExpense.expCurrency && reportingFinancialExpense.expCurrency.includes(expense.exp_currency)
            ).map(expense => ({
                exp_id: expense.exp_id,
                exp_category_name: expense.exp_category.exp_category_name,
                client_name: expense.exp_client ? expense.exp_client.client_name || expense.exp_client.legal_entity_name : 'Non Billable',
                exp_date: expense.exp_date,
                exp_is_billable: expense.exp_is_billable ? 'Yes' : 'No',
                amount: expense.exp_amount,
                currency: expense.exp_currency,
                created_at: expense.created_at
            }));
            const sortedData = filtered.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
            setFilteredData(sortedData);
        }
    }, [expenseData, reportingFinancialExpense]);

    const onPageChange = (e) => {
        setCurrentPage(e.first / rowsPerPage);
    };

    const renderStatus = (rowData) => {
        const status = rowData.exp_is_billable;
        const statusClass = status === 'Yes' ? 'payment-status manual' : 'payment-status overdue';
        return <span className={`payment-status ${statusClass}`}>{status}</span>;
    };

    const renderDueDate = (rowData) => {
        const dueDate = new Date(rowData.exp_date);
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return dueDate.toLocaleDateString(undefined, options);
    };

    const renderAmount = (rowData) => {
        var totalAmount = rowData.amount.toLocaleString("en-US", {
            style: "currency",
            currency: rowData.currency || 'USD',
        });

        return `${totalAmount}`;
    };

    return (
        <div className="datatable-team-maintb">
            <DataTable
                value={filteredData.slice(0, 5)}
                sort
                tableStyle={{ minWidth: '50rem', width: '100%', textAlign: 'left' }}
                paginator={false}
                // paginator={filteredData.length > 10}
                paginatorClassName="recent-invoices-pagination"
                rows={rowsPerPage}
                // first={currentPage * rowsPerPage}
                // onPage={onPageChange}
                emptyMessage={expenseDataLoading ? (
                    <div className="outter-load-table">
                        <ThreeDots
                            height="80"
                            width="80"
                            radius="9"
                            color="#405BFF"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                        />
                    </div>
                ) : "No results found"}
            >
                <Column
                    field="exp_id"
                    header="Expense Id"
                    sortable
                    style={{ width: '12%', paddingRight: '20px' }}
                ></Column>
                <Column
                    field="exp_category_name"
                    header="Category"
                    sortable
                    style={{ paddingRight: '20px' }}
                ></Column>
                <Column
                    field="client_name"
                    header="Client"
                    sortable
                    style={{ width: '15%', paddingRight: '20px' }}
                ></Column>
                <Column
                    field="exp_date"
                    body={renderDueDate}
                    header="Date"
                    sortable
                    style={{ width: '15%', paddingRight: '20px' }}
                ></Column>
                <Column
                    field="exp_is_billable"
                    body={renderStatus}
                    header="Billable"
                    sortable
                    style={{ paddingRight: '20px' }}
                ></Column>
                <Column
                    field="amount"
                    body={renderAmount}
                    className="amount-expense-recent"
                    header="Amount"
                    sortable
                    style={{ paddingRight: '20px' }}
                ></Column>
            </DataTable>
        </div>
    );
};

export default RecentExpense;
