import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export default function Page404() {
  const { masterUser } = useSelector((state) => state.masterAuth);
  const { user } = useSelector((state) => state.auth);
  useEffect(() => {
     global.config.activityLog(window.location.href, '404', 'Page not found!');
  }, []);
  const redirectPath = masterUser ? "/master-user/login" : "/";

  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      <div className="bg-blueGray-700 rounded-lg shadow-lg p-8 md:p-16">
        <h1 className="text-4xl md:text-6xl font-bold mb-8 text-center text-blueGray-100">
          404
        </h1>
        <p className="text-xl md:text-2xl text-gray-800 mb-8 text-blueGray-100">
          The page you are looking for cannot be found.
        </p>
        <Link to={redirectPath}>
          <button
            className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            type="submit"
          >
            <i className="fas fa-home"></i>
            {"  "}
            Go back home
          </button>
        </Link>
      </div>
    </div>
  );
}
