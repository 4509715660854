import React, { useEffect, useState } from "react";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import useSendData from "../../../hooks/useFetchData.js";
import EyeIcon from "../../../images/icons/eye-icon.svg";
import EyeCrossed from "../../../images/icons/eye-crossed.svg";


import {
    otpVerification,
    registerUser,
} from "../../../../src/services/emailRegister/emailRegisterServices.js";
import { AES, enc } from "crypto-js";
import LoginImageMobile from "../../../images/login/lg-img-mob.png";
import LoginImage from "../../../images/login/lg-img.png";
import Logo from "../../../images/logo/to-logo.svg";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ThreeDots } from "react-loader-spinner";
const Register = () => {
    const { email, otp } = useParams();
    const decodedEmail = decodeURIComponent(email.replace(/%2F/g, "/"));
    const decryptedBytes = AES.decrypt(decodedEmail, "encryptionKey");
    const deEmail = decryptedBytes.toString(enc.Utf8);
    const navigate = useNavigate();
    const [companyName, setCompanyName] = useState("");
    const [location, setlocation] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [subDomain, setSubDomain] = useState("");
    const [loading, setLoading] = useState(false); // Loading state
    const domainName = window.location.hostname;
    const currentPort = window.location.port;
    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {
        const checkUser = async () => {
            const result = await otpVerification(deEmail, otp);
            if (!result) {
                navigate(`/home`);
            }
        };
        checkUser();
    }, [deEmail, otp, navigate]);
    const submitHandler = async () => {
        if (password !== confirmPassword) {
            alert("Password and confirm password do not match");
            return;
        }
        setLoading(true); // Start loading
        try {
            const result = await registerUser(
                deEmail,
                companyName,
                password,
                companyName,
                false
            );

            if (result.status === "success") {
                if (result.data.subDomain) {
                    if (currentPort === "3000") {
                        window.location.href = `http://${result.data.subDomain}.${domainName}:3000`;
                    } else {
                        window.location.href = `https://${result.data.subDomain}.${domainName}`;
                    }
                }
            } else {

                toast.error(result.message, {
                    position: "bottom-right",
                    autoClose: 1500,
                });

            }
        } catch (error) {
            console.error("Error:", error);
        } finally {
            setLoading(false); // End loading
        }
    };
    return (
        <div>
            <>
                <div className="top-logo-mobile">
                    <a href="/"><img src={Logo} alt="Talent On" /></a>
                </div>
                <div className="home-register-outer">
                    <div className="lg-lft">
                        <img src={LoginImage} alt="desktop" className="desktop-lg-img" />
                        <img
                            src={LoginImageMobile}
                            alt="mobile"
                            className="mobile-lg-img"
                        />
                    </div>
                    <div className="home-register-form-section">
                        <div className="top-logo">
                            <a href="/"><img src={Logo} alt="Talent On" /></a>
                        </div>
                        <div className="register-head">
                            Please enter your details to register
                        </div>
                        <div className="input-outer">
                            <label>Email</label>
                            <input type="email" value={deEmail} disabled />
                            <br />
                        </div>
                        <div className="input-outer">
                            <label>Company Name</label>
                            <input
                                type="text"
                                value={companyName}
                                onChange={(e) => setCompanyName(e.target.value)}
                            />
                            <br />
                        </div>
                        <div className="input-outer">
                            <label>Password</label>
                            <div className="password-input-wrapper">
                                <input
                                    type={showPassword ? "text" : "password"}
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                                <img
                                    src={showPassword ? EyeCrossed : EyeIcon}
                                    className="password-toggle-icon"
                                    alt="Toggle Password"
                                    onClick={() => setShowPassword(!showPassword)}
                                />
                            </div>
                            <br />
                        </div>
                        <div className="input-outer">
                            <label>Confirm Password</label>
                            <div className="password-input-wrapper 123">
                                <input
                                    type="password"
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                />
                            </div>
                            <br />
                        </div>
                        <div className="create-acnt-button">
                            <button onClick={submitHandler}>
                                {loading ?
                                    <div className="outter-load-table">
                                        <ThreeDots
                                            height="20"
                                            width="40"
                                            radius="4"
                                            color="white"
                                            ariaLabel="three-dots-loading"
                                            wrapperStyle={{}}
                                            wrapperClassName=""
                                            visible={true}
                                        />
                                    </div> : "Create Account"
                                }
                            </button>
                        </div>
                    </div>
                </div>
            </>
        </div>
    );
};

export default Register;