import axios from 'axios';
import { useQuery } from 'react-query';
import userTypeName from "../../../../constants/userType";

export function useMasterUserView(userId, token, profileEdit) {
  return useQuery(['masterUserView', userId], async () => {
    const response = await axios.get(`/api/master/user/view/${userId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  },
    {
      enabled: !profileEdit, // Use this to conditionally enable or disable the query
    }
  );
}

export function useMasterUserType() {
  return useQuery('userType', async () => {
    const response = await axios.get('/api/master/user-type');
    return response.data.filter(
      (role) =>
        role._id !== userTypeName.Collaborator &&
        role._id !== userTypeName.Business
    );
  });
}