import moment from "moment";
import React, { useState, useEffect, useRef } from "react";
import { Column } from "primereact/column";
import "react-toastify/dist/ReactToastify.css";
import { DataTable } from "primereact/datatable";
import { ThreeDots } from 'react-loader-spinner';
import { useSelector, useDispatch } from 'react-redux';
import { useFetchRecentTenants } from "./MasterDashboardApi";
import { setHeaderText } from "../../../features/headerText/headerTextSlice";
import { motion } from 'framer-motion';
import { dateMasterCreateConvert } from "../../../helpers/dateHelper.js";
import TruncateFieldView from "../../helpers/truncateFieldView.js";
import profile_image from "../../../images/user/Default_pfp.png";

const useRenderMemberName = (rowData) => {
  const [showFullname, setShowFullname] = useState(false);

  const fullName = rowData.commercial_name ? `${rowData.commercial_name}` : `${rowData.ownerName}`

  const displayName = showFullname ? fullName : fullName && fullName.length <= 21 ? fullName : fullName && fullName.slice(0, 21) + "...";

  return (
    <div
      className={`member-name${showFullname ? " active" : ""}`}
      onMouseEnter={() => {
        if (fullName.length > 21) {
          setShowFullname(true);
        }
      }}
      onMouseLeave={() => setShowFullname(false)}
    >
      {rowData.company_logo !== null && rowData.company_logo !== "null" ? (
        <img
          src={`/uploads/lowProfileImage/${rowData.company_logo}`}
          alt={fullName}
          className="member-image"
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = "/uploads/lowProfileImage/user_default.png";
          }}
        />
      ) : (
        <img src={profile_image} alt={fullName} className="member-image" />
      )}
      <span>{displayName}</span>
    </div>
  );
};

const MasterRecentTenants = (props) => {
  const dispatch = useDispatch();
  const dashboradDate = useSelector(
    (state) => state.masterDashBoard.masterDashboardDateRange
  );
  const user = useSelector(
    (state) => state.masterAuth.masterUser
  )
  const token = useSelector(
    (state) => state.masterAuth.token
  )

  const { data: tenants = [], isLoading, refetch: refetchRecentTenant } = useFetchRecentTenants(
    token,
    new Date(moment(dashboradDate[0]).tz(global.config.getTimeZone()).startOf('day')).toISOString(),
    new Date(moment(dashboradDate[1]).tz(global.config.getTimeZone()).endOf('day')).toISOString(),
  );

  useEffect(() => {
    dispatch(setHeaderText("Dashboard"));
    refetchRecentTenant();
  }, [dashboradDate, dispatch, token, user]);

  const renderUserStatus = (rowData) => {
    let statusClass = rowData.status ? "payment-status active" : "payment-status inactive";
    let statusName = rowData.status ? "Active" : "Inactive";
    if (rowData.status === true) {
      statusClass = "active";
      statusName = "Active";
    } else if (rowData.status === false) {
      statusClass = "inactive";
      statusName = "Inactive";
    }
    return (
      <div className="accout-status-outer">
        <span className={`account-status ${statusClass}`}>{statusName}</span>
      </div>
    );
  };

  const renderUserSubscriptionStatus = (rowData) => {
    let statusClass;
    let statusName;
    if (rowData.status === true) {
      statusClass = "active";
      statusName = "Active";
    } else if (rowData.status === false) {
      statusClass = "inactive";
      statusName = "Inactive";
    }
    return (
      <span className={`payment-status active`}>Free Trail</span>
    );
  };

  const componentRef = useRef(null);
  const fadeIn = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 }
  };
  return (
    <div className="invoice-outer">
      <div className="recent-title">Recent Tenants</div>
      <div className="card">
        {isLoading ? (
          <ThreeDots
            height="80"
            width="80"
            radius="9"
            color="#405BFF"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        ) : (
          <motion.div
            ref={componentRef}
            initial="hidden"
            animate="visible"
            variants={fadeIn}
            transition={{ duration: 0.3 }}
          >
            <DataTable
              value={tenants.slice(0, 5)}
              removableSort
              tableStyle={{ minWidth: "50rem", width: "100%", textAlign: "left" }}
              paginator={false}
              paginatorClassName="recent-invoices-pagination"
              rows={5}
              emptyMessage="No Tenants found"
            >
              <Column
                body={useRenderMemberName}
                field="ownerName"
                sortField="ownerName"
                header="Admin Name"
                sortable
                style={{ minWidth: "300px", paddingRight: "20px" }}
              ></Column>
              <Column
                field="ownerName"
                header="Company Name"
                body={(rowData) => {
                  return <TruncateFieldView textContent={rowData?.ownerName} />;
                }}
                sortable
                style={{ minWidth: "300px", paddingRight: "20px" }}
              ></Column>
              <Column
                field="subDomain"
                header="Sub-domain"
                body={(rowData) => {
                  return <TruncateFieldView textContent={rowData?.subDomain} />;
                }}
                sortable
                style={{ minWidth: "300px", paddingRight: "20px" }}
              ></Column>
              <Column
                field="created_at"
                body={dateMasterCreateConvert}
                header="Due Added"
                className="fixed-sec3"
                sortable
                style={{ paddingRight: "50px" }}
              ></Column>
              <Column
                field="subscriptionType"
                header="Subscription Type"
                body={(rowData) => rowData.subscriptionType || "Pro"}
                sortable
                style={{ paddingRight: "30px" }}
              ></Column>
              <Column
                field="subscriptionStatus"
                header="Subscription Status"
                body={(rowData) => renderUserSubscriptionStatus(rowData.subscriptionStatus || "Free Trail")}
                sortable
                style={{ paddingRight: "30px" }}
              ></Column>
              <Column
                field="status"
                body={(rowData) => renderUserStatus(rowData)}
                header="Account Status"
                sortable
                style={{ paddingRight: "30px" }}
              ></Column>
            </DataTable>
          </motion.div>
        )}
      </div>
    </div>
  );
};

export default MasterRecentTenants;