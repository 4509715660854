import "./MasterUserDetail.css";
import { AES, enc } from "crypto-js";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import Back from "../../../../images/icons/lf-arw.svg";
import { useDispatch, useSelector } from "react-redux";
import { setHeaderText } from "../../../../features/headerText/headerTextSlice.js";
import MasterProfileDetails from "../../../../components/Master/MasterUsers/MasterUserProfile/MasterUserProfile.jsx";

function MasterUserDeatils() {
  const dispatch = useDispatch();
  const { encryptedId } = useParams();
  const decodedId = decodeURIComponent(encryptedId.replace(/%2F/g, "/"));
  const decryptedBytes = AES.decrypt(decodedId, "encryptionKey");
  const id = decryptedBytes.toString(enc.Utf8);

  useEffect(() => {
    dispatch(setHeaderText("Master Users"));
  }, [id]);

  return (
    <div>
      <div className="back-to">
        <Link to="/master-user/list">
          Back to All Users
        </Link>
      </div>
      <MasterProfileDetails id={id} />
    </div>
  );
}

export default MasterUserDeatils;
