import moment from "moment";
import { reportingFilterDateRangesConst } from "../../../../../constants/reportingFilterDateRangesConst";
import { reportingFilterComparisonConst } from "../../../../../constants/reportingFilterComparisonConst";

export function calculateTotalHours(trackReport, reportingTimeWorked) {
    const timeZone = global.config.getTimeZone();
    const firstDayOfYear = moment.tz(timeZone).startOf("year").toDate();
    let totalTrackedHours = 0;

    trackReport.forEach(entry => {
        const dateRange = reportingTimeWorked.timeWorkedDateRange || [firstDayOfYear, new Date()];
        const trackEndDate = moment.tz(entry?.endTime, timeZone);
        const userId = entry.user._id;
        const startDate = moment.tz(dateRange[0], timeZone);
        const endDate = moment.tz(dateRange[1], timeZone);
        if (
            // !entry.is_delete &&
            userId &&
            (reportingTimeWorked.timeWorkedUser && reportingTimeWorked.timeWorkedUser.includes(userId)) &&
            trackEndDate.isBetween(startDate, endDate, null, '[]')
        ) {
            totalTrackedHours += entry.duration;
        }
    });

    return totalTrackedHours;
}

export function calculateTotalUsers(trackReport, reportingTimeWorked) {
    const timeZone = global.config.getTimeZone();
    const firstDayOfYear = moment.tz(timeZone).startOf("year").toDate();
    const dateRange = reportingTimeWorked.timeWorkedDateRange || [firstDayOfYear, new Date()];
    const startDate = moment.tz(dateRange[0], timeZone);
    const endDate = moment.tz(dateRange[1], timeZone);
    const uniqueUsers = new Set();

    trackReport.forEach(entry => {
        const trackEndDate = moment.tz(entry?.endTime, timeZone);
        const userId = entry.user._id;

        if (
            // !entry.is_delete &&
            userId &&
            (reportingTimeWorked.timeWorkedUser && reportingTimeWorked.timeWorkedUser.includes(userId)) &&
            trackEndDate.isBetween(startDate, endDate, null, '[]')
        ) {
            uniqueUsers.add(userId);
        }
    });

    return uniqueUsers.size;
};

export function calculateUserHours(trackReport, reportingTimeWorked) {
    const timeZone = global.config.getTimeZone();
    const firstDayOfYear = moment.tz(timeZone).startOf("year").toDate();
    const dateRange = reportingTimeWorked.timeWorkedDateRange || [firstDayOfYear, new Date()];
    const startDate = moment.tz(dateRange[0], timeZone);
    const endDate = moment.tz(dateRange[1], timeZone);
    const userHours = {};

    trackReport.forEach(entry => {
        const trackEndDate = moment.tz(entry?.endTime, timeZone);
        const userId = entry.user._id;
        const userName = entry.user.first_name
            ? `${entry.user.first_name} ${entry.user.middle_name || ''} ${entry.user.last_name}`
            : `${entry.user.company_first_name ? entry.user.company_first_name + (entry.user.company_last_name && entry.user.company_last_name) : entry.user.company_name}` || "Unknown User"; // Ensure user name is present
        const isBillable = entry.project.is_billable;

        if (
            userName &&
            (reportingTimeWorked.timeWorkedUser && reportingTimeWorked.timeWorkedUser.includes(userId)) &&
            trackEndDate.isBetween(startDate, endDate, null, '[]')
        ) {
            if (!userHours[userName]) {
                userHours[userName] = { billableHours: 0, nonBillableHours: 0 };
            }
            if (isBillable) {
                userHours[userName].billableHours += entry.duration; // Convert duration from seconds to hours
            } else {
                userHours[userName].nonBillableHours += entry.duration; // Convert duration from seconds to hours
            }
        }
    });

    return Object.keys(userHours).map(userName => ({
        name: userName,
        billableHours: userHours[userName].billableHours,
        nonBillableHours: userHours[userName].nonBillableHours,
    }));
}

export function calculateUserHoursFilter(
    trackReport,
    reportingTimeWorked,
    selectedDateRange,
    selectedComparison,
    selectedYearRange,
    selectedMonthRange,
    selectedComparisonYear,
    selectedComparisonMonth
) {
    const timeZone = global.config.getTimeZone();

    const getLastDayOfMonth = (year, month) => {
        return new Date(year, month, 0).getDate();
    };

    const getStartAndEndDates = (rangeId, year = new Date().getFullYear()) => {
        switch (rangeId) {
            case reportingFilterDateRangesConst.This_Year:
                return [
                    new Date(`${year}-01-01`),
                    new Date(`${year}-12-31`)
                ];

            case reportingFilterDateRangesConst.This_Quarter: {
                const quarter = Math.floor((new Date().getMonth() + 3) / 3);
                const startMonth = (quarter - 1) * 3 + 1;
                const endMonth = startMonth + 2;
                const endDay = getLastDayOfMonth(year, endMonth);
                return [
                    new Date(`${year}-${startMonth}-01`),
                    new Date(`${year}-${endMonth}-${endDay}`)
                ];
            }

            case reportingFilterDateRangesConst.First_Half:
                return [
                    new Date(`${year}-01-01`),
                    new Date(`${year}-06-30`)
                ];

            case reportingFilterDateRangesConst.This_Month: {
                const month = new Date().getMonth() + 1;
                const endDay = getLastDayOfMonth(year, month);
                return [
                    new Date(`${year}-${month}-01`),
                    new Date(`${year}-${month}-${endDay}`)
                ];
            }

            case reportingFilterDateRangesConst.Year_Selected:
                if (selectedYearRange) {
                    const selectedYear = selectedYearRange.getFullYear();
                    return [
                        new Date(`${selectedYear}-01-01`),
                        new Date(`${selectedYear}-12-31`)
                    ];
                } else {
                    return [
                        new Date(`${year}-01-01`),
                        new Date(`${year}-12-31`)
                    ];
                }

            case reportingFilterDateRangesConst.Month_Selected:
                if (selectedMonthRange) {
                    const selectedMonth = selectedMonthRange.getMonth() + 1;
                    const selectedYearForMonth = selectedMonthRange.getFullYear();
                    const endDay = getLastDayOfMonth(selectedYearForMonth, selectedMonth);
                    return [
                        new Date(`${selectedYearForMonth}-${selectedMonth}-01`),
                        new Date(`${selectedYearForMonth}-${selectedMonth}-${endDay}`)
                    ];
                } else {
                    // Fallback to the current month if selectedMonthRange is not provided
                    const currentMonth = new Date().getMonth() + 1;
                    const endDay = getLastDayOfMonth(year, currentMonth);
                    return [
                        new Date(`${year}-${currentMonth}-01`),
                        new Date(`${year}-${currentMonth}-${endDay}`)
                    ];
                }

            default:
                return [
                    new Date(`${year}-01-01`),
                    new Date(`${year}-12-31`)
                ];
        }
    };

    const getComparisonStartAndEndDates = (comparisonId, year = new Date().getFullYear()) => {
        switch (comparisonId) {
            case reportingFilterComparisonConst.Last_Year:
                return [
                    new Date(`${year - 1}-01-01`),
                    new Date(`${year - 1}-12-31`)
                ];

            case reportingFilterComparisonConst.Last_Quarter: {
                const quarter = Math.floor((new Date().getMonth() + 3) / 3);
                const lastQuarter = quarter === 1 ? 4 : quarter - 1;
                const startMonth = (lastQuarter - 1) * 3 + 1;
                const endMonth = startMonth + 2;
                const lastYear = quarter === 1 ? year - 1 : year;
                const endDay = getLastDayOfMonth(lastYear, endMonth);
                return [
                    new Date(`${lastYear}-${startMonth}-01`),
                    new Date(`${lastYear}-${endMonth}-${endDay}`)
                ];
            }

            case reportingFilterComparisonConst.Second_Half:
                return [
                    new Date(`${year}-07-01`),
                    new Date(`${year}-12-31`)
                ];

            case reportingFilterComparisonConst.Last_Month: {
                const lastMonth = new Date().getMonth(); // 0-indexed for previous month
                const lastYear = lastMonth === 0 ? year - 1 : year;
                const lastMonthFormatted = lastMonth === 0 ? 12 : lastMonth;
                const endDay = getLastDayOfMonth(lastYear, lastMonthFormatted);
                return [
                    new Date(`${lastYear}-${lastMonthFormatted}-01`),
                    new Date(`${lastYear}-${lastMonthFormatted}-${endDay}`)
                ];
            }

            case reportingFilterComparisonConst.Year_Select:
                if (selectedComparisonYear) {
                    const comparisonYear = selectedComparisonYear.getFullYear();
                    return [
                        new Date(`${comparisonYear}-01-01`),
                        new Date(`${comparisonYear}-12-31`)
                    ];
                } else {
                    return [
                        new Date(`${year - 1}-01-01`),
                        new Date(`${year - 1}-12-31`)
                    ];
                }

            case reportingFilterComparisonConst.Month_Select:
                if (selectedComparisonMonth) {
                    const comparisonMonth = selectedComparisonMonth.getMonth() + 1;
                    const comparisonYearForMonth = selectedComparisonMonth.getFullYear();
                    const endDay = getLastDayOfMonth(comparisonYearForMonth, comparisonMonth);
                    return [
                        new Date(`${comparisonYearForMonth}-${comparisonMonth}-01`),
                        new Date(`${comparisonYearForMonth}-${comparisonMonth}-${endDay}`)
                    ];
                } else {
                    const lastMonth = new Date().getMonth() || 12;
                    const fallbackYear = lastMonth === 12 ? year - 1 : year;
                    const endDay = getLastDayOfMonth(fallbackYear, lastMonth);
                    return [
                        new Date(`${fallbackYear}-${lastMonth}-01`),
                        new Date(`${fallbackYear}-${lastMonth}-${endDay}`)
                    ];
                }

            default:
                return [
                    new Date(`${year - 1}-01-01`),
                    new Date(`${year - 1}-12-31`)
                ];
        }
    };

    const filterByDateRange = (reports, startDate, endDate) => {
        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(23, 59, 59, 999);
        return reports.filter(entry => {
            const trackEndDate = moment.tz(entry?.endTime, timeZone);
            const userId = entry.user._id;
            return trackEndDate.isBetween(startDate, endDate, null, '[]') &&
                reportingTimeWorked.timeWorkedUser.includes(userId);
        });
    };

    const calculateUserHours = (reports) => {
        const userHours = {};

        reports.forEach(entry => {
            const userId = entry.user._id;
            const userName = entry.user.first_name
                ? `${entry.user.first_name} ${entry.user.middle_name || ''} ${entry.user.last_name}`
                : `${entry.user.company_first_name ? entry.user.company_first_name + (entry.user.company_last_name && entry.user.company_last_name) : entry.user.company_name}` || "Unknown User";
            const isBillable = entry.project.is_billable;

            if (!userHours[userName]) {
                userHours[userName] = { billableHours: 0, nonBillableHours: 0 };
            }

            if (isBillable) {
                userHours[userName].billableHours += entry.duration;
            } else {
                userHours[userName].nonBillableHours += entry.duration;
            }
        });

        return Object.keys(userHours).map(userName => ({
            name: userName,
            billableHours: userHours[userName].billableHours,
            nonBillableHours: userHours[userName].nonBillableHours,
        }));
    };

    const [startCurrentRange, endCurrentRange] = getStartAndEndDates(selectedDateRange);
    const [startComparisonRange, endComparisonRange] = getComparisonStartAndEndDates(selectedComparison);

    const filteredCurrentPeriod = filterByDateRange(trackReport, startCurrentRange, endCurrentRange);
    const filteredComparisonPeriod = filterByDateRange(trackReport, startComparisonRange, endComparisonRange);

    const currentPeriodHours = calculateUserHours(filteredCurrentPeriod);
    const comparisonPeriodHours = calculateUserHours(filteredComparisonPeriod);

    const combinedUserHours = {};

    currentPeriodHours.forEach(current => {
        const userName = current.name;
        combinedUserHours[userName] = {
            name: userName,
            currentPeriodBillableHours: current.billableHours || 0,
            currentPeriodNonBillableHours: current.nonBillableHours || 0,
            comparisonPeriodBillableHours: 0,
            comparisonPeriodNonBillableHours: 0,
        };
    });

    comparisonPeriodHours.forEach(comparison => {
        const userName = comparison.name;
        if (!combinedUserHours[userName]) {
            combinedUserHours[userName] = {
                name: userName,
                currentPeriodBillableHours: 0,
                currentPeriodNonBillableHours: 0,
                comparisonPeriodBillableHours: comparison.billableHours || 0,
                comparisonPeriodNonBillableHours: comparison.nonBillableHours || 0,
            };
        } else {
            combinedUserHours[userName].comparisonPeriodBillableHours = comparison.billableHours || 0;
            combinedUserHours[userName].comparisonPeriodNonBillableHours = comparison.nonBillableHours || 0;
        }
    });

    return Object.values(combinedUserHours);
}
