import React from "react";
import { useSelector } from 'react-redux';
import Dollar from "../../../../images/icons/dollar-kit.svg";

// Functional component
function CancelledRequestsTimeOff({ timeoffReq }) {
  const reportingTimeoffRequest = useSelector((state) => state.reportingTimeoffRequest);

  const filteredTimeoffReqs = timeoffReq.filter(tor => {
    const isUserDetailMatch = !reportingTimeoffRequest.timeoffReqUser || reportingTimeoffRequest.timeoffReqUser.includes(tor.user._id);
    const isStatusDetailMatch = !reportingTimeoffRequest.timeoffReqReqStatus || reportingTimeoffRequest.timeoffReqReqStatus.includes(tor.status);
    const isNotDeleted = !tor.is_delete;
    const isYearMatch = !reportingTimeoffRequest.timeoffReqYear ||
      new Date(tor?.end_date).getFullYear() === reportingTimeoffRequest.timeoffReqYear;
    const isCategoryMatch = reportingTimeoffRequest.timeoffReqCategory && reportingTimeoffRequest.timeoffReqCategory.includes(tor.type_of_leave._id)
    return isUserDetailMatch && isStatusDetailMatch && isNotDeleted && isYearMatch && isCategoryMatch;
  });

  const cancelledRequestCount = filteredTimeoffReqs.filter(tor => tor.status === 'Cancelled').length;
  return (
    <div>
      <div className="overview-item">
        <div className="overview-title-outer">
          <div className="overview-title">Total Cancelled</div>
          <div className="overview-icon">
            <svg width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g filter="url(#filter0_d_3042_6)">
                <circle cx="24.5" cy="24.5" r="20.5" fill="white" />
              </g>
              <path d="M26 36.5C26 36.776 25.776 37 25.5 37H17.5C15.019 37 13 34.981 13 32.5V17.5C13 15.019 15.019 13 17.5 13H26.5C28.981 13 31 15.019 31 17.5V22.5C31 22.776 30.776 23 30.5 23C30.224 23 30 22.776 30 22.5V17.5C30 15.57 28.43 14 26.5 14H17.5C15.57 14 14 15.57 14 17.5V32.5C14 34.43 15.57 36 17.5 36H25.5C25.776 36 26 36.224 26 36.5ZM27 18.5C27 18.224 26.776 18 26.5 18H17.5C17.224 18 17 18.224 17 18.5C17 18.776 17.224 19 17.5 19H26.5C26.776 19 27 18.776 27 18.5ZM25 23.5C25 23.224 24.776 23 24.5 23H17.5C17.224 23 17 23.224 17 23.5C17 23.776 17.224 24 17.5 24H24.5C24.776 24 25 23.776 25 23.5ZM17.5 28C17.224 28 17 28.224 17 28.5C17 28.776 17.224 29 17.5 29H21.5C21.776 29 22 28.776 22 28.5C22 28.224 21.776 28 21.5 28H17.5ZM37 30.5C37 34.084 34.084 37 30.5 37C26.916 37 24 34.084 24 30.5C24 26.916 26.916 24 30.5 24C34.084 24 37 26.916 37 30.5ZM36 30.5C36 27.468 33.532 25 30.5 25C27.468 25 25 27.468 25 30.5C25 33.532 27.468 36 30.5 36C33.532 36 36 33.532 36 30.5Z" fill="#6A7988" />
              <path d="M26.9645 30.4999H34.0355" stroke="#6A7988" stroke-linecap="round" stroke-linejoin="round" />
              <defs>
                <filter id="filter0_d_3042_6" x="0" y="0" width="49" height="49" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                  <feOffset />
                  <feGaussianBlur stdDeviation="2" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix type="matrix" values="0 0 0 0 0.468733 0 0 0 0 0.51145 0 0 0 0 0.554167 0 0 0 0.39 0" />
                  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3042_6" />
                  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3042_6" result="shape" />
                </filter>
              </defs>
            </svg>
          </div>
        </div>
        <div className="overview-rice">
          {cancelledRequestCount}
        </div>
      </div>
    </div>
  );
}

export default CancelledRequestsTimeOff;