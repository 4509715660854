import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { reportingFilterDateRangesConst } from '../../../../../../constants/reportingFilterDateRangesConst';
import { reportingFilterComparisonConst } from '../../../../../../constants/reportingFilterComparisonConst';
import Tooltip from '../../../../../Invoices/InvoiceCreateManual/Tooltip';

// Functional component
function RevenueHistoryFilterGraphFilter({
    selectedDateRange,
    selectedComparison,
    selectedFrequency,
    selectedYearRange,
    selectedMonthRange,
    selectedComparisonYear,
    selectedComparisonMonth
}) {
    const currentYear = new Date().getFullYear();
    const previousYear = currentYear - 1;
    const [showDateRangeTooltip, setShowDateRangeTooltip] = useState(false);
    const [showComparisonTooltip, setShowComparisonTooltip] = useState(false);

    const handleDateRangeTooltipToggle = () => {
        setShowDateRangeTooltip(!showDateRangeTooltip);
    };

    const handleComparisonTooltipToggle = () => {
        setShowComparisonTooltip(!showComparisonTooltip);
    };

    const getComparisonTitle = (isCurrent, selectedRange, selectedComparison, selectedYearRange, selectedMonthRange, selectedComparisonYear, selectedComparisonMonth) => {
        const rangeTitles = {
            [reportingFilterDateRangesConst.This_Year]: `Current Year (${currentYear})`,
            [reportingFilterDateRangesConst.This_Quarter]: `This Quarter`,
            [reportingFilterDateRangesConst.First_Half]: `First Half`,
            [reportingFilterDateRangesConst.This_Month]: `This Month`,
            [reportingFilterDateRangesConst.Year_Selected]: selectedYearRange ? `Year ${selectedYearRange.getFullYear()}` : `Selected Year`,
            [reportingFilterDateRangesConst.Month_Selected]: selectedMonthRange ? `Month ${selectedMonthRange.toLocaleString('default', { month: 'long' })} ${selectedMonthRange.getFullYear()}` : `Selected Month`,
        };

        const comparisonTitles = {
            [reportingFilterComparisonConst.Last_Year]: `Last Year (${previousYear})`,
            [reportingFilterComparisonConst.Last_Quarter]: `Last Quarter`,
            [reportingFilterComparisonConst.Second_Half]: `Second Half`,
            [reportingFilterComparisonConst.Last_Month]: `Last Month`,
            [reportingFilterComparisonConst.Year_Select]: selectedComparisonYear ? `Year ${selectedComparisonYear.getFullYear()}` : `Previous Year`,
            [reportingFilterComparisonConst.Month_Select]: selectedComparisonMonth ? `Month ${selectedComparisonMonth.toLocaleString('default', { month: 'long' })} ${selectedComparisonMonth.getFullYear()}` : `Previous Month`,
        };

        return isCurrent ? rangeTitles[selectedRange] : comparisonTitles[selectedComparison];
    };

    const getDateRange = (isCurrent, selectedRange, selectedComparison, selectedYearRange, selectedMonthRange, selectedComparisonYear, selectedComparisonMonth) => {
        let dateRange = '';
        const year = new Date().getFullYear();
        const getLastDayOfMonth = (year, month) => new Date(year, month, 0).getDate();
        const getFormattedDate = (date) => {
            return new Date(date).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric'
            });
        };

        if (isCurrent) {
            switch (selectedRange) {
                case reportingFilterDateRangesConst.This_Year:
                    dateRange = `${getFormattedDate(new Date(`${year}-01-01`))} - ${getFormattedDate(new Date(`${year}-12-31`))}`;
                    break;
                case reportingFilterDateRangesConst.This_Quarter: {
                    const quarter = Math.floor((new Date().getMonth() + 3) / 3);
                    const startMonth = (quarter - 1) * 3 + 1;
                    const endMonth = startMonth + 2;
                    const endDay = getLastDayOfMonth(year, endMonth);
                    dateRange = `${getFormattedDate(new Date(`${year}-${startMonth}-01`))} - ${getFormattedDate(new Date(`${year}-${endMonth}-${endDay}`))}`;
                    break;
                }
                case reportingFilterDateRangesConst.First_Half:
                    dateRange = `${getFormattedDate(new Date(`${year}-01-01`))} - ${getFormattedDate(new Date(`${year}-06-30`))}`;
                    break;

                case reportingFilterDateRangesConst.This_Month: {
                    const month = new Date().getMonth() + 1;
                    const endDay = getLastDayOfMonth(year, month);
                    dateRange = `${getFormattedDate(new Date(`${year}-${month}-01`))} - ${getFormattedDate(new Date(`${year}-${month}-${endDay}`))}`;
                    break;
                }

                case reportingFilterDateRangesConst.Year_Selected:
                    if (selectedYearRange) {
                        const selectedYear = selectedYearRange.getFullYear();
                        dateRange = `${getFormattedDate(new Date(`${selectedYear}-01-01`))} - ${getFormattedDate(new Date(`${selectedYear}-12-31`))}`;
                    } else {
                        dateRange = `${getFormattedDate(new Date(`${year}-01-01`))} - ${getFormattedDate(new Date(`${year}-12-31`))}`;
                    }
                    break;

                case reportingFilterDateRangesConst.Month_Selected:
                    if (selectedMonthRange) {
                        const selectedMonth = selectedMonthRange.getMonth() + 1;
                        const selectedYearForMonth = selectedMonthRange.getFullYear();
                        const endDay = getLastDayOfMonth(selectedYearForMonth, selectedMonth);
                        dateRange = `${getFormattedDate(new Date(`${selectedYearForMonth}-${selectedMonth}-01`))} - ${getFormattedDate(new Date(`${selectedYearForMonth}-${selectedMonth}-${endDay}`))}`;
                    } else {
                        const currentMonth = new Date().getMonth() + 1;
                        const endDay = getLastDayOfMonth(year, currentMonth);
                        dateRange = `${getFormattedDate(new Date(`${year}-${currentMonth}-01`))} - ${getFormattedDate(new Date(`${year}-${currentMonth}-${endDay}`))}`;
                    }
                    break;

                default:
                    dateRange = 'Unknown range';
                    break;
            }
        } else {
            switch (selectedComparison) {
                case reportingFilterComparisonConst.Last_Year:
                    dateRange = `${getFormattedDate(new Date(`${year - 1}-01-01`))} - ${getFormattedDate(new Date(`${year - 1}-12-31`))}`;
                    break;

                case reportingFilterComparisonConst.Last_Quarter: {
                    const quarter = Math.floor((new Date().getMonth() + 3) / 3);
                    const lastQuarter = quarter === 1 ? 4 : quarter - 1;
                    const startMonth = (lastQuarter - 1) * 3 + 1;
                    const endMonth = startMonth + 2;
                    const lastYear = quarter === 1 ? year - 1 : year;
                    const endDay = getLastDayOfMonth(lastYear, endMonth);
                    dateRange = `${getFormattedDate(new Date(`${lastYear}-${startMonth}-01`))} - ${getFormattedDate(new Date(`${lastYear}-${endMonth}-${endDay}`))}`;
                    break;
                }

                case reportingFilterComparisonConst.Second_Half:
                    dateRange = `${getFormattedDate(new Date(`${year}-07-01`))} - ${getFormattedDate(new Date(`${year}-12-31`))}`;
                    break;

                case reportingFilterComparisonConst.Last_Month: {
                    const lastMonth = new Date().getMonth();
                    const lastYear = lastMonth === 0 ? year - 1 : year;
                    const lastMonthFormatted = lastMonth === 0 ? 12 : lastMonth;
                    const endDay = getLastDayOfMonth(lastYear, lastMonthFormatted);
                    dateRange = `${getFormattedDate(new Date(`${lastYear}-${lastMonthFormatted}-01`))} - ${getFormattedDate(new Date(`${lastYear}-${lastMonthFormatted}-${endDay}`))}`;
                    break;
                }

                case reportingFilterComparisonConst.Year_Select:
                    if (selectedComparisonYear) {
                        const comparisonYear = selectedComparisonYear.getFullYear();
                        dateRange = `${getFormattedDate(new Date(`${comparisonYear}-01-01`))} - ${getFormattedDate(new Date(`${comparisonYear}-12-31`))}`;
                    } else {
                        dateRange = `${getFormattedDate(new Date(`${year - 1}-01-01`))} - ${getFormattedDate(new Date(`${year - 1}-12-31`))}`;
                    }
                    break;

                case reportingFilterComparisonConst.Month_Select:
                    if (selectedComparisonMonth) {
                        const comparisonMonth = selectedComparisonMonth.getMonth() + 1;
                        const comparisonYearForMonth = selectedComparisonMonth.getFullYear();
                        const endDay = getLastDayOfMonth(comparisonYearForMonth, comparisonMonth);
                        dateRange = `${getFormattedDate(new Date(`${comparisonYearForMonth}-${comparisonMonth}-01`))} - ${getFormattedDate(new Date(`${comparisonYearForMonth}-${comparisonMonth}-${endDay}`))}`;
                    } else {
                        const lastMonth = new Date().getMonth() || 12;
                        const fallbackYear = lastMonth === 12 ? year - 1 : year;
                        const endDay = getLastDayOfMonth(fallbackYear, lastMonth);
                        dateRange = `${getFormattedDate(new Date(`${fallbackYear}-${lastMonth}-01`))} - ${getFormattedDate(new Date(`${fallbackYear}-${lastMonth}-${endDay}`))}`;
                    }
                    break;

                default:
                    dateRange = 'Unknown comparison range';
                    break;
            }
        }

        return dateRange;
    };

    return (
        <div className='top-outer-graph-report top-hours-per-client'>
            <div className="top-inner-graph">
                <div className="graph-title">
                    <div className='comparison-type'>
                        <div className='comparison-type-inner'>
                            <div className='comparison-type-icon'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
                                    <circle cx="3" cy="3" r="3" transform="matrix(-1 0 0 1 6 0)" fill="#86ABE1" />
                                </svg>
                            </div>
                            <div className='comparison-type-title-section'>
                                <div className='comparison-type-title' onMouseEnter={handleDateRangeTooltipToggle} onMouseLeave={handleDateRangeTooltipToggle}>
                                    {getComparisonTitle(true, selectedDateRange, selectedComparison, selectedYearRange, selectedMonthRange, selectedComparisonYear, selectedComparisonMonth)}
                                </div>
                                {showDateRangeTooltip && (
                                    <div className="tooltip-boxmain">
                                        <Tooltip
                                            className="custom-tooltip"
                                            content={[
                                                {
                                                    heading: getComparisonTitle(true, selectedDateRange, selectedComparison, selectedYearRange, selectedMonthRange, selectedComparisonYear, selectedComparisonMonth),
                                                    content: getDateRange(true, selectedDateRange, selectedComparison, selectedYearRange, selectedMonthRange, selectedComparisonYear, selectedComparisonMonth),
                                                    headingClass: "heading1tool",
                                                    contentClass: "content1tool",
                                                },
                                            ]}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='comparison-type-inner'>
                            <div className='comparison-type-icon'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
                                    <circle cx="3" cy="3" r="3" transform="matrix(-1 0 0 1 6 0)" fill="#C7B6F6" />
                                </svg>
                            </div>
                            <div className='comparison-type-title-section'>
                                <div className='comparison-type-title' onMouseEnter={handleComparisonTooltipToggle} onMouseLeave={handleComparisonTooltipToggle}>
                                    {getComparisonTitle(false, selectedDateRange, selectedComparison, selectedYearRange, selectedMonthRange, selectedComparisonYear, selectedComparisonMonth)}
                                </div>
                                {showComparisonTooltip && (
                                    <div className="tooltip-boxmain">
                                        <Tooltip
                                            className="custom-tooltip"
                                            content={[
                                                {
                                                    heading: getComparisonTitle(false, selectedDateRange, selectedComparison, selectedYearRange, selectedMonthRange, selectedComparisonYear, selectedComparisonMonth),
                                                    content: getDateRange(false, selectedDateRange, selectedComparison, selectedYearRange, selectedMonthRange, selectedComparisonYear, selectedComparisonMonth),
                                                    headingClass: "heading1tool",
                                                    contentClass: "content1tool",
                                                },
                                            ]}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="icon-graph">
                    <div className="filter-comparison-graph">
                        <a >YEAR / MONTH</a>
                    </div>
                </div> */}
            </div>
        </div>
    );
}

export default RevenueHistoryFilterGraphFilter;