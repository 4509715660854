import axios from 'axios';
import { useQuery } from 'react-query';
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout, reset } from "../../features/auth/authSlice";
import { reset as invoiceReset } from "../../features/invoice/invoiceSlice.js";
import { reset as headerReset } from "../../features/headerText/headerTextSlice.js";
import { reset as appSettingsReset } from "../../features/appSettings/appSettingsSlice.js";
import userType from '../../constants/userType.js';

// Define your query key
const USER_PROFILES_QUERY_KEY = 'userProfiles';
// Create a function to fetch user profiles
export const fetchUserProfiles = async (token, dispatch, navigate) => {
    try {

        let cancelToken;
        if (cancelToken) {
            cancelToken.cancel();
        }
        cancelToken = axios.CancelToken.source();


        const response = await axios.get('/api/user', {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        global.config.slackMessage(error.toString());
        if (error.response?.data?.status === "deleted") {
            localStorage.removeItem("user");
            dispatch(logout());
            dispatch(reset());
            dispatch(invoiceReset());
            dispatch(headerReset());
            dispatch(appSettingsReset());
            toast.error('This user is deleted!', {
                position: "bottom-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            navigate("/");
            return;
        } else if (error.response && error.response.status === 403) {
            // Redirect to 404 page
            navigate("../../../404");
        } else if (error.response && error.response.status === 401) {
            // Redirect to login page
            navigate("/user/login");
        } else {
            console.log(error);
            navigate("../../../404");
        }
        throw new Error(error);
    }
};

// Create a custom hook to fetch user profiles using React Query
export const useUserProfiles = (token) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    return useQuery(USER_PROFILES_QUERY_KEY, () => fetchUserProfiles(token, dispatch, navigate));
};

export const useTypeOfAccount = (token) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    return useQuery(USER_PROFILES_QUERY_KEY, () => fetchUserProfiles(token, dispatch, navigate));
};

// Fetch user by ID
export const useUserType = (token) => {
    return useQuery('userType', async () => {
        const response = await axios.get(`/api/user-type`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        const filteredUserType = response.data.filter(
            (role) =>
                role._id !== userType.Collaborator &&
                role._id !== userType.Business
        );

        return filteredUserType;
    });
};