import axios from "axios";


export async function checkTimer(userID, token) {

     const resp =  await axios.get(`/api/time-track/check-active/${userID}`, {
                headers: {
                        Authorization: `Bearer ${token}`,
                },
        });
        return resp.data;

}