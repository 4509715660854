import axios from 'axios';
import { useQuery } from 'react-query';
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { masterLogout, reset } from '../../../features/masterAuth/masterAuthSlice.js';
import { reset as invoiceReset } from "../../../features/invoice/invoiceSlice.js";
import { reset as headerReset } from "../../../features/headerText/headerTextSlice.js";
import { reset as appSettingsReset } from "../../../features/appSettings/appSettingsSlice.js";

// Define your query key
const TENANT_PROFILES_QUERY_KEY = 'tenantsProfiles';

export const fetchTenantProfiles = async (token, dispatch, navigate) => {
    try {
        const response = await axios.get('/api/master/tenant', {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        console.log("Tenant Error:", error)
        global.config.slackMessage(error.toString());
        if (error.response?.data?.status === "deleted") {
            localStorage.removeItem("user");
            dispatch(masterLogout());
            dispatch(reset());
            dispatch(invoiceReset());
            dispatch(headerReset());
            dispatch(appSettingsReset());
            toast.error('This user is deleted!', {
                position: "bottom-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            navigate("/");
            return;
        } else if (error.response && error.response.status === 403) {
            navigate("../../../404");
        } else if (error.response && error.response.status === 401) {
            navigate("/master-user/login");
        } else {
            console.log(error);
            navigate("../../../404");
        }
        throw new Error(error);
    }
};

export const useOwnerProfiles = (token) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    return useQuery(TENANT_PROFILES_QUERY_KEY, () => fetchTenantProfiles(token, dispatch, navigate));
};