import axios from "axios";
import "./MasterUserProfile.css";
import Modal from "react-modal";
import { saveAs } from "file-saver";
import { toast } from "react-toastify";
import { useMasterUserView } from "./useMasterUserView";
import { useNavigate } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import Edit from "../../../../images/icons/edit.svg";
import Type from "../../../../images/icons/type.svg";
import Tele from "../../../../images/icons/tele.svg";
import Cost from "../../../../images/icons/cost1.svg";
import { useSelector, useDispatch } from 'react-redux';
import Delete from "../../../../images/icons/delete.svg";
import React, { useState, useEffect } from "react";
import EmailIcon from "../../../../images/icons/email.svg";
import Company from "../../../../images/icons/company.svg";
import Payment from "../../../../images/icons/payment.svg";
import location from "../../../../images/icons/location.svg";
import JobTitle from "../../../../images/icons/job-title.svg";
import typeOfContract from "../../../../constants/typeOfContract";
import PostalCode from "../../../../images/icons/postal-code.svg";
import PassportlIcon from "../../../../images/icons/passport.svg";
import birthIcon from "../../../../images/icons/dat-of-birth.svg";
import TruncateFieldView from "../../../helpers/truncateFieldView";
import profile_image from "../../../../images/user/Default_pfp.png";
import { logout, reset } from "../../../../features/auth/authSlice";
import UserIcon from "../../../../images/icons/single-user-icon.svg";
import { reset as invoiceReset } from "../../../../features/invoice/invoiceSlice.js";
import { setNotification } from "../../../../features/notification/notificationSlice.js";
import { reset as headerReset } from "../../../../features/headerText/headerTextSlice.js";
import { reset as appSettingsReset } from "../../../../features/appSettings/appSettingsSlice.js";
import { setUserRoleName } from "../../../../features/users/usersSlice.js";
import userTypeName from "../../../../constants/userType";
import MasterUserProfileEdit from "./MasterUserProfileEdit.jsx";

const MasterProfileDetails = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [profileEdit, setProfileEdit] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState("");

  const user = useSelector(
    (state) => state.masterAuth.masterUser
  );
  let token = null;
  if (user) {
    if (user && user.token) {
      token = user.token;
    }
  }

  const { data: userView = [], isLoading, isError: userViewError } = useMasterUserView(props.id, token, profileEdit);

  if (userViewError) {
    if (userViewError.response?.data?.status === "deleted") {
      // Handle the case where the user is deleted
      localStorage.removeItem("user");
      dispatch(logout());
      dispatch(reset());
      dispatch(invoiceReset());
      dispatch(headerReset());
      dispatch(appSettingsReset());
      toast.error('This user is deleted!', {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      navigate("/");
      return;
    }
    // Handle other errors
    alert(`Error fetching users`);
  }

  const handleDeleteClick = (id) => {
    setSelectedUserId(id);
    setShowConfirmation(true);
  };

  const handleConfirmationOk = async () => {
    try {
      setIsSubmitLoading(true);
      await axios.get(`/api/delete/master/user/${selectedUserId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      toast.success("User removed successfully", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      dispatch(setNotification(Date.now()));
      navigate("/user/myteam/list");
    } catch (error) {
      if (error.response?.data?.status === "deleted") {
        localStorage.removeItem("user");
        dispatch(logout());
        dispatch(reset());
        dispatch(invoiceReset());
        dispatch(headerReset());
        dispatch(appSettingsReset());
        toast.error('This user is deleted!', {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/");
        return;
      }
      toast.error("Something went wrong while user removal", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      global.config.slackMessage(error.toString());
    } finally {
      setIsSubmitLoading(false);
    }
  };

  const goToEditProfile = (e) => {
    setProfileEdit(true);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${day}/${month}/${year}`;
  };

  const handleCancelEdit = () => {
    setProfileEdit(false);
  };

  return (
    <>
      {profileEdit ? (
        <>
          <MasterUserProfileEdit id={props.id} onCancel={handleCancelEdit} />
        </>
      ) : (
        <>
          <div className="profile-details">
            <div className="profile-photo-outer">
              <div className="profile-photo">
                <div className="profile-photo-inner">
                  <div className="profile-photo-sec">
                    {isLoading ? (
                      <div>
                        <ThreeDots
                          height="18"
                          width="20"
                          radius="9"
                          color="#6479f9"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClassName=""
                          visible={true}
                        />
                      </div>
                    ) : (
                      <div className="profile-image">
                        {userView.profile_image !== "null" &&
                          userView.profile_image !== null ? (
                          <img
                            src={`/uploads/lowProfileImage/${userView.profile_image}`}
                            alt="User Profile Image"
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = '/uploads/lowProfileImage/user_default.png';
                            }}
                          />
                        ) : (
                          <img
                            src={profile_image}
                            alt="User Profile Image"
                          />
                        )}
                      </div>
                    )}
                  </div>
                  <div className="profile-name">
                    {isLoading ? (
                      <div>
                        <ThreeDots
                          height="18"
                          width="20"
                          radius="9"
                          color="#6479f9"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClassName=""
                          visible={true}
                        />
                      </div>
                    ) : (
                      <div className="profile-name-sec">
                        {userView.first_name}{" "}
                        {userView.middle_name && userView.middle_name !== "null"
                          ? userView.middle_name + " "
                          : ""}
                        {userView.last_name}{" "}
                      </div>
                    )}
                    {isLoading ? (
                      <div>
                        <ThreeDots
                          height="18"
                          width="20"
                          radius="9"
                          color="#6479f9"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClassName=""
                          visible={true}
                        />
                      </div>
                    ) : (
                      <>
                        {userView.role_id && (
                          <div className="profile-type">
                            {userView.role_id.user_type}
                          </div>
                        )}
                      </>
                    )}
                    <div className="profile-status">
                      {isLoading ? (
                        <div>
                          <ThreeDots
                            height="18"
                            width="20"
                            radius="9"
                            color="#6479f9"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                          />
                        </div>
                      ) : (
                        <>
                          {userView.is_active === false ? (
                            <div className="inactive">
                              Inactive
                            </div>
                          ) : userView.is_active === true ? (
                            <div className="active">
                              Active
                            </div>
                          ) : (
                            userView.is_active
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {isLoading ? (
                <div>
                  <ThreeDots
                    height="18"
                    width="20"
                    radius="9"
                    color="#6479f9"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                </div>
              ) : (
                <div className="profile-button">
                  <a onClick={goToEditProfile} className="edit-profile">
                    <span class="bt-ico">
                      <img src={Edit} alt="edit" />
                    </span>
                    Edit Profile
                  </a>{" "}
                  {userView._id !== user._id && (
                    <a
                      href="#"
                      className="delete-profile"
                      onClick={() => handleDeleteClick(userView._id)}
                    >
                      <span class="bt-ico">
                        <img src={Delete} alt="delete" />
                      </span>
                      Delete
                    </a>
                  )}
                </div>
              )}
            </div>

            {showConfirmation && (
              <Modal
                className="delete-modal"
                isOpen={showConfirmation}
                onRequestClose={() => setShowConfirmation(false)}
                contentLabel="Confirmation Delete"
                style={{
                  content: {
                    height: "41%",
                    width: "30%",
                    position: "fixed",
                    top: "36%",
                    left: "50%",
                    transform: "translate(-19.75%, 0%)",
                    parent: document.querySelector(".admin-outer.time.tracker"),
                  },
                }}
              >
                <div className="delete-modal-content">
                  <h2>Confirm Delete</h2>
                  <p>
                    Are you sure you want to delete this User?
                  </p>
                  <div className="delete-modal-buttons">
                    <button className="buttons-rejected" onClick={handleConfirmationOk} disabled={isSubmitLoading}>
                      {isSubmitLoading ?
                        <div className="outter-load-table">
                          <ThreeDots
                            height="18"
                            width="20"
                            radius="9"
                            color="white"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                          />
                        </div> : "Delete"}
                    </button>
                    <button onClick={() => setShowConfirmation(false)} disabled={isSubmitLoading}>
                      Cancel
                    </button>
                  </div>
                </div>
              </Modal>
            )}
            {showSuccessMessage && (
              <div className="success-message">
                Profile deleted successfully!
              </div>
            )}



            <div className="information-outer-section">
              <div className="information-inner">
                <div className="information">
                  <div className="information-title">Personal Information</div>
                  <div className="information-outer">
                    <div className="information-left">
                      {userView.first_name && (
                        <div className="inner-detail-outer">
                          <div className="inner-detail">
                            <span class="icon">
                              <img alt="" src={UserIcon} />
                            </span>
                            <div className="inner-detail-inner">
                              <label>First Name</label>
                            </div>
                          </div>
                          <div className="inner-detail">
                            <span class="icon"></span>
                            <div className="inner-detail-inner">
                              {isLoading ? (
                                <ThreeDots
                                  height="18"
                                  width="20"
                                  radius="9"
                                  color="#6479f9"
                                  ariaLabel="three-dots-loading"
                                  wrapperStyle={{}}
                                  wrapperClassName=""
                                  visible={true}
                                />
                              ) : (
                                <div className="value-field">{userView.first_name}</div>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                      {userView.middle_name && (
                        <div className="inner-detail-outer">
                          <div className="inner-detail">
                            <span class="icon">
                              <img alt="" src={UserIcon} />
                            </span>
                            <div className="inner-detail-inner">
                              <label>Middle Name</label>
                            </div>
                          </div>
                          <div className="inner-detail">
                            <span class="icon"></span>
                            <div className="inner-detail-inner">
                              {isLoading ? (
                                <ThreeDots
                                  height="18"
                                  width="20"
                                  radius="9"
                                  color="#6479f9"
                                  ariaLabel="three-dots-loading"
                                  wrapperStyle={{}}
                                  wrapperClassName=""
                                  visible={true}
                                />
                              ) : (
                                <div className="value-field">{userView.middle_name}</div>)}
                            </div>
                          </div>
                        </div>
                      )}
                      {userView.last_name && (
                        <div className="inner-detail-outer">
                          <div className="inner-detail">
                            <span class="icon">
                              <img alt="" src={UserIcon} />
                            </span>
                            <div className="inner-detail-inner">
                              <label>Last Name</label>
                            </div>
                          </div>
                          <div className="inner-detail">
                            <span class="icon"></span>
                            <div className="inner-detail-inner">
                              {isLoading ? (
                                <ThreeDots
                                  height="18"
                                  width="20"
                                  radius="9"
                                  color="#6479f9"
                                  ariaLabel="three-dots-loading"
                                  wrapperStyle={{}}
                                  wrapperClassName=""
                                  visible={true}
                                />
                              ) : (
                                <div className="value-field">{userView.last_name}</div>)}
                            </div>
                          </div>
                        </div>
                      )}
                      {userView.role_id && (
                        <div className="inner-detail-outer">
                          <div className="inner-detail">
                            <span class="icon">
                              <img alt="" src={Type} />
                            </span>
                            <div className="inner-detail-inner">
                              <label>Type of Account</label>
                            </div>
                          </div>
                          <div className="inner-detail">
                            <span class="icon"></span>
                            <div className="inner-detail-inner">
                              {isLoading ? (
                                <ThreeDots
                                  height="18"
                                  width="20"
                                  radius="9"
                                  color="#6479f9"
                                  ariaLabel="three-dots-loading"
                                  wrapperStyle={{}}
                                  wrapperClassName=""
                                  visible={true}
                                />
                              ) : (
                                <div className="value-field">{userView.role_id.user_type}</div>)}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="information-right">
                      {userView.email && (
                        <div className="inner-detail-outer">
                          <div className="inner-detail">
                            <span class="icon">
                              <img alt="" src={EmailIcon} />
                            </span>
                            <div className="inner-detail-inner">
                              <label>Email</label>
                            </div>
                          </div>
                          <div className="inner-detail">
                            <span class="icon"></span>
                            <div className="inner-detail-inner">
                              {isLoading ? (
                                <ThreeDots
                                  height="18"
                                  width="20"
                                  radius="9"
                                  color="#6479f9"
                                  ariaLabel="three-dots-loading"
                                  wrapperStyle={{}}
                                  wrapperClassName=""
                                  visible={true}
                                />
                              ) : (
                                <div className="value-field">{userView.email}</div>)}
                            </div>
                          </div>
                        </div>
                      )}
                      {userView.dob && (
                        <div className="inner-detail-outer">
                          <div className="inner-detail">
                            <span class="icon">
                              <img alt="" src={birthIcon} />
                            </span>
                            <div className="inner-detail-inner">
                              <label>Date of birth</label>
                            </div>
                          </div>
                          <div className="inner-detail">
                            <span class="icon"></span>
                            <div className="inner-detail-inner">
                              {isLoading ? (
                                <ThreeDots
                                  height="18"
                                  width="20"
                                  radius="9"
                                  color="#6479f9"
                                  ariaLabel="three-dots-loading"
                                  wrapperStyle={{}}
                                  wrapperClassName=""
                                  visible={true}
                                />
                              ) : (
                                // <div className="value-field">{userView.dob}</div>)}
                                <div className="value-field">{formatDate(userView.dob)}</div>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                      {userView.passport_no && (
                        <div className="inner-detail-outer">
                          <div className="inner-detail">
                            <span class="icon">
                              <img alt="" src={PassportlIcon} />
                            </span>
                            <div className="inner-detail-inner">
                              <label>Passport or ID Number</label>
                            </div>
                          </div>
                          <div className="inner-detail">
                            <span class="icon"></span>
                            <div className="inner-detail-inner">
                              {isLoading ? (
                                <ThreeDots
                                  height="18"
                                  width="20"
                                  radius="9"
                                  color="#6479f9"
                                  ariaLabel="three-dots-loading"
                                  wrapperStyle={{}}
                                  wrapperClassName=""
                                  visible={true}
                                />
                              ) : (
                                <div className="value-field">{userView.passport_no}</div>)}
                            </div>
                          </div>
                        </div>
                      )}
                      {userView.created_at && (
                        <div className="inner-detail-outer">
                          <div className="inner-detail">
                            <span class="icon">
                              <img alt="" src={birthIcon} />
                            </span>
                            <div className="inner-detail-inner">
                              <label>Date Added</label>
                            </div>
                          </div>
                          <div className="inner-detail">
                            <span class="icon"></span>
                            <div className="inner-detail-inner">
                              {isLoading ? (
                                <ThreeDots
                                  height="18"
                                  width="20"
                                  radius="9"
                                  color="#6479f9"
                                  ariaLabel="three-dots-loading"
                                  wrapperStyle={{}}
                                  wrapperClassName=""
                                  visible={true}
                                />
                              ) : (
                                <div className="value-field">{formatDate(userView.created_at)}</div>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="information-inner">
                <div className="information">
                  <div className="information-title">Address</div>
                  <div className="information-outer">
                    <div className="information-left">
                      {userView.city && (
                        <div className="inner-detail-outer">
                          <div className="inner-detail">
                            <span class="icon">
                              <img alt="" src={location} />
                            </span>
                            <div className="inner-detail-inner">
                              <label>City</label>
                            </div>
                          </div>
                          <div className="inner-detail">
                            <span class="icon"></span>
                            <div className="inner-detail-inner">
                              {isLoading ? (
                                <ThreeDots
                                  height="18"
                                  width="20"
                                  radius="9"
                                  color="#6479f9"
                                  ariaLabel="three-dots-loading"
                                  wrapperStyle={{}}
                                  wrapperClassName=""
                                  visible={true}
                                />
                              ) : (
                                <div className="value-field">{userView.city}</div>)}
                            </div>
                          </div>
                        </div>
                      )}
                      {userView.country && (
                        <div className="inner-detail-outer">
                          <div className="inner-detail">
                            <span class="icon">
                              <img alt="" src={location} />
                            </span>
                            <div className="inner-detail-inner">
                              <label>Country</label>
                            </div>
                          </div>
                          <div className="inner-detail">
                            <span class="icon"></span>
                            <div className="inner-detail-inner">
                              {isLoading ? (
                                <ThreeDots
                                  height="18"
                                  width="20"
                                  radius="9"
                                  color="#6479f9"
                                  ariaLabel="three-dots-loading"
                                  wrapperStyle={{}}
                                  wrapperClassName=""
                                  visible={true}
                                />
                              ) : (
                                <div className="value-field">{userView.country}</div>)}
                            </div>
                          </div>
                        </div>
                      )}
                      {userView.state && (
                        <div className="inner-detail-outer">
                          <div className="inner-detail">
                            <span class="icon">
                              <img alt="" src={location} />
                            </span>
                            <div className="inner-detail-inner">
                              <label>State</label>
                            </div>
                          </div>
                          <div className="inner-detail">
                            <span class="icon"></span>
                            <div className="inner-detail-inner">
                              {isLoading ? (
                                <ThreeDots
                                  height="18"
                                  width="20"
                                  radius="9"
                                  color="#6479f9"
                                  ariaLabel="three-dots-loading"
                                  wrapperStyle={{}}
                                  wrapperClassName=""
                                  visible={true}
                                />
                              ) : (
                                <div className="value-field">{userView.state}</div>)}
                            </div>
                          </div>
                        </div>
                      )}
                      {userView.address && (
                        <div className="inner-detail-outer">
                          <div className="inner-detail">
                            <span class="icon">
                              <img alt="" src={location} />
                            </span>
                            <div className="inner-detail-inner">
                              <label>Address</label>
                            </div>
                          </div>
                          <div className="inner-detail">
                            <span class="icon"></span>
                            <div className="inner-detail-inner">
                              {isLoading ? (
                                <ThreeDots
                                  height="18"
                                  width="20"
                                  radius="9"
                                  color="#6479f9"
                                  ariaLabel="three-dots-loading"
                                  wrapperStyle={{}}
                                  wrapperClassName=""
                                  visible={true}
                                />
                              ) : (
                                <div className="value-field"><TruncateFieldView textContent={userView.address} /></div>)}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="information-right">
                      {userView.pincode && (
                        <div className="inner-detail-outer">
                          <div className="inner-detail">
                            <span class="icon">
                              <img alt="" src={PostalCode} />
                            </span>
                            <div className="inner-detail-inner">
                              <label>Postal Code</label>
                            </div>
                          </div>
                          <div className="inner-detail">
                            <span class="icon"></span>
                            <div className="inner-detail-inner">
                              {isLoading ? (
                                <ThreeDots
                                  height="18"
                                  width="20"
                                  radius="9"
                                  color="#6479f9"
                                  ariaLabel="three-dots-loading"
                                  wrapperStyle={{}}
                                  wrapperClassName=""
                                  visible={true}
                                />
                              ) : (
                                <div className="value-field">{userView.pincode}</div>)}
                            </div>
                          </div>
                        </div>
                      )}
                      {userView.mobile_no && (
                        <div className="inner-detail-outer">
                          <div className="inner-detail">
                            <span class="icon">
                              <img alt="" src={Tele} />
                            </span>
                            <div className="inner-detail-inner">
                              <label>Mobile No.</label>
                            </div>
                          </div>
                          <div className="inner-detail">
                            <span class="icon"></span>
                            <div className="inner-detail-inner">
                              {isLoading ? (
                                <ThreeDots
                                  height="18"
                                  width="20"
                                  radius="9"
                                  color="#6479f9"
                                  ariaLabel="three-dots-loading"
                                  wrapperStyle={{}}
                                  wrapperClassName=""
                                  visible={true}
                                />
                              ) : (
                                <div className="value-field">{userView.mobile_no}</div>)}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default MasterProfileDetails;