import React from 'react';
import moment from 'moment';

const TimestampConverter = (timestamp) => {

const localTime = moment.utc(timestamp).tz(global.config.getTimeZone());


// Format the local time in 'hh:mm A' format
const formattedTime = localTime.format('hh:mm A');

return formattedTime;

};

export default TimestampConverter;
