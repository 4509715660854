import React from "react";
import ClientOverview from "../../components/Clients/ClientsOverview";



function ClientOverviewSection()  {
    return(
        <div className="react-outer">
            <ClientOverview />
            
        </div>
    )
}

export default ClientOverviewSection;