import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import AES from "crypto-js/aes";
import axios from "axios";
import Modal from "react-modal";
import PhoneInput from "react-phone-number-input";
import "./VendorOverviewListingSingleDetails.css";
import Countries from "../../../constants/countries.js";
import AccProfileImg from "../../../images/icons/manager-alice.svg";
import ContactProfileImg from "../../../images/icons/contact-icon.svg";
import MailIcon from "../../../images/icons/email.svg";
import PhoneIcon from "../../../images/icons/phone-icon.svg";
import NewVendorIcon from "../../../images/icons/plus-icon.svg";
import profile_image from "../../../images/user/Default_pfp.png";
import crssrImage from "../../../images/singleinvoice/cross-red.svg";
import Plus from "../../../images/icons/plus-black.svg";
import Delete from "../../../images/icons/delete.svg";
import { setHeaderText } from "../../../features/headerText/headerTextSlice.js";
import { logout, reset } from "../../../features/auth/authSlice.js";
import { reset as invoiceReset } from "../../../features/invoice/invoiceSlice.js";
import { reset as headerReset } from "../../../features/headerText/headerTextSlice.js";
import { reset as appSettingsReset } from "../../../features/appSettings/appSettingsSlice.js";
import { useUserById } from "../../TimeOff/timeOffApi.jsx";
import { useGetVendors } from "../../TimeTracker/TimeTrackerOverview/useTimeTracker.jsx";
import TruncateFilterFieldView from "../../helpers/truncateFilterFieldView.js";
import TruncateUserName from "../../helpers/truncateUserName.js";
import TruncateFieldView from "../../helpers/truncateFieldView.js";
import { useVendorView, useDeleteVendorDetails } from "../../../pages/Vendors/VendorDetailsLayout/useVendorDetails.jsx";

const VendorDropdown = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [vendorName, setVendorName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [accManager, setAccManager] = useState("");
  const [accManagerDropdownOpen, setAccManagerDropdownOpen] = useState(false);
  const [userSearchTerm, setUserSearchTerm] = useState("");
  const [accManagerIsValid, setAccManagerIsValid] = useState(false);
  const [contactPoint, setContactPoint] = useState("");
  const [contactPointDropdownOpen, setContactPointDropdownOpen] =
    useState(false);
  // const [contactPointSearchTerm, setContactPointSearchTerm] = useState('');
  const [contactPointIsValid, setContactPointIsValid] = useState(false);
  const [companyWebsite, setCompanyWebsite] = useState("");
  const [isValidUrl, setIsValidUrl] = useState(false);
  const [companyLogo, setCompanyLogo] = useState(null);
  const [companyLogoName, setCompanyLogoName] = useState("");
  const [country, setCountry] = useState("");
  const [address, setAddress] = useState("");
  const [additionalInfo, setAdditionalInfo] = useState("");
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [updateVendorList, setUpdateVendorList] = useState(false);
  const [countrySearchTerm, setCountrySearchTerm] = useState("");
  const [countryDropdownOpen, setCountryDropdownOpen] = useState(false);
  const [countriesList, setCountriesList] = useState([]);
  const [VendorDropdownOpen, setVendorDropdownOpen] = useState(false);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const vendorDropdownRef = useRef(null);
  const countryDropdownRef = useRef(null);
  const accManagerRef = useRef(null);
  const accManagerSelectDropdownRef = useRef(null);
  const contactPointRef = useRef(null);
  const contactPointSelectDropdownRef = useRef(null);

  const user = useSelector((state) => state.auth.user);
  const { appSettings } = useSelector((state) => state.appSettings);
  let token = null;
  if (user) {
    if (user && user.token) {
      token = user.token;
    }
  }

  const { data: userIds = [], isLoading: isLoadingUser } = useUserById(token);
  const {
    data: vendors = [],
    // isLoading: vendorLoading,
    refetch: refetchVendorProfiles,
    // isError: vendorError,
  } = useGetVendors(token);
  const {
    data: vendorView = [],
    isLoading,
    isError: vendorViewError,
  } = useVendorView(selectedVendor, token);
  const deleteVendorMutation = useDeleteVendorDetails(token);

  useEffect(() => {
    setSelectedVendor(props.id);
    const sortedCountries = Countries.slice().sort((a, b) =>
      a.country.localeCompare(b.country)
    );
    setCountriesList(sortedCountries);
    if (vendorViewError) {
      if (vendorViewError.response?.data?.status === "deleted") {
        // Handle the case where the user is deleted
        localStorage.removeItem("user");
        dispatch(logout());
        dispatch(reset());
        dispatch(invoiceReset());
        dispatch(headerReset());
        dispatch(appSettingsReset());
        toast.error("This vendor is deleted!", {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/");
        return;
      }
      // Handle other errors
      alert(`Error fetching users`);
    }
  }, []);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setVendorName("");
    setEmail("");
    setMobileNo("");
    setAccManager("");
    setUserSearchTerm("");
    setAccManagerDropdownOpen(false);
    setAccManagerIsValid(false);
    setContactPoint("");
    setContactPointDropdownOpen(false);
    setContactPointIsValid(false);
    setCompanyWebsite("");
    setCompanyLogo(null);
    setCompanyLogoName("");
    setCountry("");
    setAddress("");
    setAdditionalInfo("");
  };

  const handleLogoUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setCompanyLogo(e.target.files[0]);
      setCompanyLogoName(file.name);
    } else {
      setCompanyLogo(null);
      setCompanyLogoName("");
    }
  };

  const handleToggleAccManagerDropdown = () => {
    setUserSearchTerm("");
    setAccManagerDropdownOpen((prevOpen) => !prevOpen);
  };

  const handleSelectAccManager = (selectedAccManager) => {
    setAccManager(selectedAccManager);
    setUserSearchTerm("");
    setAccManagerDropdownOpen(false);
  };

  const handleAccManagerSearchInputChange = (e) => {
    setUserSearchTerm(e.target.value);
  };

  const handleToggleContactPointDropdown = () => {
    setUserSearchTerm("");
    setContactPointDropdownOpen((prevOpen) => !prevOpen);
  };

  const handleSelectContactPoint = (selectedContactPoint) => {
    setContactPoint(selectedContactPoint);
    setUserSearchTerm("");
    setContactPointDropdownOpen(false);
  };

  const handleContactPointSearchInputChange = (e) => {
    setUserSearchTerm(e.target.value);
  };

  const filteredUsers = userIds.filter((user) => {
    const fullName = user.first_name
      ? `${user.first_name} ${user.middle_name || ""} ${user.last_name}`
      : `${user.company_first_name
        ? user.company_first_name +
        (user.company_last_name && user.company_last_name)
        : user.company_name
      }`;
    return fullName.toLowerCase().includes(userSearchTerm.toLowerCase());
  });

  const handleToggleCountryDropdown = () => {
    setCountrySearchTerm("");
    setCountryDropdownOpen((prevOpen) => !prevOpen);
  };

  const handleCountrySearchInputChange = (e) => {
    setCountrySearchTerm(e.target.value);
  };

  const filteredCountries = countriesList.filter((country) => {
    return country.country
      .toLowerCase()
      .includes(countrySearchTerm.toLowerCase());
  });

  const handleClickOutsideDropdown = (event) => {
    const isClickInsideCountryDropdown =
      countryDropdownRef.current &&
      countryDropdownRef.current.contains(event.target);
    const isClickInsideVendorDropdown =
      vendorDropdownRef.current &&
      vendorDropdownRef.current.contains(event.target);
    const isClickInsideAccManagerSelectDropdown =
      accManagerSelectDropdownRef.current &&
      accManagerSelectDropdownRef.current.contains(event.target);
    const isClickInsideContactPointSelectDropdown =
      contactPointSelectDropdownRef.current &&
      contactPointSelectDropdownRef.current.contains(event.target);

    if (!isClickInsideCountryDropdown) {
      setCountryDropdownOpen(false);
    }
    if (!isClickInsideVendorDropdown) {
      setVendorDropdownOpen(false);
    }
    if (!isClickInsideAccManagerSelectDropdown) {
      setAccManagerDropdownOpen(false);
    }
    if (!isClickInsideContactPointSelectDropdown) {
      setContactPointDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutsideDropdown);
    return () => {
      document.removeEventListener("click", handleClickOutsideDropdown);
    };
  }, []);

  const handleDelete = () => {
    setIsDeleteModalOpen(true);
  };

  const handleConfirmDelete = async () => {
    try {
      setIsSubmitLoading(true);
      await deleteVendorMutation.mutateAsync(selectedVendor);
      toast.success("Vendor removed successfully", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      global.config.activityLog(
        window.location.href,
        "Vendor",
        `Vendor (${selectedVendor}) deleted successfully`
      );
      // handleCloseModal();
      refetchVendorProfiles();
      navigate("../overview");
    } catch (error) {
      console.error(error);
      global.config.slackMessage(error.toString());
      if (error.response?.data?.status === "deleted") {
        localStorage.removeItem("user");
        dispatch(logout());
        dispatch(reset());
        dispatch(invoiceReset());
        dispatch(headerReset());
        dispatch(appSettingsReset());
        toast.error("This user is deleted!", {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/");
        return;
      }
      toast.error("Something went wrong", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } finally {
      setIsSubmitLoading(false);
    }
    setIsDeleteModalOpen(false);
  };

  const handleCancelDelete = () => {
    setIsDeleteModalOpen(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setAccManagerIsValid(false);
    setContactPointIsValid(false);
    setIsValidUrl(false);
    const pattern = /^(ftp|http|https):\/\/[^ "]+$/;
    if (accManager === "" || contactPoint === "" || vendorName === "") {
      if (accManager === "") {
        setAccManagerIsValid(true);
        accManagerRef && accManagerRef.current.focus();
        return;
      } else if (contactPoint === "") {
        setContactPointIsValid(true);
        contactPointRef && contactPointRef.current.focus();
        return;
      }

      toast.error("Please fill all required fields!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    if (!pattern.test(companyWebsite)) {
      toast.error("It's not a valid URL.", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setIsValidUrl(true);
      setIsSubmitLoading(false);
      return;
    }
    const requestData = {
      vendor_name: vendorName,
      email,
      mobile_no: mobileNo,
      address,
      country,
      acc_manager: accManager,
      contact_point: contactPoint,
      company_website: companyWebsite,
      additional_info: additionalInfo,
    };
    // Create a new FormData object
    const formData = new FormData();
    formData.append("company_logo", companyLogo);

    // Append the other request data to the form data
    for (let key in requestData) {
      formData.append(key, requestData[key]);
    }
    try {
      setIsSubmitLoading(true);
      const response = await axios.post(
        "/api/vendor-details/create",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = response.data;
      if (data.status === "exists") {
        toast.error("Vendor already exists", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (data.status === "error") {
        toast.error("Something went wrong", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.success("Vendor registered successfully", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        handleCloseModal();
        global.config.activityLog(
          window.location.href,
          "Vendor",
          `Vendor (${vendorName}) Registered Successfully`
        );
      }
    } catch (error) {
      console.log(error);
      global.config.slackMessage(error.toString());
      if (error.response?.data?.status === "deleted") {
        localStorage.removeItem("user");
        dispatch(logout());
        dispatch(reset());
        dispatch(invoiceReset());
        dispatch(headerReset());
        dispatch(appSettingsReset());
        toast.error("This user is deleted!", {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/");
        return;
      }
      alert("Something went wrong");
    } finally {
      setIsSubmitLoading(false);
      if (updateVendorList === false) {
        setUpdateVendorList(true);
      } else {
        setUpdateVendorList(false);
      }
    }
  };

  const toggleVendorDropdown = () => {
    setVendorDropdownOpen(!VendorDropdownOpen);
  };
  const selectVendorOption = (selectedVendor) => {
    setSelectedVendor(selectedVendor);
    setVendorDropdownOpen(false);
    navigate(
      `/vendor/details/${encodeURIComponent(
        AES.encrypt(selectedVendor, "encryptionKey")
          .toString()
          .replace(/\//g, "%2F")
      )}`
    );
  };
  //   const handleOpenModal = () => {
  //     setIsModalOpen(true);
  //   };

  return (
    <div className="client-details-overview-outer">
      <div className="clint-title">Vendor</div>
      <div className="client-with-invitation">
        <div className="custom-dropdown">
          <div
            ref={vendorDropdownRef}
            className={`custom-dropdown-user-new-outer custom-dropdown-select ${VendorDropdownOpen ? "open" : ""
              }`}
          >
            <div
              className="selected-option custom-dropdown-user-new"
              onClick={toggleVendorDropdown}
            >
              {selectedVendor
                ? vendors.find((item) => item._id === selectedVendor)
                  ?.vendor_name
                : "Select Vendor"}
            </div>
            {VendorDropdownOpen && (
              <div className="custom-dropdown-new">
                {vendors.length > 0 && (
                  <ul className="type-list">
                    {vendors
                      .slice()
                      .sort((a, b) =>
                        a.vendor_name
                          .toLowerCase()
                          .localeCompare(b.vendor_name.toLowerCase())
                      )
                      .map((vendor) => (
                        <li onClick={() => selectVendorOption(vendor._id)}>
                          {vendor.vendor_name && `${vendor.vendor_name}`}
                        </li>
                      ))}
                    {/* Add more Vendor options as needed */}
                  </ul>
                )}
              </div>
            )}
          </div>
          <span className="custom-arrow"></span>
        </div>
        <div className="invite-and-addclient">
          <div className="invite-client-outer">
            <div className="open-client-profile">
              <Link
                to={`/vendor/profile/${encodeURIComponent(
                  AES.encrypt(props.id, "encryptionKey")
                    .toString()
                    .replace(/\//g, "%2F")
                )}`}
              >
                Open vendor profile
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="client-professional-details-outer">
        <div className="client-detail-row-one">
          <div className="client-status">
            <div className="client-status-text">Vendor Status</div>
            {isLoading ? (
              <div>
                <ThreeDots
                  height="18"
                  width="20"
                  radius="9"
                  color="#6479f9"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
              </div>
            ) : (
              <>
                {vendorView.is_active === false ? (
                  <div className="inactive">Inactive</div>
                ) : vendorView.is_active === true ? (
                  <div className="client-status-bar">Active</div>
                ) : (
                  vendorView.is_active
                )}
              </>
            )}
          </div>
          <div className="customer-since">
            <div className="customer-since-text">Vendor Since</div>
            {isLoading ? (
              <div>
                <ThreeDots
                  height="18"
                  width="20"
                  radius="9"
                  color="#6479f9"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
              </div>
            ) : (
              <div className="customer-since-date">
                {new Date(vendorView.created_at).toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })}
              </div>
            )}
          </div>
        </div>
        <div className={`client-details-border no-border`} >
          <div className="client-detail-row-two">
            <div className="point-of-contact">
              <div className="point-of-contact-text">Point of Contact</div>
              {isLoading ? (
                <div>
                  <ThreeDots
                    height="18"
                    width="20"
                    radius="9"
                    color="#6479f9"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                </div>
              ) : vendorView.poc_user_name ? (
                <div className="client-manager-image-with-name">
                  <span className="contact-profile-icon">
                    <img src={ContactProfileImg} alt="profile" />
                  </span>
                  <div className="point-of-contact-name">
                    <TruncateFieldView textContent={vendorView.poc_user_name} />
                  </div>
                </div>
              ) : (
                <div>Point of Contact is not assigned</div>
              )}
            </div>
            <div className="email-with-icon">
              <span className="email-icon">
                <img src={MailIcon} alt="mail-icon" />
              </span>
              {isLoading ? (
                <div>
                  <ThreeDots
                    height="18"
                    width="20"
                    radius="9"
                    color="#6479f9"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                </div>
              ) : (
                <div className="mail-addrss-text">
                  <a href={`mailto:${vendorView.email}`}>
                    {vendorView?.email && (
                      <TruncateFieldView textContent={vendorView?.email} />
                    )}
                  </a>
                </div>
              )}
            </div>
            <div className="contact-with-icon">
              <span className="contact-icon">
                <img src={PhoneIcon} alt="contact-icon" />
              </span>
              {isLoading ? (
                <div>
                  <ThreeDots
                    height="18"
                    width="20"
                    radius="9"
                    color="#6479f9"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                </div>
              ) : (
                <div className="contact-number-text">
                  <a href={`tel:${vendorView.mobile_no}`}>
                    {vendorView.mobile_no}
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default VendorDropdown;
