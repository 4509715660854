import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { QueryClient, QueryClientProvider } from "react-query";
import { UserbackProvider } from "@userback/react";
import App from "./App";
import { store } from "./app/store";
import reportWebVitals from "./reportWebVitals";

import "./config";
import "./index.css";
import "./assets/custom.css";

// Create a new QueryClient instance
const queryClient = new QueryClient();

// Get the root container from the DOM
const container = document.getElementById("root");

// Ensure the container exists before creating the root
if (container) {
  const root = createRoot(container);
  console.warn = () =>{};

  // Render the application
  root.render(
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <UserbackProvider token="3783|81189|joO0IGuxtmy9vBXjjARKh5s0WfPB00lw6wOMFeMUVGL4pwzibG">
            <App />
          </UserbackProvider>
        </Provider>
      </QueryClientProvider>
    </React.StrictMode>
  );

  // Measure performance of the app
  reportWebVitals();
  
} else {
  console.error("Root container not found. Failed to render the application.");
}