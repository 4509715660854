import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ThreeDots } from "react-loader-spinner";
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, LabelList } from 'recharts';

import { processedExpensesByLocationFilter } from '../../Services/reportExpense';
import { reportingFilterComparisonConst } from '../../../../../../constants/reportingFilterComparisonConst';
import { reportingFilterDateRangesConst } from '../../../../../../constants/reportingFilterDateRangesConst';

const ExpensePerLocationFiterGraph = ({
  expenseData,
  expenseDataLoading,
  expenseDataError,
  selectedDateRange,
  selectedComparison,
  selectedFrequency,
  selectedYearRange,
  selectedMonthRange,
  selectedComparisonYear,
  selectedComparisonMonth,
}) => {
  const reportingFinancialExpense = useSelector((state) => state.reportingExpense);
  const { forex } = useSelector((state) => state.forex);
  const settings = JSON.parse(localStorage.getItem("settings"));

  const currentYear = reportingFinancialExpense.expYear || new Date().getFullYear();
  const previousYear = currentYear - 1;
  const isCurrentYear = currentYear === new Date().getFullYear();

  const processedData = useMemo(() => {
    return processedExpensesByLocationFilter(
      expenseData,
      reportingFinancialExpense,
      settings,
      forex,
      selectedDateRange,
      selectedComparison,
      selectedYearRange,
      selectedMonthRange,
      selectedComparisonYear,
      selectedComparisonMonth,
    );
  }, [expenseData, reportingFinancialExpense, settings, forex, selectedDateRange, selectedComparison, selectedYearRange, selectedMonthRange, selectedComparisonYear, selectedComparisonMonth,]);

  if (expenseDataLoading) return <div className="outter-load-table">
    <ThreeDots
      height="80"
      width="80"
      radius="9"
      color="#6479f9"
      ariaLabel="three-dots-loading"
      wrapperStyle={{}}
      wrapperClassName=""
      visible={true}
    />
  </div>;
  if (expenseDataError) return <div>Error loading data.</div>;

  const calculateXTicks = () => {
    if (!processedData.length) return [];
    const maxDataValue = Math.max(...processedData.map(item => Math.max(item.currentYear, item.lastYear)));
    if (maxDataValue === 0) return [0];
    // const tickInterval = 10; // Set the interval between ticks
    const tickInterval = Math.ceil(maxDataValue / 10);
    const numOfTicks = Math.ceil(maxDataValue / tickInterval);
    const ticks = [];

    for (let i = 0; i <= numOfTicks + 4; i++) { // Adjust the number of ticks
      ticks.push(i * tickInterval);
    }

    return ticks;
  }
  // const xAxisTicks = calculateXTicks(processedData);
  const xAxisTicks = calculateXTicks();

  const getBarName = (isCurrent, selectedRange, selectedComparison, selectedYearRange, selectedMonthRange, selectedComparisonYear, selectedComparisonMonth) => {
    const rangeNames = {
      [reportingFilterDateRangesConst.This_Year]: 'This Year',
      [reportingFilterDateRangesConst.This_Quarter]: 'This Quarter',
      [reportingFilterDateRangesConst.First_Half]: 'First Half',
      [reportingFilterDateRangesConst.This_Month]: 'This Month',
      [reportingFilterDateRangesConst.Year_Selected]: selectedYearRange ? `Year ${selectedYearRange.getFullYear()}` : `Selected Year`,
      [reportingFilterDateRangesConst.Month_Selected]: selectedMonthRange ? `Month ${selectedMonthRange.toLocaleString('default', { month: 'long' })} ${selectedMonthRange.getFullYear()}` : `Selected Month`,
    };

    const comparisonNames = {
      [reportingFilterComparisonConst.Last_Year]: 'Last Year',
      [reportingFilterComparisonConst.Last_Quarter]: 'Last Quarter',
      [reportingFilterComparisonConst.Second_Half]: 'Second Half',
      [reportingFilterComparisonConst.Last_Month]: 'Last Month',
      [reportingFilterComparisonConst.Year_Select]: selectedComparisonYear ? `Year ${selectedComparisonYear.getFullYear()}` : `Previous Year`,
      [reportingFilterComparisonConst.Month_Select]: selectedComparisonMonth ? `Month ${selectedComparisonMonth.toLocaleString('default', { month: 'long' })} ${selectedComparisonMonth.getFullYear()}` : `Previous Month`,
    };

    const rangeName = rangeNames[selectedRange] || 'Current Range';
    const comparisonName = comparisonNames[selectedComparison] || 'Comparison Range';

    return isCurrent
      ? `${rangeName}`
      : `${comparisonName}`;
  };

  const calculateChartHeight = (dataLength) => {
    const rowHeight = 50;
    const baseHeight = 100;
    return dataLength * rowHeight + baseHeight;
  };

  const calculateLeftMargin = (data) => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    context.font = '12px Arial';

    const longestWordLength = data.reduce((maxLength, item) => {
      const firstWord = item.name.split(' ')[0];
      const wordWidth = context.measureText(firstWord).width;
      return Math.max(maxLength, wordWidth);
    }, 0);

    return Math.max(30, longestWordLength - 150);
  };

  const leftMargin = calculateLeftMargin(processedData);

  return (
    <div className='graph-project-per-hour' style={{ width: '100%', height: Math.max(542, calculateChartHeight(processedData.length)), }}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          layout="vertical"
          data={processedData}
          barSize={13} // Set the height of each bar
          margin={{
            top: 20,
            right: 42,
            left: leftMargin,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" horizontal={false} vertical={true} />
          <XAxis
            type="number"
            domain={[0, 'dataMax + 10']}
            tickFormatter={(tick) => `${new Intl.NumberFormat("en-US", { style: "currency", currency: settings.currency, }).format(tick)}`}
            ticks={xAxisTicks}
          />
          <YAxis
            type="category"
            dataKey="name"
            // width={150} // Ensure enough width for expense categories
            tickMargin={10}
          />
          <Tooltip formatter={(value) => `${new Intl.NumberFormat("en-US", { style: "currency", currency: settings.currency, }).format(value)}`} />
          <Legend />
          <Bar dataKey="currentYear" name={getBarName(true, selectedDateRange, selectedComparison, selectedYearRange, selectedMonthRange, selectedComparisonYear, selectedComparisonMonth)} fill="#87ABE2">
            <LabelList dataKey="currentYear" position="right" formatter={(value) => `${new Intl.NumberFormat("en-US", { style: "currency", currency: settings.currency, }).format(value)}`} />
          </Bar>
          <Bar dataKey="lastYear" name={getBarName(false, selectedDateRange, selectedComparison, selectedYearRange, selectedMonthRange, selectedComparisonYear, selectedComparisonMonth)} fill="#C7B6F6">
            <LabelList dataKey="lastYear" position="right" formatter={(value) => `${new Intl.NumberFormat("en-US", { style: "currency", currency: settings.currency, }).format(value)}`} />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}

export default ExpensePerLocationFiterGraph;