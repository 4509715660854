import React from 'react'
import PaymentOption from '../../../components/AccountSettings/PaymentOptions/PaymentOption';

const paymentOption = () => {
    return (
        <div>
            <PaymentOption />
        </div>
    )
}

export default paymentOption