import axios from "axios";
import Modal from "react-modal";
import { AES } from 'crypto-js';
import { saveAs } from "file-saver";
import { toast } from "react-toastify";
import React, { useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { ThreeDots } from "react-loader-spinner";
import { useReactToPrint } from "react-to-print";
import { useNavigate, Link } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import pImage from "../../../images/singleinvoice/print.svg";
import dImage from "../../../images/singleinvoice/delete.svg";
import editImage from "../../../images/singleinvoice/edit.svg";
import invStatusConsts from "../../../constants/invStatusConsts";
import { logout, reset } from "../../../features/auth/authSlice";
import crssrImage from "../../../images/singleinvoice/cross-red.svg";
import dowImage from "../../../images/singleinvoice/download_icon.svg";
import { INVOICE_STATUS } from "../../../constants/invoice.js";
import { reset as invoiceReset } from "../../../features/invoice/invoiceSlice.js";
import { reset as headerReset } from "../../../features/headerText/headerTextSlice.js";
import { reset as appSettingsReset } from "../../../features/appSettings/appSettingsSlice.js";

const InvoiceActions = (props) => {
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [isCreatingPdf, setIsCreatingPdf] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [selectedInvoiceId, setSelectedInvoiceId] = useState("");
  const user = useSelector(
    (state) => state.auth.user
  )
  let token = null;
  if (user) {
    if (user && user.token) {
      token = user.token;
    }
  }

  const PENDING = 0;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let encodedId;

  const handlePrint = useReactToPrint({
    content: () => props.componentRef.current,
    documentTitle: "invoice-data",
  });

  const createPdf = async (e) => {
    e.preventDefault();
    setIsCreatingPdf(true); // Set loading state to true

    try {
      await axios.post(`/api/invoice/create`, props.invoiceView, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const response = await axios.get(`/api/invoice/download/created/pdf`, {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const filename = `${props.invoiceData.inv_number}.pdf`;
      const pdfBlob = new Blob([response.data], { type: "application/pdf" });
      saveAs(pdfBlob, filename);
      toast.success(`Invoice "${props.invoiceData.inv_number}" downloaded successfully!`, {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      global.config.activityLog(
        window.location.href,
        "Invoice",
        `Invoice "${props.invoiceData.inv_number}" Downloaded Successfully!`
      );
    } catch (error) {
      console.error(error);
      global.config.slackMessage(error.toString());
      if (error.response?.data?.status === "deleted") {
        localStorage.removeItem("user");
        dispatch(logout());
        dispatch(reset());
        dispatch(invoiceReset());
        dispatch(headerReset());
        dispatch(appSettingsReset());
        toast.error('This user is deleted!', {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/");
        return;
      }
      // Display an error message to the user
      alert("Something went wrong while creating the PDF.");
    } finally {
      setIsCreatingPdf(false); // Set loading state to false, regardless of success or failure
    }
  };

  if (props.invoiceData) {
    const encryptedId = AES.encrypt(props.invoiceData._id, 'encryptionKey').toString();
    encodedId = encodeURIComponent(encryptedId).replace(/\//g, '%2F');
  }

  const handleDeleteInvoice = async (id) => {
    setSelectedInvoiceId(id);
    setDeleteModalIsOpen(true);
  };

  const handleModalConfirm = async () => {
    try {
      setIsSubmitLoading(true);
      await axios.get(
        `/api/delete/invoice/${selectedInvoiceId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      toast.success('Invoice removed successfully!', {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      global.config.activityLog(
        window.location.href,
        "Invoice",
        `Invoice "${props.invoiceData.inv_number}" removed Successfully!`
      );
      navigate("/user/invoices");
    } catch (error) {
      console.log(error);
      global.config.slackMessage(error.toString());
      if (error.response?.data?.status === "deleted") {
        localStorage.removeItem("user");
        dispatch(logout());
        dispatch(reset());
        dispatch(invoiceReset());
        dispatch(headerReset());
        dispatch(appSettingsReset());
        toast.error('This user is deleted!', {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/");
        return;
      }
      toast.error("Something went wrong");
    } finally {
      setIsSubmitLoading(false);
    }
    setDeleteModalIsOpen(false);
  };

  const handleModalCancel = () => {
    setDeleteModalIsOpen(false);
  };

  return (
    <div className="s1-contain">
      <div className="bill-num-si">
        <a className="invoice-num-01">
          {props.invoiceData ? (
            props.invoiceData.inv_number
          ) : (
            <ThreeDots
              height="50"
              width="80"
              radius="9"
              color="#6479f9"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          )}
        </a>
        {props.invoiceData && (props.invoiceData.inv_status === invStatusConsts.Approved) && (<div className="approve-top">Approved</div>)}
        {props.invoiceData && (props.invoiceData.inv_status === invStatusConsts.Rejected) && (<div className="reject-top">Rejected</div>)}
      </div>
      <div className="btn-aary">
        {props.invoiceData ? (
          <>
            {props.invoiceData.inv_status === PENDING &&
              props.invoiceData.is_automatic_inv === false && (
                <Link className="editbtn-in-sle" to={`../invoice/edit/${encodedId}`}>
                  <img src={editImage} alt="Edit Icon" /> Edit Invoice
                </Link>
              )}
          </>
        ) : (
          <div className="loader-in-action-btn-arry">
            <ThreeDots
              height="18"
              width="20"
              radius="9"
              color="#6479f9"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
        )}

        {props.invoiceData ? (
          <a className="editbtn-in-slp" onClick={handlePrint}>
            {" "}
            <img src={pImage} alt="printer Icon" /> Print
          </a>
        ) : (
          <div className="loader-in-action-btn-arry">
            <ThreeDots
              height="18"
              width="20"
              radius="9"
              color="#6479f9"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
        )}
        {props.invoiceData ? (
          <a className="editbtn-in-sldow" onClick={createPdf}>
            {isCreatingPdf === true ? (
              <ThreeDots
                height="18"
                width="20"
                radius="9"
                color="#6479f9"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            ) : (
              <>
                <img src={dowImage} alt="Edit Icon" /> Download
              </>
            )}
          </a>
        ) : (
          <div className="loader-in-action-btn-arry">
            <ThreeDots
              height="18"
              width="20"
              radius="9"
              color="#6479f9"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
        )}
        {props.invoiceData && props.invoiceData._id ? (
          props.invoiceData.inv_payment_status === 0 && (
            <a
              className="editbtn-in-sld"
              onClick={() => handleDeleteInvoice(props.invoiceData._id)}
            >
              {" "}
              <img src={dImage} alt="Edit Icon" /> Delete
            </a>
          )
        ) : (
          <div className="loader-in-action-btn-arry">
            <ThreeDots
              height="18"
              width="20"
              radius="9"
              color="#6479f9"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
        )}
      </div>
      {/* delete modal */}
      <Modal
        isOpen={deleteModalIsOpen}
        onRequestClose={!isSubmitLoading ? handleModalCancel : undefined}
        contentLabel="Confirm Deletion"
        style={{
          content: {
            height: "41%",
            width: "30%",
            position: "fixed",
            top: "40%",
            left: "50%",
            transform: "translate(-17.5%, 0%)",
            parent: document.querySelector(".admin-outer.time.tracker"),
          },
        }}
      >
        <div className="modal del-pop-modal">
          <div className="outter-modal-main delmodalicon">
            <a onClick={handleModalCancel} disabled={isSubmitLoading}>
              <img src={crssrImage} alt="Close Icon" />
            </a>
          </div>
          <h2>Confirm Deletion?</h2>
          <div>
            <div className="modal-content-del">
              <p>Are you sure you want to delete this Invoice?</p>
            </div>
            <div className="button-container btn-2-in-modal">
              <button className="buttons-rejected" onClick={handleModalConfirm} disabled={isSubmitLoading}>
                {isSubmitLoading ?
                  <div className="outter-load-table">
                    <ThreeDots
                      height="18"
                      width="20"
                      radius="9"
                      color="white"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  </div> : "Delete"}
              </button>
              <button className="Reject-singlebtn" onClick={handleModalCancel} disabled={isSubmitLoading}>
                Close
              </button>
            </div>
          </div>
        </div>
      </Modal>
      {/* modal end */}
    </div>
  );
};

export default InvoiceActions;