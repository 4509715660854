import React, { useState, useRef } from 'react';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Drive from "../../../../images/icons/drive.svg"

const GoogleDriveModal = () => {
    const [expandedFolder, setExpandedFolder] = useState(null);
    const [driveDropdownOpen, setDriveDropdownOpen] = useState(false);
    const [showSearchInput, setShowSearchInput] = useState(false);
    const [selectedDrive, setSelectedDrive] = useState(null);
    const driveDropdownRef = useRef(null);

    const GoogleDriveData = [
        { type: "folder", name: "Folder1", size: "", updated: "Sep 24, 18:29" },
        { type: "file", name: "File1.pdf", size: "10MB", updated: "Sep 24, 18:29", parent: "Folder1" },
        { type: "file", name: "File2.docx", size: "1MB", updated: "Sep 24, 18:29", parent: "Folder1" },
        { type: "folder", name: "Folder2", size: "", updated: "Sep 24, 18:29" },
        { type: "file", name: "File3.png", size: "5MB", updated: "Sep 24, 18:29", parent: "Folder2" },
        { type: "file", name: "File4.xlsx", size: "3MB", updated: "Sep 24, 18:29", parent: "Folder2" },
        { type: "folder", name: "Folder3", size: "", updated: "Sep 24, 18:29" },
        { type: "file", name: "File5.jpg", size: "7MB", updated: "Sep 24, 18:29", parent: "Folder3" },
        { type: "file", name: "File6.txt", size: "2MB", updated: "Sep 24, 18:29", parent: "Folder3" },
        // Add more folders and files as needed
    ];

    const toggleFolder = (folderName) => {
        setExpandedFolder((prevExpandedFolder) => (prevExpandedFolder === folderName ? null : folderName));
    };

    const filteredData = expandedFolder ? GoogleDriveData.filter((item) => item.parent === expandedFolder) : [];


    const toggleDriveDropdown = () => {
        setDriveDropdownOpen(!driveDropdownOpen);
      };
    
      const selectDriveOption = (selectedDrive) => {
        setSelectedDrive(selectedDrive);
        setDriveDropdownOpen(false);
      };

      const toggleSearchInput = () => {
        setShowSearchInput(!showSearchInput);
      };

    return (
        <div>
            <div className="google-drive-outer">
            {showSearchInput && (
                    <div className='search-input-show'>
                    <input type="text" placeholder="Search..." />
                    </div>
                )}
                <div className="google-drive-top">
                    <div className="google-drive-search">
                        <div className='search-icon' onClick={toggleSearchInput}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M23.7913 22.7103L16.5459 15.5328C19.7285 11.8397 19.5477 6.26379 16.0396 2.75172C14.2313 0.905172 11.8082 0 9.42121 0C7.03426 0 4.61115 0.905172 2.76669 2.75172C-0.92223 6.44483 -0.92223 12.3828 2.76669 16.0759C4.57498 17.8862 6.9981 18.8276 9.42121 18.8276C11.5912 18.8276 13.7973 18.0672 15.5332 16.5466L22.7787 23.7603C22.8872 23.869 23.068 23.9414 23.2488 23.9414C23.4297 23.9414 23.6105 23.869 23.7552 23.7241C24.0445 23.4707 24.0445 23 23.7913 22.7103ZM3.77933 15.0259C2.26037 13.5414 1.46472 11.5138 1.46472 9.41379C1.46472 7.31379 2.29653 5.28621 3.77933 3.76552C5.2983 2.24483 7.28742 1.44828 9.42121 1.44828C11.555 1.44828 13.5441 2.28103 15.0631 3.76552C16.5459 5.28621 17.3777 7.27759 17.3777 9.41379C17.3777 11.55 16.5459 13.5414 15.0631 15.0621C13.5441 16.5466 11.5188 17.3793 9.42121 17.3793C7.32359 17.3793 5.2983 16.5466 3.77933 15.0259Z" fill="#6A7988"/>
                            </svg>
                        </div>
                    </div>
                    <div className="google-drive-account-select">
                    <div className="custom-dropdown">
                        <div ref={driveDropdownRef} className={`custom-dropdown-user-new-outer custom-dropdown-select ${driveDropdownOpen ? 'open' : ''}`}>
                            <div className="selected-option custom-dropdown-user-new" onClick={toggleDriveDropdown}>
                              <img src={Drive} />{selectedDrive ? selectedDrive : 'Select Google Drive'}
                            </div>
                            {driveDropdownOpen && (
                            <div className="custom-dropdown-new">
                                <ul className="type-list">
                                <li onClick={() => selectDriveOption('My Drive')}>My Drive</li>
                                <li onClick={() => selectDriveOption('Shared with me')}>Shared with me</li>
                                {/* Add more drive options as needed */}
                                </ul>
                            </div>
                            )}
                        </div>
                        </div>
                    </div>
                </div>
                
                <div className="google-drive-table">
                    <DataTable
                        value={GoogleDriveData.filter((item) => item.type === "folder")}
                        resizableColumns
                        columnResizeMode="fit"
                        rows={10}
                        expandedRows={[expandedFolder]}
                        onRowToggle={(e) => toggleFolder(e.data.name)}
                    >
                        <Column header="Name" body={(rowData) => (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="21" viewBox="0 0 26 21" fill="none">
                                    <path d="M2.221 1H6.66294C6.94858 1 7.22518 1.10014 7.44461 1.283L8.85161 2.4555C8.90647 2.50122 8.97561 2.52625 9.04702 2.52625H21.757C22.4314 2.52625 22.978 3.07291 22.978 3.74725V6.4945C22.978 6.66309 23.1147 6.79976 23.2833 6.79976H24.5043C25.1737 6.79976 25.7163 7.3424 25.7163 8.01179C25.7163 8.10421 25.7057 8.19632 25.6848 8.28634L23.1977 18.9811C23.069 19.5341 22.5762 19.9255 22.0084 19.9255H2.221C1.54666 19.9255 1 19.3789 1 18.7045V2.221C1 1.54666 1.54666 1 2.221 1ZM21.757 6.4945V4.0525C21.757 3.88392 21.6204 3.74725 21.4518 3.74725H8.93651C8.65087 3.74725 8.37427 3.64711 8.15484 3.46425L6.74784 2.29175C6.69299 2.24604 6.62384 2.221 6.55243 2.221H2.52625C2.35767 2.221 2.22101 2.35767 2.221 2.52625L2.22019 15.2584C2.22019 15.309 2.26118 15.35 2.31176 15.35C2.3544 15.35 2.39141 15.3206 2.40099 15.279L4.13934 7.7462C4.26719 7.1922 4.76051 6.79976 5.32907 6.79976H21.4518C21.6204 6.79976 21.757 6.66309 21.757 6.4945ZM3.21522 18.7045H21.766C21.9079 18.7045 22.0311 18.6067 22.0633 18.4684L24.4059 8.39513C24.4441 8.23092 24.342 8.06685 24.1777 8.02867C24.1551 8.0234 24.1319 8.02073 24.1086 8.02073L5.78297 8.01954C5.49867 8.01953 5.25199 8.21575 5.18806 8.49277L2.91778 18.3306C2.87988 18.4949 2.98231 18.6588 3.14658 18.6967C3.16909 18.7019 3.19212 18.7045 3.21522 18.7045Z" fill="#6479F8" stroke="#6479F8" strokeWidth="0.3"/>
                                </svg>
                                <span style={{ paddingLeft: '17px' }}>{rowData.name}</span>
                            </div>
                        )} sortable tyle={{ width: "60%" }}/>
                        <Column field="size" header="Size" sortable style={{ width: "15%" }}></Column>
                        <Column field="updated" header="Updated" sortable style={{ width: "25%", paddingLeft: "10px" }}></Column>
                    </DataTable>
                </div>
            </div>
            {filteredData.length > 0 && (
                <div className="google-drive-table">
                    <DataTable
                        value={filteredData}
                        resizableColumns
                        columnResizeMode="fit"
                        rows={10}
                    >
                        <Column field="name" header="Name" sortable style={{ width: "65%" }}></Column>
                        <Column field="size" header="Size" sortable style={{ width: "10%" }}></Column>
                        <Column field="updated" header="Updated" sortable style={{ width: "25%" }}></Column>
                    </DataTable>
                </div>
            )}
        </div>
    );
};

export default GoogleDriveModal;
