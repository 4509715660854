import "rsuite/dist/rsuite.min.css";
import "react-toastify/dist/ReactToastify.css";
import React, { useState, useEffect, useRef } from "react";
import { RotatingLines } from 'react-loader-spinner';
import { useSelector, useDispatch } from "react-redux";
import { ThreeDots } from "react-loader-spinner";
import Modal from "react-modal";
import { useNavigate, Link } from "react-router-dom";
import { toast } from "react-toastify";
import UserIcon from "../../../images/icons/user-icon.svg";
import addicon from "../../../images/icons/addicon.svg";
import InviteIcon from "../../../images/icons/invite.svg";
import dletImage from "../../../images/singleinvoice/delete.svg";
import crssrImage from "../../../images/singleinvoice/cross-red.svg";
import { setMasterDashboardDate } from "../../../features/masterDashBoard/masterDashBoardSlice";
import Calendar from "../../../helpers/dateComponents/Calendar";
import PlusIcon from "../../../images/icons/plus-icon.svg";

export default function MasterFilterCalendar() {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
  const [invEmail, setInvEmail] = useState("");
  const [invEmails, setInvEmails] = useState([]);
  const [alreadySentInvEmailsList, setAlreadySentInvEmailsList] = useState([]);
  const [isResendInviteModalOpen, setIsResendInviteModalOpen] = useState(false);
  const [isInvMailLoading, setIsInvMailLoading] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const [selectedRange, setSelectedRange] = useState(null);
  const calendarRef = useRef(null);
  let token = null;
  const { masterUser } = useSelector((state) => state.masterAuth);
  if (masterUser) {
    if (masterUser && masterUser.token) {
      token = masterUser.token;
    }
  }

  const onDateChange = async (ranges) => {
    // moment.tz(item.date,'YYYY-MM-DD HH:mm:ss',global.config.getTimeZone()).format()
    const dateRangeArray = [ranges.startDate, ranges.endDate];
    setSelectedRange(dateRangeArray);
    await dispatch(setMasterDashboardDate(dateRangeArray));
    setShowCalendar(false);
  };

  const toggleCalendar = () => {
    setShowCalendar(!showCalendar);
  };

  const cancelCalendar = () => {
    setShowCalendar(false);
  };

  const handleClickOutsideDropdown = (event) => {
    const isClickInsideCalendarDropdown = calendarRef.current && calendarRef.current.contains(event.target);
    if (!isClickInsideCalendarDropdown) {
      setShowCalendar(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutsideDropdown);
    return () => {
      document.removeEventListener('click', handleClickOutsideDropdown);
    };
  }, []);


  const handleInviteSubmit = async (e) => {
    e.preventDefault();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmails = invEmails.every((obj) => (!obj.invEmail || emailRegex.test(obj.invEmail)));
    if (!isValidEmails) {
      toast.error("Please enter valid email addresses!!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      handleCloseInviteModal();
      return;
    }
    var allInvEmails = invEmails;
    if (invEmail !== "") {
      allInvEmails = [...invEmails, { invEmail: invEmail, id: Date.now() }];
    }
    if (!invEmail) {
      toast.error("Email cannot be empty!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    if (allInvEmails.some(obj => !obj.invEmail)) {
      toast.error("Email cannot be empty!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    try {
      setIsInvMailLoading(true);
      const response = await fetch("/api/tenant-invite", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          invEmails: allInvEmails.map((obj) => obj.invEmail),
          invite_status: "0",
        }),
      });
      const data = await response.json();
      setInvEmails([]);
      setInvEmail("");
      setIsInviteModalOpen(false);
      if (response.ok) {
        if (data.alreadySentInvEmails.length > 0 || data.alreadyRegisteredInvEmails.length > 0) {
          const alreadySentInvEmails = data.alreadySentInvEmails.join(", ");
          const alreadyRegisteredInvEmails = data.alreadyRegisteredInvEmails.join(", ");
          const successSentInvEmails = data.successSentInvEmails.join(", ");
          if (alreadySentInvEmails) {

            setAlreadySentInvEmailsList(data.alreadySentInvEmails);
            const sentInvEmailsList = data.alreadySentInvEmails.map((entry) => entry).join(", ");
            handleOpenResendInviteModal();
            toast.warning(`Invitation already sent to ${sentInvEmailsList}`, {
              position: "bottom-right",
              autoClose: 1500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
          if (alreadyRegisteredInvEmails) {
            toast.warning(`Tenant with email ${alreadyRegisteredInvEmails} is already registered`, {
              position: "bottom-right",
              autoClose: 1500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
          if (successSentInvEmails) {
            toast.success(`Invitation successfully sent to ${successSentInvEmails}`, {
              position: "bottom-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            global.config.activityLog(
              window.location.href,
              "Tenant Invite",
              `Invitation successfully sent to ${successSentInvEmails}`
            );
          }
        } else {
          const successSentInvEmails = data.successSentInvEmails.join(", ");
          toast.success("Mail sent successfully!", {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          global.config.activityLog(
            window.location.href,
            "Tenant Invite",
            `Invitation successfully sent to ${successSentInvEmails}`
          );
        }
      } else {
        toast.error("Failed to send mail.", {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      console.log(error);
      global.config.slackMessage(error.toString());
      alert("Something went wrong");
    } finally {
      setIsInvMailLoading(false);
      handleCloseInviteModal();
    }
  };

  const handleResendInvEmails = async () => {
    try {
      setIsInvMailLoading(true);
      const response = await fetch("/api/tenant/resend-invitations", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          invEmails: alreadySentInvEmailsList, // List of emails to resend
        }),
      });

      if (response.ok) {
        // Handle success
        // Close the resend modal
        setIsResendInviteModalOpen(false);
        // Show a success message
        toast.success("Invitations resent successfully!", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        global.config.activityLog(
          window.location.href,
          "Tenant Invite",
          `Invitation successfully resent to ${alreadySentInvEmailsList}`
        );
      } else {
        // Handle failure
        // Show an error message
        toast.error("Failed to resend invitations.", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.log(error);
      // Handle network or server error
      // Show an error message
      global.config.slackMessage(error.toString());
      toast.error("Failed to resend invitations.", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } finally {
      setIsInvMailLoading(false);
      handleCloseResendInviteModal();
    }
  };

  const handleAddInvEmail = (e) => {
    e.preventDefault();
    // if (email !== "") {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidInvEmails = emailRegex.test(invEmail);
    if (invEmail && !isValidInvEmails) {
      toast.error("Please enter valid email addresses!!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    // if (email) {
    const newInvEmailEntry = { invEmail: invEmail, id: Date.now() };
    setInvEmails([...invEmails, newInvEmailEntry]);
    // }
    setInvEmail("");
    // }
  };

  const handleEditInvEmail = (id, field, value) => {
    const index = invEmails.findIndex((obj) => obj.id === id);
    if (index !== -1) {
      const updatedInvEmails = [...invEmails];
      updatedInvEmails[index][field] = value;
      setInvEmails(updatedInvEmails);
    }
  };

  const handleRemoveInvEmail = (id) => {
    const filteredInvEmails = invEmails.filter((obj) => obj.id !== id);
    setInvEmails(filteredInvEmails);
  };

  const handleRemoveLatestInvEmail = () => {
    const updatedInvEmails = [...invEmails];
    const lastInvEmail = updatedInvEmails.pop();
    setInvEmails(updatedInvEmails);
    setInvEmail(lastInvEmail.invEmail);
  };

  const handleRemoveResendInvEmail = (invEmailToRemove) => {
    const updatedInvEmails = alreadySentInvEmailsList.filter((invEmail) => invEmail !== invEmailToRemove);
    setAlreadySentInvEmailsList(updatedInvEmails);
  };

  const handleOpenResendInviteModal = () => {
    setIsResendInviteModalOpen(true);
  };

  const handleCloseResendInviteModal = () => {
    setIsResendInviteModalOpen(false);
    setAlreadySentInvEmailsList([]);
  };

  const handleOpenInviteModal = () => {
    setIsInviteModalOpen(true);
  };

  const handleCloseInviteModal = () => {
    setIsInviteModalOpen(false);
    setInvEmails([]);
    setInvEmail("");
  };

  return (
    <>
      <div className="curency-dropdown filter-dashbord-calendar master-dash-top">
        <div className="button-sec-invoice">
          <Link to="/master-user/tenants/register">
            <span className="icon-invoice">
              <img src={PlusIcon} alt="Add new Expense " />
            </span>
            New Tenant
          </Link>
          <a className="btn-forpop-initem" onClick={handleOpenInviteModal}>
            <span className="icon-invoice">
              <img src={InviteIcon} alt="Export" />
            </span>
            Invite Tenant
          </a>

          <Modal
            isOpen={isInviteModalOpen}
            onRequestClose={!isInvMailLoading ? handleCloseInviteModal : undefined}
            contentLabel="Invite a Tenant"
            className="user-invite-modal-main-outer"
            style={{
              content: {
                height: "41%",
                width: "30%",
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-25%, -50%)",
              },
            }}
          >
            <div className="modal">
              <div className="modal-top-section-user-invite">
                <div className="modal-invite-head">
                  <h2 className="">Invite a new tenant</h2>
                </div>
                <div className="modal-close-invite outter-modal-main outter-modal-main-user">
                  <a className="" onClick={handleCloseInviteModal} disabled={isInvMailLoading}>
                    <img src={crssrImage} alt="Edit Icon" />{" "}
                  </a>
                </div>

              </div>

              <form onSubmit={handleInviteSubmit}>
                <div>
                  {invEmails.map((obj) => (
                    <div key={obj.id} className="the-modal-inuser">

                      <div className="user-add-outer-innner">
                        <div className="invite-user">
                          <label htmlFor="grid-password" className="">
                            Email Address
                          </label>
                          <input
                            type="email"
                            id={`email-${obj.id}`}
                            className=""
                            name="email"
                            required
                            placeholder="Enter Email ID"
                            value={obj.invEmail}
                            onChange={(e) => handleEditInvEmail(obj.id, 'invEmail', e.target.value)}
                          />
                        </div>
                        <div
                          className="invite-user-delete"
                          onClick={() => handleRemoveInvEmail(obj.id)}
                        >
                          <img src={dletImage} alt="delete-icon" />{" "}
                        </div>
                      </div>

                    </div>
                  ))}
                  <div className="the-modal-inuser">
                    <div className="user-add-outer-innner">
                      <div className="invite-user">
                        <label htmlFor="grid-password" className="">
                          Email Address
                        </label>
                        <input
                          type="email"
                          name="email"
                          className=""
                          placeholder="Enter Email ID"
                          value={invEmail}
                          onChange={(e) => setInvEmail(e.target.value)}
                        />
                      </div>
                      {invEmails.length > 0 && (
                        <div className="invite-user-delete" onClick={handleRemoveLatestInvEmail}>
                          <img src={dletImage} alt="delete-icon" />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="add-email-sec-modal" onClick={handleAddInvEmail} style={{ cursor: "pointer" }}>
                    <img src={addicon} alt="Edit Icon" /><div className="add-icon-main-text">Add new email</div>
                  </div>
                </div>
                <div className="invite-mail-popup-btn" >
                  <button type="submit" className="" disabled={isInvMailLoading}>
                    {isInvMailLoading ?
                      <div className="outter-load-table">
                        <ThreeDots
                          height="18"
                          width="20"
                          radius="9"
                          color="white"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClassName=""
                          visible={true}
                        />
                      </div> : "Send Invite"}
                  </button>
                </div>
              </form>
            </div>
          </Modal>

          <Modal
            isOpen={isResendInviteModalOpen}
            onRequestClose={!isInvMailLoading ? handleCloseResendInviteModal : undefined}
            contentLabel="Resend Invitations"
            style={{
              content: {
                height: "41%",
                width: "30%",
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-25%, -50%)",
              },
            }}
          >
            <div className="modal">
              <div className="modal-close-invite outter-modal-main">
                <a
                  className=""
                  onClick={handleCloseResendInviteModal}
                  disabled={isInvMailLoading}
                >
                  <img src={crssrImage} alt="Edit Icon" />{" "}
                </a>
              </div>
              <div className="modal-invite-head">
                <h2 className="">Resend Invitations</h2>
              </div>
              {/* List the already sent emails here */}
              <div>
                <div className="the-modal-inuser">
                  <label htmlFor="grid-password" className="">
                    Email
                  </label>
                  {alreadySentInvEmailsList.map((email) => (
                    <div key={email} className="the-modal-inusers">

                      <div className="resend-invitation">
                        <input
                          type="email"
                          className=""
                          name="email"
                          required
                          placeholder="Enter Email ID"
                          value={email}
                          disabled
                        />
                        {alreadySentInvEmailsList.length > 1 && (
                          <button className="del-resend" onClick={() => handleRemoveResendInvEmail(email)}>
                            <img src={dletImage} alt="delete-icon" />{" "}
                          </button>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="sprint-btn-outer resend-invoice">
                <button
                  type="button"
                  onClick={handleCloseResendInviteModal}
                  disabled={isInvMailLoading}
                  className="submit-client cancel-sprint"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="submit-client save-sprint"
                  disabled={isInvMailLoading}
                  onClick={handleResendInvEmails}
                >
                  {isInvMailLoading ?
                    <div className="outter-load-table">
                      <ThreeDots
                        height="18"
                        width="20"
                        radius="9"
                        color="white"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                      />
                    </div> : "Resend Invitations"}
                </button>
              </div>
            </div>
          </Modal>
          <Link to="/master-user/register">
            <span className="icon-invoice">
            <img src={PlusIcon} alt="Add new Expense " />
            </span>
            New Master User
          </Link>
        </div>
        <div className="date-picker-outer date-picker-outer-all" ref={calendarRef}>
          <div className="custom-picker-icon custom-picker-icon-all">
            <svg
              width="1em"
              height="1em"
              viewBox="0 0 14 14"
              fill="currentColor"
              aria-hidden="true"
              focusable="false"
              class="rs-picker-toggle-caret rs-icon"
              aria-label="calendar"
              data-category="legacy"
            >
              <path d="M1 4v8.5a.5.5 0 00.5.5H8c0-.128.049-.256.146-.354.555-.555.854-1.6.854-3.146a.5.5 0 01.621-.485l.119.03A2.623 2.623 0 0012.999 6.5V4h-12zm3-2h6V0h1v2h1.5A1.5 1.5 0 0114 3.5v3a3.623 3.623 0 01-4.015 3.603c-.064 1.245-.335 2.212-.831 2.898H12.5a.5.5 0 00.5-.5v-2a.5.5 0 011 0v2a1.5 1.5 0 01-1.5 1.5h-11a1.5 1.5 0 01-1.5-1.5v-9a1.5 1.5 0 011.5-1.5H3v-2h1v2zm5 4V5h1v1h2v1h-2v1H9V7H7v2h1v1H7v2H6v-2H4v2H3v-2H1V9h2V7H1V6h2V5h1v1h2V5h1v1h2zM6 9V7H4v2h2z"></path>
            </svg>
          </div>
          <div className="custoom-icon-calender custoom-icon-calender-all custom-date-range" >
            <input
              type="text"
              value={
                selectedRange ?
                  `${new Date(selectedRange[0]).toLocaleDateString("en-GB", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                  })} to ${new Date(selectedRange[1]).toLocaleDateString("en-GB", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                  })}`
                  : null
              }
              id="date-range"
              name="date-range"
              placeholder="All time"
              onClick={toggleCalendar}
              autocomplete="off"
              readOnly
            />
          </div>
          <div className="date-range-new right-align">
            {/* {showCalendar && <Calendar onCancel={cancelCalendar} onChange={onDateChange} initialRange={{ startDate: selectedRange ? selectedRange[0] : null, endDate: selectedRange ? selectedRange[1] : null }} />} */}
            {showCalendar &&
              <React.Fragment>
                <Calendar onCancel={cancelCalendar} onChange={onDateChange} initialRange={{ startDate: selectedRange ? selectedRange[0] : null, endDate: selectedRange ? selectedRange[1] : null }} />
                {/* <div className="new-calendar-button">
                  <button className="calendar-button-new" onClick={handleOK}>Cancel</button>
                  <button className="calendar-button-new" onClick={handleOK}>OK</button>
                </div> */}
              </React.Fragment>
            }

          </div>
        </div>
      </div>
    </>
  );
}