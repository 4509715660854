import axios from 'axios';
import moment from 'moment';
import { useMemo } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useQuery } from 'react-query';


function ConvertHelper(currencyFrom, currencyTo, amount, forex) {

    const currencyFromRate = forex && forex[currencyFrom];
    const currencyToRate = forex && forex[currencyTo];
    const amountConvertedUSD = amount / currencyFromRate;
    const amountConvertTo = amountConvertedUSD * currencyToRate;
    return amountConvertTo;

}

export function useFetchDashboardCurrency(token) {
    return useQuery('dashboardCurrency', async () => {
        const response = await axios.get('/api/dashboard/invoice-currency', {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    });
}

export function useFetchInvoiceCount(token, startDate, endDate, currencySum) {
    return useQuery('invoiceCount', async () => {
        const response = await axios.post(
            '/api/invoices/by-date-range',
            { startDate, endDate },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        return response.data;

    });
}

export function useFetchOverdue(token, startDate, endDate, currency, currencySum, forex) {
    const queryResult = useQuery('overdue', async () => {
        const response = await axios.post(
            '/api/dashboard/overdue',
            { startDate, endDate },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        const valueArray = Object.entries(response.data).map(([currency, amount]) => {
            return { currency, amount };
        });

        return valueArray;
    });

    const { data: rawData = [], isLoading: rawDataLoading } = queryResult;

    const filteredData = useMemo(() => {
        if (currencySum === false) {
            return rawData.filter((item) => item.currency === currency);
        }
        var tot = 0;
        rawData.map((item) => {
            tot = tot + ConvertHelper(item.currency, currency, item.amount, forex);
        });
        const final = [{ currency: currency, amount: tot }];

        return final;
    }, [rawData, currency, currencySum]);

    return {
        data: filteredData,
        isLoading: rawDataLoading,
        refetch: queryResult.refetch, // Return the refetch function
    };
}

const GetConversion = (from, to) => {
    // const forex = useSelector((state) => state.forex.forex);
    return from;
}

export function useFetchTotalPayments(token, startDate, endDate, currency, currencySum, defaultCurrency, forex) {
    const queryResult = useQuery('totalPayments', async () => {
        const response = await axios.post(
            '/api/dashboard/total-payments',
            { startDate, endDate },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        const valueArray = Object.entries(response.data).map(([currency, amount]) => {
            return { currency, amount };
        });

        return valueArray;
    });

    const { data: rawData = [], isLoading: rawDataLoading } = queryResult;

    const filteredData = useMemo(() => {
        if (currencySum === false) {
            return rawData.filter((item) => item.currency === currency);
        }
        var tot = 0;
        rawData.map((item) => {
            tot = tot + ConvertHelper(item.currency, currency, item.amount, forex);
        });

        const final = [{ currency: currency, amount: tot }];

        return final;
    }, [rawData, currency, currencySum]);

    return {
        data: filteredData,
        isLoading: rawDataLoading,
        refetch: queryResult.refetch, // Return the refetch function
    };
}

export function useFetchOutstandingAmount(token, startDate, endDate, currency, currencySum, forex) {
    const queryResult = useQuery('outstandingAmount', async () => {
        const response = await axios.post(
            '/api/dashboard/outstanding-amount',
            { startDate, endDate },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        const valueArray = Object.entries(response.data).map(([currency, amount]) => {
            return { currency, amount };
        });

        return valueArray;
    });

    const { data: rawData = [], isLoading: rawDataLoading } = queryResult;

    const filteredData = useMemo(() => {
        if (currencySum === false) {
            return rawData.filter((item) => item.currency === currency);
        }
        var tot = 0;
        rawData.map((item) => {
            tot = tot + ConvertHelper(item.currency, currency, item.amount, forex);
        });
        const final = [{ currency: 'INR', amount: tot }];

        return final;
    }, [rawData, currency, currencySum]);

    return {
        data: filteredData,
        isLoading: rawDataLoading,
        refetch: queryResult.refetch, // Return the refetch function
    };
}

export function useFetchAllInvoices(token, startDate, endDate, dashboradCurrency, currencySum) {
    return useQuery(
        ['allInvoices', startDate, endDate, dashboradCurrency, currencySum],
        async () => {
            const response = await axios.post(
                '/api/invoices/recent',
                { startDate, endDate, numberOfInvoices: 5, dashboradCurrency, currencySum },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            return response.data;
        },
        {
            enabled: !!token && !!startDate && !!endDate,
        }
    );
}

export function useFetchRecentInvoices(token, startDate, endDate, numberOfInvoices, dashboradCurrency, currencySum) {
    const queryResult = useFetchAllInvoices(token, startDate, endDate, dashboradCurrency, currencySum);

    const { data: allInvoicesData = [], isLoading: allInvoicesLoading } = queryResult;

    const filteredData = useMemo(() => {
        if (currencySum === false) {
            return allInvoicesData.filter(item => item.inv_currency === dashboradCurrency).slice(0, numberOfInvoices);
        }
        return allInvoicesData;

    }, [allInvoicesData, dashboradCurrency, numberOfInvoices, currencySum]);

    return {
        data: filteredData,
        isLoading: allInvoicesLoading,
        refetch: queryResult.refetch,
    };
}

export function useFetchDashboardChartData(userToken, dashboardCurrency, startDate, endDate, currencySum) {
    return useQuery('dashboardChartData', async () => {
        const response = await axios.post(
            '/api/dashboard/chart',
            {
                startDate,
                endDate,
                currency: dashboardCurrency,
                currencySum: currencySum
            },
            {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            }
        );
        return response.data;
    });
}

export function useForexCurrency(currencies) {
    return useQuery('forex', async () => {

        // const response = await axios.get('/api/dashboard/invoice-currency', {
        //     headers: {
        //         Authorization: `Bearer ${token}`,
        //     },
        // });

        currencies.map((item) => {



        });

        return currencies;
    });
}