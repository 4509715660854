import React, { useRef, useEffect ,useState } from 'react';

const MyComponentImportData = ({ group, editedId }) => {
  const inputRef = useRef(null);
  const [overflow,setOverflow] = useState(false);

  useEffect(() => {
    const input = inputRef.current;
    if (input) {
      const isTextOverflowing = input.scrollWidth > input.clientWidth;
      setOverflow(isTextOverflowing);
    }
  }, [group.task, editedId]);

  return (
    <>
    {overflow && (
        <span className="tooltip-tracker testq">{group.task}</span>
    )}
    <input
      type="text"
      name="task"
      autoComplete="off"
      title={group.task}
      style={{ textOverflow: 'ellipsis', cursor: 'pointer', caretColor: 'transparent' }}
      value={group.task}
      placeholder="Regular Input"
      className={group._id === editedId ? '' : ''}
      ref={inputRef}
    />
    </>
  );
};

export default MyComponentImportData;
