const API_URL = "/api/user/";

const vendorInvoiceData = async (autoData) => {
  var user = autoData;
  localStorage.setItem("vendorInvoiceData", JSON.stringify(autoData));
  return user;
};

const vendorInvoiceService = {
    vendorInvoiceData: vendorInvoiceData,
};

export default vendorInvoiceService;
