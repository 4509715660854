const API_URL = '/api/user/'

const setDateRange = async (date) => {
    return date;
}

const clientService = {
    setDateRange,
}

export default clientService;