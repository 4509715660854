import axios from "axios";
import * as XLSX from "xlsx";
import Modal from "react-modal";
import css from "./Invoices.css";
import { DatePicker } from "rsuite";
import moment from "moment-timezone";
import subDays from "date-fns/subDays";
import { toast } from "react-toastify";
import { Column } from "primereact/column";
import WXCompany from "./elements/wxCompany";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { ThreeDots } from "react-loader-spinner";
import { RotatingLines } from "react-loader-spinner";
import { saveAs } from "file-saver";
import invStatus from "../../constants/invStatus";
import InvoiceModal from "./elements/invoiceModal";
import PaymentStatus from "./elements/paymentStatus";
import { useSelector, useDispatch } from "react-redux";
import { renderCTA } from "../../helpers/invoiceHelpers";
import MemberName from "../Dashboard/elements/memberName";
import invPaymStatus from "../../constants/invPaymStatus";
import React, { useState, useRef, useEffect, useMemo } from "react";
import profile_image from "../../images/user/Default_pfp.png";
import PaymentStatusWise from "./elements/paymentStatusWise";
import invStatusConsts from "../../constants/invStatusConsts";
import { logout, reset } from "../../features/auth/authSlice";
import InvoiceImage from "../../images/icons/invoice-icon-1.svg";
import DownloadImage from "../../images/icons/download-icon.svg";
import crssrImage from "../../images/singleinvoice/cross-red.svg";
import payStatusConsts from "../../constants/invPaymetStatusConsts";
import { createDateConvert, dateConvert } from "../../helpers/dateHelper";
import { setHeaderText } from "../../features/headerText/headerTextSlice";
import { reset as invoiceReset } from "../../features/invoice/invoiceSlice.js";
import { reset as headerReset } from "../../features/headerText/headerTextSlice.js";
import { reset as appSettingsReset } from "../../features/appSettings/appSettingsSlice.js";
import Calendar from "../../helpers/dateComponents/Calendar";
import isEqual from "lodash/isEqual";
import userTypeConsts from "../../constants/userTypeConsts.js";
import CustomDropdown from "./elements/customDropdown.jsx";
import { useGetTeams } from "../TimeTracker/TimeTrackerOverview/useTimeTracker.jsx";
import TruncateFilterFieldView from "../helpers/truncateFilterFieldView.js";
import { fetchInvoices } from "./services.js";
import { motion } from 'framer-motion';
import JSZip from 'jszip';

const useRenderMemberName = (rowData) => {
  const [showFullname, setShowFullname] = useState(false);

  const fullName = rowData.inv_for.first_name
    ? `${rowData.inv_for.first_name} ${rowData.inv_for.middle_name && rowData.inv_for.middle_name !== "null"
      ? rowData.inv_for.middle_name + " "
      : ""
    }${rowData.inv_for.last_name}`
    : rowData.inv_for.company_first_name
      ? `${rowData.inv_for.company_first_name} ${rowData.inv_for.company_last_name ? rowData.inv_for.company_last_name : ""
      }`
      : rowData.inv_for.company_name;
  const displayName = showFullname
    ? fullName
    : fullName.length <= 15
      ? fullName
      : fullName.slice(0, 15) + "...";

  return (
    <div
      className={`member-name${showFullname ? " active" : ""}`}
      // onMouseEnter={() => setShowFullname(true)}
      onMouseEnter={() => {
        if (fullName.length > 15) {
          setShowFullname(true);
        }
      }}
      onMouseLeave={() => setShowFullname(false)}
    >
      {rowData.inv_for.profile_image !== null &&
        rowData.inv_for.profile_image !== "null" ? (
        <img
          src={`/uploads/lowProfileImage/${rowData.inv_for.profile_image}`}
          alt={fullName}
          className="member-image"
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = "/uploads/lowProfileImage/user_default.png";
          }}
        />
      ) : (
        <img src={profile_image} alt={fullName} className="member-image" />
      )}
      <span>{displayName}</span>
    </div>
  );
};

const renderImage = (rowData) => {
  return (
    <img
      src={require("../../images/brand-logos/brand_logo.svg").default}
      alt="App"
    />
  );
};

const ranges = [
  {
    label: "today",
    value: new Date(), // Use a single date instead of an array
  },
  {
    label: "yesterday",
    value: subDays(new Date(), 1),
  },
];

const Invoices = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedDate, setSelectedDate] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [paymentStatusFilter, setPaymentStatusFilter] = useState("All");
  const [statusFilter, setStatusFilter] = useState("All");
  const [invTypeFilter, setInvTypeFilter] = useState("All");
  const [userFilter, setUserFilter] = useState("All");
  const [invoiceNumberFilter, setInvoiceNumberFilter] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [invStatusList, setInvStatusList] = useState([]);
  const [invPaymStatusList, setInvPaymStatusList] = useState([]);
  const [invoiceTypeModalIsOpen, setInvoiceTypeModalIsOpen] = useState(false);
  const [userSelectModal, setUserSelectModal] = useState(false);
  const [isAutomaticInv, setIsAutomaticInv] = useState(false);
  const [isLoadingDownload, setIsLoadingDownload] = useState(false);
  const [selectAllTeam, setSelectAllTeam] = useState(true);
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [isTeamDropdownOpen, setIsTeamDropdownOpen] = useState(false);
  const [isTeamModalDropdownOpen, setIsTeamModalDropdownOpen] = useState(false);
  const [selectAllStatus, setSelectAllStatus] = useState(true);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [isStatusDropdownOpen, setIsStatusDropdownOpen] = useState(false);
  const [isStatusModalDropdownOpen, setIsStatusModalDropdownOpen] = useState(false);
  const [selectAllPaymentStatus, setSelectAllPaymentStatus] = useState(true);
  const [selectedPaymentStatus, setSelectedPaymentStatus] = useState([]);
  const [isPaymentStatusDropdownOpen, setIsPaymentStatusDropdownOpen] =
    useState(false);
  const [isPaymentStatusModalDropdownOpen, setIsPaymentStatusModalDropdownOpen] =
    useState(false);
  const [isInvTypeDropdownOpen, setIsInvTypeDropdownOpen] = useState(false);
  const [isInvTypeModalDropdownOpen, setIsInvTypeModalDropdownOpen] = useState(false);
  const [selectedInvTypes, setSelectedInvTypes] = useState([]);
  const [selectAllInvType, setSelectAllInvType] = useState(true);
  const firstDayOfYear = moment.tz(global.config.getTimeZone()).startOf("year").toDate();
  const [showCalendar, setShowCalendar] = useState(false);
  const [showModalCalendar, setShowModalCalendar] = useState(false);
  // const [dateRange, setDateRange] = useState([firstDayOfYear, new Date()]);
  const [dateRange, setDateRange] = useState(null);
  const calendarRef = useRef(null);
  const calendarModalRef = useRef(null);
  const [showingDropdownOpen, setShowingDropdownOpen] = useState(false);
  const teamDropdownRef = useRef(null);
  const teamModalDropdownRef = useRef(null);
  const paymentStatusDropdownRef = useRef(null);
  const paymentStatusModalDropdownRef = useRef(null);
  const statusDropdownRef = useRef(null);
  const statusModalDropdownRef = useRef(null);
  const invTypeDropdownRef = useRef(null);
  const invTypeModalDropdownRef = useRef(null);
  const showingDropdownRef = useRef(null);
  var filteredData = [];
  const [sec, setSec] = useState(100);
  const [sec1, setSec1] = useState(100);
  const [sec2, setSec2] = useState(100);
  const [sec3, setSec3] = useState(100);
  const [selectAll, setSelectAll] = useState(false);
  const [lastSelectedRowIndex, setLastSelectedRowIndex] = useState(null);
  const [selectAllByPage, setSelectAllByPage] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const { appSettings } = useSelector((state) => state.appSettings);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setCurrentPage(0);
  };
  const [products, setProducts] = useState([]);
  let token = null;
  const { user } = useSelector((state) => state.auth);
  if (user) {
    if (user && user.token) {
      token = user.token;
    }
  }
  const dashboradDate = useSelector((state) => state.invoice.dashboardDateRange);
  const reimbursementInvoiceType = useSelector((state) => state.invoice.invoiceType);
  const { data: teams = [], isLoading: teamLoading, isError: teamError, } = useGetTeams(token);
  
  useEffect(() => {
    async function fetchInvoice() {
      try {
        const params = new URLSearchParams(location.search);
        const monthNumber = params.get("month");
        const year = new Date().getFullYear();
        var startDate = new Date(year, monthNumber - 1, 1);
        var endDate = new Date(year, monthNumber, 0);
        setIsLoading(true);
        if (monthNumber) {
          startDate = startDate;
          endDate = endDate;
        } else {
          const startDateWithOneMoreDay = new Date(dashboradDate[0]);
          startDateWithOneMoreDay.setDate(
            startDateWithOneMoreDay.getDate() - 1
          );
          startDate = new Date(dashboradDate[0]); // startDateWithOneMoreDay;
          const endDateWithOneMoreDay = new Date(dashboradDate[1]);
          endDateWithOneMoreDay.setDate(endDateWithOneMoreDay.getDate() - 1);
          endDate = new Date(dashboradDate[1]); // endDateWithOneMoreDay;
        }

        const fetchInvoiceData = await fetchInvoices(startDate, endDate, token);
        setProducts(fetchInvoiceData);


        setIsLoading(false);
      } catch (error) {
        console.log(error);
        global.config.slackMessage(error.toString());
        if (error.response?.data?.status === "deleted") {
          localStorage.removeItem("user");
          dispatch(logout());
          dispatch(reset());
          dispatch(invoiceReset());
          dispatch(headerReset());
          dispatch(appSettingsReset());
          toast.error("This user is deleted!", {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          navigate("/");
          return;
        }
        alert("Something went wrong");
      }
    }

    fetchInvoice().then(() => {
      // Calculate the widths of the elements
      const fixedSe = document.querySelector(".fixed-se");
      const fixedSec = document.querySelector(".fixed-sec");
      const fixedSe1 = document.querySelector(".fixed-se1");
      const fixedSe2 = document.querySelector(".fixed-sec2");
      const fixedSe3 = document.querySelector(".fixed-sec3");

      if (fixedSe && fixedSec && fixedSe1 && fixedSe2 && fixedSe3) {
        const fixedSeRect = fixedSe.getBoundingClientRect();
        const fixedSecRect = fixedSec.getBoundingClientRect();
        const fixedSe1Rect = fixedSe1.getBoundingClientRect();
        const fixedSe2Rect = fixedSe2.getBoundingClientRect();

        const fixedSeWidth = fixedSeRect.width;
        const fixedSecWidth = fixedSecRect.width + fixedSeWidth;
        const fixedSe1Width = fixedSe1Rect.width + fixedSecWidth;
        const fixedSe2Width = fixedSe2Rect.width + fixedSe1Width;

        setSec(fixedSeWidth);
        setSec1(fixedSecWidth);
        setSec2(fixedSe1Width);
        setSec3(fixedSe2Width);
      }
    });
    setInvStatusList(invStatus.inv_status);
    setInvPaymStatusList(invPaymStatus.inv_paym_status);
    dispatch(setHeaderText("Invoices"));
  }, [dispatch, dashboradDate, location.search, navigate, token]);

  const filter = {
    paymentStat: paymentStatusFilter,
    invoiceStat: statusFilter,
    seldate: selectedDate,
    invoiceNumber: invoiceNumberFilter,
    user: userFilter,
    invType: invTypeFilter,
    dateRange: dateRange,
  };
  const memoizedProducts = useMemo(() => products, [products]);

  filteredData = memoizedProducts && memoizedProducts.filter(item => {
    // If all filter criteria are set to non-filtering values, include the item
    if (filter['invType'] === "All" && filter['invoiceNumber'] === "" && filter['invoiceStat'] === "All" && filter['paymentStat'] === "All" && filter['seldate'] === null && filter['user'] === "All" && filter['dateRange'] === null) {
      return true;
    } else {

      let inv_payment_status = true;
      let inv_status = true;
      let user = true;
      let inv_number = true;
      let inv_due_date = true;
      let inv_type = true;
      let inv_created_at = true;

      const fullName =
        (item.inv_for.first_name || item.inv_for.company_name) +
        (item.inv_for.middle_name && item.inv_for.middle_name !== "null"
          ? " " + item.inv_for.middle_name
          : "") +
        " " +
        item.inv_for.last_name;

      // Check payment status
      if (filter['paymentStat'] !== "All" && !paymentStatusFilter.includes(item['inv_payment_status'])) {

        inv_payment_status = false;
        if (paymentStatusFilter.includes(0)) {
          const currentDate = new Date().toISOString().slice(0, 10);
          if (item.inv_status === 2 && item['inv_payment_status'] === 0) {
            inv_payment_status = false;
          }
        }

        if (paymentStatusFilter.includes(payStatusConsts.Rejected)) {
          const currentDate = new Date().toISOString().slice(0, 10);
          if (item['inv_status'] === invStatusConsts.Rejected && moment(item.inv_due_date).isSameOrBefore(currentDate, "day")) {
            inv_payment_status = true;
          }
        }

        if (paymentStatusFilter.includes(payStatusConsts.Overdue)) {
          const currentDate = new Date().toISOString().slice(0, 10);
          if (moment(item.inv_due_date).isSameOrBefore(currentDate, "day") && item['inv_status'] !== invStatusConsts.Rejected && item['inv_payment_status'] !== payStatusConsts.Manually_Processed && item['inv_payment_status'] !== payStatusConsts.Payment_Processing && item['inv_payment_status'] !== payStatusConsts.Cancelled) {
            inv_payment_status = true;
          }
        }

      }

      // Check invoice status
      if (filter['invoiceStat'] !== "All" && !statusFilter.includes(item['inv_status'])) {
        inv_status = false;
      }

      if (userFilter !== "All" && !userFilter.includes(item.inv_for._id)) {
        user = false;
      }

      if (
        invoiceNumberFilter.trim() !== "" &&
        !item.inv_number
          ?.toLowerCase()
          .includes(invoiceNumberFilter.trim()?.toLowerCase())
      ) {
        inv_number = false;
      }

      if (
        selectedDate &&
        (new Date(item.inv_due_date)?.getFullYear() !== selectedDate.getFullYear() ||
          new Date(item.inv_due_date)?.getMonth() !== selectedDate.getMonth() ||
          new Date(item.inv_due_date)?.getDate() !== selectedDate.getDate())
      ) {
        inv_due_date = false;
      }

      if (invTypeFilter !== "All" && !invTypeFilter.includes(item.inv_type)) {
        inv_type = false;
      }

      // Set dateRange[0] to the start of the day
      // const startOfDay = new Date(dateRange[0]);
      // startOfDay.setHours(0, 0, 0, 0); // Set to 00:00:00.000

      // Set dateRange[1] to the end of the day
      // const endOfDay = new Date(dateRange[1]);
      // endOfDay.setHours(23, 59, 59, 999);
      // const createdDate = new Date(item.inv_created_at);
      // if (!(createdDate >= startOfDay && createdDate <= endOfDay)) {
      //   inv_created_at = false;
      // }

      if (Array.isArray(dateRange) && dateRange.length === 2) {
        const startOfDay = new Date(dateRange[0]);
        startOfDay.setHours(0, 0, 0, 0);

        const endOfDay = new Date(dateRange[1]);
        endOfDay.setHours(23, 59, 59, 999);

        const createdDate = new Date(item.inv_created_at);
        if (!(createdDate >= startOfDay && createdDate <= endOfDay)) {
          inv_created_at = false;
        }
      }

      if (inv_payment_status === false || inv_status === false || user === false || inv_number === false || inv_due_date === false || inv_type === false || inv_created_at === false) {
        return false;
      } else {
        return true;
      }


    }
  });

  filteredData = filteredData && filteredData.filter((item) => {
    if (paymentStatusFilter.includes(0)) {
      if (item.inv_status === 2) {
        return false;
      }
    }
    return true;
  });

  const filteredData12 = products && products.filter((item) => {
    const fullName =
      (item.inv_for.first_name || item.inv_for.company_name) +
      (item.inv_for.middle_name && item.inv_for.middle_name !== "null"
        ? " " + item.inv_for.middle_name
        : "") +
      " " +
      item.inv_for.last_name;
    const currentDate = new Date().toISOString().slice(0, 10);

    if (
      paymentStatusFilter.includes(payStatusConsts.Pending) &&
      (item.inv_payment_status !== payStatusConsts.Pending ||
        moment(item.inv_due_date).isBefore(currentDate, "day") ||
        item.inv_status === invStatusConsts.Rejected)
    ) {
      return false;
    }

    if (
      paymentStatusFilter.includes(payStatusConsts.Overdue)
      &&
      (
        //  (item.inv_payment_status === 2 )  
        //   ||
        moment(item.inv_due_date).isSameOrBefore(currentDate, "day")
        ||
        item.inv_status === invStatusConsts.Rejected
      )
    ) {
      return false;
    }

    //else if (
    //   paymentStatusFilter.includes(payStatusConsts.Rejected) &&
    //   (
    //     item.inv_status !== 2)
    // ) {
    //   return false;
    // } else if (
    //   paymentStatusFilter !== "All" &&
    //   !paymentStatusFilter.includes(item.inv_payment_status)
    // ) {
    //   return false;
    // }

    // if (statusFilter !== "All" && !statusFilter.includes(item.inv_status)) {
    //   return false;
    // }

    // if (
    //   selectedDate &&
    //   (new Date(item.inv_due_date)?.getFullYear() !== selectedDate.getFullYear() ||
    //     new Date(item.inv_due_date)?.getMonth() !== selectedDate.getMonth() ||
    //     new Date(item.inv_due_date)?.getDate() !== selectedDate.getDate())
    // ) {
    //   return false;
    // }

    // if (
    //   invoiceNumberFilter.trim() !== "" &&
    //   !item.inv_number
    //     .toLowerCase()
    //     .includes(invoiceNumberFilter.trim().toLowerCase())
    // ) {
    //   return false;
    // }

    // if (userFilter !== "All" && !userFilter.includes(item.inv_for._id)) {
    //   return false;
    // }
    // if (invTypeFilter !== "All" && !invTypeFilter.includes(item.inv_type)) {
    //   return false;
    // }

    return true;

  });

  const totalResults = filteredData.length;
  const renderAmount = (rowData) => {
    var totalAmount = rowData.inv_total_amount.toLocaleString("en-US", {
      style: "currency",
      currency: rowData.inv_currency,
    });

    return <span> {totalAmount}</span>;
  };

  const handleCheckboxTeamChange = (teamId) => {
    const updatedSelection = [...selectedTeams];
    const index = updatedSelection.indexOf(teamId);

    if (index === -1) {
      // Add to selection
      updatedSelection.push(teamId);
    } else {
      // Remove from selection
      updatedSelection.splice(index, 1);
    }
    setCurrentPage(0);
    setSelectedTeams(updatedSelection);
  };

  const handleSelectAllTeamChange = () => {
    if (!selectAllTeam) {
      setSelectedTeams(teams.map((team) => team._id));
    } else {
      setSelectedTeams([]);
    }
    setSelectAllTeam(!selectAllTeam);
  };

  useEffect(() => {
    setSelectAllTeam(selectedTeams.length === teams.length);
    if (selectedTeams.length === teams.length) {
      setUserFilter("All");
    } else {
      setUserFilter(selectedTeams);
    }
  }, [selectedTeams, teams]);

  const toggleTeamDropdown = () => {
    setIsTeamDropdownOpen(!isTeamDropdownOpen);
  };

  const toggleTeamModalDropdown = () => {
    setIsTeamModalDropdownOpen(!isTeamModalDropdownOpen);
  };

  const handleCheckboxStatusChange = (statusId) => {
    const updatedSelection = [...selectedStatus];
    const index = updatedSelection.indexOf(statusId);

    if (index === -1) {
      // Add to selection
      updatedSelection.push(statusId);
    } else {
      // Remove from selection
      updatedSelection.splice(index, 1);
    }
    setCurrentPage(0);
    setSelectedStatus(updatedSelection);
  };

  const handleSelectAllStatusChange = () => {
    if (!selectAllStatus) {
      setSelectedStatus(invStatusList.map((status) => status._id));
    } else {
      setSelectedStatus([]);
    }
    setSelectAllStatus(!selectAllStatus);
  };

  useEffect(() => {
    setSelectAllStatus(selectedStatus.length === invStatusList.length);
    if (selectedStatus.length === invStatusList.length) {
      setStatusFilter("All");
    } else {
      setStatusFilter(selectedStatus);
    }
  }, [selectedStatus, invStatusList]);

  const toggleStatusDropdown = () => {
    setIsStatusDropdownOpen(!isStatusDropdownOpen);
  };

  const toggleStatusModalDropdown = () => {
    setIsStatusModalDropdownOpen(!isStatusModalDropdownOpen);
  };

  const handleCheckboxPaymentStatusChange = (paymentStatusId) => {
    const updatedSelection = [...selectedPaymentStatus];
    const index = updatedSelection.indexOf(paymentStatusId);

    if (index === -1) {
      // Add to selection
      updatedSelection.push(paymentStatusId);
    } else {
      // Remove from selection
      updatedSelection.splice(index, 1);
    }
    setCurrentPage(0);
    setSelectedPaymentStatus(updatedSelection);
  };

  const handleSelectAllPaymentStatusChange = () => {
    if (!selectAllPaymentStatus) {
      setSelectedPaymentStatus(
        invPaymStatusList.map((paymentstatus) => paymentstatus._id)
      );
    } else {
      setSelectedPaymentStatus([]);
    }
    setSelectAllPaymentStatus(!selectAllPaymentStatus);
  };

  useEffect(() => {
    setSelectAllPaymentStatus(
      selectedPaymentStatus.length === invPaymStatusList.length
    );
    if (selectedPaymentStatus.length === invPaymStatusList.length) {
      setPaymentStatusFilter("All");
    } else {
      setPaymentStatusFilter(selectedPaymentStatus);
    }
  }, [selectedPaymentStatus, invPaymStatusList]);

  const togglePaymentStatusDropdown = () => {
    setIsPaymentStatusDropdownOpen(!isPaymentStatusDropdownOpen);
  };

  const togglePaymentStatusModalDropdown = () => {
    setIsPaymentStatusModalDropdownOpen(!isPaymentStatusModalDropdownOpen);
  };

  const toggleInvTypeDropdown = () => {
    setIsInvTypeDropdownOpen(!isInvTypeDropdownOpen);
  };

  const toggleInvTypeModalDropdown = () => {
    setIsInvTypeModalDropdownOpen(!isInvTypeModalDropdownOpen);
  };

  const handleCheckboxInvTypeChange = (invType) => {
    const updatedSelection = [...selectedInvTypes];
    const index = updatedSelection.indexOf(invType);

    if (index === -1) {
      // Add to selection
      updatedSelection.push(invType);
    } else {
      // Remove from selection
      updatedSelection.splice(index, 1);
    }
    setCurrentPage(0);
    setSelectedInvTypes(updatedSelection);
  };

  const handleSelectAllInvTypeChange = () => {
    if (!selectAllInvType) {
      setSelectedInvTypes(["Regular", "Reimbursement"]); // Add all available invoice types
    } else {
      setSelectedInvTypes([]);
    }
    setSelectAllInvType(!selectAllInvType);
  };

  useEffect(() => {
    setSelectAllInvType(selectedInvTypes.length === 2);
    if (selectedInvTypes.length === 2) {
      setInvTypeFilter("All");
    } else {
      setInvTypeFilter(selectedInvTypes);
    }
  }, [selectedInvTypes]);

  const onDateChange = (ranges) => {
    // moment.tz(item.date,'YYYY-MM-DD HH:mm:ss',global.config.getTimeZone()).format()
    const dateRangeArray = [ranges.startDate, ranges.endDate];
    setDateRange(dateRangeArray);
    if (ranges === null) {
      const firstDayOfYear = new Date(new Date().getFullYear(), 0, 1);
      // setDateRange([firstDayOfYear, new Date()]);
      setDateRange(null);
    }
    setCurrentPage(0);
    setShowCalendar(false);
    setShowModalCalendar(false);
  };

  const toggleCalendar = () => {
    setShowCalendar(!showCalendar);
  };

  const cancelCalendar = () => {
    setShowCalendar(false);
  };

  const toggleModalCalendar = () => {
    setShowModalCalendar(!showModalCalendar);
  };

  const cancelModalCalendar = () => {
    setShowModalCalendar(false);
  };

  const handleClickOutsideDropdown = (event) => {
    const isClickInsideTeamDropdown =
      teamDropdownRef.current && teamDropdownRef.current.contains(event.target);
    const isClickInsideTeamModalDropdown =
      teamModalDropdownRef.current && teamModalDropdownRef.current.contains(event.target);
    const isClickInsidePaymentStatusDropdown =
      paymentStatusDropdownRef.current &&
      paymentStatusDropdownRef.current.contains(event.target);
    const isClickInsidePaymentStatusModalDropdown =
      paymentStatusModalDropdownRef.current &&
      paymentStatusModalDropdownRef.current.contains(event.target);
    const isClickInsideStatusDropdown =
      statusDropdownRef.current &&
      statusDropdownRef.current.contains(event.target);
    const isClickInsideStatusModalDropdown =
      statusModalDropdownRef.current &&
      statusModalDropdownRef.current.contains(event.target);
    const isClickInsideInvTypeDropdown =
      invTypeDropdownRef.current &&
      invTypeDropdownRef.current.contains(event.target);
    const isClickInsideInvTypeModalDropdown =
      invTypeModalDropdownRef.current &&
      invTypeModalDropdownRef.current.contains(event.target);
    const isClickInsideshowingDropdown =
      showingDropdownRef.current &&
      showingDropdownRef.current.contains(event.target);
    const isClickInsideCalendarDropdown = calendarRef.current && calendarRef.current.contains(event.target);
    const isClickInsideModalCalendarDropdown = calendarModalRef.current && calendarModalRef.current.contains(event.target);
    if (!isClickInsideTeamDropdown) {
      setIsTeamDropdownOpen(false);
    }
    if (!isClickInsideTeamModalDropdown) {
      setIsTeamModalDropdownOpen(false);
    }
    if (!isClickInsideStatusDropdown) {
      setIsStatusDropdownOpen(false);
    }
    if (!isClickInsideStatusModalDropdown) {
      setIsStatusModalDropdownOpen(false);
    }
    if (!isClickInsidePaymentStatusDropdown) {
      setIsPaymentStatusDropdownOpen(false);
    }
    if (!isClickInsidePaymentStatusModalDropdown) {
      setIsPaymentStatusModalDropdownOpen(false);
    }
    if (!isClickInsideInvTypeDropdown) {
      setIsInvTypeDropdownOpen(false);
    }
    if (!isClickInsideInvTypeModalDropdown) {
      setIsInvTypeModalDropdownOpen(false);
    }
    if (!isClickInsideshowingDropdown) {
      setShowingDropdownOpen(false);
    }
    if (!isClickInsideCalendarDropdown) {
      setShowCalendar(false);
    }
    if (!isClickInsideModalCalendarDropdown) {
      setShowModalCalendar(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutsideDropdown);

    return () => {
      document.removeEventListener("click", handleClickOutsideDropdown);
    };
  }, []);

  const applyFilters = () => {
    setShowFilterModal(false);
  };

  const clearFilters = () => {
    setInvoiceNumberFilter("");
    setSelectedTeams(teams.map((team) => team._id));
    setSelectedStatus(invStatusList.map((status) => status._id));
    setSelectedPaymentStatus(
      invPaymStatusList.map((paymentstatus) => paymentstatus._id)
    );
    setSelectedDate(null);
    setSelectedInvTypes(["Regular", "Reimbursement"]);
    setShowFilterModal(false);
  };

  const checkAndShowConfirmationModal = () => {
    const isInvNumModified = !isEqual(invoiceNumberFilter, "");
    const isTeamsModified = !isEqual(selectedTeams, teams.map((team) => team._id));
    const isStatusModified = !isEqual(selectedStatus, invStatusList.map((status) => status._id));
    const isPaymentStatusModified = !isEqual(selectedPaymentStatus, invPaymStatusList.map((paymentstatus) => paymentstatus._id));
    const isDueDateModified = !isEqual(selectedDate, null);
    const isInvTypeModified = !isEqual(selectedInvTypes, ["Regular", "Reimbursement"]);

    if (
      isInvNumModified ||
      isTeamsModified ||
      isStatusModified ||
      isPaymentStatusModified ||
      isDueDateModified ||
      isInvTypeModified
    ) {
      setShowConfirmationModal(true);
    } else {
      setShowFilterModal(false);
    }
  };

  const handleCancel = () => {
    setShowConfirmationModal(false);
  };

  const handleApplyConfirm = () => {
    setShowConfirmationModal(false);
    setShowFilterModal(false);
  };

  const toggleFilterModal = () => {
    setShowConfirmationModal(false);
    setShowFilterModal(!showFilterModal);
  };

  useEffect(() => {
    setSelectedTeams(teams.map((team) => team._id));
    setSelectedStatus(invStatusList.map((status) => status._id));
    setSelectedPaymentStatus(
      invPaymStatusList.map((paymentstatus) => paymentstatus._id)
    );
    if (reimbursementInvoiceType === "Reimbursement") {
      setSelectedInvTypes(["Reimbursement"]);
    } else {
      setSelectedInvTypes(["Regular", "Reimbursement"]);
    }
  }, [teams, invPaymStatusList, reimbursementInvoiceType]);

  const fetchPaymentDetails = async (rowData) => {
    try {
      const response = await fetch("/api/invoices/paymentDetails/status", {
        method: "POST",
        body: JSON.stringify({ _id: rowData._id }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        await response.json();
      } else {
        // Handle error case
        console.error("Failed to fetch payment details");
      }
    } catch (error) {
      global.config.slackMessage(error.toString());
      console.error("Error fetching payment details:", error);
    }
  };

  const renderStatus = (rowData) => {
    // Determine the class based on the payment_status value
    let statusClass;
    let statusName;
    if (rowData.inv_status === invStatusConsts.Approved) {
      statusClass = "manual";
      statusName = "Approved";
    } else if (rowData.inv_status === invStatusConsts.Not_Reviewed) {
      statusClass = "pending";
      statusName = "Not Reviewed";
    } else if (rowData.inv_status === invStatusConsts.Rejected) {
      statusClass = "overdue";
      statusName = "Rejected";
    } else if (rowData.inv_payment_status === invStatusConsts.Canceled) {
      statusClass = "overdue";
      statusName = "Canceled";
    }
    return (
      <span className={`payment-status ${statusClass}`}>{statusName}</span>
    );
  };

  const handleAutomaticModalOpen = async () => {
    setInvoiceTypeModalIsOpen(true);
  };

  const handleModalCancel = () => {
    setInvoiceTypeModalIsOpen(false);
    setUserSelectModal(false);
  };

  const exportToExcel = () => {
    if (filteredData.length === 0) {
      toast.error("No data to export.", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    try {
      const now = new Date();
      const formattedDate =
        now.getFullYear() +
        "-" +
        (now.getMonth() + 1).toString().padStart(2, "0") +
        "-" +
        now.getDate().toString().padStart(2, "0");
      const formattedTime =
        now.getHours().toString().padStart(2, "0") +
        ":" +
        now.getMinutes().toString().padStart(2, "0") +
        ":" +
        now.getSeconds().toString().padStart(2, "0");

      const modifiedLog = [];
      filteredData.forEach((item, index) => {
        // Assuming these items in an array
        const descriptions = item.inv_description;
        const costs = item.inv_cost;
        const taxs = item.inv_tax;

        if (descriptions && descriptions.length > 0) {
          descriptions.forEach((description, i) => {
            const cost = parseFloat(costs[i]);
            const tax = parseFloat(taxs[i]);
            const vat = (cost * (tax / 100)).toFixed(2);
            const amount = (cost + parseFloat(vat)).toFixed(2);
            modifiedLog.push({
              "Invoice Number": item.inv_number,
              "Invoice Type": item.inv_type,
              "Invoice Issue Date": moment(item.inv_issue_date)
                .tz(global.config.getTimeZone())
                .format("DD/MM/YYYY"),
              "Invoice Due Date": moment(item.inv_due_date)
                .tz(global.config.getTimeZone())
                .format("DD/MM/YYYY"),
              Period:
                moment(item.inv_strat_date)
                  .tz(global.config.getTimeZone())
                  .format("DD/MM/YYYY") +
                " - " +
                moment(item.inv_end_date)
                  .tz(global.config.getTimeZone())
                  .format("DD/MM/YYYY"),
              User: item.inv_for
                ? item.inv_for.first_name
                  ? `${item.inv_for.first_name} ${item.inv_for.middle_name ? item.inv_for.middle_name : ""
                  } ${item.inv_for.last_name ? item.inv_for.last_name : ""}`
                  : item.inv_for.company_first_name
                    ? `${item.inv_for.company_first_name} ${item.inv_for.company_last_name
                      ? item.inv_for.company_last_name
                      : ""
                    }`
                    : item.inv_for.company_name
                : "",
              Item: i + 1,
              Description: description,
              Cost: costs[i],
              "VAT(%)": taxs[i],
              VAT: (costs[i] * (taxs[i] / 100)).toFixed(2),
              Amount: amount,
              "Invoice Currency": item.inv_currency,
              "Invoice Payment Date & Time": item.inv_payment_details
                ? moment(item.inv_payment_details.created_at)
                  .tz(global.config.getTimeZone())
                  .format("DD/MM/YYYY, hh:mm:ss A")
                : "Not Updated",
              "Created At": moment(item.inv_created_at)
                .tz(global.config.getTimeZone())
                .format("DD/MM/YYYY, hh:mm:ss A"),
            });
          });
        } else {
          modifiedLog.push({
            "Invoice Number": item.inv_number,
            "Invoice Type": item.inv_type,
            "Invoice Issue Date": moment(item.inv_issue_date)
              .tz(global.config.getTimeZone())
              .format("DD/MM/YYYY"),
            "Invoice Due Date": moment(item.inv_due_date)
              .tz(global.config.getTimeZone())
              .format("DD/MM/YYYY"),
            Period:
              moment(item.inv_strat_date)
                .tz(global.config.getTimeZone())
                .format("DD/MM/YYYY") +
              " - " +
              moment(item.inv_end_date)
                .tz(global.config.getTimeZone())
                .format("DD/MM/YYYY"),
            User: item.inv_for
              ? item.inv_for.first_name +
              " " +
              (item.inv_for.middle_name && item.inv_for.middle_name !== "null"
                ? " " + item.inv_for.middle_name
                : "") +
              " " +
              item.inv_for.last_name
              : "",
            Item: 1,
            Description: "", // Set empty description for single row
            Cost: "",
            "VAT(%)": "",
            Amount: "",
            "Invoice Currency": item.inv_currency,
            "Invoice Payment Date and Time": item.inv_payment_details
              ? moment(item.inv_payment_details.created_at)
                .tz(global.config.getTimeZone())
                .format("DD/MM/YYYY, hh:mm:ss A")
              : "Not Updated",
            Company: item.inv_for
              ? item.inv_for.user_wx_comapny.wx_company
              : "",
            "Payment Bank": item.inv_for
              ? item.inv_for.user_wx_comapny.country
              : "",
            "Created At": moment(item.inv_created_at)
              .tz(global.config.getTimeZone())
              .format("DD/MM/YYYY, hh:mm:ss A"),
          });
        }
      });

      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(modifiedLog);
      const colWidths = [
        { wpx: 80 },
        { wpx: 80 },
        { wpx: 100 },
        { wpx: 100 },
        { wpx: 125 },
        { wpx: 125 },
        { wpx: 35 },
        { wpx: 200 },
        { wpx: 100 },
        { wpx: 100 },
        { wpx: 100 },
        { wpx: 100 },
        { wpx: 100 },
        { wpx: 175 },
        { wpx: 100 },
        { wpx: 100 },
        { wpx: 150 },
      ];
      worksheet["!cols"] = colWidths;

      // Add alignment to center align the first row and increase font size
      const firstRow = XLSX.utils.decode_range(worksheet["!ref"]).s.r;
      for (let i = 0; i < colWidths.length; i++) {
        const cell = XLSX.utils.encode_cell({ r: firstRow, c: i });
        const style = worksheet[cell]?.s || {};
        style.alignment = style.alignment || {};
        style.alignment.horizontal = "center !important";
        style.alignment.vertical = "center !important";
        style.font = { size: 14, bold: true };
        worksheet[cell] = { ...worksheet[cell], s: style };
      }

      // Set border for active area and increase font size
      const range = XLSX.utils.decode_range(worksheet["!ref"]);
      for (let row = range.s.r + 1; row <= range.e.r; row++) {
        for (let col = range.s.c; col <= range.e.c; col++) {
          const cell = XLSX.utils.encode_cell({ r: row, c: col });
          const style = worksheet[cell]?.s || {};
          style.font = { size: 12 };
          worksheet[cell] = { ...worksheet[cell], s: style };
        }
      }
      global.config.activityLog(
        window.location.href,
        "Invoice",
        "Invoices List exported to Excel"
      );
      const fileName = `Invoice_Report_${formattedDate}_${formattedTime}.xlsx`;
      worksheet["!autofilter"] = { ref: range };
      XLSX.utils.book_append_sheet(workbook, worksheet, "Invoice Report");
      XLSX.writeFile(workbook, fileName);
    } catch (error) {
      console.log(error);
      global.config.slackMessage(error.toString());
      global.config.activityLog(
        window.location.href,
        "Invoice",
        "Error occurred while exporting INVOICES to Excel"
      );
      alert("Error occurred while exporting to Excel.");
    }
  };

  const downloadSelectedPDF = async () => {
    if (selectedRows.length === 0) {
      toast.error("Please select at least one invoice to download.", {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    if (selectedRows.length > 10) {
      toast.error("Please select only Maximum of 10 invoices!", {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setSelectAll(false);
      setSelectedRows([]);
      return;
    }

    try {

      setIsLoadingDownload(true);
      // Download each PDF file separately
      const zip = new JSZip();
    const folder = zip.folder("invoices"); // Optional: create a folder inside ZIP
    
    for (const row of selectedRows) {
      const formattedDate = new Date().toISOString().replace(/[-:]/g, "_");
      const filename = `invoices_${row.inv_number}_${formattedDate}.pdf`;

      // Step 1: Create the invoice
      await axios.post(
        `/api/invoice/create-multiple`,
        { invoiceId: row._id },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Step 2: Download the generated PDF
      const pdfBlobResponse = await axios.get(
        `/api/invoice/download/created-multiple/pdf/${row._id}`,
        {
          responseType: "blob",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Step 3: Add PDF Blob to ZIP file
      folder.file(filename, pdfBlobResponse.data, { binary: true });
    }

    // Step 4: Generate ZIP and trigger download
    const zipBlob = await zip.generateAsync({ type: "blob" });
    saveAs(zipBlob, `invoices_${new Date().toISOString().replace(/[-:]/g, "_")}.zip`);

    // Step 5: Reset UI state and show success message
    setSelectAll(false);
    setSelectedRows([]);
    toast.success("All the Selected Invoices are Downloaded Successfully!", {
      position: "bottom-right",
      autoClose: 1500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

    global.config.activityLog(
      window.location.href,
      "Invoice",
      "All the Selected Invoices are Downloaded Successfully"
    );


      
    } catch (error) {
      console.error(error);
      global.config.slackMessage(error.toString());
      if (error.response?.data?.status === "deleted") {
        localStorage.removeItem("user");
        dispatch(logout());
        dispatch(reset());
        dispatch(invoiceReset());
        dispatch(headerReset());
        dispatch(appSettingsReset());
        toast.error("This user is deleted!", {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/");
        return;
      }
      alert("Something went wrong while creating or downloading the PDFs.");
      global.config.activityLog(
        window.location.href,
        "Invoice",
        "Something went wrong while creating or downloading the PDFs in bulk."
      );
    } finally {
      setIsLoadingDownload(false);
    }
  };

  const handleRowsPerPageChange = (e) => {
    const selected = [...selectedRows];
    const newRowsPerPage = parseInt(e.target.value);
    setRowsPerPage(newRowsPerPage);
    if (selected.length === newRowsPerPage) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
    setCurrentPage(0); // Set the current page to 0 when changing rows per page
  };

  const onPageChange = (e) => {
    const newPage = e.page;
    // Store the current "Select All" state for the current page
    setSelectAllByPage((prevSelectAllByPage) => ({
      ...prevSelectAllByPage,
      [currentPage]: selectAll,
    }));
    setCurrentPage(e.page);
    setSelectAll(selectAllByPage[newPage] || false);
  };

  const checkboxTemplate = (rowData, rowIndex) => {
    return (
      <input
        type="checkbox"
        onChange={(e) => handleCheckboxChange(e, rowData, rowIndex)}
        checked={isSelected(rowData)}
      />
    );
  };

  const handleSelectAllChange = () => {
    const selected = [...selectedRows];
    if (!selectAll && selected.length >= 10) {
      // Display an error message if the limit is exceeded
      toast.error("Please select only a maximum of 10 invoices!", {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return; // Stop further processing
    }
    const startIdx = currentPage * rowsPerPage;
    const endIdx = startIdx + rowsPerPage;
    const rowsOnPage = filteredData.slice(startIdx, endIdx);
    if (!selectAll && rowsOnPage.length > 10) {
      // Display an error message if the limit is exceeded
      toast.error("Please select only a maximum of 10 invoices!", {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return; // Stop further processing
    }
    setSelectAll(!selectAll);

    if (!selectAll) {
      setSelectedRows(rowsOnPage);
    } else {
      setSelectedRows([]);
    }
  };

  const handleCheckboxChange = (e, rowData, rowIndex) => {
    const selected = [...selectedRows];
    const index = selected.findIndex((item) => isEqual(item, rowData));

    if (e.nativeEvent.shiftKey && lastSelectedRowIndex !== null) {
      const startIdx = Math.min(lastSelectedRowIndex, rowIndex);
      const endIdx = Math.max(lastSelectedRowIndex, rowIndex);

      for (let i = startIdx; i <= endIdx; i++) {
        const currentRowData = filteredData[i];
        const currentIndex = selected.findIndex((item) =>
          isEqual(item, currentRowData)
        );

        if (e.target.checked) {
          // Add to selection if not already selected
          if (currentIndex === -1) {
            selected.push(currentRowData);
          }
        } else {
          // Remove from selection if already selected
          if (currentIndex !== -1) {
            selected.splice(currentIndex, 1);
          }
        }
        setLastSelectedRowIndex(rowIndex);
      }
    } else {
      if (e.target.checked) {
        if (selected.length >= 10) {
          toast.error("Please select only a maximum of 10 invoices!", {
            position: "bottom-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          return;
        }
        selected.push(rowData);
      } else {
        if (index !== -1) {
          selected.splice(index, 1);
        }
      }
      setLastSelectedRowIndex(rowIndex);
    }

    // Check if all checkboxes on the current page are selected
    const startIdx = currentPage * rowsPerPage;
    const endIdx = startIdx + rowsPerPage;
    const rowsOnPage = filteredData.slice(startIdx, endIdx);
    const allSelectedOnPage = rowsOnPage.every((row) => selected.includes(row));

    // Update selectAll based on whether all checkboxes on the page are selected
    setSelectAll(allSelectedOnPage);
    setSelectedRows(selected);
  };

  const isSelected = (rowData) => {
    return selectedRows.some((item) => isEqual(item, rowData));
  };


  const componentRef = useRef(null);
  const fadeIn = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 }
  };

  return (

    <motion.div
      ref={componentRef}
      initial="hidden"
      animate="visible"
      variants={fadeIn}
      transition={{ duration: 0.3 }}
    >
      <div className="invoice-outer-invoice">
        <div className="invoice-top">
          <div className="new-top-flter">
            <div className="button-sec-invoice">
              <a onClick={handleAutomaticModalOpen}>
                <span className="icon-invoice">
                  <img src={InvoiceImage} alt="Create Invoice" />
                </span>
                Create Invoice
              </a>
              {/* invoice type modal */}
              <Modal
                isOpen={invoiceTypeModalIsOpen}
                onRequestClose={handleModalCancel}
                contentLabel="Invoice Type"
                style={{
                  content: {
                    height: "50%",
                    width: "50%",
                    position: "fixed",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-17.5%, -46%)",
                  },
                }}
              >
                <div className="modal">
                  <div className="outter-modal-main">
                    <a
                      className="close-image-clr1"
                      onClick={() => {
                        setInvoiceTypeModalIsOpen(false);
                        setUserSelectModal(false);
                      }}
                    >
                      <img src={crssrImage} alt="Edit Icon" />{" "}
                    </a>
                  </div>
                  {userSelectModal === false ? (
                    <>
                      <div className="outter-modal-main-2">
                        <p>
                          <p>Type of Invoice</p>
                        </p>
                        <div className="modal-btn-main">
                          <button
                            onClick={() => {
                              setUserSelectModal(true);
                              setIsAutomaticInv(false);
                            }}
                          >
                            Manually
                          </button>
                          <button
                            onClick={() => {
                              setUserSelectModal(true);
                              setIsAutomaticInv(true);
                            }}
                          >
                            Automatically
                          </button>
                        </div>
                      </div>
                    </>
                  ) : (
                    <InvoiceModal isAutomaticInv={isAutomaticInv} />
                  )}
                </div>
              </Modal>
              {/* invoice type madal end */}
              <a onClick={exportToExcel}>
                <span className="icon-invoice">
                  <img src={DownloadImage} alt="Export" />
                </span>
                Export Data
              </a>

              {user &&
                (user.userType === userTypeConsts.SUPER_ADMIN ||
                  user.userType === userTypeConsts.ADMIN) && (
                  <>
                    <a
                      onClick={isLoadingDownload ? null : downloadSelectedPDF}
                      disabled={isLoadingDownload}
                    >
                      {isLoadingDownload ? (
                        <div className="outter-load-table">
                          <RotatingLines
                            strokeColor="white"
                            strokeWidth="5"
                            animationDuration="0.75"
                            width="18"
                            visible={true}
                          />
                        </div>
                      ) : (
                        <span className="icon-invoice">
                          <img src={DownloadImage} alt="Export" />
                        </span>
                      )}
                      Download
                    </a>
                  </>
                )}
            </div>
            <div className="filter-invoice-right">
              <div className="filter-inner showing-result">
                <label>Showing</label>
                {/* <select
                value={
                  totalResults < 10
                    ? totalResults.toString()
                    : rowsPerPage.toString()
                }
                onChange={handleRowsPerPageChange}
              >
                {totalResults >= 10 && <option value="10">10</option>}
                {totalResults >= 20 && <option value="20">20</option>}
                {totalResults >= 30 && <option value="30">30</option>}
                {totalResults >= 40 && <option value="40">40</option>}
                {totalResults > 10 && <option value={totalResults.toString()}>ALL</option>}
                {totalResults < 10 ? (
                  <option value={totalResults.toString()}>
                    {totalResults}
                  </option>
                ) : null}
              </select> */}
                <div
                  ref={showingDropdownRef}
                  className="select-modal-mainM custom-dropdown-user-new-outer"
                >
                  <div
                    className="selected-option custom-dropdown-user-new"
                    onClick={() => setShowingDropdownOpen(!showingDropdownOpen)}
                  >
                    {totalResults < 10
                      ? totalResults.toString()
                      : rowsPerPage.toString()}
                  </div>
                  {showingDropdownOpen && (
                    <div className="custom-dropdown-new">
                      <ul className="showing-options-list">
                        {totalResults >= 10 && (
                          <li
                            className={rowsPerPage === 10 ? "selected-item" : ""}
                            onClick={() => {
                              handleRowsPerPageChange({ target: { value: 10 } });
                              setShowingDropdownOpen(false);
                            }}
                          >
                            10
                          </li>
                        )}
                        {totalResults >= 20 && (
                          <li
                            className={rowsPerPage === 20 ? "selected-item" : ""}
                            onClick={() => {
                              handleRowsPerPageChange({ target: { value: 20 } });
                              setShowingDropdownOpen(false);
                            }}
                          >
                            20
                          </li>
                        )}
                        {totalResults >= 30 && (
                          <li
                            className={rowsPerPage === 30 ? "selected-item" : ""}
                            onClick={() => {
                              handleRowsPerPageChange({ target: { value: 30 } });
                              setShowingDropdownOpen(false);
                            }}
                          >
                            30
                          </li>
                        )}
                        {totalResults >= 40 && (
                          <li
                            className={rowsPerPage === 40 ? "selected-item" : ""}
                            onClick={() => {
                              handleRowsPerPageChange({ target: { value: 40 } });
                              setShowingDropdownOpen(false);
                            }}
                          >
                            40
                          </li>
                        )}
                        {totalResults > 10 && (
                          <li
                            className={
                              totalResults > 10 && rowsPerPage === totalResults
                                ? "selected-item"
                                : ""
                            }
                            onClick={() => {
                              handleRowsPerPageChange({
                                target: { value: totalResults },
                              });
                              setShowingDropdownOpen(false);
                            }}
                          >
                            ALL
                          </li>
                        )}
                        {totalResults < 10 ? (
                          <li
                            className={totalResults < 10 ? "selected-item" : ""}
                            onClick={() => {
                              handleRowsPerPageChange({
                                target: { value: totalResults },
                              });
                              setShowingDropdownOpen(false);
                            }}
                          >
                            {totalResults}
                          </li>
                        ) : null}
                      </ul>
                    </div>
                  )}
                </div>
                <label>of {totalResults} Results</label>
              </div>
            </div>
          </div>

          <div className="invoice-filter">
            <div className="invoice-filter-inner-section no-flter-all">
              <div className="filter-inner invoice-id">
                <label for="fname">Invoice ID</label>
                <input
                  type="text"
                  onChange={(e) => {
                    setInvoiceNumberFilter(e.target.value);
                    setCurrentPage(0);
                  }}
                  value={invoiceNumberFilter}
                  id="invoiceId"
                  name="invoiceId"
                  placeholder="All"
                />
              </div>
              {user &&
                (user.userType === userTypeConsts.SUPER_ADMIN ||
                  user.userType === userTypeConsts.ADMIN) && (
                  <div className="filter-inner invoice-status">
                    <label for="user">User</label>
                    <div className="custom-dropdown1" ref={teamDropdownRef}>
                      <div className="drop-down-inner">
                        <div
                          className="selected-options1"
                          onClick={toggleTeamDropdown}
                        >
                          {selectedTeams.length === 0 ? (
                            <div className="placeholder">Select</div>
                          ) : (
                            <>
                              <div
                                className="selected-option1"
                                key={selectedTeams[0]}
                              >
                                {selectedTeams.length === teams.length ? (
                                  `All`
                                ) : (
                                  <>
                                    <TruncateFilterFieldView
                                      textContent={
                                        teams.find(
                                          (item) => item._id === selectedTeams[0]
                                        )?.first_name
                                          ? `${teams.find(
                                            (item) =>
                                              item._id === selectedTeams[0]
                                          ).first_name
                                          } ${teams.find(
                                            (item) =>
                                              item._id === selectedTeams[0]
                                          ).middle_name
                                            ? teams.find(
                                              (item) =>
                                                item._id ===
                                                selectedTeams[0]
                                            ).middle_name
                                            : ""
                                          } ${teams.find(
                                            (item) =>
                                              item._id === selectedTeams[0]
                                          ).last_name
                                            ? teams.find(
                                              (item) =>
                                                item._id ===
                                                selectedTeams[0]
                                            ).last_name
                                            : ""
                                          }`
                                          : teams.find(
                                            (item) =>
                                              item._id === selectedTeams[0]
                                          ).company_first_name
                                            ? `${teams.find(
                                              (item) =>
                                                item._id === selectedTeams[0]
                                            ).company_first_name
                                            } ${teams.find(
                                              (item) =>
                                                item._id === selectedTeams[0]
                                            ).company_last_name
                                              ? teams.find(
                                                (item) =>
                                                  item._id ===
                                                  selectedTeams[0]
                                              ).company_last_name
                                              : ""
                                            }`
                                            : `${teams.find(
                                              (item) =>
                                                item._id === selectedTeams[0]
                                            ).company_name
                                            } (Company)`
                                      }
                                    />
                                    {selectedTeams.length > 1 &&
                                      selectedTeams.length !== teams.length && (
                                        <div className="selected-option-count">
                                          {selectedTeams.length - 1}+
                                        </div>
                                      )}
                                  </>
                                )}
                              </div>
                            </>
                          )}
                        </div>
                        {isTeamDropdownOpen && (
                          <div className="dropdown-list1">
                            {teams.length > 0 && (
                              <>
                                <label
                                  className="checkbox-label1"
                                  key="selectAll"
                                >
                                  <input
                                    type="checkbox"
                                    checked={selectAllTeam}
                                    onChange={handleSelectAllTeamChange}
                                  />
                                  All Teams
                                </label>
                                {teams.map((team) => (
                                  <label
                                    className="checkbox-label1"
                                    key={team._id}
                                  >
                                    <input
                                      type="checkbox"
                                      checked={selectedTeams.includes(team._id)}
                                      onChange={() =>
                                        handleCheckboxTeamChange(team._id)
                                      }
                                    />
                                    {team.first_name
                                      ? `${team.first_name} ${team.middle_name ? team.middle_name : ""
                                      } ${team.last_name}`
                                      : team.company_first_name
                                        ? `${team.company_first_name} ${team.company_last_name
                                          ? team.company_last_name
                                          : ""
                                        }`
                                        : `${team.company_name} (Company)`}
                                  </label>
                                ))}
                              </>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}


              <div className="filter-inner invoice-status">
                <label for="invoiceStatus">Invoice Status</label>
                <div className="custom-dropdown1" ref={statusDropdownRef}>
                  <div className="drop-down-inner">
                    <div
                      className="selected-options1"
                      onClick={toggleStatusDropdown}
                    >
                      {selectedStatus.length === 0 ? (
                        <div className="placeholder">Select</div>
                      ) : (
                        <>
                          <div className="selected-option1" key={selectedStatus[0]} >
                            {selectedStatus.length === invStatusList.length ? (
                              "All"
                            ) : (
                              <>
                                <TruncateFilterFieldView
                                  textContent={
                                    invStatusList.find(
                                      (item) => item._id === selectedStatus[0]
                                    )?.status
                                  }
                                />
                                {selectedStatus.length > 1 &&
                                  selectedStatus.length !==
                                  invStatusList.length && (
                                    <div className="selected-option-count">
                                      {selectedStatus.length - 1}+
                                    </div>
                                  )}
                              </>
                            )}
                          </div>
                        </>
                      )}
                    </div>

                    {isStatusDropdownOpen && (
                      <div className="dropdown-list1">
                        {invStatusList.length > 0 && (
                          <>
                            <label className="checkbox-label1" key="selectAll">
                              <input
                                type="checkbox"
                                checked={selectAllStatus}
                                onChange={handleSelectAllStatusChange}
                              />
                              All
                            </label>
                            {invStatusList.map((status) => (
                              <label className="checkbox-label1" key={status._id}>
                                <input
                                  type="checkbox"
                                  checked={selectedStatus.includes(status._id)}
                                  onChange={() =>
                                    handleCheckboxStatusChange(status._id)
                                  }
                                />
                                {status.status && `${status.status}`}
                              </label>
                            ))}
                          </>
                        )}
                      </div>
                    )}
                    
                  </div>
                </div>
              </div>


              
              <div className="filter-inner payment-status">
                <label for="paymentStatus">Payment Status</label>
                <div className="custom-dropdown1" ref={paymentStatusDropdownRef}>
                  <div className="drop-down-inner">
                    <div
                      className="selected-options1"
                      onClick={togglePaymentStatusDropdown}
                    >
                      {selectedPaymentStatus.length === 0 ? (
                        <div className="placeholder">Select</div>
                      ) : (
                        <>
                          <div
                            className="selected-option1"
                            key={selectedPaymentStatus[0]}
                          >
                            {selectedPaymentStatus.length ===
                              invPaymStatusList.length ? (
                              "All"
                            ) : (
                              <>
                                <TruncateFilterFieldView
                                  textContent={
                                    invPaymStatusList.find(
                                      (item) =>
                                        item._id === selectedPaymentStatus[0]
                                    )?.status
                                  }
                                />
                                {selectedPaymentStatus.length > 1 &&
                                  selectedPaymentStatus.length !==
                                  invPaymStatusList.length && (
                                    <div className="selected-option-count">
                                      {selectedPaymentStatus.length - 1}+
                                    </div>
                                  )}
                              </>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                    {isPaymentStatusDropdownOpen && (
                      <div className="dropdown-list1">
                        {invPaymStatusList.length > 0 && (
                          <>
                            <label className="checkbox-label1" key="selectAll">
                              <input
                                type="checkbox"
                                checked={selectAllPaymentStatus}
                                onChange={handleSelectAllPaymentStatusChange}
                              />
                              All
                            </label>
                            {invPaymStatusList.map((paymentstatus) => (
                              <label
                                className="checkbox-label1"
                                key={paymentstatus._id}
                              >
                                <input
                                  type="checkbox"
                                  checked={selectedPaymentStatus.includes(
                                    paymentstatus._id
                                  )}
                                  onChange={() =>
                                    handleCheckboxPaymentStatusChange(
                                      paymentstatus._id
                                    )
                                  }
                                />
                                {paymentstatus.status &&
                                  `${paymentstatus.status}`}
                              </label>
                            ))}
                          </>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="filter-inner due-date">
                <label htmlFor="dueDate" className="calendar-icon">
                  Due Date
                </label>
                <div className="date-picker-outer date-picker-outer-all">
                  <div className="custom-picker-icon custom-picker-icon-all">
                    <svg
                      width="1em"
                      height="1em"
                      viewBox="0 0 14 14"
                      fill="currentColor"
                      aria-hidden="true"
                      focusable="false"
                      class="rs-picker-toggle-caret rs-icon"
                      aria-label="calendar"
                      data-category="legacy"
                    >
                      <path d="M1 4v8.5a.5.5 0 00.5.5H8c0-.128.049-.256.146-.354.555-.555.854-1.6.854-3.146a.5.5 0 01.621-.485l.119.03A2.623 2.623 0 0012.999 6.5V4h-12zm3-2h6V0h1v2h1.5A1.5 1.5 0 0114 3.5v3a3.623 3.623 0 01-4.015 3.603c-.064 1.245-.335 2.212-.831 2.898H12.5a.5.5 0 00.5-.5v-2a.5.5 0 011 0v2a1.5 1.5 0 01-1.5 1.5h-11a1.5 1.5 0 01-1.5-1.5v-9a1.5 1.5 0 011.5-1.5H3v-2h1v2zm5 4V5h1v1h2v1h-2v1H9V7H7v2h1v1H7v2H6v-2H4v2H3v-2H1V9h2V7H1V6h2V5h1v1h2V5h1v1h2zM6 9V7H4v2h2z"></path>
                    </svg>
                  </div>
                  <div className="custoom-icon-calender custoom-icon-calender-all">
                    <DatePicker
                      oneTap // Use DatePicker instead of DateRangePicker
                      format="dd/MM/yyyy"
                      ranges={ranges}
                      value={selectedDate}
                      onChange={handleDateChange}
                      placement="auto"
                      className="invoice-filter-top-date"
                      isoWeek={appSettings.weekStartDay && appSettings.weekStartDay === 'Monday'}
                      timeZone={global.config.getTimeZone()}
                    />
                  </div>
                </div>
              </div>
              <div className="filter-inner invoice-type">
                <label for="invoice-type">Invoice Type</label>
                <div className="custom-dropdown1" ref={invTypeDropdownRef}>
                  <div className="drop-down-inner">
                    <div
                      className="selected-options1"
                      onClick={toggleInvTypeDropdown}
                    >
                      {selectedInvTypes.length === 0 ? (
                        <div className="placeholder">Select</div>
                      ) : (
                        <>
                          <div
                            className="selected-option1"
                            key={selectedInvTypes[0]}
                          >
                            {selectedInvTypes.length === 2
                              ? "All"
                              : selectedInvTypes.join(", ")}
                          </div>
                          {selectedInvTypes.length > 1 &&
                            selectedInvTypes.length !== 2 && (
                              <div className="selected-option-count">
                                {selectedInvTypes.length - 1}+
                              </div>
                            )}
                        </>
                      )}
                    </div>
                    {isInvTypeDropdownOpen && (
                      <div className="dropdown-list1">
                        <label className="checkbox-label1" key="selectAll">
                          <input
                            type="checkbox"
                            checked={selectAllInvType}
                            onChange={handleSelectAllInvTypeChange}
                          />
                          All Types
                        </label>
                        <label className="checkbox-label1" key="Regular">
                          <input
                            type="checkbox"
                            checked={selectedInvTypes.includes("Regular")}
                            onChange={() =>
                              handleCheckboxInvTypeChange("Regular")
                            }
                          />
                          Regular
                        </label>
                        <label
                          className="checkbox-label1"
                          key="Reimbursement"
                        >
                          <input
                            type="checkbox"
                            checked={selectedInvTypes.includes(
                              "Reimbursement"
                            )}
                            onChange={() =>
                              handleCheckboxInvTypeChange("Reimbursement")
                            }
                          />
                          Reimbursement
                        </label>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="filter-inner due-date">
                <label htmlFor="dueDate" className="calendar-icon">
                  Issue Date
                </label>
                <div
                  className="date-picker-outer date-picker-outer-all"
                  ref={calendarRef}
                >
                  <div className="custom-picker-icon custom-picker-icon-all">
                    <svg
                      width="1em"
                      height="1em"
                      viewBox="0 0 14 14"
                      fill="currentColor"
                      aria-hidden="true"
                      focusable="false"
                      class="rs-picker-toggle-caret rs-icon"
                      aria-label="calendar"
                      data-category="legacy"
                    >
                      <path d="M1 4v8.5a.5.5 0 00.5.5H8c0-.128.049-.256.146-.354.555-.555.854-1.6.854-3.146a.5.5 0 01.621-.485l.119.03A2.623 2.623 0 0012.999 6.5V4h-12zm3-2h6V0h1v2h1.5A1.5 1.5 0 0114 3.5v3a3.623 3.623 0 01-4.015 3.603c-.064 1.245-.335 2.212-.831 2.898H12.5a.5.5 0 00.5-.5v-2a.5.5 0 011 0v2a1.5 1.5 0 01-1.5 1.5h-11a1.5 1.5 0 01-1.5-1.5v-9a1.5 1.5 0 011.5-1.5H3v-2h1v2zm5 4V5h1v1h2v1h-2v1H9V7H7v2h1v1H7v2H6v-2H4v2H3v-2H1V9h2V7H1V6h2V5h1v1h2V5h1v1h2zM6 9V7H4v2h2z"></path>
                    </svg>
                  </div>
                  <div className="custoom-icon-calender custoom-icon-calender-all">
                    <div className="custoom-icon-calender custoom-icon-calender-all custom-date-range ">
                      <input
                        type="text"
                        value={
                          dateRange
                            ? `${new Date(dateRange[0]).toLocaleDateString(
                              "en-GB",
                              {
                                day: "2-digit",
                                month: "2-digit",
                                year: "numeric",
                              }
                            )} to ${new Date(dateRange[1]).toLocaleDateString(
                              "en-GB",
                              {
                                day: "2-digit",
                                month: "2-digit",
                                year: "numeric",
                              }
                            )}`
                            : null
                        }
                        id="invoiceId"
                        name="invoiceId"
                        placeholder="All Time"
                        onClick={toggleCalendar}
                        autocomplete="off"
                        readOnly
                      />
                    </div>
                    <div className="date-range-new right-align">
                      {showCalendar && (
                        <Calendar
                          onCancel={cancelCalendar}
                          onChange={onDateChange}
                          initialRange={{
                            startDate: dateRange ? dateRange[0] : null,
                            endDate: dateRange ? dateRange[1] : null,
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="filter-invoice-right apply-fliter-right">
              <button onClick={toggleFilterModal}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="13"
                  height="17"
                  viewBox="0 0 13 17"
                  fill="none"
                >
                  <path
                    d="M11.7363 8.81108C11.4276 9.81451 10.4925 10.5444 9.38889 10.5444C8.28471 10.5444 7.35016 9.8141 7.04147 8.81108L0.722221 8.81108C0.323349 8.81108 -1.07845e-06 8.48773 -1.06101e-06 8.08886C-1.04357e-06 7.68998 0.323349 7.36663 0.722221 7.36663L7.04144 7.36663C7.35022 6.3632 8.28529 5.6333 9.38889 5.6333C10.4931 5.6333 11.4276 6.36362 11.7363 7.36663L12.2778 7.36663C12.6767 7.36663 13 7.68998 13 8.08886C13 8.48773 12.6766 8.81108 12.2778 8.81108L11.7363 8.81108ZM9.38889 7.07775C8.83162 7.07775 8.37778 7.53123 8.37778 8.08886C8.37778 8.64613 8.83127 9.09997 9.38889 9.09997C9.94616 9.09997 10.4 8.64648 10.4 8.08886C10.4 7.53158 9.94651 7.07775 9.38889 7.07775Z"
                    fill="#6479F8"
                  />
                  <path
                    d="M5.38078 3.17778C5.072 4.18121 4.13693 4.91111 3.03333 4.91111C1.92882 4.91111 0.994035 4.18035 0.68563 3.17687C0.303759 3.15781 -1.07791e-06 2.84216 -1.06101e-06 2.45556C-1.04411e-06 2.06896 0.303748 1.75331 0.685606 1.73425C0.994098 0.730343 1.9294 -4.83911e-07 3.03333 -4.35657e-07C4.13752 -3.87392e-07 5.07206 0.730315 5.38076 1.73333L12.2778 1.73333C12.6767 1.73333 13 2.05668 13 2.45556C13 2.85443 12.6766 3.17778 12.2778 3.17778L5.38078 3.17778ZM3.03333 1.44444C2.47606 1.44444 2.02222 1.89793 2.02222 2.45556C2.02222 3.01283 2.47571 3.46667 3.03333 3.46667C3.5906 3.46667 4.04444 3.01318 4.04444 2.45556C4.04444 1.89828 3.59096 1.44444 3.03333 1.44444Z"
                    fill="#6479F8"
                  />
                  <path
                    d="M7.403 14.4444C7.09422 15.4478 6.15916 16.1777 5.05556 16.1777C3.95137 16.1777 3.01682 15.4474 2.70813 14.4444L0.722221 14.4444C0.323349 14.4444 -1.07845e-06 14.121 -1.06101e-06 13.7222C-1.04357e-06 13.3233 0.323349 12.9999 0.722221 12.9999L2.70811 12.9999C3.01689 11.9965 3.95195 11.2666 5.05556 11.2666C6.15974 11.2666 7.09429 11.9969 7.40298 12.9999L12.2778 12.9999C12.6767 12.9999 13 13.3233 13 13.7222C13 14.121 12.6766 14.4444 12.2778 14.4444L7.403 14.4444ZM5.05556 12.711C4.49828 12.711 4.04444 13.1645 4.04444 13.7222C4.04444 14.2794 4.49793 14.7333 5.05556 14.7333C5.61283 14.7333 6.06667 14.2798 6.06667 13.7222C6.06667 13.1649 5.61318 12.711 5.05556 12.711Z"
                    fill="#6479F8"
                  />
                </svg>
                All Filters
              </button>
            </div>
            <Modal
              isOpen={showFilterModal}
              onRequestClose={checkAndShowConfirmationModal}
              contentLabel="Filter Modal"
              className="new-filter-modal"
              style={{
                content: {
                  height: "50%",
                  width: "50%",
                  position: "fixed",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-25%, -25%)",
                  borderRadius: "10px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  backgroundColor: "#fff",
                  padding: "20px",
                  // overflow: "auto",
                },
                overlay: {
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                },
              }}
            >
              <div className="new-nodal-invoice-outer-sec">

                <div className="new-modal-top-sec">
                  <div className="new-modal-heading">
                    All filters
                  </div>
                  <a className="close-image-clr1" onClick={checkAndShowConfirmationModal}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                      <path d="M1 13L13 1" stroke="black" />
                      <path d="M1 0.999999L13 13" stroke="black" />
                    </svg>{" "}
                  </a>
                </div>
                <div className="invoice-filter-inner-section">
                  <div className="filter-inner invoice-id">
                    <label for="fname">Invoice ID</label>
                    <input
                      type="text"
                      onChange={(e) => {
                        setInvoiceNumberFilter(e.target.value);
                        setCurrentPage(0);
                      }}
                      value={invoiceNumberFilter}
                      id="invoiceId"
                      name="invoiceId"
                      placeholder="All"
                    />
                  </div>
                  {user &&
                    (user.userType === userTypeConsts.SUPER_ADMIN ||
                      user.userType === userTypeConsts.ADMIN) && (
                      <div className="filter-inner invoice-status">
                        <label for="user">User</label>
                        <div className="custom-dropdown1" ref={teamModalDropdownRef}>
                          <div className="drop-down-inner">
                            <div
                              className="selected-options1"
                              onClick={toggleTeamModalDropdown}
                            >
                              {selectedTeams.length === 0 ? (
                                <div className="placeholder">Select</div>
                              ) : (
                                <>
                                  <div
                                    className="selected-option1"
                                    key={selectedTeams[0]}
                                  >
                                    {selectedTeams.length === teams.length ? (
                                      `All`
                                    ) : (
                                      <>
                                        <TruncateFilterFieldView
                                          textContent={
                                            teams.find(
                                              (item) =>
                                                item._id === selectedTeams[0]
                                            )?.first_name
                                              ? `${teams.find(
                                                (item) =>
                                                  item._id ===
                                                  selectedTeams[0]
                                              ).first_name
                                              } ${teams.find(
                                                (item) =>
                                                  item._id ===
                                                  selectedTeams[0]
                                              ).middle_name
                                                ? teams.find(
                                                  (item) =>
                                                    item._id ===
                                                    selectedTeams[0]
                                                ).middle_name
                                                : ""
                                              } ${teams.find(
                                                (item) =>
                                                  item._id ===
                                                  selectedTeams[0]
                                              ).last_name
                                                ? teams.find(
                                                  (item) =>
                                                    item._id ===
                                                    selectedTeams[0]
                                                ).last_name
                                                : ""
                                              }`
                                              : teams.find(
                                                (item) =>
                                                  item._id === selectedTeams[0]
                                              ).company_first_name
                                                ? `${teams.find(
                                                  (item) =>
                                                    item._id ===
                                                    selectedTeams[0]
                                                ).company_first_name
                                                } ${teams.find(
                                                  (item) =>
                                                    item._id ===
                                                    selectedTeams[0]
                                                ).company_last_name
                                                  ? teams.find(
                                                    (item) =>
                                                      item._id ===
                                                      selectedTeams[0]
                                                  ).company_last_name
                                                  : ""
                                                }`
                                                : `${teams.find(
                                                  (item) =>
                                                    item._id ===
                                                    selectedTeams[0]
                                                ).company_name
                                                } (Company)`
                                          }
                                        />
                                        {selectedTeams.length > 1 &&
                                          selectedTeams.length !==
                                          teams.length && (
                                            <div className="selected-option-count">
                                              {selectedTeams.length - 1}+
                                            </div>
                                          )}
                                      </>
                                    )}
                                  </div>
                                </>
                              )}
                            </div>
                            {isTeamModalDropdownOpen && (
                              <div className="dropdown-list1">
                                {teams.length > 0 && (
                                  <>
                                    <label
                                      className="checkbox-label1"
                                      key="selectAll"
                                    >
                                      <input
                                        type="checkbox"
                                        checked={selectAllTeam}
                                        onChange={handleSelectAllTeamChange}
                                      />
                                      All Teams
                                    </label>
                                    {teams.map((team) => (
                                      <label
                                        className="checkbox-label1"
                                        key={team._id}
                                      >
                                        <input
                                          type="checkbox"
                                          checked={selectedTeams.includes(
                                            team._id
                                          )}
                                          onChange={() =>
                                            handleCheckboxTeamChange(team._id)
                                          }
                                        />
                                        {team.first_name
                                          ? `${team.first_name} ${team.middle_name
                                            ? team.middle_name
                                            : ""
                                          } ${team.last_name}`
                                          : team.company_first_name
                                            ? `${team.company_first_name} ${team.company_last_name
                                              ? team.company_last_name
                                              : ""
                                            }`
                                            : `${team.company_name} (Company)`}
                                      </label>
                                    ))}
                                  </>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  <div className="filter-inner invoice-status">
                    <label for="invoiceStatus">Invoice Status</label>
                    <div className="custom-dropdown1" ref={statusModalDropdownRef}>
                      <div className="drop-down-inner">
                        <div
                          className="selected-options1"
                          onClick={toggleStatusModalDropdown}
                        >
                          {selectedStatus.length === 0 ? (
                            <div className="placeholder">Select</div>
                          ) : (
                            <>
                              <div
                                className="selected-option1"
                                key={selectedStatus[0]}
                              >
                                {selectedStatus.length ===
                                  invStatusList.length ? (
                                  "All"
                                ) : (
                                  <>
                                    <TruncateFilterFieldView
                                      textContent={
                                        invStatusList.find(
                                          (item) => item._id === selectedStatus[0]
                                        )?.status
                                      }
                                    />
                                    {selectedStatus.length > 1 &&
                                      selectedStatus.length !==
                                      invStatusList.length && (
                                        <div className="selected-option-count">
                                          {selectedStatus.length - 1}+
                                        </div>
                                      )}
                                  </>
                                )}
                              </div>
                            </>
                          )}
                        </div>
                        {isStatusModalDropdownOpen && (
                          <div className="dropdown-list1">
                            {invStatusList.length > 0 && (
                              <>
                                <label
                                  className="checkbox-label1"
                                  key="selectAll"
                                >
                                  <input
                                    type="checkbox"
                                    checked={selectAllStatus}
                                    onChange={handleSelectAllStatusChange}
                                  />
                                  All
                                </label>
                                {invStatusList.map((status) => (
                                  <label
                                    className="checkbox-label1"
                                    key={status._id}
                                  >
                                    <input
                                      type="checkbox"
                                      checked={selectedStatus.includes(
                                        status._id
                                      )}
                                      onChange={() =>
                                        handleCheckboxStatusChange(status._id)
                                      }
                                    />
                                    {status.status && `${status.status}`}
                                  </label>
                                ))}
                              </>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="filter-inner payment-status">
                    <label for="paymentStatus">Payment Status</label>
                    <div
                      className="custom-dropdown1"
                      ref={paymentStatusModalDropdownRef}
                    >
                      <div className="drop-down-inner">
                        <div
                          className="selected-options1"
                          onClick={togglePaymentStatusModalDropdown}
                        >
                          {selectedPaymentStatus.length === 0 ? (
                            <div className="placeholder">Select</div>
                          ) : (
                            <>
                              <div
                                className="selected-option1"
                                key={selectedPaymentStatus[0]}
                              >
                                {selectedPaymentStatus.length ===
                                  invPaymStatusList.length ? (
                                  "All"
                                ) : (
                                  <>
                                    <TruncateFilterFieldView
                                      textContent={
                                        invPaymStatusList.find(
                                          (item) =>
                                            item._id === selectedPaymentStatus[0]
                                        )?.status
                                      }
                                    />
                                    {selectedPaymentStatus.length > 1 &&
                                      selectedPaymentStatus.length !==
                                      invPaymStatusList.length && (
                                        <div className="selected-option-count">
                                          {selectedPaymentStatus.length - 1}+
                                        </div>
                                      )}
                                  </>
                                )}
                              </div>
                            </>
                          )}
                        </div>
                        {isPaymentStatusModalDropdownOpen && (
                          <div className="dropdown-list1">
                            {invPaymStatusList.length > 0 && (
                              <>
                                <label
                                  className="checkbox-label1"
                                  key="selectAll"
                                >
                                  <input
                                    type="checkbox"
                                    checked={selectAllPaymentStatus}
                                    onChange={handleSelectAllPaymentStatusChange}
                                  />
                                  All
                                </label>
                                {invPaymStatusList.map((paymentstatus) => (
                                  <label
                                    className="checkbox-label1"
                                    key={paymentstatus._id}
                                  >
                                    <input
                                      type="checkbox"
                                      checked={selectedPaymentStatus.includes(
                                        paymentstatus._id
                                      )}
                                      onChange={() =>
                                        handleCheckboxPaymentStatusChange(
                                          paymentstatus._id
                                        )
                                      }
                                    />
                                    {paymentstatus.status &&
                                      `${paymentstatus.status}`}
                                  </label>
                                ))}
                              </>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="filter-inner due-date">
                    <label htmlFor="dueDate" className="calendar-icon">
                      Due Date
                    </label>
                    <div className="date-picker-outer date-picker-outer-all">
                      <div className="custom-picker-icon custom-picker-icon-all">
                        <svg
                          width="1em"
                          height="1em"
                          viewBox="0 0 14 14"
                          fill="currentColor"
                          aria-hidden="true"
                          focusable="false"
                          class="rs-picker-toggle-caret rs-icon"
                          aria-label="calendar"
                          data-category="legacy"
                        >
                          <path d="M1 4v8.5a.5.5 0 00.5.5H8c0-.128.049-.256.146-.354.555-.555.854-1.6.854-3.146a.5.5 0 01.621-.485l.119.03A2.623 2.623 0 0012.999 6.5V4h-12zm3-2h6V0h1v2h1.5A1.5 1.5 0 0114 3.5v3a3.623 3.623 0 01-4.015 3.603c-.064 1.245-.335 2.212-.831 2.898H12.5a.5.5 0 00.5-.5v-2a.5.5 0 011 0v2a1.5 1.5 0 01-1.5 1.5h-11a1.5 1.5 0 01-1.5-1.5v-9a1.5 1.5 0 011.5-1.5H3v-2h1v2zm5 4V5h1v1h2v1h-2v1H9V7H7v2h1v1H7v2H6v-2H4v2H3v-2H1V9h2V7H1V6h2V5h1v1h2V5h1v1h2zM6 9V7H4v2h2z"></path>
                        </svg>
                      </div>
                      <div className="custoom-icon-calender custoom-icon-calender-all">
                        <DatePicker
                          oneTap // Use DatePicker instead of DateRangePicker
                          format="dd/MM/yyyy"
                          ranges={ranges}
                          value={selectedDate}
                          onChange={handleDateChange}
                          placement="auto"
                          className="invoice-filter-top-date"
                          isoWeek={appSettings.weekStartDay && appSettings.weekStartDay === 'Monday'}
                          timeZone={global.config.getTimeZone()}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="filter-inner invoice-type">
                    <label for="invoice-type">Invoice Type</label>
                    <div className="custom-dropdown1" ref={invTypeModalDropdownRef}>
                      <div className="drop-down-inner">
                        <div
                          className="selected-options1"
                          onClick={toggleInvTypeModalDropdown}
                        >
                          {selectedInvTypes.length === 0 ? (
                            <div className="placeholder">Select</div>
                          ) : (
                            <>
                              <div
                                className="selected-option1"
                                key={selectedInvTypes[0]}
                              >
                                {selectedInvTypes.length === 2
                                  ? "All"
                                  : selectedInvTypes.join(", ")}
                              </div>
                              {selectedInvTypes.length > 1 &&
                                selectedInvTypes.length !== 2 && (
                                  <div className="selected-option-count">
                                    {selectedInvTypes.length - 1}+
                                  </div>
                                )}
                            </>
                          )}
                        </div>
                        {isInvTypeModalDropdownOpen && (
                          <div className="dropdown-list1">
                            <label className="checkbox-label1" key="selectAll">
                              <input
                                type="checkbox"
                                checked={selectAllInvType}
                                onChange={handleSelectAllInvTypeChange}
                              />
                              All Types
                            </label>
                            <label className="checkbox-label1" key="Regular">
                              <input
                                type="checkbox"
                                checked={selectedInvTypes.includes("Regular")}
                                onChange={() =>
                                  handleCheckboxInvTypeChange("Regular")
                                }
                              />
                              Regular
                            </label>
                            <label
                              className="checkbox-label1"
                              key="Reimbursement"
                            >
                              <input
                                type="checkbox"
                                checked={selectedInvTypes.includes(
                                  "Reimbursement"
                                )}
                                onChange={() =>
                                  handleCheckboxInvTypeChange("Reimbursement")
                                }
                              />
                              Reimbursement
                            </label>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="filter-inner due-date">
                    <label htmlFor="dueDate" className="calendar-icon">
                      Issue Date
                    </label>
                    <div
                      className="date-picker-outer date-picker-outer-all"
                      ref={calendarModalRef}
                    >
                      <div className="custom-picker-icon custom-picker-icon-all">
                        <svg
                          width="1em"
                          height="1em"
                          viewBox="0 0 14 14"
                          fill="currentColor"
                          aria-hidden="true"
                          focusable="false"
                          class="rs-picker-toggle-caret rs-icon"
                          aria-label="calendar"
                          data-category="legacy"
                        >
                          <path d="M1 4v8.5a.5.5 0 00.5.5H8c0-.128.049-.256.146-.354.555-.555.854-1.6.854-3.146a.5.5 0 01.621-.485l.119.03A2.623 2.623 0 0012.999 6.5V4h-12zm3-2h6V0h1v2h1.5A1.5 1.5 0 0114 3.5v3a3.623 3.623 0 01-4.015 3.603c-.064 1.245-.335 2.212-.831 2.898H12.5a.5.5 0 00.5-.5v-2a.5.5 0 011 0v2a1.5 1.5 0 01-1.5 1.5h-11a1.5 1.5 0 01-1.5-1.5v-9a1.5 1.5 0 011.5-1.5H3v-2h1v2zm5 4V5h1v1h2v1h-2v1H9V7H7v2h1v1H7v2H6v-2H4v2H3v-2H1V9h2V7H1V6h2V5h1v1h2V5h1v1h2zM6 9V7H4v2h2z"></path>
                        </svg>
                      </div>
                      <div className="custoom-icon-calender custoom-icon-calender-all">
                        <div className="custoom-icon-calender custoom-icon-calender-all custom-date-range">
                          <input
                            type="text"
                            // onChange={}
                            value={
                              dateRange
                                ? `${new Date(dateRange[0]).toLocaleDateString(
                                  "en-GB",
                                  {
                                    day: "2-digit",
                                    month: "2-digit",
                                    year: "numeric",
                                  }
                                )} to ${new Date(
                                  dateRange[1]
                                ).toLocaleDateString("en-GB", {
                                  day: "2-digit",
                                  month: "2-digit",
                                  year: "numeric",
                                })}`
                                : null
                            }
                            id="invoiceId"
                            name="invoiceId"
                            placeholder="All Time"
                            onClick={toggleModalCalendar}
                            autocomplete="off"
                          />
                        </div>

                        {/* Date range selector */}
                        <div className="date-range-new">
                          {showModalCalendar && (
                            <Calendar
                              onCancel={cancelModalCalendar}
                              onChange={onDateChange}
                              initialRange={{
                                startDate: dateRange ? dateRange[0] : null,
                                endDate: dateRange ? dateRange[1] : null,
                              }}
                            />
                          )}
                        </div>
                        {/* Date range selector */}
                      </div>
                    </div>
                  </div>
                  <Modal
                    className="delete-modal"
                    isOpen={showConfirmationModal}
                    onRequestClose={handleCancel}
                    contentLabel="Confirm Delete"
                    style={{
                      content: {
                        height: "41%",
                        width: "30%",
                        position: "fixed",
                        top: "36%",
                        left: "50%",
                        transform: "translate(-24.75%, 0%)",
                      },
                    }}
                  >
                    <div className="delete-modal-content">
                      <h2>Apply or Discard changes?</h2>
                      <p>
                        Do you want to Apply or Discard filter changes?
                      </p>

                      <div className="delete-modal-buttons">
                        <button className="delete" onClick={handleApplyConfirm} >
                          Apply Changes
                        </button>
                        <button onClick={clearFilters}>
                          Discard Changes
                        </button>
                      </div>
                    </div>
                  </Modal>
                </div>
              </div>
              <div className="filter-buttons">
                <button className="clear-all" onClick={clearFilters}>Clear All</button>
                <button className="apply-filter" onClick={applyFilters}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="13" height="17" viewBox="0 0 13 17" fill="none">
                    <path d="M11.7363 8.81108C11.4276 9.81451 10.4925 10.5444 9.38889 10.5444C8.28471 10.5444 7.35016 9.8141 7.04147 8.81108L0.722221 8.81108C0.323349 8.81108 -1.07845e-06 8.48773 -1.06101e-06 8.08886C-1.04357e-06 7.68998 0.323349 7.36663 0.722221 7.36663L7.04144 7.36663C7.35022 6.3632 8.28529 5.6333 9.38889 5.6333C10.4931 5.6333 11.4276 6.36362 11.7363 7.36663L12.2778 7.36663C12.6767 7.36663 13 7.68998 13 8.08886C13 8.48773 12.6766 8.81108 12.2778 8.81108L11.7363 8.81108ZM9.38889 7.07775C8.83162 7.07775 8.37778 7.53123 8.37778 8.08886C8.37778 8.64613 8.83127 9.09997 9.38889 9.09997C9.94616 9.09997 10.4 8.64648 10.4 8.08886C10.4 7.53158 9.94651 7.07775 9.38889 7.07775Z" fill="white" />
                    <path d="M5.38078 3.17778C5.072 4.18121 4.13693 4.91111 3.03333 4.91111C1.92882 4.91111 0.994035 4.18035 0.68563 3.17687C0.303759 3.15781 -1.07791e-06 2.84216 -1.06101e-06 2.45556C-1.04411e-06 2.06896 0.303748 1.75331 0.685606 1.73425C0.994098 0.730343 1.9294 -4.83911e-07 3.03333 -4.35657e-07C4.13752 -3.87392e-07 5.07206 0.730315 5.38076 1.73333L12.2778 1.73333C12.6767 1.73333 13 2.05668 13 2.45556C13 2.85443 12.6766 3.17778 12.2778 3.17778L5.38078 3.17778ZM3.03333 1.44444C2.47606 1.44444 2.02222 1.89793 2.02222 2.45556C2.02222 3.01283 2.47571 3.46667 3.03333 3.46667C3.5906 3.46667 4.04444 3.01318 4.04444 2.45556C4.04444 1.89828 3.59096 1.44444 3.03333 1.44444Z" fill="white" />
                    <path d="M7.403 14.4444C7.09422 15.4478 6.15916 16.1777 5.05556 16.1777C3.95137 16.1777 3.01682 15.4474 2.70813 14.4444L0.722221 14.4444C0.323349 14.4444 -1.07845e-06 14.121 -1.06101e-06 13.7222C-1.04357e-06 13.3233 0.323349 12.9999 0.722221 12.9999L2.70811 12.9999C3.01689 11.9965 3.95195 11.2666 5.05556 11.2666C6.15974 11.2666 7.09429 11.9969 7.40298 12.9999L12.2778 12.9999C12.6767 12.9999 13 13.3233 13 13.7222C13 14.121 12.6766 14.4444 12.2778 14.4444L7.403 14.4444ZM5.05556 12.711C4.49828 12.711 4.04444 13.1645 4.04444 13.7222C4.04444 14.2794 4.49793 14.7333 5.05556 14.7333C5.61283 14.7333 6.06667 14.2798 6.06667 13.7222C6.06667 13.1649 5.61318 12.711 5.05556 12.711Z" fill="white" />
                  </svg>
                  Apply Filters
                </button>
              </div>
            </Modal>
          </div>

        </div>
        <div className="card-outer1">
          <div className="card-outer">
            <div className="card">
              {isLoading == true ? (
                <div className="outter-load-table">
                  <ThreeDots
                    height="80"
                    width="80"
                    radius="9"
                    color="#405BFF"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                </div>
              ) : (
                <div className="datatable-in-invoice">
                  <DataTable
                    value={filteredData}
                    removableSort
                    tableStyle={{
                      minWidth: "50rem",
                      width: "100%",
                      textAlign: "left",
                    }}
                    paginator={filteredData.length > 10}
                    paginatorClassName="recent-invoices-pagination"
                    rows={rowsPerPage} // Set the number of rows per page
                    first={currentPage * rowsPerPage} // Set the current page using currentPage
                    onPage={onPageChange} // Update currentPage when changing pages
                  >
                    <Column
                      header={() => (
                        <input
                          type="checkbox"
                          onChange={handleSelectAllChange}
                          checked={selectAll}
                        />
                      )}
                      body={(rowData) =>
                        checkboxTemplate(rowData, filteredData.indexOf(rowData))
                      }
                      style={{
                        width: "50px",
                        maxWidth: "100px",
                        textAlign: "center",
                        paddingRight: "20px",
                      }}
                      className="fixed-se"
                    />
                    <Column
                      field="inv_number"
                      header="Invoice ID"
                      className="fixed-sec"
                      sortable
                      style={{ paddingRight: "44px", left: `${sec}px` }}
                    ></Column>
                    <Column
                      field="inv_for.first_name"
                      body={useRenderMemberName}
                      header="User Name"
                      className="fixed-se1"
                      sortable
                      style={{
                        paddingRight: "35px",
                        maxWidth: "230px",
                        minWidth: "230px",
                        width: "230px",
                        left: `${sec1}px`,
                      }}
                      sortField="fullName" // Use a different sortField to avoid confusion with the original field
                      sortFunction={(e) => {
                        const dataCopy = [...filteredData];
                        dataCopy.sort((rowData1, rowData2) => {
                          const fullName1 =
                            (rowData1.inv_for.first_name ||
                              rowData1.inv_for.company_name) +
                            (rowData1.inv_for.middle_name &&
                              rowData1.inv_for.middle_name !== "null"
                              ? " " + rowData1.inv_for.middle_name
                              : "") +
                            " " +
                            rowData1.inv_for.last_name;

                          const fullName2 =
                            (rowData2.inv_for.first_name ||
                              rowData2.inv_for.company_name) +
                            (rowData2.inv_for.middle_name &&
                              rowData2.inv_for.middle_name !== "null"
                              ? " " + rowData2.inv_for.middle_name
                              : "") +
                            " " +
                            rowData2.inv_for.last_name;

                          // Implement your custom comparison logic here
                          return e.order === 1
                            ? fullName1.localeCompare(fullName2)
                            : fullName2.localeCompare(fullName1);
                        });
                        return dataCopy;
                      }}
                    ></Column>
                    <Column
                      field="_id"
                      body={renderCTA}
                      className="fixed-sec2"
                      style={{
                        paddingRight: "45px",
                        minWidth: "121.73px",
                        left: `${sec2}px`,
                      }}
                    ></Column>
                    <Column
                      field="inv_due_date"
                      body={dateConvert}
                      header="Due Date"
                      className="fixed-sec3"
                      sortable
                      style={{ paddingRight: "44px", left: `${sec3}px` }}
                    ></Column>
                    <Column
                      field="inv_status"
                      header="Status"
                      className="fixed-sec4"
                      body={renderStatus}
                      sortable
                      style={{ paddingRight: "44px" }}
                    ></Column>
                    <Column
                      field="inv_payment_status"
                      header="Payment Status"
                      body={(rowData) => (
                        <PaymentStatusWise
                          inv_payment_details={rowData.inv_payment_details}
                          invData={rowData}
                          invoiceId={rowData.inv_number}
                          createdAt={rowData.created_at}
                          paymentStatus={rowData.inv_payment_status}
                        />
                      )}
                      sortable
                      style={{ paddingRight: "44px" }}
                    ></Column>
                    <Column
                      field="inv_total_amount"
                      header="Amount"
                      body={renderAmount}
                      className="amount-bold"
                      sortable
                      style={{ paddingRight: "44px" }}
                    ></Column>
                    <Column
                      field="inv_created_at"
                      body={createDateConvert}
                      header="Creation Date"
                      sortable
                      style={{ paddingRight: "44px" }}
                    ></Column>
                    <Column
                      field="inv_created_by"
                      body={(rowData) => (
                        <MemberName userId={rowData.inv_created_by} />
                      )}
                      header="Created by"
                      // sortable
                      style={{
                        paddingRight: "44px",
                        maxWidth: "230px",
                        minWidth: "230px",
                        width: "230px",
                      }}
                    ></Column>
                     <Column
                      field="inv_created_by"
                      body={(rowData) => (
                        <MemberName userId={rowData.inv_approved_by} />
                      )}
                      header="Approved by"
                      // sortable
                      style={{
                        paddingRight: "44px",
                        maxWidth: "230px",
                        minWidth: "230px",
                        width: "230px",
                      }}
                    ></Column>
                    <Column
                      field="inv_payment_details"
                      body={(rowData) => (
                         //rowData.inv_number
                        <PaymentStatus
                          inv_payment_details={rowData.inv_payment_details}
                          invData={rowData}
                          payStatus={rowData.inv_payment_status}
                        />
                      )}
                      header="Paid Date"
                      // sortable
                      style={{ paddingRight: "44px" }}
                    ></Column>
                    <Column
                      field="inv_company_name"
                      body={(rowData) => {
                        if (rowData.inv_company_name !== "WX" && rowData.inv_company_name !== "Xpertos" && rowData.inv_company_name !== "Any") {
                          return "Any";
                        } else {
                          return rowData.inv_company_name;
                        }
                      }}
                      header="Recipient"
                      sortable
                      style={{ paddingRight: "44px" }}
                    ></Column>
                    <Column
                      field="app"
                      header="App"
                      body={renderImage}
                      // sortable
                      style={{}}
                    ></Column>
                  </DataTable>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};
export default Invoices;
