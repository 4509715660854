import axios from "axios";
import Modal from "react-modal";
import * as XLSX from "xlsx";
import "./ClientsOverview.css";
import { toast } from "react-toastify";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ThreeDots } from "react-loader-spinner";
import React, { useEffect, useRef, useState } from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import Countries from "../../constants/countries";
import userTypeConsts from '../../constants/userTypeConsts';
import { useSelector, useDispatch } from "react-redux";
import Plus from "../../images/icons/plus-black.svg";
import PlusIcon from "../../images/icons/plus-icon.svg";
import InviteIcon from "../../images/icons/invite.svg";
import crssrImage from "../../images/singleinvoice/cross-red.svg";
import dletImage from "../../images/singleinvoice/delete.svg";
import addicon from "../../images/icons/addicon.svg";
import DownloadImage from "../../images/icons/download-icon.svg";
import { setNotification } from "../../features/notification/notificationSlice.js";
import { setHeaderText } from "../../features/headerText/headerTextSlice";
import { logout, reset } from "../../features/auth/authSlice";
import { reset as invoiceReset } from "../../features/invoice/invoiceSlice.js";
import { reset as headerReset } from "../../features/headerText/headerTextSlice.js";
import { reset as appSettingsReset } from "../../features/appSettings/appSettingsSlice.js";
import { useUserById } from "../TimeOff/timeOffApi.jsx";
import ClientsOverviewListing from "./ClientsOverviewListing";
import OD from "./ClientDocument/OneDrive.jsx";
import {
  useFetchClientDetails,
  useFetchClientExportDetails,
} from "../TimeTracker/TimeTrackerClient/useClientDetails.jsx";

const ClientOverviewSection = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [clientName, setClientName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [accManager, setAccManager] = useState("");
  const [accManagerDropdownOpen, setAccManagerDropdownOpen] = useState(false);
  const [userSearchTerm, setUserSearchTerm] = useState("");
  const [accManagerIsValid, setAccManagerIsValid] = useState(false);
  const [contactPoint, setContactPoint] = useState("");
  const [contactPointDropdownOpen, setContactPointDropdownOpen] = useState(false);
  // const [contactPointSearchTerm, setContactPointSearchTerm] = useState('');
  const [contactPointIsValid, setContactPointIsValid] = useState(false);
  const [companyWebsite, setCompanyWebsite] = useState("");
  const [isValidUrl, setIsValidUrl] = useState(false);
  const [companyLogo, setCompanyLogo] = useState(null);
  const [companyLogoName, setCompanyLogoName] = useState("");
  const [country, setCountry] = useState("");
  const [address, setAddress] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [passwordMatchError, setPasswordMatchError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [additionalInfo, setAdditionalInfo] = useState("");
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [updateClientList, setUpdateClientList] = useState(false);
  const [countrySearchTerm, setCountrySearchTerm] = useState("");
  const [countryDropdownOpen, setCountryDropdownOpen] = useState(false);
  const [countriesList, setCountriesList] = useState([]);
  const [filter, setFilter] = useState("");
  const [statusDropdownOpen, setStatusDropdownOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [isCliInviteModalOpen, setIsCliInviteModalOpen] = useState(false);
  const [cliInvEmail, setCliInvEmail] = useState("");
  const [cliInvEmails, setCliInvEmails] = useState([]);
  const [alreadySentCliInvEmailsList, setAlreadySentCliInvEmailsList] = useState([]);
  const [isResendCliInviteModalOpen, setIsResendCliInviteModalOpen] = useState(false);
  const [isCliInvMailLoading, setIsCliInvMailLoading] = useState(false);
  const [isBulkImportModalOpen, setIsBulkImportModalOpen] = useState(false);
  const [isBulkImportLoading, setIsBulkImportLoading] = useState(false);
  const [bulkImportFile, setBulkImportFile] = useState(null);
  const [bulkImportFileName, setBulkImportFileName] = useState("");
  const accManagerRef = useRef(null);
  const accManagerSelectDropdownRef = useRef(null);
  const contactPointRef = useRef(null);
  const contactPointSelectDropdownRef = useRef(null);
  const statusDropdownRef = useRef(null);
  const countryDropdownRef = useRef(null);
  const passwordRef = useRef(null);
  const passwordConfirmationRef = useRef(null);
  const [sortDropdownOpen, setSortDropdownOpen] = useState(false);
  const [selectedSortOption, setSelectedSortOption] = useState(null);
  const [checkedOption, setCheckedOption] = useState(null);
  const sortDropdownRef = useRef(null);

  let token = null;
  const { user } = useSelector((state) => state.auth);
  const { appSettings } = useSelector((state) => state.appSettings);
  if (user) {
    if (user && user.token) {
      token = user.token;
    }
  }
  const { data: userIds = [], isLoading: isLoadingUser } = useUserById(token);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setClientName("");
    setEmail("");
    setMobileNo("");
    setAccManager("");
    setUserSearchTerm("");
    setAccManagerDropdownOpen(false);
    setAccManagerIsValid(false);
    setContactPoint("");
    setContactPointDropdownOpen(false);
    setContactPointIsValid(false);
    setCompanyWebsite("");
    setCompanyLogo(null);
    setCompanyLogoName("");
    setCountry("");
    setAddress("");
    setAdditionalInfo("");
  };

  const handleLogoUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setCompanyLogo(e.target.files[0]);
      setCompanyLogoName(file.name);
    } else {
      setCompanyLogo(null);
      setCompanyLogoName("");
    }
  };

  useEffect(() => {
    dispatch(setHeaderText("Client Overview"));
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        document.activeElement.blur();
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleToggleAccManagerDropdown = () => {
    setUserSearchTerm("");
    setAccManagerDropdownOpen((prevOpen) => !prevOpen);
  };

  const handleSelectAccManager = (selectedAccManager) => {
    setAccManager(selectedAccManager);
    setUserSearchTerm("");
    setAccManagerDropdownOpen(false);
  };

  const handleAccManagerSearchInputChange = (e) => {
    setUserSearchTerm(e.target.value);
  };

  const handleToggleContactPointDropdown = () => {
    setUserSearchTerm("");
    setContactPointDropdownOpen((prevOpen) => !prevOpen);
  };

  const handleSelectContactPoint = (selectedContactPoint) => {
    setContactPoint(selectedContactPoint);
    setUserSearchTerm("");
    setContactPointDropdownOpen(false);
  };

  const handleContactPointSearchInputChange = (e) => {
    setUserSearchTerm(e.target.value);
  };

  const filteredUsers = userIds.filter((user) => {
    const fullName = user.first_name
      ? `${user.first_name} ${user.middle_name || ""} ${user.last_name}`
      : `${user.company_first_name
        ? user.company_first_name +
        (user.company_last_name && user.company_last_name)
        : user.company_name
      }`;
    return fullName?.toLowerCase().includes(userSearchTerm?.toLowerCase());
  });

  useEffect(() => {
    const sortedCountries = Countries.slice().sort((a, b) =>
      a.country.localeCompare(b.country)
    );
    setCountriesList(sortedCountries);
  }, []);

  const handleToggleCountryDropdown = () => {
    setCountrySearchTerm("");
    setCountryDropdownOpen((prevOpen) => !prevOpen);
  };

  const handleCountrySearchInputChange = (e) => {
    setCountrySearchTerm(e.target.value);
  };

  const filteredCountries = countriesList.filter((country) => {
    return country.country
      ?.toLowerCase()
      .includes(countrySearchTerm?.toLowerCase());
  });

  const handleClickOutsideDropdown = (event) => {
    const isClickInsideCountryDropdown =
      countryDropdownRef.current &&
      countryDropdownRef.current.contains(event.target);
    const isClickInsideSortDropdown =
      sortDropdownRef.current && sortDropdownRef.current.contains(event.target);
    const isClickInsideStatusDropdown =
      statusDropdownRef.current &&
      statusDropdownRef.current.contains(event.target);
    const isClickInsideAccManagerSelectDropdown =
      accManagerSelectDropdownRef.current &&
      accManagerSelectDropdownRef.current.contains(event.target);
    const isClickInsideContactPointSelectDropdown =
      contactPointSelectDropdownRef.current &&
      contactPointSelectDropdownRef.current.contains(event.target);

    if (!isClickInsideCountryDropdown) {
      setCountryDropdownOpen(false);
    }
    if (!isClickInsideSortDropdown) {
      setSortDropdownOpen(false);
    }
    if (!isClickInsideStatusDropdown) {
      setStatusDropdownOpen(false);
    }
    if (!isClickInsideAccManagerSelectDropdown) {
      setAccManagerDropdownOpen(false);
    }
    if (!isClickInsideContactPointSelectDropdown) {
      setContactPointDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutsideDropdown);
    return () => {
      document.removeEventListener("click", handleClickOutsideDropdown);
    };
  }, []);

  const toggleStatusDropdown = () => {
    setStatusDropdownOpen(!statusDropdownOpen);
  };
  const selectStatusOption = (selectedStatus) => {
    setSelectedStatus(selectedStatus);
    setStatusDropdownOpen(false);
  };

  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (sortDropdownRef.current && !sortDropdownRef.current.contains(event.target)) {
  //       setSortDropdownOpen(false);
  //     }
  //   };

  //   document.addEventListener('mousedown', handleClickOutside);

  //   return () => {
  //     document.removeEventListener('mousedown', handleClickOutside);
  //   };
  // }, []);

  const handleCliInviteSubmit = async (e) => {
    e.preventDefault();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmails = cliInvEmails.every(
      (obj) => !obj.cliInvEmail || emailRegex.test(obj.cliInvEmail)
    );
    if (!isValidEmails) {
      toast.error("Please enter valid email addresses!!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      handleCloseCliInviteModal();
      return;
    }
    var allCliInvEmails = cliInvEmails;
    if (cliInvEmail !== "") {
      allCliInvEmails = [
        ...cliInvEmails,
        { cliInvEmail: cliInvEmail, id: Date.now() },
      ];
    }
    if (!cliInvEmail) {
      toast.error("Email cannot be empty!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    if (allCliInvEmails.some((obj) => !obj.cliInvEmail)) {
      toast.error("Email cannot be empty!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    try {
      setIsCliInvMailLoading(true);
      const response = await fetch("/api/client-invite", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          cliInvEmails: allCliInvEmails.map((obj) => obj.cliInvEmail),
          invite_status: "0",
        }),
      });
      const data = await response.json();
      setCliInvEmails([]);
      setCliInvEmail("");
      setIsCliInviteModalOpen(false);
      if (response.ok) {
        if (
          data.alreadySentCliInvEmails.length > 0 ||
          data.alreadyRegisteredCliInvEmails.length > 0
        ) {
          const alreadySentCliInvEmails =
            data.alreadySentCliInvEmails.join(", ");
          const alreadyRegisteredCliInvEmails =
            data.alreadyRegisteredCliInvEmails.join(", ");
          const successSentCliInvEmails =
            data.successSentCliInvEmails.join(", ");
          if (alreadySentCliInvEmails) {
            setAlreadySentCliInvEmailsList(data.alreadySentCliInvEmails);
            const sentCliInvEmailsList = data.alreadySentCliInvEmails
              .map((entry) => entry)
              .join(", ");
            handleOpenResendCliInviteModal();
            toast.warning(
              `Invitation already sent to ${sentCliInvEmailsList}`,
              {
                position: "bottom-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              }
            );
          }
          if (alreadyRegisteredCliInvEmails) {
            toast.warning(
              `User with email ${alreadyRegisteredCliInvEmails} is already registered`,
              {
                position: "bottom-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              }
            );
          }
          if (successSentCliInvEmails) {
            dispatch(setNotification(Date.now()));
            toast.success(
              `Invitation successfully sent to ${successSentCliInvEmails}`,
              {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              }
            );
            global.config.activityLog(
              window.location.href,
              "User Invite",
              `Invitation successfully sent to ${successSentCliInvEmails}`
            );
          }
        } else {
          const successSentCliInvEmails =
            data.successSentCliInvEmails.join(", ");
          dispatch(setNotification(Date.now()));
          toast.success("Mail sent successfully!", {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          global.config.activityLog(
            window.location.href,
            "User Invite",
            `Invitation successfully sent to ${successSentCliInvEmails}`
          );
        }
      } else {
        toast.error("Failed to send mail.", {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      console.log(error);
      global.config.slackMessage(error.toString());
      alert("Something went wrong");
    } finally {
      setIsCliInvMailLoading(false);
      handleCloseCliInviteModal();
    }
  };

  const handleResendCliInvEmails = async () => {
    try {
      setIsCliInvMailLoading(true);
      const response = await fetch("/api/client/resend-invitations", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          cliInvEmails: alreadySentCliInvEmailsList, // List of emails to resend
        }),
      });

      if (response.ok) {
        // Handle success
        // Close the resend modal
        setIsResendCliInviteModalOpen(false);
        // Show a success message
        toast.success("Invitations resent successfully!", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        global.config.activityLog(
          window.location.href,
          "User Invite",
          `Invitation successfully resent to ${alreadySentCliInvEmailsList}`
        );
      } else {
        // Handle failure
        // Show an error message
        toast.error("Failed to resend invitations.", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.log(error);
      // Handle network or server error
      // Show an error message
      global.config.slackMessage(error.toString());
      toast.error("Failed to resend invitations.", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } finally {
      setIsCliInvMailLoading(false);
      handleCloseResendCliInviteModal();
    }
  };

  const handleAddCliInvEmail = (e) => {
    e.preventDefault();
    // if (email !== "") {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidCliInvEmails = emailRegex.test(cliInvEmail);
    if (cliInvEmail && !isValidCliInvEmails) {
      toast.error("Please enter valid email addresses!!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    // if (email) {
    const newCliInvEmailEntry = { cliInvEmail: cliInvEmail, id: Date.now() };
    setCliInvEmails([...cliInvEmails, newCliInvEmailEntry]);
    // }
    setCliInvEmail("");
    // }
  };

  const handleEditCliInvEmail = (id, field, value) => {
    // Find the index of the email entry with the given id
    const index = cliInvEmails.findIndex((obj) => obj.id === id);
    if (index !== -1) {
      // Create a copy of the emails array to avoid mutating state directly
      const updatedCliInvEmails = [...cliInvEmails];
      // Update the specified field of the email entry
      updatedCliInvEmails[index][field] = value;
      // Update the state with the new array
      setCliInvEmails(updatedCliInvEmails);
    }
  };

  const handleRemoveCliInvEmail = (id) => {
    const filteredCliInvEmails = cliInvEmails.filter((obj) => obj.id !== id);
    setCliInvEmails(filteredCliInvEmails);
  };

  const handleRemoveLatestCliInvEmail = () => {
    const updatedCliInvEmails = [...cliInvEmails];
    const lastCliInvEmail = updatedCliInvEmails.pop(); // Remove the last element
    setCliInvEmails(updatedCliInvEmails);
    // Set state variables with the latest values
    setCliInvEmail(lastCliInvEmail.cliInvEmail);
  };

  // Function to remove an email from the list
  const handleRemoveResendCliInvEmail = (cliInvEmailToRemove) => {
    const updatedCliInvEmails = alreadySentCliInvEmailsList.filter(
      (cliInvEmail) => cliInvEmail !== cliInvEmailToRemove
    );
    setAlreadySentCliInvEmailsList(updatedCliInvEmails);
  };

  const handleOpenResendCliInviteModal = () => {
    // Set the list of already sent emails here
    setIsResendCliInviteModalOpen(true);
  };

  const handleCloseResendCliInviteModal = () => {
    setIsResendCliInviteModalOpen(false);
    setAlreadySentCliInvEmailsList([]);
  };

  const handleOpenCliInviteModal = () => {
    setIsCliInviteModalOpen(true);
  };

  const handleCloseCliInviteModal = () => {
    setIsCliInviteModalOpen(false);
    setCliInvEmails([]);
    setCliInvEmail("");
  };

  const handleUpload = async (event) => {
    event.preventDefault();
    setIsBulkImportLoading(true);
    if (!bulkImportFile) {
      toast.error("Please select a file.", {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setIsBulkImportLoading(true);
      return;
    }

    const fileExtension = bulkImportFile.name.split(".").pop();
    if (fileExtension !== "xlsx") {
      toast.error("Only xlsx files are supported.", {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setIsBulkImportLoading(false);
      return;
    }

    const formData = new FormData();
    formData.append("file", bulkImportFile);

    try {
      const response = await axios.post("/api/bulk/import/client", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
      const { status, message, existingClientEmails, insertedClients, skippedRows } = response.data;

      if (status === "requiredValidationError") {
        toast.error(message, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        if (existingClientEmails && existingClientEmails.length > 0) {
          toast.error(
            `The following emails are already registered: ${existingClientEmails.join(", ")}`,
            {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }
          );
        }

        if (insertedClients && insertedClients.length > 0) {
          toast.success(`Inserted ${insertedClients.length} client records successfully!`, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }

        if (skippedRows && skippedRows.length > 0) {
          const skippedMessage = skippedRows.map((row) => {
            return `Row ${row.row}: ${row.reason}`;
          }).join(" | ");

          toast.warn(`Some rows were skipped: ${skippedMessage}`, {
            position: "bottom-right",
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
        handleBulkImportCloseModal();
      }
    } catch (error) {
      console.error(error);
      global.config.slackMessage(error.toString());
      toast.error("Error uploading file. Please try again.", {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } finally {
      setIsBulkImportLoading(false);
      setUpdateClientList((prevState) => !prevState);
    }
  };

  const handleBulkImportOpenModal = () => {
    setIsBulkImportModalOpen(true);
  };

  const handleBulkImportCloseModal = () => {
    setBulkImportFile(null);
    setBulkImportFileName("");
    setIsBulkImportModalOpen(false);
  };

  const handleBulkImportUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setBulkImportFile(e.target.files[0]);
      setBulkImportFileName(file.name);
    } else {
      setBulkImportFile(null);
      setBulkImportFileName("");
    }
  };

  const toggleSortDropdown = () => {
    setSortDropdownOpen(!sortDropdownOpen);
  };

  const selectSortOption = (selectedOption) => {
    setSelectedSortOption(selectedOption);
    setCheckedOption(selectedOption); // Set the checked option
    setSortDropdownOpen(false);
    // Don't close the dropdown here
    // Add sorting logic here based on the selectedOption
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setAccManagerIsValid(false);
    setContactPointIsValid(false);
    setIsValidUrl(false);
    setPasswordMatchError("");
    setPasswordError("");
    const pattern = /^(ftp|http|https):\/\/[^ "]+$/;
    if (accManager === "" || clientName === "" || password === "" || passwordConfirmation === "") {
      if (accManager === "") {
        setAccManagerIsValid(true);
        accManagerRef && accManagerRef.current.focus();
        return;
      } else if (password === "") {
        setPasswordError("Password cannot be empty");
        passwordRef.current.focus();
        return;
      } else if (passwordConfirmation === "") {
        setPasswordMatchError("Confirm Password cannot be null");
        passwordConfirmationRef.current.focus();
        return;
      }

      toast.error("Please fill all required fields!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    if (!pattern.test(companyWebsite)) {
      toast.error("It's not a valid URL.", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setIsValidUrl(true);
      setIsSubmitLoading(false);
      return;
    }
    if (password !== passwordConfirmation) {
      setPasswordMatchError("Passwords do not match");
      passwordConfirmationRef.current.focus();
      return;
    }
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    if (!passwordRegex.test(password)) {
      setPasswordError("Invalid password format");
      toast.error("Password must be at least 8 charachters long and include atleast one uppercase letter ,one lowercase letter,one digit and one special character from the set !@#$%^&*()_+,-/:;<=>?@_'[\]{|}~", {
        position: "bottom-right",
        autoClose: 3500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      passwordRef.current.focus();
      return;
    }
    const requestData = {
      client_name: clientName,
      email,
      mobile_no: mobileNo,
      address,
      country,
      acc_manager: accManager,
      // contact_point: contactPoint,
      company_website: companyWebsite,
      password: password,
      additional_info: additionalInfo,
    };
    // Create a new FormData object
    const formData = new FormData();
    formData.append("company_logo", companyLogo);

    // Append the other request data to the form data
    for (let key in requestData) {
      formData.append(key, requestData[key]);
    }
    try {
      setIsSubmitLoading(true);
      const response = await axios.post(
        "/api/client-details/create",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = response.data;
      if (data.status === "exists") {
        toast.error("Client already exists", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (data.status === "error") {
        toast.error("Something went wrong", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.success("Client registered successfully", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        handleCloseModal();
        global.config.activityLog(
          window.location.href,
          "Client",
          `Client (${clientName}) Registered Successfully`
        );
      }
    } catch (error) {
      console.log(error);
      global.config.slackMessage(error.toString());
      if (error.response?.data?.status === "deleted") {
        localStorage.removeItem("user");
        dispatch(logout());
        dispatch(reset());
        dispatch(invoiceReset());
        dispatch(headerReset());
        dispatch(appSettingsReset());
        toast.error("This user is deleted!", {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/");
        return;
      }
      alert("Something went wrong");
    } finally {
      setIsSubmitLoading(false);
      refetchClientProfiles();
      if (updateClientList === false) {
        setUpdateClientList(true);
      } else {
        setUpdateClientList(false);
      }
    }
  };

  const { data: clientProfiles = [], isLoading, refetch: refetchClientProfiles, } = useFetchClientDetails(token);
  const { data: clientExportProfiles = [], isLoading: clientExportIsLoading, refetch: refetchClientExportProfiles, } = useFetchClientExportDetails(token);

  const sortData = (data, selectedSortOption) => {
    switch (selectedSortOption) {
      case "By name: A - Z":
        return data
          .slice()
          .sort((a, b) => a.client_name.localeCompare(b.client_name));
      case "By name: Z - A":
        return data
          .slice()
          .sort((a, b) => b.client_name.localeCompare(a.client_name));
      case "Date: old to new":
        return data
          .slice()
          .sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
      case "Date: new to old":
        return data
          .slice()
          .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
      default:
        return data;
    }
  };

  const sortedData = sortData(clientExportProfiles, selectedSortOption);

  const filteredData = sortedData.filter((item) => {
    const searchTerm = filter.trim()?.toLowerCase();
    const hasMatch =
      item.client_name?.toLowerCase().includes(searchTerm) &&
      (selectedStatus === "Active" ? item.is_active : true) &&
      (selectedStatus === "Inactive" ? !item.is_active : true);
    return hasMatch;
  });

  const exportToExcel = () => {
    if (filteredData.length === 0) {
      toast.error("No data to export.", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    try {
      const now = new Date();
      const formattedDate =
        now.getFullYear() +
        "-" +
        (now.getMonth() + 1).toString().padStart(2, "0") +
        "-" +
        now.getDate().toString().padStart(2, "0");
      const formattedTime =
        now.getHours().toString().padStart(2, "0") +
        ":" +
        now.getMinutes().toString().padStart(2, "0") +
        ":" +
        now.getSeconds().toString().padStart(2, "0");

      const modifiedLog = filteredData.map((item, index) => ({
        SlNo: index + 1,
        "Commercial Name": item.client_name,
        "Legal Entity Name": item.legal_entity_name || "",
        Email: item.email || "",
        "Company Url": item.company_website || "",
        "Contact Number": item.mobile_no || "",
        Address: item.address || "",
        City: item.city || "",
        Country: item.country || "",
        Pincode: item.pincode || "",
        "Account Manager": item.acc_manager
          ? item.acc_manager?.first_name
            ? `${item.acc_manager.first_name} ${item.acc_manager.middle_name &&
              item.acc_manager.middle_name !== "null"
              ? item.acc_manager.middle_name + " "
              : ""
            }${item.acc_manager.last_name}`
            : item.acc_manager?.company_first_name
              ? `${item.acc_manager.company_first_name} ${item.acc_manager.company_last_name || ""
              }`
              : `${item.acc_manager?.company_name || ""} (Company)`
          : "",
        "Company VAT Number": item.vat_number || "",
        "Company VAT applicable Percentage": item.vat_percentage || "",
        "Billing Contact (Full Name)": item.billing_contact_name || "",
        "Billing Contact - Position": item.billing_contact_position || "",
        "Billing Contact - Email Address": item.billing_contact_email || "",
        "Preferred Currency": item.preferred_currency || "",
        "Preferred Payment Method": item.payment_method || "",
        "Signatory User Name": item.signatory_user_name || "",
        "Signatory User Email": item.signatory_email || "",
        "Signatory User Position": item.signatory_user_position || "",
        "Signatory Mobile-No": item.signatory_mobile_no || "",
        "Point of Contact User Name": item.poc_user_name || "",
        "Point of Contact Email": item.poc_email || "",
        "Point of Contact User Position": item.poc_user_position || "",
        "Additional Notes": item.additional_info || "",
        "Is Client Active": item.is_active ? "Yes" : "No",
        // Is_Invited: item.is_invited ? 'Yes' : 'No',
        "Created At": moment(item.created_at)
          .tz(global.config.getTimeZone())
          .format("DD/MM/YYYY, hh:mm:ss A"),
      }));

      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(modifiedLog);
      const colWidths = [
        { wpx: 25 }, // SlNo
        { wpx: 100 }, // Client_Name
        { wpx: 100 }, // Legal_Entity_Name
        { wpx: 175 }, // Email
        { wpx: 150 }, // Company_Website
        { wpx: 100 }, // Mobile_No
        { wpx: 200 }, // Address
        { wpx: 100 }, // City
        { wpx: 100 }, // Country
        { wpx: 100 }, // Pincode
        { wpx: 100 }, // Account_Manager
        { wpx: 100 }, // VAT_Number
        { wpx: 130 }, // VAT_Percentage
        { wpx: 150 }, // Billing_Contact_Name
        { wpx: 150 }, // Billing_Contact_Position
        { wpx: 175 }, // Billing_Contact_Email
        { wpx: 100 }, // Preferred_Currency
        { wpx: 100 }, // Payment_Method
        { wpx: 150 }, // Signatory_User_Name
        { wpx: 150 }, // Signatory_Email
        { wpx: 150 }, // Signatory_Position
        { wpx: 150 }, // Signatory_Mobile_No
        { wpx: 150 }, // POC_User_Name
        { wpx: 175 }, // POC_Email
        { wpx: 150 }, // POC_User_Position
        { wpx: 250 }, // Additional_Info
        { wpx: 100 }, // Is_Active
        // { wpx: 100 }, // Is_Invited
        { wpx: 200 }, // Created_At
      ];

      worksheet["!cols"] = colWidths;

      const range = XLSX.utils.decode_range(worksheet["!ref"]);
      worksheet["!autofilter"] = { ref: range };
      // worksheet['A1'].s = { alignment: { wrapText: true, horizontal: 'center', vertical: 'center' } };
      // worksheet['B1'].s = { alignment: { wrapText: true, horizontal: 'center', vertical: 'center' } };
      // worksheet['C1'].s = { alignment: { wrapText: true, horizontal: 'center', vertical: 'center' } };
      for (let rowNum = range.s.r; rowNum <= range.e.r; rowNum++) {
        for (let colNum = range.s.c; colNum <= range.e.c; colNum++) {
          const cellRef = XLSX.utils.encode_cell({ r: rowNum, c: colNum });
          if (!worksheet[cellRef]) continue;
          if (colNum === 4 || colNum === 5 || colNum === 24) {
            worksheet[cellRef].s = {
              alignment: { wrapText: true, vertical: "top" },
            };
          } else {
            worksheet[cellRef].s = {
              alignment: { horizontal: "center", vertical: "center" },
            };
          }
        }
      }
      XLSX.utils.book_append_sheet(workbook, worksheet, "Clients Log");
      XLSX.writeFile(
        workbook,
        `Clients_${formattedDate}_${formattedTime}.xlsx`
      );
    } catch (error) {
      console.error(error);
      global.config.slackMessage(error.toString());
      global.config.activityLog(
        window.location.href,
        "Clients Log",
        "Error occurred while exporting to Excel"
      );
      alert("Error occurred while exporting to Excel.");
    }
  };

  return (
    <div className="time-client-outer client-top-overview-outer changes-client">
      <div className="client-top-overview-name-btn">
        <div className="title-client-all">
          {global.config.locate(
            appSettings && appSettings.language,
            "Select a client"
          )}
        </div>
        <div className="outer-client-overview-sec">
          <div className="invite-client-outer invite-client-outer-client-overview">

            {(user && (user.userType === userTypeConsts.SUPER_ADMIN || user.userType === userTypeConsts.ADMIN)) && (
              <a className="btn-invite-client" onClick={handleOpenCliInviteModal}>
                <span className="icon-invite">
                  <img src={InviteIcon} alt="invite" />
                </span>
                Invite Client
              </a>
            )}

            <Modal
              isOpen={isCliInviteModalOpen}
              onRequestClose={
                !isCliInvMailLoading ? handleCloseCliInviteModal : undefined
              }
              contentLabel="Invite a User"
              className="user-invite-modal-main-outer"
              style={{
                content: {
                  height: "41%",
                  width: "30%",
                  position: "fixed",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-25%, -50%)",
                },
              }}
            >
              <div className="modal">


                <div className="modal-top-section-user-invite">
                  <div className="modal-invite-head">
                    <h2 className="">Invite a new Client</h2>
                  </div>
                  <div className="modal-close-invite outter-modal-main outter-modal-main-user">
                    <a
                      className=""
                      onClick={handleCloseCliInviteModal}
                      disabled={isCliInvMailLoading}
                    >
                      <img src={crssrImage} alt="Edit Icon" />{" "}
                    </a>
                  </div>
                </div>

                <form onSubmit={handleCliInviteSubmit}>
                  <div>
                    {cliInvEmails.map((obj) => (
                      <div key={obj.id} className="the-modal-inuser">
                        <div className="user-add-outer-innner">
                          <div className="invite-user">
                            <label htmlFor="grid-password" className="">
                              Email Address
                            </label>
                            <input
                              type="email"
                              id={`email-${obj.id}`}
                              className=""
                              name="email"
                              required
                              placeholder="Enter Email ID"
                              value={obj.cliInvEmail}
                              onChange={(e) =>
                                handleEditCliInvEmail(
                                  obj.id,
                                  "cliInvEmail",
                                  e.target.value
                                )
                              }
                            />
                          </div>
                          <div
                            className="invite-user-delete"
                            onClick={() => handleRemoveCliInvEmail(obj.id)}
                          >
                            <img src={dletImage} alt="delete-icon" />{" "}
                          </div>
                        </div>
                      </div>
                    ))}
                    <div className="the-modal-inuser">
                      <div className="user-add-outer-innner">
                        <div className="invite-user">
                          <label htmlFor="grid-password" className="">
                            Email Address
                          </label>
                          <input
                            type="email"
                            name="email"
                            className=""
                            placeholder="Enter Email ID"
                            value={cliInvEmail}
                            onChange={(e) => setCliInvEmail(e.target.value)}
                          />
                        </div>
                        {cliInvEmails.length > 0 && (
                          <div
                            className="invite-user-delete"
                            onClick={handleRemoveLatestCliInvEmail}
                          >
                            <img src={dletImage} alt="delete-icon" />
                          </div>
                        )}
                      </div>
                    </div>
                    <div
                      className="add-email-sec-modal"
                      onClick={handleAddCliInvEmail}
                      style={{ cursor: "pointer" }}
                    >
                      <img src={addicon} alt="Edit Icon" />
                      <div className="add-icon-main-text">Add new email</div>
                    </div>
                  </div>
                  <div className="invite-mail-popup-btn">
                    <button
                      type="submit"
                      className=""
                      disabled={isCliInvMailLoading}
                    >
                      {isCliInvMailLoading ? (
                        <div className="outter-load-table">
                          <ThreeDots
                            height="18"
                            width="20"
                            radius="9"
                            color="white"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                          />
                        </div>
                      ) : (
                        "Send Invite"
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </Modal>

            <Modal
              isOpen={isResendCliInviteModalOpen}
              onRequestClose={
                !isCliInvMailLoading
                  ? handleCloseResendCliInviteModal
                  : undefined
              }
              contentLabel="Resend Invitations"
              style={{
                content: {
                  height: "41%",
                  width: "30%",
                  position: "fixed",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-25%, -50%)",
                },
              }}
            >
              <div className="modal">
                <div className="modal-close-invite outter-modal-main">
                  <a
                    className=""
                    onClick={handleCloseResendCliInviteModal}
                    disabled={isCliInvMailLoading}
                  >
                    <img src={crssrImage} alt="Edit Icon" />{" "}
                  </a>
                </div>
                <div className="modal-invite-head">
                  <h2 className="">Resend Invitations</h2>
                </div>
                {/* List the already sent emails here */}
                <div>
                  <div className="the-modal-inuser">
                    <label htmlFor="grid-password" className="">
                      Email
                    </label>
                    {alreadySentCliInvEmailsList.map((email) => (
                      <div key={email} className="the-modal-inusers">
                        <div className="resend-invitation">
                          <input
                            type="email"
                            className=""
                            name="email"
                            required
                            placeholder="Enter Email ID"
                            value={email}
                            disabled
                          />
                          {alreadySentCliInvEmailsList.length > 1 && (
                            <button
                              className="del-resend"
                              onClick={() =>
                                handleRemoveResendCliInvEmail(email)
                              }
                            >
                              <img src={dletImage} alt="delete-icon" />{" "}
                            </button>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="sprint-btn-outer resend-invoice">
                  <button
                    type="button"
                    onClick={handleCloseResendCliInviteModal}
                    disabled={isCliInvMailLoading}
                    className="submit-client cancel-sprint"
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="submit-client save-sprint"
                    disabled={isCliInvMailLoading}
                    onClick={handleResendCliInvEmails}
                  >
                    {isCliInvMailLoading ? (
                      <div className="outter-load-table">
                        <ThreeDots
                          height="18"
                          width="20"
                          radius="9"
                          color="white"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClassName=""
                          visible={true}
                        />
                      </div>
                    ) : (
                      "Resend Invitations"
                    )}
                  </button>
                </div>
              </div>
            </Modal>

            <a
              className="btn-invite-client bulk-export"
              onClick={exportToExcel}
            >
              <span className="icon-invoice">
                <img src={DownloadImage} alt="Export" />
              </span>
              Export Data
            </a>

            <a
              className="btn-invite-client bulk-import"
              onClick={handleBulkImportOpenModal}
            >
              <span className="icon-invoice">
                <img src={DownloadImage} alt="Export" />
              </span>
              Bulk Import
            </a>

            <Modal
              isOpen={isBulkImportModalOpen}
              onRequestClose={
                !isBulkImportLoading ? handleBulkImportCloseModal : undefined
              }
              contentLabel="Invite a User"
              className="bulk-import-popup"
              style={{
                content: {
                  height: "50%",
                  width: "50%",
                  position: "fixed",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-17.5%, -46%)",
                },
              }}
            >
              <div className="modal">
                <div className="modal-top-section-user-invite">
                  <div className="modal-invite-head">
                    <h2 className="">Bulk Import</h2>
                  </div>
                  <div className="modal-close-invite outter-modal-main outter-modal-main-user">
                    <a
                      className=""
                      onClick={handleBulkImportCloseModal}
                      disabled={isBulkImportLoading}
                    >
                      <img src={crssrImage} alt="Close Bulk Import" />{" "}
                    </a>
                  </div>
                </div>

                <form>
                  <div className="relative w-full mb-3">
                    <div className="file-input-wrapper">
                      <input
                        type="text"
                        className="form-input form-input-file"
                        value={bulkImportFileName}
                        readOnly
                      />
                      <input
                        type="file"
                        id="expFileAttchEdit"
                        name="expFileAttchEdit"
                        className="form-input hidden-input"
                        onChange={handleBulkImportUpload}
                      // required
                      />
                      <label
                        htmlFor="expFileAttch"
                        className="file-input-label"
                      >
                        <img src={Plus} alt="Browse" />
                        Browse
                      </label>
                    </div>
                    <div className="text-sm bulk-text">
                      Demo File: {"    "}
                      <a
                        href="https://docs.google.com/spreadsheets/d/1u5GFHWbJkYVFn0k2X0myPw-nZUinKhcLzCsePbpuVD4/edit?usp=sharing"
                        className="form-textarea"
                        target="_blank"
                      >
                        Import_demo.xlsx
                      </a>
                    </div>
                  </div>

                  <div className="bulk-btn-section sprint-btn-outer">
                    <button
                      className="submit-client cancel-sprint"
                      type="button"
                      onClick={handleBulkImportCloseModal}
                      disabled={isBulkImportLoading}
                    >
                      Close
                    </button>
                    <button
                      className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded-full shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={handleUpload}
                      disabled={isBulkImportLoading}
                    >
                      {isBulkImportLoading ? (
                        <div className="outter-load-table">
                          <ThreeDots
                            height="18"
                            width="20"
                            radius="9"
                            color="white"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                          />
                        </div>
                      ) : (
                        "Send"
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </Modal>

            {(user && (user.userType === userTypeConsts.SUPER_ADMIN || user.userType === userTypeConsts.ADMIN)) && (
              <a className="new-client" onClick={handleOpenModal}>
                <span className="icon-invoice">
                  <img src={PlusIcon} alt="New Client" />
                </span>
                {global.config.locate(
                  appSettings && appSettings.language,
                  "New Client"
                )}
              </a>)}

            <Modal
              className="client-modal"
              isOpen={isModalOpen}
              onRequestClose={handleCloseModal}
              contentLabel="New Client Account"
              style={{
                content: {
                  height: "41%",
                  width: "30%",
                  position: "fixed",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  parent: document.querySelector(".admin-outer.time.tracker"),
                },
              }}
            >
              <div className="modal">
                <div className="modal-close-invite outter-modal-main">
                  <a className="" onClick={handleCloseModal}>
                    <img src={crssrImage} alt="Edit Icon" />{" "}
                  </a>
                </div>
                <div className="modal-client-head">
                  <h2 className="">
                    {global.config.locate(
                      appSettings && appSettings.language,
                      "New Client Account"
                    )}
                  </h2>
                </div>
                <div className="form-field-client-outer profile-details-edit">
                  <form onSubmit={handleSubmit}>
                    <div className="form-field-client">
                      <label htmlFor="clientName" className="form-label">
                        {global.config.locate(
                          appSettings && appSettings.language,
                          "Client Name"
                        )}
                      </label>
                      <input
                        type="text"
                        id="clientName"
                        name="clientName"
                        className="form-input"
                        value={clientName}
                        onChange={(e) => setClientName(e.target.value)}
                        required
                      />
                    </div>
                    <div className="form-field-client">
                      <label htmlFor="emailAddress" className="form-label">
                        {global.config.locate(
                          appSettings && appSettings.language,
                          "Email Address"
                        )}
                      </label>
                      <input
                        type="email"
                        id="emailAddress"
                        name="emailAddress"
                        className="form-input"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                    </div>
                    <div className="inner-detail-inner form-field-client">
                      <label htmlFor="mobileNo" className="form-label">
                        {global.config.locate(
                          appSettings && appSettings.language,
                          "Mobile_No"
                        )}
                      </label>
                      <PhoneInput
                        placeholder="Enter phone number"
                        value={mobileNo}
                        onChange={setMobileNo}
                      />
                    </div>
                    <div className="form-field-client">
                      <label htmlFor="companyWebsite" className="form-label">
                        {global.config.locate(
                          appSettings && appSettings.language,
                          "Company Website"
                        )}
                      </label>
                      <input
                        type="text"
                        id="companyWebsite"
                        name="companyWebsite"
                        className="form-input"
                        value={companyWebsite}
                        onChange={(e) => setCompanyWebsite(e.target.value)}
                      />
                      {isValidUrl && (
                        <span className="text-red-500 text-xs italic">
                          Please enter a valid URL!!
                        </span>
                      )}
                    </div>
                    <div className="form-field-client">
                      <label htmlFor="companyLogo" className="form-label">
                        {global.config.locate(
                          appSettings && appSettings.language,
                          "Company Logo"
                        )}
                      </label>
                      <div className="file-input-wrapper">
                        <input
                          type="text"
                          className="form-input form-input-file"
                          value={companyLogoName}
                          readOnly
                        />
                        <input
                          type="file"
                          id="companyLogo"
                          name="companyLogo"
                          className="form-input hidden-input"
                          accept="image/*"
                          onChange={handleLogoUpload}
                          required
                        />
                        <label
                          htmlFor="companyLogo"
                          className="file-input-label"
                        >
                          <img src={Plus} alt="Browse" />
                          Browse
                        </label>
                      </div>
                    </div>
                    <div className="form-field-client">
                      <label htmlFor="client" className="form-label">
                        {global.config.locate(
                          appSettings && appSettings.language,
                          "Account Manager"
                        )}
                      </label>

                      <div
                        ref={accManagerSelectDropdownRef}
                        className={`cursor ${accManagerDropdownOpen ? "open" : ""
                          }`}
                      >
                        <span
                          id="user-select"
                          onClick={handleToggleAccManagerDropdown}
                          className={`custom-dropdown-user-new custom-dropdown-user ${accManagerIsValid ? "input-validation-error" : ""
                            }`}
                          ref={accManagerRef}
                        >
                          {accManager ? (
                            userIds?.find((user) => user._id === accManager)
                              ?.first_name ? (
                              <>
                                {
                                  userIds?.find(
                                    (user) => user._id === accManager
                                  )?.first_name
                                }{" "}
                                {userIds?.find(
                                  (user) => user._id === accManager
                                )?.middle_name &&
                                  userIds?.find((user) => user._id === accManager)
                                    ?.middle_name !== "null"
                                  ? userIds?.find(
                                    (user) => user._id === accManager
                                  )?.middle_name + " "
                                  : ""}
                                {
                                  userIds?.find(
                                    (user) => user._id === accManager
                                  )?.last_name
                                }{" "}
                              </>
                            ) : (
                              <>
                                {userIds.find((user) => user._id === accManager)
                                  ?.company_first_name
                                  ? `${userIds.find(
                                    (user) => user._id === accManager
                                  )?.company_first_name
                                  } ${userIds.find(
                                    (user) => user._id === accManager
                                  )?.company_last_name
                                    ? userIds.find(
                                      (user) => user._id === accManager
                                    )?.company_last_name
                                    : ""
                                  }`
                                  : `${userIds.find(
                                    (user) => user._id === accManager
                                  )?.company_name || ""
                                  }(Company)`}
                              </>
                            )
                          ) : (
                            global.config.locate(
                              appSettings && appSettings.language,
                              "Select Account Manager"
                            )
                          )}
                        </span>
                        {accManagerDropdownOpen && (
                          <div className="custom-dropdown-new">
                            <input
                              type="text"
                              className="search-bar"
                              placeholder={global.config.locate(
                                appSettings && appSettings.language,
                                "Search"
                              )}
                              value={userSearchTerm}
                              onChange={handleAccManagerSearchInputChange}
                            />
                            <ul className="client-list">
                              {filteredUsers.length > 0 &&
                                filteredUsers
                                  .slice()
                                  .sort((a, b) => {
                                    const nameA = a.first_name
                                      ? `${a.first_name} ${a.middle_name || ""
                                      } ${a.last_name}`
                                      : `${a.company_first_name
                                        ? a.company_first_name +
                                        (a.company_last_name &&
                                          a.company_last_name)
                                        : a.company_name
                                      }`;
                                    const nameB = b.first_name
                                      ? `${b.first_name} ${b.middle_name || ""
                                      } ${b.last_name}`
                                      : `${b.company_first_name
                                        ? b.company_first_name +
                                        (b.company_last_name &&
                                          b.company_last_name)
                                        : b.company_name
                                      }`;
                                    return nameA.localeCompare(nameB);
                                  })
                                  .map((item) => (
                                    <li
                                      key={item._id}
                                      className={
                                        accManager === item._id
                                          ? "selected-item"
                                          : ""
                                      }
                                      onClick={() =>
                                        handleSelectAccManager(item._id)
                                      }
                                    >
                                      {item.first_name ? (
                                        <>
                                          {item.first_name}{" "}
                                          {item.middle_name &&
                                            item.middle_name !== "null"
                                            ? item.middle_name + " "
                                            : ""}
                                          {item.last_name}{" "}
                                        </>
                                      ) : (
                                        <>
                                          {item.company_first_name
                                            ? `${item.company_first_name} ${item.company_last_name
                                              ? item.company_last_name
                                              : ""
                                            }`
                                            : `${item.company_name || ""
                                            }(Company)`}
                                        </>
                                      )}
                                    </li>
                                  ))}
                            </ul>
                          </div>
                        )}
                      </div>

                      {accManagerIsValid && (
                        <span className="text-red-500 text-xs italic">
                          Account Manager needs to be selected
                        </span>
                      )}
                    </div>
                    {/* <div className="form-field-client">
                      <label htmlFor="client" className="form-label">
                        Contact Point
                      </label>
                      <div
                        ref={contactPointSelectDropdownRef}
                        className={`cursor ${contactPointDropdownOpen ? "open" : ""
                          }`}
                      >
                        <span
                          id="user-select"
                          onClick={handleToggleContactPointDropdown}
                          className={`custom-dropdown-user-new custom-dropdown-user ${contactPointIsValid ? "input-validation-error" : ""
                            }`}
                          ref={contactPointRef}
                        >
                          {contactPoint ? (
                            userIds?.find((user) => user._id === contactPoint)
                              ?.first_name ? (
                              <>
                                {
                                  userIds?.find(
                                    (user) => user._id === contactPoint
                                  )?.first_name
                                }{" "}
                                {userIds?.find(
                                  (user) => user._id === contactPoint
                                )?.middle_name &&
                                  userIds?.find(
                                    (user) => user._id === contactPoint
                                  )?.middle_name !== "null"
                                  ? userIds?.find(
                                    (user) => user._id === contactPoint
                                  )?.middle_name + " "
                                  : ""}
                                {
                                  userIds?.find(
                                    (user) => user._id === contactPoint
                                  )?.last_name
                                }{" "}
                              </>
                            ) : (
                              <>
                                {userIds.find(
                                  (user) => user._id === contactPoint
                                )?.company_first_name
                                  ? `${userIds.find(
                                    (user) => user._id === contactPoint
                                  )?.company_first_name
                                  } ${userIds.find(
                                    (user) => user._id === contactPoint
                                  )?.company_last_name
                                    ? userIds.find(
                                      (user) => user._id === contactPoint
                                    )?.company_last_name
                                    : ""
                                  }`
                                  : `${userIds.find(
                                    (user) => user._id === contactPoint
                                  )?.company_name || ""
                                  }(Company)`}
                              </>
                            )
                          ) : (
                            "Select Contact Point Person"
                          )}
                        </span>
                        {contactPointDropdownOpen && (
                          <div className="custom-dropdown-new">
                            <input
                              type="text"
                              className="search-bar"
                              placeholder="Search..."
                              value={userSearchTerm}
                              onChange={handleContactPointSearchInputChange}
                            />
                            <ul className="client-list">
                              {filteredUsers.length > 0 &&
                                filteredUsers
                                  .slice()
                                  .sort((a, b) => {
                                    const nameA = a.first_name
                                      ? `${a.first_name} ${a.middle_name || ""
                                      } ${a.last_name}`
                                      : `${a.company_first_name
                                        ? a.company_first_name +
                                        (a.company_last_name &&
                                          a.company_last_name)
                                        : a.company_name
                                      }`;
                                    const nameB = b.first_name
                                      ? `${b.first_name} ${b.middle_name || ""
                                      } ${b.last_name}`
                                      : `${b.company_first_name
                                        ? b.company_first_name +
                                        (b.company_last_name &&
                                          b.company_last_name)
                                        : b.company_name
                                      }`;
                                    return nameA.localeCompare(nameB);
                                  })
                                  .map((item) => (
                                    <li
                                      key={item._id}
                                      className={
                                        contactPoint === item._id
                                          ? "selected-item"
                                          : ""
                                      }
                                      onClick={() =>
                                        handleSelectContactPoint(item._id)
                                      }
                                    >
                                      {item.first_name ? (
                                        <>
                                          {item.first_name}{" "}
                                          {item.middle_name &&
                                            item.middle_name !== "null"
                                            ? item.middle_name + " "
                                            : ""}
                                          {item.last_name}{" "}
                                        </>
                                      ) : (
                                        <>
                                          {item.company_first_name
                                            ? `${item.company_first_name} ${item.company_last_name
                                              ? item.company_last_name
                                              : ""
                                            }`
                                            : `${item.company_name || ""
                                            }(Company)`}
                                        </>
                                      )}
                                    </li>
                                  ))}
                            </ul>
                          </div>
                        )}
                      </div>

                      {contactPointIsValid && (
                        <span className="text-red-500 text-xs italic">
                          Contact Point needs to be selected
                        </span>
                      )}
                    </div> */}
                    <div className="form-field-client">
                      <label htmlFor="address" className="form-label">
                        {global.config.locate(
                          appSettings && appSettings.language,
                          "Address"
                        )}
                      </label>
                      <input
                        type="text"
                        id="address"
                        name="address"
                        className="form-input"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                      />
                    </div>
                    <div className="form-field-client">
                      <label htmlFor="country" className="form-label">
                        {global.config.locate(
                          appSettings && appSettings.language,
                          "Country"
                        )}
                      </label>
                      <div
                        ref={countryDropdownRef}
                        className="select-modal-mainM custom-dropdown-user-new-outer"
                      >
                        <span
                          id="country"
                          onClick={handleToggleCountryDropdown}
                          className="custom-dropdown-user-new"
                        >
                          {country
                            ? country
                            : appSettings && appSettings.language
                              ? global.config.locate(
                                appSettings.language,
                                "Select Country"
                              )
                              : null}
                        </span>
                        {countryDropdownOpen && (
                          <div className="custom-dropdown-new">
                            <input
                              type="text"
                              className="search-bar"
                              placeholder={global.config.locate(
                                appSettings && appSettings.language,
                                "Search"
                              )}
                              value={countrySearchTerm}
                              onChange={handleCountrySearchInputChange}
                            />
                            <ul className="country-list">
                              {filteredCountries.length > 0 &&
                                filteredCountries.map((c) => (
                                  <li
                                    key={c.country}
                                    className={
                                      country === c.country
                                        ? "selected-item"
                                        : ""
                                    }
                                    onClick={() => {
                                      setCountry(c.country);
                                      setCountryDropdownOpen(false);
                                    }}
                                  >
                                    {c.country}
                                  </li>
                                ))}
                            </ul>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="form-field-client">
                      <label htmlFor="password" className="form-label">
                        New Password
                      </label>
                      <input
                        type="password"
                        name="password"
                        id="password"
                        className={
                          passwordError
                            ? "input-validation-error form-input"
                            : "form-input"
                        }
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        ref={passwordRef}
                      />
                      {passwordError && (
                        <span className="text-red-500 text-xs italic">
                          {passwordError}
                        </span>
                      )}
                    </div>
                    <div className="form-field-client">
                      <label htmlFor="confPassword" className="form-label">
                        Confirm new Password
                      </label>
                      <input
                        type="password"
                        className={
                          passwordMatchError
                            ? "input-validation-error form-input"
                            : "form-input"
                        }
                        value={passwordConfirmation}
                        onChange={(e) =>
                          setPasswordConfirmation(e.target.value)
                        }
                        ref={passwordConfirmationRef}
                      />
                      {passwordMatchError && (
                        <span className="text-red-500 text-xs italic">
                          {passwordMatchError}
                        </span>
                      )}
                    </div>
                    <div className="form-field-client">
                      <label
                        htmlFor="additionalInformation"
                        className="form-label"
                      >
                        {global.config.locate(
                          appSettings && appSettings.language,
                          "Additional Information"
                        )}
                      </label>
                      <textarea
                        id="additionalInformation"
                        name="additionalInformation"
                        className="form-textarea"
                        rows="4"
                        value={additionalInfo}
                        onChange={(e) => setAdditionalInfo(e.target.value)}
                      ></textarea>
                    </div>
                    <button
                      type="submit"
                      className="submit-client"
                      disabled={isSubmitLoading}
                    >
                      {isSubmitLoading ? (
                        <div className="outter-load-table">
                          <ThreeDots
                            height="18"
                            width="20"
                            radius="9"
                            color="white"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                          />
                        </div>
                      ) : (
                        global.config.locate(
                          appSettings && appSettings.language,
                          "Create Client"
                        )
                      )}
                    </button>
                  </form>
                </div>
              </div>
            </Modal>
          </div>
        </div>
      </div>

      <div className="top-filter-client">
        <div className="top-filter-client-inner">
          <div className="top-filter-client-inner-left">
            <div className="top-filter-client-status">
              <label className="top-filter-client-status-label">Status</label>
              <div className="custom-dropdown">
                <div
                  ref={statusDropdownRef}
                  className={`custom-dropdown-user-new-outer custom-dropdown-select ${statusDropdownOpen ? "open" : ""
                    }`}
                >
                  <div
                    className="selected-option custom-dropdown-user-new"
                    onClick={toggleStatusDropdown}
                  >
                    {selectedStatus
                      ? global.config.locate(
                        appSettings && appSettings.language,
                        selectedStatus
                      )
                      : appSettings && appSettings.language
                        ? global.config.locate(appSettings.language, "ALL")
                        : null}
                  </div>
                  {statusDropdownOpen && (
                    <div className="custom-dropdown-new">
                      <ul className="type-list">
                        <li onClick={() => selectStatusOption(null)}>All</li>
                        <li onClick={() => selectStatusOption("Active")}>
                          {global.config.locate(
                            appSettings && appSettings.language,
                            "Active"
                          )}
                        </li>
                        <li onClick={() => selectStatusOption("Inactive")}>
                          {global.config.locate(
                            appSettings && appSettings.language,
                            "Inactive"
                          )}
                        </li>
                        {/* Add more Status options as needed */}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="title-client-all-mobile">Select a Client</div>
            <div className="top-filter-client-search">
              <div className="search-sec">
                <div className="search-container">
                  <input
                    type="text"
                    className="search-input"
                    placeholder={global.config.locate(
                      appSettings && appSettings.language,
                      "Search"
                    )}
                    onChange={(e) => setFilter(e.target.value)}
                    value={filter}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="top-filter-client-sort">
            <div className="custom-dropdown">
              <div
                ref={sortDropdownRef}
                className={`custom-dropdown-user-new-outer custom-dropdown-select ${sortDropdownOpen ? "open" : ""
                  }`}
              >
                <div
                  className="selected-option custom-dropdown-user-new"
                  onClick={toggleSortDropdown}
                >
                  <div className="center-sort">
                    {selectedSortOption
                      ? global.config.locate(
                        appSettings && appSettings.language,
                        selectedSortOption
                      )
                      : appSettings && appSettings.language
                        ? global.config.locate(appSettings.language, "Sort By")
                        : null}
                  </div>
                </div>
                {sortDropdownOpen && (
                  <div className="custom-dropdown-new">
                    <ul className="type-list">
                      <li
                        className={
                          checkedOption === "By name: A - Z" ? "selected" : ""
                        }
                        onClick={() => selectSortOption("By name: A - Z")}
                      >
                        <span
                          className={`checkbox ${checkedOption === "By name: A - Z" ? "checked" : ""
                            }`}
                        ></span>
                        {global.config.locate(
                          appSettings && appSettings.language,
                          "By name: A - Z"
                        )}
                      </li>
                      <li
                        className={
                          checkedOption === "By name: Z - A" ? "selected" : ""
                        }
                        onClick={() => selectSortOption("By name: Z - A")}
                      >
                        <span
                          className={`checkbox ${checkedOption === "By name: Z - A" ? "checked" : ""
                            }`}
                        ></span>
                        {global.config.locate(
                          appSettings && appSettings.language,
                          "By name: Z - A"
                        )}
                      </li>
                      <li
                        className={
                          checkedOption === "Date: old to new" ? "selected" : ""
                        }
                        onClick={() => selectSortOption("Date: old to new")}
                      >
                        <span
                          className={`checkbox ${checkedOption === "Date: old to new"
                            ? "checked"
                            : ""
                            }`}
                        ></span>
                        {global.config.locate(
                          appSettings && appSettings.language,
                          "Date: old to new"
                        )}
                      </li>
                      <li
                        className={
                          checkedOption === "Date: new to old" ? "selected" : ""
                        }
                        onClick={() => selectSortOption("Date: new to old")}
                      >
                        <span
                          className={`checkbox ${checkedOption === "Date: new to old"
                            ? "checked"
                            : ""
                            }`}
                        ></span>
                        {global.config.locate(
                          appSettings && appSettings.language,
                          "Date: new to old"
                        )}
                      </li>
                      {/* Add more sorting options as needed */}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="client-ls-sec">
        <ClientsOverviewListing
          clientProfiles={clientProfiles}
          filter={filter}
          isLoading={isLoading}
          refetchClientProfiles={refetchClientProfiles}
          updateClientList={updateClientList}
          selectedSortOption={selectedSortOption}
          selectedStatus={selectedStatus}
        />
      </div>
    </div>
  );
};

export default ClientOverviewSection;
