import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ThreeDots } from "react-loader-spinner";
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, LabelList } from 'recharts';
import { processedTimeOffReqPerUserFilter } from '../../Services/reportTimeOffRequest';
import { reportingFilterComparisonConst } from '../../../../../../constants/reportingFilterComparisonConst';
import { reportingFilterDateRangesConst } from '../../../../../../constants/reportingFilterDateRangesConst';

const TimeOffReqPerUserFiterGraph = ({
  timeoffReq,
  timeoffReqLoading,
  timeoffReqError,
  selectedDateRange,
  selectedComparison,
  selectedFrequency,
  selectedYearRange,
  selectedMonthRange,
  selectedComparisonYear,
  selectedComparisonMonth,
}) => {
  const reportingTimeoffRequest = useSelector((state) => state.reportingTimeoffRequest);
  const settings = JSON.parse(localStorage.getItem("settings"));

  const currentYear = reportingTimeoffRequest.timeoffReqYear || new Date().getFullYear();
  const isCurrentYear = currentYear === new Date().getFullYear();

  const processedData = useMemo(() => {
    return processedTimeOffReqPerUserFilter(
      timeoffReq,
      reportingTimeoffRequest,
      selectedDateRange,
      selectedComparison,
      selectedFrequency,
      selectedYearRange,
      selectedMonthRange,
      selectedComparisonYear,
      selectedComparisonMonth,
    );
  }, [timeoffReq, reportingTimeoffRequest, selectedDateRange, selectedComparison, selectedYearRange, selectedMonthRange, selectedComparisonYear, selectedComparisonMonth,]);

  if (timeoffReqLoading) {
    return (
      <div className="outter-load-table">
        <ThreeDots
          height="80"
          width="80"
          radius="9"
          color="#6479f9"
          ariaLabel="three-dots-loading"
          visible={true}
        />
      </div>
    );
  }

  if (timeoffReqError) {
    return <div>Error loading data.</div>;
  }

  const calculateXTicks = () => {
    if (!processedData.length) return [];
    const maxDataValue = Math.max(...processedData.map(item => Math.max(item.currentYear, item.lastYear)));
    if (maxDataValue === 0) return [0];
    const tickInterval = Math.ceil(maxDataValue / 10);
    const numOfTicks = Math.ceil(maxDataValue / tickInterval);
    const ticks = [];

    for (let i = 0; i <= numOfTicks + 2; i++) {
      ticks.push(i * tickInterval);
    }

    return ticks;
  };

  const xAxisTicks = calculateXTicks();

  const getBarName = (isCurrent, selectedRange, selectedComparison, selectedYearRange, selectedMonthRange, selectedComparisonYear, selectedComparisonMonth) => {
    const rangeNames = {
      [reportingFilterDateRangesConst.This_Year]: 'This Year',
      [reportingFilterDateRangesConst.This_Quarter]: 'This Quarter',
      [reportingFilterDateRangesConst.First_Half]: 'First Half',
      [reportingFilterDateRangesConst.This_Month]: 'This Month',
      [reportingFilterDateRangesConst.Year_Selected]: selectedYearRange ? `Year ${selectedYearRange.getFullYear()}` : `Selected Year`,
      [reportingFilterDateRangesConst.Month_Selected]: selectedMonthRange ? `Month ${selectedMonthRange.toLocaleString('default', { month: 'long' })} ${selectedMonthRange.getFullYear()}` : `Selected Month`,
    };

    const comparisonNames = {
      [reportingFilterComparisonConst.Last_Year]: 'Last Year',
      [reportingFilterComparisonConst.Last_Quarter]: 'Last Quarter',
      [reportingFilterComparisonConst.Second_Half]: 'Second Half',
      [reportingFilterComparisonConst.Last_Month]: 'Last Month',
      [reportingFilterComparisonConst.Year_Select]: selectedComparisonYear ? `Year ${selectedComparisonYear.getFullYear()}` : `Previous Year`,
      [reportingFilterComparisonConst.Month_Select]: selectedComparisonMonth ? `Month ${selectedComparisonMonth.toLocaleString('default', { month: 'long' })} ${selectedComparisonMonth.getFullYear()}` : `Previous Month`,
    };

    const rangeName = rangeNames[selectedRange] || 'Current Range';
    const comparisonName = comparisonNames[selectedComparison] || 'Comparison Range';

    return isCurrent
      ? `${rangeName}`
      : `${comparisonName}`;
  };

  const calculateChartHeight = (dataLength) => {
    const rowHeight = 50;
    const baseHeight = 100;
    return dataLength * rowHeight + baseHeight;
  };

  const calculateLeftMargin = (data) => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    context.font = '12px Arial';

    const longestWordLength = data.reduce((maxLength, item) => {
      const firstWord = item.name.split(' ')[0];
      const wordWidth = context.measureText(firstWord).width;
      return Math.max(maxLength, wordWidth);
    }, 0);

    return Math.max(0, longestWordLength - 150);
  };

  const leftMargin = calculateLeftMargin(processedData);

  return (
    <div className='graph-project-per-hour' style={{ width: '100%', height: Math.max(542, calculateChartHeight(processedData.length)), }}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          layout="vertical"
          data={processedData}
          barSize={13} // Set the height of each bar
          margin={{
            top: 20,
            right: 42,
            left: leftMargin,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" horizontal={false} vertical={true} />
          <XAxis
            type="number"
            domain={[Math.min(...xAxisTicks), Math.max(...xAxisTicks)]}
            ticks={xAxisTicks}
            tickFormatter={(tick) => `${tick}`}
          />
          <YAxis
            type="category"
            dataKey="name"
            tickMargin={10}
          />
          <Tooltip formatter={(value) => `${value} ${value <= 1 ? 'request' : 'requests'}`} />
          <Legend />
          <Bar dataKey="currentYear" name={getBarName(true, selectedDateRange, selectedComparison, selectedYearRange, selectedMonthRange, selectedComparisonYear, selectedComparisonMonth)} fill="#87ABE2">
            <LabelList dataKey="currentYear" position="right" formatter={(value) => `${value} ${value <= 1 ? 'request' : 'requests'}`} />
          </Bar>
          <Bar dataKey="lastYear" name={getBarName(false, selectedDateRange, selectedComparison, selectedYearRange, selectedMonthRange, selectedComparisonYear, selectedComparisonMonth)} fill="#C7B6F6">
            <LabelList dataKey="lastYear" position="right" formatter={(value) => `${value} ${value <= 1 ? 'request' : 'requests'}`} />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}

export default TimeOffReqPerUserFiterGraph;