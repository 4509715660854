import moment from "moment";
import vendorService from "./vendorService";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
//get user from localStaorage
const initialState = {
  dashboardDateRange: [
    new Date(
      moment().utc().subtract(30, "years").startOf("year").format()
    ).toISOString(),
    new Date(moment().utc().endOf("year").format()).toISOString(),
  ],
};

export const setVendorDashboardDate = createAsyncThunk(
  "vendor/setVendorDashboardDate",
  async (date) => {
    return await vendorService.setDateRange(date);
  }
);

export const vendorSlice = createSlice({
  name: "vendor",
  initialState,
  reducers: {
    reset: (state) => {
      state.dashboardDateRange = [
        new Date(
          moment().utc().subtract(30, "years").startOf("year").format()
        ).toISOString(),
        new Date(moment().utc().endOf("year").format()).toISOString(),
      ];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setVendorDashboardDate.fulfilled, (state, action) => {
        state.dashboardDateRange = action.payload;
      });
  },
});

export const { reset } = vendorSlice.actions;
export default vendorSlice.reducer;
