const CLIENT_INVOICE_STATUS =
{
    INVOICE_PENDING: 0,
    INVOICE_SEND: 1,
    INVOICE_REJECT: 2,
    INVOICE_CANCEL: 3,
    INVOICE_PAYMENT_PROCESSED: 4,
};

module.exports = {
    CLIENT_INVOICE_STATUS
}
