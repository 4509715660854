import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {

        personalFirst: {},
        personalTwo: {},
        personalSecurity: {},
        businessFirst: {},
        businessTwo: {},
        businessThree: {},
        businessSecurity: {},
        vendorFirst: {},
        vendorTwo: {},
        userType: null,
        page: 0,
        pageB: 0,
        page1: 0,
        page2: 0,
        page3: 0,
        page4: 0,

};

export const setOnboardUserType = createAsyncThunk("onBoard/setOnboardUserType",
        async (userType) => {
                return userType;
        }
);


// personal

export const setOnboardingPersonalFirst = createAsyncThunk("onBoard/setOnboardingPersonalFirst",
        async (formData) => {
                return formData;
        }
);

export const setOnboardingPersonalSecond = createAsyncThunk("onBoard/setOnboardingPersonalSecond",
        async (formData) => {
                return formData;
        }
);

export const setOnboardingPersonalSecurity = createAsyncThunk("onBoard/setOnboardingPersonalSecurity",
        async (formData) => {
                return formData;
        }
);

// end personal


// business

export const setOnboardingBusinessFirst = createAsyncThunk("onBoard/setOnboardingBusinessFirst",
        async (formData) => {
                return formData;
        }
);

export const setOnboardingBusinessSecond = createAsyncThunk("onBoard/setOnboardingBusinessSecond",
        async (formData) => {
                return formData;
        }
);

export const setOnboardingBusinessThird = createAsyncThunk("onBoard/setOnboardingBusinessThird",
        async (formData) => {
                return formData;
        }
);

export const setOnboardingBusinessSecurity = createAsyncThunk("onBoard/setOnboardingBusinessSecurity",
        async (formData) => {
                return formData;
        }
);

// end business

// vendor

export const setOnboardingVendorFirst = createAsyncThunk("onBoard/setOnboardingVendorFirst",
        async (formData) => {

                return formData;
        }
);

export const setOnboardingVendorSecond = createAsyncThunk("onBoard/setOnboardingVendorSecond",
        async (formData) => {

                return formData;
        }
);


export const setOnboardingvendorSecurity = createAsyncThunk("onBoard/setOnboardingvendorSecurity",
        async (formData) => {

                return formData;
        }
);

// end vendor




export const setPage = createAsyncThunk("onBoard/setPage",
        async (page) => {

                return page;
        }
);

export const setBPage = createAsyncThunk("onBoard/setBPage",
        async (page) => {

                return page;
        }
);

export const setVPage = createAsyncThunk("onBoard/setVPage",
        async (page) => {

                return page;
        }
);




export const onBoardSlice = createSlice({
        name: "onBoard",
        initialState,
        reducers: {
                reset: (state) => {
                        state.onBoardState = null;
                        state.userType = null;
                        state.personalFirst = {};
                        state.personalTwo = {};
                        state.personalSecurity = {};
                        state.userType = null;
                        state.page = 0;
                        state.pageB = 0;
                        state.pageV = 0;
                        state.page1 = 0;
                        state.page2 = 0;
                        state.page3 = 0;
                        state.page4 = 0;
                },
        },
        extraReducers: (builder) => {


                builder.addCase(setOnboardUserType.fulfilled, (state, action) => {
                        state.userType = action.payload;

                });

                // personal
                builder.addCase(setOnboardingPersonalFirst.fulfilled, (state, action) => {
                        state.personalFirst = action.payload;
                        state.page1 = 1;
                });

                builder.addCase(setOnboardingPersonalSecond.fulfilled, (state, action) => {
                        state.personalTwo = action.payload;
                        state.page2 = 1;
                });

                builder.addCase(setOnboardingPersonalSecurity.fulfilled, (state, action) => {
                        state.personalSecurity = action.payload;
                        state.page3 = 1;
                });

                // end personal

                // business
                builder.addCase(setOnboardingBusinessFirst.fulfilled, (state, action) => {
                        state.businessFirst = action.payload;
                        state.page1 = 1;
                });

                builder.addCase(setOnboardingBusinessSecond.fulfilled, (state, action) => {
                        state.businessTwo = action.payload;
                        state.page2 = 1;
                });

                builder.addCase(setOnboardingBusinessThird.fulfilled, (state, action) => {
                        state.businessThree = action.payload;
                        state.page3 = 1;
                });

                builder.addCase(setBPage.fulfilled, (state, action) => {
                        state.pageB = action.payload;
                });

                // end business

                // vendor
                builder.addCase(setOnboardingVendorFirst.fulfilled, (state, action) => {
                        state.vendorFirst = action.payload;
                        state.page1 = 1;
                });

                builder.addCase(setOnboardingVendorSecond.fulfilled, (state, action) => {
                        state.vendorTwo = action.payload;
                        state.page2 = 1;
                });

                builder.addCase(setVPage.fulfilled, (state, action) => {
                        state.pageV = action.payload;
                });
                // end vendor

                builder.addCase(setOnboardingBusinessSecurity.fulfilled, (state, action) => {
                        state.personalSecurity = action.payload;
                        state.page4 = 1;
                });

                builder.addCase(setPage.fulfilled, (state, action) => {
                        state.page = action.payload;
                });
        },
});

export const { reset } = onBoardSlice.actions;
export default onBoardSlice.reducer;
