import React, { useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AES, enc } from "crypto-js";
import { otpVerification } from "../../../../src/services/emailRegister/emailRegisterServices.js";
import "../../../../src/pages/home/home.css";
import Logo from "../../../images/logo/to-logo.svg";
import { toast } from "react-toastify";
import { ThreeDots } from "react-loader-spinner";
const OtpVerification = () => {
        const { email } = useParams();
        const [otpDig1, setOtpDig1] = useState("");
        const [otpDig2, setOtpDig2] = useState("");
        const [otpDig3, setOtpDig3] = useState("");
        const [otpDig4, setOtpDig4] = useState("");
        const navigate = useNavigate();

        const otpRef1 = useRef(null);
        const otpRef2 = useRef(null);
        const otpRef3 = useRef(null);
        const otpRef4 = useRef(null);

        const decodedEmail = decodeURIComponent(email.replace(/%2F/g, "/"));
        const decryptedBytes = AES.decrypt(decodedEmail, "encryptionKey");
        const deEmail = decryptedBytes.toString(enc.Utf8);
        const [loading, setLoading] = useState(false);

        const handleOtpChange = (setOtp, nextRef, prevRef, e) => {
                const value = e.target.value;
                if (value.length === 1) {
                        setOtp(value);
                        if (nextRef) {
                                nextRef.current.focus();
                        }
                } else if (
                        value === "" &&
                        prevRef &&
                        e.nativeEvent.inputType === "deleteContentBackward"
                ) {
                        prevRef.current.focus();
                        setOtp("");
                }
        };

        const validateOtp = async () => {
                setLoading(true);
                if (otpDig1 && otpDig2 && otpDig3 && otpDig4) {

                        const otp = parseInt(otpDig1 + otpDig2 + otpDig3 + otpDig4);
                        const result = await otpVerification(deEmail, otp);
                        if (result === true) {
                                navigate(
                                        `/app/register/${encodeURIComponent(
                                                AES.encrypt(deEmail, "encryptionKey")
                                                        .toString()
                                                        .replace(/\//g, "%2F")
                                        )}/${otp}`
                                );
                        } else {
                              
                                setLoading(false);
                                toast.error("OTP is incorrect", {
                                        position: "bottom-right",
                                        autoClose: 1500,
                                      });
                        }
                }
                setLoading(false);
        };

        const handleBackspace = (e, currentRef, nextRef, setOtp) => {
                if (e.key === "Backspace") {
                  setOtp("");
                  if (currentRef && nextRef) currentRef.current.focus();
                }
              };

        return (
                <>
                        <div className="otp-outer-bg">
                                <div className="top-logo">
                                <a href="/"><img src={Logo} alt="Talent On" /></a>
                                </div>
                                <div className="top-logo-mobile">
                                <a href="/"><img src={Logo} alt="Talent On" /></a>
                                </div>
                                <div className="otp-section-outer">

                                        <div className="outer-otp">
                                                <div className="enter-otp-head">Enter OTP</div>
                                                <div className="enter-otp-subhead">
                                                        Enter the 4-digit code you received
                                                </div>
                                                <div className="otp-section-inner">
                                                        <input
                                                                type="number"
                                                                value={otpDig1}
                                                                ref={otpRef1}
                                                                onChange={(e) => handleOtpChange(setOtpDig1, otpRef2, null, e)}
                                                                onKeyDown={(e) => handleBackspace(e, otpRef1, otpRef2, setOtpDig1)}
                                                                maxLength={1}
                                                        />
                                                        <input
                                                                type="number"
                                                                value={otpDig2}
                                                                ref={otpRef2}
                                                                onChange={(e) => handleOtpChange(setOtpDig2, otpRef3, otpRef1, e)}
                                                                maxLength={1}
                                                        />
                                                        <input
                                                                type="number"
                                                                value={otpDig3}
                                                                ref={otpRef3}
                                                                onChange={(e) => handleOtpChange(setOtpDig3, otpRef4, otpRef2, e)}
                                                                maxLength={1}
                                                        />
                                                        <input
                                                                type="number"
                                                                value={otpDig4}
                                                                ref={otpRef4}
                                                                onChange={(e) => handleOtpChange(setOtpDig4, null, otpRef3, e)}
                                                                maxLength={1}
                                                        />
                                                </div>
                                                <div className="opt-verify-button">
                                                        <button onClick={validateOtp}>
                                                        {loading ?
                                    <div className="outter-load-table">
                                        <ThreeDots
                                            height="20"
                                            width="40"
                                            radius="4"
                                            color="white"
                                            ariaLabel="three-dots-loading"
                                            wrapperStyle={{}}
                                            wrapperClassName=""
                                            visible={true}
                                        />
                                    </div> : "Verify"
                                }
                                                                </button>
                                                </div>
                                        </div>
                                </div>
                        </div>
                </>
        );
};

export default OtpVerification;