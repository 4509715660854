import axios from 'axios';
import { useQuery, useMutation } from 'react-query';

export const useFetchEquipments = (token) => {
    return useQuery('equipments', async () => {
        const response = await axios.get('/api/equipments', {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    });
};

export function useClientView(clientId, token) {
    return useQuery(['clientView', clientId], async () => {
        const response = await axios.get(`/api/client-details/${clientId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    });
}

export function useEquipId(token) {
    return useQuery('equipId', async () => {
        const response = await axios.get('/api/equipment/id', {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    });
}

export function useEquipCategory(token) {
    return useQuery('equipCategory', async () => {
        const response = await axios.get('/api/equip-categories', {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    });
}

// export function useDeleteClientDetails(token) {
//     const mutation = useMutation((selectedId) => {
//         return axios.get(`/api/client-details/delete/${selectedId}`, {
//             headers: {
//                 Authorization: `Bearer ${token}`,
//             },
//         });
//     });

//     return mutation;
// }

// export function useUpdateClientDetails(token) {
//     const mutation = useMutation((updatedClientDetails) => {
//         return axios.put(`/api/client-details/update/${updatedClientDetails.selectedId}`, updatedClientDetails.data, {
//             headers: {
//                 Authorization: `Bearer ${token}`,
//             },
//         });
//     });

//     return mutation;
// }

// export function useUpdateAccManager(token) {
//     const mutation = useMutation((updatedAccManager) => {
//         console.log(updatedAccManager);
//         return axios.put(`/api/clients/update-acc-manager/${updatedAccManager.id}`,{acc_manager: updatedAccManager.acc_manager}, {
//             headers: {
//                 Authorization: `Bearer ${token}`,
//             },
//         });
//     });

//     return mutation;
// }

// export function useUpdateProjManager(token) {
//     const mutation = useMutation((updatedProjManager) => {
//         return axios.put(`/api/client/update-project-managers/${updatedProjManager.id}`,{projectManagers: updatedProjManager.projectManagers, previousProjectManager: updatedProjManager.previousProjectManager}, {
//             headers: {
//                 Authorization: `Bearer ${token}`,
//             },
//         });
//     });

//     return mutation;
// }

// export function useRemoveTeamMember(token) {
//     const mutation = useMutation((removedProjManager) => {
//         return axios.put(`/api/client/remove-project-manager/${removedProjManager.id}`,{projectManagerId: removedProjManager.projectManager }, {
//             headers: {
//                 Authorization: `Bearer ${token}`,
//             },
//         });
//     });

//     return mutation;
// }