const API_URL = "/api/user/";

const clientInvoiceData = async (autoData) => {
  var user = autoData;
  localStorage.setItem("clientInvoiceData", JSON.stringify(autoData));
  return user;
};

const clientInvoiceService = {
    clientInvoiceData: clientInvoiceData,
};

export default clientInvoiceService;
