import axios from "axios";
import "./MasterForgetPassword.css";
import { toast } from "react-toastify";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { validate } from "react-email-validator";
import Logo from "../../images/logo/to-logo.svg";
import { ThreeDots } from "react-loader-spinner";
import LoginImage from "../../images/login/lg-img.png";
import LoginImageMobile from "../../images/login/lg-img-mob.png";

const MasterForgetPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [isLoadingModal, setIsLoadingModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setIsLoadingModal(true);
    const requestData = {
      email: email,
    };

    if (validate(email)) {

      try {
        const res = await axios.post("/api/master/user/forgot-password", requestData);
        const data = res.data;
        if (data.status === "success") {
          toast.success("Password reset mail is send to your Email-ID", {
            position: "bottom-right",
            autoClose: 1500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });

          navigate("/master-user/login");
        } else if (data.status === "not_registered") {
          toast.error("Email id not found in our records.Please make sure it is registered or enter a valid email address.", {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          toast.error("Something went wrong while sending password reset mail ", {
            position: "bottom-right",
            autoClose: 1500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
        setIsLoadingModal(false);
        setLoading(false);
      } catch (err) {
        console.error(err.message);
        global.config.slackMessage(err.toString());
        toast.error("Error occurred");
        setLoading(false);
      }
    } else {
      toast.error("Invalid email address. Please enter a valid email ID", {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    setLoading(false);
  };

  return (
    <div className="login-outer-forget">
      <div className="login-inner">
        <div className="top-logo-mobile">
          <img src={Logo} alt="Talent On" />
        </div>
        <div className="lg-lft">
          <img src={LoginImage} alt="desktop" className="desktop-lg-img" />
          <img
            src={LoginImageMobile}
            alt="mobile"
            className="mobile-lg-img"
          />
        </div>
        <div className="lg-rgt">
          <div className="top-logo">
            <img src={Logo} alt="Talent On" />
          </div>
          <div className="login-form-sec">
            <form onSubmit={onSubmit}>
              <h3>Let’s reset your password!</h3>
              <p>
                Please enter your email address.
                <br />
                You will receive a link to create a new password.
              </p>
              <div className="form-outer">
                <label>Email</label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="email@gmail.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div className="reset-button"></div>
              <button type="submit" disabled={loading}>
                {loading ?
                  <div className="outter-load-table">
                    <ThreeDots
                      height="38"
                      width="40"
                      radius="9"
                      color="white"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  </div> : "Reset password"
                }
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MasterForgetPassword;