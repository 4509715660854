import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, LabelList } from 'recharts';
import { ThreeDots } from 'react-loader-spinner';
import { processedExpensesByLocation } from './Services/reportExpense';

const data = [
  { name: 'United States', closedAmount: 44 },
  { name: 'Belgium', closedAmount: 55 },
  { name: 'Panama', closedAmount: 13 },
  { name: 'Germany', closedAmount: 43 },
  { name: 'Netherlands', closedAmount: 46 },
  { name: 'Mexico', closedAmount: 55 },
  { name: 'Country G', closedAmount: 32 },
];

const ExpensePerLocationGraph = ({ expenseData, expenseDataLoading, expenseDataError }) => {
  const reportingFinancialExpense = useSelector((state) => state.reportingExpense);
  const { forex } = useSelector((state) => state.forex);
  const settings = JSON.parse(localStorage.getItem("settings"));
  const setReportingCurrency = useSelector((state) => state.reportingExpense.expCurrency);
  var currencies = "USD";
  if(setReportingCurrency && setReportingCurrency.length===1){
     currencies = setReportingCurrency[0];
  }else{
    currencies = settings.currency;
  }



  const processedData = useMemo(() => {
    const processed = processedExpensesByLocation(expenseData, reportingFinancialExpense, settings,currencies, forex);
    return processed.sort((a, b) => b.closedAmount - a.closedAmount);
  }, [expenseData, reportingFinancialExpense, settings,currencies, forex]);

  const calculateXTicks = () => {
    const maxDataValue = Math.max(...processedData.map(item => item.closedAmount));
    const tickInterval = Math.ceil(maxDataValue / 10); // Set the interval between ticks
    const numOfTicks = Math.min(Math.ceil(maxDataValue / tickInterval), 10);
    const ticks = [];

    for (let i = 0; i <= numOfTicks + 4; i++) { // Adjust the number of ticks
      ticks.push(i * tickInterval);
    }

    return ticks;
  }

  const xAxisTicks = calculateXTicks();

  if (expenseDataLoading) return (
    <div className="outter-load-table">
      <ThreeDots
        height="80"
        width="80"
        radius="9"
        color="#6479f9"
        ariaLabel="three-dots-loading"
        visible={true}
      />
    </div>
  );

  if (expenseDataError) return <div>Error loading data.</div>;

  const calculateChartHeight = (dataLength) => {
    const rowHeight = 50;
    const baseHeight = 100;
    return dataLength * rowHeight + baseHeight;
  };

  const calculateLeftMargin = (data) => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    context.font = '12px Arial';

    const longestWordLength = data.reduce((maxLength, item) => {
      const firstWord = item.name.split(' ')[0];
      const wordWidth = context.measureText(firstWord).width;
      return Math.max(maxLength, wordWidth);
    }, 0);

    return Math.max(30, longestWordLength - 150);
  };

  const leftMargin = calculateLeftMargin(processedData);

  return (
    <div className='graph-project-per-hour' style={{ width: '100%', height: Math.max(542, calculateChartHeight(processedData.length)), }}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          layout="vertical"
          data={processedData}
          barSize={13} // Set the height of each bar
          margin={{
            top: 20,
            right: 42,
            left: leftMargin,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" horizontal={false} vertical={true} />
          <XAxis
            type="number"
            domain={[0, 'dataMax + 10']}
            tickFormatter={(tick) => `${new Intl.NumberFormat("en-US", {style: "currency", currency: currencies,}).format(tick)}`}
            ticks={xAxisTicks}
          />
          <YAxis
            type="category"
            dataKey="name"
            // width={150} // Ensure enough width for client names
            tickMargin={10}
          />
          <Tooltip formatter={(value) => `${new Intl.NumberFormat("en-US", {style: "currency", currency: currencies,}).format(value)}`} />
          <Bar dataKey="closedAmount" name="Closed amount in default currency" fill="#C7B6F6">
            <LabelList dataKey="closedAmount" position="right" fill="#282828" formatter={(value) => `${new Intl.NumberFormat("en-US", {style: "currency", currency: currencies,}).format(value)}`} />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}

export default ExpensePerLocationGraph;
