import React from "react";
import { useSelector } from 'react-redux';
import Dollar from "../../../../images/icons/dollar-kit.svg";

// Functional component
function DeniedRequestsTimeOff({ timeoffReq }) {
  const reportingTimeoffRequest = useSelector((state) => state.reportingTimeoffRequest);

  const filteredTimeoffReqs = timeoffReq.filter(tor => {
    const isUserDetailMatch = !reportingTimeoffRequest.timeoffReqUser || reportingTimeoffRequest.timeoffReqUser.includes(tor.user._id);
    const isStatusDetailMatch = !reportingTimeoffRequest.timeoffReqReqStatus || reportingTimeoffRequest.timeoffReqReqStatus.includes(tor.status);
    const isNotDeleted = !tor.is_delete;
    const isYearMatch = !reportingTimeoffRequest.timeoffReqYear ||
      new Date(tor?.end_date).getFullYear() === reportingTimeoffRequest.timeoffReqYear;
    const isCategoryMatch = reportingTimeoffRequest.timeoffReqCategory && reportingTimeoffRequest.timeoffReqCategory.includes(tor.type_of_leave._id)
    return isUserDetailMatch && isStatusDetailMatch && isNotDeleted && isYearMatch && isCategoryMatch;
  });

  const deniedRequestCount = filteredTimeoffReqs.filter(tor => tor.status === 'Rejected').length;
  return (
    <div>
      <div className="overview-item">
        <div className="overview-title-outer">
          <div className="overview-title">Total Denied</div>
          <div className="overview-icon">
            <svg width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g filter="url(#filter0_d_2991_42)">
                <circle cx="24.5" cy="24.5" r="20.5" fill="white" />
              </g>
              <path d="M28 35.5C28 35.776 27.776 36 27.5 36H19.5C17.019 36 15 33.981 15 31.5V16.5C15 14.019 17.019 12 19.5 12H28.5C30.981 12 33 14.019 33 16.5V21.5C33 21.776 32.776 22 32.5 22C32.224 22 32 21.776 32 21.5V16.5C32 14.57 30.43 13 28.5 13H19.5C17.57 13 16 14.57 16 16.5V31.5C16 33.43 17.57 35 19.5 35H27.5C27.776 35 28 35.224 28 35.5ZM29 17.5C29 17.224 28.776 17 28.5 17H19.5C19.224 17 19 17.224 19 17.5C19 17.776 19.224 18 19.5 18H28.5C28.776 18 29 17.776 29 17.5ZM27 22.5C27 22.224 26.776 22 26.5 22H19.5C19.224 22 19 22.224 19 22.5C19 22.776 19.224 23 19.5 23H26.5C26.776 23 27 22.776 27 22.5ZM19.5 27C19.224 27 19 27.224 19 27.5C19 27.776 19.224 28 19.5 28H23.5C23.776 28 24 27.776 24 27.5C24 27.224 23.776 27 23.5 27H19.5ZM39 29.5C39 33.084 36.084 36 32.5 36C28.916 36 26 33.084 26 29.5C26 25.916 28.916 23 32.5 23C36.084 23 39 25.916 39 29.5ZM38 29.5C38 26.468 35.532 24 32.5 24C29.468 24 27 26.468 27 29.5C27 32.532 29.468 35 32.5 35C35.532 35 38 32.532 38 29.5Z" fill="#6A7988" />
              <path d="M30 32L35 27" stroke="#6A7988" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M35 32L30 27" stroke="#6A7988" stroke-linecap="round" stroke-linejoin="round" />
              <defs>
                <filter id="filter0_d_2991_42" x="0" y="0" width="49" height="49" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                  <feOffset />
                  <feGaussianBlur stdDeviation="2" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix type="matrix" values="0 0 0 0 0.468733 0 0 0 0 0.51145 0 0 0 0 0.554167 0 0 0 0.39 0" />
                  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2991_42" />
                  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2991_42" result="shape" />
                </filter>
              </defs>
            </svg>

          </div>
        </div>

        <div className="overview-rice">
          {deniedRequestCount}
        </div>
      </div>
    </div>
  );
}

export default DeniedRequestsTimeOff;
