import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../features/auth/authSlice';
import masterAuthReducer from '../features/masterAuth/masterAuthSlice';
import sprintReducer from '../features/sprint/sprintSlice';
import userReducer from '../features/users/usersSlice';
import invoiceReducer from '../features/invoice/invoiceSlice';
import clientInvoiceReducer from '../features/client/clientInvoiceSlice';
import clientReducer from '../features/client/clientSlice';
import vendorInvoiceReducer from '../features/vendor/vendorInvoiceSlice';
import masterDashBoardReducer from '../features/masterDashBoard/masterDashBoardSlice';
import vendorReducer from '../features/vendor/vendorSlice';
import headerTextReducer from '../features/headerText/headerTextSlice';
import sideBarTextReducer from '../features/sideBarText/sideBarTextSlice';
import timeTrackerReducer from '../features/timeTracker/timeTrackerSlice';
import timerDataReducer from '../features/timerData/timerDataSlice';
import reportingOverviewReducer from '../features/reporting/reportingOverview/reportingOverviewSlice';
import reportingClientReducer from '../features/reporting/reportingClient/reportingClientSlice';
import reportingClientTimeWorkedReducer  from '../features/reporting/reportingClient/reportingClientTimeWorkedSlice';
import reportingExpenseReducer from '../features/reporting/reportingFinancial/reportingExpenseSlice';
import reportingFinancialOverviewReducer from '../features/reporting/reportingFinancial/reportingFinancialOverviewSlice';
import reportingRevenueReducer from '../features/reporting/reportingFinancial/reportingRevenueSlice';
import reportingTimeoffRequestReducer from '../features/reporting/reportingPersonnel/reportingTimeoffReqSlice';
import reportingReimbursmentReducer from '../features/reporting/reportingPersonnel/reportingReimbursmentSlice';
import reportingTimeWorkedReducer from '../features/reporting/reportingPersonnel/reportingTimeWorkedSlice';
import reportingServicesReducer from '../features/reporting/reportingServices/reportingServicesSlice';
import expenseDataReducer from '../features/expenseData/expenseDataSlice';
import notificationReducer from '../features/notification/notificationSlice';
import accountDetailsReducer from '../features/accountDetails/accountDetailsSlice';
import bankFetchUpdateReducer from '../features/bankFetchUpdate/bankFetchUpdateSlice';
import appSettingsSlice from '../features/appSettings/appSettingsSlice';
import timeTrackerLogSlice from '../features/timeTrackerData/timeTrackerLogSlice';
import project from '../features/projects/projectSlice';
import forexSlice from '../features/forex/forexSlice';
import onBoard from '../features/onBoard/onBoardSlice';
import graphFilterSlice from '../features/graphFilter/graphFilterSlice';

export const store = configureStore({
    reducer: {
        auth: authReducer,
        masterAuth: masterAuthReducer,
        invoice: invoiceReducer,
        client: clientReducer,
        clientInvoice: clientInvoiceReducer,
        vendor: vendorReducer,
        vendorInvoice: vendorInvoiceReducer,
        masterDashBoard: masterDashBoardReducer,
        notification: notificationReducer,
        headerText: headerTextReducer,
        sideBarText: sideBarTextReducer,
        tracker: timeTrackerReducer,
        timerData: timerDataReducer,
        expenseData: expenseDataReducer,
        accountDetails: accountDetailsReducer,
        bankFetchUpdate: bankFetchUpdateReducer,
        sprint: sprintReducer,
        user: userReducer,
        appSettings: appSettingsSlice,
        timeTrackerLog: timeTrackerLogSlice,
        reportingOverview: reportingOverviewReducer,
        reportingClient: reportingClientReducer,
        reportingCliTimeWorked: reportingClientTimeWorkedReducer,
        reportingExpense: reportingExpenseReducer,
        reportingRevenue: reportingRevenueReducer,
        reportingFinancialOverview: reportingFinancialOverviewReducer,
        reportingTimeoffRequest: reportingTimeoffRequestReducer,
        reportingReimbursment: reportingReimbursmentReducer,
        reportingTimeWorked: reportingTimeWorkedReducer,
        reportingServices: reportingServicesReducer,
        project: project,
        forex: forexSlice,
        onBoard: onBoard,
        graphFilter: graphFilterSlice
    },
})