import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { startOfYear } from 'date-fns';

const initialState = {
  client: null,
  project: null,
  year: null,
  currency: null,
};

export const setReportingClient = createAsyncThunk(
  "reporting/client",
  async (client) => {
    return client;
  }
);

export const setReportingProject = createAsyncThunk(
  "reporting/project",
  async (project) => {
    return project;
  }
);

export const setReportingYear = createAsyncThunk(
  "reporting/year",
  async (year) => {
    if (year !== null) {
      //   const date = startOfYear(new Date(year, 0, 1));
      //   date.setHours(0, 0, 0, 0);
      return year;
    } else {
      return null;
    }
  }
);

export const setReportingCurrency = createAsyncThunk(
  "reporting/currency",
  async (currency) => {
    return currency;
  }
);

export const reportingOverviewSlice = createSlice({
  name: "reporting",
  initialState,
  reducers: {
    reset: (state) => {
      state.client = null;
      state.project = null;
      state.year = null;
      state.currency = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setReportingClient.fulfilled, (state, action) => {
      state.client = action.payload;
    });
    builder.addCase(setReportingProject.fulfilled, (state, action) => {
      state.project = action.payload;
    });
    builder.addCase(setReportingYear.fulfilled, (state, action) => {
      state.year = action.payload;
    });
    builder.addCase(setReportingCurrency.fulfilled, (state, action) => {
      state.currency = action.payload;
    });
  },
});

export const { reset } = reportingOverviewSlice.actions;
export default reportingOverviewSlice.reducer;
