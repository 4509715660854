import axios from "axios";
import React, { useRef } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import { useSelector, useDispatch } from 'react-redux';
import profileImage from "../../../images/user/Default_pfp.png";
import TruncateFieldView from "../../helpers/truncateFieldView";
import { logout, reset } from "../../../features/auth/authSlice";
import { reset as invoiceReset } from "../../../features/invoice/invoiceSlice.js";
import { reset as headerReset } from "../../../features/headerText/headerTextSlice.js";
import { reset as appSettingsReset } from "../../../features/appSettings/appSettingsSlice.js";
import { motion } from 'framer-motion';
import { useVendorNameView, } from "../../../pages/Vendors/VendorDetailsLayout/useVendorDetails.jsx";
import userCategory from "../../../constants/userCategory.js";

const fetchUserDetails = async (userId, token, dispatch, navigate) => {
  try {
    const response = await axios.post(
      "/api/user/nameImage",
      { _id: userId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    global.config.slackMessage(error.toString());
    if (error.response?.data?.status === "deleted") {
      localStorage.removeItem("user");
      dispatch(logout());
      dispatch(reset());
      dispatch(invoiceReset());
      dispatch(headerReset());
      dispatch(appSettingsReset());
      toast.error("This user is deleted!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      navigate("/");
      return;
    }
    throw new Error("Error fetching user details");
  }
};

const MemberName = ({ userId }) => {
  const componentRef = useRef(null);
  const fadeIn = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 }
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  let token = null;
  const { user } = useSelector((state) => state.auth);
  if (user) {
    if (user && user.token) {
      token = user.token;
    }
  }

  const { data: userDetails, isLoading, isError } = useQuery(
    ["userDetails", userId, token],
    () => fetchUserDetails(userId, token, dispatch, navigate)
  );

  // if (!isLoading || (userDetails?.user_category === userCategory.Vendor && userDetails?.venId)) {
  const { data: vendorView = [], isLoading: isVendorLoading, isError: vendorViewError } = useVendorNameView(
    userDetails?.venId ?? null, // Pass venId or null
    token
  );
  // }

  if (isLoading || (userDetails?.user_category === userCategory.Vendor && isVendorLoading)) {
    return (
      <div className="loading">
        <ThreeDots
          height="18"
          width="20"
          radius="9"
          color="#6479f9"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          wrapperClassName=""
          visible={true}
        />
      </div>
    );
  }

  if (isError || (userDetails?.user_category === userCategory.Vendor && vendorViewError) || (!userDetails && !vendorView)) {
    return <div>N/A</div>;
  }

  const { first_name, middle_name, last_name, company_name, company_first_name, company_last_name, profile_image } =
    userDetails;
  const fullName = userDetails?.user_category === userCategory.Vendor
    ? vendorView.vendor_name || vendorView.vendor_legalentity_name || "Unknown Vendor"
    : first_name
      ? first_name + (middle_name && middle_name !== "null" ? " " + middle_name : "") + " " + last_name
      : company_first_name
        ? company_first_name + (company_last_name && company_last_name !== "null" ? " " + company_last_name : "")
        : company_name;

  
  const profileImageSrc = (userDetails?.user_category === userCategory.Vendor && userDetails?.venId)
    ? `/uploads/vendorCompyLogo/lowQuality/${vendorView.company_logo}`
    : profile_image !== null && profile_image !== "null"
      ? `/uploads/lowProfileImage/${profile_image}`
      : profileImage;

  return (
    <>
      <motion.div
        ref={componentRef}
        initial="hidden"
        animate="visible"
        variants={fadeIn}
        transition={{ duration: 0.3 }}
      >
        <div className="member-name">
          {/* {profile_image !== null && profile_image !== "null" ? ( */}
            <img
              src={profileImageSrc}
              alt={fullName}
              className="member-image"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = '/uploads/lowProfileImage/user_default.png';
              }}
            />
          {/* ) : (
            <img src={profileImage} alt={fullName} className="member-image" />
          )} */}
          <span><TruncateFieldView textContent={fullName} /></span>
        </div>
      </motion.div>
    </>
  );
};

export default MemberName;