import headerTextService from "./headerTextService";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const headerTextLocal = JSON.parse(localStorage.getItem('headerTextLocal'));
const initialState = {
  headerTextState: headerTextLocal ? headerTextLocal : "Home",
};

export const setHeaderText = createAsyncThunk(
  "headerText/setHeaderText",
  async (headerText) => {
    localStorage.setItem('headerTextLocal',JSON.stringify(headerText))
    return headerText;
  }
);

export const headerTextSlice = createSlice({
  name: "headerText",
  initialState,
  reducers: {
    reset: (state) => {
      state.headerTextState = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setHeaderText.fulfilled, (state, action) => {
      state.headerTextState = action.payload;
    });
  },
});

export const { reset } = headerTextSlice.actions;
export default headerTextSlice.reducer;