import React, { useEffect, useState } from "react";
import { AES, enc } from 'crypto-js';
import { Link, useNavigate, useParams } from "react-router-dom";
import "./OnboardingCompanyDetails.css";
import CompanyDetailsForm from "../../../components/Onboarding/OnboardingCompanyDetails/CompanyDetaislForm";
import DetailSidebar from "../../../components/Onboarding/OnboardingCompanyDetails/CompanyDetailsSidebar";
import AccountInformation from "../../../components/Onboarding/CompantAccountInformation/AccountInformation";
import YourPersonalDetails from "../../../components/Onboarding/YourPersonalDetails/YourPersonalDetails";
import BoardingSecurity from "../../../components/Onboarding/Security/Security";
import OnboardingFormResponce from "../../../components/Onboarding/OnboardingCompanyDetails/OnboardingFormResponse";
import axios from "axios";
import CryptoJS from "crypto-js";

function OnboardingCompanyDetails() {
  const [activeIndex, setActiveIndex] = useState(0);
  const [submittedIndices, setSubmittedIndices] = useState([]);

  const handleSidebarItemClick = (index) => {
    setActiveIndex(index);
  };

  const handleFormSubmit = () => {
    setSubmittedIndices([...submittedIndices, activeIndex]);
    setActiveIndex(activeIndex + 1);
  };

  const handleBack = () => {
    if (activeIndex > 0) {
      setActiveIndex(activeIndex - 1);
    }
  };
  const { email, usertype, encodedAppId } = useParams();
  const bytes1 = CryptoJS.AES.decrypt(encodedAppId, "encryptionKey");

  const decodedEmail = decodeURIComponent(
    atob(email.replace(/\-/g, "+").replace(/_/g, "/"))
  );
  const [profileDetails, setProfileDetails] = useState(null);

  useEffect(() => {
    const getInviteDetails = async () => {
      try {

        const response = await axios.post(
          `/api/onboard/invite`,
          {
            email: decodedEmail
          },
          {
          }
        );
        // Handle the response if needed
        setProfileDetails(response.data);
      } catch (error) {
        console.error('Error fetching invite details:', error);
      }
    };

    getInviteDetails();
  }, []); // Include dependencies if there are any


  return (
    <div className="onboarding-page-outer">
      <DetailSidebar
        activeIndex={activeIndex}
        handleItemClick={handleSidebarItemClick}
        submittedIndices={submittedIndices}
      />
      <div className="detail-form-section-outer">
        <AccountInformation onSubmit={handleFormSubmit} onBack={handleBack} email={decodedEmail} />
        {/* {activeIndex === 0 && (
          <CompanyDetailsForm onFormSubmit={handleFormSubmit} usertype={usertype} />
        )}
        {activeIndex === 1 && (
          <AccountInformation onSubmit={handleFormSubmit} onBack={handleBack} />
        )}
        {activeIndex === 2 && (
          <YourPersonalDetails
            onSubmit={handleFormSubmit}
            onBack={handleBack}
          />
        )}
        {activeIndex === 3 && (
          <BoardingSecurity onSubmit={handleFormSubmit} onBack={handleBack} />
        )}
        {activeIndex === 4 && <OnboardingFormResponce />} */}
      </div>
    </div>
  );
}

export default OnboardingCompanyDetails;
