const cliInvStatusConsts = {
  Pending: 0,
  Invoice_Send: 1,
  Rejected: 2,
  Canceled: 3,
  Payment_Processed: 4,
  Overdue: 5,
};
export default cliInvStatusConsts;

