import React from "react";
import UsersTest from "../../components/User/UserOverviewTest";

function User()  {
    return(
        <div className="react-outer">
            <UsersTest/>
        </div>
    )
}

export default User;