import React, { useState } from "react";

function TruncateUserName({ textContent }) {
  const [showFullText, setShowFullText] = useState(false);

  const handleMouseOver = () => {
    if (extractedTextContent.length > 16) {
      setShowFullText(true);
    }
  };

  const handleMouseOut = () => {
    setShowFullText(false);
  };

  const truncateText = (text, maxLength) => {
    if (text && text.length > maxLength) {
      return text.substr(0, maxLength) + "...";
    }
    return text;
  };

  const extractTextContent = (children) => {
    // Convert JSX children to an array of React elements
    const elements = React.Children.toArray(children);
    // Concatenate text content from each element
    return elements.reduce((acc, child) => {
      if (typeof child === "string") {
        return acc + child;
      } else if (typeof child === "object" && child.props.children) {
        return acc + extractTextContent(child.props.children);
      }
      return acc;
    }, "");
  };

  // Extract text content from the provided JSX
  const extractedTextContent = extractTextContent(textContent);

  const fieldClass = showFullText ? "value-field full-text" : "value-field";

  return (
    <div className="tran-outer">
      <div
        className={fieldClass}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
      >
        {showFullText ? textContent : truncateText(extractedTextContent, 16)}
      </div>
    </div>
  );
}

export default TruncateUserName;
