import moment from "moment";
import masterDashBoardService from './masterDashBoardService';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {
    masterDashboardDateRange: [
        new Date(moment().utc().subtract(30, 'years').startOf('year').format()).toISOString(),
        new Date(moment().utc().endOf('year').format()).toISOString()
    ]
}

export const setMasterDashboardDate = createAsyncThunk('masterDashboard/setMasterDashboardDate', async (dateRange) => {
    return await masterDashBoardService.setDateRange(dateRange);
})

export const masterDashBoardSlice = createSlice({
    name: 'masterDashBoardDateRange',
    initialState,
    reducers: {
        reset: (state) => {
            state.masterDashboardDateRange = [new Date(moment().utc().subtract(30, 'years').startOf('year').format()).toISOString(),
            new Date(moment().utc().endOf('year').format()).toISOString()]
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(setMasterDashboardDate.fulfilled, (state, action) => {
                state.masterDashboardDateRange = action.payload
            })
    }
})

export const { reset } = masterDashBoardSlice.actions
export default masterDashBoardSlice.reducer 