import React, { useState } from "react";
import "./OnboardingCompanyDetails.css";
import CompanyDetailsForm from "../../components/Onboarding/OnboardingCompanyDetails/CompanyDetaislForm";
import DetailSidebar from "../../components/Onboarding/OnboardingCompanyDetails/CompanyDetailsSidebar";
import AccountInformation from "../../components/Onboarding/CompantAccountInformation/AccountInformation";
import YourPersonalDetails from "../../components/Onboarding/YourPersonalDetails/YourPersonalDetails";
import BoardingSecurity from "../../components/Onboarding/Security/Security";
import OnboardingFormResponce from "../../components/Onboarding/OnboardingCompanyDetails/OnboardingFormResponse";

function OnboardingCompanyDetails() {
  const [activeIndex, setActiveIndex] = useState(0);
  const [submittedIndices, setSubmittedIndices] = useState([]);

  const handleSidebarItemClick = (index) => {
    setActiveIndex(index);
  };

  const handleFormSubmit = () => {
    setSubmittedIndices([...submittedIndices, activeIndex]);
    setActiveIndex(activeIndex + 1);
  };

  const handleBack = () => {
    if (activeIndex > 0) {
      setActiveIndex(activeIndex - 1);
    }
  };

  return (
    <div className="onboarding-page-outer">
      <DetailSidebar
        activeIndex={activeIndex}
        handleItemClick={handleSidebarItemClick}
        submittedIndices={submittedIndices}
      />
      <div className="detail-form-section-outer">
        {activeIndex === 0 && (
          <CompanyDetailsForm onFormSubmit={handleFormSubmit} />
        )}
        {activeIndex === 1 && (
          <AccountInformation onSubmit={handleFormSubmit} onBack={handleBack} />
        )}
        {activeIndex === 2 && (
          <YourPersonalDetails
            onSubmit={handleFormSubmit}
            onBack={handleBack}
          />
        )}
        {activeIndex === 3 && (
          <BoardingSecurity onSubmit={handleFormSubmit} onBack={handleBack} />
        )}
        {activeIndex === 4 && <OnboardingFormResponce />}
      </div>
    </div>
  );
}

export default OnboardingCompanyDetails;
