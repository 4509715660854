import React from "react";
import VendorsInvoices from "../../../components/Vendors/VendorsInvoices/VendorsInvoices";

function VendorInvoice()  {
    return(
        <div className="react-outer">
            <VendorsInvoices />
        </div>
    )
}

export default VendorInvoice;