import React, { useMemo } from 'react';
import { useSelector } from "react-redux";
import { ThreeDots } from "react-loader-spinner";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { convertCurrencyHelper } from '../../../../../helpers/forexConvertor';
import { reportingFilterDateRangesConst } from '../../../../../constants/reportingFilterDateRangesConst';
import { reportingFilterComparisonConst } from '../../../../../constants/reportingFilterComparisonConst';

const data = [
  {
    name: 'January',
    currentYear: 4000,
    lastYear: 2400,
    amt: 2400,
  },
  {
    name: 'February',
    currentYear: 3000,
    lastYear: 1398,
    amt: 2210,
  },
  {
    name: 'March',
    currentYear: 2000,
    lastYear: 800,
    amt: 2290,
  },
  {
    name: 'April',
    currentYear: 2780,
    lastYear: 3908,
    amt: 2000,
  },
  {
    name: 'May',
    currentYear: 1890,
    lastYear: 4800,
    amt: 2181,
  },
  {
    name: 'June',
    currentYear: 2390,
    lastYear: 3800,
    amt: 2500,
  },
  {
    name: 'July',
    currentYear: 3490,
    lastYear: 4300,
    amt: 2100,
  },
  {
    name: 'August',
    currentYear: 1490,
    lastYear: 4300,
    amt: 2100,
  },
  {
    name: 'September',
    currentYear: 2490,
    lastYear: 1300,
    amt: 2100,
  },
  {
    name: 'October',
    currentYear: 3490,
    lastYear: 2300,
    amt: 2100,
  },
  {
    name: 'November',
    currentYear: 3490,
    lastYear: 1300,
    amt: 2100,
  },
  {
    name: 'December',
    currentYear: 2490,
    lastYear: 2300,
    amt: 2100,
  },
];

// const useProcessedData = (
//   clientInvoices,
//   clientInvoicesLoading,
//   isError,
//   reportingOverview,
//   preferredCurrency,
//   forex,
//   selectedDateRange,
//   selectedComparison,
//   selectedFrequency,
//   selectedYearRange,
//   selectedMonthRange,
//   selectedComparisonYear,
//   selectedComparisonMonth,
// ) => {
//   return useMemo(() => {
//     if (clientInvoicesLoading || isError) return [];

//     const getLastDayOfMonth = (year, month) => {
//       return new Date(year, month, 0).getDate();
//     };

//     const getStartAndEndDates = (rangeId, year = new Date().getFullYear()) => {
//       switch (rangeId) {
//         case reportingFilterDateRangesConst.This_Year:
//           return [new Date(`${year}-01-01`), new Date(`${year}-12-31`)];

//         case reportingFilterDateRangesConst.This_Quarter: {
//           const quarter = Math.floor((new Date().getMonth() + 3) / 3);
//           const startMonth = (quarter - 1) * 3 + 1;
//           const endMonth = startMonth + 2;
//           const endDay = getLastDayOfMonth(year, endMonth);
//           return [new Date(`${year}-${startMonth}-01`), new Date(`${year}-${endMonth}-${endDay}`)];
//         }

//         case reportingFilterDateRangesConst.First_Half:
//           return [new Date(`${year}-01-01`), new Date(`${year}-06-30`)];

//         case reportingFilterDateRangesConst.This_Month: {
//           const month = new Date().getMonth() + 1;
//           const endDay = getLastDayOfMonth(year, month);
//           return [new Date(`${year}-${month}-01`), new Date(`${year}-${month}-${endDay}`)];
//         }

//         case reportingFilterDateRangesConst.Year_Selected:
//           if (selectedYearRange) {
//             const selectedYear = selectedYearRange.getFullYear();
//             return [new Date(`${selectedYear}-01-01`), new Date(`${selectedYear}-12-31`)];
//           } else {
//             return [new Date(`${year}-01-01`), new Date(`${year}-12-31`)];
//           }
//         case reportingFilterDateRangesConst.Month_Selected:
//           if (selectedMonthRange) {
//             const selectedMonth = selectedMonthRange.getMonth() + 1;
//             const selectedYearForMonth = selectedMonthRange.getFullYear();
//             const endDay = getLastDayOfMonth(selectedYearForMonth, selectedMonth);
//             return [new Date(`${selectedYearForMonth}-${selectedMonth}-01`), new Date(`${selectedYearForMonth}-${selectedMonth}-${endDay}`)];
//           } else {
//             const currentMonth = new Date().getMonth() + 1;
//             const endDay = getLastDayOfMonth(year, currentMonth);
//             return [new Date(`${year}-${currentMonth}-01`), new Date(`${year}-${currentMonth}-${endDay}`)];
//           }
//         default:
//           return [new Date(`${year}-01-01`), new Date(`${year}-12-31`)];
//       }
//     };

//     const getComparisonStartAndEndDates = (comparisonId, year = new Date().getFullYear()) => {
//       switch (comparisonId) {
//         case reportingFilterComparisonConst.Last_Year:
//           return [new Date(`${year - 1}-01-01`), new Date(`${year - 1}-12-31`)];

//         case reportingFilterComparisonConst.Last_Quarter: {
//           const quarter = Math.floor((new Date().getMonth() + 3) / 3);
//           const lastQuarter = quarter === 1 ? 4 : quarter - 1;
//           const startMonth = (lastQuarter - 1) * 3 + 1;
//           const endMonth = startMonth + 2;
//           const lastYear = quarter === 1 ? year - 1 : year;
//           const endDay = getLastDayOfMonth(lastYear, endMonth);
//           return [new Date(`${lastYear}-${startMonth}-01`), new Date(`${lastYear}-${endMonth}-${endDay}`)];
//         }

//         case reportingFilterComparisonConst.Second_Half:
//           return [new Date(`${year}-07-01`), new Date(`${year}-12-31`)];

//         case reportingFilterComparisonConst.Last_Month: {
//           const lastMonth = new Date().getMonth();
//           const lastYear = lastMonth === 0 ? year - 1 : year;
//           const lastMonthFormatted = lastMonth === 0 ? 12 : lastMonth;
//           const endDay = getLastDayOfMonth(lastYear, lastMonthFormatted);
//           return [new Date(`${lastYear}-${lastMonthFormatted}-01`), new Date(`${lastYear}-${lastMonthFormatted}-${endDay}`)];
//         }
//         case reportingFilterComparisonConst.Year_Select:
//           if (selectedComparisonYear) {
//             const comparisonYear = selectedComparisonYear.getFullYear();
//             return [new Date(`${comparisonYear}-01-01`), new Date(`${comparisonYear}-12-31`)];
//           } else {
//             return [new Date(`${year - 1}-01-01`), new Date(`${year - 1}-12-31`)];
//           }

//         case reportingFilterComparisonConst.Month_Select:
//           if (selectedComparisonMonth) {
//             const comparisonMonth = selectedComparisonMonth.getMonth() + 1;
//             const comparisonYearForMonth = selectedComparisonMonth.getFullYear();
//             const endDay = getLastDayOfMonth(comparisonYearForMonth, comparisonMonth);
//             return [new Date(`${comparisonYearForMonth}-${comparisonMonth}-01`), new Date(`${comparisonYearForMonth}-${comparisonMonth}-${endDay}`)];
//           } else {
//             const lastMonth = new Date().getMonth() || 12;
//             const fallbackYear = lastMonth === 12 ? year - 1 : year;
//             const endDay = getLastDayOfMonth(fallbackYear, lastMonth);
//             return [new Date(`${fallbackYear}-${lastMonth}-01`), new Date(`${fallbackYear}-${lastMonth}-${endDay}`)];
//           }
//         default:
//           return [new Date(`${year - 1}-01-01`), new Date(`${year - 1}-12-31`)];
//       }
//     };

//     const [startCurrentRange, endCurrentRange] = getStartAndEndDates(selectedDateRange);
//     const [startComparisonRange, endComparisonRange] = getComparisonStartAndEndDates(selectedComparison);

//     const calculateROI = (invoices, startDate, endDate, frequency) => {
//       const data = invoices.reduce((acc, invoice) => {
//         const invoiceDate = new Date(invoice.cli_inv_end_date);
//         startDate.setHours(0, 0, 0, 0);
//         endDate.setHours(23, 59, 59, 999);
//         if (invoiceDate >= startDate && invoiceDate <= endDate && !invoice.is_delete) {
//           const paymentCurrency = invoice.cli_inv_currency || 'USD';
//           const clientId = invoice.cli_inv_for._id;

//           if (
//             reportingOverview.client ? reportingOverview.client.includes(clientId) : true &&
//               reportingOverview.currency ? reportingOverview.currency.includes(paymentCurrency) : true
//           ) {
//             let key;
//             if (frequency === 'Monthly') {
//               key = invoiceDate.toLocaleString('default', { month: 'long' });
//             } else if (frequency === 'Daily') {
//               key = invoiceDate.toLocaleDateString();
//             }

//             if (!acc[key]) {
//               acc[key] = { name: key, cost: 0 };
//             }
//             acc[key].cost += convertCurrencyHelper(paymentCurrency, preferredCurrency, invoice.cli_inv_total_amount, forex);
//           }
//         }
//         return acc;
//       }, {});

//       return data;
//     };

//     const currentData = calculateROI(clientInvoices, startCurrentRange, endCurrentRange, selectedFrequency === '1' ? 'Monthly' : 'Daily');
//     const comparisonData = calculateROI(clientInvoices, startComparisonRange, endComparisonRange, selectedFrequency === '1' ? 'Monthly' : 'Daily');

//     // Merge keys from both currentData and comparisonData
//     const allKeys = new Set([...Object.keys(currentData), ...Object.keys(comparisonData)]);

//     // Map the merged data
//     let mergedData = Array.from(allKeys).map(key => ({
//       name: key,
//       currentYear: parseFloat(currentData[key]?.cost?.toFixed(2) || 0),
//       lastYear: parseFloat(comparisonData[key]?.cost?.toFixed(2) || 0),
//     }));

//     if (selectedFrequency === '1') {
//       const monthOrder = [
//         'January', 'February', 'March', 'April', 'May', 'June',
//         'July', 'August', 'September', 'October', 'November', 'December'
//       ];
//       mergedData = mergedData.sort((a, b) => {
//         return monthOrder.indexOf(a.name) - monthOrder.indexOf(b.name);
//       });
//     } else {
//       const parseDate = (dateString) => {
//         const [day, month, year] = dateString.split('/').map(Number);
//         return new Date(year, month - 1, day); // JavaScript months are 0-based
//       };
//       // mergedData = mergedData.sort((a, b) => return new Date(a.name) - new Date(b.name));
//       mergedData = mergedData.sort((a, b) => {
//         const dateA = parseDate(a.name);
//         const dateB = parseDate(b.name);
//         return dateA - dateB;
//       });
//       // mergedData = mergedData.sort((a, b) => new Date(b.name) - new Date(a.name));
//     }

//     return mergedData;
//   }, [
//     clientInvoices,
//     clientInvoicesLoading,
//     isError,
//     reportingOverview,
//     preferredCurrency,
//     forex,
//     selectedDateRange,
//     selectedComparison,
//     selectedFrequency,
//     selectedYearRange,
//     selectedMonthRange,
//     selectedComparisonYear,
//     selectedComparisonMonth,
//   ]);
// };

const useProcessedData = (
  clientInvoices,
  clientInvoicesLoading,
  isError,
  reportingOverview,
  preferredCurrency,
  forex,
  selectedDateRange,
  selectedComparison,
  selectedFrequency,
  selectedYearRange,
  selectedMonthRange,
  selectedComparisonYear,
  selectedComparisonMonth,
) => {
  return useMemo(() => {
    if (clientInvoicesLoading || isError) return [];

    const getLastDayOfMonth = (year, month) => {
      return new Date(year, month, 0).getDate();
    };

    const getStartAndEndDates = (rangeId, year = new Date().getFullYear()) => {
      switch (rangeId) {
        case reportingFilterDateRangesConst.This_Year:
          return [new Date(`${year}-01-01`), new Date(`${year}-12-31`)];

        case reportingFilterDateRangesConst.This_Quarter: {
          const quarter = Math.floor((new Date().getMonth() + 3) / 3);
          const startMonth = (quarter - 1) * 3 + 1;
          const endMonth = startMonth + 2;
          const endDay = getLastDayOfMonth(year, endMonth);
          return [new Date(`${year}-${startMonth}-01`), new Date(`${year}-${endMonth}-${endDay}`)];
        }

        case reportingFilterDateRangesConst.First_Half:
          return [new Date(`${year}-01-01`), new Date(`${year}-06-30`)];

        case reportingFilterDateRangesConst.This_Month: {
          const month = new Date().getMonth() + 1;
          const endDay = getLastDayOfMonth(year, month);
          return [new Date(`${year}-${month}-01`), new Date(`${year}-${month}-${endDay}`)];
        }

        case reportingFilterDateRangesConst.Year_Selected:
          if (selectedYearRange) {
            const selectedYear = selectedYearRange.getFullYear();
            return [new Date(`${selectedYear}-01-01`), new Date(`${selectedYear}-12-31`)];
          } else {
            return [new Date(`${year}-01-01`), new Date(`${year}-12-31`)];
          }
        case reportingFilterDateRangesConst.Month_Selected:
          if (selectedMonthRange) {
            const selectedMonth = selectedMonthRange.getMonth() + 1;
            const selectedYearForMonth = selectedMonthRange.getFullYear();
            const endDay = getLastDayOfMonth(selectedYearForMonth, selectedMonth);
            return [new Date(`${selectedYearForMonth}-${selectedMonth}-01`), new Date(`${selectedYearForMonth}-${selectedMonth}-${endDay}`)];
          } else {
            const currentMonth = new Date().getMonth() + 1;
            const endDay = getLastDayOfMonth(year, currentMonth);
            return [new Date(`${year}-${currentMonth}-01`), new Date(`${year}-${currentMonth}-${endDay}`)];
          }
        default:
          return [new Date(`${year}-01-01`), new Date(`${year}-12-31`)];
      }
    };

    const getComparisonStartAndEndDates = (comparisonId, year = new Date().getFullYear()) => {
      switch (comparisonId) {
        case reportingFilterComparisonConst.Last_Year:
          return [new Date(`${year - 1}-01-01`), new Date(`${year - 1}-12-31`)];

        case reportingFilterComparisonConst.Last_Quarter: {
          const quarter = Math.floor((new Date().getMonth() + 3) / 3);
          const lastQuarter = quarter === 1 ? 4 : quarter - 1;
          const startMonth = (lastQuarter - 1) * 3 + 1;
          const endMonth = startMonth + 2;
          const lastYear = quarter === 1 ? year - 1 : year;
          const endDay = getLastDayOfMonth(lastYear, endMonth);
          return [new Date(`${lastYear}-${startMonth}-01`), new Date(`${lastYear}-${endMonth}-${endDay}`)];
        }

        case reportingFilterComparisonConst.Second_Half:
          return [new Date(`${year}-07-01`), new Date(`${year}-12-31`)];

        case reportingFilterComparisonConst.Last_Month: {
          const lastMonth = new Date().getMonth();
          const lastYear = lastMonth === 0 ? year - 1 : year;
          const lastMonthFormatted = lastMonth === 0 ? 12 : lastMonth;
          const endDay = getLastDayOfMonth(lastYear, lastMonthFormatted);
          return [new Date(`${lastYear}-${lastMonthFormatted}-01`), new Date(`${lastYear}-${lastMonthFormatted}-${endDay}`)];
        }
        case reportingFilterComparisonConst.Year_Select:
          if (selectedComparisonYear) {
            const comparisonYear = selectedComparisonYear.getFullYear();
            return [new Date(`${comparisonYear}-01-01`), new Date(`${comparisonYear}-12-31`)];
          } else {
            return [new Date(`${year - 1}-01-01`), new Date(`${year - 1}-12-31`)];
          }

        case reportingFilterComparisonConst.Month_Select:
          if (selectedComparisonMonth) {
            const comparisonMonth = selectedComparisonMonth.getMonth() + 1;
            const comparisonYearForMonth = selectedComparisonMonth.getFullYear();
            const endDay = getLastDayOfMonth(comparisonYearForMonth, comparisonMonth);
            return [new Date(`${comparisonYearForMonth}-${comparisonMonth}-01`), new Date(`${comparisonYearForMonth}-${comparisonMonth}-${endDay}`)];
          } else {
            const lastMonth = new Date().getMonth() || 12;
            const fallbackYear = lastMonth === 12 ? year - 1 : year;
            const endDay = getLastDayOfMonth(fallbackYear, lastMonth);
            return [new Date(`${fallbackYear}-${lastMonth}-01`), new Date(`${fallbackYear}-${lastMonth}-${endDay}`)];
          }
        default:
          return [new Date(`${year - 1}-01-01`), new Date(`${year - 1}-12-31`)];
      }
    };

    const [startCurrentRange, endCurrentRange] = getStartAndEndDates(selectedDateRange);
    const [startComparisonRange, endComparisonRange] = getComparisonStartAndEndDates(selectedComparison);

    const calculateTotalAmount = (invoices, startDate, endDate) => {
      return invoices.reduce((total, invoice) => {
        const invoiceDate = new Date(invoice.cli_inv_end_date);
        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(23, 59, 59, 999);
        if (invoiceDate >= startDate && invoiceDate <= endDate && !invoice.is_delete) {
          const paymentCurrency = invoice.cli_inv_currency || 'USD';
          const amountInPreferredCurrency = convertCurrencyHelper(paymentCurrency, preferredCurrency, invoice.cli_inv_total_amount, forex);
          total += amountInPreferredCurrency;
        }
        return total;
      }, 0);
    };

    const currentAmount = calculateTotalAmount(clientInvoices, startCurrentRange, endCurrentRange);
    const comparisonAmount = calculateTotalAmount(clientInvoices, startComparisonRange, endComparisonRange);

    // Single data entry with amounts for the selected date range and comparison
    return [{
      name: 'Invoiced Amount',
      currentAmount: parseFloat(currentAmount.toFixed(2)),
      comparisonAmount: parseFloat(comparisonAmount.toFixed(2)),
    }];
  }, [
    clientInvoices,
    clientInvoicesLoading,
    isError,
    reportingOverview,
    preferredCurrency,
    forex,
    selectedDateRange,
    selectedComparison,
    selectedFrequency,
    selectedYearRange,
    selectedMonthRange,
    selectedComparisonYear,
    selectedComparisonMonth,
  ]);
};

const GraphData = ({
  clientInvoices,
  clientInvoicesLoading,
  clientInvoicesError,
  selectedDateRange,
  selectedComparison,
  selectedFrequency,
  selectedYearRange,
  selectedMonthRange,
  selectedComparisonYear,
  selectedComparisonMonth,
}) => {
  const reportingOverview = useSelector((state) => state.reportingOverview);
  const { forex } = useSelector((state) => state.forex);
  const settings = JSON.parse(localStorage.getItem("settings"));

  const setReportingCurrency = useSelector((state) => state.reportingOverview.currency);
  var currencies = "USD";
  if (setReportingCurrency && setReportingCurrency.length === 1) {
    currencies = setReportingCurrency[0];
  } else {
    currencies = settings.currency;
  }


  const processedData = useProcessedData(
    clientInvoices,
    clientInvoicesLoading,
    clientInvoicesError,
    reportingOverview,
    currencies,
    forex,
    selectedDateRange,
    selectedComparison,
    selectedFrequency,
    selectedYearRange,
    selectedMonthRange,
    selectedComparisonYear,
    selectedComparisonMonth,
  );

  const currentYear = reportingOverview.year || new Date().getFullYear();
  const previousYear = currentYear - 1;
  const isCurrentYear = currentYear === new Date().getFullYear();

  if (clientInvoicesLoading) return <div className="outter-load-table">
    <ThreeDots
      height="80"
      width="80"
      radius="9"
      color="#6479f9"
      ariaLabel="three-dots-loading"
      wrapperStyle={{}}
      wrapperClassName=""
      visible={true}
    />
  </div>;
  if (clientInvoicesError) return <div>Error loading data.</div>;

  const getBarName = (isCurrent, selectedRange, selectedComparison, selectedYearRange, selectedMonthRange, selectedComparisonYear, selectedComparisonMonth) => {
    const rangeNames = {
      [reportingFilterDateRangesConst.This_Year]: 'This Year',
      [reportingFilterDateRangesConst.This_Quarter]: 'This Quarter',
      [reportingFilterDateRangesConst.First_Half]: 'First Half',
      [reportingFilterDateRangesConst.This_Month]: 'This Month',
      [reportingFilterDateRangesConst.Year_Selected]: selectedYearRange ? `Year ${selectedYearRange.getFullYear()}` : `Selected Year`,
      [reportingFilterDateRangesConst.Month_Selected]: selectedMonthRange ? `Month ${selectedMonthRange.toLocaleString('default', { month: 'long' })} ${selectedMonthRange.getFullYear()}` : `Selected Month`,
    };

    const comparisonNames = {
      [reportingFilterComparisonConst.Last_Year]: 'Last Year',
      [reportingFilterComparisonConst.Last_Quarter]: 'Last Quarter',
      [reportingFilterComparisonConst.Second_Half]: 'Second Half',
      [reportingFilterComparisonConst.Last_Month]: 'Last Month',
      [reportingFilterComparisonConst.Year_Select]: selectedComparisonYear ? `Year ${selectedComparisonYear.getFullYear()}` : `Previous Year`,
      [reportingFilterComparisonConst.Month_Select]: selectedComparisonMonth ? `Month ${selectedComparisonMonth.toLocaleString('default', { month: 'long' })} ${selectedComparisonMonth.getFullYear()}` : `Previous Month`,
    };

    const rangeName = rangeNames[selectedRange] || 'Current Range';
    const comparisonName = comparisonNames[selectedComparison] || 'Comparison Range';

    return isCurrent
      ? `${rangeName}`
      : `${comparisonName}`;
  };

  return (
    <div style={{ width: '100%', height: 542 }}>
      <ResponsiveContainer>
        <LineChart
          layout="horizontal"
          data={processedData}
          margin={{
            top: 20,
            right: 30,
            left: Math.max(
              0, // minimum margin
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: currencies,
              })
                .format(Math.max(...processedData.map((item) => Math.max(item.currentAmount, item.comparisonAmount))))
                .length // scale it based on the label length
            ),
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" type="category" padding={{ left: 50 }} tickMargin={20} />
          <YAxis type="number" tickFormatter={(value) => new Intl.NumberFormat("en-US", { style: "currency", currency: currencies, }).format(value)} />
          <Tooltip formatter={(value) => `${new Intl.NumberFormat("en-US", { style: "currency", currency: currencies, }).format(value)}`} />
          <Legend />
          <Line dataKey="currentAmount" name={getBarName(true, selectedDateRange, selectedComparison, selectedYearRange, selectedMonthRange, selectedComparisonYear, selectedComparisonMonth)} stroke="#96A8BA" />
          <Line dataKey="comparisonAmount" name={getBarName(false, selectedDateRange, selectedComparison, selectedYearRange, selectedMonthRange, selectedComparisonYear, selectedComparisonMonth)} stroke="#6479F8" />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}

export default GraphData;
