import React, { useState, useEffect, useRef } from 'react';
import Modal from 'react-modal';
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Close from "../../../../../../images/icons/close.svg";
import Filter from '../../../../Filter';
import { reportingFilterDateRangesConst } from '../../../../../../constants/reportingFilterDateRangesConst';
import { reportingFilterComparisonConst } from '../../../../../../constants/reportingFilterComparisonConst';
import reportingFilterFrequency from '../../../../../../constants/reportingFilterFrequency';
import reportingFilterComparison from '../../../../../../constants/reportingFilterComparison';
import reportingFilterDateRanges from '../../../../../../constants/reportingFilterDateRanges';
import InvoicedOutstandingFilterGraphFilter from './ReportingInvoicedVsOutstandingFilterGraphFilter';
import InvoicedOutstandingFiterGraph from './ReportInvoicedVsOutstandingFiterGraph';

// Functional component
function InvoicedOutstandingGraphFilter({ clientInvoices, clientInvoicesLoading, clientInvoicesError }) {
  const reportingRevenue = useSelector((state) => state.reportingRevenue);
  const currentYear = reportingRevenue.revenueYear || new Date().getFullYear();
  const previousYear = currentYear - 1;
  const isCurrentYear = currentYear === new Date().getFullYear();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedYearRange, setSelectedYearRange] = useState(null);
  const [selectedMonthRange, setSelectedMonthRange] = useState(null);
  const [selectedComparisonYear, setSelectedComparisonYear] = useState(null);
  const [selectedComparisonMonth, setSelectedComparisonMonth] = useState(null);
  // const defaultDateRange = reportingFilterDateRanges.find(range => range.range_name === 'This Year')?._id;
  const [selectedDateRange, setSelectedDateRange] = useState(reportingFilterDateRangesConst.This_Year);
  const [selectedComparison, setSelectedComparison] = useState(reportingFilterComparisonConst.Last_Year);
  const [selectedFrequency, setSelectedFrequency] = useState(null);

  const [isDateRangeDropdownOpen, setIsDateRangeDropdownOpen] = useState(false);
  const [isComparisonDropdownOpen, setIsComparisonDropdownOpen] = useState(false);
  const [isFrequencyDropdownOpen, setIsFrequencyDropdownOpen] = useState(false);

  const dateRangeDropdownRef = useRef(null);
  const comparisonDropdownRef = useRef(null);
  const frequencyDropdownRef = useRef(null);

  const handleFilterComparisonClick = () => {
    setSelectedDateRange(reportingFilterDateRangesConst.This_Year);
    setSelectedComparison(reportingFilterComparisonConst.Last_Year);
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setSelectedDateRange(reportingFilterDateRangesConst.This_Year);
    setSelectedComparison(reportingFilterComparisonConst.Last_Year);
    setIsModalOpen(false);
  };

  const toggleDateRangeDropdown = () => {
    setIsDateRangeDropdownOpen(!isDateRangeDropdownOpen);
  };

  const toggleComparisonDropdown = () => {
    setIsComparisonDropdownOpen(!isComparisonDropdownOpen);
  };

  const toggleFrequencyDropdown = () => {
    setIsFrequencyDropdownOpen(!isFrequencyDropdownOpen);
  };

  const handleDateRangeSelect = async (rangeId) => {
    setSelectedDateRange(rangeId);
    const correspondingComparison = reportingFilterDateRanges.find(range => range._id === rangeId)?.correspondingComparison;
    if (correspondingComparison) {
      setSelectedComparison(correspondingComparison);
    }
    setIsDateRangeDropdownOpen(false);
  };

  const handleComparisonSelect = (comparisonId) => {
    setSelectedComparison(comparisonId);
    const correspondingDateRange = reportingFilterComparison.find(option => option._id === comparisonId)?.correspondingDateRange;
    if (correspondingDateRange) {
      setSelectedDateRange(correspondingDateRange);
    }
    setIsComparisonDropdownOpen(false);
  };

  const handleFrequencySelect = (frequencyId) => {
    setSelectedFrequency(frequencyId);
    setIsFrequencyDropdownOpen(false);
  };

  const handleClickOutsideDropdown = (event) => {
    const isClickInsideDateRangeDropdown =
      dateRangeDropdownRef.current &&
      dateRangeDropdownRef.current.contains(event.target);
    const isClickInsideComparisonDropdown =
      comparisonDropdownRef.current &&
      comparisonDropdownRef.current.contains(event.target);
    const isClickInsideFrequencyDropdown =
      frequencyDropdownRef.current &&
      frequencyDropdownRef.current.contains(event.target);

    if (!isClickInsideDateRangeDropdown) {
      setIsDateRangeDropdownOpen(false);
    }
    if (!isClickInsideComparisonDropdown) {
      setIsComparisonDropdownOpen(false);
    }
    if (!isClickInsideFrequencyDropdown) {
      setIsFrequencyDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutsideDropdown);

    return () => {
      document.removeEventListener("click", handleClickOutsideDropdown);
    };
  }, []);

  return (
    <div className='top-outer-graph-report'>
      <div className="top-inner-graph">
        <div className="graph-title">
          <span>Invoiced vs Outstanding</span>
          <div className='comparison-type'>
            <div className='comparison-type-inner'>
              <div className='comparison-type-icon'>
                <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
                  <circle cx="3" cy="3" r="3" transform="matrix(-1 0 0 1 6 0)" fill="#86ABE1" />
                </svg>
              </div>
              <div className='comparison-type-title'>
                Total Invoiced
              </div>
            </div>
            <div className='comparison-type-inner'>
              <div className='comparison-type-icon'>
                <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
                  <circle cx="3" cy="3" r="3" transform="matrix(-1 0 0 1 6 0)" fill="#C7B6F6" />
                </svg>
              </div>
              <div className='comparison-type-title'>
                Outstanding Balance
              </div>
            </div>
          </div>
        </div>
        <div className="icon-graph">
          <div className="filter-comparison-graph">
            {/* <a>YEAR / MONTH</a> */}
            <button className='filter-comparison' onClick={handleFilterComparisonClick}>
              <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 23" fill="none">
                <path d="M9.30533 6.77208C9.79279 6.55329 10.2239 6.22619 10.5659 5.81566C10.9079 5.40514 11.1517 4.92201 11.2788 4.40305L20.1401 4.40305C20.3682 4.40305 20.5869 4.31245 20.7481 4.15119C20.9094 3.98992 21 3.7712 21 3.54314C21 3.31508 20.9094 3.09636 20.7481 2.9351C20.5869 2.77384 20.3682 2.68324 20.1401 2.68324L11.2831 2.68324C11.2305 2.47742 11.1615 2.27613 11.0767 2.08131C10.7733 1.41134 10.2689 0.852396 9.63354 0.482023C8.99814 0.111649 8.26322 -0.0517941 7.53074 0.0143659C6.79825 0.080526 6.10451 0.373009 5.54575 0.851242C4.987 1.32947 4.59093 1.96975 4.41249 2.68324L1.22226 2.68324C0.994194 2.68324 0.775474 2.77384 0.614212 2.9351C0.452949 3.09636 0.362352 3.31508 0.362352 3.54314C0.362352 3.7712 0.452949 3.98992 0.614212 4.15119C0.775474 4.31245 0.994194 4.40305 1.22226 4.40305L4.40819 4.40305C4.54425 4.95994 4.81437 5.47514 5.19505 5.90377C5.57573 6.33241 6.05541 6.66149 6.59234 6.86236C7.02793 7.02803 7.49214 7.10537 7.95791 7.08987C8.42369 7.07437 8.88172 6.96634 9.30533 6.77208ZM7.19857 5.25435C6.91737 5.14841 6.66663 4.97476 6.46857 4.74877C6.27051 4.52278 6.13124 4.25143 6.06309 3.95876C5.99494 3.66609 6.00003 3.36113 6.07789 3.0709C6.15576 2.78066 6.304 2.51411 6.50948 2.29485C6.71497 2.07559 6.97135 1.91039 7.25593 1.81388C7.54051 1.71738 7.8445 1.69254 8.14097 1.74158C8.43744 1.79062 8.71724 1.91202 8.95559 2.09502C9.19394 2.27802 9.38347 2.51698 9.50741 2.79073C9.6155 3.02529 9.67125 3.28057 9.67079 3.53884C9.67084 3.89079 9.56949 4.23529 9.37886 4.53114C9.18824 4.82699 8.91642 5.06165 8.59591 5.20705C8.37835 5.30858 8.14276 5.3658 7.90286 5.3754C7.66296 5.38499 7.42356 5.34677 7.19857 5.26295L7.19857 5.25435Z" fill="#6479F8" />
                <path d="M9.30533 22.6803C9.79279 22.4615 10.2239 22.1344 10.5659 21.7239C10.9079 21.3133 11.1517 20.8302 11.2788 20.3112L20.1401 20.3112C20.3682 20.3112 20.5869 20.2207 20.7481 20.0594C20.9094 19.8981 21 19.6794 21 19.4513C21 19.2233 20.9094 19.0046 20.7481 18.8433C20.5869 18.682 20.3682 18.5914 20.1401 18.5914L11.2831 18.5914C11.2305 18.3856 11.1615 18.1843 11.0767 17.9895C10.7733 17.3195 10.2689 16.7606 9.63354 16.3902C8.99814 16.0199 8.26322 15.8564 7.53074 15.9226C6.79825 15.9887 6.10451 16.2812 5.54575 16.7594C4.987 17.2377 4.59093 17.878 4.41249 18.5914L1.22226 18.5914C0.994194 18.5914 0.775474 18.682 0.614212 18.8433C0.452949 19.0046 0.362352 19.2233 0.362352 19.4513C0.362352 19.6794 0.452949 19.8981 0.614212 20.0594C0.775474 20.2207 0.994194 20.3112 1.22226 20.3112L4.40819 20.3112C4.54425 20.8681 4.81437 21.3833 5.19505 21.812C5.57573 22.2406 6.05541 22.5697 6.59234 22.7706C7.02793 22.9362 7.49214 23.0136 7.95791 22.9981C8.42369 22.9826 8.88172 22.8745 9.30533 22.6803ZM7.19857 21.1626C6.91737 21.0566 6.66663 20.883 6.46857 20.657C6.27051 20.431 6.13124 20.1596 6.06309 19.867C5.99494 19.5743 6.00003 19.2693 6.07789 18.9791C6.15576 18.6889 6.304 18.4223 6.50948 18.2031C6.71497 17.9838 6.97135 17.8186 7.25593 17.7221C7.54051 17.6256 7.8445 17.6007 8.14097 17.6498C8.43744 17.6988 8.71724 17.8202 8.95559 18.0032C9.19394 18.1862 9.38347 18.4252 9.50741 18.6989C9.61511 18.9336 9.67084 19.1888 9.67079 19.447C9.67084 19.799 9.56949 20.1435 9.37886 20.4393C9.18824 20.7352 8.91642 20.9699 8.59591 21.1153C8.37777 21.2154 8.14189 21.2711 7.90199 21.2793C7.6621 21.2874 7.42299 21.2477 7.19857 21.1626Z" fill="#6479F8" />
                <path d="M16.7005 12.7484C16.7478 12.6194 16.7865 12.4904 16.8209 12.3572L20.1401 12.3572C20.3682 12.3572 20.5869 12.2666 20.7481 12.1053C20.9094 11.944 21 11.7253 21 11.4972C21 11.2692 20.9094 11.0505 20.7481 10.8892C20.5869 10.7279 20.3682 10.6373 20.1401 10.6373L16.808 10.6373C16.681 10.1342 16.4452 9.66494 16.1173 9.26269C15.7894 8.86044 15.3773 8.53494 14.9101 8.3091C14.4428 8.08326 13.9317 7.96257 13.4128 7.95553C12.8939 7.94848 12.3797 8.05525 11.9065 8.26832C11.4817 8.45897 11.0988 8.73191 10.78 9.07138C10.4612 9.41084 10.2128 9.8101 10.0491 10.2461C10.0019 10.3751 9.96316 10.5084 9.92876 10.6373L1.22226 10.6373C0.994194 10.6373 0.775474 10.7279 0.614212 10.8892C0.452949 11.0505 0.362352 11.2692 0.362352 11.4972C0.362352 11.7253 0.452949 11.944 0.614212 12.1053C0.775474 12.2666 0.994194 12.3572 1.22226 12.3572L9.93306 12.3572C9.98239 12.5639 10.0515 12.7655 10.1394 12.9591C10.4256 13.6032 10.8973 14.1473 11.4943 14.5219C12.0913 14.8964 12.7864 15.0844 13.4909 15.0618C14.1953 15.0392 14.877 14.807 15.4487 14.3949C16.0204 13.9828 16.4563 13.4095 16.7005 12.7484ZM12.7234 13.2214C12.3852 13.0938 12.0924 12.8688 11.882 12.5748C11.6717 12.2808 11.5532 11.931 11.5417 11.5697C11.5302 11.2084 11.6261 10.8518 11.8174 10.545C12.0086 10.2382 12.2865 9.99504 12.616 9.84624C12.8942 9.72053 13.1994 9.66653 13.5039 9.68917C13.8083 9.71181 14.1023 9.81037 14.3588 9.97585C14.6154 10.1413 14.8264 10.3684 14.9726 10.6365C15.1187 10.9045 15.1954 11.2049 15.1957 11.5101C15.1955 11.7303 15.1562 11.9487 15.0796 12.1551C14.9937 12.3776 14.8648 12.5809 14.7003 12.7536C14.5358 12.9262 14.3389 13.0647 14.1208 13.1612C13.9035 13.2634 13.6681 13.3213 13.4282 13.3317C13.1883 13.342 12.9487 13.3045 12.7234 13.2214Z" fill="#6479F8" />
              </svg>
            </button>
          </div>
        </div>
      </div>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={handleModalClose}
        ariaHideApp={false}
        className="client-modal sprint-modal add-bank-account-modal account-details-modal report-grpah-filter"
        contentLabel="Invite a User"
        style={{
          content: {
            height: "41%",
            width: "70%",
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-40%, -40%)",
          },
        }}
      >
        <div className="modal add-bank-account-content account-details-modal-inner report-grpah-filter-inner">
          <div className="modal-close-invite outter-modal-main">
            <div className="modal-invite-head">
              <h2 className=""> View and filter report </h2>
            </div>
            <Link className="" onClick={handleModalClose}>
              <img src={Close} alt="Edit Icon" />{" "}
            </Link>
          </div>

          <div className='modal-body-filter'>
            <div className='modal-sub-title'>
              Invoiced vs Outstanding
            </div>
            <div className='modal-inner-des-outer'>
              <div className='modal-inner-graph'>
                <InvoicedOutstandingFilterGraphFilter
                  selectedDateRange={selectedDateRange}
                  selectedComparison={selectedComparison}
                  selectedFrequency={selectedFrequency}
                  selectedYearRange={selectedYearRange}
                  selectedMonthRange={selectedMonthRange}
                  selectedComparisonYear={selectedComparisonYear}
                  selectedComparisonMonth={selectedComparisonMonth}
                />
                <InvoicedOutstandingFiterGraph
                  clientInvoices={clientInvoices}
                  clientInvoicesLoading={clientInvoicesLoading}
                  clientInvoicesError={clientInvoicesError}
                  selectedDateRange={selectedDateRange}
                  selectedComparison={selectedComparison}
                  selectedFrequency={selectedFrequency}
                  selectedYearRange={selectedYearRange}
                  selectedMonthRange={selectedMonthRange}
                  selectedComparisonYear={selectedComparisonYear}
                  selectedComparisonMonth={selectedComparisonMonth}
                />
                {/* <ReportingClientComparisonGraphFilter /> */}
              </div>

              <Filter
                showFrequency={false}
                dateRangeDropdownRef={dateRangeDropdownRef}
                toggleDateRangeDropdown={toggleDateRangeDropdown}
                selectedDateRange={selectedDateRange}
                dateRanges={reportingFilterDateRanges}
                isDateRangeDropdownOpen={isDateRangeDropdownOpen}
                handleDateRangeSelect={handleDateRangeSelect}
                selectedYearRange={selectedYearRange}
                setSelectedYearRange={setSelectedYearRange}
                selectedMonthRange={selectedMonthRange}
                setSelectedMonthRange={setSelectedMonthRange}
                selectedComparisonYear={selectedComparisonYear}
                setSelectedComparisonYear={setSelectedComparisonYear}
                selectedComparisonMonth={selectedComparisonMonth}
                setSelectedComparisonMonth={setSelectedComparisonMonth}
                comparisonDropdownRef={comparisonDropdownRef}
                toggleComparisonDropdown={toggleComparisonDropdown}
                selectedComparison={selectedComparison}
                comparisonOptions={reportingFilterComparison}
                isComparisonDropdownOpen={isComparisonDropdownOpen}
                frequencyDropdownRef={frequencyDropdownRef}
                handleComparisonSelect={handleComparisonSelect}
                toggleFrequencyDropdown={toggleFrequencyDropdown}
                selectedFrequency={selectedFrequency}
                frequencyOptions={reportingFilterFrequency}
                isFrequencyDropdownOpen={isFrequencyDropdownOpen}
                handleFrequencySelect={handleFrequencySelect}
              />
            </div>
          </div>
          <div className='modal-bottom-cta'>
            <Link className="cancel-filter-modal" onClick={handleModalClose}>
              Cancel
            </Link>
            <button
              className='refresh-modal'
              onClick={() => {
                setSelectedDateRange(reportingFilterDateRangesConst.This_Year);
                setSelectedComparison(reportingFilterComparisonConst.Last_Year);
              }}
            >
              Refresh
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default InvoicedOutstandingGraphFilter;
