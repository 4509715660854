import React from 'react';
import Setup from "../../../components/Clients/ClientSetup/ClientSetup"

const ClentSetup = () => {
    return (
        <>
            <Setup/>
        </>
    )
}

export default ClentSetup;