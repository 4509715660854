import axios from 'axios';
import { useQuery, useMutation } from 'react-query';
import userCategory from '../../../constants/userCategory';

export const useFetchClientInvoices = (clientId, token) => {
    return useQuery(['clientInvoices', clientId], async () => {
        const response = await axios.get(`/api/client/invoices/${clientId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    });
};

export const useFetchClientExpenses = (clientId, token) => {
    return useQuery(['clientExpenses', clientId], async () => {
        const response = await axios.get(`/api/client/expenses/${clientId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    });
};

export const useFetchClientsInvoices = (token) => {
    return useQuery('clientsInvoices', async () => {
        const response = await axios.get(`/api/clients/invoices`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    });
};

export function useFetchClientProjectDetails(clientId, token) {
    return useQuery(['clientProjectDetails', clientId], async () => {
        const response = await axios.get(`/api/project-details/client/${clientId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    });
}

export function useFetchClientsProjects(token) {
    return useQuery('clientProjectDetails', async () => {
        const response = await axios.get(`/api/projects/clients`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    });
}

export const useVendorUser = (token) => {
    return useQuery('vendorUser', async () => {
        const response = await axios.get(`/api/user/user-vendors`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        const sortedTeams = response.data.sort((a, b) => {
            const nameA = a.first_name ? `${a.first_name} ${a.middle_name ? a.middle_name : ''} ${a.last_name}` : `${a.company_first_name ? a.company_first_name + (a.company_last_name && a.company_last_name) : a.company_name}`;
            const nameB = b.first_name ? `${b.first_name} ${b.middle_name ? b.middle_name : ''} ${b.last_name}` : `${b.company_first_name ? b.company_first_name + (b.company_last_name && b.company_last_name) : b.company_name}`;
            return nameA.localeCompare(nameB);
        });

        return sortedTeams;
    });
};

export const useWxCompany = (token) => {
    return useQuery('wxCompany', async () => {
        const response = await axios.get(`/api/get-wx-comapies`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        const sortedWxCompany = response.data.filter(
            (compny) =>
                compny.wx_company !== "Any"
        );

        return sortedWxCompany;
    });
};

// export function useClientView(clientId, token) {
//     return useQuery(['clientView', clientId], async () => {
//         const response = await axios.get(`/api/client-details/${clientId}`, {
//             headers: {
//                 Authorization: `Bearer ${token}`,
//             },
//         });
//         return response.data;
//     });
// }

// export function usePosition(token) {
//     return useQuery('position', async () => {
//         const response = await axios.get('/api/position-details', {
//             headers: {
//                 Authorization: `Bearer ${token}`,
//             },
//         });
//         return response.data;
//     });
// }

// export function useDeleteClientDetails(token) {
//     const mutation = useMutation((selectedId) => {
//         return axios.get(`/api/client-details/delete/${selectedId}`, {
//             headers: {
//                 Authorization: `Bearer ${token}`,
//             },
//         });
//     });

//     return mutation;
// }

// export function useUpdateClientDetails(token) {
//     const mutation = useMutation((updatedClientDetails) => {
//         return axios.put(`/api/client-details/update/${updatedClientDetails.selectedId}`, updatedClientDetails.data, {
//             headers: {
//                 Authorization: `Bearer ${token}`,
//             },
//         });
//     });

//     return mutation;
// }