import React from "react";

const TimeConverter = ({ time }) => {
  let hour = parseInt(time);
  let hours = parseInt(time);
  let minute = Math.round((time - hour) * 60);

  if (hour > 12) {
    hour -= 12;
  }

  if (minute === 0) {
    minute = "0";
  }

  return (
    <span>
      {hour < 10 ? "0" + hour : hour}:{minute < 10 ? "0" + minute : minute}
      {hours < 12 ? "am" : "pm"}
    </span>
  );
};

export default TimeConverter;
