import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  revenueClient: null,
  revenueProject: null,
  revenueYear: null,
  revenueCurrency: null,
};

export const setReportingRevenueClient = createAsyncThunk(
  "reporting/revenue/client",
  async (revenueClient) => {
    return revenueClient;
  }
);

export const setReportingRevenueProject = createAsyncThunk(
  "reporting/revenue/project",
  async (revenueProject) => {
    return revenueProject;
  }
);

export const setReportingRevenueYear = createAsyncThunk(
  "reporting/revenue/year",
  async (revenueYear) => {
    if (revenueYear !== null) {
    //   const date = startOfYear(new Date(year, 0, 1));
    //   date.setHours(0, 0, 0, 0);
      return revenueYear;
    } else {
      return null;
    }
  }
);

export const setReportingRevenueCurrency = createAsyncThunk(
  "reporting/financial/overview/currency",
  async (revenueCurrency) => {
    return revenueCurrency;
  }
);

export const reportingRevenueSlice = createSlice({
  name: "reportingRevenue",
  initialState,
  reducers: {
    reset: (state) => {
      state.revenueClient = null;
      state.revenueProject = null;
      state.revenueYear = null;
      state.revenueCurrency = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setReportingRevenueClient.fulfilled, (state, action) => {
      state.revenueClient = action.payload;
    });
    builder.addCase(setReportingRevenueProject.fulfilled, (state, action) => {
      state.revenueProject = action.payload;
    });
    builder.addCase(setReportingRevenueYear.fulfilled, (state, action) => {
      state.revenueYear = action.payload;
    });
    builder.addCase(setReportingRevenueCurrency.fulfilled, (state, action) => {
      state.revenueCurrency = action.payload;
    });
  },
});

export const { reset } = reportingRevenueSlice.actions;
export default reportingRevenueSlice.reducer;
