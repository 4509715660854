import Modal from "react-modal";
import React, { useState, useEffect, useRef } from "react";
import crssrImage from "../../../../../images/singleinvoice/cross-red.svg";
import { toast } from "react-toastify";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { ThreeDots } from "react-loader-spinner";
import { useSelector, useDispatch } from "react-redux";
import { setSprintStatusView ,setProjectAdded } from "../../../../../features/projects/projectSlice";



const StatusModal = (props) => {

       
        const dispatch = useDispatch();
        const [statusModalOpen, setStatusModalOpen] = useState(props.statusOpen);
        const [status, setStatus] = useState(null);
        const [isSubmitLoading, setIsSubmitLoading] = useState(false);
        const sprintStatusDropdownRef = useRef(null);
        const [sprintStatusDropdownOpen, setSprintStatusDropdownOpen] = useState(false);
        const { sprintStatusView, sprintData } = useSelector((state) => state.project);
        const [sprintStatus, setSprintStatus] = useState(sprintData?.sprint_status);
        const token = useSelector((state) => state.auth.token);

        useEffect(() => {

                setStatusModalOpen(true);

        }, [props.statusOpen]);

        const handleSprintStatusToggleDropdown = () => {
                setSprintStatusDropdownOpen(!sprintStatusDropdownOpen);
        };

        const handleCloseStatusModal = async () => {
                setStatusModalOpen(false);
                setStatus(null);
                setSprintStatus(null);
                setSprintStatusDropdownOpen(false);
                await dispatch(setSprintStatusView(false));
        };

        const handleSprintStatusSelectOption = (value) => {
                setSprintStatus(value);
                setSprintStatusDropdownOpen(false);
        };


        const handleStatusSubmit = async (event) => {
                event.preventDefault();
                setIsSubmitLoading(true);

                const requestData = {
                      
                        sprint_status: sprintStatus,
                      
                      };
                      try {
                        setIsSubmitLoading(true);
                        const response = await axios.put(
                          `/api/sprint-details/update/${sprintData._id}`,
                          requestData, {
                          headers: {
                            Authorization: `Bearer ${token}`,
                          },
                        }
                        );
                        const data = response.data;
                        if (data.status === "error") {
                          toast.error("Something went wrong", {
                            position: "bottom-right",
                            autoClose: 2000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                          });
                        } else {
                          toast.success("Sprint updated successfully", {
                            position: "bottom-right",
                            autoClose: 2000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                          });
                        //   global.config.activityLog(window.location.href, 'Sprint', `Sprint "${sprint}" Updated Successfully`);
                           dispatch(setProjectAdded());
                          handleCloseStatusModal();
                        }
                      } catch (error) {
                        console.log(error);
                        global.config.slackMessage(error.toString());
                        if (error.response?.data?.status === "deleted") {
                          localStorage.removeItem("user");
                        //   dispatch(logout());
                        //   dispatch(reset());
                        //   dispatch(invoiceReset());
                        //   dispatch(headerReset());
                        //   dispatch(appSettingsReset());
                          toast.error('This user is deleted!', {
                            position: "bottom-right",
                            autoClose: 1500,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                          });
                         
                          return;
                        }
                        toast.error("Something went wrong", {
                          position: "bottom-right",
                          autoClose: 2000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                        });
                        global.config.activityLog(window.location.href, 'Sprint', `Something went wrong: Sprint update`);
                      } finally {
                        setIsSubmitLoading(false); // Set isLoading to false after the approval process is complete
                      }



        };


        return (
                <>
                        <div className="modal-invite-main-outter">
                                <Modal
                                        className="client-modal sprint-modal"
                                        isOpen={sprintStatusView}
                                        onRequestClose={handleCloseStatusModal}
                                        contentLabel="Add Team-Members to the project"
                                        style={{
                                                content: {
                                                        height: "41%",
                                                        width: "30%",
                                                        position: "fixed",
                                                        top: "50%",
                                                        left: "50%",
                                                        transform: "translate(-50%, -50%)",
                                                        parent: document.querySelector(".admin-outer.time.tracker"),
                                                },
                                        }}
                                >
                                        <div className="modal">
                                                <div className="modal-close-invite outter-modal-main">
                                                        <a className="" onClick={handleCloseStatusModal}>
                                                                <img src={crssrImage} alt="Edit Icon" />{" "}
                                                        </a>
                                                </div>
                                                <div className="modal-client-head">
                                                        <h2 className="">Sprint Status</h2>
                                                </div>
                                                <div className="form-field-client-outer">
                                                        <form onSubmit={handleStatusSubmit}>

                                                                <div className="form-field-client">
                                                                        <label htmlFor="projectManager" className="form-label">
                                                                                Status
                                                                        </label>
                                                                        <div className="custom-dropdown">

                                                                                <div ref={sprintStatusDropdownRef} className={`custom-dropdown-user-new-outer custom-dropdown-select ${sprintStatusDropdownOpen ? 'open' : ''}`}>
                                                                                        <div className="selected-option custom-dropdown-user-new" onClick={handleSprintStatusToggleDropdown}>
                                                                                                {sprintStatus ? sprintStatus : sprintData?.sprint_status}
                                                                                        </div>
                                                                                        {sprintStatusDropdownOpen && (
                                                                                                <div className="custom-dropdown-new">
                                                                                                        <ul className="type-list">
                                                                                                                <li className={sprintData?.sprint_status === 'In Progress' ? 'selected-item' : ''} onClick={() => handleSprintStatusSelectOption('In Progress')}>In Progress</li>
                                                                                                                <li className={sprintData?.sprint_status === 'Cancelled' ? 'selected-item' : ''} onClick={() => handleSprintStatusSelectOption('Cancelled')}>Cancelled</li>
                                                                                                                <li className={sprintData?.sprint_status === 'Delivered' ? 'selected-item' : ''} onClick={() => handleSprintStatusSelectOption('Delivered')}>Delivered</li>
                                                                                                        </ul>
                                                                                                </div>
                                                                                        )}
                                                                                </div>
                                                                                <span className="custom-arrow"></span>
                                                                        </div>

                                                                        {/* {isUserLoading ? (
                                        <div className="outter-load-table">
                                            <ThreeDots
                                                height="38"
                                                width="40"
                                                radius="9"
                                                color="#6479f9"
                                                ariaLabel="three-dots-loading"
                                                wrapperStyle={{}}
                                                wrapperClassName=""
                                                visible={true}
                                            />
                                        </div>
                                    ) : (
                                        <Select
                                            isMulti
                                            options={projectManagerOptions}
                                            value={selectedTeamMembers}
                                            onChange={(selectedOptions) => setSelectedTeamMembers(selectedOptions)}
                                        />
                                    )} */}
                                                                </div>


                                                                {(isSubmitLoading) ?
                                                                        (
                                                                                <div className="outter-load-table">
                                                                                        <ThreeDots
                                                                                                height="38"
                                                                                                width="40"
                                                                                                radius="9"
                                                                                                color="#6479f9"
                                                                                                ariaLabel="three-dots-loading"
                                                                                                wrapperStyle={{}}
                                                                                                wrapperClassName=""
                                                                                                visible={true}
                                                                                        />
                                                                                </div>
                                                                        ) : (
                                                                                <button type="submit" className="submit-client">
                                                                                       Update
                                                                                </button>
                                                                        )}
                                                        </form>
                                                </div>
                                        </div>
                                </Modal>
                        </div>
                </>
        )



}

export default StatusModal;