
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  timeWorkedUser: null,
  timeWorkedDateRange: null,
};

export const setReportingTimeWorkedUser = createAsyncThunk(
  "reporting/timeWorked/user",
  async (timeWorkedUser) => {
    return timeWorkedUser;
  }
);

export const setReportingTimeWorkedDateRange = createAsyncThunk(
  "reporting/timeWorked/dateRange",
  async (timeWorkedDateRange) => {
    if (timeWorkedDateRange !== null && Array.isArray(timeWorkedDateRange) && timeWorkedDateRange.length === 2) {
      let [start, end] = timeWorkedDateRange;

      // Add one day to start and end dates
      start = new Date(start);
      // start.setDate(start.getDate() + 1);

      end = new Date(end);
      // end.setDate(end.getDate() + 1);

      // Set start date to start of the day
      const startOfDay = new Date(start);
      startOfDay.setHours(0, 0, 0, 0);

      // Set end date to end of the day
      const endOfDay = new Date(end);
      endOfDay.setHours(23, 59, 59, 999);

      const adjustedDateRange = [startOfDay, endOfDay];

      return adjustedDateRange;
    } else {
      return null;
    }
  }
);

export const reportingTimeWorkedSlice = createSlice({
  name: "reportingTimeWorked",
  initialState,
  reducers: {
    reset: (state) => {
      state.timeWorkedUser = null;
      state.timeWorkedDateRange = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setReportingTimeWorkedUser.fulfilled, (state, action) => {
      state.timeWorkedUser = action.payload;
    });
    builder.addCase(setReportingTimeWorkedDateRange.fulfilled, (state, action) => {
      state.timeWorkedDateRange = action.payload;
    });
  },
});

export const { reset } = reportingTimeWorkedSlice.actions;
export default reportingTimeWorkedSlice.reducer;
