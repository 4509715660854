const payStatusConsts = {
  Pending: 0,
  Payment_Processing: 1,
  Manually_Processed: 2,
  Sucessfully_Processed: 3,
  Overdue: 4,
  Rejected: 5,
  Cancelled: 6,
  Refund: 7,
};
export default payStatusConsts;

