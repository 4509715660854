import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const YearPicker = ({ selectedYear, setSelectedYear, onCancel }) => {
    const currentYear = new Date().getFullYear();
    const [year, setYear] = useState(selectedYear ? new Date(selectedYear, 0, 1) : new Date(currentYear, 0, 1));

    const handleApply = () => {
        setSelectedYear(year.getFullYear());
        onCancel();
    };

    return (
        <div className="year-picker">
            <div className="picker">
                <DatePicker
                    selected={year}
                    onChange={(date) => setYear(new Date(date.getFullYear(), 0, 1))}
                    showYearPicker
                    dateFormat="yyyy"
                />
            </div>
            <div className="buttons">
                <button onClick={handleApply}>Apply</button>
                <button onClick={onCancel}>Cancel</button>
            </div>
        </div>
    );
};

export default YearPicker;