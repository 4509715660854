import React, { useState, useEffect, useRef, createRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import "./ClientProfile.css";
import Modal from "react-modal";
import { toast } from "react-toastify";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { ThreeDots } from "react-loader-spinner";
import { RotatingLines } from "react-loader-spinner";
import { Link, useNavigate } from "react-router-dom";
import { AES, enc } from "crypto-js";
import { useParams } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import LeftArrow from "../../../images/icons/blue-left-arrow.svg";
import RoundImage from "../../../images/icons/round-image.svg";
import SingleUser from "../../../images/icons/single-user-icon.svg";
import EyeIcon from "../../../images/icons/eye-icon.svg";
import CompanyUrl from "../../../images/icons/webpage.svg";
import EmailIcon from "../../../images/icons/email.svg";
import BottomArrow from "../../../images/icons/down-arrow.svg";
import LocationIcon from "../../../images/icons/location.svg";
import PostalIcon from "../../../images/icons/postal-code.svg";
import PassportIcon from "../../../images/icons/passport.svg";
import PaymentIcon from "../../../images/icons/payment.svg";
import PhoneIcon from "../../../images/icons/phone-icon.svg";
import CostIcon from "../../../images/icons/cost1.svg";
import CompanyIcon from "../../../images/icons/company-type.svg";
import WhiteTick from "../../../images/icons/tick-arrow.svg";
import profile_image from "../../../images/user/Default_pfp.png";
import PicEdit from "../../../images/icons/profile-pic-edit.svg";
import EyeCrossed from "../../../images/icons/eye-crossed.svg";
import PasswordLock from "../../../images/icons/pass_lock.svg";
import Delete from "../../../images/icons/delete.svg";
import Currencies from "../../../constants/currenciesList";
import Countries from "../../../constants/countries";
import { setNotification } from "../../../features/notification/notificationSlice.js";
import { logout, reset } from "../../../features/auth/authSlice";
import { reset as invoiceReset } from "../../../features/invoice/invoiceSlice.js";
import { reset as headerReset } from "../../../features/headerText/headerTextSlice.js";
import { reset as appSettingsReset } from "../../../features/appSettings/appSettingsSlice.js";
import {
  useClientView,
  useDeleteClientDetails,
  usePosition,
  useUpdateClientDetails,
} from "../../TimeTracker/TimeTrackerClient/useClientDetails.jsx";

const ClientProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { encryptedId } = useParams();
  const decodedId = decodeURIComponent(encryptedId.replace(/%2F/g, "/"));
  const decryptedBytes = AES.decrypt(decodedId, "encryptionKey");
  const id = decryptedBytes.toString(enc.Utf8);
  const [uploadedImageUrl, setUploadedImageUrl] = useState("");
  const [image, setImage] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [removeProfileImage, setRemoveProfileImage] = useState(false);
  const [fileSelectIsLoading, setFileSelectIsLoading] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [clientName, setClientName] = useState("");
  const [clientNameIsValid, setClientNameIsValid] = useState(false);
  const [legalEntityName, setLegalEntityName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [accManager, setAccManager] = useState("");
  const [accManagerDropdownOpen, setAccManagerDropdownOpen] = useState(false);
  const [userSearchTerm, setUserSearchTerm] = useState("");
  const [accManagerIsValid, setAccManagerIsValid] = useState(false);
  const [contactPoint, setContactPoint] = useState("");
  const [contactPointDropdownOpen, setContactPointDropdownOpen] =
    useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  // const [contactPointSearchTerm, setContactPointSearchTerm] = useState('');
  const [contactPointIsValid, setContactPointIsValid] = useState(false);
  const [companyWebsite, setCompanyWebsite] = useState("");
  const [isValidUrl, setIsValidUrl] = useState(false);
  const [companyLogo, setCompanyLogo] = useState(null);
  const [country, setCountry] = useState("");
  const [countryIsValid, setCountryIsValid] = useState(true);
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [pincode, setPincode] = useState("");
  const [vatNumber, setVatNumber] = useState("");
  const [billingContactName, setBillingContactName] = useState("");
  const [vatPercentage, setVatPercentage] = useState("");
  const [billingContactPosition, setBillingContactPosition] = useState("");
  const [billContPosIsValid, setBillContPosIsValid] = useState(false);
  const [preferredCurrency, setPreferredCurrency] = useState("");
  const [currencyIsValid, setCurrencyIsValid] = useState(false);
  const [billingContactEmail, setBillingContactEmail] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [signatoryUserName, setSignatoryUserName] = useState("");
  const [signatoryEmail, setSignatoryEmail] = useState("");
  const [signatoryUserPosition, setSignatoryUserPosition] = useState("");
  const [signUserPosIsValid, setSignUserPosIsValid] = useState(false);
  const [signatoryMobileNo, setSignatoryMobileNo] = useState("");
  const [pocUserName, setPocUserName] = useState("");
  const [pocEmail, setPocEmail] = useState("");
  const [pocUserPosition, setPocUserPosition] = useState("");
  const [pocUserPosIsValid, setPocUserPosIsValid] = useState(false);
  const [additionalInfo, setAdditionalInfo] = useState("");
  const [countrySearchTerm, setCountrySearchTerm] = useState("");
  const [countryDropdownOpen, setCountryDropdownOpen] = useState(false);
  const [countriesList, setCountriesList] = useState([]);
  const [updatedClientProfiles, setUpdatedClientProfiles] = useState([]);
  const [isCompanyInfoOpen, setCompanyInfoOpen] = useState(true);
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [isAddressOpen, setAddressOpen] = useState(true);
  const [isBillingOpen, setBillingOpen] = useState(true);
  const passwordConfirmationRef = useRef(null);
  const [passwordMatchError, setPasswordMatchError] = useState("");
  const [isSignatoryOpen, setSignatoryOpen] = useState(true);
  const [isPointOfContactOpen, setPointOfContactOpen] = useState(true);
  const [currencyDropdownOpen, setCurrencyDropdownOpen] = useState(false);
  const [signatoryPositionDropdownOpen, setSignatoryPositionDropdownOpen] =
    useState(false);
  const [positionContactDropdownOpen, setPositionContactDropdownOpen] =
    useState(false);
  const [currency, setCurrency] = useState("");
  const [currencySearchTerm, setCurrencySearchTerm] = useState("");
  const [currencyList, setCurrencyList] = useState([]);
  const currencyDropdownRef = useRef(null);
  const clientNameRef = useRef(null);
  const compWebsiteRef = useRef(null);
  const countryRef = useRef(null);
  const billContPosRef = useRef(null);
  const currencyRef = useRef(null);
  const signUserPosRef = useRef(null);
  const pocUserPosRef = useRef(null);
  const accManagerRef = useRef(null);
  const accManagerSelectDropdownRef = useRef(null);
  const contactPointRef = useRef(null);
  const contactPointSelectDropdownRef = useRef(null);
  const signatoryPositionDropdownRef = useRef(null);
  const positionContactDropdownRef = useRef(null);
  const [passwordError, setPasswordError] = useState("");
  const countryDropdownRef = useRef(null);
  const cropperRef = createRef();
  const passwordRef = useRef(null);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);
  const [isSecurityOpen, setIsSecurityOpen] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");

  const [paymentMethodDropdownOpen, setPaymentMethodDropdownOpen] =
    useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const paymentMethodDropdownRef = useRef(null);
  const fileInputRef = useRef(null);
  const user = useSelector((state) => state.auth.user);
  const { appSettings } = useSelector((state) => state.appSettings);
  let token = null;
  if (user) {
    if (user && user.token) {
      token = user.token;
    }
  }

  const {
    data: clientView = [],
    isLoading,
    refetch: refetchClientProfiles,
    isError: clientViewError,
  } = useClientView(id, token);
  const {
    data: position = [],
    isLoading: positionLoading,
    isError,
  } = usePosition(token);
  const deleteClientMutation = useDeleteClientDetails(token);

  useEffect(() => {
    if (!isLoading && clientView) {
      setClientName(clientView.client_name);
      if (clientView.legal_entity_name) {
        setLegalEntityName(clientView.legal_entity_name);
      } else {
        setLegalEntityName("");
      }
      setEmail(clientView.email);
      setMobileNo(clientView.mobile_no);
      if (clientView.acc_manager) {
        setAccManager(clientView.acc_manager._id);
      } else {
        setAccManager("");
      }
      setUserSearchTerm("");
      setAccManagerDropdownOpen(false);
      setAccManagerIsValid(false);
      if (clientView.contact_point) {
        setContactPoint(clientView.contact_point._id);
      } else {
        setContactPoint(null);
      }
      setContactPointDropdownOpen(false);
      setContactPointIsValid(false);
      setAddress(clientView.address);
      setCountry(clientView.country);
      if (clientView.city) {
        setCity(clientView.city);
      } else {
        setCity("");
      }
      if (clientView.pincode) {
        setPincode(clientView.pincode);
      } else {
        setPincode("");
      }
      if (clientView.vat_number) {
        setVatNumber(clientView.vat_number);
      } else {
        setVatNumber("");
      }
      if (clientView.billing_contact_name) {
        setBillingContactName(clientView.billing_contact_name);
      } else {
        setBillingContactName("");
      }
      if (clientView.vat_percentage) {
        setVatPercentage(clientView.vat_percentage);
      } else {
        setVatPercentage("");
      }
      if (clientView.billing_contact_position) {
        setBillingContactPosition(clientView.billing_contact_position);
      } else {
        setBillingContactPosition("");
      }
      if (clientView.preferred_currency) {
        setPreferredCurrency(clientView.preferred_currency);
      } else {
        setPreferredCurrency("");
      }
      if (clientView.billing_contact_email) {
        setBillingContactEmail(clientView.billing_contact_email);
      } else {
        setBillingContactEmail("");
      }
      if (clientView.payment_method) {
        setPaymentMethod(clientView.payment_method);
      } else {
        setPaymentMethod("");
      }
      if (clientView.company_website) {
        setCompanyWebsite(clientView.company_website);
      } else {
        setCompanyWebsite("");
      }
      if (clientView.signatory_user_name) {
        setSignatoryUserName(clientView.signatory_user_name);
      } else {
        setSignatoryUserName("");
      }
      if (clientView.signatory_email) {
        setSignatoryEmail(clientView.signatory_email);
      } else {
        setSignatoryEmail("");
      }
      if (clientView.signatory_user_position) {
        setSignatoryUserPosition(clientView.signatory_user_position);
      } else {
        setSignatoryUserPosition("");
      }
      if (clientView.signatory_mobile_no) {
        setSignatoryMobileNo(clientView.signatory_mobile_no);
      } else {
        setSignatoryMobileNo("");
      }
      if (clientView.poc_user_name) {
        setPocUserName(clientView.poc_user_name);
      } else {
        setPocUserName("");
      }
      if (clientView.poc_email) {
        setPocEmail(clientView.poc_email);
      } else {
        setPocEmail("");
      }
      if (clientView.poc_user_position) {
        setPocUserPosition(clientView.poc_user_position);
      } else {
        setPocUserPosition("");
      }
      // setCompanyLogoName(clientView.company_logo);
      setAdditionalInfo(clientView.additional_info);
    }
  }, [isLoading, clientView]);

  const handleProfilePicChange = () => {
    setFileSelectIsLoading(true);
    fileInputRef.current.click();
  };
  const handleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleFileInputCancel = () => {
    // Handle the case where the file selection window is canceled
    setFileSelectIsLoading(false);
  };

  // Attach event listeners to the file input
  useEffect(() => {
    const inputElement = fileInputRef.current;

    const handleChange = () => {
      handleFileInputChange({ target: { files: inputElement.files } });
    };

    const handleCancel = () => {
      handleFileInputCancel();
    };

    inputElement.addEventListener("change", handleChange);
    inputElement.addEventListener("cancel", handleCancel);

    return () => {
      inputElement.removeEventListener("change", handleChange);
      inputElement.removeEventListener("cancel", handleCancel);
    };
  }, []);

  const handleFileInputChange = (event) => {
    setFileSelectIsLoading(false);
    const file = event.target.files[0];
    setCompanyLogo(event.target.files[0]);
    if (file) {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        setUploadedImageUrl(fileReader.result);
        // setModalIsOpen(true);
      };
      // if (removeProfileImage) {
      setRemoveProfileImage(false);
      setDropdownVisible(false);
      // }
      fileReader.readAsDataURL(file);
    }
  };

  const closeModal = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    setFileSelectIsLoading(false);
    setModalIsOpen(false);
  };
  const handleDelete = () => {
    setIsDeleteModalOpen(true);
  };
  const handleConfirmDelete = async () => {
    try {
      setIsSubmitLoading(true);
      await deleteClientMutation.mutateAsync(id);
      toast.success("Client removed successfully", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      global.config.activityLog(
        window.location.href,
        "Client",
        `Client (${id}) deleted successfully`
      );
      // handleCloseModal();
      refetchClientProfiles();
      navigate("/client/overview");
    } catch (error) {
      console.error(error);
      global.config.slackMessage(error.toString());
      if (error.response?.data?.status === "deleted") {
        localStorage.removeItem("user");
        dispatch(logout());
        dispatch(reset());
        dispatch(invoiceReset());
        dispatch(headerReset());
        dispatch(appSettingsReset());
        toast.error("This user is deleted!", {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/");
        return;
      }
      toast.error("Something went wrong", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } finally {
      setIsSubmitLoading(false);
    }
    setIsDeleteModalOpen(false);
  };
  const handleCancelDelete = () => {
    setIsDeleteModalOpen(false);
  };
  const handleCropClick = () => {
    if (typeof cropperRef.current?.cropper !== "undefined") {
      const croppedImage = cropperRef.current?.cropper
        .getCroppedCanvas()
        .toDataURL();
      setUploadedImageUrl(croppedImage);
      setCompanyLogo(croppedImage);
    }
    closeModal();
  };

  // Function to resize the image
  const resizeImage = async (base64Image, maxWidth) => {
    return new Promise((resolve) => {
      const image = new Image();
      image.src = base64Image;

      image.onload = () => {
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
        const aspectRatio = image.width / image.height;
        const newHeight = maxWidth / aspectRatio;

        canvas.width = maxWidth;
        canvas.height = newHeight;

        context.drawImage(image, 0, 0, maxWidth, newHeight);

        const resizedImage = canvas.toDataURL("image/jpeg"); // Change to 'image/png' if needed
        resolve(resizedImage);
      };
    });
  };

  const togglePaymentMethodDropdown = () => {
    setPaymentMethodDropdownOpen(!paymentMethodDropdownOpen);
  };
  const selectPaymentMethodOption = (selectedPaymentMethod) => {
    setPaymentMethod(selectedPaymentMethod);
    setPaymentMethodDropdownOpen(false);
  };

  const [billingContactDropdownOpen, setBillingContactDropdownOpen] =
    useState(false);
  const [selectedBillingContact, setSelectedBillingContact] = useState(null);
  const billingContactDropdownRef = useRef(null);
  const toggleBillingContactDropdown = () => {
    setBillingContactDropdownOpen(!billingContactDropdownOpen);
  };

  const selectBillingContactOption = (selectedBillingContact) => {
    setSelectedBillingContact(selectedBillingContact);
    setBillingContactDropdownOpen(false);
  };

  const [selectedPosition, setSelectedPosition] = useState(null);

  const toggleSignatoryPositionDropdown = () => {
    setSignatoryPositionDropdownOpen(!signatoryPositionDropdownOpen);
  };

  const selectPositionOption = (selectedPosition) => {
    setSignatoryUserPosition(selectedPosition);
    setSignatoryPositionDropdownOpen(false);
  };

  const togglePositionContactDropdown = () => {
    setPositionContactDropdownOpen(!positionContactDropdownOpen);
  };

  const selectPositionContactOption = (selectedPositionContact) => {
    setPocUserPosition(selectedPositionContact);
    setPositionContactDropdownOpen(false);
  };

  useEffect(() => {
    setCurrencyList(Currencies.sourceCurrencies[0].targetCurrencies);
    const sortedCountries = Countries.slice().sort((a, b) =>
      a.country.localeCompare(b.country)
    );
    setCountriesList(sortedCountries);
  }, []);

  const toggleCompanyInfoAccordion = () => {
    setCompanyInfoOpen(!isCompanyInfoOpen);
  };

  const toggleAddressAccordion = () => {
    setAddressOpen(!isAddressOpen);
  };

  const toggleBillingAccordion = () => {
    setBillingOpen(!isBillingOpen);
  };

  const toggleSignatoryAccordion = () => {
    setSignatoryOpen(!isSignatoryOpen);
  };

  const togglePointOfContactAccordion = () => {
    setPointOfContactOpen(!isPointOfContactOpen);
  };

  const handleCurrencySearchInputChange = (e) => {
    setCurrencySearchTerm(e.target.value);
  };

  const filteredCurrencies = currencyList.filter((currency) => {
    const currencyName =
      currency.currencyCode &&
      `${currency.currencyCode} (${currency.currencyName})`;
    return currencyName
      ?.toLowerCase()
      .includes(currencySearchTerm?.toLowerCase());
  });

  const handleCountryChange = (selectedCountry) => {
    setCountry(selectedCountry);
    setCountryDropdownOpen(false);
  };

  const handleCountrySearchInputChange = (e) => {
    setCountrySearchTerm(e.target.value);
  };

  const filteredCountries = countriesList.filter((country) => {
    return country.country
      ?.toLowerCase()
      .includes(countrySearchTerm?.toLowerCase());
  });

  const handleToggleCountryDropdown = () => {
    setCountrySearchTerm("");
    setCountryDropdownOpen(!countryDropdownOpen);
  };
  const toggleSecurityAccordion = () => {
    setIsSecurityOpen(!isSecurityOpen);
  };

  const handleClickOutsideDropdown = (event) => {
    const isClickInsideCountryDropdown =
      countryDropdownRef.current &&
      countryDropdownRef.current.contains(event.target);
    const isClickInsideCurrencyDropdown =
      currencyDropdownRef.current &&
      currencyDropdownRef.current.contains(event.target);
    const isClickInsidePaymentMethodDropdown =
      paymentMethodDropdownRef.current &&
      paymentMethodDropdownRef.current.contains(event.target);
    const isClickInsideBillingContactDropdown =
      billingContactDropdownRef.current &&
      billingContactDropdownRef.current.contains(event.target);
    const isClickInsideSignatoryPositionDropdown =
      signatoryPositionDropdownRef.current &&
      signatoryPositionDropdownRef.current.contains(event.target);
    const isClickInsidePositionContactDropdown =
      positionContactDropdownRef.current &&
      positionContactDropdownRef.current.contains(event.target);

    if (!isClickInsideCountryDropdown) {
      setCountryDropdownOpen(false);
    }
    if (!isClickInsideCurrencyDropdown) {
      setCurrencyDropdownOpen(false);
    }
    if (!isClickInsidePaymentMethodDropdown) {
      setPaymentMethodDropdownOpen(false);
    }
    if (!isClickInsideBillingContactDropdown) {
      setBillingContactDropdownOpen(false);
    }
    if (!isClickInsideSignatoryPositionDropdown) {
      setSignatoryPositionDropdownOpen(false);
    }
    if (!isClickInsidePositionContactDropdown) {
      setPositionContactDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutsideDropdown);
    return () => {
      document.removeEventListener("click", handleClickOutsideDropdown);
    };
  }, []);

  const StatusChangeActive = async (id) => {
    try {
      setIsSubmitLoading(true);
      await axios.get(`/api/client/status-change/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      toast.success(
        `${clientView && clientView.client_name
          ? clientView.client_name
          : clientView.legal_entity_name
        }'s account has been deactivated successfully!`,
        {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      global.config.activityLog(
        window.location.href,
        "User",
        `${clientView && clientView.client_name
          ? clientView.client_name
          : clientView.legal_entity_name
        }'s account has been deactivated successfully!`
      );
      dispatch(setNotification(Date.now()));
      refetchClientProfiles();
    } catch (error) {
      console.log("Something went wrong while deactivating client: ", error);
      toast.error(
        `Something went wrong while deactivating ${clientView && clientView.client_name
          ? clientView.client_name
          : clientView.legal_entity_name
        }'s account`,
        {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      global.config.activityLog(
        window.location.href,
        "User",
        `Something went wrong while deactivating ${clientView && clientView.client_name
          ? clientView.client_name
          : clientView.legal_entity_name
        }'s account!`
      );
      global.config.slackMessage(error.toString());
      console.log(error);
      if (error.response?.data?.status === "deleted") {
        localStorage.removeItem("user");
        dispatch(logout());
        dispatch(reset());
        dispatch(invoiceReset());
        dispatch(headerReset());
        dispatch(appSettingsReset());
        toast.error("This user is deleted!", {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/");
        return;
      }
    } finally {
      setIsSubmitLoading(false);
    }
  };

  const StatusChangeInactive = async (id) => {
    try {
      setIsSubmitLoading(true);
      await axios.get(`/api/client/status-change/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      toast.success(
        `${clientView && clientView.client_name
          ? clientView.client_name
          : clientView.legal_entity_name
        }'s account has been activated successfully!`,
        {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      dispatch(setNotification(Date.now()));
      global.config.activityLog(
        window.location.href,
        "User",
        `${clientView && clientView.client_name
          ? clientView.client_name
          : clientView.legal_entity_name
        }'s account has been activated successfully!`
      );
      refetchClientProfiles();
    } catch (error) {
      console.log("Something went wrong while activating user: ", error);
      toast.error("Something went wrong in changing user status!",
        {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      global.config.slackMessage(error.toString());
      global.config.activityLog(
        window.location.href,
        "User",
        `Something went wrong while activating ${clientView && clientView.client_name
          ? clientView.client_name
          : clientView.legal_entity_name
        }'s account!`
      );
      console.log(error);
      if (error.response?.data?.status === "deleted") {
        localStorage.removeItem("user");
        dispatch(logout());
        dispatch(reset());
        dispatch(invoiceReset());
        dispatch(headerReset());
        dispatch(appSettingsReset());
        toast.error("This user is deleted!", {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/");
        return;
      }
    } finally {
      setIsSubmitLoading(false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setAccManagerIsValid(false);
    setContactPointIsValid(false);
    setIsValidUrl(false);
    const pattern = /^(ftp|http|https):\/\/[^ "]+$/;
    if (
      // accManager === "" ||
      // contactPoint === "" ||
      clientName === "" ||
      !country ||
      billingContactPosition === "" ||
      preferredCurrency === "" ||
      signatoryUserPosition === "" ||
      pocUserPosition === ""
    ) {
      // if (accManager === "") {
      //   setAccManagerIsValid(true);
      //   accManagerRef && accManagerRef.current.focus();
      //   return;
      // } else if (contactPoint === "") {
      //   setContactPointIsValid(true);
      //   contactPointRef && contactPointRef.current.focus();
      //   return;
      // }
      if (clientName === "") {
        setClientNameIsValid(true);
        clientNameRef && clientNameRef.current.focus();
        return;
      } else if (country === "") {
        setCountryIsValid(true);
        countryRef && countryRef.current.focus();
        return;
      } else if (billingContactPosition === "") {
        setBillContPosIsValid(true);
        billContPosRef && billContPosRef.current.focus();
        return;
      } else if (preferredCurrency === "") {
        setCurrencyIsValid(true);
        currencyRef && currencyRef.current.focus();
        return;
      } else if (signatoryUserPosition === "") {
        setSignUserPosIsValid(true);
        signUserPosRef && signUserPosRef.current.focus();
        return;
      } else if (pocUserPosition === "") {
        setPocUserPosIsValid(true);
        pocUserPosRef && pocUserPosRef.current.focus();
        return;
      }

      toast.error("Please fill all required fields!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    if (companyWebsite && !pattern.test(companyWebsite)) {
      toast.error("It's not a valid URL.", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setIsValidUrl(true);
      compWebsiteRef && compWebsiteRef.current.focus();
      return;
    }

    if (clientView.preferred_currency !== preferredCurrency) {

      toast.warning("Currency update will effect all related areas.", {
        position: "bottom-right",
        autoClose: 7000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

    }

    const requestData = {
      client_name: clientName,
      legal_entity_name: legalEntityName,
      mobile_no: mobileNo,
      address,
      country,
      // acc_manager: accManager,
      // contact_point: contactPoint,
      city,
      pincode,
      vat_number: vatNumber,
      billing_contact_name: billingContactName,
      vat_percentage: vatPercentage,
      billing_contact_position: billingContactPosition,
      preferred_currency: preferredCurrency,
      billing_contact_email: billingContactEmail,
      payment_method: paymentMethod,
      company_website: companyWebsite,
      signatory_user_name: signatoryUserName,
      signatory_email: signatoryEmail,
      signatory_user_position: signatoryUserPosition,
      signatory_mobile_no: signatoryMobileNo,
      poc_user_name: pocUserName,
      poc_email: pocEmail,
      poc_user_position: pocUserPosition,
      additional_info: additionalInfo,
      password
    };

    const formData = new FormData();
    formData.append("company_logo", companyLogo);
    for (let key in requestData) {
      formData.append(key, requestData[key]);
    }
    if (removeProfileImage) {
      formData.append("removeProfileImage", true); // Add removeProfileImage flag
    }
    try {
      setIsSubmitLoading(true);
      await axios.put(`/api/client-details/update/${id}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      toast.success("Client updated successfully", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      // handleCloseModal();
      global.config.activityLog(
        window.location.href,
        "Client",
        `Client (${clientName}) updated successfully`
      );
      refetchClientProfiles();
    } catch (error) {
      console.log(error);
      global.config.slackMessage(error.toString());
      if (error.response?.data?.status === "deleted") {
        localStorage.removeItem("user");
        dispatch(logout());
        dispatch(reset());
        dispatch(invoiceReset());
        dispatch(headerReset());
        dispatch(appSettingsReset());
        toast.error("This user is deleted!", {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/");
        return;
      }
      alert("Something went wrong");
    } finally {
      setIsSubmitLoading(false); // Set isLoading to false after the approval process is complete
    }
  };

  const numberInputOnWheelPreventChange = (e) => {
    // Prevent the input value change
    e.target.blur();

    // Prevent the page/container scrolling
    e.stopPropagation();

    setTimeout(() => {
      e.target.focus();
    }, 0);
  };

  return (
    <>
      <div className="text-arrow-outer text-arrow-outer-delete">
        <div className="text-arrow-inner">
          <Link
            to={`/client/details/${encodeURIComponent(
              AES.encrypt(id, "encryptionKey").toString().replace(/\//g, "%2F")
            )}`}
          >
            <div className="text-arrow">
              <div className="left-arrow">
                <img src={LeftArrow} alt="left-arrow" />
              </div>
              <div className="top-text">Back to Overview</div>
            </div>
          </Link>
        </div>
        <div className="delete-cliet">
          <div className="invite-client-outer">
            <button
              type="button"
              className="delete-client"
              onClick={handleDelete}
              disabled={isSubmitLoading}
            >
              <img src={Delete} alt="Delete" />{" "}
              {global.config.locate(
                appSettings && appSettings.language,
                "Delete"
              )}
            </button>
            <Modal
              className="delete-modal"
              isOpen={isDeleteModalOpen}
              onRequestClose={handleCancelDelete}
              contentLabel="Confirm Delete"
              style={{
                content: {
                  height: "41%",
                  width: "30%",
                  position: "fixed",
                  top: "36%",
                  left: "50%",
                  transform: "translate(-19.75%, 0%)",
                  parent: document.querySelector(".admin-outer.time.tracker"),
                },
              }}
            >
              <div className="delete-modal-content">
                <h2>
                  {global.config.locate(
                    appSettings && appSettings.language,
                    "Confirm_Delete"
                  )}
                </h2>
                <p>
                  {global.config.locate(
                    appSettings && appSettings.language,
                    "Are you sure you want to delete this client?"
                  )}
                </p>
                <div className="delete-modal-buttons">
                  <button
                    className="buttons-rejected"
                    onClick={handleConfirmDelete}
                    disabled={isSubmitLoading}
                  >
                    {isSubmitLoading ? (
                      <div className="outter-load-table">
                        <ThreeDots
                          height="18"
                          width="20"
                          radius="9"
                          color="white"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClassName=""
                          visible={true}
                        />
                      </div>
                    ) : (
                      global.config.locate(
                        appSettings && appSettings.language,
                        "Delete Client"
                      )
                    )}
                  </button>
                  <button
                    onClick={handleCancelDelete}
                    disabled={isSubmitLoading}
                  >
                    {global.config.locate(
                      appSettings && appSettings.language,
                      "Cancel"
                    )}
                  </button>
                </div>
              </div>
            </Modal>
          </div>
        </div>
      </div>

      <form onSubmit={handleSubmit}>
        <div className="client-name-section">
          <div className="client-image">
            <div className="profile-photo-sec">
              {((clientView &&
                clientView.company_logo !== "null" &&
                clientView.company_logo !== null) ||
                uploadedImageUrl) &&
                !isLoading &&
                !removeProfileImage ? (
                <div className="profile-photo-sec-edit-outer">
                  <div
                    className="pic-edit picart"
                    onClick={!fileSelectIsLoading && handleDropdown}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="29"
                      height="29"
                      viewBox="0 0 29 29"
                      fill="none"
                    >
                      <circle cx="14.5" cy="14.5" r="14.5" fill="#6479F8" />
                      <path
                        d="M22.7194 10.1524L19.2559 6.66847C19.2063 6.61849 19.1405 6.59104 19.0701 6.59104C18.9998 6.59104 18.9344 6.6185 18.8847 6.66848L16.8328 8.73279L16.8303 8.74018L16.7894 8.77644L8.86392 16.7497C8.82719 16.7866 8.80235 16.8331 8.7922 16.8838L7.91485 21.2513C7.89736 21.3382 7.9243 21.4273 7.98658 21.4903C8.03626 21.5399 8.10204 21.5673 8.17236 21.5673C8.18986 21.5673 8.207 21.5656 8.2231 21.5624L12.5654 20.6792C12.6164 20.669 12.6623 20.644 12.6987 20.6074L22.7194 10.5259C22.7691 10.4762 22.7961 10.4097 22.7961 10.339C22.7961 10.2682 22.7688 10.2021 22.7194 10.1524ZM12.3831 20.1777L8.50718 20.9662L9.29043 17.0668L10.4345 15.9158L13.5271 19.0271L12.3831 20.1777ZM13.898 18.6536L10.8053 15.5427L16.9801 9.33077L20.0724 12.4417L13.8976 18.6536L13.898 18.6536ZM20.4439 12.0682L17.3516 8.95733L19.0702 7.22844L22.1628 10.3393L20.4439 12.0682Z"
                        fill="white"
                        stroke="white"
                        strokeWidth="0.4"
                      />
                    </svg>
                  </div>

                  {dropdownVisible && (
                    <div className="dropdown-edit-profile" ref={dropdownRef}>
                      {/* <button onClick={handleUploadPhoto}>Upload Photo</button> */}
                      <div className="profile-pic-edit">
                        <Link
                          className="pic-edit"
                          onClick={
                            !fileSelectIsLoading && handleProfilePicChange
                          }
                        >
                          {fileSelectIsLoading ? (
                            <RotatingLines
                              strokeColor="grey"
                              strokeWidth="5"
                              animationDuration="0.75"
                              width="18"
                              visible={true}
                            />
                          ) : (
                            <span>Upload Photo</span>
                          )}
                        </Link>
                      </div>
                      {/* <button onClick={!fileSelectIsLoading && handleProfilePicChange}>Delete Photo</button> */}
                      <div className="remove-pic">
                        <label for="delete1">
                          <input
                            type="checkbox"
                            checked={removeProfileImage}
                            onChange={() =>
                              setRemoveProfileImage(!removeProfileImage)
                            }
                            id="delete1"
                            hidden
                          />
                          <span>Delete Photo</span>
                        </label>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div className="profile-pic-edit">
                  <Link
                    className="pic-edit"
                    onClick={!fileSelectIsLoading && handleProfilePicChange}
                  >
                    {fileSelectIsLoading ? (
                      <RotatingLines
                        strokeColor="grey"
                        strokeWidth="5"
                        animationDuration="0.75"
                        width="18"
                        visible={true}
                      />
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="29"
                        height="29"
                        viewBox="0 0 29 29"
                        fill="none"
                      >
                        <circle cx="14.5" cy="14.5" r="14.5" fill="#6479F8" />
                        <path
                          d="M22.7194 10.1524L19.2559 6.66847C19.2063 6.61849 19.1405 6.59104 19.0701 6.59104C18.9998 6.59104 18.9344 6.6185 18.8847 6.66848L16.8328 8.73279L16.8303 8.74018L16.7894 8.77644L8.86392 16.7497C8.82719 16.7866 8.80235 16.8331 8.7922 16.8838L7.91485 21.2513C7.89736 21.3382 7.9243 21.4273 7.98658 21.4903C8.03626 21.5399 8.10204 21.5673 8.17236 21.5673C8.18986 21.5673 8.207 21.5656 8.2231 21.5624L12.5654 20.6792C12.6164 20.669 12.6623 20.644 12.6987 20.6074L22.7194 10.5259C22.7691 10.4762 22.7961 10.4097 22.7961 10.339C22.7961 10.2682 22.7688 10.2021 22.7194 10.1524ZM12.3831 20.1777L8.50718 20.9662L9.29043 17.0668L10.4345 15.9158L13.5271 19.0271L12.3831 20.1777ZM13.898 18.6536L10.8053 15.5427L16.9801 9.33077L20.0724 12.4417L13.8976 18.6536L13.898 18.6536ZM20.4439 12.0682L17.3516 8.95733L19.0702 7.22844L22.1628 10.3393L20.4439 12.0682Z"
                          fill="white"
                          stroke="white"
                          strokeWidth="0.4"
                        />
                      </svg>
                    )}
                  </Link>
                </div>
              )}

              {isLoading ? (
                <div>
                  <ThreeDots
                    height="18"
                    width="20"
                    radius="9"
                    color="#6479f9"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                </div>
              ) : removeProfileImage ? (
                <div className="profile-image">
                  {/* Display a default placeholder image */}
                  <img src={profile_image} alt="Default Profile" />
                </div>
              ) : uploadedImageUrl ? (
                <div className="profile-image">
                  <img src={uploadedImageUrl} alt="Uploaded Profile " />
                </div>
              ) : (
                <div className="profile-image">
                  {clientView &&
                    clientView.company_logo !== "null" &&
                    clientView.company_logo !== null ? (
                    <img
                      src={`/uploads/clientCompyLogo/lowQuality/${clientView.company_logo}`}
                      alt="User Profile "
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src =
                          "/uploads/clientCompyLogo/lowQuality/DefaultCompLogo.png";
                      }}
                    />
                  ) : (
                    <img src={profile_image} alt="User Profile " />
                  )}
                </div>
              )}
              {isLoading ? (
                <ThreeDots
                  height="18"
                  width="20"
                  radius="9"
                  color="#6479f9"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
              ) : (
                <div className="profile-pic-edit" style={{ display: "none" }}>
                  <Link
                    className="pic-edit"
                    onClick={!fileSelectIsLoading && handleProfilePicChange}
                  >
                    {fileSelectIsLoading ? (
                      <RotatingLines
                        strokeColor="grey"
                        strokeWidth="5"
                        animationDuration="0.75"
                        width="18"
                        visible={true}
                      />
                    ) : (
                      <img src={PicEdit} alt="edit" />
                    )}
                  </Link>
                </div>
              )}
            </div>
          </div>
          <div className="client-right">
            {isLoading ? (
              <div>
                <ThreeDots
                  height="18"
                  width="20"
                  radius="9"
                  color="#6479f9"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
              </div>
            ) : (
              <div className="client-text">
                {clientView.client_name && clientView.client_name}
              </div>
            )}
            {isLoading ? (
              <div>
                <ThreeDots
                  height="18"
                  width="20"
                  radius="9"
                  color="#6479f9"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
              </div>
            ) : (
              <>
                {clientView && clientView.is_active === false ? (
                  <div
                    className="inactive-text inactive"
                    onClick={() =>
                      !isSubmitLoading && StatusChangeInactive(clientView._id)
                    }
                    style={{ cursor: "pointer" }}
                  >
                    {isSubmitLoading ? (
                      <div className="outter-load-table">
                        <ThreeDots
                          height="18"
                          width="18"
                          radius="9"
                          color="#405BFF"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClassName=""
                          visible={true}
                        />
                      </div>
                    ) : (
                      "Inactive"
                    )}
                  </div>
                ) : clientView.is_active === true ? (
                  <div
                    className="active-text"
                    onClick={() =>
                      !isSubmitLoading && StatusChangeActive(clientView._id)
                    }
                    style={{ cursor: "pointer" }}
                  >
                    {isSubmitLoading ? (
                      <div className="outter-load-table">
                        <ThreeDots
                          height="18"
                          width="18"
                          radius="9"
                          color="#405BFF"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClassName=""
                          visible={true}
                        />
                      </div>
                    ) : (
                      "Active"
                    )}
                  </div>
                ) : (
                  clientView.is_active
                )}
              </>
            )}
          </div>
        </div>
        <input
          type="file"
          accept="image/*"
          style={{ display: "none" }}
          ref={fileInputRef}
        // onChange={handleFileInputChange}
        />
        <Modal
          isOpen={modalIsOpen}
          // onRequestClose={() => setModalIsOpen(false)}
          contentLabel="Image Crop Modal"
          style={{
            content: {
              height: "500%",
              width: "1000%",
              position: "fixed",
              top: "15%",
              left: "50%",
              transform: "translate(-17.5%, 0%)",
              parent: document.querySelector(".admin-outer.time.tracker"),
            },
          }}
        >
          <div>
            {image && (
              <Cropper
                ref={cropperRef}
                style={{ height: 400, width: "100%" }}
                zoomTo={0}
                initialAspectRatio={1}
                preview=".img-preview"
                src={image}
                viewMode={1}
                minCropBoxHeight={10}
                minCropBoxWidth={10}
                background={false}
                responsive={true}
                autoCropArea={100}
                checkOrientation={false}
                guides={true}
              />
            )}
          </div>
          <div>
            <div className="crop-btn">
              <button onClick={handleCropClick}>Crop and Upload</button>
              <button className="delete-client" onClick={closeModal}>
                Cancel
              </button>
            </div>
          </div>
        </Modal>

        <div className="accordion-client-outer">
          <div
            className="header-client-accordion"
            onClick={toggleCompanyInfoAccordion}
          >
            <span>Company Information</span>
            <img src={BottomArrow} alt="bottom-arrow" />
          </div>

          {isCompanyInfoOpen && (
            <div className="company-information-section">
              <div className="left-section">
                <div className="outer-section">
                  <div className="text-icon">
                    <div className="icon-image">
                      <img src={SingleUser} alt="single-user" />
                    </div>
                    <span>Commercial Name *</span>
                  </div>
                  {isLoading ? (
                    <ThreeDots
                      height="18"
                      width="20"
                      radius="9"
                      color="#6479f9"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  ) : (
                    <div className="text-section">
                      <input
                        type="text"
                        id="compname"
                        className={`${clientNameIsValid} ? "input-validation-error" : ""`}
                        value={clientName}
                        onChange={(e) => setClientName(e.target.value)}
                        ref={clientNameRef}
                      />
                    </div>
                  )}
                </div>
                <div className="outer-section">
                  <div className="text-icon">
                    <div className="icon-image">
                      <img src={SingleUser} alt="single-user" />
                    </div>

                    <span>Legal Entity Name *</span>
                  </div>
                  {isLoading ? (
                    <div>
                      <ThreeDots
                        height="18"
                        width="20"
                        radius="9"
                        color="#6479f9"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                      />
                    </div>
                  ) : (
                    <div className="text-section">
                      <input
                        type="text"
                        className="type-field"
                        value={legalEntityName}
                        onChange={(e) => setLegalEntityName(e.target.value)}
                        required
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="right-section">
                <div className="outer-section">
                  <div className="text-icon">
                    <div className="icon-image">
                      <img src={EmailIcon} alt="email-icon" />
                    </div>

                    <span>Email *</span>
                  </div>
                  {isLoading ? (
                    <ThreeDots
                      height="18"
                      width="20"
                      radius="9"
                      color="#6479f9"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  ) : (
                    <div className="text-section">
                      <input
                        type="email"
                        className="read-only-input"
                        value={clientView.email}
                        required
                        readOnly
                      />
                    </div>
                  )}
                </div>
                <div className="outer-section">
                  <div className="text-icon">
                    <div className="icon-image">
                      <img src={CompanyUrl} alt="company-url" />
                    </div>
                    <span>Company Url *</span>
                  </div>
                  {isLoading ? (
                    <ThreeDots
                      height="18"
                      width="20"
                      radius="9"
                      color="#6479f9"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  ) : (
                    <div className="text-section">
                      <input
                        type="text"
                        className="type-field"
                        value={companyWebsite}
                        onChange={(e) => setCompanyWebsite(e.target.value)}
                        ref={compWebsiteRef}
                        required
                      />
                    </div>
                  )}
                  {isValidUrl && (
                    <span className="text-red-500 text-xs italic">
                      Please enter a valid URL!!
                    </span>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="accordion-client-outer">
          <div
            className="header-client-accordion"
            onClick={toggleAddressAccordion}
          >
            <span>Address</span>
            <img src={BottomArrow} alt="bottom-arrow" />
          </div>

          {isAddressOpen && (
            <div className="company-information-section">
              <div className="left-section">
                <div className="outer-section">
                  <div className="text-icon">
                    <div className="icon-image">
                      <img src={LocationIcon} alt="location-icon" />
                    </div>

                    <span>City *</span>
                  </div>
                  {isLoading ? (
                    <ThreeDots
                      height="18"
                      width="20"
                      radius="9"
                      color="#6479f9"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  ) : (
                    <div className="text-section">
                      <input
                        type="text"
                        className="type-field"
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                        required
                      />
                    </div>
                  )}
                </div>
                <div className="outer-section">
                  <div className="text-icon">
                    <div className="icon-image">
                      <img src={LocationIcon} alt="location-icon" />
                    </div>

                    <span>Country *</span>
                  </div>
                  {isLoading ? (
                    <ThreeDots
                      height="18"
                      width="20"
                      radius="9"
                      color="#6479f9"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  ) : (
                    <div className="text-section">
                      <div
                        className={`custom-dropdown-user-new-outer custom-dropdown-select ${countryDropdownOpen ? "open" : ""
                          }`}
                        ref={countryDropdownRef}
                      >
                        <div
                          className={`selected-option custom-dropdown-user-new ${countryIsValid} ? "input-validation-error" : ""`}
                          onClick={handleToggleCountryDropdown}
                          style={{ cursor: "pointer" }}
                          ref={countryRef}
                        >
                          {country
                            ? countriesList.find((c) => c.country === country)
                              ?.country
                            : "Select Country"}
                        </div>
                        {countryDropdownOpen && (
                          <div className="custom-dropdown-new">
                            <input
                              type="text"
                              className="search-bar"
                              placeholder="Search..."
                              value={countrySearchTerm}
                              onChange={handleCountrySearchInputChange}
                            />
                            <ul className="country-list">
                              {filteredCountries.length > 0 &&
                                filteredCountries.map((c) => (
                                  <li
                                    key={c.country}
                                    className={
                                      country === c.country
                                        ? "selected-item"
                                        : ""
                                    }
                                    onClick={() =>
                                      handleCountryChange(c.country)
                                    }
                                  >
                                    {c.country}
                                  </li>
                                ))}
                            </ul>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="right-section">
                <div className="outer-section">
                  <div className="text-icon">
                    <div className="icon-image">
                      <img src={LocationIcon} alt="location-icon" />
                    </div>
                    <span>Address *</span>
                  </div>
                  {isLoading ? (
                    <ThreeDots
                      height="18"
                      width="20"
                      radius="9"
                      color="#6479f9"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  ) : (
                    <div className="text-section">
                      <input
                        type="text"
                        className="type-field"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        required
                      />
                    </div>
                  )}
                </div>
                <div className="outer-section">
                  <div className="text-icon">
                    <div className="icon-image">
                      <img src={PostalIcon} alt="postal-icon" />
                    </div>

                    <span>Postal Code *</span>
                  </div>
                  {isLoading ? (
                    <ThreeDots
                      height="18"
                      width="20"
                      radius="9"
                      color="#6479f9"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  ) : (
                    <div className="text-section">
                      <input
                        type="text"
                        className="type-field"
                        value={pincode}
                        onChange={(e) => setPincode(e.target.value)}
                        required
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="accordion-client-outer">
          <div
            className="header-client-accordion"
            onClick={toggleBillingAccordion}
          >
            <span>Billing Information</span>
            <img src={BottomArrow} alt="bottom-arrow" />
          </div>

          {isBillingOpen && (
            <div className="company-information-section">
              <div className="left-section">
                <div className="outer-section">
                  <div className="text-icon">
                    <div className="icon-image">
                      <img src={PassportIcon} alt="passport-icon" />
                    </div>

                    <span>Company VAT Number *</span>
                  </div>
                  {isLoading ? (
                    <ThreeDots
                      height="18"
                      width="20"
                      radius="9"
                      color="#6479f9"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  ) : (
                    <div className="text-section">
                      <input
                        type="text"
                        className="type-field"
                        value={vatNumber}
                        onChange={(e) => setVatNumber(e.target.value)}
                        required
                      />
                    </div>
                  )}
                </div>
                <div className="outer-section">
                  <div className="text-icon">
                    <div className="icon-image">
                      <img src={PassportIcon} alt="passport-icon" />
                    </div>

                    <span>Company VAT applicable Percentage *</span>
                  </div>
                  {isLoading ? (
                    <ThreeDots
                      height="18"
                      width="20"
                      radius="9"
                      color="#6479f9"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  ) : (
                    <div className="text-section">
                      <input
                        type="number"
                        className="type-field"
                        onWheel={numberInputOnWheelPreventChange}
                        value={vatPercentage}
                        onChange={(e) => setVatPercentage(e.target.value)}
                        required
                      />
                    </div>
                  )}
                </div>
                <div className="outer-section">
                  <div className="inner-detail-outer">
                    <div className="text-icon">
                      <div className="icon-image">
                        <img src={PaymentIcon} alt="payment-icon" />
                      </div>

                      <span>Preferred Currency *</span>
                    </div>

                    <div className="inner-detail ">
                      <span class="icon"></span>
                      {isLoading ? (
                        <ThreeDots
                          height="18"
                          width="20"
                          radius="9"
                          color="#6479f9"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClassName=""
                          visible={true}
                        />
                      ) : (
                        <div className="inner-detail-inner custom-dropdown1">
                          <div className="tooltip-container">
                            <div
                              className={`custom-dropdown-user-new-outer custom-dropdown-select ${currencyDropdownOpen ? "open" : ""
                                }`}
                              ref={currencyDropdownRef}
                            >
                              <div
                                className={`selected-option custom-dropdown-user-new ${currencyIsValid} ? "input-validation-error" : ""`}
                                onClick={() =>
                                  setCurrencyDropdownOpen(!currencyDropdownOpen)
                                }
                                ref={currencyRef}
                              >
                                {preferredCurrency
                                  ? currencyList.find(
                                    (c) =>
                                      c.currencyCode === preferredCurrency
                                  )?.currencyCode
                                  : "Select Currency"}
                              </div>
                              {currencyDropdownOpen && (
                                <div className="custom-dropdown-new">
                                  <input
                                    type="text"
                                    className="search-bar"
                                    placeholder="Search..."
                                    value={currencySearchTerm}
                                    onChange={handleCurrencySearchInputChange}
                                  />
                                  <ul className="currency-list">
                                    {filteredCurrencies.length > 0 &&
                                      filteredCurrencies.map((c) => (
                                        <li
                                          key={c.currencyCode}
                                          className={
                                            currency === c.currencyCode
                                              ? "selected-item"
                                              : ""
                                          }
                                          onClick={() => {
                                            setPreferredCurrency(
                                              c.currencyCode
                                            );
                                            setCurrencyDropdownOpen(false);
                                          }}
                                        >
                                          {`${c.currencyCode} (${c.currencyName})`}
                                        </li>
                                      ))}
                                  </ul>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="outer-section">
                  <div className="text-icon">
                    <div className="icon-image">
                      <img src={CostIcon} alt="cost-icon" />
                    </div>

                    <span>Preferred Payment Method *</span>
                  </div>
                  <div className="text-section">
                    {isLoading ? (
                      <ThreeDots
                        height="18"
                        width="20"
                        radius="9"
                        color="#6479f9"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                      />
                    ) : (
                      <div className="custom-dropdown1">
                        <div
                          ref={paymentMethodDropdownRef}
                          className={`custom-dropdown-user-new-outer custom-dropdown-select ${paymentMethodDropdownOpen ? "open" : ""
                            }`}
                        >
                          <div
                            className="selected-option custom-dropdown-user-new"
                            onClick={togglePaymentMethodDropdown}
                          >
                            {paymentMethod
                              ? paymentMethod
                              : "Select Payment Method"}
                          </div>
                          {paymentMethodDropdownOpen && (
                            <div className="custom-dropdown-new">
                              <ul className="type-list">
                                <li
                                  onClick={() =>
                                    selectPaymentMethodOption("Bank Transfer")
                                  }
                                >
                                  Bank Transfer
                                </li>
                                <li
                                  onClick={() =>
                                    selectPaymentMethodOption("Wise Transfer")
                                  }
                                >
                                  Wise Transfer
                                </li>
                                <li
                                  onClick={() =>
                                    selectPaymentMethodOption("Card")
                                  }
                                >
                                  Card
                                </li>
                                {/* Add more PaymentMethod options as needed */}
                              </ul>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="right-section">
                <div className="outer-section">
                  <div className="text-icon">
                    <div className="icon-image">
                      <img src={SingleUser} alt="single-user" />
                    </div>

                    <span>Billing contact - Full Name *</span>
                  </div>
                  {isLoading ? (
                    <ThreeDots
                      height="18"
                      width="20"
                      radius="9"
                      color="#6479f9"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  ) : (
                    <div className="text-section">
                      <input
                        type="text"
                        className="type-field"
                        value={billingContactName}
                        onChange={(e) => setBillingContactName(e.target.value)}
                        required
                      />
                    </div>
                  )}
                </div>
                <div className="outer-section">
                  <div className="text-icon">
                    <div className="icon-image">
                      <img src={CompanyIcon} alt="company-icon" />
                    </div>

                    <span>Billing contact - Position *</span>
                  </div>
                  <div className="text-section">
                    {isLoading ? (
                      <ThreeDots
                        height="18"
                        width="20"
                        radius="9"
                        color="#6479f9"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                      />
                    ) : (
                      // <div className="custom-dropdown1">
                      //   <div ref={billingContactDropdownRef} className={`custom-dropdown-user-new-outer custom-dropdown-select ${billingContactDropdownOpen ? 'open' : ''}`}>
                      //     <div className={`selected-option custom-dropdown-user-new ${billContPosIsValid} ? "input-validation-error" : ""`} onClick={toggleBillingContactDropdown} ref={billContPosRef}>
                      //       {billingContactPosition ? position.find((pos) => pos._id === billingContactPosition)?.position_name : 'Select Billing Contact Position'}
                      //     </div>
                      //     {billingContactDropdownOpen && (
                      //       <div className="custom-dropdown-new">
                      //         <ul className="type-list">
                      //           {position.length > 0 &&
                      //             position.map((pos) => (
                      //               <li
                      //                 key={pos._id}
                      //                 className={billingContactPosition === pos._id ? 'selected-item' : ''}
                      //                 onClick={() => {
                      //                   setBillingContactPosition(pos._id);
                      //                   setBillingContactDropdownOpen(false);
                      //                 }}>
                      //                 {pos.position_name}
                      //               </li>
                      //             ))}
                      //           {/* Add more language options as needed */}
                      //         </ul>
                      //       </div>
                      //     )}
                      //   </div>
                      // </div>
                      <div className="text-section">
                        <input
                          type="text"
                          className="type-field"
                          value={billingContactPosition}
                          onChange={(e) =>
                            setBillingContactPosition(e.target.value)
                          }
                          ref={billContPosRef}
                          required
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className="outer-section">
                  <div className="text-icon">
                    <div className="icon-image">
                      <img src={EmailIcon} alt="email-icon" />
                    </div>

                    <span>Billing contact - Email Address *</span>
                  </div>
                  {isLoading ? (
                    <ThreeDots
                      height="18"
                      width="20"
                      radius="9"
                      color="#6479f9"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  ) : (
                    <div className="text-section">
                      <input
                        type="text"
                        className="type-field"
                        value={billingContactEmail}
                        onChange={(e) => setBillingContactEmail(e.target.value)}
                        required
                      />
                    </div>
                  )}
                </div>
                <div className="outer-section">
                  <div className="text-icon">
                    <div className="icon-image">
                      <img src={PhoneIcon} alt="Phone-icon" />
                    </div>

                    <span>Contact Number *</span>
                  </div>
                  {isLoading ? (
                    <ThreeDots
                      height="18"
                      width="20"
                      radius="9"
                      color="#6479f9"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  ) : (
                    <div className="text-section">
                      <PhoneInput
                        placeholder="Enter phone number"
                        value={mobileNo}
                        onChange={setMobileNo}
                        required
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="accordion-client-outer">
          <div
            className="header-client-accordion"
            onClick={toggleSignatoryAccordion}
          >
            <span>Signatory Information</span>
            <img src={BottomArrow} alt="bottom-arrow" />
          </div>

          {isSignatoryOpen && (
            <div className="company-information-section">
              <div className="left-section">
                <div className="outer-section">
                  <div className="text-icon">
                    <div className="icon-image">
                      <img src={SingleUser} alt="single-user" />
                    </div>
                    <span>Full Name *</span>
                  </div>
                  {isLoading ? (
                    <ThreeDots
                      height="18"
                      width="20"
                      radius="9"
                      color="#6479f9"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  ) : (
                    <div className="text-section">
                      <input
                        type="text"
                        id="signatoryName"
                        className="type-field"
                        value={signatoryUserName}
                        onChange={(e) => setSignatoryUserName(e.target.value)}
                        required
                      />
                    </div>
                  )}
                </div>
                <div className="outer-section">
                  <div className="text-icon">
                    <div className="icon-image">
                      <img src={CompanyIcon} alt="company-icon" />
                    </div>

                    <span>Position *</span>
                  </div>
                  <div className="text-section">
                    {isLoading ? (
                      <ThreeDots
                        height="18"
                        width="20"
                        radius="9"
                        color="#6479f9"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                      />
                    ) : (
                      // <div className="custom-dropdown1">
                      //   <div ref={signatoryPositionDropdownRef} className={`custom-dropdown-user-new-outer custom-dropdown-select ${signatoryPositionDropdownOpen ? 'open' : ''}`}>
                      //     <div className={`selected-option custom-dropdown-user-new ${signUserPosIsValid} ? "input-validation-error" : ""`} onClick={toggleSignatoryPositionDropdown} ref={signUserPosRef}>
                      //       {signatoryUserPosition ? position.find((pos) => pos._id === signatoryUserPosition)?.position_name : 'Select Position'}
                      //     </div>
                      //     {signatoryPositionDropdownOpen && (
                      //       <div className="custom-dropdown-new">
                      //         <ul className="type-list">
                      //           {position.length > 0 &&
                      //             position.map((pos) => (
                      //               <li
                      //                 key={pos._id}
                      //                 className={signatoryUserPosition === pos._id ? 'selected-item' : ''}
                      //                 onClick={() => {
                      //                   setSignatoryUserPosition(pos._id);
                      //                   setSignatoryPositionDropdownOpen(false);
                      //                 }}>
                      //                 {pos.position_name}
                      //               </li>
                      //             ))}
                      //         </ul>
                      //       </div>
                      //     )}
                      //   </div>
                      // </div>
                      <div className="text-section">
                        <input
                          type="text"
                          className="type-field"
                          value={signatoryUserPosition}
                          onChange={(e) =>
                            setSignatoryUserPosition(e.target.value)
                          }
                          ref={signUserPosRef}
                          required
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="right-section">
                <div className="outer-section">
                  <div className="text-icon">
                    <div className="icon-image">
                      <img src={EmailIcon} alt="email-icon" />
                    </div>
                    <span>Email *</span>
                  </div>
                  {isLoading ? (
                    <ThreeDots
                      height="18"
                      width="20"
                      radius="9"
                      color="#6479f9"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  ) : (
                    <div className="text-section">
                      <input
                        type="email"
                        className="type-field"
                        value={signatoryEmail}
                        onChange={(e) => setSignatoryEmail(e.target.value)}
                        required
                      />
                    </div>
                  )}
                </div>
                <div className="outer-section">
                  <div className="text-icon">
                    <div className="icon-image">
                      <img src={PhoneIcon} alt="phone-icon" />
                    </div>

                    <span>Contact Number *</span>
                  </div>
                  {isLoading ? (
                    <ThreeDots
                      height="18"
                      width="20"
                      radius="9"
                      color="#6479f9"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  ) : (
                    <div className="text-section">
                      <PhoneInput
                        placeholder="Enter phone number"
                        value={signatoryMobileNo}
                        onChange={setSignatoryMobileNo}
                        required
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="accordion-client-outer">
          <div
            className="header-client-accordion"
            onClick={togglePointOfContactAccordion}
          >
            <span>Point of Contact</span>
            <img src={BottomArrow} alt="bottom-arrow" />
          </div>

          {isPointOfContactOpen && (
            <div className="company-information-section">
              <div className="left-section">
                <div className="outer-section">
                  <div className="text-icon">
                    <div className="icon-image">
                      <img src={SingleUser} alt="single-user" />
                    </div>
                    <span>Full Name *</span>
                  </div>
                  {isLoading ? (
                    <ThreeDots
                      height="18"
                      width="20"
                      radius="9"
                      color="#6479f9"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  ) : (
                    <div className="text-section">
                      <input
                        type="text"
                        className="type-field"
                        value={pocUserName}
                        onChange={(e) => setPocUserName(e.target.value)}
                        required
                      />
                    </div>
                  )}
                </div>
                <div className="outer-section">
                  <div className="text-icon">
                    <div className="icon-image">
                      <img src={CompanyIcon} alt="company-icon" />
                    </div>

                    <span>Position *</span>
                  </div>
                  <div className="text-section">
                    {isLoading ? (
                      <ThreeDots
                        height="18"
                        width="20"
                        radius="9"
                        color="#6479f9"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                      />
                    ) : (
                      // <div className="custom-dropdown1">
                      //   <div ref={positionContactDropdownRef} className={`custom-dropdown-user-new-outer custom-dropdown-select ${positionContactDropdownOpen ? 'open' : ''}`}>
                      //     <div className={`selected-option custom-dropdown-user-new ${pocUserPosIsValid} ? "input-validation-error" : ""`} onClick={togglePositionContactDropdown} ref={pocUserPosRef}>
                      //       {pocUserPosition ? position.find((pos) => pos._id === pocUserPosition)?.position_name : 'Select Contact Point Position'}
                      //     </div>
                      //     {positionContactDropdownOpen && (
                      //       <div className="custom-dropdown-new">
                      //         <ul className="type-list">
                      //           {position.length > 0 &&
                      //             position.map((pos) => (
                      //               <li
                      //                 key={pos._id}
                      //                 className={pocUserPosition === pos._id ? 'selected-item' : ''}
                      //                 onClick={() => {
                      //                   setPocUserPosition(pos._id);
                      //                   setPositionContactDropdownOpen(false);
                      //                 }}
                      //               >
                      //                 {pos.position_name}
                      //               </li>
                      //             ))}
                      //         </ul>
                      //       </div>
                      //     )}
                      //   </div>
                      // </div>
                      <div className="text-section">
                        <input
                          type="text"
                          className="type-field"
                          value={pocUserPosition}
                          onChange={(e) => setPocUserPosition(e.target.value)}
                          ref={pocUserPosRef}
                          required
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="right-section">
                <div className="outer-section">
                  <div className="text-icon">
                    <div className="icon-image">
                      <img src={EmailIcon} alt="email-icon" />
                    </div>

                    <span>Email *</span>
                  </div>
                  {isLoading ? (
                    <ThreeDots
                      height="18"
                      width="20"
                      radius="9"
                      color="#6479f9"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  ) : (
                    <div className="text-section">
                      <input
                        type="email"
                        className="type-field"
                        value={pocEmail}
                        onChange={(e) => setPocEmail(e.target.value)}
                        required
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>

 

        <div className="accordion-client-outer">
          <div
            className="header-client-accordion"
            onClick={toggleSecurityAccordion}
          >
            <span>Security</span>
            <img src={BottomArrow} alt="bottom-arrow" />
          </div>

          {isSecurityOpen && (
            <div className="company-information-section">
              <div className="left-section">
                <div className="outer-section">
                  <div className="text-icon">
                    <div className="icon-image">
                      <img src={PasswordLock} alt="single-user" />
                    </div>
                    <span>Password</span>
                  </div>
                  {isLoading ? (
                    <ThreeDots
                      height="18"
                      width="20"
                      radius="9"
                      color="#6479f9"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  ) : (
                    <div className="text-section">
                      <div className="inner-detail-inner pass-eye">
                        <input
                          type={showPassword ? "text" : "password"}
                          className="type-field"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          ref={passwordRef}
                        />
                        <img
                          src={showPassword ? EyeCrossed : EyeIcon}
                          className="password-toggle-icon"
                          alt="Toggle Password"
                          onClick={() => setShowPassword(!showPassword)}
                        />
                      </div>
                      {passwordError && (
                        <span>{passwordError}</span>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className="right-section">
                <div className="outer-section">
                  <div className="text-icon">
                    <div className="icon-image">
                      <img src={PasswordLock} alt="email-icon" />
                    </div>

                    <span>Confirm Password</span>
                  </div>
                  {isLoading ? (
                    <ThreeDots
                      height="18"
                      width="20"
                      radius="9"
                      color="#6479f9"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  ) : (
                    <div className="text-section">
                      <div className="text-section">
                        <input
                          type="password"
                          className="type-field"
                          value={passwordConfirmation}
                          onChange={(e) => setPasswordConfirmation(e.target.value)}
                          ref={passwordConfirmationRef}
                          required={!!password}
                        />
                        {passwordMatchError && (
                          <span style={{ color: "red" }}> {passwordMatchError} </span>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="accordion-client-outer bottom-field">
          <div className="header-client-accordion">
            <span>Additional Notes</span>
          </div>

          <div className="additional-notes-section">
            {isLoading ? (
              <ThreeDots
                height="18"
                width="20"
                radius="9"
                color="#6479f9"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            ) : (
              <div className="total-section">
                <textarea
                  id="additionalInformation"
                  name="additionalInformation"
                  value={additionalInfo}
                  onChange={(e) => setAdditionalInfo(e.target.value)}
                ></textarea>
              </div>
            )}
          </div>
        </div>

        <div className="button-section-outer">
          <Link
            to={`/client/details/${encodeURIComponent(
              AES.encrypt(id, "encryptionKey").toString().replace(/\//g, "%2F")
            )}`}
          >
            <div className="cancel-button">
              <span>Cancel</span>
            </div>
          </Link>
          <button type="submit" className="save-btn" disabled={isSubmitLoading}>
            {isSubmitLoading ? (
              <div className="outter-load-table">
                <ThreeDots
                  height="38"
                  width="40"
                  radius="9"
                  color="white"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
              </div>
            ) : (
              <div className="submit-button">
                <div className="button-tick">
                  <img src={WhiteTick} alt="tick-icon-white" />
                </div>
                <div className="button-text">
                  <span>Save Changes</span>
                </div>
              </div>
            )}
          </button>
        </div>
      </form>
    </>
  );
};

export default ClientProfile;
