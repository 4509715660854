import axios from "axios";
import { useQuery, queryCache } from "react-query";

export function useGroupedTimerDataImportData(token) {
    return useQuery("groupTimerDataImportData", async () => {
        const response = await axios.get("/api/time-track/importdata/grouped/get-timer", {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                tz: global.config.getTimeZone(),
            },
        });

        return response.data;
    });
}

export function useTimerDataImportData(token) {
    return useQuery("timerDataImportData", async () => {
        const response = await axios.get("/api/time-track/importdata/get-timer", {
            headers: {
                Authorization: `Bearer ${token}`,

            },
            params: {
                tz: global.config.getTimeZone(),
            },
        });
        var result = Object.keys(response.data).map((key) => [
            key,
            response.data[key],
        ]);
        return result;
    });
}