import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Dollar from "../../../../images/icons/dollar-kit.svg";
import { calculateOverdueReimbursement } from "./Services/reportReimbursement";

// Functional component
function TotalReimburesentOverdue({ reimbursementInv }) {
  const [totalOverdueReimbursement, setTotalOverdueReimbursement] = useState(null);
  const reportingReimbursment = useSelector((state) => state.reportingReimbursment);
  const { forex } = useSelector((state) => state.forex);
  const settings = JSON.parse(localStorage.getItem("settings"));

  useEffect(() => {
    const totalOverdueReimbursementAmount = calculateOverdueReimbursement(reimbursementInv, reportingReimbursment, settings.currency, forex);
    setTotalOverdueReimbursement(totalOverdueReimbursementAmount);
  }, [reimbursementInv, reportingReimbursment, settings.currency, forex]);
  return (
    <div>
      <div className="overview-item">
        <div className="overview-title-outer">
          <div className="overview-title">Total Overdue</div>
          <div className="overview-icon">
            <svg width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g filter="url(#filter0_d_2951_106)">
                <circle cx="24.5" cy="24.5" r="20.5" fill="white" />
              </g>
              <path d="M31.25 14.6667H30V13.4167C30 13.1867 29.8133 13 29.5833 13C29.3533 13 29.1667 13.1867 29.1667 13.4167V14.6667H20.8333V13.4167C20.8333 13.1867 20.6467 13 20.4167 13C20.1867 13 20 13.1867 20 13.4167V14.6667H18.75C16.6825 14.6667 15 16.3492 15 18.4167V29.25C15 31.3175 16.6825 33 18.75 33H24.5833C24.8133 33 25 32.8133 25 32.5833C25 32.3533 24.8133 32.1667 24.5833 32.1667H18.75C17.1417 32.1667 15.8333 30.8583 15.8333 29.25V20.5H34.1667V25.8804C34.1667 26.1104 34.3533 26.2971 34.5833 26.2971C34.8133 26.2971 35 26.1104 35 25.8804V18.4167C35 16.3492 33.3175 14.6667 31.25 14.6667ZM34.1667 19.6667H15.8333V18.4167C15.8333 16.8083 17.1417 15.5 18.75 15.5H31.25C32.8583 15.5 34.1667 16.8083 34.1667 18.4167V19.6667Z" fill="#6A7988" stroke="#6A7988" stroke-width="0.2" />
              <path d="M30.5 35C32.9853 35 35 32.9853 35 30.5C35 28.0147 32.9853 26 30.5 26C28.0147 26 26 28.0147 26 30.5C26 32.9853 28.0147 35 30.5 35Z" stroke="#6A7988" />
              <path d="M30 28L30.4 31L31 28H30Z" fill="#6A7988" stroke="#6A7988" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M30.5 33C30.7761 33 31 32.7761 31 32.5C31 32.2239 30.7761 32 30.5 32C30.2239 32 30 32.2239 30 32.5C30 32.7761 30.2239 33 30.5 33Z" fill="#6A7988" />
              <defs>
                <filter id="filter0_d_2951_106" x="0" y="0" width="49" height="49" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                  <feOffset />
                  <feGaussianBlur stdDeviation="2" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix type="matrix" values="0 0 0 0 0.468733 0 0 0 0 0.51145 0 0 0 0 0.554167 0 0 0 0.39 0" />
                  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2951_106" />
                  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2951_106" result="shape" />
                </filter>
              </defs>
            </svg>
          </div>
        </div>

        <div className="overview-rice">
          {new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: settings.currency,
          }).format(totalOverdueReimbursement)}
        </div>
      </div>
    </div>
  );
}

export default TotalReimburesentOverdue;
