import { Outlet } from 'react-router-dom';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { setHeaderText } from "../../features/headerText/headerTextSlice";
import ReporPersonnel from "../../components/Reporting/PersonalReport.jsx";

function ReportingPersonnel() {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user);
    const navigate = useNavigate();
    const handleSelectChange = (event) => {
        const selectedPage = event.target.value;
        navigate(selectedPage);
    };

    useEffect(() => {
        // Set the initial value of the select to match the current URL path
        const currentPath = window.location.pathname;
        document.querySelector('.mobile-tab-select').value = currentPath;
    }, []);

    useEffect(() => {
        dispatch(setHeaderText("Personnel Reports"));
    }, []);
    return (
        <div>
            <div className='tab-outer-time'>
                <div className='tab-mobile-select'>
                    <h4>Select an option</h4>
                    <select className="mobile-tab-select" onChange={handleSelectChange}>
                        <option value="/report/personnel/timeoffrequest">Time Off Requests</option>
                        <option value="/report/personnel/reimbursement">Reimbursement</option>
                        <option value="/report/personnel/timeworked">Time Worked</option>

                    </select>
                </div>

                <ul className="tabs">
                    <li>
                        <Link to="/report/personnel/timeoffrequest" className={window.location.pathname.startsWith('/report/personnel/timeoffrequest') ? 'active' : ''}>
                            Time Off Requests
                        </Link>
                    </li>

                    <li>
                        <Link to='/report/personnel/reimbursement' className={window.location.pathname.startsWith('/report/personnel/reimbursement') ? 'active' : ''}>
                            Reimbursement
                        </Link>
                    </li>

                    <li>
                        <Link to='/report/personnel/timeworked' className={window.location.pathname.startsWith('/report/personnel/timeworked') ? 'active' : ''}>
                            Time Worked
                        </Link>
                    </li>

                </ul>

                <div className="tabs-content">
                    <div className='tab-inner-time'>
                        <Outlet />
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ReportingPersonnel; 