const currencies = {
  sourceCurrencies: [
    {
      currencyCode: "CHF",
      maxInvoiceAmount: 1300000.0,
      targetCurrencies: [
        {
          currencyCode: "EUR",
          currencySymbol: "€",
          currencyName: "Euro",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "GBP",
          currencySymbol: "£",
          currencyName: "British Pound",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "INR",
          currencySymbol: "₹",
          currencyName: "Indian Rupee",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CHF",
          currencySymbol: "Fr",
          currencyName: "Swiss Franc",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "AED",
          currencySymbol: "د.إ",
          currencyName: "UAE Dirham",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "ARS",
          currencySymbol: "$",
          currencyName: "Argentine Peso",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "AUD",
          currencySymbol: "$",
          currencyName: "Australian Dollar",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "BGN",
          currencySymbol: "лв",
          currencyName: "Bulgarian Lev",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "BRL",
          currencySymbol: "R$",
          currencyName: "Brazilian Real",
          minInvoiceAmount: 10.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "BWP",
          currencySymbol: "P",
          currencyName: "Botswana Pula",
          minInvoiceAmount: 7.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CAD",
          currencySymbol: "$",
          currencyName: "Canadian Dollar",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CLP",
          currencySymbol: "$",
          currencyName: "Chilean Peso",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CNY",
          currencySymbol: "¥",
          currencyName: "Chinese Yuan",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "COP",
          currencySymbol: "$",
          currencyName: "Colombian Peso",
          minInvoiceAmount: 13.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CRC",
          currencySymbol: "₡",
          currencyName: "Costa Rican Colón",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CZK",
          currencySymbol: "Kč",
          currencyName: "Czech Koruna",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "DKK",
          currencySymbol: "kr",
          currencyName: "Danish Krone",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "EGP",
          currencySymbol: "£",
          currencyName: "Egyptian Pound",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "FJD",
          currencySymbol: "$",
          currencyName: "Fijian Dollar",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "GEL",
          currencySymbol: "₾",
          currencyName: "Georgian Lari",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "GHS",
          currencySymbol: "₵",
          currencyName: "Ghanaian Cedi",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "HKD",
          currencySymbol: "$",
          currencyName: "Hong Kong Dollar",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "HUF",
          currencySymbol: "Ft",
          currencyName: "Hungarian Forint",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "IDR",
          currencySymbol: "Rp",
          currencyName: "Indonesian Rupiah",
          minInvoiceAmount: 4.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "ILS",
          currencySymbol: "₪",
          currencyName: "Israeli Shekel",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "JPY",
          currencySymbol: "¥",
          currencyName: "Japanese Yen",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "KES",
          currencySymbol: "KSh",
          currencyName: "Kenyan Shilling",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "KRW",
          currencySymbol: "₩",
          currencyName: "South Korean Won",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "LKR",
          currencySymbol: "Rs",
          currencyName: "Sri Lankan Rupee",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "MAD",
          currencySymbol: "د.م.",
          currencyName: "Moroccan Dirham",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "MXN",
          currencySymbol: "$",
          currencyName: "Mexican Peso",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "MYR",
          currencySymbol: "RM",
          currencyName: "Malaysian Ringgit",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "NGN",
          currencySymbol: "₦",
          currencyName: "Nigerian Naira",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "NOK",
          currencySymbol: "kr",
          currencyName: "Norwegian Krone",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "NPR",
          currencySymbol: "Rs",
          currencyName: "Nepalese Rupee",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "NZD",
          currencySymbol: "$",
          currencyName: "New Zealand Dollar",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "PHP",
          currencySymbol: "₱",
          currencyName: "Philippine Peso",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "PLN",
          currencySymbol: "zł",
          currencyName: "Polish Złoty",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "RON",
          currencySymbol: "lei",
          currencyName: "Romanian Leu",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "RUB",
          currencySymbol: "₽",
          currencyName: "Russian Ruble",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "SEK",
          currencySymbol: "kr",
          currencyName: "Swedish Krona",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "SGD",
          currencySymbol: "$",
          currencyName: "Singapore Dollar",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "THB",
          currencySymbol: "฿",
          currencyName: "Thai Baht",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "TRY",
          currencySymbol: "₺",
          currencyName: "Turkish Lira",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "TTD",
          currencySymbol: "$",
          currencyName: "Trinidad and Tobago Dollar",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "TWD",
          currencySymbol: "NT$",
          currencyName: "New Taiwan Dollar",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "TZS",
          currencySymbol: "TSh",
          currencyName: "Tanzanian Shilling",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "UAH",
          currencySymbol: "₴",
          currencyName: "Ukrainian Hryvnia",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "UGX",
          currencySymbol: "USh",
          currencyName: "Ugandan Shilling",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "USD",
          currencySymbol: "$",
          currencyName: "United States Dollar",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "VND",
          currencySymbol: "₫",
          currencyName: "Vietnamese Đồng",
          minInvoiceAmount: 23.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "XAF",
          currencySymbol: "FCFA",
          currencyName: "Central African CFA Franc",
          minInvoiceAmount: 568.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "XCD",
          currencySymbol: "$",
          currencyName: "East Caribbean Dollar",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "ZAR",
          currencySymbol: "R",
          currencyName: "South African Rand",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "ZMW",
          currencySymbol: "ZK",
          currencyName: "Zambian Kwacha",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
      ],
      totalTargetCurrencies: 51,
    },
    {
      currencyCode: "INR",
      maxInvoiceAmount: 650000.0,
      targetCurrencies: [
        {
          currencyCode: "AED",
          minInvoiceAmount: 23.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "ARS",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "AUD",
          minInvoiceAmount: 56.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "BGN",
          minInvoiceAmount: 46.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "BRL",
          minInvoiceAmount: 830.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "BWP",
          minInvoiceAmount: 612.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CAD",
          minInvoiceAmount: 62.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CHF",
          minInvoiceAmount: 92.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CLP",
          minInvoiceAmount: 104.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CNY",
          minInvoiceAmount: 12.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "COP",
          minInvoiceAmount: 1119.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CRC",
          minInvoiceAmount: 154.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CZK",
          minInvoiceAmount: 4.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "DKK",
          minInvoiceAmount: 13.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "EGP",
          minInvoiceAmount: 3.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "EUR",
          minInvoiceAmount: 90.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "FJD",
          minInvoiceAmount: 38.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "GBP",
          minInvoiceAmount: 104.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "GEL",
          minInvoiceAmount: 32.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "HKD",
          minInvoiceAmount: 11.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "HUF",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "IDR",
          minInvoiceAmount: 279.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "ILS",
          minInvoiceAmount: 23.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "INR",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "JPY",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "KES",
          minInvoiceAmount: 6.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "KRW",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "MAD",
          minInvoiceAmount: 9.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "MXN",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "MYR",
          minInvoiceAmount: 19.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "NGN",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "NOK",
          minInvoiceAmount: 8.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "NPR",
          minInvoiceAmount: 7.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "NZD",
          minInvoiceAmount: 53.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "PHP",
          minInvoiceAmount: 15.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "PLN",
          minInvoiceAmount: 20.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "RON",
          minInvoiceAmount: 19.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "RUB",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "SEK",
          minInvoiceAmount: 8.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "SGD",
          minInvoiceAmount: 62.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "THB",
          minInvoiceAmount: 3.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "TRY",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "UAH",
          minInvoiceAmount: 3.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "USD",
          minInvoiceAmount: 83.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "UYU",
          minInvoiceAmount: 212.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "VND",
          minInvoiceAmount: 35.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "XOF",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "ZAR",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "ZMW",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: true,
        },
      ],
      totalTargetCurrencies: 49,
    },
    {
      currencyCode: "AUD",
      maxInvoiceAmount: 1800000.0,
      targetCurrencies: [
        {
          currencyCode: "EUR",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "GBP",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "INR",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "AUD",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "AED",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "ARS",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "BGN",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "BRL",
          minInvoiceAmount: 16.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "BWP",
          minInvoiceAmount: 12.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CAD",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CHF",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CLP",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CNY",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "COP",
          minInvoiceAmount: 21.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CRC",
          minInvoiceAmount: 3.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CZK",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "DKK",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "EGP",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "FJD",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "GEL",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "GHS",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "HKD",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "HUF",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "IDR",
          minInvoiceAmount: 6.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "ILS",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "JPY",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "KES",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "KRW",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "LKR",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "MAD",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "MXN",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "MYR",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "NGN",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "NOK",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "NPR",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "NZD",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "PHP",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "PLN",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "RON",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "RUB",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "SEK",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "SGD",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "THB",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "TRY",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "UAH",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "USD",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "UYU",
          minInvoiceAmount: 4.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "VND",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "XOF",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "ZAR",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "ZMW",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
      ],
      totalTargetCurrencies: 51,
    },
    {
      currencyCode: "ILS",
      maxInvoiceAmount: 1057320.0,
      targetCurrencies: [
        {
          currencyCode: "EUR",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "GBP",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "INR",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "USD",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "AED",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "ARS",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "AUD",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "BGN",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "BRL",
          minInvoiceAmount: 37.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "BWP",
          minInvoiceAmount: 28.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CAD",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CHF",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CLP",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CNY",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "COP",
          minInvoiceAmount: 50.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CRC",
          minInvoiceAmount: 7.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CZK",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "DKK",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "EGP",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "FJD",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "GEL",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "GHS",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "HKD",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "HUF",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "IDR",
          minInvoiceAmount: 13.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "ILS",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "JPY",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "KES",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "KRW",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "LKR",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "MAD",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "MXN",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "NGN",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "NOK",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "NPR",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "NZD",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "PHP",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "PLN",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "RON",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "RUB",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "SEK",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "SGD",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "THB",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "TRY",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "UAH",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "UYU",
          minInvoiceAmount: 10.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "VND",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "XOF",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "ZAR",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "ZMW",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: true,
        },
      ],
      totalTargetCurrencies: 50,
    },
    {
      currencyCode: "JPY",
      maxInvoiceAmount: 1000000.0,
      targetCurrencies: [
        {
          currencyCode: "USD",
          minInvoiceAmount: 135.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "GBP",
          minInvoiceAmount: 169.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "EUR",
          minInvoiceAmount: 147.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "JPY",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "AED",
          minInvoiceAmount: 37.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "ARS",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "AUD",
          minInvoiceAmount: 91.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "BGN",
          minInvoiceAmount: 75.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "BRL",
          minInvoiceAmount: 1357.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "BWP",
          minInvoiceAmount: 1000.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CAD",
          minInvoiceAmount: 100.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CHF",
          minInvoiceAmount: 151.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CLP",
          minInvoiceAmount: 170.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CNY",
          minInvoiceAmount: 20.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "COP",
          minInvoiceAmount: 1828.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CRC",
          minInvoiceAmount: 251.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CZK",
          minInvoiceAmount: 7.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "DKK",
          minInvoiceAmount: 20.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "EGP",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "FJD",
          minInvoiceAmount: 61.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "GEL",
          minInvoiceAmount: 53.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "GHS",
          minInvoiceAmount: 58.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "HKD",
          minInvoiceAmount: 18.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "HUF",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "IDR",
          minInvoiceAmount: 456.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "ILS",
          minInvoiceAmount: 37.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "INR",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "KES",
          minInvoiceAmount: 10.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "KRW",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "LKR",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "MAD",
          minInvoiceAmount: 14.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "MXN",
          minInvoiceAmount: 8.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "MYR",
          minInvoiceAmount: 31.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "NGN",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "NOK",
          minInvoiceAmount: 13.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "NPR",
          minInvoiceAmount: 11.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "NZD",
          minInvoiceAmount: 86.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "PHP",
          minInvoiceAmount: 25.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "PLN",
          minInvoiceAmount: 33.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "RON",
          minInvoiceAmount: 30.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "RUB",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "SEK",
          minInvoiceAmount: 14.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "SGD",
          minInvoiceAmount: 102.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "THB",
          minInvoiceAmount: 4.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "TRY",
          minInvoiceAmount: 7.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "UAH",
          minInvoiceAmount: 4.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "UYU",
          minInvoiceAmount: 346.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "VND",
          minInvoiceAmount: 58.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "XOF",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "ZAR",
          minInvoiceAmount: 8.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "ZMW",
          minInvoiceAmount: 8.0,
          fixedTargetPaymentAllowed: true,
        },
      ],
      totalTargetCurrencies: 51,
    },
    {
      currencyCode: "PLN",
      maxInvoiceAmount: 5000000.0,
      targetCurrencies: [
        {
          currencyCode: "GBP",
          minInvoiceAmount: 6.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "EUR",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "INR",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "PLN",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "AED",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "ARS",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "AUD",
          minInvoiceAmount: 3.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "BGN",
          minInvoiceAmount: 3.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "BRL",
          minInvoiceAmount: 42.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "BWP",
          minInvoiceAmount: 31.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CAD",
          minInvoiceAmount: 4.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CHF",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CLP",
          minInvoiceAmount: 6.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CNY",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "COP",
          minInvoiceAmount: 57.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CRC",
          minInvoiceAmount: 8.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CZK",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "DKK",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "EGP",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "FJD",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "GEL",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "GHS",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "HKD",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "HUF",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "IDR",
          minInvoiceAmount: 15.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "ILS",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "JPY",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "KES",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "KRW",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "LKR",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "MAD",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "MXN",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "MYR",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "NGN",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "NOK",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "NPR",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "NZD",
          minInvoiceAmount: 3.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "PHP",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "RON",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "RUB",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "SEK",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "SGD",
          minInvoiceAmount: 4.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "THB",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "TRY",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "UAH",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "USD",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "UYU",
          minInvoiceAmount: 11.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "VND",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "XOF",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "ZAR",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "ZMW",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
      ],
      totalTargetCurrencies: 51,
    },
    {
      currencyCode: "GBP",
      maxInvoiceAmount: 1000000.0,
      targetCurrencies: [
        {
          currencyCode: "AED",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "ARS",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "AUD",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "BGN",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "BRL",
          minInvoiceAmount: 9.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "BWP",
          minInvoiceAmount: 6.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CAD",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CHF",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CLP",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CNY",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "COP",
          minInvoiceAmount: 11.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CRC",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CZK",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "DKK",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "EGP",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "EUR",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "FJD",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "GBP",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "GEL",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "GHS",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "HKD",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "HUF",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "IDR",
          minInvoiceAmount: 3.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "ILS",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "INR",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "JPY",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "KES",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "KRW",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "LKR",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "MAD",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "MXN",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "MYR",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "NGN",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "NOK",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "NPR",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "NZD",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "PHP",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "PLN",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "RON",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "RUB",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "SEK",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "SGD",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "THB",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "TRY",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "UAH",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "USD",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "UYU",
          minInvoiceAmount: 3.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "VND",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "XOF",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "ZAR",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "ZMW",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
      ],
      totalTargetCurrencies: 51,
    },
    {
      currencyCode: "IDR",
      maxInvoiceAmount: 1.39e9,
      targetCurrencies: [
        {
          currencyCode: "AED",
          minInvoiceAmount: 10000.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "ARS",
          minInvoiceAmount: 10000.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "AUD",
          minInvoiceAmount: 10000.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "BGN",
          minInvoiceAmount: 10000.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "BRL",
          minInvoiceAmount: 148893.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "BWP",
          minInvoiceAmount: 109691.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CAD",
          minInvoiceAmount: 10973.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CHF",
          minInvoiceAmount: 16483.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CLP",
          minInvoiceAmount: 18613.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CNY",
          minInvoiceAmount: 10000.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "COP",
          minInvoiceAmount: 200628.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CRC",
          minInvoiceAmount: 27458.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CZK",
          minInvoiceAmount: 10000.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "DKK",
          minInvoiceAmount: 10000.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "EGP",
          minInvoiceAmount: 10000.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "EUR",
          minInvoiceAmount: 16092.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "FJD",
          minInvoiceAmount: 10000.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "GBP",
          minInvoiceAmount: 18548.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "GEL",
          minInvoiceAmount: 10000.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "GHS",
          minInvoiceAmount: 10000.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "HKD",
          minInvoiceAmount: 10000.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "HUF",
          minInvoiceAmount: 10000.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "IDR",
          minInvoiceAmount: 50000.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "ILS",
          minInvoiceAmount: 10000.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "INR",
          minInvoiceAmount: 10000.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "JPY",
          minInvoiceAmount: 10000.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "KES",
          minInvoiceAmount: 10000.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "KRW",
          minInvoiceAmount: 10000.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "LKR",
          minInvoiceAmount: 10000.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "MAD",
          minInvoiceAmount: 10000.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "MXN",
          minInvoiceAmount: 10000.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "MYR",
          minInvoiceAmount: 10000.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "NGN",
          minInvoiceAmount: 10000.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "NOK",
          minInvoiceAmount: 10000.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "NPR",
          minInvoiceAmount: 10000.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "NZD",
          minInvoiceAmount: 10000.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "PHP",
          minInvoiceAmount: 10000.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "PLN",
          minInvoiceAmount: 10000.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "RON",
          minInvoiceAmount: 10000.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "RUB",
          minInvoiceAmount: 10000.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "SEK",
          minInvoiceAmount: 10000.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "SGD",
          minInvoiceAmount: 11089.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "THB",
          minInvoiceAmount: 10000.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "TRY",
          minInvoiceAmount: 10000.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "UAH",
          minInvoiceAmount: 10000.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "USD",
          minInvoiceAmount: 14724.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "UYU",
          minInvoiceAmount: 37874.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "VND",
          minInvoiceAmount: 10000.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "XOF",
          minInvoiceAmount: 10000.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "ZAR",
          minInvoiceAmount: 10000.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "ZMW",
          minInvoiceAmount: 10000.0,
          fixedTargetPaymentAllowed: true,
        },
      ],
      totalTargetCurrencies: 51,
    },
    {
      currencyCode: "HUF",
      maxInvoiceAmount: 3.0e8,
      targetCurrencies: [
        {
          currencyCode: "EUR",
          minInvoiceAmount: 372.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "GBP",
          minInvoiceAmount: 428.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "USD",
          minInvoiceAmount: 340.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "HUF",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "AED",
          minInvoiceAmount: 93.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "ARS",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "AUD",
          minInvoiceAmount: 229.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "BGN",
          minInvoiceAmount: 190.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "BRL",
          minInvoiceAmount: 3435.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "BWP",
          minInvoiceAmount: 2531.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CAD",
          minInvoiceAmount: 254.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CHF",
          minInvoiceAmount: 381.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CLP",
          minInvoiceAmount: 430.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CNY",
          minInvoiceAmount: 49.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "COP",
          minInvoiceAmount: 4628.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CRC",
          minInvoiceAmount: 634.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CZK",
          minInvoiceAmount: 16.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "DKK",
          minInvoiceAmount: 50.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "EGP",
          minInvoiceAmount: 11.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "FJD",
          minInvoiceAmount: 155.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "GEL",
          minInvoiceAmount: 133.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "GHS",
          minInvoiceAmount: 146.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "HKD",
          minInvoiceAmount: 44.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "IDR",
          minInvoiceAmount: 1154.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "ILS",
          minInvoiceAmount: 94.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "INR",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "JPY",
          minInvoiceAmount: 3.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "KES",
          minInvoiceAmount: 25.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "KRW",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "LKR",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "MAD",
          minInvoiceAmount: 34.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "MXN",
          minInvoiceAmount: 20.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "MYR",
          minInvoiceAmount: 77.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "NGN",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "NOK",
          minInvoiceAmount: 33.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "NPR",
          minInvoiceAmount: 26.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "NZD",
          minInvoiceAmount: 216.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "PHP",
          minInvoiceAmount: 61.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "PLN",
          minInvoiceAmount: 83.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "RON",
          minInvoiceAmount: 76.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "RUB",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "SEK",
          minInvoiceAmount: 34.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "SGD",
          minInvoiceAmount: 256.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "THB",
          minInvoiceAmount: 11.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "TRY",
          minInvoiceAmount: 18.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "UAH",
          minInvoiceAmount: 10.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "UYU",
          minInvoiceAmount: 874.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "VND",
          minInvoiceAmount: 145.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "XOF",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "ZAR",
          minInvoiceAmount: 18.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "ZMW",
          minInvoiceAmount: 19.0,
          fixedTargetPaymentAllowed: true,
        },
      ],
      totalTargetCurrencies: 51,
    },
    {
      currencyCode: "PHP",
      maxInvoiceAmount: 480000.0,
      targetCurrencies: [
        {
          currencyCode: "AED",
          minInvoiceAmount: 16.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "ARS",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "AUD",
          minInvoiceAmount: 38.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "BGN",
          minInvoiceAmount: 32.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "BRL",
          minInvoiceAmount: 564.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "BWP",
          minInvoiceAmount: 415.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CAD",
          minInvoiceAmount: 42.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CHF",
          minInvoiceAmount: 63.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CLP",
          minInvoiceAmount: 71.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CNY",
          minInvoiceAmount: 9.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "COP",
          minInvoiceAmount: 759.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CRC",
          minInvoiceAmount: 104.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CZK",
          minInvoiceAmount: 3.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "DKK",
          minInvoiceAmount: 9.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "EGP",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "EUR",
          minInvoiceAmount: 61.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "FJD",
          minInvoiceAmount: 26.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "GBP",
          minInvoiceAmount: 71.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "GEL",
          minInvoiceAmount: 22.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "GHS",
          minInvoiceAmount: 24.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "HKD",
          minInvoiceAmount: 8.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "HUF",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "IDR",
          minInvoiceAmount: 190.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "ILS",
          minInvoiceAmount: 16.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "INR",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "JPY",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "KES",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "KRW",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "LKR",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "MAD",
          minInvoiceAmount: 6.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "MXN",
          minInvoiceAmount: 4.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "MYR",
          minInvoiceAmount: 13.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "NGN",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "NOK",
          minInvoiceAmount: 6.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "NPR",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "NZD",
          minInvoiceAmount: 36.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "PHP",
          minInvoiceAmount: 10.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "PLN",
          minInvoiceAmount: 14.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "RON",
          minInvoiceAmount: 13.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "RUB",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "SEK",
          minInvoiceAmount: 6.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "SGD",
          minInvoiceAmount: 42.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "THB",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "TRY",
          minInvoiceAmount: 3.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "UAH",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "USD",
          minInvoiceAmount: 56.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "UYU",
          minInvoiceAmount: 144.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "VND",
          minInvoiceAmount: 24.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "XOF",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "ZAR",
          minInvoiceAmount: 3.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "ZMW",
          minInvoiceAmount: 4.0,
          fixedTargetPaymentAllowed: true,
        },
      ],
      totalTargetCurrencies: 51,
    },
    {
      currencyCode: "TRY",
      maxInvoiceAmount: 3000000.0,
      targetCurrencies: [
        {
          currencyCode: "EUR",
          minInvoiceAmount: 22.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "GBP",
          minInvoiceAmount: 25.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "USD",
          minInvoiceAmount: 20.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "AED",
          minInvoiceAmount: 6.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "ARS",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "AUD",
          minInvoiceAmount: 14.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "BGN",
          minInvoiceAmount: 11.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "BRL",
          minInvoiceAmount: 198.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "BWP",
          minInvoiceAmount: 146.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CAD",
          minInvoiceAmount: 15.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CHF",
          minInvoiceAmount: 22.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CLP",
          minInvoiceAmount: 25.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CNY",
          minInvoiceAmount: 3.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "COP",
          minInvoiceAmount: 267.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CRC",
          minInvoiceAmount: 37.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CZK",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "DKK",
          minInvoiceAmount: 3.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "EGP",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "FJD",
          minInvoiceAmount: 9.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "GEL",
          minInvoiceAmount: 8.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "GHS",
          minInvoiceAmount: 9.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "HKD",
          minInvoiceAmount: 3.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "HUF",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "IDR",
          minInvoiceAmount: 67.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "ILS",
          minInvoiceAmount: 6.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "INR",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "JPY",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "KES",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "KRW",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "LKR",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "MAD",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "MXN",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "MYR",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "NGN",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "NOK",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "NPR",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "NZD",
          minInvoiceAmount: 13.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "PHP",
          minInvoiceAmount: 4.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "PLN",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "RON",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "RUB",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "SEK",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "SGD",
          minInvoiceAmount: 15.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "THB",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "TRY",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "UAH",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "UYU",
          minInvoiceAmount: 51.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "VND",
          minInvoiceAmount: 9.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "XOF",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "ZAR",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "ZMW",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
      ],
      totalTargetCurrencies: 51,
    },
    {
      currencyCode: "HKD",
      maxInvoiceAmount: 1.0e7,
      targetCurrencies: [
        {
          currencyCode: "EUR",
          minInvoiceAmount: 9.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "GBP",
          minInvoiceAmount: 10.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "INR",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "USD",
          minInvoiceAmount: 8.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "AED",
          minInvoiceAmount: 3.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "ARS",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "AUD",
          minInvoiceAmount: 6.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "BGN",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "BRL",
          minInvoiceAmount: 80.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "BWP",
          minInvoiceAmount: 59.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CAD",
          minInvoiceAmount: 6.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CHF",
          minInvoiceAmount: 9.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CLP",
          minInvoiceAmount: 10.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CNY",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "COP",
          minInvoiceAmount: 107.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CRC",
          minInvoiceAmount: 15.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CZK",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "DKK",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "EGP",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "FJD",
          minInvoiceAmount: 4.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "GEL",
          minInvoiceAmount: 4.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "GHS",
          minInvoiceAmount: 4.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "HKD",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "HUF",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "IDR",
          minInvoiceAmount: 27.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "ILS",
          minInvoiceAmount: 3.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "JPY",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "KES",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "KRW",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "LKR",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "MAD",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "MXN",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "MYR",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "NGN",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "NOK",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "NPR",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "NZD",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "PHP",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "PLN",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "RON",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "RUB",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "SEK",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "SGD",
          minInvoiceAmount: 6.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "THB",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "TRY",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "UAH",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "UYU",
          minInvoiceAmount: 21.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "VND",
          minInvoiceAmount: 4.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "XOF",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "ZAR",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "ZMW",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
      ],
      totalTargetCurrencies: 51,
    },
    {
      currencyCode: "AED",
      maxInvoiceAmount: 1.0e7,
      targetCurrencies: [
        {
          currencyCode: "EUR",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: false,
        },
        {
          currencyCode: "GBP",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: false,
        },
        {
          currencyCode: "USD",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: false,
        },
        {
          currencyCode: "EGP",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: false,
        },
        {
          currencyCode: "AED",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: false,
        },
        {
          currencyCode: "ARS",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: false,
        },
        {
          currencyCode: "AUD",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: false,
        },
        {
          currencyCode: "BGN",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: false,
        },
        {
          currencyCode: "BRL",
          minInvoiceAmount: 38.0,
          fixedTargetPaymentAllowed: false,
        },
        {
          currencyCode: "BWP",
          minInvoiceAmount: 28.0,
          fixedTargetPaymentAllowed: false,
        },
        {
          currencyCode: "CAD",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: false,
        },
        {
          currencyCode: "CHF",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: false,
        },
        {
          currencyCode: "CLP",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: false,
        },
        {
          currencyCode: "CNY",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: false,
        },
        {
          currencyCode: "COP",
          minInvoiceAmount: 51.0,
          fixedTargetPaymentAllowed: false,
        },
        {
          currencyCode: "CRC",
          minInvoiceAmount: 7.0,
          fixedTargetPaymentAllowed: false,
        },
        {
          currencyCode: "CZK",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: false,
        },
        {
          currencyCode: "DKK",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: false,
        },
        {
          currencyCode: "FJD",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: false,
        },
        {
          currencyCode: "GEL",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: false,
        },
        {
          currencyCode: "GHS",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: false,
        },
        {
          currencyCode: "HKD",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: false,
        },
        {
          currencyCode: "HUF",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: false,
        },
        {
          currencyCode: "IDR",
          minInvoiceAmount: 13.0,
          fixedTargetPaymentAllowed: false,
        },
        {
          currencyCode: "INR",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: false,
        },
        {
          currencyCode: "JPY",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: false,
        },
        {
          currencyCode: "KES",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: false,
        },
        {
          currencyCode: "KRW",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: false,
        },
        {
          currencyCode: "LKR",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: false,
        },
        {
          currencyCode: "MAD",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: false,
        },
        {
          currencyCode: "MXN",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: false,
        },
        {
          currencyCode: "MYR",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: false,
        },
        {
          currencyCode: "NGN",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: false,
        },
        {
          currencyCode: "NOK",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: false,
        },
        {
          currencyCode: "NPR",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: false,
        },
        {
          currencyCode: "NZD",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: false,
        },
        {
          currencyCode: "PHP",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: false,
        },
        {
          currencyCode: "PLN",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: false,
        },
        {
          currencyCode: "RON",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: false,
        },
        {
          currencyCode: "RUB",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: false,
        },
        {
          currencyCode: "SEK",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: false,
        },
        {
          currencyCode: "SGD",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: false,
        },
        {
          currencyCode: "THB",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: false,
        },
        {
          currencyCode: "TRY",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: false,
        },
        {
          currencyCode: "UAH",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: false,
        },
        {
          currencyCode: "UYU",
          minInvoiceAmount: 10.0,
          fixedTargetPaymentAllowed: false,
        },
        {
          currencyCode: "VND",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: false,
        },
        {
          currencyCode: "XOF",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: false,
        },
        {
          currencyCode: "ZAR",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: false,
        },
        {
          currencyCode: "ZMW",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: false,
        },
      ],
      totalTargetCurrencies: 50,
    },
    {
      currencyCode: "EUR",
      maxInvoiceAmount: 1200000.0,
      targetCurrencies: [
        {
          currencyCode: "AED",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "ARS",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "AUD",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "BGN",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "BRL",
          minInvoiceAmount: 10.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "BWP",
          minInvoiceAmount: 7.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CAD",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CHF",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CLP",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CNY",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "COP",
          minInvoiceAmount: 13.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CRC",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CZK",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "DKK",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "EGP",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "EUR",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "FJD",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "GBP",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "GEL",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "GHS",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "HKD",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "HUF",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "IDR",
          minInvoiceAmount: 4.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "ILS",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "INR",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "JPY",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "KES",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "KRW",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "LKR",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "MAD",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "MXN",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "MYR",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "NGN",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "NOK",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "NPR",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "NZD",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "PHP",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "PLN",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "RON",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "RUB",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "SEK",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "SGD",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "THB",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "TRY",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "UAH",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "USD",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "UYU",
          minInvoiceAmount: 3.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "VND",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "XOF",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "ZAR",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "ZMW",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
      ],
      totalTargetCurrencies: 51,
    },
    {
      currencyCode: "DKK",
      maxInvoiceAmount: 8500000.0,
      targetCurrencies: [
        {
          currencyCode: "INR",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "EUR",
          minInvoiceAmount: 8.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "GBP",
          minInvoiceAmount: 9.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "DKK",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "AED",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "ARS",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "AUD",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "BGN",
          minInvoiceAmount: 4.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "BRL",
          minInvoiceAmount: 69.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "BWP",
          minInvoiceAmount: 51.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CAD",
          minInvoiceAmount: 6.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CHF",
          minInvoiceAmount: 8.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CLP",
          minInvoiceAmount: 9.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CNY",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "COP",
          minInvoiceAmount: 93.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CRC",
          minInvoiceAmount: 13.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CZK",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "EGP",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "FJD",
          minInvoiceAmount: 4.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "GEL",
          minInvoiceAmount: 3.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "GHS",
          minInvoiceAmount: 3.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "HKD",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "HUF",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "IDR",
          minInvoiceAmount: 24.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "ILS",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "JPY",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "KES",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "KRW",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "LKR",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "MAD",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "MXN",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "MYR",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "NGN",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "NOK",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "NPR",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "NZD",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "PHP",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "PLN",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "RON",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "RUB",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "SEK",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "SGD",
          minInvoiceAmount: 6.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "THB",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "TRY",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "UAH",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "USD",
          minInvoiceAmount: 7.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "UYU",
          minInvoiceAmount: 18.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "VND",
          minInvoiceAmount: 3.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "XOF",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "ZAR",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "ZMW",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
      ],
      totalTargetCurrencies: 51,
    },
    {
      currencyCode: "USD",
      maxInvoiceAmount: 1000000.0,
      targetCurrencies: [
        {
          currencyCode: "AED",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "ARS",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "AUD",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "BGN",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "BRL",
          minInvoiceAmount: 11.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "BWP",
          minInvoiceAmount: 8.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CAD",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CHF",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CLP",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CNY",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "COP",
          minInvoiceAmount: 14.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CRC",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CZK",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "DKK",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "EGP",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "EUR",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "FJD",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "GBP",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "GEL",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "GHS",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "HKD",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "HUF",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "IDR",
          minInvoiceAmount: 4.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "ILS",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "INR",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "JPY",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "KES",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "KRW",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "LKR",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "MAD",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "MXN",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "MYR",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "NGN",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "NOK",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "NPR",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "NZD",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "PHP",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "PLN",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "RON",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "RUB",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "SEK",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "SGD",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "THB",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "TRY",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "UAH",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "USD",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "UYU",
          minInvoiceAmount: 3.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "VND",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "XOF",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "ZAR",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "ZMW",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
      ],
      totalTargetCurrencies: 51,
    },
    {
      currencyCode: "CAD",
      maxInvoiceAmount: 1500000.0,
      targetCurrencies: [
        {
          currencyCode: "EUR",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "GBP",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "INR",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "USD",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "AED",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "ARS",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "AUD",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "BGN",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "BRL",
          minInvoiceAmount: 14.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "BWP",
          minInvoiceAmount: 10.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CAD",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CHF",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CLP",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CNY",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "COP",
          minInvoiceAmount: 19.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CRC",
          minInvoiceAmount: 3.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CZK",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "DKK",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "EGP",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "FJD",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "GEL",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "GHS",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "HKD",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "HUF",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "IDR",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "ILS",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "JPY",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "KES",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "KRW",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "LKR",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "MAD",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "MXN",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "MYR",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "NGN",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "NOK",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "NPR",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "NZD",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "PHP",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "PLN",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "RON",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "RUB",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "SEK",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "SGD",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "THB",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "TRY",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "UAH",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "UYU",
          minInvoiceAmount: 4.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "VND",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "XOF",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "ZAR",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "ZMW",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
      ],
      totalTargetCurrencies: 51,
    },
    {
      currencyCode: "MYR",
      maxInvoiceAmount: 30000.0,
      targetCurrencies: [
        {
          currencyCode: "EUR",
          minInvoiceAmount: 10.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "GBP",
          minInvoiceAmount: 10.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "INR",
          minInvoiceAmount: 10.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "USD",
          minInvoiceAmount: 10.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "AED",
          minInvoiceAmount: 10.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "ARS",
          minInvoiceAmount: 10.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "AUD",
          minInvoiceAmount: 10.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "BGN",
          minInvoiceAmount: 10.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "BRL",
          minInvoiceAmount: 46.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "BWP",
          minInvoiceAmount: 34.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CAD",
          minInvoiceAmount: 10.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CHF",
          minInvoiceAmount: 10.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CLP",
          minInvoiceAmount: 10.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CNY",
          minInvoiceAmount: 10.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "COP",
          minInvoiceAmount: 61.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CRC",
          minInvoiceAmount: 10.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CZK",
          minInvoiceAmount: 10.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "DKK",
          minInvoiceAmount: 10.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "EGP",
          minInvoiceAmount: 10.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "FJD",
          minInvoiceAmount: 10.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "GEL",
          minInvoiceAmount: 10.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "GHS",
          minInvoiceAmount: 10.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "HKD",
          minInvoiceAmount: 10.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "HUF",
          minInvoiceAmount: 10.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "IDR",
          minInvoiceAmount: 16.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "JPY",
          minInvoiceAmount: 10.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "KES",
          minInvoiceAmount: 10.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "KRW",
          minInvoiceAmount: 10.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "LKR",
          minInvoiceAmount: 10.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "MAD",
          minInvoiceAmount: 10.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "MXN",
          minInvoiceAmount: 10.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "MYR",
          minInvoiceAmount: 10.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "NGN",
          minInvoiceAmount: 10.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "NOK",
          minInvoiceAmount: 10.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "NPR",
          minInvoiceAmount: 10.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "NZD",
          minInvoiceAmount: 10.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "PHP",
          minInvoiceAmount: 10.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "PLN",
          minInvoiceAmount: 10.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "RON",
          minInvoiceAmount: 10.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "RUB",
          minInvoiceAmount: 10.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "SEK",
          minInvoiceAmount: 10.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "SGD",
          minInvoiceAmount: 10.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "THB",
          minInvoiceAmount: 10.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "TRY",
          minInvoiceAmount: 10.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "UAH",
          minInvoiceAmount: 10.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "UYU",
          minInvoiceAmount: 12.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "VND",
          minInvoiceAmount: 10.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "XOF",
          minInvoiceAmount: 10.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "ZAR",
          minInvoiceAmount: 10.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "ZMW",
          minInvoiceAmount: 10.0,
          fixedTargetPaymentAllowed: true,
        },
      ],
      totalTargetCurrencies: 50,
    },
    {
      currencyCode: "BGN",
      maxInvoiceAmount: 2000000.0,
      targetCurrencies: [
        {
          currencyCode: "EUR",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "GBP",
          minInvoiceAmount: 3.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "USD",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "BGN",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "AED",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "ARS",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "AUD",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "BRL",
          minInvoiceAmount: 19.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "BWP",
          minInvoiceAmount: 14.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CAD",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CHF",
          minInvoiceAmount: 3.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CLP",
          minInvoiceAmount: 3.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CNY",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "COP",
          minInvoiceAmount: 25.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CRC",
          minInvoiceAmount: 4.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CZK",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "DKK",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "EGP",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "FJD",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "GEL",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "GHS",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "HKD",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "HUF",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "IDR",
          minInvoiceAmount: 7.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "ILS",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "INR",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "JPY",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "KES",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "KRW",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "LKR",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "MAD",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "MXN",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "MYR",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "NGN",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "NOK",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "NPR",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "NZD",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "PHP",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "PLN",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "RON",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "RUB",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "SEK",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "SGD",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "THB",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "TRY",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "UAH",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "UYU",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "VND",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "XOF",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "ZAR",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "ZMW",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
      ],
      totalTargetCurrencies: 51,
    },
    {
      currencyCode: "NOK",
      maxInvoiceAmount: 1.15e7,
      targetCurrencies: [
        {
          currencyCode: "INR",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "PHP",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "GBP",
          minInvoiceAmount: 14.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "NOK",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "AED",
          minInvoiceAmount: 3.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "ARS",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "AUD",
          minInvoiceAmount: 8.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "BGN",
          minInvoiceAmount: 6.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "BRL",
          minInvoiceAmount: 107.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "BWP",
          minInvoiceAmount: 79.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CAD",
          minInvoiceAmount: 8.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CHF",
          minInvoiceAmount: 12.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CLP",
          minInvoiceAmount: 14.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CNY",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "COP",
          minInvoiceAmount: 144.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CRC",
          minInvoiceAmount: 20.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CZK",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "DKK",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "EGP",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "EUR",
          minInvoiceAmount: 12.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "FJD",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "GEL",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "GHS",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "HKD",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "HUF",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "IDR",
          minInvoiceAmount: 36.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "ILS",
          minInvoiceAmount: 3.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "JPY",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "KES",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "KRW",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "LKR",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "MAD",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "MXN",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "MYR",
          minInvoiceAmount: 3.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "NGN",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "NPR",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "NZD",
          minInvoiceAmount: 7.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "PLN",
          minInvoiceAmount: 3.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "RON",
          minInvoiceAmount: 3.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "RUB",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "SEK",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "SGD",
          minInvoiceAmount: 8.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "THB",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "TRY",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "UAH",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "USD",
          minInvoiceAmount: 11.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "UYU",
          minInvoiceAmount: 28.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "VND",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "XOF",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "ZAR",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "ZMW",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
      ],
      totalTargetCurrencies: 51,
    },
    {
      currencyCode: "RON",
      maxInvoiceAmount: 5300000.0,
      targetCurrencies: [
        {
          currencyCode: "EUR",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "USD",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "INR",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "RON",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "AED",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "ARS",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "AUD",
          minInvoiceAmount: 4.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "BGN",
          minInvoiceAmount: 3.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "BRL",
          minInvoiceAmount: 46.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "BWP",
          minInvoiceAmount: 34.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CAD",
          minInvoiceAmount: 4.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CHF",
          minInvoiceAmount: 6.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CLP",
          minInvoiceAmount: 6.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CNY",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "COP",
          minInvoiceAmount: 62.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CRC",
          minInvoiceAmount: 9.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CZK",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "DKK",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "EGP",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "FJD",
          minInvoiceAmount: 3.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "GBP",
          minInvoiceAmount: 6.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "GEL",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "GHS",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "HKD",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "HUF",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "IDR",
          minInvoiceAmount: 16.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "ILS",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "JPY",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "KES",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "KRW",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "LKR",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "MAD",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "MXN",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "MYR",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "NGN",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "NOK",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "NPR",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "NZD",
          minInvoiceAmount: 3.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "PHP",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "PLN",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "RUB",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "SEK",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "SGD",
          minInvoiceAmount: 4.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "THB",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "TRY",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "UAH",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "UYU",
          minInvoiceAmount: 12.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "VND",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "XOF",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "ZAR",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "ZMW",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
      ],
      totalTargetCurrencies: 51,
    },
    {
      currencyCode: "SGD",
      maxInvoiceAmount: 1000000.0,
      targetCurrencies: [
        {
          currencyCode: "EUR",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "GBP",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "INR",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "USD",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "AED",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "ARS",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "AUD",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "BGN",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "BRL",
          minInvoiceAmount: 14.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "BWP",
          minInvoiceAmount: 10.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CAD",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CHF",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CLP",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CNY",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "COP",
          minInvoiceAmount: 19.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CRC",
          minInvoiceAmount: 3.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CZK",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "DKK",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "EGP",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "FJD",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "GEL",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "GHS",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "HKD",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "HUF",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "IDR",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "ILS",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "JPY",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "KES",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "KRW",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "LKR",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "MAD",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "MXN",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "MYR",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "NGN",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "NOK",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "NPR",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "NZD",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "PHP",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "PLN",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "RON",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "RUB",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "SEK",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "SGD",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "THB",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "TRY",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "UAH",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "UYU",
          minInvoiceAmount: 4.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "VND",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "XOF",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "ZAR",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "ZMW",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
      ],
      totalTargetCurrencies: 51,
    },
    {
      currencyCode: "CZK",
      maxInvoiceAmount: 2.5e7,
      targetCurrencies: [
        {
          currencyCode: "EUR",
          minInvoiceAmount: 24.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "GBP",
          minInvoiceAmount: 28.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "INR",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CZK",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "AED",
          minInvoiceAmount: 6.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "ARS",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "AUD",
          minInvoiceAmount: 15.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "BGN",
          minInvoiceAmount: 13.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "BRL",
          minInvoiceAmount: 218.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "BWP",
          minInvoiceAmount: 161.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CAD",
          minInvoiceAmount: 17.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CHF",
          minInvoiceAmount: 25.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CLP",
          minInvoiceAmount: 28.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CNY",
          minInvoiceAmount: 4.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "COP",
          minInvoiceAmount: 294.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CRC",
          minInvoiceAmount: 41.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "DKK",
          minInvoiceAmount: 4.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "EGP",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "FJD",
          minInvoiceAmount: 10.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "GEL",
          minInvoiceAmount: 9.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "GHS",
          minInvoiceAmount: 10.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "HKD",
          minInvoiceAmount: 3.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "HUF",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "IDR",
          minInvoiceAmount: 74.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "ILS",
          minInvoiceAmount: 6.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "JPY",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "KES",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "KRW",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "LKR",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "MAD",
          minInvoiceAmount: 3.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "MXN",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "MYR",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "NGN",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "NOK",
          minInvoiceAmount: 3.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "NPR",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "NZD",
          minInvoiceAmount: 14.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "PHP",
          minInvoiceAmount: 4.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "PLN",
          minInvoiceAmount: 6.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "RON",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "RUB",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "SEK",
          minInvoiceAmount: 3.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "SGD",
          minInvoiceAmount: 17.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "THB",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "TRY",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "UAH",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "USD",
          minInvoiceAmount: 22.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "UYU",
          minInvoiceAmount: 56.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "VND",
          minInvoiceAmount: 10.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "XOF",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "ZAR",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "ZMW",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
      ],
      totalTargetCurrencies: 51,
    },
    {
      currencyCode: "SEK",
      maxInvoiceAmount: 1.25e7,
      targetCurrencies: [
        {
          currencyCode: "INR",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "GBP",
          minInvoiceAmount: 13.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "USD",
          minInvoiceAmount: 11.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "SEK",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "AED",
          minInvoiceAmount: 3.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "ARS",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "AUD",
          minInvoiceAmount: 7.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "BGN",
          minInvoiceAmount: 6.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "BRL",
          minInvoiceAmount: 104.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "BWP",
          minInvoiceAmount: 77.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CAD",
          minInvoiceAmount: 8.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CHF",
          minInvoiceAmount: 12.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CLP",
          minInvoiceAmount: 13.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CNY",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "COP",
          minInvoiceAmount: 141.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CRC",
          minInvoiceAmount: 20.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CZK",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "DKK",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "EGP",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "EUR",
          minInvoiceAmount: 12.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "FJD",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "GEL",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "GHS",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "HKD",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "HUF",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "IDR",
          minInvoiceAmount: 35.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "ILS",
          minInvoiceAmount: 3.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "JPY",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "KES",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "KRW",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "LKR",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "MAD",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "MXN",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "MYR",
          minInvoiceAmount: 3.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "NGN",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "NOK",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "NPR",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "NZD",
          minInvoiceAmount: 7.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "PHP",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "PLN",
          minInvoiceAmount: 3.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "RON",
          minInvoiceAmount: 3.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "RUB",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "SGD",
          minInvoiceAmount: 8.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "THB",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "TRY",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "UAH",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "UYU",
          minInvoiceAmount: 27.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "VND",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "XOF",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "ZAR",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "ZMW",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
      ],
      totalTargetCurrencies: 51,
    },
    {
      currencyCode: "NZD",
      maxInvoiceAmount: 2200000.0,
      targetCurrencies: [
        {
          currencyCode: "GBP",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "EUR",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "AUD",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "NZD",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "AED",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "ARS",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "BGN",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "BRL",
          minInvoiceAmount: 16.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "BWP",
          minInvoiceAmount: 12.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CAD",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CHF",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CLP",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CNY",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "COP",
          minInvoiceAmount: 22.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CRC",
          minInvoiceAmount: 3.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CZK",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "DKK",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "EGP",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "FJD",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "GEL",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "GHS",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "HKD",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "HUF",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "IDR",
          minInvoiceAmount: 6.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "ILS",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "INR",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "JPY",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "KES",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "KRW",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "LKR",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "MAD",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "MXN",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "MYR",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "NGN",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "NOK",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "NPR",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "PHP",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "PLN",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "RON",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "RUB",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "SEK",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "SGD",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "THB",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "TRY",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "UAH",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "USD",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "UYU",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "VND",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "XOF",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "ZAR",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "ZMW",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
      ],
      totalTargetCurrencies: 51,
    },
    {
      currencyCode: "BRL",
      maxInvoiceAmount: 1000000.0,
      targetCurrencies: [
        {
          currencyCode: "EUR",
          minInvoiceAmount: 50.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "USD",
          minInvoiceAmount: 50.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "AUD",
          minInvoiceAmount: 50.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "BRL",
          minInvoiceAmount: 50.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "AED",
          minInvoiceAmount: 50.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "ARS",
          minInvoiceAmount: 50.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "BGN",
          minInvoiceAmount: 50.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "BWP",
          minInvoiceAmount: 50.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CAD",
          minInvoiceAmount: 50.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CHF",
          minInvoiceAmount: 50.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CLP",
          minInvoiceAmount: 50.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CNY",
          minInvoiceAmount: 50.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "COP",
          minInvoiceAmount: 68.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CRC",
          minInvoiceAmount: 50.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CZK",
          minInvoiceAmount: 50.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "DKK",
          minInvoiceAmount: 50.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "EGP",
          minInvoiceAmount: 50.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "FJD",
          minInvoiceAmount: 50.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "GBP",
          minInvoiceAmount: 50.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "GEL",
          minInvoiceAmount: 50.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "HKD",
          minInvoiceAmount: 50.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "HUF",
          minInvoiceAmount: 50.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "IDR",
          minInvoiceAmount: 50.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "ILS",
          minInvoiceAmount: 50.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "INR",
          minInvoiceAmount: 50.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "JPY",
          minInvoiceAmount: 50.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "KES",
          minInvoiceAmount: 50.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "KRW",
          minInvoiceAmount: 50.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "LKR",
          minInvoiceAmount: 50.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "MXN",
          minInvoiceAmount: 50.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "MYR",
          minInvoiceAmount: 50.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "NGN",
          minInvoiceAmount: 50.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "NOK",
          minInvoiceAmount: 50.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "NPR",
          minInvoiceAmount: 50.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "NZD",
          minInvoiceAmount: 50.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "PLN",
          minInvoiceAmount: 50.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "RON",
          minInvoiceAmount: 50.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "RUB",
          minInvoiceAmount: 50.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "SEK",
          minInvoiceAmount: 50.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "SGD",
          minInvoiceAmount: 50.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "THB",
          minInvoiceAmount: 50.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "TRY",
          minInvoiceAmount: 50.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "UAH",
          minInvoiceAmount: 50.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "UYU",
          minInvoiceAmount: 50.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "VND",
          minInvoiceAmount: 50.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "XOF",
          minInvoiceAmount: 50.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "ZAR",
          minInvoiceAmount: 50.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "ZMW",
          minInvoiceAmount: 50.0,
          fixedTargetPaymentAllowed: true,
        },
      ],
      totalTargetCurrencies: 48,
    },
    {
      currencyCode: "UAH",
      maxInvoiceAmount: 1200000.0,
      targetCurrencies: [
        {
          currencyCode: "EUR",
          minInvoiceAmount: 40.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "GBP",
          minInvoiceAmount: 47.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "INR",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "USD",
          minInvoiceAmount: 37.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "AED",
          minInvoiceAmount: 10.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "ARS",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "AUD",
          minInvoiceAmount: 25.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "BGN",
          minInvoiceAmount: 21.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "BRL",
          minInvoiceAmount: 370.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "BWP",
          minInvoiceAmount: 273.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CAD",
          minInvoiceAmount: 28.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CHF",
          minInvoiceAmount: 41.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CLP",
          minInvoiceAmount: 47.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "COP",
          minInvoiceAmount: 499.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CRC",
          minInvoiceAmount: 69.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "CZK",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "DKK",
          minInvoiceAmount: 6.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "EGP",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "FJD",
          minInvoiceAmount: 17.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "GEL",
          minInvoiceAmount: 15.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "GHS",
          minInvoiceAmount: 16.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "HKD",
          minInvoiceAmount: 5.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "HUF",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "IDR",
          minInvoiceAmount: 125.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "ILS",
          minInvoiceAmount: 11.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "JPY",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "KES",
          minInvoiceAmount: 3.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "KRW",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "LKR",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "MAD",
          minInvoiceAmount: 4.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "MXN",
          minInvoiceAmount: 3.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "MYR",
          minInvoiceAmount: 9.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "NGN",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "NOK",
          minInvoiceAmount: 4.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "NPR",
          minInvoiceAmount: 3.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "NZD",
          minInvoiceAmount: 24.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "PHP",
          minInvoiceAmount: 7.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "PLN",
          minInvoiceAmount: 9.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "RON",
          minInvoiceAmount: 9.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "RUB",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "SEK",
          minInvoiceAmount: 4.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "SGD",
          minInvoiceAmount: 28.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "THB",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "TRY",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "UAH",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "UYU",
          minInvoiceAmount: 95.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "VND",
          minInvoiceAmount: 16.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "XOF",
          minInvoiceAmount: 1.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "ZAR",
          minInvoiceAmount: 2.0,
          fixedTargetPaymentAllowed: true,
        },
        {
          currencyCode: "ZMW",
          minInvoiceAmount: 3.0,
          fixedTargetPaymentAllowed: true,
        },
      ],
      totalTargetCurrencies: 50,
    },
  ],
  total: 27,
};

export default currencies;
