import "./TimeTrackerTeam.css";
import React, { useState } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
function TabModuleTrackerTeam() {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(0);
  // Update the active tab based on the current URL
  React.useEffect(() => {
    const pathname = location.pathname;
    if (pathname.includes('/user/time-tracker/team/members')) {
      setActiveTab(0);
    } else if (pathname.includes('/user/time-tracker/team/department')) {
      setActiveTab(1);
    } else if (pathname.includes('/user/time-tracker/team/type-of-leave')) {
      setActiveTab(2);
    }
  }, [location]);

  return (
    <div>
      <div className='tab-outer-time-team'>
        <ul className="tabs team-tab-s1">
          <li className={activeTab === 0 ? 'active-tab' : ''}>
            <Link to="/user/time-tracker/team/members">
              Members
            </Link>
          </li>
          <li className={activeTab === 1 ? 'active-tab' : ''}>
            <Link to="/user/time-tracker/team/department">
              Departments
            </Link>
          </li>
          <li className={activeTab === 2 ? 'active-tab' : ''}>
            <Link to="/user/time-tracker/team/type-of-leave">
              Types of Leave
            </Link>
          </li>
        </ul>
        <div className="tabs-content">
          <div className='tab-inner-time'>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}
export default TabModuleTrackerTeam;