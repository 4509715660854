import React, { useState } from "react";
function TruncateFieldView({ textContent }) {
  const [showFullText, setShowFullText] = useState(false);

  const handleMouseOver = () => {
    if (textContent.length > 16) {
      setShowFullText(true);
    }
  };

  const handleMouseOut = () => {
    setShowFullText(false);
  };

  const truncateText = (text, maxLength) => {
    if (text?.length > maxLength) {
      return text.substr(0, maxLength) + "...";
    }
    return text;
  };

  const fieldClass = showFullText ? "value-field full-text" : "value-field";

  return (
    <div className="tran-outer" onMouseOut={handleMouseOut}>
      <div
        className={fieldClass}
        onMouseOver={handleMouseOver}
        
      >
        {showFullText ? textContent : truncateText(textContent, 16)}
      </div>
    </div>
  );
}

export default TruncateFieldView;
