import axios from "axios";
import "../UserAccount/UserAccount.css";
import Modal from "react-modal";
import { toast } from "react-toastify";
import Select from "react-select";
import ReactPaginate from 'react-paginate';
import { useNavigate } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { AES } from 'crypto-js';
import { Link } from 'react-router-dom';
import userTypeName from "../../../../constants/userType";
import Close from "../../../../images/icons/close.svg";
import PlusIcon from "../../../../images/icons/plus-icon.svg";
import { logout, reset } from "../../../../features/auth/authSlice";
import profile_image from "../../../../images/user/Default_pfp.png";
import UpArrowImage from "../../../../images/icons/up-down-arrow.svg";
import DownArrowImage from "../../../../images/icons/up-down-arrow.svg";
import RemoveIcon from "../../../../images/icons/delete.svg";
import { setUserRoleName } from "../../../../features/users/usersSlice.js";
import { reset as invoiceReset } from "../../../../features/invoice/invoiceSlice.js";
import { reset as headerReset } from "../../../../features/headerText/headerTextSlice.js";
import { reset as appSettingsReset } from "../../../../features/appSettings/appSettingsSlice.js";
import { useTeamMembers, useUserList } from "./userUserTeamm.jsx";

const UserTeam = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "" });
  const [filter, setFilter] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [teamMembers, setTeamMembers] = useState([]);
  const [selectedTeamMembers, setSelectedTeamMembers] = useState([]);
  const [selectedId, setSelectedId] = useState("");
  const [selectedPage, setSelectedPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [showingDropdownOpen, setShowingDropdownOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isSubmited, setIsSubmited] = useState(false);
  const showingDropdownRef = useRef(null);
  let token = null;
  const { user } = useSelector((state) => state.auth);
  if (user) {
    if (user && user.token) {
      token = user.token;
    }
  }

  const { data: teamMemb = [], isLoading: isMemberLoading, refetch: refetchTeamMembers } = useTeamMembers(props.id, token);
  const { data: userId = [], isLoading: isUserLoading, refetch: refetchUsers } = useUserList(token);

  useEffect(() => {

    setTeamMembers(teamMemb);
    refetchUsers();
    refetchTeamMembers();
  }, [isLoading, props.id, teamMemb, isSubmited, dispatch]);

  const handleClickOutsideDropdown = (event) => {
    const isClickInsideshowingDropdown = showingDropdownRef.current && showingDropdownRef.current.contains(event.target);
    if (!isClickInsideshowingDropdown) {
      setShowingDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutsideDropdown);
    return () => {
      document.removeEventListener('click', handleClickOutsideDropdown);
    };
  }, []);

  const handleFilterChange = (e) => {
    setFilter(e.target.value)
    setSelectedPage(0);
  };

  const filteredData = teamMembers.filter((item) => {
    const searchTerm = filter.trim().toLowerCase();
    const fullName =
      (item.first_name || (item.company_first_name ? `${item.company_first_name} ${item.company_last_name ? item.company_last_name : ''}` : `${item.company_name} (Company)`)) +
      (item.middle_name && item.middle_name !== "null"
        ? " " + item.middle_name
        : "") +
      " " +
      item.last_name;
    const hasMatch =
      fullName.toLowerCase().includes(searchTerm) ||
      item.job_title.toLowerCase().includes(searchTerm) ||
      item.email.toLowerCase().includes(searchTerm);
    return hasMatch;
  });

  const teamMembersOptions = userId
    .slice()
    .sort((a, b) => {
      const nameA = a.first_name
        ? `${a.first_name} ${a.middle_name || ""} ${a.last_name}`
        : `${a.company_first_name ? a.company_first_name + (a.company_last_name && a.company_last_name) : a.company_name}`;
      const nameB = b.first_name
        ? `${b.first_name} ${b.middle_name || ""} ${b.last_name}`
        : `${b.company_first_name ? b.company_first_name + (b.company_last_name && b.company_last_name) : b.company_name}`;
      return nameA.localeCompare(nameB);
    })
    .map((item) => ({
      label: item.first_name ? `${item.first_name} ${item.middle_name || ""} ${item.last_name}` : (item.company_first_name ? `${item.company_first_name + (item.company_last_name && item.company_last_name)}` : `${item.company_name}(Company)`),
      value: item._id,
    }));

  const handleTeamMembers = async (event) => {
    event.preventDefault();

    const requestData = {
      team_manager: props.id,
      team_members: selectedTeamMembers.map(member => member.value),
    };

    try {
      setIsSubmitLoading(true);
      const response = await axios.put(`/api/team-members`, requestData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })


      const data = response.data;
      if (data.status === "error") {
        toast.error('Something went wrong while updating user details!', {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        // const Name = firstName ? `${firstName} ${middleName} ${lastName}`.trim() : `${companyName}`.trim();
        global.config.activityLog(
          window.location.href,
          "User",
          `Team members Updated successfully.`
        );
        toast.success("User details updated successfully!!!", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setIsSubmited(!isSubmited);
        closeCreateModal();
      }
    } catch (error) {
      console.log(error);
      global.config.slackMessage(error.toString());
      if (error.response?.data?.status === "deleted") {
        localStorage.removeItem("user");
        dispatch(logout());
        dispatch(reset());
        dispatch(invoiceReset());
        dispatch(headerReset());
        dispatch(appSettingsReset());
        toast.error('This user is deleted!', {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/");
        return;
      }
      alert("Something went wrong");
    } finally {
      setIsSubmitLoading(false);
    }
  };

  const sortTable = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    const sortedData = [...filteredData].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === "asc" ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === "asc" ? 1 : -1;
      }
      return 0;
    });

    setTeamMembers(sortedData);
    setSortConfig({ key, direction });
  };

  const handleTeamMemberDetails = (memberId, roleId) => {
    const encryptedId = AES.encrypt(memberId, 'encryptionKey').toString();
    const encodedId = encodeURIComponent(encryptedId).replace(/\//g, '%2F');
    const handleLinkClick = async () => {
      localStorage.setItem('activeTab', '0');
      if (roleId === userTypeName.Team_Manager) {
        await dispatch(setUserRoleName(userTypeName.Team_Manager));
      } else {
        await dispatch(setUserRoleName("otheruser"));
      }
    };

    return (
      <Link to={`../profile/${encodedId}`} className="cta-link" onClick={handleLinkClick}>
        Details
      </Link>
    );
  };

  const openCreateModal = () => {
    if (teamMembers) {
      const findTeamMemberOptions = (selectedTeamMembers, teamMembersOptions) => {
        return selectedTeamMembers.map(member => {
          const option = teamMembersOptions.find(opt => opt.value === member._id);
          return option ? option : { label: member.first_name ? `${member.first_name} ${member.middle_name || ""} ${member.last_name}` : (member.company_first_name ? `${member.company_first_name + (member.company_last_name && member.company_last_name)}` : `${member.company_name}(Company)`), value: member._id };
        });
      };
      setSelectedTeamMembers(findTeamMemberOptions(teamMembers, teamMembersOptions));
    }
    setIsCreateModalOpen(true);
  };

  const closeCreateModal = () => {
    setIsCreateModalOpen(false);
    setSelectedTeamMembers([]);
  };

  const handleDelete = (item) => {
    setIsDeleteModalOpen(true);
    setSelectedId(item._id);
  };

  const handleRemoveTeamMember = async (teamMemberId) => {
    try {
      setIsSubmitLoading(true);
      const response = await axios.post(
        `/api/team-members/remove`,
        {
          team_manager: props.id,
          user_to_remove: teamMemberId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data;
      if (data.status === "error") {
        toast.error('Something went wrong while removing the user from the team!', {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        toast.success("User removed from the team successfully!", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        handleCancelDelete();
        setIsSubmited(!isSubmited);
      }
    } catch (error) {
      console.error(error);
      global.config.slackMessage(error.toString());
      alert("Something went wrong");
    } finally {
      setIsSubmitLoading(false);
    }
  };

  const handleCancelDelete = () => {
    setSelectedId("");
    setIsDeleteModalOpen(false);
  };

  const handlePageChange = (selected) => {
    setSelectedPage(selected.selected);
  };

  const totalResults = filteredData.length;

  const pageCount = Math.ceil(filteredData.length / perPage); // Total number of pages

  const offset = perPage * selectedPage; // Offset of the current page
  const currentPageData = filteredData.slice(offset, offset + perPage);

  return (
    <>
      <div className="outer-account">
        <div className="account-top">
          <div className="button-sec-account">
            <a onClick={openCreateModal}>
              <span className="icon-invoice">
                <img src={PlusIcon} alt="Export" />
              </span>
              Add User
            </a>
          </div>
          <div className="search-sec-account">
            <div className="search-container">
              <input
                type="text"
                className="search-input"
                onChange={handleFilterChange}
                value={filter} placeholder="Search" />
            </div>
          </div>
        </div>
        <Modal
          className="userAccount-modal bank-account-mod"
          isOpen={isCreateModalOpen}
          onRequestClose={closeCreateModal}
          contentLabel="New Client Account"
          style={{
            content: {
              height: "auto",
              width: "70%",
              position: "fixed",
              top: "50%",
              left: "60%",
              transform: "translate(-50%, -50%)",
              parent: document.querySelector(".admin-outer.time.tracker"),
            },
          }}
        >
          <div className="modal">
            <div className="modal-close-invite outter-modal-main">
              <a className="" onClick={closeCreateModal}>
                <img src={Close} alt="Edit Icon" />{" "}
              </a>
            </div>
            <div className="modal-client-head">
              <h2 className="">Add User</h2>
            </div>
            <div className="form-field-client-outer">
              <form>
                <div className="form-field-client">
                  <label htmlFor="teamMember" className="form-label">
                    Team Members
                  </label>
                  {isUserLoading ?
                    <div className="outter-load-table">
                      <ThreeDots
                        height="37"
                        width="39"
                        radius="9"
                        color="#6479f9"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                      />
                    </div> :
                    <Select
                      isMulti
                      options={teamMembersOptions}
                      value={selectedTeamMembers}
                      onChange={(selectedOptions) => setSelectedTeamMembers(selectedOptions)}
                    />
                  }
                </div>

                {isSubmitLoading ?
                  (
                    <div className="outter-load-table">
                      <ThreeDots
                        height="38"
                        width="40"
                        radius="9"
                        color="#6479f9"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                      />
                    </div>
                  ) : (
                    <button type="button" onClick={handleTeamMembers} className="submit-client">
                      Create Team
                    </button>
                  )}
              </form>
            </div>
          </div>
        </Modal>
        <Modal
          className="delete-modal"
          isOpen={isDeleteModalOpen}
          onRequestClose={handleCancelDelete}
          contentLabel="Confirm Delete"
          style={{
            content: {
              height: "41%",
              width: "30%",
              position: "fixed",
              top: "36%",
              left: "50%",
              transform: "translate(-24.75%, 0%)",
              parent: document.querySelector(".admin-outer.time.tracker"),
            },
          }}
        >
          <div className="delete-modal-content">
            <h2>Confirm Delete</h2>
            <p>
              Are you sure you want to delete this member from the team?
            </p>
            {isSubmitLoading ? (
              <div className="outter-load-table">
                <ThreeDots
                  height="38"
                  width="40"
                  radius="9"
                  color="#6479f9"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
              </div>
            ) : (
              <div className="delete-modal-buttons">
                <button className="buttons-rejected" onClick={() => handleRemoveTeamMember(selectedId)} disabled={isSubmitLoading}>
                  Delete
                </button>
                <button onClick={handleCancelDelete}>
                  Cancel
                </button>
              </div>
            )}
          </div>
        </Modal>
        <div className="showing-count">
          <div className="filter-inner showing-result">
            <label>Showing</label>
            
            <div ref={showingDropdownRef} className="select-modal-mainM custom-dropdown-user-new-outer">
              <div className="selected-option custom-dropdown-user-new" onClick={() => setShowingDropdownOpen(!showingDropdownOpen)}>
                {totalResults < 10 ? totalResults.toString() : perPage.toString()}
              </div>
              {showingDropdownOpen && (
                <div className="custom-dropdown-new">
                  <ul className="showing-options-list">
                    {totalResults >= 10 && <li className={perPage === 10 ? 'selected-item' : ''} onClick={() => { setPerPage(10); setSelectedPage(0); setShowingDropdownOpen(false); }}>10</li>}
                    {totalResults >= 20 && <li className={perPage === 20 ? 'selected-item' : ''} onClick={() => { setPerPage(20); setSelectedPage(0); setShowingDropdownOpen(false); }}>20</li>}
                    {totalResults >= 30 && <li className={perPage === 30 ? 'selected-item' : ''} onClick={() => { setPerPage(30); setSelectedPage(0); setShowingDropdownOpen(false); }}>30</li>}
                    {totalResults >= 40 && <li className={perPage === 40 ? 'selected-item' : ''} onClick={() => { setPerPage(40); setSelectedPage(0); setShowingDropdownOpen(false); }}>40</li>}
                    {totalResults >= 10 && <li className={totalResults >= 10 && perPage === totalResults ? 'selected-item' : ''} onClick={() => { setPerPage(totalResults); setSelectedPage(0); setShowingDropdownOpen(false); }}>ALL</li>}
                    {/* Add more conditions as needed */}
                    {totalResults < 10 ? (
                      <li className={totalResults < 10 ? 'selected-item' : ''} onClick={() => { setPerPage(totalResults); setSelectedPage(0); setShowingDropdownOpen(false); }}>{totalResults}</li>
                    ) : null}
                  </ul>
                </div>
              )}
            </div>
            <label>of {totalResults} Results</label>
          </div>
        </div>
        <div className="account-table">
          <table>
            <thead>
              <tr>
                <th onClick={() => sortTable("fullName")}>
                  User Name{" "}
                  {sortConfig.key === "fullName" && sortConfig.direction === "asc" ? (
                    <img src={UpArrowImage} alt="Up Arrow" />
                  ) : (
                    <img src={DownArrowImage} alt="Down Arrow" />
                  )}
                </th>
                <th onClick={() => sortTable("job_title")}>
                  Job Title{" "}
                  {sortConfig.key === "job_title" &&
                    sortConfig.direction === "asc" ? (
                    <img src={UpArrowImage} alt="Up Arrow" />
                  ) : (
                    <img src={DownArrowImage} alt="Down Arrow" />
                  )}
                </th>
                <th onClick={() => sortTable("email")}>
                  Email{" "}
                  {sortConfig.key === "email" &&
                    sortConfig.direction === "asc" ? (
                    <img src={UpArrowImage} alt="Up Arrow" />
                  ) : (
                    <img src={DownArrowImage} alt="Down Arrow" />
                  )}
                </th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {isMemberLoading ? (
                <tr>
                  <td colSpan="7">
                    <div className="outter-load-table">
                      <ThreeDots
                        height="80"
                        width="80"
                        radius="9"
                        color="#6479f9"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                      />
                    </div>
                  </td>
                </tr>
              ) : (
                <>
                  {currentPageData.length > 0 ? (
                    <>
                      {currentPageData.map((item, index) => (
                        <tr key={item.id}>
                          <td>
                            <span className="user-image-listingteam">
                              {item.profile_image !== null && item.profile_image !== "null" ? (
                                <img
                                  src={`/uploads/lowProfileImage/${item.profile_image}`}
                                  alt={item.first_name}
                                  className="member-image"
                                  onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = '/uploads/lowProfileImage/user_default.png';
                                  }}
                                />
                              ) : (
                                <img src={profile_image} alt={item.first_name} className="member-image" />
                              )}
                            </span>
                            {item.first_name ? (
                              <>
                                {item.first_name}{" "}
                                {item.middle_name && item.middle_name !== "null"
                                  ? item.middle_name + " "
                                  : ""}
                                {item.last_name}{" "}
                              </>
                            ) : (
                              <>
                                {item.company_first_name
                                  ? `${item.company_first_name} ${item.company_last_name ? item.company_last_name : ''}`
                                  : `${item.company_name || ''}(Company)`
                                }
                              </>
                            )}
                          </td>
                          <td>{item.job_title}</td>
                          <td>{item.email}</td>
                          <td>
                            {handleTeamMemberDetails(item._id, item.role_id)}
                          </td>
                          <td>
                            <button
                              onClick={() => handleDelete(item)}
                              className="remove-user-button"
                            >
                              <img src={RemoveIcon} alt="Remove User" />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </>
                  ) : (
                    <tr>
                      <td colSpan="7">
                        <div className="outter-load-table">No results found</div>
                      </td>
                    </tr>
                  )}
                </>
              )}
            </tbody>
          </table>
        </div>
        <div className="pagination-container">
          {isMemberLoading ? (
            <>
            </>
          ) : (
            <>
              {pageCount > 1 && (
                <ReactPaginate
                  previousLabel={selectedPage > 0 ? "< Back" : null}
                  nextLabel={selectedPage < pageCount - 1 ? "Next >" : null}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={pageCount}
                  marginPagesDisplayed={1}
                  pageRangeDisplayed={1}
                  onPageChange={handlePageChange}
                  containerClassName={"pagination maininteampag"}
                  activeClassName={"active"}
                  forcePage={selectedPage}
                />
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default UserTeam;