
const CONTRACT_TYPE =
{
    FULL_TIME: '64670fbe6cb18be88f7cc3bc',
    PART_TIME: '646714b36cb18be88f7cc3c2',
    HOURLY: '6467154beb8ffe1659e9cd55'
};

module.exports = {
    CONTRACT_TYPE
}
