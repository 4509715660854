import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  timeoffReqUser: null,
  timeoffReqReqStatus: null,
  timeoffReqYear: null,
  timeoffReqCategory: null,
};

export const setReportingTimeoffReqUser = createAsyncThunk(
  "reporting/timeoffReq/user",
  async (timeoffReqUser) => {
    return timeoffReqUser;
  }
);

export const setReportingTimeoffReqReqStatus = createAsyncThunk(
  "reporting/timeoffReq/reqStatus",
  async (timeoffReqReqStatus) => {
    return timeoffReqReqStatus;
  }
);

export const setReportingTimeoffReqYear = createAsyncThunk(
  "reporting/timeoffReq/year",
  async (timeoffReqYear) => {
    if (timeoffReqYear !== null) {
    //   const date = startOfYear(new Date(year, 0, 1));
    //   date.setHours(0, 0, 0, 0);
      return timeoffReqYear;
    } else {
      return null;
    }
  }
);

export const setReportingTimeoffReqCategory = createAsyncThunk(
  "reporting/timeoffReq/category",
  async (timeoffReqCategory) => {
    return timeoffReqCategory;
  }
);

export const reportingTimeoffReqSlice = createSlice({
  name: "reportingTimeoffReq",
  initialState,
  reducers: {
    reset: (state) => {
      state.timeoffReqUser = null;
      state.timeoffReqReqStatus = null;
      state.timeoffReqYear = null;
      state.timeoffReqCategory = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setReportingTimeoffReqUser.fulfilled, (state, action) => {
      state.timeoffReqUser = action.payload;
    });
    builder.addCase(setReportingTimeoffReqReqStatus.fulfilled, (state, action) => {
      state.timeoffReqReqStatus = action.payload;
    });
    builder.addCase(setReportingTimeoffReqYear.fulfilled, (state, action) => {
      state.timeoffReqYear = action.payload;
    });
    builder.addCase(setReportingTimeoffReqCategory.fulfilled, (state, action) => {
      state.timeoffReqCategory = action.payload;
    });
  },
});

export const { reset } = reportingTimeoffReqSlice.actions;
export default reportingTimeoffReqSlice.reducer;
