import axios from 'axios';
import moment from "moment";
import { useEffect } from 'react';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useSelector, useDispatch } from "react-redux";

// export function useGetTeams(token) {
//   return useQuery('teams', async () => {
//     const response = await axios.get('/api/team', {
//       headers: {
//         Authorization: `Bearer ${token}`,
//       },
//     });
//     const sortedTeams = response.data.sort((a, b) => {
//       const nameA = a.first_name ? `${a.first_name} ${a.middle_name ? a.middle_name : ''} ${a.last_name}` : `${a.company_first_name ? a.company_first_name + (a.company_last_name && a.company_last_name) : a.company_name}`;
//       const nameB = b.first_name ? `${b.first_name} ${b.middle_name ? b.middle_name : ''} ${b.last_name}` : `${b.company_first_name ? b.company_first_name + (b.company_last_name && b.company_last_name) : b.company_name}`;
//       return nameA.localeCompare(nameB);
//     });
//     return sortedTeams;
//   });
// }

// export function useGetClients(token) {
//   return useQuery('clients', async () => {
//     const response = await axios.get('/api/client-details', {
//       headers: {
//         Authorization: `Bearer ${token}`,
//       },
//     });
//     return response.data;
//   });
// }


// export function useGetDepartments() {
//   return useQuery('departments', async () => {
//     const response = await axios.get('/api/user-department');
//     return response.data;
//   });
// }

export function useExpenseId(token) {
  return useQuery('expenseId', async () => {
    const response = await axios.get('/api/expense/id', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  });
}

export function useGetExpenseGraphData(token, expense, date, currency, allCurrency, defaultCurrency, forex, tz) {
  return useQuery(['expenseGraphData', expense, date, currency, allCurrency], async () => {
    const timeZoneDate = date && moment(date).tz(tz).format();
    const response = await axios.post(
      '/api/expense-data/chart',
      {
        date: timeZoneDate ? timeZoneDate : moment(new Date()).tz(tz).format(),
        team: expense.expTeam,
        client: expense.expClient,
        department: expense.expDepartment,
        category: expense.filterExpCategory,
        currency: currency,
        allCurrency: allCurrency,
        defaultCurrency,
        forex,
        tz
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const processedData = response.data.map(item => ({
      ...item,
      exps: parseFloat(item.exps).toFixed(3),
      day: moment(item.date).utc().format("ddd, MMM D")
    }));
    return processedData.reverse();
  });
}


export function useGetExpenseData(token, expense, startDate, endDate) {
  const { data: expenseData = [], isLoading, refetch } = useQuery(
    ['expenseData', expense, startDate, endDate],
    async () => {
      const result = await axios.post(
        '/api/expenses/data',
        {
          startDate: startDate && new Date(startDate) || null,
          // startDate: startDate && moment.tz(startDate,global.config.getTimeZone()).format() || null,
          endDate: endDate && new Date(endDate) || null,
          // endDate: endDate && moment.tz(endDate,global.config.getTimeZone()).format() || null,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return result.data;
    }
  );
  const expenseDataa = useSelector((state) => state.expenseData);
  const notification = useSelector((state) => state.notification.notificationState);
  const filteredData = useMemo(() => {
    refetch();
    let filtered = expenseData;

    if (expense.expTeam) {
      filtered = filtered.filter((item) => {
        return expense.expTeam.includes(item.exp_user._id);
      });
    }

    if (expense.expDepartment) {
      filtered = filtered.filter((item) => {
        return expense.expDepartment.includes(item.exp_user.department);
      });
    }

    if (expense.filterExpCategory) {
      filtered = filtered.filter((item) => {
        return expense.filterExpCategory.includes(item.exp_category._id);
      });
    }

    if (expense.filterExpCurrency) {
      // console.log(expense.filterExpCurrency);
      filtered = filtered.filter((item) => {
        return expense.filterExpCurrency.includes(item.exp_currency);
      });
    }

    if (expense.expClient) {
      filtered = filtered.filter((item) => {
        if (item?.exp_client?._id != null) {
          return expense.expClient.includes(item.exp_client._id);
        }
      });
    }

    // if (expense.project) {
    //   filtered = filtered.filter((item) => {
    //     return expense.project.includes(item.project._id);
    //   });
    // }
    // if (expense.tag) {
    //   filtered = filtered.filter((item) => {

    //     return expense.tag.includes(item.tag?._id);
    //   });
    // }

    return filtered;
  }, [expenseData, expense, expenseDataa, notification]);

  return {
    data: filteredData,
    isLoading,
  };
}

export function useGetClientExpenseData(token, clientId) {
  return useQuery(['clientExpenseData', clientId], async () => {
    const response = await axios.post('/api/expenses/client/data', {
      clientId: clientId,
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  });
}

export async function getBankDetailsByAppOwner(appId, wxComapny, token) {
  const requestData = { appId, wxComapny };

  try {
    const response = await axios.post('/api/app-owner-bank/by-appowner/get', requestData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status === 200) {
      return response.data.data;
    } else {
      throw new Error(`Unexpected response status: ${response.status}`);
    }

  } catch (error) {
    console.error("Error fetching bank details by App Owner:", error);
    if (error.response) {
      const { status, data } = error.response;
      console.error(`Response error: ${status} - ${data?.message || data}`);
      throw new Error(`Server responded with status ${status}: ${data?.message || "Unknown error"}`);
    } else if (error.request) {
      console.error("No response received:", error.request);
      throw new Error("No response received from the server. Please check your network connection.");
    } else {
      console.error("Error in request setup:", error.message);
      throw new Error(`Error in request setup: ${error.message}`);
    }
  }
}

export const useTotalBudgetOfExpense = (expenseCategories, token, forex, currency) => {

  return useQuery(['totalBudgetOfExpense', expenseCategories, forex, currency], async () => {
    try {
      const requestData = { expenseCategories, forex, currency };

      const response = await axios.post('/api/expenses/total-budget', requestData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data.totalBudget;
    } catch (error) {
      throw new Error('Error fetching total budget');
      global.config.slackMessage(error.toString());
    }
  });
};