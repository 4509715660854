import React, { PureComponent, useMemo } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { ThreeDots } from "react-loader-spinner";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import typeOfContract from '../../../../constants/typeOfContract';
import { clientTotalRevenueComparison } from './Services/reportRevenue';


const data = [
  {
    name: 'January',
    currentYear: 4000,
    lastYear: 2400,
    amt: 2400,
  },
  {
    name: 'February',
    currentYear: 3000,
    lastYear: 1398,
    amt: 2210,
  },
  {
    name: 'March',
    currentYear: 2000,
    lastYear: 800,
    amt: 2290,
  },
  {
    name: 'April',
    currentYear: 2780,
    lastYear: 3908,
    amt: 2000,
  },
  {
    name: 'May',
    currentYear: 1890,
    lastYear: 4800,
    amt: 2181,
  },
  {
    name: 'June',
    currentYear: 2390,
    lastYear: 3800,
    amt: 2500,
  },
  {
    name: 'July',
    currentYear: 3490,
    lastYear: 4300,
    amt: 2100,
  },
  {
    name: 'August',
    currentYear: 1490,
    lastYear: 4300,
    amt: 2100,
  },
  {
    name: 'September',
    currentYear: 2490,
    lastYear: 1300,
    amt: 2100,
  },
  {
    name: 'October',
    currentYear: 3490,
    lastYear: 2300,
    amt: 2100,
  },
  {
    name: 'November',
    currentYear: 3490,
    lastYear: 1300,
    amt: 2100,
  },
  {
    name: 'December',
    currentYear: 2490,
    lastYear: 2300,
    amt: 2100,
  },
];

const ReportTotalRevenueCompariosnGraph = ({ clientInvoices, clientInvoicesLoading, clientInvoicesError, trackReport, trackReportLoading, trackReportError, projectCost, expenses }) => {
  const reportingRevenue = useSelector((state) => state.reportingRevenue);
  const { forex } = useSelector((state) => state.forex);
  const settings = JSON.parse(localStorage.getItem("settings"));
  const setReportingCurrency = useSelector((state) => state.reportingRevenue.revenueCurrency);
  var currencies = "USD";
  if (setReportingCurrency && setReportingCurrency.length === 1) {
    currencies = setReportingCurrency[0];
  } else {
    currencies = settings.currency;
  }

  const processedData = useMemo(() => {
    return clientTotalRevenueComparison(
      trackReport,
      projectCost,
      clientInvoices,
      reportingRevenue,
      currencies,
      forex,
      typeOfContract,
      expenses
    );
  }, [trackReport, projectCost, clientInvoices, reportingRevenue, currencies, forex, typeOfContract, expenses]);

  const currentYear = reportingRevenue.revenueYear || new Date().getFullYear();
  const previousYear = currentYear - 1;
  const isCurrentYear = currentYear === new Date().getFullYear();

  if (clientInvoicesLoading || trackReportLoading) return <div className="outter-load-table">
    <ThreeDots
      height="80"
      width="80"
      radius="9"
      color="#6479f9"
      ariaLabel="three-dots-loading"
      wrapperStyle={{}}
      wrapperClassName=""
      visible={true}
    />
  </div>;
  if (clientInvoicesError || trackReportError) return <div>Error loading data.</div>;
  return (
    <div style={{ width: '100%', height: 542 }}>
      <ResponsiveContainer>
        <LineChart
          layout="horizontal"
          data={processedData}
          margin={{
            top: 20,
            right: 30,
            left: Math.max(
              0,
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: currencies,
              })
                .format(Math.max(...processedData.map((item) => Math.max(item.currentYear, item.lastYear))))
                .length
            ),
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" type="category" padding={{ left: 50 }} tickMargin={20} />
          <YAxis
            type="number"
            tickFormatter={(tick) => `${new Intl.NumberFormat("en-US", { style: "currency", currency: currencies || 'USD' }).format(tick)}`}
          />
          <Tooltip />
          <Legend />
          <Line dataKey="currentYear" name={isCurrentYear ? `Current Year (${currentYear})` : `Selected Year (${currentYear})`} formatter={(value) => `${new Intl.NumberFormat("en-US", { style: "currency", currency: currencies || 'USD' }).format(value)}`} stroke="#96A8BA" />
          <Line dataKey="lastYear" name={isCurrentYear ? `Last Year (${previousYear})` : `Previous Year (${previousYear})`} formatter={(value) => `${new Intl.NumberFormat("en-US", { style: "currency", currency: currencies || 'USD' }).format(value)}`} stroke="#6479F8" />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}

export default ReportTotalRevenueCompariosnGraph;