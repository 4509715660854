import React from 'react';
import { useSelector } from 'react-redux';
import { convertCurrencyHelper } from '../../../../helpers/forexConvertor';
import { calculateOutstandingBalance } from './Services/reportExpense';

// Functional component
function OutstandingBalance({ expenseData, expenseCategory }) {
  const reportingFinancialExpense = useSelector((state) => state.reportingExpense);
  const { forex } = useSelector((state) => state.forex);
  const settings = JSON.parse(localStorage.getItem("settings"));

  const setReportingCurrency = useSelector((state) => state.reportingExpense.expCurrency);
  var currencies = "USD";
  if (setReportingCurrency && setReportingCurrency.length === 1) {
    currencies = setReportingCurrency[0];
  } else {
    currencies = settings.currency;
  }

  const outstandingBalance = calculateOutstandingBalance(expenseData, expenseCategory, reportingFinancialExpense, currencies, forex);

  return (
    <div>
      <div className="overview-item">
        <div className="overview-title-outer">
          <div className="overview-title">
            Outstanding Balance
          </div>
          <div className="overview-icon">
            <svg width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g filter="url(#filter0_d_2991_20)">
                <circle cx="24.5" cy="24.5" r="20.5" fill="white" />
              </g>
              <path d="M28.999 28.5C28.811 28.5 28.62 28.447 28.451 28.336L24.451 25.708C24.17 25.522 24 25.209 24 24.872V20C24 19.447 24.447 19 25 19C25.553 19 26 19.447 26 20V24.333L29.549 26.664C30.011 26.968 30.139 27.588 29.836 28.049C29.644 28.342 29.324 28.5 28.999 28.5ZM25.714 34.975C25.478 34.992 25.24 35 25 35C19.486 35 15 30.514 15 25C15 19.486 19.486 15 25 15C28.151 15 31.112 16.512 32.988 19H30C29.447 19 29 19.447 29 20C29 20.553 29.447 21 30 21H34C35.103 21 36 20.103 36 19V15C36 14.447 35.553 14 35 14C34.447 14 34 14.447 34 15V17.104C31.743 14.543 28.473 13 25 13C18.383 13 13 18.383 13 25C13 31.617 18.383 37 25 37C25.288 37 25.573 36.989 25.856 36.969C26.407 36.93 26.822 36.451 26.782 35.901C26.743 35.351 26.27 34.926 25.714 34.975ZM36 24C35.447 24 35 24.447 35 25C35 25.455 34.969 25.913 34.908 26.36C34.834 26.908 35.218 27.412 35.764 27.486C35.81 27.492 35.855 27.495 35.9 27.495C36.393 27.495 36.822 27.131 36.89 26.63C36.962 26.094 36.999 25.545 36.999 25C36.999 24.447 36.553 24 36 24ZM35.137 29.396C34.653 29.128 34.044 29.308 33.78 29.792C33.563 30.188 33.316 30.574 33.045 30.94C32.717 31.384 32.812 32.01 33.255 32.339C33.435 32.471 33.643 32.534 33.849 32.534C34.156 32.534 34.458 32.393 34.655 32.129C34.978 31.69 35.275 31.227 35.535 30.752C35.801 30.268 35.623 29.66 35.139 29.395L35.137 29.396ZM30.406 33.416C30.023 33.662 29.623 33.883 29.215 34.072C28.714 34.305 28.496 34.899 28.729 35.4C28.899 35.765 29.259 35.979 29.637 35.979C29.778 35.979 29.921 35.95 30.057 35.886C30.548 35.657 31.03 35.393 31.489 35.097C31.954 34.798 32.088 34.18 31.789 33.715C31.49 33.252 30.871 33.118 30.407 33.415L30.406 33.416Z" fill="#6A7988" stroke="white" stroke-width="0.5" />
              <defs>
                <filter id="filter0_d_2991_20" x="0" y="0" width="49" height="49" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                  <feOffset />
                  <feGaussianBlur stdDeviation="2" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix type="matrix" values="0 0 0 0 0.468733 0 0 0 0 0.51145 0 0 0 0 0.554167 0 0 0 0.39 0" />
                  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2991_20" />
                  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2991_20" result="shape" />
                </filter>
              </defs>
            </svg>
          </div>
        </div>
        <div className="overview-rice">
          {new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: currencies,
          }).format(outstandingBalance)}
        </div>
      </div>

    </div>
  );
}

export default OutstandingBalance;
