import React from "react";
import InvoiceCreateManual from "../../components/Invoices/InvoiceCreateManual/InvoiceCreateManual";

function InvoiceCreateManualPage() {
    return (
        <div className="react-outerme">
            <div><InvoiceCreateManual /></div>
        </div>
    );
}

export default InvoiceCreateManualPage;
