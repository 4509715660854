import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ThreeDots } from "react-loader-spinner";
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, LabelList } from 'recharts';

const useProcessedData = (trackReport, trackReportLoading, isError, reportingOverview) => {
  return useMemo(() => {
    if (trackReportLoading || isError) return [];

    const currentYear = reportingOverview.year || new Date().getFullYear();
    const previousYear = currentYear - 1;

    const calculateTotalDuration = (projects, year) => {
      const clientsData = projects.reduce((acc, project) => {
        const projectYear = new Date(project.startTime).getFullYear();
        const clientName = project?.clientId?.client_name;
        const clientId = project?.clientId?._id;

        if (!acc[clientName]) {
          acc[clientName] = { name: clientName, duration: 0 };
        }

        if (
          projectYear === year &&
          !project?.project?.is_delete &&
          reportingOverview.project.includes(project?.project?._id) &&
          reportingOverview.currency.includes(project?.clientId?.preferred_currency || 'USD')
        ) {
          acc[clientName].duration += project.duration; // Convert duration from seconds to hours
        }

        return acc;
      }, {});

      return Object.values(clientsData).map(client => ({
        name: client.name,
        duration: client.duration.toFixed(2)
      }));
    };

    const currentYearData = calculateTotalDuration(trackReport, currentYear);
    const previousYearData = calculateTotalDuration(trackReport, previousYear);

    return currentYearData.map(data => ({
      name: data.name,
      currentYear: parseFloat(data.duration),
      lastYear: parseFloat(previousYearData.find(prevData => prevData.name === data.name)?.duration || 0)
    }));
  }, [trackReport, trackReportLoading, isError, reportingOverview]);
};

const ReportOverviewHoursPerClientGraph = ({ trackReport, trackReportLoading, trackReportError }) => {
  const reportingOverview = useSelector((state) => state.reportingOverview);
  const processedData = useProcessedData(trackReport, trackReportLoading, trackReportError, reportingOverview);

  const currentYear = reportingOverview.year || new Date().getFullYear();
  const previousYear = currentYear - 1;
  const isCurrentYear = currentYear === new Date().getFullYear();

  if (trackReportLoading) return <div className="outter-load-table">
    <ThreeDots
      height="80"
      width="80"
      radius="9"
      color="#6479f9"
      ariaLabel="three-dots-loading"
      wrapperStyle={{}}
      wrapperClassName=""
      visible={true}
    />
  </div>;
  if (trackReportError) return <div>Error loading data.</div>;

  const changeDurationFormat = (sec) => {
    const hours = Math.floor(sec / 3600);
    const minutes = Math.floor((sec % 3600) / 60);
    const seconds = sec % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  const calculateXTicks = () => {
    if (!processedData.length) return [];
    const maxDataValue = Math.max(...processedData.map(item => Math.max(item.currentYear, item.lastYear)));
    if (maxDataValue === 0) return [0];
    // const tickInterval = 10; // Set the interval between ticks
    const tickInterval = Math.ceil(maxDataValue / 10);
    const numOfTicks = Math.ceil(maxDataValue / tickInterval);
    const ticks = [];

    for (let i = 0; i <= numOfTicks + 4; i++) { // Adjust the number of ticks
      ticks.push(i * tickInterval);
    }

    return ticks;
  }

  const xAxisTicks = calculateXTicks();

  const calculateChartHeight = (dataLength) => {
    const rowHeight = 50; // Adjust this value to set the height per bar
    const baseHeight = 100; // Add some base height for padding and labels
    return dataLength * rowHeight + baseHeight;
  };
  

  return (
    <div className='graph-project-per-hour' style={{ width: '100%', height: Math.max(542, calculateChartHeight(processedData.length)), }}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          layout="vertical"
          data={processedData}
          barSize={13} // Set the height of each bar
          margin={{
            top: 20,
            right: 42,
            left: 0, // Adjust the left margin to remove white space
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" horizontal={false} vertical={true} />
          <XAxis
            type="number"
            domain={[0, 'dataMax + 10']}
            tickFormatter={(tick) => changeDurationFormat(tick)}
            ticks={xAxisTicks}
          />
          <YAxis
            type="category"
            dataKey="name"
            interval={0}
            minTickGap={-10}
            width={100} // Ensure enough width for client names
            tickMargin={10}
          />
          <Tooltip formatter={(value) => changeDurationFormat(value)} />
          <Legend />
          <Bar dataKey="currentYear" name={isCurrentYear ? `Current Year (${currentYear})` : `Selected Year (${currentYear})`} fill="#87ABE2">
            <LabelList dataKey="currentYear" position="right" formatter={(value) => value !== 0 ? changeDurationFormat(value) : null} />
          </Bar>
          <Bar dataKey="lastYear" name={isCurrentYear ? `Last Year (${previousYear})` : `Previous Year (${previousYear})`} fill="#C7B6F6">
            <LabelList dataKey="lastYear" position="right" formatter={(value) => value !== 0 ? changeDurationFormat(value) : null} />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}

export default ReportOverviewHoursPerClientGraph;