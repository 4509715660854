import React from "react";
import Invoices from "../../components/Invoices/Invoices";

function MainInvoices()  {
    return(
        <div className="react-outer">
            <Invoices />
        </div>
    )
}

export default MainInvoices;