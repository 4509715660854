import React, { useState, useEffect } from 'react';
import "./ClientOverviewListingRecentDocuments.css";
import axios from "axios";
import Modal from "react-modal";
import { saveAs } from "file-saver";
import { toast } from "react-toastify";
import { AES, enc } from 'crypto-js';
import { useSelector, useDispatch } from "react-redux";
import { ThreeDots } from 'react-loader-spinner';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useParams, Link, useNavigate } from "react-router-dom";
import moment from "moment";
import DeleteIcon from "../../../images/icons/delete-blue.svg";
import DownArrow from "../../../images/icons/line-down-arrow.svg";
import EyeIcon from "../../../images/icons/blue-eye-icon.svg";
import { useDocumentList } from '../../../features/client/document/documentService';
import { logout, reset } from "../../../features/auth/authSlice";
import { reset as invoiceReset } from "../../../features/invoice/invoiceSlice.js";
import { reset as headerReset } from "../../../features/headerText/headerTextSlice.js";
import { reset as appSettingsReset } from "../../../features/appSettings/appSettingsSlice.js";
import TruncateFieldView from '../../helpers/truncateFieldView';

const handleDownload = async (rowData, token) => {
  // const dispatch = useDispatch();
  // const navigate = useNavigate();

  // Logic to handle download
  // For example, you can use window.location.href to navigate to the download link
  if (rowData.doc_from === "myDevice") {

    // window.open(`./uploads/documents/client-documents/${rowData.doc_name}`, '_blank');
    try {
      const response = await axios.get(`/api/client/doc/download/${rowData.doc_name}`, {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const pdfBlob = new Blob([response.data], { type: "application/pdf" });
      saveAs(pdfBlob, `${rowData.doc_name}`);
    } catch (error) {
      console.error(error);
      global.config.slackMessage(error.toString());
      // if (error.response?.data?.status === "deleted") {
      //     localStorage.removeItem("user");
      //     dispatch(logout());
      //     dispatch(reset());
      //     dispatch(invoiceReset());
      //     dispatch(headerReset());
      //     dispatch(appSettingsReset());
      //     toast.error("This user is deleted!", {
      //         position: "bottom-right",
      //         autoClose: 1500,
      //         hideProgressBar: false,
      //         closeOnClick: true,
      //         pauseOnHover: true,
      //         draggable: true,
      //         progress: undefined,
      //         theme: "light",
      //     });
      //     navigate("/");
      //     return;
      // }
      toast.error("Error while downloading file!", {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      // Handle error
    }

  } else {

    window.open(rowData.doc_url, '_blank');

  }

};

const handleView = async (rowData) => {
  if (rowData.doc_from === "myDevice") {
    window.open(`../../uploads/documents/client-documents/${rowData.doc_name}`, '_blank');
  } else {
    window.open(rowData.doc_url, '_blank');
  }
};

export function downArrow(rowData, token) {
  return (
    <a onClick={() => handleDownload(rowData, token)} download>
      <img src={DownArrow} alt="down-arrow-icon" />
    </a>
  );
}
export function eyeIcon(rowData) {
  return (
    <a onClick={() => handleView(rowData)} target="_blank">
      <img src={EyeIcon} alt="down-arrow-icon" />
    </a>
  );
}


const RecentDocuments = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { encryptedId } = useParams();
  const decodedId = decodeURIComponent(encryptedId.replace(/%2F/g, '/'));
  const decryptedBytes = AES.decrypt(decodedId, 'encryptionKey');
  const id = decryptedBytes.toString(enc.Utf8);
  const [selectedId, setSelectedId] = useState("");
  const [isDocDeleteModalOpen, setIsDocDeleteModalOpen] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  let token = null;
  const { user } = useSelector((state) => state.auth);
  if (user) {
    if (user && user.token) {
      token = user.token;
    }
  }

  const { data: documents = [], isLoading: isFetchingDocument, refetch: documentRefetch } = useDocumentList(token, id);

  useEffect(() => {
    documentRefetch();
  }, [id]);

  const convertCreatedDate = (rowData) => {
    const timestamp = rowData.created_at;
    const formattedDate = moment(timestamp).format('MMMM DD, YYYY');
    return formattedDate;
  }

  const deleteIcon = (rowData) => {
    return (
      <a onClick={() => handleDocDelete(rowData, token)} download>
        <img src={DeleteIcon} alt="down-arrow-icon" />
      </a>
    );
  };

  const handleDocDelete = async (rowData) => {
    setSelectedId(rowData._id);
    setIsDocDeleteModalOpen(true);
  };

  const handleConfirmDocDelete = async () => {
    try {
      setIsSubmitLoading(true);
      const response = await axios.get(`/api/client/document/delete/${selectedId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      toast.success("Document removed successfully", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      // dispatch(setDocAdded());
      global.config.activityLog(window.location.href, 'Doc', `Doc (${selectedId}) deleted successfully`);
      documentRefetch();
      // navigate(`/client/projects/${encodeURIComponent(AES.encrypt(props.id, 'encryptionKey').toString().replace(/\//g, '%2F'))}`);
    } catch (error) {
      console.error(error);
      global.config.slackMessage(error.toString());
      if (error.response?.data?.status === "deleted") {
        localStorage.removeItem("user");
        dispatch(logout());
        dispatch(reset());
        dispatch(invoiceReset());
        dispatch(headerReset());
        dispatch(appSettingsReset());
        toast.error('This user is deleted!', {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/");
        return;
      }
      toast.error("Something went wrong", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } finally {
      setIsSubmitLoading(false);
      handleCancelDocDelete();
    }
  };

  const handleCancelDocDelete = () => {
    setSelectedId("");
    setIsDocDeleteModalOpen(false);
  };

  return (
    <div className="listing-recent-documents-inner">
      <div className="table-head-outer">
        <div className="table-head">
          Recent Documents
        </div>
        <div className="view-button">
          <Link to={`/client/documents/${encodeURIComponent(AES.encrypt(props.id, 'encryptionKey').toString().replace(/\//g, '%2F'))}`} className={window.location.pathname.startsWith('/client/documents') ? 'active' : ''}>
            View All
          </Link>
        </div>
      </div>
      <Modal
        className="delete-modal"
        isOpen={isDocDeleteModalOpen}
        onRequestClose={handleCancelDocDelete}
        contentLabel="Confirm Delete"
        style={{
          content: {
            height: "41%",
            width: "30%",
            position: "fixed",
            top: "36%",
            left: "50%",
            transform: "translate(-19.75%, 0%)",
            parent: document.querySelector(".admin-outer.time.tracker"),
          },
        }}
      >
        <div className="delete-modal-content">
          <h2>Confirm Delete</h2>
          <p>
            Are you sure you want to delete this Document?
          </p>
          <div className="delete-modal-buttons">
            <button className="buttons-rejected" onClick={handleConfirmDocDelete} disabled={isSubmitLoading}>
              {isSubmitLoading ?
                <div className="outter-load-table">
                  <ThreeDots
                    height="18"
                    width="20"
                    radius="9"
                    color="white"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                </div> : "Delete"}
            </button>
            <button onClick={handleCancelDocDelete} disabled={isSubmitLoading}>
              Cancel
            </button>
          </div>
        </div>
      </Modal>
      {isFetchingDocument ? (
        <ThreeDots
          height="80"
          width="80"
          radius="9"
          color="#405BFF"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          wrapperClassName=""
          visible={true}
        />
      ) : (
        <DataTable
          value={documents.slice(0, 5)}
          removableSort
          tableStyle={{ minWidth: "50rem", width: "100%", textAlign: "left" }}
          paginator={false}
          paginatorClassName="recent-document-pagination"
          rows={5}
          emptyMessage="No document found under this client"
        >
          <Column
            field="doc_id"
            header="Doc ID"
            sortable
            style={{ width: "19.5%" }}
          ></Column>
          <Column
            field="doc_type"
            header="Type"
            sortable
            style={{ width: "16.4%" }}
          ></Column>
          <Column
            field="doc_name"
            header="Name"
            body={(rowData) => (
              <TruncateFieldView textContent={rowData.doc_name} />
            )}
            sortable
            style={{ width: "19%" }}
          ></Column>
          <Column
            field="created_at"
            body={convertCreatedDate}
            header="Issue Date"
            sortable
            style={{ width: "20.4%" }}
          ></Column>
          {/* <Column
            field="expired_at"
            //   body={dateConvert}
            header="Expiration Date"
            sortable
            style={{ width: "17.4%" }}
          ></Column> */}
          <Column
            field="_id"
            body={eyeIcon}
            style={{ width: "3%" }}
          ></Column>
          <Column
            field="_id"
            body={(rowData) => downArrow(rowData, token)}
            style={{ width: "2%" }}
          ></Column>
          <Column
            field="_id"
            body={(rowData) => deleteIcon(rowData, token)}
            style={{ width: "3%" }}
          ></Column>
        </DataTable>
      )}
    </div>
  );

};
export default RecentDocuments;