import React, { useState, useEffect } from "react";

function TruncateProjectView({ textContent }) {
  const [showFullText, setShowFullText] = useState(false);
  const [truncateLength, setTruncateLength] = useState(8);

  const handleMouseOver = () => {
    if (textContent.length > truncateLength) {
      setShowFullText(true);
    }
  };

  const handleMouseOut = () => {
    setShowFullText(false);
  };

  useEffect(() => {
    const handleResize = () => {
      // Check the window width and set truncateLength accordingly
      if (window.innerWidth > 2500) {
        setTruncateLength(34);
      } else if (window.innerWidth > 1700) {
        setTruncateLength(30);
      } else if (window.innerWidth > 1500) {
        setTruncateLength(16);
      }
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Call handleResize on initial load
    handleResize();

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substr(0, maxLength) + "...";
    }
    return text;
  };

  const fieldClass = showFullText ? "value-field full-text" : "value-field";

  return (
    <div className="tran-outer">
      <div
        className={fieldClass}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
      >
        {showFullText ? textContent : truncateText(textContent, truncateLength)}
        <div className="tran-sec"></div>
      </div>
    </div>
  );
}

export default TruncateProjectView;
