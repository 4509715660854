import { useEffect, useRef } from 'react';

const InactivityHandler = () => {
  const inactivityTimer = useRef(null);

  // Set up an inactivity timeout of 1 hour (3600000 milliseconds)
  const setInactivityTimeout = () => {
    clearTimeout(inactivityTimer.current);
    inactivityTimer.current = setTimeout(() => {
      window.location.reload(); // Refresh the page after 1 hour of inactivity
    }, 3600000); // 1 hour in milliseconds 3600000
  };

  // Reset the inactivity timer on any user interaction
  const resetInactivityTimer = () => {
    setInactivityTimeout(); // Reset the timer
  };

  useEffect(() => {
    // Set the initial inactivity timeout
    setInactivityTimeout();

    // Add event listeners for user activity
    window.addEventListener('mousemove', resetInactivityTimer);
    window.addEventListener('keydown', resetInactivityTimer);
    window.addEventListener('click', resetInactivityTimer);
    window.addEventListener('scroll', resetInactivityTimer);

    // Cleanup event listeners and timeout on unmount
    return () => {
      clearTimeout(inactivityTimer.current);
      window.removeEventListener('mousemove', resetInactivityTimer);
      window.removeEventListener('keydown', resetInactivityTimer);
      window.removeEventListener('click', resetInactivityTimer);
      window.removeEventListener('scroll', resetInactivityTimer);
    };
  }, []);

  return null; // This component doesn't render anything
};

export default InactivityHandler;