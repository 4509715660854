import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ThreeDots } from 'react-loader-spinner';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LabelList } from 'recharts';
import { processedBudgetExpensesByCategory } from './Services/reportExpense';

const data = [
  { name: 'Rent', budgeted: 144, actual: 124 },
  { name: 'Utilities', budgeted: 155, actual: 139 },
  { name: 'Groceries', budgeted: 113, actual: 130 },
  { name: 'Transport', budgeted: 143, actual: 150 },
  { name: 'Entertainment', budgeted: 146, actual: 127 },
  { name: 'Healthcare', budgeted: 155, actual: 140 },
  { name: 'Miscellaneous', budgeted: 132, actual: 118 },
];

const BudgetExpense = ({ expenseData, expenseDataLoading, expenseDataError }) => {
  const reportingFinancialExpense = useSelector((state) => state.reportingExpense);
  const { forex } = useSelector((state) => state.forex);
  
  const settings = JSON.parse(localStorage.getItem("settings"));

  const setReportingCurrency = useSelector((state) => state.reportingExpense.expCurrency);
  var currencies = "USD";
  if(setReportingCurrency && setReportingCurrency.length===1){
     currencies = setReportingCurrency[0];
  }else{
    currencies = settings.currency;
  }


  const processedData = useMemo(() => {
    return processedBudgetExpensesByCategory(expenseData, reportingFinancialExpense, settings,currencies, forex);
  }, [expenseData, reportingFinancialExpense, settings,currencies, forex]);

  const calculateXTicks = () => {
    if (!processedData.length) return [];
    const maxDataValue = Math.max(...processedData.map(item => Math.max(item.budgeted, item.actual)));
    if (maxDataValue === 0) return [0];
    // const tickInterval = 10; // Set the interval between ticks
    const tickInterval = Math.ceil(maxDataValue / 10);
    const numOfTicks = Math.ceil(maxDataValue / tickInterval);
    const ticks = [];

    for (let i = 0; i <= numOfTicks + 4; i++) { // Adjust the number of ticks
      ticks.push(i * tickInterval);
    }

    return ticks;
  }

  const xAxisTicks = calculateXTicks();

  if (expenseDataLoading) return (
    <div className="outter-load-table">
      <ThreeDots
        height="80"
        width="80"
        radius="9"
        color="#6479f9"
        ariaLabel="three-dots-loading"
        visible={true}
      />
    </div>
  );

  if (expenseDataError) return <div>Error loading data.</div>;

  const calculateChartHeight = (dataLength) => {
    const rowHeight = 50;
    const baseHeight = 100;
    return dataLength * rowHeight + baseHeight;
  };

  const calculateLeftMargin = (data) => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    context.font = '12px Arial';

    const longestWordLength = data.reduce((maxLength, item) => {
      const firstWord = item.name.split(' ')[0];
      const wordWidth = context.measureText(firstWord).width;
      return Math.max(maxLength, wordWidth);
    }, 0);

    return Math.max(30, longestWordLength - 150);
  };

  const leftMargin = calculateLeftMargin(processedData);

  return (
    <div className='graph-project-per-hour' style={{ width: '100%', height: Math.max(542, calculateChartHeight(processedData.length)), }}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          layout="vertical"
          data={processedData}
          barSize={13} // Set the height of each bar
          margin={{
            top: 20,
            right: 42,
            left: leftMargin,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" horizontal={false} vertical={true} />
          <XAxis 
            type="number" 
            domain={[0, 'dataMax + 10']} 
            tickFormatter={(tick) => `${new Intl.NumberFormat('en-US', { style: 'currency', currency: currencies, }).format(0).replace(/[\d.,]/g, '')}${tick}`} 
            ticks={xAxisTicks}
          />
          <YAxis 
            type="category" 
            dataKey="name" 
            // width={150} // Ensure enough width for expense categories
            tickMargin={10} 
          />
          <Tooltip formatter={(value) => `${new Intl.NumberFormat("en-US", {style: "currency", currency: currencies,}).format(value)}`}/>
          <Legend />
          <Bar dataKey="budgeted" name="Budgeted" fill="#87ABE2">
            <LabelList dataKey="budgeted" position="right" formatter={(value) => `${new Intl.NumberFormat("en-US", {style: "currency", currency: currencies,}).format(value)}`} />
          </Bar>
          <Bar dataKey="actual" name="Actual Expenses" fill="#C7B6F6">
            <LabelList dataKey="actual" position="right" formatter={(value) => `${new Intl.NumberFormat("en-US", {style: "currency", currency: currencies,}).format(value)}`} />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}

export default BudgetExpense;
