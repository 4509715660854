import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFetchProjectDetails } from "../../TimeTracker/TimeTrackerProject/useProjectDetails";
import { useGetClients } from "../../TimeTracker/TimeTrackerOverview/useTimeTracker";
import ReportingClientTimeWorkedFilter from "./ReportTimeWorked/ReportingClientTimeWorkedFilter";
// import BudgetedTrackedGraphFilter from "./ReportTimeWorked/ReportBudgetedVsTrackedGraphFilter";
import ReportBudgetedTrackedGraph from "./ReportTimeWorked/ReportBudgetedVsTrackedGraph";
import { useFetchExpenses, useFetchProjectCosts, useFetchSprints, useFetchTrackReportDetails } from "../../../features/reporting/reportingOverview/useReportingOverview";
import BudgetedTrackedGraphFilter from "./ReportTimeWorked/GraphFilters/ReportBudgetedVsTracked/ReportBudgetedVsTrackedFilter";

function ReportingClientTimeWorked() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  let token = null;
  if (user) {
    if (user && user.token) {
      token = user.token;
    }
  }

  const { data: projects = [], isLoading: projectLoading, refetch: refetchProjects } = useFetchProjectDetails(token);
  const { data: clients = [], isLoading: clientLoading, isError: clientError } = useGetClients(token);
  const { data: trackReport = [], isLoading: trackReportLoading, isError: trackReportError } = useFetchTrackReportDetails(token);
  const { data: projectCost = [], isLoading: projectCostLoading, isError: projectCostError } = useFetchProjectCosts(token);
  const { data: sprints = [], isLoading: sprintLoading, isError: sprintError } = useFetchSprints(token);
  const { data: expenses = [], isLoading: expensesLoading, isError: expensesError } = useFetchExpenses(token);

  // useEffect(() => {
  //   dispatch(setHeaderText("Financial Reports"));
  // }, []);
  return (
    <div className="react-outer report-expense client-report-outer">
      <div className="invoice-outer-invoice invoice-outer-invoice-reprt">
        <ReportingClientTimeWorkedFilter projects={projects} clients={clients} trackReport={trackReport} projectCost={projectCost} sprints={sprints} expenses={expenses} />
      </div>
      <div className="repot-tatal-box-sec">
        
      </div>

      <div className="outer-report-graph-piechart">
        <div className="graph-main-outer-time graph-main-outer-time-report">
          <div className="top-graph">
            <BudgetedTrackedGraphFilter trackReport={trackReport} trackReportLoading={trackReportLoading} trackReportError={trackReportError} />
          </div>
          <ReportBudgetedTrackedGraph trackReport={trackReport} trackReportLoading={trackReportLoading} trackReportError={trackReportError} />
        </div>
        <div className="pie-chart-right-no">

        </div>
      </div>
    </div>
  );
}

export default ReportingClientTimeWorked;
