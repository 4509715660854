import axios from "axios";
export function getSubdomain() {
        const hostname = window.location.hostname;
        console.log('hostname', hostname);
        const parts = hostname.split('.');

        // Assuming the format is subdomain.domain.com
        if (parts.length > 2) {
                return parts[0]; // Extract the subdomain
        }
        return null; // No subdomain present
}

const getAllDomain = async () => {

        const settingsData = await axios.get('api/app-owner/get-subdomain', {
                params: {},
        });

       
        return settingsData.data;

}

export async function IsValidSubdomain() {
        
        const subdomain = await getSubdomain();
        
        try {
                const hostname = window.location.hostname;
        
                if(hostname!=="app.talenton.io" && hostname !=="talenton.app" && hostname !=="test.localhost" && hostname !=="localhost"){

                        const validSubdomains = await getAllDomain(); // Assuming getAllDomaiin is an async function
           
                        return subdomain && validSubdomains && validSubdomains.includes(subdomain);

                }else{

                        return true;
                }
              
           
        } catch (error) {
            console.error('Error fetching valid subdomains:', error);
            return true;
        }
    }