import axios from "axios";
import Modal from "react-modal";
import { AES, enc } from 'crypto-js';
import "../../../components/TimeTracker/TimeTrackerProject/TimeTrackerProject.css";
import "rsuite/dist/rsuite.min.css";
import Select from "react-select";
import subDays from 'date-fns/subDays';
import { toast } from "react-toastify";
import { DateRangePicker } from "rsuite";
// import "../../Dashboard/FilterCalendar.css";
import "react-toastify/dist/ReactToastify.css";
import { useParams, useNavigate } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import { useSelector, useDispatch } from 'react-redux';
import PlusIcon from "../../../images/icons/plus-icon.svg";
import React, { useState, useEffect, useRef } from "react";
// import TimeTrackerProjectTable from "./TimeTrackerProjectTable";
import { logout, reset } from "../../../features/auth/authSlice.js";
import crssrImage from "../../../images/singleinvoice/cross-red.svg";
import { reset as invoiceReset } from "../../../features/invoice/invoiceSlice.js";
import { reset as headerReset } from "../../../features/headerText/headerTextSlice.js";
import { reset as appSettingsReset } from "../../../features/appSettings/appSettingsSlice.js";
import { setProjectAdded } from "../../../features/projects/projectSlice.js";
import { useFetchClientProjectManager } from '../../../components/TimeTracker/TimeTrackerProject/useProjectDetails.jsx';
import { useProjectDetails } from "../../../features/projects/ProjectList.jsx";

const NewTeamMembers = (props) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { encryptedId, projectId } = useParams();
    const decodedId = decodeURIComponent(encryptedId.replace(/%2F/g, '/'));
    const decryptedBytes = AES.decrypt(decodedId, 'encryptionKey');
    const id = decryptedBytes.toString(enc.Utf8);
    const [isTeamMembersModalOpen, setIsTeamMembersModalOpen] = useState(false);
    const [isSubmitLoading, setIsSubmitLoading] = useState(false);
    const [selectedTeamMembers, setSelectedTeamMembers] = useState([]);
    const token = useSelector(
        (state) => state.auth.token
    )
    const { data: userId = [], isLoading: isUserLoading, refetch: refetchProjectManager } = useFetchClientProjectManager(token, id);
    const { data: project = [], isLoading: isLoadingProject, refetch: refetchProject } = useProjectDetails(token, projectId);

    const handleOpenTeamMembersModal = (item) => {
        if (item.project_manager) {
            const findProjectManagerOptions = (selectedTeamMembers, projectManagerOptions) => {
                return selectedTeamMembers.map(manager => {
                    const option = projectManagerOptions.find(opt => opt.value === manager._id);
                    return option ? option : { label: manager.first_name ? `${manager.first_name} ${manager.middle_name || ""} ${manager.last_name}` : (manager.company_first_name ? `${manager.company_first_name + (manager.company_last_name && manager.company_last_name)}` : `${manager.company_name}(Company)`), value: manager._id };
                });
            };
            setSelectedTeamMembers(findProjectManagerOptions(item.project_manager, projectManagerOptions));
        }
        setIsTeamMembersModalOpen(true);
    };
    
    const handleCloseTeamMembersModal = () => {
        setIsTeamMembersModalOpen(false);
        setSelectedTeamMembers([]);
    };

    useEffect(() => {
        const radioLabels = document.querySelectorAll(".radio-option label");
        radioLabels.forEach((label) => {
            label.addEventListener("click", (e) => {
                const radioInput = label.previousElementSibling;
                radioInput.checked = true;
            });
        });
    }, []);

    // const handleClickOutsideDropdown = (event) => {
    //     // const isClickInsideSprintProjectDropdown = sprintProjectDropdownRef.current && sprintProjectDropdownRef.current.contains(event.target);
    //     // if (!isClickInsideSprintProjectDropdown) {
    //     //   setSprintProjectDropdownOpen(false);
    //     // }
    // };

    // useEffect(() => {
    //     document.addEventListener('click', handleClickOutsideDropdown);
    //     return () => {
    //         document.removeEventListener('click', handleClickOutsideDropdown);
    //     };
    // }, []);


    const handleTeamMembersSubmit = async (event) => {
        event.preventDefault();
        setIsSubmitLoading(true);

        const requestData = {
            project_manager: selectedTeamMembers.map(manager => manager.value),
        };
        try {
            const response = await axios.put(
                `/api/project/team-members/update/${project._id}`,
                requestData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
            );
            const data = response.data;
            if (data.status === "error") {

                toast.error("Something went wrong", {
                    position: "bottom-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {

                toast.success("Team members to this project added successfully", {
                    position: "bottom-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                global.config.activityLog(window.location.href, 'Project Team-Members', `Team members to this project "${selectedTeamMembers}" added successfully`);
                handleCloseTeamMembersModal();
                dispatch(setProjectAdded());

            }
        } catch (error) {
            console.log(error);
            global.config.slackMessage(error.toString());
            if (error.response?.data?.status === "deleted") {
                localStorage.removeItem("user");
                dispatch(logout());
                dispatch(reset());
                dispatch(invoiceReset());
                dispatch(headerReset());
                dispatch(appSettingsReset());
                toast.error('This user is deleted!', {
                    position: "bottom-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                navigate("/");
                return;
            }
            toast.error("Something went wrong", {
                position: "bottom-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } finally {
            refetchProjectManager();
            refetchProject();
            setIsSubmitLoading(false);
        }
    };

    const projectManagerOptions = userId
        .slice()
        .sort((a, b) => {
            const nameA = a.first_name
                ? `${a.first_name} ${a.middle_name || ""} ${a.last_name}`
                : `${a.company_first_name ? a.company_first_name + (a.company_last_name && a.company_last_name) : a.company_name}`;
            const nameB = b.first_name
                ? `${b.first_name} ${b.middle_name || ""} ${b.last_name}`
                : `${b.company_first_name ? b.company_first_name + (b.company_last_name && b.company_last_name) : b.company_name}`;
            return nameA.localeCompare(nameB);
        })
        .map((item) => ({
            label: item.first_name ? `${item.first_name} ${item.middle_name || ""} ${item.last_name}` : (item.company_first_name ? `${item.company_first_name + (item.company_last_name && item.company_last_name)}` : `${item.company_name}(Company)`),
            value: item._id,
        }));


    return (

        <div className="button-sec-invoice">
            {isLoadingProject ? (
                <div>
                    <ThreeDots
                        height="18"
                        width="20"
                        radius="9"
                        color="#6479f9"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                    />
                </div>
            ) : (
                <a className="new-client" onClick={() => handleOpenTeamMembersModal(project)}>
                    <span className="icon-invoice">
                        <img src={PlusIcon} alt="New Client" />
                    </span>
                    Team Member
                </a>
            )}

            <div className="modal-invite-main-outter">
                <Modal
                    className="client-modal sprint-modal"
                    isOpen={isTeamMembersModalOpen}
                    onRequestClose={handleCloseTeamMembersModal}
                    contentLabel="Add Team-Members to the project"
                    style={{
                        content: {
                            height: "41%",
                            width: "30%",
                            position: "fixed",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            parent: document.querySelector(".admin-outer.time.tracker"),
                        },
                    }}
                >
                    <div className="modal">
                        <div className="modal-close-invite outter-modal-main">
                            <a className="" onClick={handleCloseTeamMembersModal}>
                                <img src={crssrImage} alt="Edit Icon" />{" "}
                            </a>
                        </div>
                        <div className="modal-client-head">
                            <h2 className="">Add Team members</h2>
                        </div>
                        <div className="form-field-client-outer">
                            <form onSubmit={handleTeamMembersSubmit}>

                                <div className="form-field-client">
                                    <label htmlFor="projectManager" className="form-label">
                                        Assigned Team
                                    </label>
                                    {isUserLoading ? (
                                        <div className="outter-load-table">
                                            <ThreeDots
                                                height="38"
                                                width="40"
                                                radius="9"
                                                color="#6479f9"
                                                ariaLabel="three-dots-loading"
                                                wrapperStyle={{}}
                                                wrapperClassName=""
                                                visible={true}
                                            />
                                        </div>
                                    ) : (
                                        <Select
                                            isMulti
                                            options={projectManagerOptions}
                                            value={selectedTeamMembers}
                                            onChange={(selectedOptions) => setSelectedTeamMembers(selectedOptions)}
                                        />
                                    )}
                                </div>


                                {(isSubmitLoading || isLoadingProject) ?
                                    (
                                        <div className="outter-load-table">
                                            <ThreeDots
                                                height="38"
                                                width="40"
                                                radius="9"
                                                color="#6479f9"
                                                ariaLabel="three-dots-loading"
                                                wrapperStyle={{}}
                                                wrapperClassName=""
                                                visible={true}
                                            />
                                        </div>
                                    ) : (
                                        <button type="submit" className="submit-client">
                                            Add Team Members
                                        </button>
                                    )}
                            </form>
                        </div>
                    </div>
                </Modal>
            </div>
        </div>
    )
}

export default NewTeamMembers;