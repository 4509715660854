import React, { useState, useRef, useEffect } from "react";
import { useNavigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import userTypeConsts from "../../constants/userTypeConsts";

function EquipmentLayout() {
    const navigate = useNavigate();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState("");
    const optionDropdownRef = useRef(null);
    const { user } = useSelector((state) => state.auth);

    const getOptionText = (path) => {
        switch (path) {
            case `/user/equipment/overview`:
                return "Overview";
            case `/user/equipment/equipment/category`:
                return "Manage Categories";
            default:
                return "";
        }
    };

    useEffect(() => {
        const currentPath = window.location.pathname;
        setSelectedOption(getOptionText(currentPath));
        const selectElement = document.querySelector('.mobile-tab-select');
        if (selectElement) {
            selectElement.value = currentPath;
        }
    }, []);

    const handleClickOutsideDropdown = (event) => {
        const isClickInsideOptionDropdown = optionDropdownRef.current && optionDropdownRef.current.contains(event.target);
        if (!isClickInsideOptionDropdown) {
            setDropdownOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutsideDropdown);
        return () => {
            document.removeEventListener('click', handleClickOutsideDropdown);
        };
    }, []);

    const handleOptionClick = (option, url) => {
        setSelectedOption(option);
        navigate(url);
        setDropdownOpen(false);
    };
    return (
        <div className="react-outer">
            <div className='tab-mobile-select'>
                <h4>Select an option</h4>
                <div className="custom-dropdown-user-new-outer">
                    <div className="selected-option custom-dropdown-user-new" onClick={() => setDropdownOpen(!dropdownOpen)} ref={optionDropdownRef} >
                        {selectedOption}
                    </div>
                    {dropdownOpen && (
                        <div className="custom-dropdown-new">
                            <ul className="showing-options-list">
                                <li onClick={() => handleOptionClick("Overview", "/user/equipment/overview")}>Overview</li>
                                {(user.userType === userTypeConsts.SUPER_ADMIN || user.userType === userTypeConsts.ADMIN) && (
                                    <li onClick={() => handleOptionClick("Manage Categories", "/user/equipment/equipment/category")}>Manage Categories </li>
                                )}
                            </ul>
                        </div>
                    )}
                </div>
            </div>
            <Outlet />
        </div>
    )
}

export default EquipmentLayout;