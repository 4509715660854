import axios from 'axios';
import { useQuery, useMutation, useQueryClient } from 'react-query';
const API_BASE_URL = '/api';

export const useFetchEqpCategory = (token) => {
    return useQuery('eqpCategories', async () => {
        const response = await axios.get(`${API_BASE_URL}/equip-categories`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    });
};

// Delete a EquipmentCategory
export const useDeleteEqpCategory = (token) => {
    const queryClient = useQueryClient(); // Get the queryClient
    return useMutation(
        (selectedId) =>
            axios.get(`/api/equip-category/delete/${selectedId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }),
        {
            onSuccess: () => {
                queryClient.invalidateQueries('eqpCategories');
            },
        }
    );
};

// Create new EquipmentCategory
export const useCreateEqpCategory = (token) => {
    const queryClient = useQueryClient(); // Get the queryClient
    return useMutation(
        (requestData) =>
            axios.post(`${API_BASE_URL}/equip-category/create`, requestData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }).then((response) => response.data),
        {
            onSuccess: () => {
                queryClient.invalidateQueries('eqpCategories');
            },
        }
    );
};

// update EqpCategory
export const useUpdateEqpCategory = (token) => {
    const queryClient = useQueryClient();
    return useMutation(
        ({ selectedId, eqp_category_name }) =>
            axios.put(`${API_BASE_URL}/equip-category/update/${selectedId}`, { eqp_category_name }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }),
        {
            onSuccess: () => {
                queryClient.invalidateQueries('eqpCategories');
            },
        }
    );
};