import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import { Outlet, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
// components
import Sidebar from "../components/Sidebar/Sidebar.jsx";
import MobilePage from "../components/Sidebar/MobilePageTitle.jsx";
import AdminNavbar from "../components/Navbar/AdminNavbar";

export default function Admin() {
  const navigate = useNavigate();

  const headerText = useSelector(
    (state) =>
      state.headerText.headerTextState &&
      state.headerText.headerTextState    //toLowerCase()
  );

  const sideBarText = useSelector(
    (state) => state.sideBarText.sideBarTextState
  );
  const { appSettings } = useSelector((state) => state.appSettings);
  const timerData = useSelector((state) => state.timerData.timerDataState);
  var start = localStorage?.getItem("startTime") !== "undefined" ? JSON.parse(localStorage?.getItem("startTime")) : null;
  var isRunning = localStorage?.getItem("startTime") !== "undefined" ? JSON.parse(localStorage?.getItem("isRunning")) : null;
  useEffect(() => {
    let token = null;
    const user = localStorage.getItem("user");
    if (user) {
      const parsedUser = JSON.parse(user);
      if (parsedUser && parsedUser.token) {
        token = parsedUser.token;
      }
    }

    if (!token) {
      navigate("/");
    }
  }, []);
  const isClientPage = () => {
    return window.location.pathname.startsWith("/client");
  };
  const isVendorPage = () => {
    return window.location.pathname.startsWith("/vendor");
  };
  const isUserDetailsPage = () => {
    return window.location.pathname.startsWith("/user/profile");
  };
  const isUserRegisterPage = () => {
    return window.location.pathname.startsWith("/user/register");
  };
  const handleCloseSideBar = () => {

  };
  useEffect(() => {
    let intervalId;

    const updateTitleAndTimer = () => {
      const runningTime = moment().diff(moment(start), "seconds");
      const hours = Math.floor(runningTime / 3600);
      const minutes = Math.floor((runningTime % 3600) / 60);
      const seconds = runningTime % 60;

      const formattedHours = String(hours).padStart(2, "0");
      const formattedMinutes = String(minutes).padStart(2, "0");
      const formattedSeconds = String(seconds).padStart(2, "0");

      document.title = `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    };

    if (isRunning) {
      // Update title immediately
      updateTitleAndTimer();

      // Set interval to update more frequently (e.g., every 100 milliseconds)
      intervalId = setInterval(updateTitleAndTimer, 100);
    } else {
      document.title = "Talenton";
    }

    // Cleanup the interval when component unmounts or when timer is stopped
    return () => clearInterval(intervalId);
  }, [isRunning, start, timerData]);

  const location = useLocation();
  return (
    <>
      <div className={`admin-outer ${headerText}`}>
        <div className="admin-inner">
          <div className="sidebar-nav">
            <Sidebar onCancel={handleCloseSideBar} />
          </div>
          <div className="outer-nav">
            <AdminNavbar />
            <div className="page-top-section-header">
              <div className="page-top-section-left">
                <div className="inner-page-head">
                <MobilePage />
                  {/* <span className='icon-menu'>
                    <svg className='f-rec4' width="18" height="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect x="1" y="1" width="7" height="7" stroke="#6A7988" strokeWidth="1.5" strokeLinejoin="round" />
                      <rect x="12" y="1" width="7" height="7" stroke="#6A7988" strokeWidth="1.5" strokeLinejoin="round" />
                      <rect x="12" y="12" width="7" height="7" stroke="#6A7988" strokeWidth="1.5" strokeLinejoin="round" />
                      <rect x="1" y="12" width="7" height="7" stroke="#6A7988" strokeWidth="1.5" strokeLinejoin="round" />
                    </svg>
                  </span>
                  Dashboard */}
                </div>
              </div>
              <div className="page-top-section-right">
              <div className='comapany-name-mobile'>{appSettings && appSettings.app_ownername ? appSettings.app_ownername : "TalentOn"}</div>
              </div>
            </div>
            <div className={`content-outer ${headerText} ${isVendorPage() ? "vendors" : ""}${isClientPage() ? "clients" : ""}${isUserDetailsPage() ? "user details" : ""}${isUserRegisterPage() ? "user register" : ""}`} >
              <div className="content-inner">
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div></div>
    </>
  );
}
