import React from 'react'
import ClientDocument from '../../components/Clients/ClientDocument/ClientDocument'

const Document = () => {
    return (
        <>
            <ClientDocument />
        </>
    )
}

export default Document;