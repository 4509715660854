import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import masterAuthService from "./masterAuthService";
//get masterUser from localStaorage

const masterUser = JSON.parse(localStorage.getItem("masterUser"));

const initialState = {
  masterUser: masterUser ? masterUser : null,
  token: masterUser ? masterUser.token : null,
  masterLoginTemp: null,
  isError: false,
  isSucess: false,
  isLoading: false,
  message: "",
};
//Register masterUser

export const masterRegister = createAsyncThunk(
  "auth/master-user/register",
  async (masterUser, thunkAPI) => {
    try {
      return await masterAuthService.register(masterUser);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.string();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const masterLogin = createAsyncThunk("auth/master-user/login", async (masterUser) => {
  await masterAuthService.login(masterUser);
  return masterUser;
});

export const masterLogout = createAsyncThunk("auth/master-user/logout", async () => {
  localStorage.clear();
  await masterAuthService.logout();
});

export const masterLoginTemp = createAsyncThunk("auth/master-user/loginTemp", async (masterUser) => {
  return masterUser;
});

export const masterToggleNotification = createAsyncThunk(
  "auth/master-user/toggleNotification",
  async (_, thunkAPI) => {
    const { masterUser } = thunkAPI.getState().auth;
    const updatedUser = {
      ...masterUser,
      is_notification_enabled: !masterUser.is_notification_enabled,
    };

    localStorage.setItem('masterUser',JSON.stringify(updatedUser))
   
    try {
      return updatedUser;
    } catch (error) {
      // Handle any errors and reject with an error message
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const masterUpdateProfileImage = createAsyncThunk(
  "auth/master-user/updateProfileImage",
  async (newProfileImage, thunkAPI) => {
    const { masterUser } = thunkAPI.getState().auth;
    const updatedUser = {
      ...masterUser,
      profile_image: newProfileImage,
    };
    localStorage.setItem('masterUser',JSON.stringify(updatedUser))
    try {
      return updatedUser;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const masterAuthSlice = createSlice({
  name: "masterAuth",
  initialState,
  reducers: {
    reset: (state) => {
      state.masterUser = null;
      state.token = null;
      state.masterLoginTemp = null;
      state.isLoading = false;
      state.isError = false;
      state.isSucess = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(masterRegister.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(masterRegister.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSucess = true;
        state.masterUser = action.payload;
      })
      .addCase(masterRegister.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.masterUser = null;
      })
      .addCase(masterLogout.fulfilled, (state) => {
        state.masterUser = null;
        state.masterLoginTemp = null;
        state.token = null;
      })
      .addCase(masterLogin.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSucess = true;
        state.masterUser = action.payload;
        state.token = action.payload.token;
        state.masterLoginTemp = action.payload;
      })
      .addCase(masterLoginTemp.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSucess = true;
        state.masterLoginTemp = action.payload;
      })
      .addCase(masterToggleNotification.fulfilled, (state, action) => {
        state.masterUser = action.payload;
      })
      .addCase(masterUpdateProfileImage.fulfilled, (state, action) => {
        state.masterUser = action.payload;
      });
  },
});

export const { reset } = masterAuthSlice.actions;
export default masterAuthSlice.reducer;