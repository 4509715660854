const register = async (userData) => {
    localStorage.setItem('user', JSON.stringify(userData))
}

const logout = async () => {
}

const login = async (userData) => {
    localStorage.setItem('user', JSON.stringify(userData));
}

const authService = {
    register: register,
    logout: logout,
    login: login
}

export default authService;