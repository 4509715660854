import moment from "moment";
import projectService from './projectService';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
//get user from localStaorage

const initialState = {
    projectAdded: null,
    sprintView: false,
    sprintData: null,
    sprintStatusView: false
}
//Register user

export const setProjectAdded = createAsyncThunk('project/added', async () => {
    return moment().valueOf();
})

export const setSprintView = createAsyncThunk('sprint/view', async (val) => {
    return val;
})
export const setSprintData = createAsyncThunk('sprint/data', async (val) => {
    return val;
})

export const setSprintStatusView = createAsyncThunk('sprintStatus/view', async (val) => {
    return val;
})


export const projectSlice = createSlice({
    name: 'Project',
    initialState,
    reducers: {
        reset: (state) => {
            state.projectAdded = null
            state.sprintView = null
            state.sprintData = null
            state.sprintStatusView = null

        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(setProjectAdded.fulfilled, (state, action) => {
                state.projectAdded = action.payload
            })
            .addCase(setSprintView.fulfilled, (state, action) => {
                state.sprintView = action.payload
            })
            .addCase(setSprintData.fulfilled, (state, action) => {
                state.sprintData = action.payload
            })
            .addCase(setSprintStatusView.fulfilled, (state, action) => {
                state.sprintStatusView = action.payload
            })
    }
})

export const { reset } = projectSlice.actions
export default projectSlice.reducer 