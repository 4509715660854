import axios from "axios";
import { AES, enc } from 'crypto-js';
import { toast } from "react-toastify";
import { DateRangePicker } from "rsuite";
import { useNavigate, useParams } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import { ResizeObserver } from 'resize-observer';
import { useSelector, useDispatch } from "react-redux";
import React, { useState, useEffect, useRef } from "react";
import { logout, reset } from "../../../../features/auth/authSlice";
import userTypeConsts from "../../../../constants/userTypeConsts.js";
import { reset as invoiceReset } from "../../../../features/invoice/invoiceSlice.js";
import { setClientInvoiceData } from "../../../../features/client/clientInvoiceSlice.js";
import { reset as headerReset } from "../../../../features/headerText/headerTextSlice.js";
import { reset as appSettingsReset } from "../../../../features/appSettings/appSettingsSlice.js";
import { useGetClients } from "../../../TimeTracker/TimeTrackerOverview/useTimeTracker.jsx";

const ClientsInvoicesModal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [clientDropdownOpen, setClientDropdownOpen] = useState(false);
  const [clientSearchTerm, setClientSearchTerm] = useState('');
  const [clientSelectedId, setClientSelectedId] = useState('');
  const clientDropdownRef = useRef(null);
  const dropdownListRef = useRef(null);
  const [errorMessage, setErrorMessage] = useState({ message: null, field: null });
  const { appSettings } = useSelector((state) => state.appSettings);
  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });

  let token = null;
  const user = useSelector(
    (state) => state.auth.user
  )
  if (user) {
    if (user && user.token) {
      token = user.token;
    }
  }

  const handleSelect = (ranges) => {
    setDateRange(ranges);
    setErrorMessage({ message: null, field: null });
  };
  const SUPER_ADMIN = 1;
  const ADMIN = 2;
  const ref = useRef(null);
  let resizeObserver = null;

  // const { data: userId = [], isLoading: isUserLoading } = useUserById(token);
  const { data: clients = [], isLoading: clientLoading, refetch: refetchClientProfiles, isError: clientError } = useGetClients(token);

  const handleToggleClientDropdown = () => {
    setClientSearchTerm('');
    setClientDropdownOpen((prevOpen) => !prevOpen);
  };

  const handleClientSearchInputChange = (e) => {
    setClientSearchTerm(e.target.value);
  };

  const filteredClients = clients.filter((client) => {
    const fullName = client.first_name
      ? `${client.client_name}`
      : `${client.legal_entity_name}`;
    return fullName?.toLowerCase().includes(clientSearchTerm?.toLowerCase());
  });

  const handleSelectClient = (selectedClientId) => {
    setErrorMessage({ message: null, field: null });
    setClientSelectedId(selectedClientId);
    setClientSearchTerm('');
    setClientDropdownOpen(false);
  };

  const handleClickOutsideDropdown = (event) => {
    const isClickInsideClientDropdown = clientDropdownRef.current && clientDropdownRef.current.contains(event.target);
    if (!isClickInsideClientDropdown) {
      setClientDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutsideDropdown);
    return () => {
      document.removeEventListener('click', handleClickOutsideDropdown);
    };
  }, []);

  useEffect(() => {
    // Get the selected item
    const selectedItem = document.querySelector('.selected-item');
    // Get the container element
    const dropdownListContainer = dropdownListRef.current;

    // Scroll to the selected item and center it in the view
    if (selectedItem && dropdownListContainer) {
      selectedItem.scrollIntoView({ behavior: 'smooth', block: 'center' });
      // selectedItem.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  }, [clientDropdownOpen, clientSearchTerm, filteredClients, clientSelectedId]);

  useEffect(() => {
    // Create a new ResizeObserver instance
    resizeObserver = new ResizeObserver(entries => {
      // Handle resize event
    });

    if (ref.current) {
      // Observe the target element
      resizeObserver.observe(ref.current);
    }

    // Cleanup function
    return () => {
      if (resizeObserver) {
        // Disconnect the observer when component unmounts
        resizeObserver.disconnect();
        resizeObserver = null;
      }
    };
  }, []);

  const clientInvoiceSubmit = async () => {
    var autoData = [];

    if (clientSelectedId === "" || Object.keys(clientSelectedId).length === 0) {
      toast.error("Please select client", {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setErrorMessage({ message: "Please Select different date", field: "both" })
      return;
    } else {
    }
    if (dateRange === null) {
      toast.error("Please select date", {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    } else if (dateRange[0] === dateRange[1]) {
      toast.error("Please select date", {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    } else if (dateRange[0].getTime() === dateRange[1].getTime()) {
      toast.error("Please select different date", {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    } else {
      autoData.push(dateRange);
      autoData.push(clientSelectedId);
      await dispatch(setClientInvoiceData(autoData));
      navigate(`/client/invoices/create/${encodeURIComponent(AES.encrypt(clientSelectedId, 'encryptionKey').toString().replace(/\//g, '%2F'))}`);
    }
  };

  return (
    <div className="invoice-modal" ref={ref}>
      <h2 className="invoice-modal-main-head">Create Invoice</h2>
      <div className="form-group-1modal userseloutter-1">
        {clientLoading ?
          null :
          <label htmlFor="user-select">Clients *</label>
        }
        {clientLoading ?
          <div className="outter-load-table">
            <ThreeDots
              height="37"
              width="39"
              radius="9"
              color="#6479f9"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div> :
          <div ref={clientDropdownRef} className={errorMessage && errorMessage.field === 'both' ? "select-modal-mainM error custom-dropdown-user-new-outer" : "select-modal-mainM custom-dropdown-user-new-outer"}>
            <span
              id="user-select"
              onClick={handleToggleClientDropdown}
              className="custom-dropdown-user-new"
            >
              {clientSelectedId ?
                clients.find((item) => item._id === clientSelectedId)?.client_name :
                'Select Client'
              }
            </span>
            {(user.userType === userTypeConsts.SUPER_ADMIN || user.userType === userTypeConsts.ADMIN || user.userType === userTypeConsts.PROJECT_MANAGER) && clientDropdownOpen && (
              <div className="custom-dropdown-new" ref={dropdownListRef}>
                <input
                  type="text"
                  className="search-bar"
                  placeholder="Search..."
                  value={clientSearchTerm}
                  onChange={handleClientSearchInputChange}
                />
                <ul className="client-list">
                  {filteredClients.length > 0 &&
                    filteredClients
                      .slice()
                      .sort((a, b) =>
                        a.client_name?.toLowerCase().localeCompare(b.client_name?.toLowerCase())
                      )
                      .map((client) => (
                        <li key={client._id} onClick={() => handleSelectClient(client._id)} className={clientSelectedId === client._id ? 'selected-item' : ''}>
                          {client.client_name && `${client.client_name}`}
                        </li>
                      ))
                  }
                </ul>
              </div>
            )
            }
          </div>
        }
      </div>

      <div className="form-group-1modal range-picinmodal-1">
        <label htmlFor="date-range">Date Range *</label>

        <div className="date-picker-outer date-picker-outer-all">
          <div className="custom-picker-icon custom-picker-icon-all">
            <svg
              width="1em"
              height="1em"
              viewBox="0 0 14 14"
              fill="currentColor"
              aria-hidden="true"
              focusable="false"
              class="rs-picker-toggle-caret rs-icon"
              aria-label="calendar"
              data-category="legacy"
            >
              <path d="M1 4v8.5a.5.5 0 00.5.5H8c0-.128.049-.256.146-.354.555-.555.854-1.6.854-3.146a.5.5 0 01.621-.485l.119.03A2.623 2.623 0 0012.999 6.5V4h-12zm3-2h6V0h1v2h1.5A1.5 1.5 0 0114 3.5v3a3.623 3.623 0 01-4.015 3.603c-.064 1.245-.335 2.212-.831 2.898H12.5a.5.5 0 00.5-.5v-2a.5.5 0 011 0v2a1.5 1.5 0 01-1.5 1.5h-11a1.5 1.5 0 01-1.5-1.5v-9a1.5 1.5 0 011.5-1.5H3v-2h1v2zm5 4V5h1v1h2v1h-2v1H9V7H7v2h1v1H7v2H6v-2H4v2H3v-2H1V9h2V7H1V6h2V5h1v1h2V5h1v1h2zM6 9V7H4v2h2z"></path>
            </svg>
          </div>
          <div className="custoom-icon-calender custoom-icon-calender-all">
            <DateRangePicker
              showOneCalendar
              className={errorMessage && errorMessage.field === 'both' ? "errorDate" : ""}
              id="date-range"
              format="dd/MM/yyyy"
              placeholder="Select a date range"
              value={dateRange}
              onChange={handleSelect}
              placement="auto"
              isoWeek={appSettings.weekStartDay === 'Monday'}
              timeZone={global.config.getTimeZone()}
              renderStaticRangeLabel={({ range }) => {
                const startDate = range.startDate.toLocaleDateString("en-US", {
                  day: "numeric",
                  month: "short",
                });
                const endDate = range.endDate.toLocaleDateString("en-US", {
                  day: "numeric",
                  month: "short",
                });

                const label = `${startDate} to ${endDate}`;

                return label.replace("~", "to");
              }}
            />
          </div>
        </div>

      </div>
      <div className="form-group-1modal submittseloutter-1">
        <button className="create-button modalsubmitbtn-1" onClick={clientInvoiceSubmit}>
          Create Invoice
        </button>
      </div>
    </div>
  );
};

export default ClientsInvoicesModal;