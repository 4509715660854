import axios from "axios";
import "./ActivityLog.css";
import moment from "moment";
import * as XLSX from "xlsx";
import { DatePicker } from "rsuite";
import { useQuery } from "react-query";
import subDays from "date-fns/subDays";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { ThreeDots } from "react-loader-spinner";
import { useActivityLog } from "./useActivityLog";
import { useSelector, useDispatch } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import TruncateFieldView from "../helpers/truncateFieldView";
import profile_image from "../../images/user/Default_pfp.png";
import { logout, reset } from "../../features/auth/authSlice";
import UpArrowImage from "../../images/icons/up-down-arrow.svg";
import DownloadImage from "../../images/icons/download-icon.svg";
import DownArrowImage from "../../images/icons/up-down-arrow.svg";
import { setHeaderText } from "../../features/headerText/headerTextSlice";
import { reset as invoiceReset } from "../../features/invoice/invoiceSlice.js";
import { reset as headerReset } from "../../features/headerText/headerTextSlice.js";

const ranges = [
  {
    label: "today",
    value: new Date(), // Use a single date instead of an array
  },
  {
    label: "yesterday",
    value: subDays(new Date(), 1),
  },
];

const ActivityLogs = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });
  const [selectedPage, setSelectedPage] = useState(0);
  const [startDateFilter, setStartDateFilter] = useState(null);
  const [endDateFilter, setEndDateFilter] = useState(null);
  const [showingDropdownOpen, setShowingDropdownOpen] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const showingDropdownRef = useRef(null);
  const { appSettings } = useSelector((state) => state.appSettings);

  let token = null;
  const { user } = useSelector((state) => state.auth);
  if (user) {
    if (user && user.token) {
      token = user.token;
    }
  }
  const { data: activityLog = [], isLoading, isError } = useActivityLog(token);

  const sortTable = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
    // Add your sorting logic here
  };

  // useEffect(() => {
  //   async function fetchActivityLog() {
  //     try {
  //       setIsLoading(true);
  //       const response = await axios.get("/api/activity-log",
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       });
  //       setActivityLog(response.data);
  //       setIsLoading(false);
  //     } catch (error) {
  //       console.log(error);
  //       if (error.response?.data?.status === "deleted") {
  //         localStorage.removeItem("user");
  //         dispatch(logout());
  //         dispatch(reset());
  //         dispatch(invoiceReset());
  //         dispatch(headerReset());
  //         toast.error('This user is deleted!', {
  //           position: "bottom-right",
  //           autoClose: 2000,
  //           hideProgressBar: false,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //           progress: undefined,
  //           theme: "light",
  //         });
  //         navigate("/");
  //         return;
  //       }
  //       alert("Something went wrong");
  //     }
  //   }

  //   fetchActivityLog().then(() => {
  //     //   alert("Data fetched successfully!");
  //   });

  //   dispatch(setHeaderText("Activity Log"));
  // }, []);

  // async function fetchActivityLog() {
  //   try {
  //     const response = await axios.get("/api/activity-log", {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     });
  //     return response.data;
  //   } catch (error) {
  //     throw error;
  //   }
  // }

  useEffect(() => {
    dispatch(setHeaderText("Activity Log"));
  }, []);

  const handleStartDateChange = (date) => {
    if (!endDateFilter || new Date(date) < new Date(endDateFilter)) {
      setStartDateFilter(date);
      setSelectedPage(0);
    } else {
      alert("Start date must be before end date");
    }
  };
  const disableInput = (event) => {
    event.preventDefault();
  };
  const handleEndDateChange = (date) => {
    if (!startDateFilter || new Date(date) > new Date(startDateFilter)) {
      setEndDateFilter(date);
      setSelectedPage(0);
    } else {
      alert("End date must be after start date");
    }
  };

  const filteredData = activityLog.filter((item) => {
    if (
      startDateFilter &&
      new Date(item.created_at) < new Date(startDateFilter)
    ) {
      return false;
    }
    if (endDateFilter && new Date(item.created_at) > new Date(endDateFilter)) {
      return false;
    }
    return true;
  });

  const sortedData = [...filteredData].sort((a, b) => {
    if (sortConfig.key !== "") {
      const aValue = a[sortConfig.key];
      const bValue = b[sortConfig.key];

      if (aValue < bValue) {
        return sortConfig.direction === "asc" ? -1 : 1;
      }
      if (aValue > bValue) {
        return sortConfig.direction === "asc" ? 1 : -1;
      }
    }
    return 0;
  });

  const handleClickOutsideDropdown = (event) => {
    const isClickInsideshowingDropdown = showingDropdownRef.current && showingDropdownRef.current.contains(event.target);
    if (!isClickInsideshowingDropdown) {
      setShowingDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutsideDropdown);
    return () => {
      document.removeEventListener('click', handleClickOutsideDropdown);
    };
  }, []);

  const handlePageChange = (selected) => {
    setSelectedPage(selected.selected);
  };
  const totalResults = sortedData.length;
  const pageCount = Math.ceil(sortedData.length / perPage); // Total number of pages
  const offset = perPage * selectedPage; // Offset of the current page
  const currentPageData = sortedData.slice(offset, offset + perPage);

  const exportToExcel = () => {
    if (filteredData.length === 0) {
      toast.error("No data to export.", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    try {
      const now = new Date();
      const formattedDate =
        now.getFullYear() +
        "-" +
        (now.getMonth() + 1).toString().padStart(2, "0") +
        "-" +
        now.getDate().toString().padStart(2, "0");
      const formattedTime =
        now.getHours().toString().padStart(2, "0") +
        ":" +
        now.getMinutes().toString().padStart(2, "0") +
        ":" +
        now.getSeconds().toString().padStart(2, "0");

      const modifiedLog = filteredData.map((item, index) => ({
        SlNo: index + 1,
        DONE_BY: item.user_id
          ? item.user_id.first_name
            ? `${item.user_id.first_name} ${item.user_id.middle_name ? item.user_id.middle_name : ''} ${item.user_id.last_name ? item.user_id.last_name : ''}`
            : item.user_id.company_first_name
              ? `${item.user_id.company_first_name} ${item.user_id.company_last_name ? item.user_id.company_last_name : ''}`
              : item.user_id.company_name
          : '',
        GROUP: item.action,
        MESSAGE: item.message,
        URL: item.url,
        IP: item.ip,
        CREATED_AT: moment(item.created_at).tz(global.config.getTimeZone()).format("DD/MM/YYYY, hh:mm:ss A"),
      }));

      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(modifiedLog);
      const colWidths = [
        { wpx: 25 },
        { wpx: 100 },
        { wpx: 100 },
        { wpx: 175 },
        { wpx: 225 },
        { wpx: 100 },
        { wpx: 150 },
      ];
      worksheet["!cols"] = colWidths;
      // Add alignment to center align the first row and increase font size
      const firstRow = XLSX.utils.decode_range(worksheet["!ref"]).s.r;
      for (let i = 0; i < colWidths.length; i++) {
        const cell = XLSX.utils.encode_cell({ r: firstRow, c: i });
        const style = worksheet[cell]?.s || {};
        style.alignment = style.alignment || {};
        style.alignment.horizontal = "center !important";
        style.alignment.vertical = "center !important";
        style.font = { size: 14, bold: true };
        worksheet[cell] = { ...worksheet[cell], s: style };
      }
      // Set border for active area and increase font size
      const range = XLSX.utils.decode_range(worksheet["!ref"]);
      for (let row = range.s.r + 1; row <= range.e.r; row++) {
        for (let col = range.s.c; col <= range.e.c; col++) {
          const cell = XLSX.utils.encode_cell({ r: row, c: col });
          const style = worksheet[cell]?.s || {};
          style.font = { size: 12 };
          worksheet[cell] = { ...worksheet[cell], s: style };
        }
      }
      global.config.activityLog(
        window.location.href,
        "Activity Log",
        "Activity Log exported to Excel"
      );
      const fileName = `activity-log_${formattedDate}_${formattedTime}.xlsx`;
      worksheet["!autofilter"] = { ref: range };
      XLSX.utils.book_append_sheet(workbook, worksheet, "Activity Log");
      XLSX.writeFile(workbook, fileName);
    } catch (error) {
      console.log(error);
      global.config.slackMessage(error.toString());
      global.config.activityLog(
        window.location.href,
        "Activity Log",
        "Error occurred while exporting to Excel"
      );
      alert("Error occurred while exporting to Excel.");
    }
  };
  return (
    <div className="activitylog-main-outter">
      <div className="range-activitylog">
        <div className="date-pickers">
          <div className="range-activitylog-startdate">
            <label>Start Date</label>
            <div className="date-picker-outer">
              <div className="custom-picker-icon">
                <svg
                  width="1em"
                  height="1em"
                  viewBox="0 0 14 14"
                  fill="currentColor"
                  aria-hidden="true"
                  focusable="false"
                  class="rs-picker-toggle-caret rs-icon"
                  aria-label="calendar"
                  data-category="legacy"
                >
                  <path d="M1 4v8.5a.5.5 0 00.5.5H8c0-.128.049-.256.146-.354.555-.555.854-1.6.854-3.146a.5.5 0 01.621-.485l.119.03A2.623 2.623 0 0012.999 6.5V4h-12zm3-2h6V0h1v2h1.5A1.5 1.5 0 0114 3.5v3a3.623 3.623 0 01-4.015 3.603c-.064 1.245-.335 2.212-.831 2.898H12.5a.5.5 0 00.5-.5v-2a.5.5 0 011 0v2a1.5 1.5 0 01-1.5 1.5h-11a1.5 1.5 0 01-1.5-1.5v-9a1.5 1.5 0 011.5-1.5H3v-2h1v2zm5 4V5h1v1h2v1h-2v1H9V7H7v2h1v1H7v2H6v-2H4v2H3v-2H1V9h2V7H1V6h2V5h1v1h2V5h1v1h2zM6 9V7H4v2h2z"></path>
                </svg>
              </div>
              <div className="custoom-icon-calender">
                <DatePicker
                  oneTap
                  format="dd/MM/yyyy"
                  ranges={ranges}
                  placeholder="StartDate"
                  value={startDateFilter}
                  onChange={handleStartDateChange}
                  placement="bottomStart"
                  onKeyDown={disableInput}
                  timeZone={global.config.getTimeZone()}
                  isoWeek={appSettings.weekStartDay === 'Monday'}
                />
              </div>
            </div>
          </div>
          <div className="range-activitylog-enddate">
            <label>End Date</label>
            <div className="date-picker-outer">
              <div className="custom-picker-icon">
                <svg
                  width="1em"
                  height="1em"
                  viewBox="0 0 14 14"
                  fill="currentColor"
                  aria-hidden="true"
                  focusable="false"
                  class="rs-picker-toggle-caret rs-icon"
                  aria-label="calendar"
                  data-category="legacy"
                >
                  <path d="M1 4v8.5a.5.5 0 00.5.5H8c0-.128.049-.256.146-.354.555-.555.854-1.6.854-3.146a.5.5 0 01.621-.485l.119.03A2.623 2.623 0 0012.999 6.5V4h-12zm3-2h6V0h1v2h1.5A1.5 1.5 0 0114 3.5v3a3.623 3.623 0 01-4.015 3.603c-.064 1.245-.335 2.212-.831 2.898H12.5a.5.5 0 00.5-.5v-2a.5.5 0 011 0v2a1.5 1.5 0 01-1.5 1.5h-11a1.5 1.5 0 01-1.5-1.5v-9a1.5 1.5 0 011.5-1.5H3v-2h1v2zm5 4V5h1v1h2v1h-2v1H9V7H7v2h1v1H7v2H6v-2H4v2H3v-2H1V9h2V7H1V6h2V5h1v1h2V5h1v1h2zM6 9V7H4v2h2z"></path>
                </svg>
              </div>
              <div className="custoom-icon-calender">
                <DatePicker
                  oneTap
                  format="dd/MM/yyyy"
                  ranges={ranges}
                  placeholder="EndDate"
                  value={endDateFilter}
                  onChange={handleEndDateChange}
                  placement="bottomStart"
                  className="custom-date-picker"
                  onKeyDown={disableInput}
                  timeZone={global.config.getTimeZone()}
                  isoWeek={appSettings.weekStartDay === 'Monday'}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="search-userandmail">
          <a onClick={exportToExcel}>
            <span className="icon-invoice">
              <img src={DownloadImage} alt="Export" />
            </span>
            Export Data
          </a>
        </div>
      </div>
      <div className="overview-top-right">
        <div className="showing-count">
          <div className="filter-activitylog-inner showing-result show-result-activitylog-fill">
            <label>Showing</label>
            <div ref={showingDropdownRef} className="select-modal-mainM custom-dropdown-user-new-outer">
              <div className="selected-option custom-dropdown-user-new" onClick={() => setShowingDropdownOpen(!showingDropdownOpen)}>
                {totalResults < 10 ? totalResults.toString() : perPage.toString()}
              </div>
              {showingDropdownOpen && (
                <div className="custom-dropdown-new">
                  <ul className="showing-options-list">
                    {totalResults >= 10 && <li className={perPage === 10 ? 'selected-item' : ''} onClick={() => { setPerPage(10); setSelectedPage(0); setShowingDropdownOpen(false); }}>10</li>}
                    {totalResults >= 20 && <li className={perPage === 20 ? 'selected-item' : ''} onClick={() => { setPerPage(20); setSelectedPage(0); setShowingDropdownOpen(false); }}>20</li>}
                    {totalResults >= 30 && <li className={perPage === 30 ? 'selected-item' : ''} onClick={() => { setPerPage(30); setSelectedPage(0); setShowingDropdownOpen(false); }}>30</li>}
                    {totalResults >= 40 && <li className={perPage === 40 ? 'selected-item' : ''} onClick={() => { setPerPage(40); setSelectedPage(0); setShowingDropdownOpen(false); }}>40</li>}
                    {totalResults >= 10 && <li className={totalResults >= 10 && perPage === totalResults ? 'selected-item' : ''} onClick={() => { setPerPage(totalResults); setSelectedPage(0); setShowingDropdownOpen(false); }}>ALL</li>}
                    {/* Add more conditions as needed */}
                    {totalResults < 10 ? (
                      <li className={totalResults < 10 ? 'selected-item' : ''} onClick={() => { setPerPage(totalResults); setSelectedPage(0); setShowingDropdownOpen(false); }}>{totalResults}</li>
                    ) : null}
                  </ul>
                </div>
              )}
            </div>
            <label>of {totalResults} Results</label>
          </div>
        </div>
      </div>
      <div className="datatable-team-maintb">
        <table>
          <thead className="head-tb-of-team-member">
            <tr>
              <th className="sortable-header" onClick={() => sortTable("user")}>
                Done By{" "}
                {sortConfig.key === "user" && sortConfig.direction === "asc" ? (
                  <img src={UpArrowImage} alt="Up Arrow" />
                ) : (
                  <img src={DownArrowImage} alt="Down Arrow" />
                )}
              </th>
              <th
                className="sortable-header"
                onClick={() => sortTable("email")}
              >
                Group{" "}
                {sortConfig.key === "email" &&
                  sortConfig.direction === "asc" ? (
                  <img src={UpArrowImage} alt="Up Arrow" />
                ) : (
                  <img src={DownArrowImage} alt="Down Arrow" />
                )}
              </th>
              <th
                className="sortable-header"
                style={{ minWidth: "150px", paddingRight: "30px" }}
                onClick={() => sortTable("billableRate")}
              >
                Message{" "}
                {sortConfig.key === "billableRate" &&
                  sortConfig.direction === "asc" ? (
                  <img src={UpArrowImage} alt="Up Arrow" />
                ) : (
                  <img src={DownArrowImage} alt="Down Arrow" />
                )}
              </th>
              <th
                className="sortable-header"
                onClick={() => sortTable("cost")}
                style={{ minWidth: "150px", paddingRight: "30px" }}
              >
                URL{" "}
                {sortConfig.key === "cost" && sortConfig.direction === "asc" ? (
                  <img src={UpArrowImage} alt="Up Arrow" />
                ) : (
                  <img src={DownArrowImage} alt="Down Arrow" />
                )}
              </th>
              <th
                className="sortable-header"
                onClick={() => sortTable("job_title")}
              >
                IP{" "}
                {sortConfig.key === "job_title" &&
                  sortConfig.direction === "asc" ? (
                  <img src={UpArrowImage} alt="Up Arrow" />
                ) : (
                  <img src={DownArrowImage} alt="Down Arrow" />
                )}
              </th>
              <th
                className="sortable-header"
                onClick={() => sortTable("department")}
              >
                Created at{" "}
                {sortConfig.key === "department" &&
                  sortConfig.direction === "asc" ? (
                  <img src={UpArrowImage} alt="Up Arrow" />
                ) : (
                  <img src={DownArrowImage} alt="Down Arrow" />
                )}
              </th>
            </tr>
          </thead>
          <tbody className="body-tb-of-team-member">
            {isLoading ? (
              <tr>
                <td colSpan="6">
                  <div className="outter-load-table">
                    <ThreeDots
                      height="80"
                      width="80"
                      radius="9"
                      color="#6479f9"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  </div>
                </td>
              </tr>
            ) : (
              <>
                {currentPageData.length > 0 ? (
                  <>
                    {currentPageData.map((item, index) => (
                      <tr key={index}>
                        <td>
                          {item.user_id && (
                            <>
                              <span className="user-image-listingteam">
                                {item.user_id.profile_image !== null &&
                                  item.user_id.profile_image !== "null" ? (
                                  <img
                                    src={`/uploads/lowProfileImage/${item.user_id.profile_image}`}
                                    alt={item.user_id.first_name}
                                    className="member-image"
                                    onError={(e) => {
                                      e.target.onerror = null;
                                      e.target.src =
                                        "/uploads/lowProfileImage/user_default.png";
                                    }}
                                  />
                                ) : (
                                  <img
                                    src={profile_image}
                                    alt={item.user_id.first_name}
                                    className="member-image"
                                  />
                                )}
                              </span>
                              {item.user_id.first_name ? (
                                <>
                                  {item.user_id.first_name}{" "}
                                  {item.user_id.middle_name &&
                                    item.user_id.middle_name !== "null"
                                    ? " " + item.user_id.middle_name
                                    : ""}
                                  {item.user_id.last_name}{" "}
                                </>
                              ) : (
                                <>
                                  {item.user_id.company_first_name
                                    ? `${item.user_id.company_first_name} ${item.user_id.company_last_name ? item.user_id.company_last_name : ''}`
                                    : `${item.user_id.company_name || ''}(Company)`
                                  }
                                </>
                              )}
                            </>
                          )}
                        </td>
                        <td>{item.action}</td>
                        <td>
                          {item.message && (
                            <TruncateFieldView textContent={item.message} />
                          )}
                        </td>
                        <td>
                          {item.url && (
                            <TruncateFieldView textContent={item.url} />
                          )}
                        </td>
                        <td>{item.ip}</td>
                        <td>
                          {moment(item.created_at).tz(global.config.getTimeZone()).format(
                            "DD/MM/YYYY , hh:mm:ss A"
                          )}
                        </td>
                      </tr>
                    ))}
                  </>
                ) : (
                  <tr>
                    <td colSpan="6">
                      {!isLoading && (
                        <div className="outter-load-table">
                          No results found
                        </div>
                      )}
                    </td>
                  </tr>
                )}
              </>
            )}
          </tbody>
        </table>
      </div>
      <div className="pagination-container">
        {isLoading ? (
          <></>
        ) : (
          <ReactPaginate
            previousLabel={selectedPage > 0 ? "< Back" : null}
            nextLabel={selectedPage < pageCount - 1 ? "Next >" : null}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={pageCount}
            marginPagesDisplayed={1}
            pageRangeDisplayed={1}
            onPageChange={handlePageChange}
            containerClassName={"pagination maininteampag"}
            activeClassName={"active"}
            forcePage={selectedPage}
          />
        )}
      </div>
    </div>
  );
};

export default ActivityLogs;