
import { reportingFilterComparisonConst } from '../../../../../constants/reportingFilterComparisonConst';
import { reportingFilterDateRangesConst } from '../../../../../constants/reportingFilterDateRangesConst';
import { convertCurrencyHelper } from '../../../../../helpers/forexConvertor';

export function calculateTotalExpense(expenseData, reportingFinancialExpense, preferredCurrency, forex) {
    const year = reportingFinancialExpense.expYear || new Date().getFullYear();
    const filteredExpenses = expenseData.filter(expense =>
        expense.is_delete === false &&
        new Date(expense.exp_date).getFullYear() === year &&
        expense.exp_category && reportingFinancialExpense.expCategory && reportingFinancialExpense.expCategory.includes(expense.exp_category._id) &&
        reportingFinancialExpense.expCurrency && reportingFinancialExpense.expCurrency.includes(expense.exp_currency)
    );

    const totalExpense = filteredExpenses.reduce((total, expense) => {
        const convertedAmount = convertCurrencyHelper(expense.exp_currency, preferredCurrency, expense.exp_amount, forex);
        return total + convertedAmount;
    }, 0);

    return totalExpense.toFixed(2);
};

export function calculateHighestExpense(expenseData, reportingFinancialExpense, preferredCurrency, forex) {
    const year = reportingFinancialExpense.expYear || new Date().getFullYear();
    const filteredExpenses = expenseData.filter(expense =>
        !expense.is_delete &&
        reportingFinancialExpense.expCategory && reportingFinancialExpense.expCategory.includes(expense.exp_category._id) &&
        new Date(expense.exp_date).getFullYear() === year &&
        reportingFinancialExpense.expCurrency && reportingFinancialExpense.expCurrency.includes(expense.exp_currency)
    );

    let highestExpense = 0;
    let highestExpenseCategoryName = '';

    filteredExpenses.forEach(expense => {
        const convertedAmount = convertCurrencyHelper(expense.exp_currency, preferredCurrency, expense.exp_amount, forex);
        if (convertedAmount > highestExpense) {
            highestExpense = convertedAmount;
            highestExpenseCategoryName = expense.exp_category.exp_category_name;
        }
    });

    return { highestExpense: highestExpense.toFixed(2), highestExpenseCategoryName };
};

export function calculateLowestExpense(expenseData, reportingFinancialExpense, preferredCurrency, forex) {
    const year = reportingFinancialExpense.expYear || new Date().getFullYear();
    const filteredExpenses = expenseData.filter(expense =>
        !expense.is_delete &&
        reportingFinancialExpense.expCategory && reportingFinancialExpense.expCategory.includes(expense.exp_category._id) &&
        new Date(expense.exp_date).getFullYear() === year &&
        reportingFinancialExpense.expCurrency && reportingFinancialExpense.expCurrency.includes(expense.exp_currency)
    );

    let lowestExpense = Infinity;
    let lowestExpenseCategoryName = '';

    filteredExpenses.forEach(expense => {
        const convertedAmount = convertCurrencyHelper(expense.exp_currency, preferredCurrency, expense.exp_amount, forex);
        if (convertedAmount < lowestExpense) {
            lowestExpense = convertedAmount;
            lowestExpenseCategoryName = expense.exp_category.exp_category_name;
        }
    });

    return { lowestExpense: lowestExpense === Infinity ? 0 : lowestExpense.toFixed(2), lowestExpenseCategoryName };
};

export function calculateOutstandingBalance(expenseData, expenseCategory, reportingFinancialExpense, preferredCurrency, forex) {
    const year = reportingFinancialExpense.expYear || new Date().getFullYear();

    // Calculate total expense
    const filteredExpenses = expenseData.filter(expense => {
        const expenseYear = new Date(expense.exp_date).getFullYear();
        const isCategoryIncluded = expense.exp_category && reportingFinancialExpense.expCategory && reportingFinancialExpense.expCategory.includes(expense.exp_category._id);
        const isCurrencyIncluded = reportingFinancialExpense.expCurrency && reportingFinancialExpense.expCurrency.includes(expense.exp_currency);

        return !expense.is_delete && expenseYear === year && isCategoryIncluded && isCurrencyIncluded;
    });

    const totalExpense = filteredExpenses.reduce((total, expense) => {
        const convertedAmount = convertCurrencyHelper(expense.exp_currency, preferredCurrency, expense.exp_amount, forex);
        return total + convertedAmount;
    }, 0);

    // Calculate total budget
    const filteredCategories = expenseCategory.filter(category => {
        const categoryYear = new Date(category.created_at).getFullYear();
        const isCurrencyIncluded = reportingFinancialExpense.expCurrency && reportingFinancialExpense.expCurrency.includes(category.exp_cat_currency);

        // return !category.is_delete && (reportingFinancialExpense.expCategory && reportingFinancialExpense.expCategory.includes(category._id)) && categoryYear === year && isCurrencyIncluded;
        return !category.is_delete && (reportingFinancialExpense.expCategory && reportingFinancialExpense.expCategory.includes(category._id));
    });

    const totalBudget = filteredCategories.reduce((acc, category) => {
        const convertedBudget = convertCurrencyHelper(category.exp_cat_currency, preferredCurrency, category.exp_cat_budget, forex);
        return acc + convertedBudget;
    }, 0);

    // console.log("totalBudget", totalBudget);
    // console.log("totalExpense", totalExpense);

    // Calculate outstanding balance
    const outstandingBalance = totalBudget - totalExpense;

    return outstandingBalance.toFixed(2);
};

// export function calculateOutstandingBalance(expenseData, expenseCategory, reportingFinancialExpense, preferredCurrency, forex) {
//     const year = reportingFinancialExpense.expYear || new Date().getFullYear();

//     // Filter and process expenses
//     const filteredExpenses = expenseData.filter(expense =>
//         !expense.is_delete &&
//         new Date(expense.exp_date).getFullYear() === year &&
//         reportingFinancialExpense.expCategory.includes(expense.exp_category?._id) &&
//         reportingFinancialExpense.expCurrency.includes(expense.exp_currency)
//     );

//     const expensesByCategory = {};

//     filteredExpenses.forEach(expense => {
//         const category = expense.exp_category?._id || 'Unknown';

//         if (!expensesByCategory[category]) {
//             expensesByCategory[category] = { budgeted: 0, actual: 0 };
//         }

//         const convertedAmount = convertCurrencyHelper(expense.exp_currency, preferredCurrency, expense.exp_amount, forex);
//         expensesByCategory[category].actual += convertedAmount;
//     });

//     // Process and calculate total budget
//     expenseCategory.forEach(category => {
//         if (
//             !category.is_delete &&
//             reportingFinancialExpense.expCategory.includes(category._id) &&
//             // new Date(category.created_at).getFullYear() === year &&
//             reportingFinancialExpense.expCurrency.includes(category.exp_cat_currency)
//         ) {
//             const convertedBudget = convertCurrencyHelper(category.exp_cat_currency, preferredCurrency, category.exp_cat_budget, forex);

//             if (!expensesByCategory[category._id]) {
//                 expensesByCategory[category._id] = { budgeted: 0, actual: 0 };
//             }

//             expensesByCategory[category._id].budgeted = convertedBudget;
//         }
//     });

//     // Calculate total budget and total expenses
//     let totalBudget = 0;
//     let totalExpense = 0;

//     Object.values(expensesByCategory).forEach(category => {
//         totalBudget += category.budgeted;
//         totalExpense += category.actual;
//         console.log("category.budgeted", category.budgeted);
//     });
//     console.log("totalBudget", totalBudget);
//     console.log("totalExpense", totalExpense);
//     // Calculate outstanding balance
//     const outstandingBalance = totalBudget - totalExpense;

//     return outstandingBalance.toFixed(2);
// }

export function calculateCategoryExpenses(expenseData, reportingFinancialExpense, preferredCurrency, forex, year) {
    if (!expenseData || !reportingFinancialExpense || !reportingFinancialExpense.expCategory) return {};

    const filteredExpenses = expenseData.filter(expense =>
        !expense.is_delete &&
        new Date(expense.exp_date).getFullYear() === year &&
        expense.exp_category && reportingFinancialExpense.expCategory.includes(expense.exp_category._id) &&
        reportingFinancialExpense.expCurrency.includes(expense.exp_currency)
    );

    const categoryExpenses = {};

    // Initialize all categories with 0
    reportingFinancialExpense.expCategory.forEach(categoryId => {
        const category = expenseData.find(expense => expense.exp_category._id === categoryId);
        if (category) {
            categoryExpenses[category.exp_category.exp_category_name] = 0;
        }
    });

    filteredExpenses.forEach(expense => {
        const categoryName = expense.exp_category.exp_category_name;
        const convertedAmount = convertCurrencyHelper(expense.exp_currency, preferredCurrency, expense.exp_amount, forex);
        const roundedAmount = Math.round(convertedAmount * 100) / 100; // Round to 2 decimal places
        // const roundedAmount = parseFloat(convertedAmount.toFixed(2));

        if (categoryExpenses[categoryName] !== undefined) {
            categoryExpenses[categoryName] += roundedAmount;
        } else {
            categoryExpenses[categoryName] = roundedAmount;
        }
    });

    return categoryExpenses;
};

export function calculateCategoryExpensesHistoryFilter(expenseData, reportingFinancialExpense, preferredCurrency, forex, startDate, endDate) {
    if (!expenseData || !reportingFinancialExpense || !reportingFinancialExpense.expCategory) return {};

    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 999);
    const filteredExpenses = expenseData.filter(expense =>
        !expense.is_delete &&
        new Date(expense.exp_date) >= startDate &&
        new Date(expense.exp_date) <= endDate &&
        expense.exp_category && reportingFinancialExpense.expCategory.includes(expense.exp_category._id) &&
        reportingFinancialExpense.expCurrency.includes(expense.exp_currency)
    );

    const categoryExpenses = {};

    // Initialize all categories with 0
    reportingFinancialExpense.expCategory.forEach(categoryId => {
        const category = expenseData.find(expense => expense.exp_category._id === categoryId);
        if (category) {
            categoryExpenses[category.exp_category.exp_category_name] = 0;
        }
    });

    filteredExpenses.forEach(expense => {
        const categoryName = expense.exp_category.exp_category_name;
        const convertedAmount = convertCurrencyHelper(expense.exp_currency, preferredCurrency, expense.exp_amount, forex);
        const roundedAmount = Math.round(convertedAmount * 100) / 100; // Round to 2 decimal places
        // const roundedAmount = parseFloat(convertedAmount.toFixed(2));

        if (categoryExpenses[categoryName] !== undefined) {
            categoryExpenses[categoryName] += roundedAmount;
        } else {
            categoryExpenses[categoryName] = roundedAmount;
        }
    });

    return categoryExpenses;
};

export function processedCategoryData(expenseData, reportingFinancialExpense, settings, currencies, forex) {
    const calculateCategoryExpenses = () => {
        const categoryExpenses = {};

        expenseData.forEach(expense => {
            const expenseYear = new Date(expense.exp_date).getFullYear();
            const expenseCategory = expense.exp_category;

            if (
                !expense.is_delete &&
                reportingFinancialExpense.expCategory.includes(expenseCategory._id) &&
                expenseYear === reportingFinancialExpense.expYear &&
                reportingFinancialExpense.expCurrency.includes(expense.exp_currency)
            ) {
                const categoryId = expenseCategory._id;
                const convertedAmount = convertCurrencyHelper(
                    expense?.exp_currency,
                    currencies,
                    expense.exp_amount,
                    forex
                );

                if (!categoryExpenses[categoryId]) {
                    categoryExpenses[categoryId] = {
                        name: expenseCategory.exp_category_name,
                        amount: 0
                    };
                }

                categoryExpenses[categoryId].amount += convertedAmount;
            }
        });

        return Object.values(categoryExpenses).map(category => ({
            name: category.name,
            amount: parseFloat(category.amount.toFixed(2))
        }));
    };

    return calculateCategoryExpenses();
};

export function processedMonthlyExpenses(expenseData, reportingFinancialExpense, settings, currencies, forex) {
    if (!expenseData) return [];

    const currentYear = reportingFinancialExpense?.expYear || new Date().getFullYear();
    const previousYear = currentYear - 1;
    const monthlyExpenses = Array(12).fill(0).map((_, index) => ({
        name: new Date(0, index + 1, 0).toLocaleString('en-US', { month: 'long' }),
        currentYear: 0,
        lastYear: 0,
    }));

    expenseData.forEach(expense => {
        if (!expense.is_delete &&
            reportingFinancialExpense.expCategory.includes(expense.exp_category._id) &&
            reportingFinancialExpense.expCurrency.includes(expense.exp_currency)) {
            const expenseDate = new Date(expense.exp_date);
            const expenseYear = expenseDate.getFullYear();
            const expenseMonth = expenseDate.getMonth();
            const convertedAmount = convertCurrencyHelper(
                expense.exp_currency,
                currencies,
                expense.exp_amount,
                forex
            );

            if (expenseYear === currentYear) {
                monthlyExpenses[expenseMonth].currentYear += convertedAmount;
            } else if (expenseYear === previousYear) {
                monthlyExpenses[expenseMonth].lastYear += convertedAmount;
            }
        }
    });

    return monthlyExpenses.map(expense => ({
        ...expense,
        currentYear: parseFloat(expense.currentYear.toFixed(2)),
        lastYear: parseFloat(expense.lastYear.toFixed(2)),
    }));
};

// export function processedMonthlyExpensesFilter(
//     expenseData,
//     reportingFinancialExpense,
//     settings,
//     forex,
//     selectedDateRange,
//     selectedComparison,
//     selectedFrequency,
//     selectedYearRange,
//     selectedMonthRange,
//     selectedComparisonYear,
//     selectedComparisonMonth,
// ) {
//     if (!expenseData) return [];

//     const getLastDayOfMonth = (year, month) => {
//         return new Date(year, month, 0).getDate();
//     };

//     const getStartAndEndDates = (rangeId, year = new Date().getFullYear()) => {
//         switch (rangeId) {
//             case reportingFilterDateRangesConst.This_Year:
//                 return [new Date(`${year}-01-01`), new Date(`${year}-12-31`)];

//             case reportingFilterDateRangesConst.This_Quarter: {
//                 const quarter = Math.floor((new Date().getMonth() + 3) / 3);
//                 const startMonth = (quarter - 1) * 3 + 1;
//                 const endMonth = startMonth + 2;
//                 const endDay = getLastDayOfMonth(year, endMonth);
//                 return [new Date(`${year}-${startMonth}-01`), new Date(`${year}-${endMonth}-${endDay}`)];
//             }

//             case reportingFilterDateRangesConst.First_Half:
//                 return [new Date(`${year}-01-01`), new Date(`${year}-06-30`)];

//             case reportingFilterDateRangesConst.This_Month: {
//                 const month = new Date().getMonth() + 1;
//                 const endDay = getLastDayOfMonth(year, month);
//                 return [new Date(`${year}-${month}-01`), new Date(`${year}-${month}-${endDay}`)];
//             }

//             case reportingFilterDateRangesConst.Year_Selected:
//                 if (selectedYearRange) {
//                     const selectedYear = selectedYearRange.getFullYear();
//                     return [new Date(`${selectedYear}-01-01`), new Date(`${selectedYear}-12-31`)];
//                 } else {
//                     return [new Date(`${year}-01-01`), new Date(`${year}-12-31`)];
//                 }
//             case reportingFilterDateRangesConst.Month_Selected:
//                 if (selectedMonthRange) {
//                     const selectedMonth = selectedMonthRange.getMonth() + 1;
//                     const selectedYearForMonth = selectedMonthRange.getFullYear();
//                     const endDay = getLastDayOfMonth(selectedYearForMonth, selectedMonth);
//                     return [new Date(`${selectedYearForMonth}-${selectedMonth}-01`), new Date(`${selectedYearForMonth}-${selectedMonth}-${endDay}`)];
//                 } else {
//                     const currentMonth = new Date().getMonth() + 1;
//                     const endDay = getLastDayOfMonth(year, currentMonth);
//                     return [new Date(`${year}-${currentMonth}-01`), new Date(`${year}-${currentMonth}-${endDay}`)];
//                 }
//             default:
//                 return [new Date(`${year}-01-01`), new Date(`${year}-12-31`)];
//         }
//     };

//     const getComparisonStartAndEndDates = (comparisonId, year = new Date().getFullYear()) => {
//         switch (comparisonId) {
//             case reportingFilterComparisonConst.Last_Year:
//                 return [new Date(`${year - 1}-01-01`), new Date(`${year - 1}-12-31`)];

//             case reportingFilterComparisonConst.Last_Quarter: {
//                 const quarter = Math.floor((new Date().getMonth() + 3) / 3);
//                 const lastQuarter = quarter === 1 ? 4 : quarter - 1;
//                 const startMonth = (lastQuarter - 1) * 3 + 1;
//                 const endMonth = startMonth + 2;
//                 const lastYear = quarter === 1 ? year - 1 : year;
//                 const endDay = getLastDayOfMonth(lastYear, endMonth);
//                 return [new Date(`${lastYear}-${startMonth}-01`), new Date(`${lastYear}-${endMonth}-${endDay}`)];
//             }

//             case reportingFilterComparisonConst.Second_Half:
//                 return [new Date(`${year}-07-01`), new Date(`${year}-12-31`)];

//             case reportingFilterComparisonConst.Last_Month: {
//                 const lastMonth = new Date().getMonth();
//                 const lastYear = lastMonth === 0 ? year - 1 : year;
//                 const lastMonthFormatted = lastMonth === 0 ? 12 : lastMonth;
//                 const endDay = getLastDayOfMonth(lastYear, lastMonthFormatted);
//                 return [new Date(`${lastYear}-${lastMonthFormatted}-01`), new Date(`${lastYear}-${lastMonthFormatted}-${endDay}`)];
//             }
//             case reportingFilterComparisonConst.Year_Select:
//                 if (selectedComparisonYear) {
//                     const comparisonYear = selectedComparisonYear.getFullYear();
//                     return [new Date(`${comparisonYear}-01-01`), new Date(`${comparisonYear}-12-31`)];
//                 } else {
//                     return [new Date(`${year - 1}-01-01`), new Date(`${year - 1}-12-31`)];
//                 }

//             case reportingFilterComparisonConst.Month_Select:
//                 if (selectedComparisonMonth) {
//                     const comparisonMonth = selectedComparisonMonth.getMonth() + 1;
//                     const comparisonYearForMonth = selectedComparisonMonth.getFullYear();
//                     const endDay = getLastDayOfMonth(comparisonYearForMonth, comparisonMonth);
//                     return [new Date(`${comparisonYearForMonth}-${comparisonMonth}-01`), new Date(`${comparisonYearForMonth}-${comparisonMonth}-${endDay}`)];
//                 } else {
//                     const lastMonth = new Date().getMonth() || 12;
//                     const fallbackYear = lastMonth === 12 ? year - 1 : year;
//                     const endDay = getLastDayOfMonth(fallbackYear, lastMonth);
//                     return [new Date(`${fallbackYear}-${lastMonth}-01`), new Date(`${fallbackYear}-${lastMonth}-${endDay}`)];
//                 }
//             default:
//                 return [new Date(`${year - 1}-01-01`), new Date(`${year - 1}-12-31`)];
//         }
//     };

//     const [startCurrentRange, endCurrentRange] = getStartAndEndDates(selectedDateRange);
//     const [startComparisonRange, endComparisonRange] = getComparisonStartAndEndDates(selectedComparison);

//     const calculateExpenses = (expenses, startDate, endDate, frequency) => {
//         const data = expenses.reduce((acc, expense) => {
//             const expenseDate = new Date(expense.exp_date);
//             startDate.setHours(0, 0, 0, 0);
//             endDate.setHours(23, 59, 59, 999);

//             if (expenseDate >= startDate && expenseDate <= endDate && !expense.is_delete) {
//                 const expenseCurrency = expense.exp_currency || 'USD';

//                 if (
//                     reportingFinancialExpense.expCategory ? reportingFinancialExpense.expCategory.includes(expense.exp_category._id) : true &&
//                         reportingFinancialExpense.expCurrency ? reportingFinancialExpense.expCurrency.includes(expense.exp_currency) : true
//                 ) {
//                     let key;
//                     if (frequency === 'Monthly') {
//                         key = expenseDate.toLocaleString('default', { month: 'long' });
//                     } else if (frequency === 'Daily') {
//                         key = expenseDate.toLocaleDateString();
//                     }

//                     if (!acc[key]) {
//                         acc[key] = { name: key, cost: 0 };
//                     }
//                     acc[key].cost += convertCurrencyHelper(expenseCurrency, settings.currency, expense.exp_amount, forex);
//                 }
//             }
//             return acc;
//         }, {});

//         return data;
//     };

//     const currentData = calculateExpenses(expenseData, startCurrentRange, endCurrentRange, selectedFrequency === '1' ? 'Monthly' : 'Daily');
//     const comparisonData = calculateExpenses(expenseData, startComparisonRange, endComparisonRange, selectedFrequency === '1' ? 'Monthly' : 'Daily');
//     // console.log("currentData", currentData);
//     const allKeys = new Set([...Object.keys(currentData), ...Object.keys(comparisonData)]);

//     let mergedData = Array.from(allKeys).map(key => ({
//         name: key,
//         currentYear: parseFloat(currentData[key]?.cost?.toFixed(2) || 0),
//         lastYear: parseFloat(comparisonData[key]?.cost?.toFixed(2) || 0),
//     }));

//     if (selectedFrequency === '1') {
//         const monthOrder = [
//             'January', 'February', 'March', 'April', 'May', 'June',
//             'July', 'August', 'September', 'October', 'November', 'December'
//         ];
//         mergedData = mergedData.sort((a, b) => {
//             return monthOrder.indexOf(a.name) - monthOrder.indexOf(b.name);
//         });
//     } else {
//         const parseDate = (dateString) => {
//             const [day, month, year] = dateString.split('/').map(Number);
//             return new Date(year, month - 1, day); // JavaScript months are 0-based
//         };
//         mergedData = mergedData.sort((a, b) => {
//             const dateA = parseDate(a.name);
//             const dateB = parseDate(b.name);
//             return dateA - dateB;
//         });
//     }

//     return mergedData;
// };

export function processedMonthlyExpensesFilter(
    expenseData,
    reportingFinancialExpense,
    settings,
    forex,
    selectedDateRange,
    selectedComparison,
    selectedFrequency,
    selectedYearRange,
    selectedMonthRange,
    selectedComparisonYear,
    selectedComparisonMonth,
) {
    if (!expenseData) return [];

    const getLastDayOfMonth = (year, month) => {
        return new Date(year, month, 0).getDate();
    };

    const getStartAndEndDates = (rangeId, year = new Date().getFullYear()) => {
        switch (rangeId) {
            case reportingFilterDateRangesConst.This_Year:
                return [new Date(`${year}-01-01`), new Date(`${year}-12-31`)];

            case reportingFilterDateRangesConst.This_Quarter: {
                const quarter = Math.floor((new Date().getMonth() + 3) / 3);
                const startMonth = (quarter - 1) * 3 + 1;
                const endMonth = startMonth + 2;
                const endDay = getLastDayOfMonth(year, endMonth);
                return [new Date(`${year}-${startMonth}-01`), new Date(`${year}-${endMonth}-${endDay}`)];
            }

            case reportingFilterDateRangesConst.First_Half:
                return [new Date(`${year}-01-01`), new Date(`${year}-06-30`)];

            case reportingFilterDateRangesConst.This_Month: {
                const month = new Date().getMonth() + 1;
                const endDay = getLastDayOfMonth(year, month);
                return [new Date(`${year}-${month}-01`), new Date(`${year}-${month}-${endDay}`)];
            }

            case reportingFilterDateRangesConst.Year_Selected:
                if (selectedYearRange) {
                    const selectedYear = selectedYearRange.getFullYear();
                    return [new Date(`${selectedYear}-01-01`), new Date(`${selectedYear}-12-31`)];
                } else {
                    return [new Date(`${year}-01-01`), new Date(`${year}-12-31`)];
                }
            case reportingFilterDateRangesConst.Month_Selected:
                if (selectedMonthRange) {
                    const selectedMonth = selectedMonthRange.getMonth() + 1;
                    const selectedYearForMonth = selectedMonthRange.getFullYear();
                    const endDay = getLastDayOfMonth(selectedYearForMonth, selectedMonth);
                    return [new Date(`${selectedYearForMonth}-${selectedMonth}-01`), new Date(`${selectedYearForMonth}-${selectedMonth}-${endDay}`)];
                } else {
                    const currentMonth = new Date().getMonth() + 1;
                    const endDay = getLastDayOfMonth(year, currentMonth);
                    return [new Date(`${year}-${currentMonth}-01`), new Date(`${year}-${currentMonth}-${endDay}`)];
                }
            default:
                return [new Date(`${year}-01-01`), new Date(`${year}-12-31`)];
        }
    };

    const getComparisonStartAndEndDates = (comparisonId, year = new Date().getFullYear()) => {
        switch (comparisonId) {
            case reportingFilterComparisonConst.Last_Year:
                return [new Date(`${year - 1}-01-01`), new Date(`${year - 1}-12-31`)];

            case reportingFilterComparisonConst.Last_Quarter: {
                const quarter = Math.floor((new Date().getMonth() + 3) / 3);
                const lastQuarter = quarter === 1 ? 4 : quarter - 1;
                const startMonth = (lastQuarter - 1) * 3 + 1;
                const endMonth = startMonth + 2;
                const lastYear = quarter === 1 ? year - 1 : year;
                const endDay = getLastDayOfMonth(lastYear, endMonth);
                return [new Date(`${lastYear}-${startMonth}-01`), new Date(`${lastYear}-${endMonth}-${endDay}`)];
            }

            case reportingFilterComparisonConst.Second_Half:
                return [new Date(`${year}-07-01`), new Date(`${year}-12-31`)];

            case reportingFilterComparisonConst.Last_Month: {
                const lastMonth = new Date().getMonth();
                const lastYear = lastMonth === 0 ? year - 1 : year;
                const lastMonthFormatted = lastMonth === 0 ? 12 : lastMonth;
                const endDay = getLastDayOfMonth(lastYear, lastMonthFormatted);
                return [new Date(`${lastYear}-${lastMonthFormatted}-01`), new Date(`${lastYear}-${lastMonthFormatted}-${endDay}`)];
            }
            case reportingFilterComparisonConst.Year_Select:
                if (selectedComparisonYear) {
                    const comparisonYear = selectedComparisonYear.getFullYear();
                    return [new Date(`${comparisonYear}-01-01`), new Date(`${comparisonYear}-12-31`)];
                } else {
                    return [new Date(`${year - 1}-01-01`), new Date(`${year - 1}-12-31`)];
                }

            case reportingFilterComparisonConst.Month_Select:
                if (selectedComparisonMonth) {
                    const comparisonMonth = selectedComparisonMonth.getMonth() + 1;
                    const comparisonYearForMonth = selectedComparisonMonth.getFullYear();
                    const endDay = getLastDayOfMonth(comparisonYearForMonth, comparisonMonth);
                    return [new Date(`${comparisonYearForMonth}-${comparisonMonth}-01`), new Date(`${comparisonYearForMonth}-${comparisonMonth}-${endDay}`)];
                } else {
                    const lastMonth = new Date().getMonth() || 12;
                    const fallbackYear = lastMonth === 12 ? year - 1 : year;
                    const endDay = getLastDayOfMonth(fallbackYear, lastMonth);
                    return [new Date(`${fallbackYear}-${lastMonth}-01`), new Date(`${fallbackYear}-${lastMonth}-${endDay}`)];
                }
            default:
                return [new Date(`${year - 1}-01-01`), new Date(`${year - 1}-12-31`)];
        }
    };

    const [startCurrentRange, endCurrentRange] = getStartAndEndDates(selectedDateRange);
    const [startComparisonRange, endComparisonRange] = getComparisonStartAndEndDates(selectedComparison);

    const calculateTotalExpenses = (expenses, startDate, endDate) => {
        return expenses.reduce((total, expense) => {
            const expenseDate = new Date(expense.exp_date);
            startDate.setHours(0, 0, 0, 0);
            endDate.setHours(23, 59, 59, 999);

            if (expenseDate >= startDate && expenseDate <= endDate && !expense.is_delete) {
                const expenseCurrency = expense.exp_currency || 'USD';

                if (
                    reportingFinancialExpense.expCategory
                        ? reportingFinancialExpense.expCategory.includes(expense.exp_category._id)
                        : true &&
                            reportingFinancialExpense.expCurrency
                            ? reportingFinancialExpense.expCurrency.includes(expense.exp_currency)
                            : true
                ) {
                    total += convertCurrencyHelper(expenseCurrency, settings.currency, expense.exp_amount, forex);
                }
            }

            return total;
        }, 0);
    };

    const currentTotal = calculateTotalExpenses(expenseData, startCurrentRange, endCurrentRange);
    const comparisonTotal = calculateTotalExpenses(expenseData, startComparisonRange, endComparisonRange);

    return [
        {
            name: 'Total Expense',
            currentYear: parseFloat(currentTotal.toFixed(2)),
            lastYear: parseFloat(comparisonTotal.toFixed(2)),
        }
    ];
}

export function processedExpensesByLocation(expenseData, reportingFinancialExpense, settings, currencies, forex) {

    if (!expenseData || !reportingFinancialExpense || !reportingFinancialExpense.expCategory || !reportingFinancialExpense.expCurrency) {
        return [];
    }

    const filteredExpenses = expenseData.filter(expense =>
        !expense.is_delete &&
        // !expense.exp_client?.is_delete &&
        reportingFinancialExpense.expCategory.includes(expense.exp_category?._id) &&
        reportingFinancialExpense.expCurrency.includes(expense.exp_currency) &&
        new Date(expense.exp_date).getFullYear() === (reportingFinancialExpense.expYear || new Date().getFullYear())
        // (expense.exp_is_billable || expense.exp_client === null)
    );

    const expensesByLocation = {};

    filteredExpenses.forEach(expense => {
        const country = expense.exp_client?.country || expense.exp_wx_company?.country || 'Unknown';

        if (!expensesByLocation[country]) {
            expensesByLocation[country] = 0;
        }

        const convertedAmount = convertCurrencyHelper(expense.exp_currency, currencies, expense.exp_amount, forex);
        expensesByLocation[country] += convertedAmount;
    });

    return Object.keys(expensesByLocation).map(country => ({
        name: country,
        closedAmount: expensesByLocation[country],
    }));
};

export function processedExpensesByLocationFilter(
    expenseData,
    reportingFinancialExpense,
    settings,
    forex,
    selectedDateRange,
    selectedComparison,
    selectedYearRange,
    selectedMonthRange,
    selectedComparisonYear,
    selectedComparisonMonth,
) {

    if (!expenseData || !reportingFinancialExpense || !reportingFinancialExpense.expCategory || !reportingFinancialExpense.expCurrency) {
        return [];
    }

    const getLastDayOfMonth = (year, month) => {
        return new Date(year, month, 0).getDate();
    };

    const getStartAndEndDates = (rangeId, year = new Date().getFullYear()) => {
        switch (rangeId) {
            case reportingFilterDateRangesConst.This_Year:
                return [
                    new Date(`${year}-01-01`),
                    new Date(`${year}-12-31`)
                ];

            case reportingFilterDateRangesConst.This_Quarter: {
                const quarter = Math.floor((new Date().getMonth() + 3) / 3);
                const startMonth = (quarter - 1) * 3 + 1;
                const endMonth = startMonth + 2;
                const endDay = getLastDayOfMonth(year, endMonth);
                return [
                    new Date(`${year}-${startMonth}-01`),
                    new Date(`${year}-${endMonth}-${endDay}`)
                ];
            }

            case reportingFilterDateRangesConst.First_Half:
                return [
                    new Date(`${year}-01-01`),
                    new Date(`${year}-06-30`)
                ];

            case reportingFilterDateRangesConst.This_Month: {
                const month = new Date().getMonth() + 1;
                const endDay = getLastDayOfMonth(year, month);
                return [
                    new Date(`${year}-${month}-01`),
                    new Date(`${year}-${month}-${endDay}`)
                ];
            }

            case reportingFilterDateRangesConst.Year_Selected:
                if (selectedYearRange) {
                    const selectedYear = selectedYearRange.getFullYear();
                    return [
                        new Date(`${selectedYear}-01-01`),
                        new Date(`${selectedYear}-12-31`)
                    ];
                } else {
                    return [
                        new Date(`${year}-01-01`),
                        new Date(`${year}-12-31`)
                    ];
                }

            case reportingFilterDateRangesConst.Month_Selected:
                if (selectedMonthRange) {
                    const selectedMonth = selectedMonthRange.getMonth() + 1;
                    const selectedYearForMonth = selectedMonthRange.getFullYear();
                    const endDay = getLastDayOfMonth(selectedYearForMonth, selectedMonth);
                    return [
                        new Date(`${selectedYearForMonth}-${selectedMonth}-01`),
                        new Date(`${selectedYearForMonth}-${selectedMonth}-${endDay}`)
                    ];
                } else {
                    // Fallback to the current month if selectedMonthRange is not provided
                    const currentMonth = new Date().getMonth() + 1;
                    const endDay = getLastDayOfMonth(year, currentMonth);
                    return [
                        new Date(`${year}-${currentMonth}-01`),
                        new Date(`${year}-${currentMonth}-${endDay}`)
                    ];
                }

            default:
                return [
                    new Date(`${year}-01-01`),
                    new Date(`${year}-12-31`)
                ];
        }
    };

    const getComparisonStartAndEndDates = (comparisonId, year = new Date().getFullYear()) => {
        switch (comparisonId) {
            case reportingFilterComparisonConst.Last_Year:
                return [
                    new Date(`${year - 1}-01-01`),
                    new Date(`${year - 1}-12-31`)
                ];

            case reportingFilterComparisonConst.Last_Quarter: {
                const quarter = Math.floor((new Date().getMonth() + 3) / 3);
                const lastQuarter = quarter === 1 ? 4 : quarter - 1;
                const startMonth = (lastQuarter - 1) * 3 + 1;
                const endMonth = startMonth + 2;
                const lastYear = quarter === 1 ? year - 1 : year;
                const endDay = getLastDayOfMonth(lastYear, endMonth);
                return [
                    new Date(`${lastYear}-${startMonth}-01`),
                    new Date(`${lastYear}-${endMonth}-${endDay}`)
                ];
            }

            case reportingFilterComparisonConst.Second_Half:
                return [
                    new Date(`${year}-07-01`),
                    new Date(`${year}-12-31`)
                ];

            case reportingFilterComparisonConst.Last_Month: {
                const lastMonth = new Date().getMonth(); // 0-indexed for previous month
                const lastYear = lastMonth === 0 ? year - 1 : year;
                const lastMonthFormatted = lastMonth === 0 ? 12 : lastMonth;
                const endDay = getLastDayOfMonth(lastYear, lastMonthFormatted);
                return [
                    new Date(`${lastYear}-${lastMonthFormatted}-01`),
                    new Date(`${lastYear}-${lastMonthFormatted}-${endDay}`)
                ];
            }

            case reportingFilterComparisonConst.Year_Select:
                if (selectedComparisonYear) {
                    const comparisonYear = selectedComparisonYear.getFullYear();
                    return [
                        new Date(`${comparisonYear}-01-01`),
                        new Date(`${comparisonYear}-12-31`)
                    ];
                } else {
                    return [
                        new Date(`${year - 1}-01-01`),
                        new Date(`${year - 1}-12-31`)
                    ];
                }

            case reportingFilterComparisonConst.Month_Select:
                if (selectedComparisonMonth) {
                    const comparisonMonth = selectedComparisonMonth.getMonth() + 1;
                    const comparisonYearForMonth = selectedComparisonMonth.getFullYear();
                    const endDay = getLastDayOfMonth(comparisonYearForMonth, comparisonMonth);
                    return [
                        new Date(`${comparisonYearForMonth}-${comparisonMonth}-01`),
                        new Date(`${comparisonYearForMonth}-${comparisonMonth}-${endDay}`)
                    ];
                } else {
                    const lastMonth = new Date().getMonth() || 12;
                    const fallbackYear = lastMonth === 12 ? year - 1 : year;
                    const endDay = getLastDayOfMonth(fallbackYear, lastMonth);
                    return [
                        new Date(`${fallbackYear}-${lastMonth}-01`),
                        new Date(`${fallbackYear}-${lastMonth}-${endDay}`)
                    ];
                }

            default:
                return [
                    new Date(`${year - 1}-01-01`),
                    new Date(`${year - 1}-12-31`)
                ];
        }
    };

    const [startCurrentRange, endCurrentRange] = getStartAndEndDates(selectedDateRange);
    const [startComparisonRange, endComparisonRange] = getComparisonStartAndEndDates(selectedComparison);

    const filterExpenses = (expenses, startDate, endDate) => {
        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(23, 59, 59, 999);
        return expenses.filter(expense =>
            !expense.is_delete &&
            !expense.exp_client?.is_delete &&
            reportingFinancialExpense.expCategory.includes(expense.exp_category?._id) &&
            reportingFinancialExpense.expCurrency.includes(expense.exp_currency) &&
            new Date(expense.exp_date) >= startDate &&
            new Date(expense.exp_date) <= endDate
            // (expense.exp_is_billable || expense.exp_client === null)
        );
    };

    const calculateExpensesByLocation = (filteredExpenses) => {
        const expensesByLocation = {};

        filteredExpenses.forEach(expense => {
            const country = expense.exp_client?.country || expense.exp_wx_company?.country || 'Unknown';

            if (!expensesByLocation[country]) {
                expensesByLocation[country] = 0;
            }

            const convertedAmount = convertCurrencyHelper(expense.exp_currency, settings.currency, expense.exp_amount, forex);
            expensesByLocation[country] += convertedAmount;
        });

        return Object.keys(expensesByLocation).map(country => ({
            name: country,
            amount: expensesByLocation[country],
        }));
    };

    const currentPeriodExpenses = filterExpenses(expenseData, startCurrentRange, endCurrentRange);
    const comparisonPeriodExpenses = filterExpenses(expenseData, startComparisonRange, endComparisonRange);

    const currentYearExpensesByLocation = calculateExpensesByLocation(currentPeriodExpenses);
    const lastYearExpensesByLocation = calculateExpensesByLocation(comparisonPeriodExpenses);

    const combinedExpenses = currentYearExpensesByLocation.map(current => {
        const lastYearData = lastYearExpensesByLocation.find(last => last.name === current.name) || { amount: 0 };
        return {
            name: current.name,
            currentYear: parseFloat(current.amount.toFixed(2)),
            lastYear: parseFloat(lastYearData.amount.toFixed(2)),
        };
    });

    return combinedExpenses;
};

export function processedBudgetExpensesByCategory(expenseData, reportingFinancialExpense, settings, currencies, forex) {
    if (!expenseData || !reportingFinancialExpense || !reportingFinancialExpense.expCategory || !reportingFinancialExpense.expCurrency) {
        return [];
    }

    const filteredExpenses = expenseData.filter(expense =>
        !expense.is_delete &&
        reportingFinancialExpense.expCategory.includes(expense.exp_category?._id) &&
        // new Date(expense.exp_date).getFullYear() === (reportingFinancialExpense.expYear || new Date().getFullYear()) &&
        reportingFinancialExpense.expCurrency.includes(expense.exp_currency)
    );

    const expensesByCategory = {};

    filteredExpenses.forEach(expense => {
        const category = expense.exp_category?.exp_category_name || 'Unknown';

        if (!expensesByCategory[category]) {
            expensesByCategory[category] = { budgeted: 0, actual: 0 };
        }

        const convertedAmount = convertCurrencyHelper(expense.exp_currency, currencies, expense.exp_amount, forex);
        const convertedBudget = convertCurrencyHelper(expense.exp_category.exp_cat_currency, currencies, expense.exp_category.exp_cat_budget, forex);

        expensesByCategory[category].actual += convertedAmount;
        expensesByCategory[category].budgeted = convertedBudget; // Assuming budget is same for all expenses in the same category
    });

    return Object.keys(expensesByCategory).map(category => ({
        name: category,
        budgeted: parseFloat(expensesByCategory[category].budgeted.toFixed(2)),
        actual: parseFloat(expensesByCategory[category].actual.toFixed(2)),
    }));
};

export function processedBudgetExpensesByCategoryFilter(
    expenseData,
    reportingFinancialExpense,
    settings,
    forex,
    selectedDateRange,
    selectedComparison,
    selectedYearRange,
    selectedMonthRange,
    selectedComparisonYear,
    selectedComparisonMonth,
) {
    if (!expenseData || !reportingFinancialExpense || !reportingFinancialExpense.expCategory || !reportingFinancialExpense.expCurrency) {
        return [];
    }

    const getLastDayOfMonth = (year, month) => {
        return new Date(year, month, 0).getDate();
    };

    const getStartAndEndDates = (rangeId, year = new Date().getFullYear()) => {
        switch (rangeId) {
            case reportingFilterDateRangesConst.This_Year:
                return [
                    new Date(`${year}-01-01`),
                    new Date(`${year}-12-31`)
                ];

            case reportingFilterDateRangesConst.This_Quarter: {
                const quarter = Math.floor((new Date().getMonth() + 3) / 3);
                const startMonth = (quarter - 1) * 3 + 1;
                const endMonth = startMonth + 2;
                const endDay = getLastDayOfMonth(year, endMonth);
                return [
                    new Date(`${year}-${startMonth}-01`),
                    new Date(`${year}-${endMonth}-${endDay}`)
                ];
            }

            case reportingFilterDateRangesConst.First_Half:
                return [
                    new Date(`${year}-01-01`),
                    new Date(`${year}-06-30`)
                ];

            case reportingFilterDateRangesConst.This_Month: {
                const month = new Date().getMonth() + 1;
                const endDay = getLastDayOfMonth(year, month);
                return [
                    new Date(`${year}-${month}-01`),
                    new Date(`${year}-${month}-${endDay}`)
                ];
            }

            case reportingFilterDateRangesConst.Year_Selected:
                if (selectedYearRange) {
                    const selectedYear = selectedYearRange.getFullYear();
                    return [
                        new Date(`${selectedYear}-01-01`),
                        new Date(`${selectedYear}-12-31`)
                    ];
                } else {
                    return [
                        new Date(`${year}-01-01`),
                        new Date(`${year}-12-31`)
                    ];
                }

            case reportingFilterDateRangesConst.Month_Selected:
                if (selectedMonthRange) {
                    const selectedMonth = selectedMonthRange.getMonth() + 1;
                    const selectedYearForMonth = selectedMonthRange.getFullYear();
                    const endDay = getLastDayOfMonth(selectedYearForMonth, selectedMonth);
                    return [
                        new Date(`${selectedYearForMonth}-${selectedMonth}-01`),
                        new Date(`${selectedYearForMonth}-${selectedMonth}-${endDay}`)
                    ];
                } else {
                    const currentMonth = new Date().getMonth() + 1;
                    const endDay = getLastDayOfMonth(year, currentMonth);
                    return [
                        new Date(`${year}-${currentMonth}-01`),
                        new Date(`${year}-${currentMonth}-${endDay}`)
                    ];
                }

            default:
                return [
                    new Date(`${year}-01-01`),
                    new Date(`${year}-12-31`)
                ];
        }
    };

    const getComparisonStartAndEndDates = (comparisonId, year = new Date().getFullYear()) => {
        switch (comparisonId) {
            case reportingFilterComparisonConst.Last_Year:
                return [
                    new Date(`${year - 1}-01-01`),
                    new Date(`${year - 1}-12-31`)
                ];

            case reportingFilterComparisonConst.Last_Quarter: {
                const quarter = Math.floor((new Date().getMonth() + 3) / 3);
                const lastQuarter = quarter === 1 ? 4 : quarter - 1;
                const startMonth = (lastQuarter - 1) * 3 + 1;
                const endMonth = startMonth + 2;
                const lastYear = quarter === 1 ? year - 1 : year;
                const endDay = getLastDayOfMonth(lastYear, endMonth);
                return [
                    new Date(`${lastYear}-${startMonth}-01`),
                    new Date(`${lastYear}-${endMonth}-${endDay}`)
                ];
            }

            case reportingFilterComparisonConst.Second_Half:
                return [
                    new Date(`${year}-07-01`),
                    new Date(`${year}-12-31`)
                ];

            case reportingFilterComparisonConst.Last_Month: {
                const lastMonth = new Date().getMonth(); // 0-indexed for previous month
                const lastYear = lastMonth === 0 ? year - 1 : year;
                const lastMonthFormatted = lastMonth === 0 ? 12 : lastMonth;
                const endDay = getLastDayOfMonth(lastYear, lastMonthFormatted);
                return [
                    new Date(`${lastYear}-${lastMonthFormatted}-01`),
                    new Date(`${lastYear}-${lastMonthFormatted}-${endDay}`)
                ];
            }

            case reportingFilterComparisonConst.Year_Select:
                if (selectedComparisonYear) {
                    const comparisonYear = selectedComparisonYear.getFullYear();
                    return [
                        new Date(`${comparisonYear}-01-01`),
                        new Date(`${comparisonYear}-12-31`)
                    ];
                } else {
                    return [
                        new Date(`${year - 1}-01-01`),
                        new Date(`${year - 1}-12-31`)
                    ];
                }

            case reportingFilterComparisonConst.Month_Select:
                if (selectedComparisonMonth) {
                    const comparisonMonth = selectedComparisonMonth.getMonth() + 1;
                    const comparisonYearForMonth = selectedComparisonMonth.getFullYear();
                    const endDay = getLastDayOfMonth(comparisonYearForMonth, comparisonMonth);
                    return [
                        new Date(`${comparisonYearForMonth}-${comparisonMonth}-01`),
                        new Date(`${comparisonYearForMonth}-${comparisonMonth}-${endDay}`)
                    ];
                } else {
                    const lastMonth = new Date().getMonth() || 12;
                    const fallbackYear = lastMonth === 12 ? year - 1 : year;
                    const endDay = getLastDayOfMonth(fallbackYear, lastMonth);
                    return [
                        new Date(`${fallbackYear}-${lastMonth}-01`),
                        new Date(`${fallbackYear}-${lastMonth}-${endDay}`)
                    ];
                }

            default:
                return [
                    new Date(`${year - 1}-01-01`),
                    new Date(`${year - 1}-12-31`)
                ];
        }
    };

    const getFilteredExpensesByDateRange = (expenses, startDate, endDate) => {
        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(23, 59, 59, 999);
        return expenses.filter(expense =>
            !expense.is_delete &&
            reportingFinancialExpense.expCategory.includes(expense.exp_category?._id) &&
            new Date(expense.exp_date) >= startDate &&
            new Date(expense.exp_date) <= endDate &&
            reportingFinancialExpense.expCurrency.includes(expense.exp_currency)
        );
    };

    const calculateExpensesByCategory = (expenses) => {
        const expensesByCategory = {};

        expenses.forEach(expense => {
            const category = expense.exp_category?.exp_category_name || 'Unknown';

            if (!expensesByCategory[category]) {
                expensesByCategory[category] = { budgeted: 0, actual: 0 };
            }

            const convertedAmount = convertCurrencyHelper(expense.exp_currency, settings.currency, expense.exp_amount, forex);
            const convertedBudget = convertCurrencyHelper(expense.exp_category.exp_cat_currency, settings.currency, expense.exp_category.exp_cat_budget, forex);

            expensesByCategory[category].actual += convertedAmount;
            expensesByCategory[category].budgeted = convertedBudget; // Assuming budget is same for all expenses in the same category
        });

        return expensesByCategory;
    };

    const [startCurrentRange, endCurrentRange] = getStartAndEndDates(selectedDateRange);
    const [startComparisonRange, endComparisonRange] = getComparisonStartAndEndDates(selectedComparison);

    const currentPeriodExpenses = getFilteredExpensesByDateRange(expenseData, startCurrentRange, endCurrentRange);
    const comparisonPeriodExpenses = getFilteredExpensesByDateRange(expenseData, startComparisonRange, endComparisonRange);

    const currentPeriodExpensesByCategory = calculateExpensesByCategory(currentPeriodExpenses);
    const comparisonPeriodExpensesByCategory = calculateExpensesByCategory(comparisonPeriodExpenses);

    const combinedExpenses = Object.keys(currentPeriodExpensesByCategory).map(category => {
        const current = currentPeriodExpensesByCategory[category];
        const comparison = comparisonPeriodExpensesByCategory[category] || { budgeted: 0, actual: 0 };

        return {
            name: category,
            currentPeriodBudgeted: parseFloat(current.budgeted.toFixed(2)),
            currentPeriodActual: parseFloat(current.actual.toFixed(2)),
            comparisonPeriodBudgeted: parseFloat(comparison.budgeted.toFixed(2)),
            comparisonPeriodActual: parseFloat(comparison.actual.toFixed(2)),
        };
    });

    return combinedExpenses;
}