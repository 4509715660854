import React from "react";
import ClientInvoices from "../../../components/Clients/ClientInvoice/ClientInvoices";

function ClientInvoiceSection()  {
    return(
        <div className="react-outer">
            <ClientInvoices />
        </div>
    )
}

export default ClientInvoiceSection;