import './timeTracker.css';
import { Outlet } from 'react-router-dom';
import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import userTypeConsts from '../../constants/userTypeConsts';
import { Link, useNavigate } from 'react-router-dom';
import { setHeaderText } from "../../features/headerText/headerTextSlice";
import { motion } from 'framer-motion';

function TabModuleTracker() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const [selectedOption, setSelectedOption] = useState("Overview");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const handleOptionClick = (option, url) => {
    setSelectedOption(option);
    navigate(url);
    setDropdownOpen(false); // Close the dropdown after selecting an option
  };
  const navigate = useNavigate();
  const optionDropdownRef = useRef(null);
  const handleSelectChange = (event) => {
    const selectedPage = event.target.value;
    navigate(selectedPage);
  };

  const getOptionText = (path) => {
    switch (path) {
      case "/user/time-tracker/overview":
        return "Overview";
      case "/user/time-tracker/timer":
        return "Tracker";
      case "/user/time-tracker/team/members":
        return "Team";
      case "/user/time-tracker/client":
        return "Client";
      case "/user/time-tracker/projects":
        return "Projects";
      case "/user/time-tracker/tag":
        return "Tags";
      default:
        return "";
    }
  };
  // useEffect(() => {
  //   // Set the initial value of the select to match the current URL path
  //   const currentPath = window.location.pathname;
  //   document.querySelector('.mobile-tab-select').value = currentPath;
  // }, []);

  useEffect(() => {
    // Set the initial value of the select to match the current URL path
    const currentPath = window.location.pathname;
    setSelectedOption(getOptionText(currentPath));
    const selectElement = document.querySelector('.mobile-tab-select');
    if (selectElement) {
      selectElement.value = currentPath;
    }
  }, []);

  const handleClickOutsideDropdown = (event) => {
    const isClickInsideOptionDropdown = optionDropdownRef.current && optionDropdownRef.current.contains(event.target);
    if (!isClickInsideOptionDropdown) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutsideDropdown);
    return () => {
      document.removeEventListener('click', handleClickOutsideDropdown);
    };
  }, []);


  dispatch(setHeaderText("Time Tracker"));

  const componentRef = useRef(null);
  const fadeIn = {
      hidden: { opacity: 0 },
      visible: { opacity: 1 }
  };

  return (
    <div>
      <div className='tab-outer-time'>
        {/* <select className="mobile-tab-select" onChange={handleSelectChange}>
            <option value="/user/time-tracker/overview">Overview</option>
            <option value="/user/time-tracker/timer">Tracker</option>
            {user && (user.userType === userTypeConsts.SUPER_ADMIN || user.userType === userTypeConsts.ADMIN) && (
              <>
                <option value="/user/time-tracker/team/members">Team</option>
                <option value="/user/time-tracker/client">Client</option>
                <option value="/user/time-tracker/projects">Projects</option>
                <option value="/user/time-tracker/tag">Tags</option>
                <option value="/user/time-tracker/team/memberss" disabled>Report</option>
              </>
            )}
          </select> */}
        <div className='tab-mobile-select'>
          <h4>Select an option</h4>
          {/* <select className="mobile-tab-select" onChange={handleSelectChange}>
            <option value="/user/time-tracker/overview">Overview</option>
            <option value="/user/time-tracker/timer">Tracker</option>
            {user && (user.userType === userTypeConsts.SUPER_ADMIN || user.userType === userTypeConsts.ADMIN) && (
              <>
                <option value="/user/time-tracker/team/members">Team</option>
                <option value="/user/time-tracker/client">Client</option>
                <option value="/user/time-tracker/projects">Projects</option>
                <option value="/user/time-tracker/tag">Tags</option>
                <option value="/user/time-tracker/team/memberss" disabled>Report</option>
              </>
            )}
          </select> */}
          <div className="custom-dropdown-user-new-outer">
            <div className="selected-option custom-dropdown-user-new" onClick={() => setDropdownOpen(!dropdownOpen)} ref={optionDropdownRef} >
              {selectedOption}
            </div>
            {dropdownOpen && (
              <div className="custom-dropdown-new">
                <ul className="showing-options-list">
                  <li onClick={() => handleOptionClick("Overview", "/user/time-tracker/overview")}>Overview</li>
                  <li onClick={() => handleOptionClick("Tracker", "/user/time-tracker/timer")}>Tracker</li>
                  {user && (user.userType === userTypeConsts.SUPER_ADMIN || user.userType === userTypeConsts.ADMIN) && (
                    <>
                      {/* <li onClick={() => handleOptionClick("Team", "/user/time-tracker/team/members")}>Team</li>
                      <li onClick={() => handleOptionClick("Client", "/user/time-tracker/client")}>Client</li>
                      <li onClick={() => handleOptionClick("Projects", "/user/time-tracker/projects")}>Projects</li> */}
                      <li onClick={() => handleOptionClick("Tags", "/user/time-tracker/tag")}>Tags</li>
                      {/* <li disabled>Report</li> */}
                    </>
                  )}
                </ul>
              </div>
            )}
          </div>
        </div>


        <ul className="tabs">
          <li>
            <Link to="/user/time-tracker/overview" className={window.location.pathname.startsWith('/user/time-tracker/overview') ? 'active' : ''}>
              Overview
            </Link>
          </li>

          <li>
            <Link to='/user/time-tracker/timer' className={window.location.pathname.startsWith('/user/time-tracker/timer') ? 'active' : ''}>
              Tracker
            </Link>
          </li>

          {user && (user.userType === userTypeConsts.SUPER_ADMIN || user.userType === userTypeConsts.ADMIN) && (
            <>
              {/* <li>
                <Link to='/user/time-tracker/team/members' className={window.location.pathname.startsWith('/user/time-tracker/team') ? 'active' : ''}>
                  Team
                </Link>
              </li>
              <li>
                <Link to='/user/time-tracker/client' className={window.location.pathname === '/user/time-tracker/client' ? 'active' : ''}>
                  Client
                </Link>
              </li>
              <li>
                <Link to='/user/time-tracker/projects' className={window.location.pathname === '/user/time-tracker/projects' ? 'active' : ''}>
                  Projects
                </Link>
              </li> */}
              <li>
                <Link to='/user/time-tracker/tag' className={window.location.pathname === '/user/time-tracker/tag' ? 'active' : ''}>
                  Tags
                </Link>
              </li>
              {/* <li>
                <Link to='/user/time-tracker/team/memberss' className={window.location.pathname === '/user/time-tracker/team/memberss' ? 'active' : ''} style={{ pointerEvents: "none", opacity: 0.6 }}>
                  Report
                </Link>
              </li> */}
            </>
          )}
        </ul>

        <div className="tabs-content">
          <div className='tab-inner-time'>
         
            <Outlet />
           
          </div>
        </div>
        
      </div>
    </div>
  );
}

export default TabModuleTracker;
