import Modal from "react-modal";
import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Moment from "react-moment";
import css from "../TrackerImportData.css";
import { ThreeDots } from "react-loader-spinner";
import Card from "react-bootstrap/Card";
import CardHeader from "./CardHeaderImportData";
import CardBody from "./CardBodyImportData";


const TimeCardImportData = ({ index, item, timeFormat }) => {
    return (
        <Card className="text-center card-bg" >
            <CardHeader index={index} item={item} />
            <CardBody item={item} timeFormat={timeFormat} />
        </Card>
    );
}
export default TimeCardImportData;