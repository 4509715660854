import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ThreeDots } from "react-loader-spinner";
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ReferenceLine, LabelList } from 'recharts';
import { reportingFilterComparisonConst } from '../../../../../../constants/reportingFilterComparisonConst';
import { reportingFilterDateRangesConst } from '../../../../../../constants/reportingFilterDateRangesConst';
import { processedInvoicedOutstandingByClientFilter } from '../../Services/reportRevenue';

const InvoicedOutstandingFiterGraph = ({
  clientInvoices,
  clientInvoicesLoading,
  clientInvoicesError,
  selectedDateRange,
  selectedComparison,
  selectedFrequency,
  selectedYearRange,
  selectedMonthRange,
  selectedComparisonYear,
  selectedComparisonMonth,
}) => {
  const reportingRevenue = useSelector((state) => state.reportingRevenue);
  const { forex } = useSelector((state) => state.forex);
  const settings = JSON.parse(localStorage.getItem("settings"));

  const processedData = useMemo(() => {
    return processedInvoicedOutstandingByClientFilter(
      clientInvoices,
      reportingRevenue,
      settings.currency,
      forex,
      selectedDateRange,
      selectedComparison,
      selectedYearRange,
      selectedMonthRange,
      selectedComparisonYear,
      selectedComparisonMonth,
    );
  }, [clientInvoices, reportingRevenue, settings.currency, forex, selectedDateRange, selectedComparison, selectedYearRange, selectedMonthRange, selectedComparisonYear, selectedComparisonMonth,]);

  if (clientInvoicesLoading) return <div className="outter-load-table">
    <ThreeDots
      height="80"
      width="80"
      radius="9"
      color="#6479f9"
      ariaLabel="three-dots-loading"
      wrapperStyle={{}}
      wrapperClassName=""
      visible={true}
    />
  </div>;
  if (clientInvoicesError) return <div>Error loading data.</div>;

  const calculateXTicks = () => {
    if (!processedData.length) return [];
    const maxDataValue = Math.max(...processedData.flatMap(item => [
      item.currentPeriodInvoiced,
      item.comparisonPeriodInvoiced,
      item.currentPeriodOutstanding,
      item.comparisonPeriodOutstanding
    ]));
    if (maxDataValue === 0) return [0];
    const tickInterval = Math.ceil(maxDataValue / 10);
    const numOfTicks = Math.ceil(maxDataValue / tickInterval);
    const ticks = [];

    for (let i = 0; i <= numOfTicks + 4; i++) { // Adjust the number of ticks
      ticks.push(i * tickInterval);
    }

    return ticks;
  };

  const xAxisTicks = calculateXTicks();

  const getBarName = (isCurrent, isInvoiced) => {
    const rangeNames = {
      [reportingFilterDateRangesConst.This_Year]: 'This Year',
      [reportingFilterDateRangesConst.This_Quarter]: 'This Quarter',
      [reportingFilterDateRangesConst.First_Half]: 'First Half',
      [reportingFilterDateRangesConst.This_Month]: 'This Month',
      [reportingFilterDateRangesConst.Year_Selected]: selectedYearRange ? `Year ${selectedYearRange.getFullYear()}` : `Selected Year`,
      [reportingFilterDateRangesConst.Month_Selected]: selectedMonthRange ? `Month ${selectedMonthRange.toLocaleString('default', { month: 'long' })} ${selectedMonthRange.getFullYear()}` : `Selected Month`,
    };

    const comparisonNames = {
      [reportingFilterComparisonConst.Last_Year]: 'Last Year',
      [reportingFilterComparisonConst.Last_Quarter]: 'Last Quarter',
      [reportingFilterComparisonConst.Second_Half]: 'Second Half',
      [reportingFilterComparisonConst.Last_Month]: 'Last Month',
      [reportingFilterComparisonConst.Year_Select]: selectedComparisonYear ? `Year ${selectedComparisonYear.getFullYear()}` : `Previous Year`,
      [reportingFilterComparisonConst.Month_Select]: selectedComparisonMonth ? `Month ${selectedComparisonMonth.toLocaleString('default', { month: 'long' })} ${selectedComparisonMonth.getFullYear()}` : `Previous Month`,
    };

    const rangeName = rangeNames[selectedDateRange] || 'Current Range';
    const comparisonName = comparisonNames[selectedComparison] || 'Comparison Range';

    return isInvoiced
      ? `Invoiced ${isCurrent ? rangeName : comparisonName}`
      : `Outstanding ${isCurrent ? rangeName : comparisonName}`;
  };

  const calculateChartHeight = (dataLength) => {
    const rowHeight = 50;
    const baseHeight = 100;
    return dataLength * rowHeight + baseHeight;
  };

  const calculateLeftMargin = (data) => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    context.font = '12px Arial';

    const longestWordLength = data.reduce((maxLength, item) => {
      const firstWord = item.name.split(' ')[0];
      const wordWidth = context.measureText(firstWord).width;
      return Math.max(maxLength, wordWidth);
    }, 0);

    return Math.max(0, longestWordLength - 150);
  };

  const leftMargin = calculateLeftMargin(processedData);

  return (
    <div className='graph-project-per-hour' style={{ width: '100%', height: Math.max(542, calculateChartHeight(processedData.length)), }}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          layout="vertical"
          data={processedData}
          barSize={13}
          margin={{
            top: 20,
            right: 42,
            left: leftMargin,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" horizontal={false} vertical={true} />
          <XAxis
            type="number"
            domain={[0, 'dataMax + 10']}
            tickFormatter={(tick) => `${new Intl.NumberFormat('en-US', { style: 'currency', currency: settings.currency }).format(0).replace(/[\d.,]/g, '')}${tick}`}
            ticks={xAxisTicks}
          />
          <YAxis
            type="category"
            dataKey="name"
            // width={150}
            tickMargin={10}
          />
          <Tooltip formatter={(value) => `${new Intl.NumberFormat("en-US", { style: "currency", currency: settings.currency }).format(value)}`} />
          <Legend />
          <ReferenceLine x={0} stroke="#D3D3D3" />
          <Bar dataKey="currentPeriodInvoiced" name={getBarName(true, false)} fill="#87ABE2">
            <LabelList dataKey="currentPeriodInvoiced" position="right" formatter={(value) => `${new Intl.NumberFormat("en-US", { style: "currency", currency: settings.currency }).format(value)}`} />
          </Bar>
          <Bar dataKey="comparisonPeriodInvoiced" name={getBarName(false, false)} fill="#C7B6F6">
            <LabelList dataKey="comparisonPeriodInvoiced" position="right" formatter={(value) => `${new Intl.NumberFormat("en-US", { style: "currency", currency: settings.currency }).format(value)}`} />
          </Bar>
          <Bar dataKey="currentPeriodOutstanding" name={getBarName(true, true)} fill="#FFC107">
            <LabelList dataKey="currentPeriodOutstanding" position="right" formatter={(value) => `${new Intl.NumberFormat("en-US", { style: "currency", currency: settings.currency }).format(value)}`} />
          </Bar>
          <Bar dataKey="comparisonPeriodOutstanding" name={getBarName(false, true)} fill="#FF5722">
            <LabelList dataKey="comparisonPeriodOutstanding" position="right" formatter={(value) => `${new Intl.NumberFormat("en-US", { style: "currency", currency: settings.currency }).format(value)}`} />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}

export default InvoicedOutstandingFiterGraph;