const userTypeConsts = {
  SUPER_ADMIN: 1,
  ADMIN: 2,
  USER: 3,
  // BUSINESS: 4,
  COLLABERATOR: 5,
  PROJECT_MANAGER: 6,
  TEAM_MANAGER: 7,
};
export default userTypeConsts;

