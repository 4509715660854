import React, { useState, useEffect } from "react";

const SmoothLoadMap = ({ items }) => {
  const [visibleItems, setVisibleItems] = useState([]);

  useEffect(() => {
    let chunkIndex = 0;
    const chunkSize = 10; // Adjust chunk size for smooth loading
    const loadMoreItems = () => {
      if (chunkIndex * chunkSize < items.length) {
        const nextChunk = items.slice(chunkIndex * chunkSize, (chunkIndex + 1) * chunkSize);
        setVisibleItems((prevItems) => [...prevItems, ...nextChunk]);
        chunkIndex++;
      } else {
        clearInterval(interval); // Stop loading when all items are loaded
      }
    };

    const interval = setInterval(loadMoreItems, 500); // Adjust interval timing

    return () => clearInterval(interval); // Cleanup on component unmount
  }, [items]);

  return (
    <div>
      {visibleItems.map((item, index) => (
        <div key={index}>{item}</div> // Render your items here
      ))}
    </div>
  );
};

export default SmoothLoadMap;