import React, { useState, useRef, useEffect } from "react";
import { useNavigate, Outlet } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import PaymentOption from "../PaymentOptions/PaymentOption";
import ClientPayment from "../ClientPayment/ClientPayment";
import { setTypeOfAccount } from "../../../features/accountDetails/accountDetailsSlice";

function BankAccount() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const { typeOfAccount } = useSelector((state) => state.accountDetails);
    const [selectedOption, setSelectedOption] = useState(typeOfAccount);
    const optionDropdownRef = useRef(null);

    const handleClickOutsideDropdown = (event) => {
        const isClickInsideOptionDropdown = optionDropdownRef.current && optionDropdownRef.current.contains(event.target);
        if (!isClickInsideOptionDropdown) {
            setDropdownOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutsideDropdown);
        return () => {
            document.removeEventListener('click', handleClickOutsideDropdown);
        };
    }, []);

    const handleOptionSelect = (option) => {
        dispatch(setTypeOfAccount(option));
        setSelectedOption(option);
        setDropdownOpen(false);
    };
    return (
        <div className="react-outer">
            <label>Type of account</label>
            <div className="custom-dropdown-user-new-outer">
                <div
                    className="selected-option custom-dropdown-user-new"
                    onClick={() => setDropdownOpen(!dropdownOpen)}
                    ref={optionDropdownRef}
                >
                    {selectedOption}
                </div>
                {dropdownOpen && (
                    <div className="custom-dropdown-new">
                        <ul className="showing-options-list">
                            <li onClick={() => handleOptionSelect("Transaction Accounts")}>
                                Transaction Accounts
                            </li>
                            <li onClick={() => handleOptionSelect("Operational Accounts")}>
                                Operational Accounts
                            </li>
                        </ul>
                    </div>
                )}
            </div>
        </div>
    );
}

export default BankAccount;