import axios from 'axios';
import { useQuery, useMutation } from 'react-query';

export const useFetchVendorDetails = (token) => {
    return useQuery('vendorDetails', async () => {
        const response = await axios.get('/api/vendor-details', {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    });
};

export function useVendorView(vendorId, token) {
    return useQuery(['vendorView', vendorId], async () => {
        const response = await axios.get(`/api/vendor-details/${vendorId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    });
}

export function useVendorNameView(vendorId, token) {
    return useQuery(
        ['vendorView', vendorId],
        async () => {
            if (!vendorId) {
                return null;
            }
            const response = await axios.get(`/api/vendor-details/${vendorId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return response.data;
        },
        {
            enabled: !!vendorId, // Disable query if vendorId is not present
        }
    );
}


export function useUserByVendor(vendorId, token) {
    return useQuery(['userByVentorView', vendorId], async () => {
        const response = await axios.get(`/api/user/view/by/vendor/${vendorId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    });
}

// export function usePosition(token) {
//     return useQuery('position', async () => {
//         const response = await axios.get('/api/position-details', {
//             headers: {
//                 Authorization: `Bearer ${token}`,
//             },
//         });
//         return response.data;
//     });
// }

export function useDeleteVendorDetails(token) {
    const mutation = useMutation((selectedId) => {
        return axios.get(`/api/vendor-details/delete/${selectedId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    });

    return mutation;
}

export function useUpdateVendorDetails(token) {
    const mutation = useMutation((updatedVendorDetails) => {
        return axios.put(`/api/vendor-details/update/${updatedVendorDetails.selectedId}`, updatedVendorDetails.data, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    });

    return mutation;
}

// export function useUpdateAccManager(token) {
//     const mutation = useMutation((updatedAccManager) => {
//         return axios.put(`/api/clients/update-acc-manager/${updatedAccManager.id}`,{acc_manager: updatedAccManager.acc_manager}, {
//             headers: {
//                 Authorization: `Bearer ${token}`,
//             },
//         });
//     });

//     return mutation;
// }

// export function useUpdateProjManager(token) {
//     const mutation = useMutation((updatedProjManager) => {
//         return axios.put(`/api/client/update-project-managers/${updatedProjManager.id}`,{projectManagers: updatedProjManager.projectManagers, previousProjectManager: updatedProjManager.previousProjectManager}, {
//             headers: {
//                 Authorization: `Bearer ${token}`,
//             },
//         });
//     });

//     return mutation;
// }

// export function useRemoveTeamMember(token) {
//     const mutation = useMutation((removedTeamMember) => {
//         return axios.put(`/api/client/remove-project-manager/${removedTeamMember.id}`,{projectManagerId: removedTeamMember.teamMember }, {
//             headers: {
//                 Authorization: `Bearer ${token}`,
//             },
//         });
//     });

//     return mutation;
// }