import axios from "axios";
import "./UserSecurity.css";
import Modal from "react-modal";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import { useSelector, useDispatch } from "react-redux";
import React, { useState, useRef, useEffect } from "react";
import { logout, reset } from "../../../../features/auth/authSlice";
import crssrImage from "../../../../images/singleinvoice/cross-red.svg";
import { reset as invoiceReset } from "../../../../features/invoice/invoiceSlice.js";
import { reset as headerReset } from "../../../../features/headerText/headerTextSlice.js";
import { reset as appSettingsReset } from "../../../../features/appSettings/appSettingsSlice.js";

const UserSecurity = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isChangePassModalOpen, setIsChangePassModalOpen] = useState(false);
  const [is2faModalOpen, setIs2faModalOpen] = useState(false);
  const [is2faVerifyModalOpen, setIs2faVerifyModalOpen] = useState(false);
  const [isDisable2faModalOpen, setIsDisable2faModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [codeError, setCodeError] = useState(null);
  const [code, setCode] = useState(['', '', '', '', '', '']);
  const codeInputs = useRef([]);
  const [qrcode, setQrcode] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isQRLoading, setIsQRLoading] = useState(true);
  const [is2faEnabled, setIs2faEnabled] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [userView, setUserView] = useState([]);
  const [oldPassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [oldPasswordError, setOldPasswordError] = useState("");
  const [passwordMatchError, setPasswordMatchError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const oldPasswordRef = useRef(null);
  const passwordRef = useRef(null);
  const passwordConfirmationRef = useRef(null);
  let token = null;
  const { user } = useSelector((state) => state.auth);
  if (user) {
    if (user && user.token) {
      token = user.token;
    }
  }

  useEffect(() => {
    setIsSubmitLoading(true);
    setLoading(true);
    async function fetchUser() {
      try {
        setIsLoading(true);
        const response = await axios.get(`/api/user/view/${props.id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUserView(response.data);
        setIs2faEnabled(response.data.is_2fa_enabled);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        global.config.slackMessage(error.toString());
        if (error.response?.data?.status === "deleted") {
          localStorage.removeItem("user");
          dispatch(logout());
          dispatch(reset());
          dispatch(invoiceReset());
          dispatch(headerReset());
          dispatch(appSettingsReset());
          toast.error('This user is deleted!', {
            position: "bottom-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          navigate("/");
          return;
        }
        alert("Something went wrong in fetching users");
      } finally {
        setIsLoading(false);
      }
    }

    fetchUser().then(() => {
    });

    const fetchQr = async () => {
      setIsQRLoading(true);
      try {
        const response = await axios.post("/api/user/generateQRCode", {
          userId: props.id,
        });

        setQrcode(response.data);
        setIsQRLoading(false);
      } catch (error) {
        console.error(error);
        global.config.slackMessage(error.toString());
        if (error.response?.data?.status === "deleted") {
          localStorage.removeItem("user");
          dispatch(logout());
          dispatch(reset());
          dispatch(invoiceReset());
          dispatch(headerReset());
          dispatch(appSettingsReset());
          toast.error('This user is deleted!', {
            position: "bottom-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          navigate("/");
          return;
        }
        alert('Somthing went wrong');
      }
    };

    fetchQr();
    setIsSubmitLoading(false);
    setLoading(false);
  }, [props.id, token, userView.is_2fa_enabled]);

  const openChangePassModal = () => {
    setIsChangePassModalOpen(true);
  };

  const checkOldPassword = async (email, oldPassword) => {
    try {
      const res = await axios.post("/api/user/check-password", { email, oldPassword });
      const data = res.data;
      if (data.status === "success") {
        return true; // Old password is correct
      } else {
        return false; // Incorrect old password
      }
    } catch (error) {
      console.error(error);
      global.config.slackMessage(error.toString());
      if (error.response?.data?.status === "deleted") {
        localStorage.removeItem("user");
        dispatch(logout());
        dispatch(reset());
        dispatch(invoiceReset());
        dispatch(headerReset());
        dispatch(appSettingsReset());
        toast.error('This user is deleted!', {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/");
        return;
      }
      return false; // Something went wrong
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setOldPasswordError("");
    setPasswordMatchError("");
    setPasswordError("");
    const isOldPasswordCorrect = await checkOldPassword(userView.email, oldPassword);
    if (oldPassword === "" || password === "" || passwordConfirmation === "") {
      if (oldPassword === "") {
        setOldPasswordError("Old Password cannot be empty");
        oldPasswordRef.current.focus();
        setLoading(false);
        return;
      } else if (password === "") {
        setPasswordError("Password cannot be empty");
        passwordRef.current.focus();
        setLoading(false);
        return;
      } else if (passwordConfirmation === "") {
        setPasswordMatchError("Confirm Password cannot be null");
        passwordConfirmationRef.current.focus();
        setLoading(false);
        return;
      }
      toast.error("Please fill all required the fields", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    if (!isOldPasswordCorrect) {
      toast.error("Incorrect old password!!", {
        position: "bottom-right",
        autoClose: 3500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setOldPasswordError("Your Password is Incorrect");
      oldPasswordRef.current.focus();
      setLoading(false);
      return;
    }
    if (oldPassword === password) {
      setOldPasswordError("Your New Password is same as old password");
      oldPasswordRef.current.focus();
      setLoading(false);
      return;
    } else if (password !== passwordConfirmation) {
      setPasswordMatchError("Passwords do not match");
      passwordConfirmationRef.current.focus();
      setLoading(false);
      return;
    }
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    if (!passwordRegex.test(password)) {
      setPasswordError("Invalid password format");
      toast.error("Password must be at least 8 charachters long and include atleast one uppercase letter ,one lowercase letter,one digit and one special character from the set !@#$%^&*()_+,-/:;<=>?@_'[\]{|}~", {
        position: "bottom-right",
        autoClose: 3500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      passwordRef.current.focus();
      setLoading(false);
      return;
    }
    const requestData = {
      email: userView.email,
      password: password,
      oldPassword: oldPassword,
    };

    try {
      const res = await axios.post("/api/user/reset-password", requestData);
      const data = res.data;

      if (data.status === "success") {
        toast.success("Your password is reset successfully", {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        closeChangePassModal();
      } else {
        toast.error("Something went wrong while sending password reset mail ", {
          position: "bottom-right",
          autoClose: 3500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      setLoading(false);
    } catch (err) {
      console.error(err.message);
      global.config.slackMessage(err.toString());
      if (err.response?.data?.status === "deleted") {
        localStorage.removeItem("user");
        dispatch(logout());
        dispatch(reset());
        dispatch(invoiceReset());
        dispatch(headerReset());
        dispatch(appSettingsReset());
        navigate("/");
        return;
      } else if (err.response.data.status === "incorrectPass") {
        toast.error("Incorrect old password!!", {
          position: "bottom-right",
          autoClose: 3500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        console.log(err.response.data.message);
      } else {
        toast.error("Error occurred", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      setLoading(false);
    }
  };

  const closeChangePassModal = () => {
    setIsChangePassModalOpen(false);
    setOldPassword("");
    setPassword("");
    setPasswordConfirmation("");
    setOldPasswordError("");
    setPasswordMatchError("");
    setPasswordError("");
  };

  const open2faModal = () => {
    setIs2faModalOpen(true);
  };

  const enable2fa = async () => {
    try {
      setIsSubmitLoading(true);
      await axios.post("/api/user/enableTwofa", {
        userId: userView._id,
        secret: qrcode.secret.base32,
      });
      close2faModal();
      openVerify2faModal();
    } catch (error) {
      console.error(error);
      global.config.slackMessage(error.toString());
      if (error.response?.data?.status === "deleted") {
        localStorage.removeItem("user");
        dispatch(logout());
        dispatch(reset());
        dispatch(invoiceReset());
        dispatch(headerReset());
        dispatch(appSettingsReset());
        toast.error('This user is deleted!', {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/");
        return;
      }
    } finally {
      setIsSubmitLoading(false);
    }
  };

  const openVerify2faModal = () => {
    setIs2faVerifyModalOpen(true);
  };

  const handleCodeChange = (index, value) => {
    const newCode = [...code];
    newCode[index] = value;
    setCode(newCode);

    // Move to the next input field
    if (value !== '' && index < code.length - 1) {
      codeInputs.current[index + 1].focus();
    }
  };

  const handleCodeKeyDown = (index, event) => {
    if (event.key === 'Backspace' && index > 0 && code[index] === '') {
      // Move to the previous input field when backspace is pressed and the current field is empty
      codeInputs.current[index - 1].focus();
    }
  };

  const verifyEnableTwoFA = async () => {
    const isCodeEmpty = code.some((digit) => digit === '');
    if (isCodeEmpty) {
      toast.error("You need to enter a code to enable 2FA", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    try {
      setIsSubmitLoading(true);
      const response = await axios.post('/api/user/verify/enable/twofa', {
        userId: userView._id,
        otpCode: code.join(''),
        secret: qrcode.secret.base32,
      });
      if (response.data === true) {
        setIs2faEnabled(true);

        localStorage.setItem('twoFactorEnabled', 'true');
        localStorage.setItem('twoFactorEnabledAt', Date.now());

        toast.success("2 step verification enabled successfully", {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        closeVerify2faModal();
      } else {
        setCodeError('Invalid Code. Please Try again.');
      }
    } catch (error) {
      setCodeError('Invalid Code. Please Try again.');
      global.config.slackMessage(error.toString());
      console.error(error);
    } finally {
      setIsSubmitLoading(false);
    }
  };

  const closeVerify2faModal = () => {
    setCodeError(null);
    setCode(['', '', '', '', '', '']);
    setIs2faVerifyModalOpen(false);
  };

  const close2faModal = () => {
    setIs2faModalOpen(false);
  };

  const openDisable2faModal = () => {
    setIsDisable2faModalOpen(true);
  };

  const disable2fa = async () => {
    setIsSubmitLoading(true);
    try {
      await axios.get(`/api/user/disableTwofa/${userView._id}`);
      setIs2faEnabled(false);
      toast.success("2 step verification removed successfully", {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      closeDisable2faModal();
    } catch (error) {
      console.log(error);
      global.config.slackMessage(error.toString());
      if (error.response?.data?.status === "deleted") {
        localStorage.removeItem("user");
        dispatch(logout());
        dispatch(reset());
        dispatch(invoiceReset());
        dispatch(headerReset());
        dispatch(appSettingsReset());
        toast.error('This user is deleted!', {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/");
        return;
      }
    } finally {
      setIsSubmitLoading(false);
    }
  };

  const closeDisable2faModal = () => {
    setIsDisable2faModalOpen(false);
  };

  const openDeleteModal = () => {
    setIsDeleteModalOpen(true);
  };

  const handleDeleteAccount = async (e) => {
    e.preventDefault();
    setLoading(true);
    setPasswordError("");
    if (userPassword === "") {
      if (userPassword === "") {
        setPasswordError("Password cannot be empty");
        passwordRef.current.focus();
        setLoading(false);
        return;
      }
      toast.error("Please fill all required the fields", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setLoading(false);
      return;
    }
    const requestData = {
      id: userView._id,
      password: userPassword,
    };
    try {
      const res = await axios.post("/api/delete/user/account", requestData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = res.data;

      if (data.status === "Success") {
        toast.success("Your account is deleted successfully", {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        closeDeleteModal();
        setLoading(false);
        localStorage.removeItem("user");
        dispatch(logout());
        dispatch(reset());
        dispatch(invoiceReset());
        dispatch(headerReset());
        dispatch(appSettingsReset());
        navigate("/");
      } else {
        toast.error("Something went wrong while sending password reset mail ", {
          position: "bottom-right",
          autoClose: 3500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setLoading(false);
      }
      setLoading(false);
    } catch (err) {
      console.error(err.message);
      global.config.slackMessage(err.toString());
      if (err.response?.data?.status === "deleted") {
        localStorage.removeItem("user");
        dispatch(logout());
        dispatch(reset());
        dispatch(invoiceReset());
        dispatch(headerReset());
        dispatch(appSettingsReset());
        navigate("/");
        return;
      } else if (err.response.data.status === "invalidPass") {
        toast.error("Your entered wrong password", {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setLoading(false);
      } else if (err.response.data.status === "userNotFound") {
        toast.error("User with this ID is not found!!", {
          position: "bottom-right",
          autoClose: 3500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        console.log(err.response.data.message);
        setLoading(false);
      } else {
        toast.error("Error occurred", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      setLoading(false);
    }
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setLoading(false);
    setUserPassword("");
    setPasswordError("");
  };

  const numberInputOnWheelPreventChange = (e) => {
    // Prevent the input value change
    e.target.blur()

    // Prevent the page/container scrolling
    e.stopPropagation()

    setTimeout(() => {
      e.target.focus()
    }, 0)
  }

  return (
    <div>
      <div>
        <div>
          <div className="security-outer">
            <div className="security-inner">
              <div className="security-inner-title">
                <span className="inner-title">Password</span>
                <span className="inner-title-description">
                  Set a unique password to protect your account
                </span>
              </div>
              <div className="button-sec-account">
                <a onClick={!isLoading ? openChangePassModal : undefined}>
                  Change Password
                </a>
              </div>
              <Modal
                className="client-modal project-modal userAccount-modal change-pass-modal"
                isOpen={isChangePassModalOpen}
                onRequestClose={closeChangePassModal}
                contentLabel="New Client Account"
                style={{
                  content: {
                    height: "41%",
                    width: "30%",
                    position: "fixed",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    parent: document.querySelector(".admin-outer.time.tracker"),
                  },
                }}
              >
                <div className="modal">
                  <div className="modal-close-invite outter-modal-main">
                    <a className="" onClick={closeChangePassModal}>
                      <img src={crssrImage} alt="Edit Icon" />{" "}
                    </a>
                  </div>
                  <div className="modal-client-head">
                    <h2 className="">Change Password</h2>
                  </div>
                  <div className="form-field-client-outer">
                    <form onSubmit={onSubmit}>
                      <div className="form-field-client">
                        <label htmlFor="oldPassoword" className="form-label">
                          Old Passoword
                        </label>
                        <input
                          type="password"
                          name="oldpassword"
                          id="oldpassword"
                          className={
                            oldPasswordError
                              ? "input-validation-error form-input"
                              : "form-input"
                          }
                          value={oldPassword}
                          onChange={(e) => setOldPassword(e.target.value)}
                          ref={oldPasswordRef}
                        />
                        {oldPasswordError && (
                          <span className="text-red-500 text-xs italic">
                            {oldPasswordError}
                          </span>
                        )}
                      </div>
                      <div className="form-field-client">
                        <label htmlFor="password" className="form-label">
                          New Password
                        </label>
                        <input
                          type="password"
                          name="password"
                          id="password"
                          className={
                            passwordError
                              ? "input-validation-error form-input"
                              : "form-input"
                          }
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          ref={passwordRef}
                        />
                        {passwordError && (
                          <span className="text-red-500 text-xs italic">
                            {passwordError}
                          </span>
                        )}
                      </div>
                      <div className="form-field-client">
                        <label htmlFor="confPassword" className="form-label">
                          Confirm new Password
                        </label>
                        <input
                          type="password"
                          className={
                            passwordMatchError
                              ? "input-validation-error form-input"
                              : "form-input"
                          }
                          value={passwordConfirmation}
                          onChange={(e) =>
                            setPasswordConfirmation(e.target.value)
                          }
                          ref={passwordConfirmationRef}
                        />
                        {passwordMatchError && (
                          <span className="text-red-500 text-xs italic">
                            {passwordMatchError}
                          </span>
                        )}
                      </div>
                      <button
                        className="reset-pass"
                        type="submit"
                        disabled={loading}
                      >
                        Reset Password
                      </button>
                    </form>
                  </div>
                </div>
              </Modal>
            </div>
            <div className="security-inner">
              <div className="security-inner-title">
                <span className="inner-title">2-step verification</span>
                <span className="inner-title-description">
                  Make your account extra secure.
                </span>
              </div>
              <div className="button-sec-account">
                {isLoading ?
                  <ThreeDots
                    height="28"
                    width="30"
                    radius="9"
                    color="#6479f9"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  /> :
                  <>
                    {(is2faEnabled === false) ? (
                      <a onClick={!isSubmitLoading || !isLoading ? open2faModal : undefined}>
                        {isSubmitLoading ?
                          <div className="outter-load-table">
                            <ThreeDots
                              height="18"
                              width="20"
                              radius="9"
                              color="white"
                              ariaLabel="three-dots-loading"
                              wrapperStyle={{}}
                              wrapperClassName=""
                              visible={true}
                            />
                          </div> : "Add Second Method"}
                      </a>
                    ) : (
                      <a onClick={!isSubmitLoading || !isLoading ? openDisable2faModal : undefined} style={{ backgroundColor: "red" }}>
                        {isSubmitLoading ?
                          <div className="outter-load-table">
                            <ThreeDots
                              height="18"
                              width="20"
                              radius="9"
                              color="white"
                              ariaLabel="three-dots-loading"
                              wrapperStyle={{}}
                              wrapperClassName=""
                              visible={true}
                            />
                          </div> : "Remove Second Method"}
                      </a>
                    )}
                  </>}
              </div>
              <Modal
                className="client-modal userAccount-modal twofa-modal"
                isOpen={is2faModalOpen}
                onRequestClose={close2faModal}
                contentLabel="New Client Account"
                style={{
                  content: {
                    height: "41%",
                    width: "30%",
                    position: "fixed",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    parent: document.querySelector(".admin-outer.time.tracker"),
                  },
                }}
              >
                <div className="modal two-fa-modal-outer">
                  <div className="modal-close-invite outter-modal-main">
                    <a className="" onClick={close2faModal}>
                      <img src={crssrImage} alt="Edit Icon" />{" "}
                    </a>
                  </div>
                  <div className="twoFAContainer">
                    <>
                      <div className="qr-title">
                        <h2>Register Talenton</h2>
                      </div>
                      <div className="qr-des">
                        <p>
                          Scan this QR code with a Google Authenticator app.
                        </p>
                      </div>
                      {isQRLoading ? (
                        <ThreeDots
                          height="18"
                          width="20"
                          radius="9"
                          color="white"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClassName=""
                          visible={true}
                        />
                      ) : (
                        <>
                          <div className="qr-image-sec">
                            <img
                              className="qrCodeImage"
                              src={qrcode.data}
                              alt="QR Code"
                            />
                          </div>
                          <div className='qr-btn'>
                            <a className="linkButton" onClick={!isSubmitLoading || !isLoading ? enable2fa : undefined}>
                              {isSubmitLoading ?
                                <div className="outter-load-table">
                                  <ThreeDots
                                    height="18"
                                    width="20"
                                    radius="9"
                                    color="#6479f9"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                  />
                                </div> : "Enable"}
                            </a>
                          </div>
                        </>
                      )}
                      <div className="qr-bottom-sec">
                        <p>
                          Once you connect your Talenton account to the security
                          or authentication app, you’ll get a one-time
                          verification code whenever you need it.
                        </p>
                      </div>
                    </>
                  </div>
                </div>
              </Modal>
              <Modal
                className="client-modal userAccount-modal userAccount-modal-two-fa"
                isOpen={is2faVerifyModalOpen}
                onRequestClose={closeVerify2faModal}
                contentLabel="Delete User Account"
                style={{
                  content: {
                    height: "41%",
                    width: "30%",
                    position: "fixed",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    parent: document.querySelector(".admin-outer.time.tracker"),
                  },
                }}
              >
                <div className="modal two-fa-modal-outer">
                  <div className="modal-close-invite outter-modal-main">
                    <a className="" onClick={closeVerify2faModal}>
                      <img src={crssrImage} alt="Edit Icon" />{" "}
                    </a>
                  </div>
                  <div className="form-field-client-outer">
                    <>
                      <div className='qr-code-title'>
                        Enter 6-digit code
                      </div>
                      <div className='qr-code-des'>
                        Enter the code from your two-factor authentication app to enable 2FA
                      </div>
                      <div className="code-input-container">
                        {code.map((digit, index) => (
                          <input
                            key={index}
                            ref={(ref) => (codeInputs.current[index] = ref)}
                            type="number"
                            className="code-input"
                            value={digit}
                            onChange={(e) => handleCodeChange(index, e.target.value)}
                            onKeyDown={(e) => handleCodeKeyDown(index, e)}
                            maxLength={1}
                            onWheel={numberInputOnWheelPreventChange}
                          />
                        ))}
                        {codeError && <p className="error two-fa-error" style={{ color: 'red' }}>{codeError}</p>}
                      </div>

                      <div className='qr-code-btn'>
                        <button type="submit" onClick={verifyEnableTwoFA}>Verify</button>
                      </div>

                    </>
                  </div>
                </div>
              </Modal>
              <Modal
                className="delete-modal"
                isOpen={isDisable2faModalOpen}
                onRequestClose={closeDisable2faModal}
                contentLabel="Confirm Delete"
                style={{
                  content: {
                    height: "41%",
                    width: "30%",
                    position: "fixed",
                    top: "36%",
                    left: "50%",
                    transform: "translate(-19.75%, 0%)",
                    parent: document.querySelector(".admin-outer.time.tracker"),
                  },
                }}
              >
                <div className="delete-modal-content">
                  <h2>Confirm Disable 2FA</h2>
                  <p>
                    Are you sure you want to Disable 2FA in this account?
                  </p>
                  <div className="delete-modal-buttons">
                    <button className="buttons-rejected" onClick={!isSubmitLoading || !isLoading ? disable2fa : undefined} disabled={isSubmitLoading}>
                      {isSubmitLoading ?
                        <div className="outter-load-table">
                          <ThreeDots
                            height="18"
                            width="20"
                            radius="9"
                            color="white"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                          />
                        </div> : "Disable 2FA"}
                    </button>
                    <button onClick={closeDisable2faModal} disabled={isSubmitLoading}>
                      Cancel
                    </button>
                  </div>
                </div>
              </Modal>
            </div>
            <div className="security-inner">
              <div className="security-inner-title">
                <span className="inner-title">Delete account</span>
                <span className="inner-title-description">
                  This will delete your account. You account will be permanently
                  deleted from Talenton
                </span>
              </div>
              <div className="button-sec-account">
                <button onClick={!isLoading ? openDeleteModal : undefined}>
                  Delete
                </button>
              </div>
              <Modal
                className="client-modal userAccount-modal"
                isOpen={isDeleteModalOpen}
                onRequestClose={closeDeleteModal}
                contentLabel="Delete User Account"
                style={{
                  content: {
                    height: "41%",
                    width: "30%",
                    position: "fixed",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    parent: document.querySelector(".admin-outer.time.tracker"),
                  },
                }}
              >
                <div className="modal">
                  <div className="modal-close-invite outter-modal-main">
                    <a className="" onClick={closeDeleteModal}>
                      <img src={crssrImage} alt="Edit Icon" />{" "}
                    </a>
                  </div>
                  <div className="modal-client-head">
                    <h2 className="">Delete Account</h2>
                  </div>
                  <div className="form-field-client-outer">
                    <form onSubmit={handleDeleteAccount}>
                      <div className="form-field-client">
                        <label htmlFor="password" className="form-label">
                          Password
                        </label>
                        <input
                          type="password"
                          name="password"
                          id="password"
                          placeholder="Password"
                          className={
                            passwordError
                              ? "input-validation-error form-input"
                              : "form-input"
                          }
                          value={userPassword}
                          onChange={(e) => setUserPassword(e.target.value)}
                          ref={passwordRef}
                        />
                        {passwordError && (
                          <span className="text-red-500 text-xs italic">
                            {passwordError}
                          </span>
                        )}
                      </div>
                      <button
                        className="reset-pass"
                        type="submit"
                        disabled={loading}
                      >
                        Delete Account
                      </button>
                    </form>
                  </div>
                </div>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserSecurity;