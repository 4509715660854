import React, { useState } from "react";

function CompanyDocumentField({ companyDocument }) {
  const [showFullText, setShowFullText] = useState(false);
  const handleMouseOver = () => {
    setShowFullText(true);
  };

  const handleMouseOut = () => {
    setShowFullText(false);
  };

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substr(0, maxLength) + "...";
    }
    return text;
  };

  return (
    <div className="value-field" onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
      {showFullText ? companyDocument : truncateText(companyDocument, 16)}
    </div>
  );
}

export default CompanyDocumentField;