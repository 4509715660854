import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ThreeDots } from "react-loader-spinner";
import moment from "moment";
import profileImage from "../../../../images/user/Default_pfp.png";
import { rebInvCreateDateConvert, rebInvDueDateConvert } from "./Services/reportReimbursement";
import PaymentStatus from "../../../Invoices/elements/paymentStatusWise";

// Dummy data for testing
const dummyData = [
  {
    user: {
      name: "Rey Bugawan",
      profilePhoto: profileImage,
    },
    category: "Travel",
    creationDate: "2023-07-01",
    dueDate: "2023-07-10",
    paymentStatus: "Paid",
    amount: 1500,
  },
  {
    user: {
      name: "Calin Tatar",
      profilePhoto: profileImage,
    },
    category: "Medical",
    creationDate: "2023-07-01",
    dueDate: "2023-07-10",
    paymentStatus: "Pending",
    amount: 200,
  },
  {
    user: {
      name: "Jane Smith",
      profilePhoto: profileImage,
    },
    category: "Office Supplies",
    creationDate: "2023-07-02",
    dueDate: "2023-07-15",
    paymentStatus: "Overdue",
    amount: 75,
  },
  // Add more dummy data as needed
];

const ReimburesmentTable = ({ reimbursementInv, reimbursementInvLoading, reimbursementInvError }) => {
  const reportingReimbursment = useSelector((state) => state.reportingReimbursment);
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    if (reimbursementInv && reportingReimbursment) {
      const timeZone = global.config.getTimeZone();
      const firstDayOfYear = moment.tz(timeZone).startOf("year").toDate();
      const dateRange = reportingReimbursment.reimbursmentDateRange || [firstDayOfYear, new Date()];
      const startDate = moment.tz(dateRange[0], timeZone);
      const endDate = moment.tz(dateRange[1], timeZone);
      const filtered = reimbursementInv.filter((invoice) => {
        const invoiceIssueDate = moment.tz(invoice?.inv_issue_date, timeZone);
        return (
          invoice &&
          !invoice.is_delete &&
          (reportingReimbursment.reimbursmentUser && reportingReimbursment.reimbursmentUser.includes(invoice.inv_for._id)) &&
          (reportingReimbursment.reimbursmentInvStatus && reportingReimbursment.reimbursmentInvStatus.includes(invoice?.inv_status || 0)) &&
          (reportingReimbursment.reimbursmentInvPaymentStatus && reportingReimbursment.reimbursmentInvPaymentStatus.includes(invoice?.inv_payment_status || 0)) &&
          invoiceIssueDate.isBetween(startDate, endDate, null, '[]')
        );
      });

      const sortedData = filtered.sort((a, b) => new Date(b.inv_created_at) - new Date(a.inv_created_at));
      setFilteredData(sortedData);
    }
  }, [reimbursementInv, reportingReimbursment]);

  const useRenderMemberName = (rowData) => {
    const [showFullname, setShowFullname] = useState(false);

    const fullName =
      (rowData.inv_for.first_name || rowData.inv_for.company_name) +
      (rowData.inv_for.middle_name && rowData.inv_for.middle_name !== "null"
        ? " " + rowData.inv_for.middle_name
        : "") +
      " " +
      rowData.inv_for.last_name;
    const displayName = showFullname
      ? fullName
      : fullName.length <= 15
        ? fullName
        : fullName.slice(0, 15) + "...";

    return (
      <div
        className={`member-name${showFullname ? " active" : ""}`}
        // onMouseEnter={() => setShowFullname(true)}
        onMouseEnter={() => {
          if (fullName.length > 15) {
            setShowFullname(true);
          }
        }}
        onMouseLeave={() => setShowFullname(false)}
      >
        {rowData.inv_for.profile_image !== null &&
          rowData.inv_for.profile_image !== "null" ? (
          <img
            src={`/uploads/lowProfileImage/${rowData.inv_for.profile_image}`}
            alt={fullName}
            className="member-image"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = "/uploads/lowProfileImage/user_default.png";
            }}
          />
        ) : (
          <img src={profileImage} alt={fullName} className="member-image" />
        )}
        <span>{displayName}</span>
      </div>
    );
  };

  const renderAmount = (rowData) => {
    var totalAmount = rowData.inv_total_amount.toLocaleString("en-US", {
      style: "currency",
      currency: rowData.inv_currency,
    });

    return <span> {totalAmount}</span>;
  };

  return (
    <div className="datatable-team-maintb">
      <DataTable
        value={filteredData.slice(0, 5)}
        emptyMessage={
          reimbursementInvLoading ? (
            <div className="outter-load-table">
              <ThreeDots
                height="80"
                width="80"
                radius="9"
                color="#405BFF"
                ariaLabel="three-dots-loading"
                visible={true}
              />
            </div>
          ) : (
            "No reimbursement invoice data found"
          )
        }
        tableStyle={{ minWidth: "50rem", width: "100%", textAlign: "left" }}
      >
        <Column
          field="inv_for.first_name"
          body={useRenderMemberName}
          header="User"
          sortable
          style={{ width: "20%", paddingRight: "20px" }}
          sortField="fullName"
          sortFunction={(e) => {
            const dataCopy = [...filteredData];
            dataCopy.sort((rowData1, rowData2) => {
              const fullName1 =
                (rowData1.inv_for.first_name ||
                  rowData1.inv_for.company_name) +
                (rowData1.inv_for.middle_name &&
                  rowData1.inv_for.middle_name !== "null"
                  ? " " + rowData1.inv_for.middle_name
                  : "") +
                " " +
                rowData1.inv_for.last_name;

              const fullName2 =
                (rowData2.inv_for.first_name ||
                  rowData2.inv_for.company_name) +
                (rowData2.inv_for.middle_name &&
                  rowData2.inv_for.middle_name !== "null"
                  ? " " + rowData2.inv_for.middle_name
                  : "") +
                " " +
                rowData2.inv_for.last_name;

              // Implement your custom comparison logic here
              return e.order === 1
                ? fullName1.localeCompare(fullName2)
                : fullName2.localeCompare(fullName1);
            });
            return dataCopy;
          }}
        ></Column>
        <Column
          field="inv_category"
          header="Category"
          sortable
          style={{ width: "15%", paddingRight: "20px" }}
        />
        <Column
          field="inv_created_at"
          body={rebInvCreateDateConvert}
          header="Creation Date"
          sortable
          style={{ paddingRight: "44px" }}
        ></Column>
        <Column
          field="inv_due_date"
          body={rebInvDueDateConvert}
          header="Due Date"
          className="fixed-sec3"
          sortable
          style={{ paddingRight: "44px" }}
        ></Column>
        <Column
          field="inv_payment_status"
          header="Payment Status"
          body={(rowData) => (
            <PaymentStatus
              inv_payment_details={rowData.inv_payment_details}
              invData={rowData}
            />
          )}
          sortable
          style={{ paddingRight: "44px" }}
        ></Column>
        <Column
          field="inv_total_amount"
          header="Amount"
          body={renderAmount}
          className="amount-bold"
          sortable
          style={{ paddingRight: "44px" }}
        ></Column>
      </DataTable>
    </div>
  );
};

export default ReimburesmentTable;
