import React from "react";

function TruncateFilterFieldView({ textContent }) {
  const truncateText = (text, maxLength) => {
    if (text && text.length > maxLength) {
      return text.substr(0, maxLength) + "...";
    }
    return text;
  };

  return (
    <div className="tran-outer">
      <div className="value-field">
        {truncateText(textContent, 10)}
      </div>
    </div>
  );
}

export default TruncateFilterFieldView;