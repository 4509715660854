import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ThreeDots } from 'react-loader-spinner';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LabelList } from 'recharts';
import { processedCostByService } from './Services/reportServices';
import typeOfContract from '../../../constants/typeOfContract';

const data = [
  { name: 'Rent', cost: 144, invoice: 124 },
  { name: 'Utilities', cost: 155, invoice: 139 },
  { name: 'Groceries', cost: 113, invoice: 130 },
  { name: 'Transport', cost: 143, invoice: 150 },
  { name: 'Entertainment', cost: 146, invoice: 127 },
  { name: 'Healthcare', cost: 155, invoice: 140 },
  { name: 'Miscellaneous', cost: 132, invoice: 118 },
];



const ServiceCostGraph = ({ trackReport, trackReportLoading, trackReportError, projectCost, filteredCurrencies }) => {
  const reportingServices = useSelector((state) => state.reportingServices);
  const { forex } = useSelector((state) => state.forex);
  const settings = JSON.parse(localStorage.getItem("settings"));


  var currencies = "USD";
  if (filteredCurrencies && filteredCurrencies.length === 1) {
    currencies = filteredCurrencies[0];
  } else {
    currencies = settings.currency;
  }

  const processedData = useMemo(() => {
    return processedCostByService(trackReport, projectCost, reportingServices, currencies, forex, typeOfContract);
  }, [trackReport, projectCost, reportingServices, settings, forex]);

  const calculateXTicks = () => {
    if (!processedData.length) return [];
    const maxDataValue = Math.max(...processedData.map(item => item.totalCost));
    if (maxDataValue === 0) return [0];
    const tickInterval = Math.ceil(maxDataValue / 10);
    const numOfTicks = Math.ceil(maxDataValue / tickInterval);
    const ticks = [];

    for (let i = 0; i <= numOfTicks + 4; i++) { // Adjust the number of ticks
      ticks.push(i * tickInterval);
    }

    return ticks;
  }

  const xAxisTicks = calculateXTicks();

  if (trackReportLoading) return (
    <div className="outter-load-table">
      <ThreeDots
        height="80"
        width="80"
        radius="9"
        color="#6479f9"
        ariaLabel="three-dots-loading"
        visible={true}
      />
    </div>
  );

  if (trackReportError) return <div>Error loading data.</div>;

  const calculateChartHeight = (dataLength) => {
    const rowHeight = 50;
    const baseHeight = 100;
    return dataLength * rowHeight + baseHeight;
  };

  const calculateLeftMargin = (data) => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    context.font = '12px Arial';

    const longestWordLength = data.reduce((maxLength, item) => {
      const firstWord = item.name.split(' ')[0];
      const wordWidth = context.measureText(firstWord).width;
      return Math.max(maxLength, wordWidth);
    }, 0);

    return Math.max(20, longestWordLength - 150);
  };

  const leftMargin = calculateLeftMargin(processedData);

  return (
    <div className='graph-project-per-hour' style={{ width: '100%', height: Math.max(542, calculateChartHeight(processedData.length)), }}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          layout="vertical"
          data={processedData}
          barSize={13}
          margin={{
            top: 20,
            right: 42,
            left: leftMargin,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" horizontal={false} vertical={true} />
          <XAxis
            type="number"
            domain={[0, 'dataMax + 10']}
            tickFormatter={(tick) => `${new Intl.NumberFormat('en-US', { style: 'currency', currency: currencies, }).format(0).replace(/[\d.,]/g, '')}${tick}`}
            ticks={xAxisTicks}
          />
          <YAxis
            type="category"
            dataKey="name"
            // width={150}
            tickMargin={10}
          />
          <Tooltip formatter={(value) => `${new Intl.NumberFormat("en-US", { style: "currency", currency: currencies, }).format(value)}`} />
          <Bar dataKey="totalCost" name="Total Cost" fill="#86ABE1">
            <LabelList dataKey="totalCost" position="right" formatter={(value) => `${new Intl.NumberFormat("en-US", { style: "currency", currency: currencies, }).format(value)}`} />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}

export default ServiceCostGraph;
