import axios from "axios";
import Modal from "react-modal";
import "./UserAccountDetails.css";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import { useSelector, useDispatch } from 'react-redux';
import Type from "../../../../../images/icons/type.svg";
import React, { useState, useRef, useEffect } from "react";
import Delete from "../../../../../images/icons/delete.svg";
import EmailIcon from "../../../../../images/icons/email.svg";
import Payment from "../../../../../images/icons/payment.svg";
import location from "../../../../../images/icons/location.svg";
import AccountDetailsEdit from "./UserAccountEdit/UserAccountEdit";
import PassportlIcon from "../../../../../images/icons/passport.svg";
import birthIcon from "../../../../../images/icons/dat-of-birth.svg";
import PostalCode from "../../../../../images/icons/postal-code.svg";
import { logout, reset } from "../../../../../features/auth/authSlice";
import UserIcon from "../../../../../images/icons/single-user-icon.svg";
import TypeContact from "../../../../../images/icons/type-of-contact.svg";
import { reset as invoiceReset } from "../../../../../features/invoice/invoiceSlice.js";
import { reset as headerReset } from "../../../../../features/headerText/headerTextSlice.js";
import { reset as appSettingsReset } from "../../../../../features/appSettings/appSettingsSlice.js";
import { setBankFetchUpdate } from '../../../../../features/bankFetchUpdate/bankFetchUpdateSlice';

const AccountDetails = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [accountEdit, setAccountEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [bankDetailsView, setbankDetailsView] = useState([]);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const fileInputRef = useRef(null);
  const token = useSelector(
    (state) => state.auth.token
  )

  useEffect(() => {
    global.config.activityLog(
      window.location.href,
      "User",
      `Entered into User (${props.id}) view page`
    );
    async function fetchBank() {
      setIsLoading(true);
      try {
        const response = await axios.get(`/api/wise/user/banks/details/${props.accountId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setbankDetailsView(response.data);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        global.config.slackMessage(error.toString());
        if (error.response?.data?.status === "deleted") {
          localStorage.removeItem("user");
          dispatch(logout());
          dispatch(reset());
          dispatch(invoiceReset());
          dispatch(headerReset());
          dispatch(appSettingsReset());
          toast.error('This user is deleted!', {
            position: "bottom-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          navigate("/");
          return;
        }
        alert(`Error fetching bank`);
      }
    }

    fetchBank().then(() => {
      //   alert("Data fetched successfully!");
    });
  }, [props.accountId, accountEdit, navigate, token]);

  const handleDeleteClick = () => {
    setShowConfirmation(true);
  };

  const handleConfirmationOk = async (profileId, email, accountNumber) => {
    setIsDeleteLoading(true);
    try {
      await axios.post(`/api/wise/profile/delete`,
        {
          profileId: profileId,
          email: email,
          accountNumber: accountNumber,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }).then((response) => {
          toast.success("Bank account deleted successsfully!", {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          global.config.activityLog(
            window.location.href,
            "User Bank",
            `Bank account for ${email} has been deleted successfully.`
          );
          dispatch(setBankFetchUpdate());
          handleCancel();
        }).catch((error) => {
          console.log(error)
        });
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      global.config.slackMessage(error.toString());
      if (error.response?.data?.status === "deleted") {
        localStorage.removeItem("user");
        dispatch(logout());
        dispatch(reset());
        dispatch(invoiceReset());
        dispatch(headerReset());
        dispatch(appSettingsReset());
        toast.error('This user is deleted!', {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/");
        return;
      }
      global.config.activityLog(
        window.location.href,
        "User Bank",
        `Something went wrong while deleting Bank account for ${email}.`
      );
      alert(`Error deletion of bank account`);
    }
    setIsDeleteLoading(false);
    setShowConfirmation(false);

  };
  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
  };
  const handleCancel = () => {
    props.onCancel();
  };

  return (
    <>
      {accountEdit ? (
        <>
          <AccountDetailsEdit />
        </>
      ) : (
        <>
          <div className="account-details">
            <div className="profile-photo-outer">
              <div className="profile-photo">
                {isLoading ? (
                  <div className="outter-load-table">
                    <ThreeDots
                      height="50"
                      width="50"
                      radius="9"
                      color="#405BFF"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  </div>
                ) : (
                  <h2>Account #{props.index}</h2>)}
              </div>
              <div className="profile-button">
                {isLoading ? (
                  <div className="outter-load-table">
                    <ThreeDots
                      height="30"
                      width="30"
                      radius="9"
                      color="#405BFF"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  </div>
                ) : (
                  <a
                    href="#"
                    className="delete-profile"
                    onClick={handleDeleteClick}
                  >
                    <span class="bt-ico">
                      <img src={Delete} alt="delete" />
                    </span>
                    Delete
                  </a>
                )}
              </div>
            </div>
            <input
              type="file"
              accept="image/*"
              style={{ display: "none" }}
              ref={fileInputRef}
              onChange={handleFileInputChange}
            />

            {showConfirmation && (

              <Modal
                className="delete-modal"
                isOpen={showConfirmation}
                onRequestClose={() => setShowConfirmation(false)}
                contentLabel="Confirmation Delete"
                style={{
                  content: {
                    height: "41%",
                    width: "30%",
                    position: "fixed",
                    top: "36%",
                    left: "50%",
                    transform: "translate(-19.75%, 0%)",
                    parent: document.querySelector(".admin-outer.time.tracker"),
                  },
                }}
              >
                <div className="delete-modal-content">
                  <h2>Confirm Delete</h2>
                  <p>
                    Are you sure you want to delete this Account?
                  </p>
                  <div className="delete-modal-buttons">
                    <button className="buttons-rejected" onClick={() => handleConfirmationOk(bankDetailsView.id, bankDetailsView.details.email, (bankDetailsView.details.accountNumber || bankDetailsView.details.iban || bankDetailsView.details.clabe))} disabled={isDeleteLoading}>

                      {isDeleteLoading ?
                        <div className="outter-load-table">
                          <ThreeDots
                            height="18"
                            width="20"
                            radius="9"
                            color="white"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                          />
                        </div> : "Delete"}
                    </button>
                    <button onClick={() => setShowConfirmation(false)}>
                      Cancel
                    </button>
                  </div>
                </div>
              </Modal>
            )}

            {showSuccessMessage && (
              <div className="success-message">
                Profile deleted successfully!
              </div>
            )}
            {isLoading ? (
              <div className="outter-load-table">
                <ThreeDots
                  height="80"
                  width="80"
                  radius="9"
                  color="#405BFF"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
              </div>
            ) : (
              <div className="information-outer-section">
                <div className="information-inner">
                  <div className="information">
                    <div className="information-title">General Information</div>
                    <div className="information-outer">
                      <div className="information-left">
                        {bankDetailsView.accountHolderName && (
                          <div className="inner-detail-outer">
                            <div className="inner-detail">
                              <span class="icon">
                                <img alt="" src={UserIcon} />
                              </span>
                              <div className="inner-detail-inner">
                                <label>Account Holder Name</label>
                              </div>
                            </div>
                            <div className="inner-detail">
                              <span class="icon"></span>
                              <div className="inner-detail-inner">
                                {isLoading ? (
                                  <ThreeDots
                                    height="18"
                                    width="20"
                                    radius="9"
                                    color="#6479f9"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                  />
                                ) : (
                                  <div className="value-field">{bankDetailsView.accountHolderName}</div>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                        {bankDetailsView.business && (
                          <div className="inner-detail-outer">
                            <div className="inner-detail">
                              <span class="icon">
                                <img alt="" src={UserIcon} />
                              </span>
                              <div className="inner-detail-inner">
                                <label>Wise Profile</label>
                              </div>
                            </div>
                            <div className="inner-detail">
                              <span class="icon"></span>
                              <div className="inner-detail-inner">
                                {isLoading ? (
                                  <ThreeDots
                                    height="18"
                                    width="20"
                                    radius="9"
                                    color="#6479f9"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                  />
                                ) : (
                                  <div className="value-field">{bankDetailsView.business}</div>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                        {bankDetailsView.type && (
                          <div className="inner-detail-outer">
                            <div className="inner-detail">
                              <span class="icon">
                                <img alt="" src={Type} />
                              </span>
                              <div className="inner-detail-inner">
                                <label>Type</label>
                              </div>
                            </div>
                            <div className="inner-detail">
                              <span class="icon"></span>
                              <div className="inner-detail-inner">
                                {isLoading ? (
                                  <ThreeDots
                                    height="18"
                                    width="20"
                                    radius="9"
                                    color="#6479f9"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                  />
                                ) : (
                                  <div className="value-field">{bankDetailsView.type}</div>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                        {bankDetailsView.details.accountType && (
                          <div className="inner-detail-outer">
                            <div className="inner-detail">
                              <span class="icon">
                                <img src={Type} />
                              </span>
                              <div className="inner-detail-inner">
                                <label>Account Type</label>
                              </div>
                            </div>
                            <div className="inner-detail">
                              <span class="icon"></span>
                              <div className="inner-detail-inner">
                                {isLoading ? (
                                  <ThreeDots
                                    height="18"
                                    width="20"
                                    radius="9"
                                    color="#6479f9"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                  />
                                ) : (
                                  <div className="value-field">{bankDetailsView.details.accountType}</div>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                        {bankDetailsView.details.transitNumber && (
                          <div className="inner-detail-outer">
                            <div className="inner-detail">
                              <span class="icon">
                                <img alt="" src={TypeContact} />
                              </span>
                              <div className="inner-detail-inner">
                                <label>Transit Number</label>
                              </div>
                            </div>
                            <div className="inner-detail">
                              <span class="icon"></span>
                              <div className="inner-detail-inner">
                                {isLoading ? (
                                  <ThreeDots
                                    height="18"
                                    width="20"
                                    radius="9"
                                    color="#6479f9"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                  />
                                ) : (
                                  <div className="value-field">{bankDetailsView.details.transitNumber}</div>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                        {bankDetailsView.details.sortCode && (
                          <div className="inner-detail-outer">
                            <div className="inner-detail">
                              <span class="icon">
                                <img alt="" src={Type} />
                              </span>
                              <div className="inner-detail-inner">
                                <label>Sort Code</label>
                              </div>
                            </div>
                            <div className="inner-detail">
                              <span class="icon"></span>
                              <div className="inner-detail-inner">
                                {isLoading ? (
                                  <ThreeDots
                                    height="18"
                                    width="20"
                                    radius="9"
                                    color="#6479f9"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                  />
                                ) : (
                                  <div className="value-field">{bankDetailsView.details.sortCode}</div>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                        {bankDetailsView.details.abartn && (
                          <div className="inner-detail-outer">
                            <div className="inner-detail">
                              <span class="icon">
                                <img alt="" src={TypeContact} />
                              </span>
                              <div className="inner-detail-inner">
                                <label>ABARTN</label>
                              </div>
                            </div>
                            <div className="inner-detail">
                              <span class="icon"></span>
                              <div className="inner-detail-inner">
                                {isLoading ? (
                                  <ThreeDots
                                    height="18"
                                    width="20"
                                    radius="9"
                                    color="#6479f9"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                  />
                                ) : (
                                  <div className="value-field">{bankDetailsView.details.abartn}</div>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                        {bankDetailsView.details.bankgiroNumber && (
                          <div className="inner-detail-outer">
                            <div className="inner-detail">
                              <span class="icon">
                                <img alt="" src={TypeContact} />
                              </span>
                              <div className="inner-detail-inner">
                                <label>Bankgiro Number</label>
                              </div>
                            </div>
                            <div className="inner-detail">
                              <span class="icon"></span>
                              <div className="inner-detail-inner">
                                {isLoading ? (
                                  <ThreeDots
                                    height="18"
                                    width="20"
                                    radius="9"
                                    color="#6479f9"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                  />
                                ) : (
                                  <div className="value-field">{bankDetailsView.details.bankgiroNumber}</div>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                        {bankDetailsView.details.ifscCode && (
                          <div className="inner-detail-outer">
                            <div className="inner-detail">
                              <span class="icon">
                                <img alt="" src={Type} />
                              </span>
                              <div className="inner-detail-inner">
                                <label>Ifsc Code</label>
                              </div>
                            </div>
                            <div className="inner-detail">
                              <span class="icon"></span>
                              <div className="inner-detail-inner">
                                {isLoading ? (
                                  <ThreeDots
                                    height="18"
                                    width="20"
                                    radius="9"
                                    color="#6479f9"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                  />
                                ) : (
                                  <div className="value-field">{bankDetailsView.details.ifscCode}</div>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                        {bankDetailsView.details.bsbCode && (
                          <div className="inner-detail-outer">
                            <div className="inner-detail">
                              <span class="icon">
                                <img alt="" src={Type} />
                              </span>
                              <div className="inner-detail-inner">
                                <label>BSB Code</label>
                              </div>
                            </div>
                            <div className="inner-detail">
                              <span class="icon"></span>
                              <div className="inner-detail-inner">
                                {isLoading ? (
                                  <ThreeDots
                                    height="18"
                                    width="20"
                                    radius="9"
                                    color="#6479f9"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                  />
                                ) : (
                                  <div className="value-field">{bankDetailsView.details.bsbCode}</div>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                        {bankDetailsView.details.phoneNumber && (
                          <div className="inner-detail-outer">
                            <div className="inner-detail">
                              <span class="icon">
                                <img alt="" src={TypeContact} />
                              </span>
                              <div className="inner-detail-inner">
                                <label>Phone Number</label>
                              </div>
                            </div>
                            <div className="inner-detail">
                              <span class="icon"></span>
                              <div className="inner-detail-inner">
                                {isLoading ? (
                                  <ThreeDots
                                    height="18"
                                    width="20"
                                    radius="9"
                                    color="#6479f9"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                  />
                                ) : (
                                  <div className="value-field">{bankDetailsView.details.phoneNumber}</div>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                        {bankDetailsView.details.bankCode && (
                          <div className="inner-detail-outer">
                            <div className="inner-detail">
                              <span class="icon">
                                <img alt="" src={Type} />
                              </span>
                              <div className="inner-detail-inner">
                                <label>Bank Code</label>
                              </div>
                            </div>
                            <div className="inner-detail">
                              <span class="icon"></span>
                              <div className="inner-detail-inner">
                                {isLoading ? (
                                  <ThreeDots
                                    height="18"
                                    width="20"
                                    radius="9"
                                    color="#6479f9"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                  />
                                ) : (
                                  <div className="value-field">{bankDetailsView.details.bankCode}</div>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                        {bankDetailsView.details.russiaRegion && (
                          <div className="inner-detail-outer">
                            <div className="inner-detail">
                              <span class="icon">
                                <img alt="" src={TypeContact} />
                              </span>
                              <div className="inner-detail-inner">
                                <label>Russia Region</label>
                              </div>
                            </div>
                            <div className="inner-detail">
                              <span class="icon"></span>
                              <div className="inner-detail-inner">
                                {isLoading ? (
                                  <ThreeDots
                                    height="18"
                                    width="20"
                                    radius="9"
                                    color="#6479f9"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                  />
                                ) : (
                                  <div className="value-field">{bankDetailsView.details.russiaRegion}</div>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                        {bankDetailsView.details.routingNumber && (
                          <div className="inner-detail-outer">
                            <div className="inner-detail">
                              <span class="icon">
                                <img alt="" src={TypeContact} />
                              </span>
                              <div className="inner-detail-inner">
                                <label>Routing Number</label>
                              </div>
                            </div>
                            <div className="inner-detail">
                              <span class="icon"></span>
                              <div className="inner-detail-inner">
                                {isLoading ? (
                                  <ThreeDots
                                    height="18"
                                    width="20"
                                    radius="9"
                                    color="#6479f9"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                  />
                                ) : (
                                  <div className="value-field">{bankDetailsView.details.routingNumber}</div>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                        {bankDetailsView.details.branchCode && (
                          <div className="inner-detail-outer">
                            <div className="inner-detail">
                              <span class="icon">
                                <img alt="" src={Type} />
                              </span>
                              <div className="inner-detail-inner">
                                <label>Branch Code</label>
                              </div>
                            </div>
                            <div className="inner-detail">
                              <span class="icon"></span>
                              <div className="inner-detail-inner">
                                {isLoading ? (
                                  <ThreeDots
                                    height="18"
                                    width="20"
                                    radius="9"
                                    color="#6479f9"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                  />
                                ) : (
                                  <div className="value-field">{bankDetailsView.details.branchCode}</div>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                        {bankDetailsView.details.cpf && (
                          <div className="inner-detail-outer">
                            <div className="inner-detail">
                              <span class="icon">
                                <img alt="" src={TypeContact} />
                              </span>
                              <div className="inner-detail-inner">
                                <label>CPF</label>
                              </div>
                            </div>
                            <div className="inner-detail">
                              <span class="icon"></span>
                              <div className="inner-detail-inner">
                                {isLoading ? (
                                  <ThreeDots
                                    height="18"
                                    width="20"
                                    radius="9"
                                    color="#6479f9"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                  />
                                ) : (
                                  <div className="value-field">{bankDetailsView.details.cpf}</div>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                        {bankDetailsView.details.cardToken && (
                          <div className="inner-detail-outer">
                            <div className="inner-detail">
                              <span class="icon">
                                <img alt="" src={TypeContact} />
                              </span>
                              <div className="inner-detail-inner">
                                <label>Card Token</label>
                              </div>
                            </div>
                            <div className="inner-detail">
                              <span class="icon"></span>
                              <div className="inner-detail-inner">
                                {isLoading ? (
                                  <ThreeDots
                                    height="18"
                                    width="20"
                                    radius="9"
                                    color="#6479f9"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                  />
                                ) : (
                                  <div className="value-field">{bankDetailsView.details.cardToken}</div>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                        {bankDetailsView.details.idType && (
                          <div className="inner-detail-outer">
                            <div className="inner-detail">
                              <span class="icon">
                                <img alt="" src={TypeContact} />
                              </span>
                              <div className="inner-detail-inner">
                                <label>Id Type</label>
                              </div>
                            </div>
                            <div className="inner-detail">
                              <span class="icon"></span>
                              <div className="inner-detail-inner">
                                {isLoading ? (
                                  <ThreeDots
                                    height="18"
                                    width="20"
                                    radius="9"
                                    color="#6479f9"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                  />
                                ) : (
                                  <div className="value-field">{bankDetailsView.details.idType}</div>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                        {bankDetailsView.details.idNumber && (
                          <div className="inner-detail-outer">
                            <div className="inner-detail">
                              <span class="icon">
                                <img alt="" src={TypeContact} />
                              </span>
                              <div className="inner-detail-inner">
                                <label>Id Number</label>
                              </div>
                            </div>
                            <div className="inner-detail">
                              <span class="icon"></span>
                              <div className="inner-detail-inner">
                                {isLoading ? (
                                  <ThreeDots
                                    height="18"
                                    width="20"
                                    radius="9"
                                    color="#6479f9"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                  />
                                ) : (
                                  <div className="value-field">{bankDetailsView.details.idNumber}</div>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                        {bankDetailsView.details.idCountryIso3 && (
                          <div className="inner-detail-outer">
                            <div className="inner-detail">
                              <span class="icon">
                                <img alt="" src={TypeContact} />
                              </span>
                              <div className="inner-detail-inner">
                                <label>Id Country Iso3</label>
                              </div>
                            </div>
                            <div className="inner-detail">
                              <span class="icon"></span>
                              <div className="inner-detail-inner">
                                {isLoading ? (
                                  <ThreeDots
                                    height="18"
                                    width="20"
                                    radius="9"
                                    color="#6479f9"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                  />
                                ) : (
                                  <div className="value-field">{bankDetailsView.details.idCountryIso3}</div>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                        {bankDetailsView.details.idValidFrom && (
                          <div className="inner-detail-outer">
                            <div className="inner-detail">
                              <span class="icon">
                                <img alt="" src={TypeContact} />
                              </span>
                              <div className="inner-detail-inner">
                                <label>Id Valid From</label>
                              </div>
                            </div>
                            <div className="inner-detail">
                              <span class="icon"></span>
                              <div className="inner-detail-inner">
                                {isLoading ? (
                                  <ThreeDots
                                    height="18"
                                    width="20"
                                    radius="9"
                                    color="#6479f9"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                  />
                                ) : (
                                  <div className="value-field">{bankDetailsView.details.idValidFrom}</div>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                        {bankDetailsView.details.idValidTo && (
                          <div className="inner-detail-outer">
                            <div className="inner-detail">
                              <span class="icon">
                                <img alt="" src={TypeContact} />
                              </span>
                              <div className="inner-detail-inner">
                                <label>Id Valid To</label>
                              </div>
                            </div>
                            <div className="inner-detail">
                              <span class="icon"></span>
                              <div className="inner-detail-inner">
                                {isLoading ? (
                                  <ThreeDots
                                    height="18"
                                    width="20"
                                    radius="9"
                                    color="#6479f9"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                  />
                                ) : (
                                  <div className="value-field">{bankDetailsView.details.idValidTo}</div>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                        {bankDetailsView.details.cardToken && (
                          <div className="inner-detail-outer">
                            <div className="inner-detail">
                              <span class="icon">
                                <img alt="" src={TypeContact} />
                              </span>
                              <div className="inner-detail-inner">
                                <label>Card Token</label>
                              </div>
                            </div>
                            <div className="inner-detail">
                              <span class="icon"></span>
                              <div className="inner-detail-inner">
                                {isLoading ? (
                                  <ThreeDots
                                    height="18"
                                    width="20"
                                    radius="9"
                                    color="#6479f9"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                  />
                                ) : (
                                  <div className="value-field">{bankDetailsView.details.cardToken}</div>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                        {bankDetailsView.details.clabe && (
                          <div className="inner-detail-outer">
                            <div className="inner-detail">
                              <span class="icon">
                                <img alt="" src={TypeContact} />
                              </span>
                              <div className="inner-detail-inner">
                                <label>Clabe</label>
                              </div>
                            </div>
                            <div className="inner-detail">
                              <span class="icon"></span>
                              <div className="inner-detail-inner">
                                {isLoading ? (
                                  <ThreeDots
                                    height="18"
                                    width="20"
                                    radius="9"
                                    color="#6479f9"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                  />
                                ) : (
                                  <div className="value-field">{bankDetailsView.details.clabe}</div>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                        {bankDetailsView.details.swiftCode && (
                          <div className="inner-detail-outer">
                            <div className="inner-detail">
                              <span class="icon">
                                <img alt="" src={Type} />
                              </span>
                              <div className="inner-detail-inner">
                                <label>Swift Code</label>
                              </div>
                            </div>
                            <div className="inner-detail">
                              <span class="icon"></span>
                              <div className="inner-detail-inner">
                                {isLoading ? (
                                  <ThreeDots
                                    height="18"
                                    width="20"
                                    radius="9"
                                    color="#6479f9"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                  />
                                ) : (
                                  <div className="value-field">{bankDetailsView.details.swiftCode}</div>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                        {bankDetailsView.details.prefix && (
                          <div className="inner-detail-outer">
                            <div className="inner-detail">
                              <span class="icon">
                                <img alt="" src={TypeContact} />
                              </span>
                              <div className="inner-detail-inner">
                                <label>Prefix</label>
                              </div>
                            </div>
                            <div className="inner-detail">
                              <span class="icon"></span>
                              <div className="inner-detail-inner">
                                {isLoading ? (
                                  <ThreeDots
                                    height="18"
                                    width="20"
                                    radius="9"
                                    color="#6479f9"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                  />
                                ) : (
                                  <div className="value-field">{bankDetailsView.details.prefix}</div>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                        {bankDetailsView.details.IBAN && (
                          <div className="inner-detail-outer">
                            <div className="inner-detail">
                              <span class="icon">
                                <img alt="" src={TypeContact} />
                              </span>
                              <div className="inner-detail-inner">
                                <label>IBAN</label>
                              </div>
                            </div>
                            <div className="inner-detail">
                              <span class="icon"></span>
                              <div className="inner-detail-inner">
                                {isLoading ? (
                                  <ThreeDots
                                    height="18"
                                    width="20"
                                    radius="9"
                                    color="#6479f9"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                  />
                                ) : (
                                  <div className="value-field">{bankDetailsView.details.IBAN}</div>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                        {bankDetailsView.details.BIC && (
                          <div className="inner-detail-outer">
                            <div className="inner-detail">
                              <span class="icon">
                                <img alt="" src={TypeContact} />
                              </span>
                              <div className="inner-detail-inner">
                                <label>BIC</label>
                              </div>
                            </div>
                            <div className="inner-detail">
                              <span class="icon"></span>
                              <div className="inner-detail-inner">
                                {isLoading ? (
                                  <ThreeDots
                                    height="18"
                                    width="20"
                                    radius="9"
                                    color="#6479f9"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                  />
                                ) : (
                                  <div className="value-field">{bankDetailsView.details.BIC}</div>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="information-right">
                        {bankDetailsView.id && (
                          <div className="inner-detail-outer">
                            <div className="inner-detail">
                              <span class="icon">
                                <img alt="" src={PassportlIcon} />
                              </span>
                              <div className="inner-detail-inner">
                                <label>Wise ID</label>
                              </div>
                            </div>
                            <div className="inner-detail">
                              <span class="icon"></span>
                              <div className="inner-detail-inner">
                                {isLoading ? (
                                  <ThreeDots
                                    height="18"
                                    width="20"
                                    radius="9"
                                    color="#6479f9"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                  />
                                ) : (
                                  <div className="value-field">{bankDetailsView.id}</div>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                        {bankDetailsView.currency && (
                          <div className="inner-detail-outer">
                            <div className="inner-detail">
                              <span class="icon">
                                <img alt="" src={Payment} />
                              </span>
                              <div className="inner-detail-inner">
                                <label>Currency</label>
                              </div>
                            </div>
                            <div className="inner-detail">
                              <span class="icon"></span>
                              <div className="inner-detail-inner">
                                {isLoading ? (
                                  <ThreeDots
                                    height="18"
                                    width="20"
                                    radius="9"
                                    color="#6479f9"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                  />
                                ) : (
                                  <div className="value-field">{bankDetailsView.currency}</div>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                        {bankDetailsView.details.legalType && (
                          <div className="inner-detail-outer">
                            <div className="inner-detail">
                              <span class="icon">
                                <img alt="" src={Type} />
                              </span>
                              <div className="inner-detail-inner">
                                <label>Legal Type</label>
                              </div>
                            </div>
                            <div className="inner-detail">
                              <span class="icon"></span>
                              <div className="inner-detail-inner">
                                {isLoading ? (
                                  <ThreeDots
                                    height="18"
                                    width="20"
                                    radius="9"
                                    color="#6479f9"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                  />
                                ) : (
                                  <div className="value-field">{bankDetailsView.details.legalType}</div>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                        {bankDetailsView.details.accountNumber && (
                          <div className="inner-detail-outer">
                            <div className="inner-detail">
                              <span class="icon">
                                <img alt="" src={TypeContact} />
                              </span>
                              <div className="inner-detail-inner">
                                <label>Account Number</label>
                              </div>
                            </div>
                            <div className="inner-detail">
                              <span class="icon"></span>
                              <div className="inner-detail-inner">
                                {isLoading ? (
                                  <ThreeDots
                                    height="18"
                                    width="20"
                                    radius="9"
                                    color="#6479f9"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                  />
                                ) : (
                                  <div className="value-field">{bankDetailsView.details.accountNumber}</div>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                        {bankDetailsView.details.institutionNumber && (
                          <div className="inner-detail-outer">
                            <div className="inner-detail">
                              <span class="icon">
                                <img alt="" src={TypeContact} />
                              </span>
                              <div className="inner-detail-inner">
                                <label>Institution Number</label>
                              </div>
                            </div>
                            <div className="inner-detail">
                              <span class="icon"></span>
                              <div className="inner-detail-inner">
                                {isLoading ? (
                                  <ThreeDots
                                    height="18"
                                    width="20"
                                    radius="9"
                                    color="#6479f9"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                  />
                                ) : (
                                  <div className="value-field">{bankDetailsView.details.institutionNumber}</div>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                        {bankDetailsView.details.dateOfBirth && (
                          <div className="inner-detail-outer">
                            <div className="inner-detail">
                              <span class="icon">
                                <img alt="" src={birthIcon} />
                              </span>
                              <div className="inner-detail-inner">
                                <label>Date Of Birth</label>
                              </div>
                            </div>
                            <div className="inner-detail">
                              <span class="icon"></span>
                              <div className="inner-detail-inner">
                                {isLoading ? (
                                  <ThreeDots
                                    height="18"
                                    width="20"
                                    radius="9"
                                    color="#6479f9"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                  />
                                ) : (
                                  <div className="value-field">{bankDetailsView.details.dateOfBirth}</div>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                        {bankDetailsView.details.clearingNumber && (
                          <div className="inner-detail-outer">
                            <div className="inner-detail">
                              <span class="icon">
                                <img alt="" src={TypeContact} />
                              </span>
                              <div className="inner-detail-inner">
                                <label>Clearing Number</label>
                              </div>
                            </div>
                            <div className="inner-detail">
                              <span class="icon"></span>
                              <div className="inner-detail-inner">
                                {isLoading ? (
                                  <ThreeDots
                                    height="18"
                                    width="20"
                                    radius="9"
                                    color="#6479f9"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                  />
                                ) : (
                                  <div className="value-field">{bankDetailsView.details.clearingNumber}</div>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                        {bankDetailsView.details.bankName && (
                          <div className="inner-detail-outer">
                            <div className="inner-detail">
                              <span class="icon">
                                <img alt="" src={TypeContact} />
                              </span>
                              <div className="inner-detail-inner">
                                <label>Bank Name</label>
                              </div>
                            </div>
                            <div className="inner-detail">
                              <span class="icon"></span>
                              <div className="inner-detail-inner">
                                {isLoading ? (
                                  <ThreeDots
                                    height="18"
                                    width="20"
                                    radius="9"
                                    color="#6479f9"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                  />
                                ) : (
                                  <div className="value-field">{bankDetailsView.details.bankName}</div>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                        {bankDetailsView.details.branchName && (
                          <div className="inner-detail-outer">
                            <div className="inner-detail">
                              <span class="icon">
                                <img alt="" src={TypeContact} />
                              </span>
                              <div className="inner-detail-inner">
                                <label>Branch Name</label>
                              </div>
                            </div>
                            <div className="inner-detail">
                              <span class="icon"></span>
                              <div className="inner-detail-inner">
                                {isLoading ? (
                                  <ThreeDots
                                    height="18"
                                    width="20"
                                    radius="9"
                                    color="#6479f9"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                  />
                                ) : (
                                  <div className="value-field">{bankDetailsView.details.branchName}</div>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                        {bankDetailsView.details.businessNumber && (
                          <div className="inner-detail-outer">
                            <div className="inner-detail">
                              <span class="icon">
                                <img alt="" src={TypeContact} />
                              </span>
                              <div className="inner-detail-inner">
                                <label>Business Number</label>
                              </div>
                            </div>
                            <div className="inner-detail">
                              <span class="icon"></span>
                              <div className="inner-detail-inner">
                                {isLoading ? (
                                  <ThreeDots
                                    height="18"
                                    width="20"
                                    radius="9"
                                    color="#6479f9"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                  />
                                ) : (
                                  <div className="value-field">{bankDetailsView.details.businessNumber}</div>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                        {bankDetailsView.details.province && (
                          <div className="inner-detail-outer">
                            <div className="inner-detail">
                              <span class="icon">
                                <img alt="" src={TypeContact} />
                              </span>
                              <div className="inner-detail-inner">
                                <label>Province</label>
                              </div>
                            </div>
                            <div className="inner-detail">
                              <span class="icon"></span>
                              <div className="inner-detail-inner">
                                {isLoading ? (
                                  <ThreeDots
                                    height="18"
                                    width="20"
                                    radius="9"
                                    color="#6479f9"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                  />
                                ) : (
                                  <div className="value-field">{bankDetailsView.details.province}</div>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                        {bankDetailsView.details.rut && (
                          <div className="inner-detail-outer">
                            <div className="inner-detail">
                              <span class="icon">
                                <img alt="" src={TypeContact} />
                              </span>
                              <div className="inner-detail-inner">
                                <label>RUT</label>
                              </div>
                            </div>
                            <div className="inner-detail">
                              <span class="icon"></span>
                              <div className="inner-detail-inner">
                                {isLoading ? (
                                  <ThreeDots
                                    height="18"
                                    width="20"
                                    radius="9"
                                    color="#6479f9"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                  />
                                ) : (
                                  <div className="value-field">{bankDetailsView.details.rut}</div>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                        {bankDetailsView.details.token && (
                          <div className="inner-detail-outer">
                            <div className="inner-detail">
                              <span class="icon">
                                <img alt="" src={TypeContact} />
                              </span>
                              <div className="inner-detail-inner">
                                <label>Token</label>
                              </div>
                            </div>
                            <div className="inner-detail">
                              <span class="icon"></span>
                              <div className="inner-detail-inner">
                                {isLoading ? (
                                  <ThreeDots
                                    height="18"
                                    width="20"
                                    radius="9"
                                    color="#6479f9"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                  />
                                ) : (
                                  <div className="value-field">{bankDetailsView.details.token}</div>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                        {bankDetailsView.details.cnpj && (
                          <div className="inner-detail-outer">
                            <div className="inner-detail">
                              <span class="icon">
                                <img alt="" src={TypeContact} />
                              </span>
                              <div className="inner-detail-inner">
                                <label>CNPJ</label>
                              </div>
                            </div>
                            <div className="inner-detail">
                              <span class="icon"></span>
                              <div className="inner-detail-inner">
                                {isLoading ? (
                                  <ThreeDots
                                    height="18"
                                    width="20"
                                    radius="9"
                                    color="#6479f9"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                  />
                                ) : (
                                  <div className="value-field">{bankDetailsView.details.cnpj}</div>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                        {bankDetailsView.details.payinReference && (
                          <div className="inner-detail-outer">
                            <div className="inner-detail">
                              <span class="icon">
                                <img alt="" src={TypeContact} />
                              </span>
                              <div className="inner-detail-inner">
                                <label>Payin Reference</label>
                              </div>
                            </div>
                            <div className="inner-detail">
                              <span class="icon"></span>
                              <div className="inner-detail-inner">
                                {isLoading ? (
                                  <ThreeDots
                                    height="18"
                                    width="20"
                                    radius="9"
                                    color="#6479f9"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                  />
                                ) : (
                                  <div className="value-field">{bankDetailsView.details.payinReference}</div>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                        {bankDetailsView.details.pspReference && (
                          <div className="inner-detail-outer">
                            <div className="inner-detail">
                              <span class="icon">
                                <img src={TypeContact} alt="contact" />
                              </span>
                              <div className="inner-detail-inner">
                                <label>Psp Reference</label>
                              </div>
                            </div>
                            <div className="inner-detail">
                              <span class="icon"></span>
                              <div className="inner-detail-inner">
                                {isLoading ? (
                                  <ThreeDots
                                    height="18"
                                    width="20"
                                    radius="9"
                                    color="#6479f9"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                  />
                                ) : (
                                  <div className="value-field">{bankDetailsView.details.pspReference}</div>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                        {bankDetailsView.details.orderId && (
                          <div className="inner-detail-outer">
                            <div className="inner-detail">
                              <span class="icon">
                                <img src={TypeContact} alt="contact" />
                              </span>
                              <div className="inner-detail-inner">
                                <label>Order Id</label>
                              </div>
                            </div>
                            <div className="inner-detail">
                              <span class="icon"></span>
                              <div className="inner-detail-inner">
                                {isLoading ? (
                                  <ThreeDots
                                    height="18"
                                    width="20"
                                    radius="9"
                                    color="#6479f9"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                  />
                                ) : (
                                  <div className="value-field">{bankDetailsView.details.orderId}</div>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                        {bankDetailsView.details.idDocumentType && (
                          <div className="inner-detail-outer">
                            <div className="inner-detail">
                              <span class="icon">
                                <img alt="" src={TypeContact} />
                              </span>
                              <div className="inner-detail-inner">
                                <label>Id Document Type</label>
                              </div>
                            </div>
                            <div className="inner-detail">
                              <span class="icon"></span>
                              <div className="inner-detail-inner">
                                {isLoading ? (
                                  <ThreeDots
                                    height="18"
                                    width="20"
                                    radius="9"
                                    color="#6479f9"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                  />
                                ) : (
                                  <div className="value-field">{bankDetailsView.details.idDocumentType}</div>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                        {bankDetailsView.details.targetProfile && (
                          <div className="inner-detail-outer">
                            <div className="inner-detail">
                              <span class="icon">
                                <img alt="" src={TypeContact} />
                              </span>
                              <div className="inner-detail-inner">
                                <label>Target Profile</label>
                              </div>
                            </div>
                            <div className="inner-detail">
                              <span class="icon"></span>
                              <div className="inner-detail-inner">
                                {isLoading ? (
                                  <ThreeDots
                                    height="18"
                                    width="20"
                                    radius="9"
                                    color="#6479f9"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                  />
                                ) : (
                                  <div className="value-field">{bankDetailsView.details.targetProfile}</div>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                        {bankDetailsView.details.targetUserId && (
                          <div className="inner-detail-outer">
                            <div className="inner-detail">
                              <span class="icon">
                                <img alt="" src={TypeContact} />
                              </span>
                              <div className="inner-detail-inner">
                                <label>Target User Id</label>
                              </div>
                            </div>
                            <div className="inner-detail">
                              <span class="icon"></span>
                              <div className="inner-detail-inner">
                                {isLoading ? (
                                  <ThreeDots
                                    height="18"
                                    width="20"
                                    radius="9"
                                    color="#6479f9"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                  />
                                ) : (
                                  <div className="value-field">{bankDetailsView.details.targetUserId}</div>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                        {bankDetailsView.details.taxId && (
                          <div className="inner-detail-outer">
                            <div className="inner-detail">
                              <span class="icon">
                                <img alt="" src={TypeContact} />
                              </span>
                              <div className="inner-detail-inner">
                                <label>Tax Id</label>
                              </div>
                            </div>
                            <div className="inner-detail">
                              <span class="icon"></span>
                              <div className="inner-detail-inner">
                                {isLoading ? (
                                  <ThreeDots
                                    height="18"
                                    width="20"
                                    radius="9"
                                    color="#6479f9"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                  />
                                ) : (
                                  <div className="value-field">{bankDetailsView.details.taxId}</div>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                        {bankDetailsView.details.job && (
                          <div className="inner-detail-outer">
                            <div className="inner-detail">
                              <span class="icon">
                                <img alt="" src={TypeContact} />
                              </span>
                              <div className="inner-detail-inner">
                                <label>Job</label>
                              </div>
                            </div>
                            <div className="inner-detail">
                              <span class="icon"></span>
                              <div className="inner-detail-inner">
                                {isLoading ? (
                                  <ThreeDots
                                    height="18"
                                    width="20"
                                    radius="9"
                                    color="#6479f9"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                  />
                                ) : (
                                  <div className="value-field">{bankDetailsView.details.job}</div>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                        {bankDetailsView.details.nationality && (
                          <div className="inner-detail-outer">
                            <div className="inner-detail">
                              <span class="icon">
                                <img alt="" src={TypeContact} />
                              </span>
                              <div className="inner-detail-inner">
                                <label>Nationality</label>
                              </div>
                            </div>
                            <div className="inner-detail">
                              <span class="icon"></span>
                              <div className="inner-detail-inner">
                                {isLoading ? (
                                  <ThreeDots
                                    height="18"
                                    width="20"
                                    radius="9"
                                    color="#6479f9"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                  />
                                ) : (
                                  <div className="value-field">{bankDetailsView.details.nationality}</div>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                        {bankDetailsView.details.interacAccount && (
                          <div className="inner-detail-outer">
                            <div className="inner-detail">
                              <span class="icon">
                                <img alt="" src={TypeContact} />
                              </span>
                              <div className="inner-detail-inner">
                                <label>Interac Account</label>
                              </div>
                            </div>
                            <div className="inner-detail">
                              <span class="icon"></span>
                              <div className="inner-detail-inner">
                                {isLoading ? (
                                  <ThreeDots
                                    height="18"
                                    width="20"
                                    radius="9"
                                    color="#6479f9"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                  />
                                ) : (
                                  <div className="value-field">{bankDetailsView.details.interacAccount}</div>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                        {bankDetailsView.details.bban && (
                          <div className="inner-detail-outer">
                            <div className="inner-detail">
                              <span class="icon">
                                <img alt="" src={TypeContact} />
                              </span>
                              <div className="inner-detail-inner">
                                <label>B Ban</label>
                              </div>
                            </div>
                            <div className="inner-detail">
                              <span class="icon"></span>
                              <div className="inner-detail-inner">
                                {isLoading ? (
                                  <ThreeDots
                                    height="18"
                                    width="20"
                                    radius="9"
                                    color="#6479f9"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                  />
                                ) : (
                                  <div className="value-field">{bankDetailsView.details.bban}</div>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                        {bankDetailsView.details.town && (
                          <div className="inner-detail-outer">
                            <div className="inner-detail">
                              <span class="icon">
                                <img alt="" src={TypeContact} />
                              </span>
                              <div className="inner-detail-inner">
                                <label>Town</label>
                              </div>
                            </div>
                            <div className="inner-detail">
                              <span class="icon"></span>
                              <div className="inner-detail-inner">
                                {isLoading ? (
                                  <ThreeDots
                                    height="18"
                                    width="20"
                                    radius="9"
                                    color="#6479f9"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                  />
                                ) : (
                                  <div className="value-field">{bankDetailsView.details.town}</div>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                        {bankDetailsView.details.language && (
                          <div className="inner-detail-outer">
                            <div className="inner-detail">
                              <span class="icon">
                                <img alt="" src={TypeContact} />
                              </span>
                              <div className="inner-detail-inner">
                                <label>Language</label>
                              </div>
                            </div>
                            <div className="inner-detail">
                              <span class="icon"></span>
                              <div className="inner-detail-inner">
                                {isLoading ? (
                                  <ThreeDots
                                    height="18"
                                    width="20"
                                    radius="9"
                                    color="#6479f9"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                  />
                                ) : (
                                  <div className="value-field">{bankDetailsView.details.language}</div>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                        {bankDetailsView.details.billerCode && (
                          <div className="inner-detail-outer">
                            <div className="inner-detail">
                              <span class="icon">
                                <img alt="" src={Type} />
                              </span>
                              <div className="inner-detail-inner">
                                <label>Biller Code</label>
                              </div>
                            </div>
                            <div className="inner-detail">
                              <span class="icon"></span>
                              <div className="inner-detail-inner">
                                {isLoading ? (
                                  <ThreeDots
                                    height="18"
                                    width="20"
                                    radius="9"
                                    color="#6479f9"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                  />
                                ) : (
                                  <div className="value-field">{bankDetailsView.details.billerCode}</div>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                        {bankDetailsView.details.customerReferenceNumber && (
                          <div className="inner-detail-outer">
                            <div className="inner-detail">
                              <span class="icon">
                                <img alt="" src={TypeContact} />
                              </span>
                              <div className="inner-detail-inner">
                                <label>Customer Reference Number</label>
                              </div>
                            </div>
                            <div className="inner-detail">
                              <span class="icon"></span>
                              <div className="inner-detail-inner">
                                {isLoading ? (
                                  <ThreeDots
                                    height="18"
                                    width="20"
                                    radius="9"
                                    color="#6479f9"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                  />
                                ) : (
                                  <div className="value-field">{bankDetailsView.details.customerReferenceNumber}</div>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="information-inner">
                  <div className="information">
                    <div className="information-title">Address</div>
                    <div className="information-outer">
                      <div className="information-left">
                        {bankDetailsView.details.address.city && (
                          <div className="inner-detail-outer">
                            <div className="inner-detail">
                              <span class="icon">
                                <img alt="" src={location} />
                              </span>
                              <div className="inner-detail-inner">
                                <label>City</label>
                              </div>
                            </div>
                            <div className="inner-detail">
                              <span class="icon"></span>
                              <div className="inner-detail-inner">
                                {isLoading ? (
                                  <ThreeDots
                                    height="18"
                                    width="20"
                                    radius="9"
                                    color="#6479f9"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                  />
                                ) : (
                                  <div className="value-field">{bankDetailsView.details.address.city}</div>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                        {bankDetailsView.details.address.country && (
                          <div className="inner-detail-outer">
                            <div className="inner-detail">
                              <span class="icon">
                                <img alt="" src={location} />
                              </span>
                              <div className="inner-detail-inner">
                                <label>Country</label>
                              </div>
                            </div>
                            <div className="inner-detail">
                              <span class="icon"></span>
                              <div className="inner-detail-inner">
                                {isLoading ? (
                                  <ThreeDots
                                    height="18"
                                    width="20"
                                    radius="9"
                                    color="#6479f9"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                  />
                                ) : (
                                  <div className="value-field">{bankDetailsView.details.address.country}</div>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                        {bankDetailsView.details.address.state && (
                          <div className="inner-detail-outer">
                            <div className="inner-detail">
                              <span class="icon">
                                <img alt="" src={location} />
                              </span>
                              <div className="inner-detail-inner">
                                <label>State</label>
                              </div>
                            </div>
                            <div className="inner-detail">
                              <span class="icon"></span>
                              <div className="inner-detail-inner">
                                {isLoading ? (
                                  <ThreeDots
                                    height="18"
                                    width="20"
                                    radius="9"
                                    color="#6479f9"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                  />
                                ) : (
                                  <div className="value-field">{bankDetailsView.details.address.state}</div>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="information-right">
                        {bankDetailsView.details.address.firstLine && (
                          <div className="inner-detail-outer">
                            <div className="inner-detail">
                              <span class="icon">
                                <img alt="" src={location} />
                              </span>
                              <div className="inner-detail-inner">
                                <label>Address</label>
                              </div>
                            </div>
                            <div className="inner-detail">
                              <span class="icon"></span>
                              <div className="inner-detail-inner">
                                {isLoading ? (
                                  <ThreeDots
                                    height="18"
                                    width="20"
                                    radius="9"
                                    color="#6479f9"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                  />
                                ) : (
                                  <div className="value-field">{bankDetailsView.details.address.firstLine}</div>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                        {bankDetailsView.details.address.postCode && (
                          <div className="inner-detail-outer">
                            <div className="inner-detail">
                              <span class="icon">
                                <img alt="" src={PostalCode} />
                              </span>
                              <div className="inner-detail-inner">
                                <label>Postal Code</label>
                              </div>
                            </div>
                            <div className="inner-detail">
                              <span class="icon"></span>
                              <div className="inner-detail-inner">
                                {isLoading ? (
                                  <ThreeDots
                                    height="18"
                                    width="20"
                                    radius="9"
                                    color="#6479f9"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                  />
                                ) : (
                                  <div className="value-field">{bankDetailsView.details.address.postCode}</div>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                        {bankDetailsView.details.email && (
                          <div className="inner-detail-outer">
                            <div className="inner-detail">
                              <span class="icon">
                                <img alt="" src={EmailIcon} />
                              </span>
                              <div className="inner-detail-inner">
                                <label>Email</label>
                              </div>
                            </div>
                            <div className="inner-detail">
                              <span class="icon"></span>
                              <div className="inner-detail-inner">
                                {isLoading ? (
                                  <ThreeDots
                                    height="18"
                                    width="20"
                                    radius="9"
                                    color="#6479f9"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                  />
                                ) : (
                                  <div className="value-field">{bankDetailsView.details.email}</div>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <a href="#" className="cancel-btn" onClick={handleCancel} >Cancel</a>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default AccountDetails;