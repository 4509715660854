import React, { useRef, useState } from "react";
import { DatePicker } from "rsuite";
import subDays from "date-fns/subDays";
import PlusIcon from "../../../images/icons/plus-black.svg";
import "../OnboardingCompanyDetails/CompanyDetaislForm.css";
import { setOnboard , setFistName } from "../../../features/onBoard/onBoardSlice.js";
import { useSelector, useDispatch } from "react-redux";

function YourPersonalDetails({ onSubmit, onBack }) {

  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    dob: "",
    idNumber: "",
    address: "",
    city: "",
    country: "",
    postalCode: "",
    phoneNo: "",
  });
  const datePickerRef = useRef();
  const [selectedDate, setSelectedDate] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const ranges = [
    {
      label: "today",
      value: new Date(), // Use a single date instead of an array
    },
    {
      label: "yesterday",
      value: subDays(new Date(), 1),
    },
  ];

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setCurrentPage(0);
  };

  const handleDateIconClick = () => {
    datePickerRef.current.open(); // Open the date picker programmatically
  };

  const handleSubmit = (e) => {
    // console.log('formData');
    e.preventDefault();
    //onSubmit(formData); // Call the passed down onSubmit function
    // setFormData({
    //   firstName: "",
    //   lastName: "",
    //   email: "",
    //   dob: "",
    //   idNumber: "",
    //   address: "",
    //   city: "",
    //   country: "",
    //   postalCode: "",
    //   phoneNo: "",
    // });
   
    updateOnBoardingData();

  };

  const updateOnBoardingData = async () => {

  //  await setFistName(formData);

  }


  return (
    <div className="onboarding-detail-outer">
      <div className="company-detail-title">Your Personal Details</div>
      <div className="company-detail-sub-title">
        You will be the company account’s super admin. Please fill out your
        personal information.
      </div>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="first-name">First Name</label>
          <input
            type="text"
            id="first-name"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="last-name">Last Name</label>
          <input
            type="text"
            id="last-name"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group date-of-birth">
          <label htmlFor="dob">Date of Birth</label>
        </div>
        <div className="date-picker-outer date-picker-outer-all">
          <div className="custom-picker-icon custom-picker-icon-all">
            <svg
              width="1em"
              height="1em"
              viewBox="0 0 14 14"
              fill="currentColor"
              aria-hidden="true"
              focusable="false"
              class="rs-picker-toggle-caret rs-icon"
              aria-label="calendar"
              data-category="legacy"
            >
              <path d="M1 4v8.5a.5.5 0 00.5.5H8c0-.128.049-.256.146-.354.555-.555.854-1.6.854-3.146a.5.5 0 01.621-.485l.119.03A2.623 2.623 0 0012.999 6.5V4h-12zm3-2h6V0h1v2h1.5A1.5 1.5 0 0114 3.5v3a3.623 3.623 0 01-4.015 3.603c-.064 1.245-.335 2.212-.831 2.898H12.5a.5.5 0 00.5-.5v-2a.5.5 0 011 0v2a1.5 1.5 0 01-1.5 1.5h-11a1.5 1.5 0 01-1.5-1.5v-9a1.5 1.5 0 011.5-1.5H3v-2h1v2zm5 4V5h1v1h2v1h-2v1H9V7H7v2h1v1H7v2H6v-2H4v2H3v-2H1V9h2V7H1V6h2V5h1v1h2V5h1v1h2zM6 9V7H4v2h2z"></path>
            </svg>
          </div>
          <div className="custoom-icon-calender custoom-icon-calender-all">
            <DatePicker
              oneTap // Use DatePicker instead of DateRangePicker
              format="dd/MM/yyyy"
              ranges={ranges}
              value={selectedDate}
              onChange={handleDateChange}
              placement="auto"
              className="invoice-filter-top-date"
              required
            />
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="id-number">Passport or ID Number</label>
          <div className="id-brwosing">
            <div className="id-detail">
              <input
                type="text"
                id="id-number"
                name="idNumber"
                value={formData.idNumber}
                onChange={handleChange}
                required
              />
            </div>
            <div className="browsing-outer">
              <label htmlFor="expFileAttch" className="file-input-label">
                <img src={PlusIcon} alt="Browse" />
                Browse
              </label>
            </div>
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="address">Address</label>
          <input
            type="text"
            id="address"
            name="address"
            value={formData.address}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="city">City</label>
          <input
            type="text"
            id="city"
            name="city"
            value={formData.city}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="country">Country</label>
          <input
            type="text"
            id="country"
            name="country"
            value={formData.country}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="postal-code">Postal Code</label>
          <input
            type="text"
            id="postal-code"
            name="postalCode"
            value={formData.postalCode}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="phone-no">Phone No.</label>
          <input
            type="text"
            id="phone-no"
            name="phoneNo"
            value={formData.phoneNo}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-button-group">
          <div className="form-back-button">
            <button type="button" onClick={onBack}>
              Back
            </button>
          </div>
          <div className="continue-button">
            <button type="submit">Continue</button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default YourPersonalDetails;
