const VENDOR_INVOICE_STATUS =
{
    INVOICE_APPROVE: 1,
    INVOICE_PENDING: 0,
    INVOICE_REJECT: 2,
    INVOICE_CANCEL: 3,
    INVOICE_PROCESSING_BY_WISE: 4,
    INVOICE_PAYMENT_COMPLETED_BY_WISE: 5,
    INVOICE_PAYMENT_PROCESSING: 1,
    INVOICE_PAYMENT_NOT_PROCESSING: 0,
    INVOICE_MANNUAL_PAYMENT: 2,
};

module.exports = {
    VENDOR_INVOICE_STATUS
}
