import axios from 'axios';
import { useQuery, useMutation, useQueryClient } from 'react-query';

const API_BASE_URL = '/api';

// Fetch users
export const useFetchUsers = (team) => {
    return useQuery('team', async () => {
        // const response = await axios.get(`${API_BASE_URL}/user`, {
        //     headers: {
        //         Authorization: `Bearer ${token}`,
        //     },
        // });
        return team;
    });
};

export const useFetchUsersTimer = (token) => {
    return useQuery('userProfile', async () => {
        const response = await axios.get(`${API_BASE_URL}/user`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    });
};

// update billable rate
export const useUpdateBillableRate = (token) => {
    const queryClient = useQueryClient(); // Get the queryClient
    return useMutation(
        ({ selectedId, billable_cost }) => // Use an object to pass both selectedId and billable_cost
            axios.put(`${API_BASE_URL}/billable-rate/update/${selectedId}`, { billable_cost }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }),
        {
            onSuccess: () => {
                // You can perform any actions needed after the request is successfully created.
                // For example, refetch the users.
                queryClient.invalidateQueries('users');
            },
        }
    );
};

export const useUserProjectCost = (projectId, userId, token) => {
    return useQuery(['userProjectCost', projectId], async () => {
        try {
            const response = await axios.post('/api/project-user-costs', { projectId, userId, }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return response.data.amount;
        } catch (error) {
            global.config.slackMessage(error.toString());
            throw new Error('Error fetching user project cost');
        }
    });
};

export const useUpdateProjectCost = (token) => {
    const queryClient = useQueryClient(); // Get the queryClient
    return useMutation(
        ({ projectId, userId, amount }) => // Use an object to pass both selectedId and billable_cost
            axios.put(`${API_BASE_URL}/project-user-costs-update`, { projectId, userId, amount }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }),
        {
            onSuccess: () => {
                // You can perform any actions needed after the request is successfully created.
                // For example, refetch the users.
                queryClient.invalidateQueries('userProjectCost');
            },
        }
    );
};

export const useUpdateBillableCostRate = (token) => {

    const queryClient = useQueryClient();
    return useMutation(
        ({ selectedId, cost }) => // Use an object to pass both selectedId and billable_cost
            axios.put(`${API_BASE_URL}/cost-rate/update/${selectedId}`, { cost }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }),
        {
            onSuccess: () => {
                // You can perform any actions needed after the request is successfully created.
                // For example, refetch the users.
                queryClient.invalidateQueries('users');
            },
        }
    );

}

export const useUpdateTeam = (token) => {

    const queryClient = useQueryClient(); // Get the queryClient
    return useMutation(
        ({ userId, projectId }) => // Use an object to pass both selectedId and billable_cost
            axios.put(`${API_BASE_URL}/project-team/update/${projectId}`, { userId }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }),
        {
            onSuccess: () => {
                // You can perform any actions needed after the request is successfully created.
                // For example, refetch the users.
                queryClient.invalidateQueries('projects');
            },
        }
    );

}

export const useFetchProjectByCost = async (token, projectId, UserId) => {
    //  return useQuery('userProjectCost', async () => {

    // const response =  axios.get(`/api/project-user-costs`, {
    //     params:{ projectId,UserId },
    //     headers: {
    //         Authorization: `Bearer ${token}`,
    //     },
    // });
    // console.log('UserId', UserId);
    const response = await axios.post('/api/project-user-costs', { projectId, userId: UserId }, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });

    return response.data;

    //  });
};