import moment from "moment";
import "./equipment.css";
import { ThreeDots } from "react-loader-spinner";
import { useSelector, useDispatch } from "react-redux";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import React, { useState, useEffect, useRef } from "react";
import { setHeaderText } from "../../features/headerText/headerTextSlice";
import TruncateFieldView from "../../components/helpers/truncateFieldView.js";
import profile_image from "../../images/user/Default_pfp.png";
import { useFetchEquipments } from "../../features/equipments/useEquipDetails.jsx";

const useRenderAssignUser = (rowData) => {
    const [showFullname, setShowFullname] = useState(false);

    const fullName =
        (rowData.assign_user.first_name || rowData.assign_user.company_name) +
        (rowData.assign_user.middle_name && rowData.assign_user.middle_name !== "null"
            ? " " + rowData.assign_user.middle_name
            : "") +
        " " +
        rowData.assign_user.last_name;
    const displayName = showFullname
        ? fullName
        : fullName.length <= 15
            ? fullName
            : fullName.slice(0, 15) + "...";

    return (
        <div
            className={`member-name${showFullname ? " active" : ""}`}
            // onMouseEnter={() => setShowFullname(true)}
            onMouseEnter={() => {
                if (fullName.length > 15) {
                    setShowFullname(true);
                }
            }}
            onMouseLeave={() => setShowFullname(false)}
        >
            {rowData.assign_user.profile_image !== null &&
                rowData.assign_user.profile_image !== "null" ? (
                <img
                    src={`/uploads/lowProfileImage/${rowData.assign_user.profile_image}`}
                    alt={fullName}
                    className="member-image"
                    onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = "/uploads/lowProfileImage/user_default.png";
                    }}
                />
            ) : (
                <img src={profile_image} alt={fullName} className="member-image" />
            )}
            <span>{displayName}</span>
        </div>
    );
};

const UserEquipment = () => {

    const dispatch = useDispatch();
    const [filter, setFilter] = useState("");
    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [selectAllByPage, setSelectAllByPage] = useState({});
    const [showingDropdownOpen, setShowingDropdownOpen] = useState(false);
    const showingDropdownRef = useRef(null);
    const { appSettings } = useSelector((state) => state.appSettings);

    const handleFilterChange = (e) => {
        setFilter(e.target.value);
        setCurrentPage(0); // Reset page to the first page
    };

    let token = null;
    const { user } = useSelector((state) => state.auth);
    if (user) {
        if (user && user.token) {
            token = user.token;
        }
    }

    const { data: equipments = [], isLoading: isFetchingEquipment, refetch: equipmentRefetch } = useFetchEquipments(token);

    const handleClickOutsideDropdown = (event) => {
        const isClickInsideShowingDropdown = showingDropdownRef.current && showingDropdownRef.current.contains(event.target);
        
        if (!isClickInsideShowingDropdown) {
            setShowingDropdownOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutsideDropdown);
        return () => {
            document.removeEventListener('click', handleClickOutsideDropdown);
        };
    }, []);



    const onPageChange = (e) => {
        const newPage = e.page;
        // Store the current "Select All" state for the current page
        setSelectAllByPage((prevSelectAllByPage) => ({
            ...prevSelectAllByPage,
        }));
        setCurrentPage(e.page);
    };

    const filteredData = equipments.filter((item) => {
        const searchTerm = filter.trim().toLowerCase();
        const hasMatch = item.eqp_id.toLowerCase().includes(searchTerm) ||
            item.eqp_name.toLowerCase().includes(searchTerm) ||
            item.eqp_category?.eqp_category_name.toLowerCase().includes(searchTerm);
        return hasMatch;
    });

    const totalResults = filteredData.length;


    useEffect(() => {
        dispatch(setHeaderText("Equipment"));
    }, [equipments]);


    const useRenderEqpCategory = (rowData) => {
        return (
            <span>{rowData?.eqp_category && rowData.eqp_category.eqp_category_name}</span>
        );
    }

    const convertAppointedDate = (rowData) => {
        if (!rowData.appointed_at) {
            return "Not appointed yet";
        }
        const timestamp = rowData.appointed_at;
        const formattedDate = moment(timestamp).format('MMMM DD, YYYY');
        return formattedDate;
    };

    return (
        <div className="client-document-outer">
            <div className="user-outer-timeoff document-client-top-sec equipment-table equipment-table-user"  >
                <div className="invoice-top">
                    <div className="search-sec">
                        <div class="search-container">
                            <input
                                type="text"
                                onChange={handleFilterChange}
                                value={filter}
                                class="search-input"
                                placeholder="Search by Equipment name"
                            />
                        </div>
                    </div>
                </div>

                <div className="overview-top-right client-document-outer-top-section" >
                    <div className="showing-count showing-count-timeoff">
                        <div className="filter-inner showing-result show-result-team-fill">
                            <label>Showing</label>

                            <div ref={showingDropdownRef} className="select-modal-mainM custom-dropdown-user-new-outer">
                                <div className="selected-option custom-dropdown-user-new" onClick={() => setShowingDropdownOpen(!showingDropdownOpen)}>
                                    {totalResults < 10 ? totalResults.toString() : rowsPerPage.toString()}
                                </div>
                                {showingDropdownOpen && (
                                    <div className="custom-dropdown-new">
                                        <ul className="showing-options-list">
                                            {totalResults >= 10 && <li className={rowsPerPage === 10 ? 'selected-item' : ''} onClick={() => { setRowsPerPage(10); setCurrentPage(0); setShowingDropdownOpen(false); }}>10</li>}
                                            {totalResults >= 20 && <li className={rowsPerPage === 20 ? 'selected-item' : ''} onClick={() => { setRowsPerPage(20); setCurrentPage(0); setShowingDropdownOpen(false); }}>20</li>}
                                            {totalResults >= 30 && <li className={rowsPerPage === 30 ? 'selected-item' : ''} onClick={() => { setRowsPerPage(30); setCurrentPage(0); setShowingDropdownOpen(false); }}>30</li>}
                                            {totalResults >= 40 && <li className={rowsPerPage === 40 ? 'selected-item' : ''} onClick={() => { setRowsPerPage(40); setCurrentPage(0); setShowingDropdownOpen(false); }}>40</li>}
                                            {totalResults >= 10 && <li className={totalResults >= 10 && rowsPerPage === totalResults ? 'selected-item' : ''} onClick={() => { setRowsPerPage(totalResults); setCurrentPage(0); setShowingDropdownOpen(false); }}>ALL</li>}
                                            {/* Add more conditions as needed */}
                                            {totalResults < 10 ? (
                                                <li className={totalResults < 10 ? 'selected-item' : ''} onClick={() => { setRowsPerPage(totalResults); setCurrentPage(0); setShowingDropdownOpen(false); }}>{totalResults}</li>
                                            ) : null}
                                        </ul>
                                    </div>
                                )}
                            </div>
                            <label>of {totalResults} Results</label>
                        </div>
                    </div>
                </div>

                {isFetchingEquipment == true ? (
                    <div className="outter-load-table">
                        <ThreeDots
                            height="80"
                            width="80"
                            radius="9"
                            color="#405BFF"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                        />
                    </div>
                ) : (
                    <div className="datatable-team-maintb">
                        <DataTable
                            value={filteredData}
                            removableSort
                            tableStyle={{ minWidth: "50rem", width: "100%", textAlign: "left" }}
                            paginator={filteredData.length > 10}
                            paginatorClassName="recent-invoices-pagination"
                            rows={rowsPerPage}
                            first={currentPage * rowsPerPage}
                            onPage={onPageChange}
                            emptyMessage="No Equipment data found"
                        >
                            <Column
                                field="eqp_id"
                                header="Equipment ID"
                                sortable
                                style={{ width: "19.5%", paddingRight: "20px" }}
                            ></Column>
                            <Column
                                field="eqp_category.eqp_category_name"
                                header="Category"
                                body={useRenderEqpCategory}
                                sortable
                                style={{ width: "16.4%", paddingRight: "20px" }}
                            ></Column>
                            <Column
                                field="eqp_name"
                                header="Name"
                                body={(rowData) => (
                                    <TruncateFieldView textContent={rowData.eqp_name} />
                                )}
                                sortable
                                style={{ width: "19%", paddingRight: "20px" }}
                            ></Column>
                            <Column
                                field="assign_user"
                                body={useRenderAssignUser}
                                header="Assigned User"
                                sortable
                                style={{ width: "17.4%", paddingRight: "20px" }}
                            ></Column>
                            <Column
                                field="appointed_at"
                                body={convertAppointedDate}
                                header="Date Appointed"
                                sortable
                                style={{ width: "20.4%", paddingRight: "20px" }}
                            ></Column>
                        </DataTable>
                    </div>
                )}
            </div>
        </div>
    );
};

export default UserEquipment;