import axios from 'axios';
import { useQuery, useMutation } from 'react-query';
import userCategory from '../../../constants/userCategory';

export const useFetchVendorInvoices = (vendorId, token) => {
    return useQuery(['vendorInvoices', vendorId], async () => {
        const response = await axios.get(`/api/vendor/invoices/${vendorId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    });
};

export const useFetchVendorExpenses = (vendorId, token) => {
    return useQuery(['vendorExpenses', vendorId], async () => {
        const response = await axios.get(`/api/vendor/expenses/${vendorId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    });
};

export const useFetchVendorsInvoices = (token) => {
    return useQuery('vendorsInvoices', async () => {
        const response = await axios.get(`/api/vendors/invoices`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    });
};

export function useFetchVendorProjectDetails(vendorId, token) {
    return useQuery(['vendorProjectDetails', vendorId], async () => {
        const response = await axios.get(`/api/project-details/vendor/${vendorId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    });
}

export function useFetchVendorsProjects(token) {
    return useQuery('vendorProjectDetails', async () => {
        const response = await axios.get(`/api/projects/vendors`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    });
}

export const useVendorUser = (token) => {
    return useQuery('vendorUser', async () => {
        const response = await axios.get(`/api/user/user-vendors`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        const sortedTeams = response.data.sort((a, b) => {
            const nameA = a.first_name ? `${a.first_name} ${a.middle_name ? a.middle_name : ''} ${a.last_name}` : `${a.company_first_name ? a.company_first_name + (a.company_last_name && a.company_last_name) : a.company_name}`;
            const nameB = b.first_name ? `${b.first_name} ${b.middle_name ? b.middle_name : ''} ${b.last_name}` : `${b.company_first_name ? b.company_first_name + (b.company_last_name && b.company_last_name) : b.company_name}`;
            return nameA.localeCompare(nameB);
        });

        return sortedTeams;
    });
};

export const useWxCompany = (token) => {
    return useQuery('wxCompany', async () => {
        const response = await axios.get(`/api/get-wx-comapies`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        const sortedWxCompany = response.data.filter(
            (compny) =>
                compny.wx_company !== "Any"
        );

        return sortedWxCompany;
    });
};

// export function useVendorView(vendorId, token) {
//     return useQuery(['vendorView', vendorId], async () => {
//         const response = await axios.get(`/api/vendor-details/${vendorId}`, {
//             headers: {
//                 Authorization: `Bearer ${token}`,
//             },
//         });
//         return response.data;
//     });
// }

// export function usePosition(token) {
//     return useQuery('position', async () => {
//         const response = await axios.get('/api/position-details', {
//             headers: {
//                 Authorization: `Bearer ${token}`,
//             },
//         });
//         return response.data;
//     });
// }

// export function useDeleteVendorDetails(token) {
//     const mutation = useMutation((selectedId) => {
//         return axios.get(`/api/vendor-details/delete/${selectedId}`, {
//             headers: {
//                 Authorization: `Bearer ${token}`,
//             },
//         });
//     });

//     return mutation;
// }

// export function useUpdateVendorDetails(token) {
//     const mutation = useMutation((updatedVendorDetails) => {
//         return axios.put(`/api/vendor-details/update/${updatedVendorDetails.selectedId}`, updatedVendorDetails.data, {
//             headers: {
//                 Authorization: `Bearer ${token}`,
//             },
//         });
//     });

//     return mutation;
// }