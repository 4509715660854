import axios from 'axios';
import { useQuery, useMutation, useQueryClient } from 'react-query';


const API_BASE_URL = '/api';

export function useFetchTrackReportDetails(token) {
    return useQuery(['reportTrackDetails', token], async ({ queryKey }) => {
        const [, token] = queryKey;
        const response = await axios.post(
            '/api/track/report/overview', {},
            {
                headers: {
                    Authorization: `Bearer ${token}`
                },
            }
        );
        return response.data;
    });
}

export function useFetchClientInvoices(token) {
    return useQuery(['clientInvoices', token], async ({ queryKey }) => {
        const [, token] = queryKey;

        const response = await axios.get(
            `/api/clients/invoices`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        return response.data;

    });
}

export function useFetchExpenses(token) {
    return useQuery(['allExpenses', token], async ({ queryKey }) => {
        const [, token] = queryKey;

        const response = await axios.get(
            `/api/expenses`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        return response.data;

    });
}

export function useFetchSprints(token) {
    return useQuery(['allSprints', token], async ({ queryKey }) => {
        const [, token] = queryKey;

        const response = await axios.get(
            `/api/sprint/report/overview`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        return response.data;

    });
}

export function useFetchProjectCosts(token) {
    return useQuery(['projectCosts', token], async ({ queryKey }) => {
        const [, token] = queryKey;

        const response = await axios.get(
            `/api/user/project/cost/report`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        return response.data;

    });
}