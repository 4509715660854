import axios from "axios";
import { useQuery, useMutation, useQueryClient } from 'react-query';
const API_URL = "/api/user/";


export const postDocument = async (token, doc_id, client_id, doc_from, doc_type, doc_name, doc_embedUrl, doc_url, doc_organizationDisplayName, doc_iconUrl, is_delete, expired_at) => {



    await axios.post(
        `/api/client/document`,
        {
            doc_id,
            client_id,
            doc_from,
            doc_type,
            doc_name,
            doc_embedUrl,
            doc_url,
            doc_organizationDisplayName,
            doc_iconUrl,
            is_delete,
            expired_at
        },
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
    );

}



export const deviceFileUpload = async (token, file , client_id) => {

    const formData = new FormData();
    formData.append('file', file);
    formData.append('client_id', client_id);

    try {
        const response = await axios.post("/api/client/device-document", formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        });
    } catch (error) {
        global.config.slackMessage(error.toString());
        console.error('Error uploading file:', error);
    }
}


export const useDocumentList = (token,id) => {
    try {
        return useQuery('documentList', async () => {
            const response = await axios.get(`/api/client/document/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return response.data;
        });
    } catch (error) {
        console.error('Error uploading file:', error);
        global.config.slackMessage(error.toString());
    }
};