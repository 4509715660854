import { reportingFilterComparisonConst } from "../../../../../constants/reportingFilterComparisonConst";
import { reportingFilterDateRangesConst } from "../../../../../constants/reportingFilterDateRangesConst";

export function processedBudgetedTrackedByProject(trackReport, reportingCliTimeWorked) {
    // const currentYear = reportingCliTimeWorked.cliTimeWorkedYear || new Date().getFullYear();
    const projectData = {};
    const calculatedProjects = new Set(); // To keep track of already processed projects

    // Helper function to calculate total tracked hours for a given project only once
    const calculateTrackedHours = (projectId) => {
        const trackedData = trackReport.filter(track => track?.project?._id === projectId);
        return trackedData.reduce((total, track) => total + track.duration, 0);
    };

    // Iterate over the trackReport to process each track
    trackReport.forEach((track) => {
        const project = track?.project;
        const projectYear = new Date(track?.startTime).getFullYear();
        const projectId = project?._id;
        const projectName = project?.project_name;
        const budgetedHours = project?.total_hours || 0;
        const budgetedSeconds = budgetedHours * 3600; // Convert hours to seconds

        // Skip processing if the project has already been calculated
        if (
            // projectYear === currentYear &&
            !project?.is_delete &&
            reportingCliTimeWorked?.cliTimeWorkedProject?.includes(projectId) &&
            !calculatedProjects.has(projectId)
        ) {
            // Initialize the project data if not already present
            if (!projectData[projectId]) {
                projectData[projectId] = {
                    name: projectName,
                    budgeted: 0,
                    tracked: 0,
                };
            }

            // Accumulate the budgeted hours (in seconds) for the project
            projectData[projectId].budgeted += budgetedSeconds;

            // Calculate the tracked hours only once for the project
            const trackedSeconds = calculateTrackedHours(projectId);
            projectData[projectId].tracked += trackedSeconds;

            // Mark the project as processed
            calculatedProjects.add(projectId);
        }
    });

    // Format budgeted and tracked times to two decimal places (for hours)
    Object.keys(projectData).forEach((projectId) => {
        projectData[projectId].budgeted = (projectData[projectId].budgeted).toFixed(2); // Convert to hours
        projectData[projectId].tracked = (projectData[projectId].tracked).toFixed(2); // Convert to hours
    });

    // Return the processed project data as an array of values
    return Object.values(projectData);
}

export function processedBudgetedTrackedByProjectFilter(
    trackReport,
    reportingCliTimeWorked,
    selectedDateRange,
    selectedComparison,
    selectedYearRange,
    selectedMonthRange,
    selectedComparisonYear,
    selectedComparisonMonth
) {
    const calculatedProjects = new Set();
    const getLastDayOfMonth = (year, month) => {
        return new Date(year, month, 0).getDate();
    };

    const getStartAndEndDates = (rangeId, year = new Date().getFullYear()) => {
        switch (rangeId) {
            case reportingFilterDateRangesConst.This_Year:
                return [
                    new Date(`${year}-01-01`),
                    new Date(`${year}-12-31`)
                ];

            case reportingFilterDateRangesConst.This_Quarter: {
                const quarter = Math.floor((new Date().getMonth() + 3) / 3);
                const startMonth = (quarter - 1) * 3 + 1;
                const endMonth = startMonth + 2;
                const endDay = getLastDayOfMonth(year, endMonth);
                return [
                    new Date(`${year}-${startMonth}-01`),
                    new Date(`${year}-${endMonth}-${endDay}`)
                ];
            }

            case reportingFilterDateRangesConst.First_Half:
                return [
                    new Date(`${year}-01-01`),
                    new Date(`${year}-06-30`)
                ];

            case reportingFilterDateRangesConst.This_Month: {
                const month = new Date().getMonth() + 1;
                const endDay = getLastDayOfMonth(year, month);
                return [
                    new Date(`${year}-${month}-01`),
                    new Date(`${year}-${month}-${endDay}`)
                ];
            }

            case reportingFilterDateRangesConst.Year_Selected:
                if (selectedYearRange) {
                    const selectedYear = selectedYearRange.getFullYear();
                    return [
                        new Date(`${selectedYear}-01-01`),
                        new Date(`${selectedYear}-12-31`)
                    ];
                } else {
                    return [
                        new Date(`${year}-01-01`),
                        new Date(`${year}-12-31`)
                    ];
                }

            case reportingFilterDateRangesConst.Month_Selected:
                if (selectedMonthRange) {
                    const selectedMonth = selectedMonthRange.getMonth() + 1;
                    const selectedYearForMonth = selectedMonthRange.getFullYear();
                    const endDay = getLastDayOfMonth(selectedYearForMonth, selectedMonth);
                    return [
                        new Date(`${selectedYearForMonth}-${selectedMonth}-01`),
                        new Date(`${selectedYearForMonth}-${selectedMonth}-${endDay}`)
                    ];
                } else {
                    const currentMonth = new Date().getMonth() + 1;
                    const endDay = getLastDayOfMonth(year, currentMonth);
                    return [
                        new Date(`${year}-${currentMonth}-01`),
                        new Date(`${year}-${currentMonth}-${endDay}`)
                    ];
                }

            default:
                return [
                    new Date(`${year}-01-01`),
                    new Date(`${year}-12-31`)
                ];
        }
    };

    const getComparisonStartAndEndDates = (comparisonId, year = new Date().getFullYear()) => {
        switch (comparisonId) {
            case reportingFilterComparisonConst.Last_Year:
                return [
                    new Date(`${year - 1}-01-01`),
                    new Date(`${year - 1}-12-31`)
                ];

            case reportingFilterComparisonConst.Last_Quarter: {
                const quarter = Math.floor((new Date().getMonth() + 3) / 3);
                const lastQuarter = quarter === 1 ? 4 : quarter - 1;
                const startMonth = (lastQuarter - 1) * 3 + 1;
                const endMonth = startMonth + 2;
                const lastYear = quarter === 1 ? year - 1 : year;
                const endDay = getLastDayOfMonth(lastYear, endMonth);
                return [
                    new Date(`${lastYear}-${startMonth}-01`),
                    new Date(`${lastYear}-${endMonth}-${endDay}`)
                ];
            }

            case reportingFilterComparisonConst.Second_Half:
                return [
                    new Date(`${year}-07-01`),
                    new Date(`${year}-12-31`)
                ];

            case reportingFilterComparisonConst.Last_Month: {
                const lastMonth = new Date().getMonth(); // 0-indexed for previous month
                const lastYear = lastMonth === 0 ? year - 1 : year;
                const lastMonthFormatted = lastMonth === 0 ? 12 : lastMonth;
                const endDay = getLastDayOfMonth(lastYear, lastMonthFormatted);
                return [
                    new Date(`${lastYear}-${lastMonthFormatted}-01`),
                    new Date(`${lastYear}-${lastMonthFormatted}-${endDay}`)
                ];
            }

            case reportingFilterComparisonConst.Year_Select:
                if (selectedComparisonYear) {
                    const comparisonYear = selectedComparisonYear.getFullYear();
                    return [
                        new Date(`${comparisonYear}-01-01`),
                        new Date(`${comparisonYear}-12-31`)
                    ];
                } else {
                    return [
                        new Date(`${year - 1}-01-01`),
                        new Date(`${year - 1}-12-31`)
                    ];
                }

            case reportingFilterComparisonConst.Month_Select:
                if (selectedComparisonMonth) {
                    const comparisonMonth = selectedComparisonMonth.getMonth() + 1;
                    const comparisonYearForMonth = selectedComparisonMonth.getFullYear();
                    const endDay = getLastDayOfMonth(comparisonYearForMonth, comparisonMonth);
                    return [
                        new Date(`${comparisonYearForMonth}-${comparisonMonth}-01`),
                        new Date(`${comparisonYearForMonth}-${comparisonMonth}-${endDay}`)
                    ];
                } else {
                    const lastMonth = new Date().getMonth() || 12;
                    const fallbackYear = lastMonth === 12 ? year - 1 : year;
                    const endDay = getLastDayOfMonth(fallbackYear, lastMonth);
                    return [
                        new Date(`${fallbackYear}-${lastMonth}-01`),
                        new Date(`${fallbackYear}-${lastMonth}-${endDay}`)
                    ];
                }
            default:
                return [
                    new Date(`${year - 1}-01-01`),
                    new Date(`${year - 1}-12-31`)
                ];
        }
    };

    const filterTrackedDataByDateRange = (trackedData, startDate, endDate) => {
        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(23, 59, 59, 999);
        return trackedData.filter(track => {
            const trackDate = new Date(track?.startTime);
            const project = track?.project;
            const projectId = project?._id;
            return !project.is_delete &&
                reportingCliTimeWorked?.cliTimeWorkedProject?.includes(projectId) &&
                trackDate >= startDate && trackDate <= endDate;
        });
    };

    const calculateTrackedHours = (trackReport, projectId) => {
        const filteredData = trackReport.filter((track) => track?.project?._id === projectId);
        return filteredData.reduce((acc, track) => acc + track.duration, 0);
    };

    const calculateBudgetedTrackedByProject = (trackReport) => {
        const projectData = {};

        trackReport.forEach((track) => {
            const project = track?.project;
            const projectId = project?._id;
            const projectName = project?.project_name;
            const budgetedHours = project?.total_hours || 0;
            const budgetedSeconds = budgetedHours * 3600;
            const trackedSeconds = calculateTrackedHours(trackReport, projectId);

            if (!projectData[projectId]) {
                projectData[projectId] = {
                    name: projectName,
                    budgeted: 0,
                    tracked: 0,
                };
            }

            // projectData[projectId].budgeted === 0 && projectData[projectId].tracked === 0
            if (!calculatedProjects.has(projectId)) {
                projectData[projectId].budgeted += budgetedSeconds;
                projectData[projectId].tracked += trackedSeconds;
            }
            calculatedProjects.add(projectId);
        });

        Object.keys(projectData).forEach((projectId) => {
            projectData[projectId].budgeted = (projectData[projectId].budgeted).toFixed(2);
            projectData[projectId].tracked = (projectData[projectId].tracked).toFixed(2);
        });

        return projectData;
    };

    const [startCurrentRange, endCurrentRange] = getStartAndEndDates(selectedDateRange, selectedYearRange ? selectedYearRange.getFullYear() : undefined);
    const [startComparisonRange, endComparisonRange] = getComparisonStartAndEndDates(selectedComparison, selectedComparisonYear ? selectedComparisonYear.getFullYear() : undefined);

    const currentPeriodTrackedData = filterTrackedDataByDateRange(trackReport, startCurrentRange, endCurrentRange);
    const comparisonPeriodTrackedData = filterTrackedDataByDateRange(trackReport, startComparisonRange, endComparisonRange);

    const currentPeriodProjectData = calculateBudgetedTrackedByProject(currentPeriodTrackedData);
    const comparisonPeriodProjectData = calculateBudgetedTrackedByProject(comparisonPeriodTrackedData);

    const combinedProjectData = Object.keys(currentPeriodProjectData).map(projectId => {
        const current = currentPeriodProjectData[projectId];
        const comparison = comparisonPeriodProjectData[projectId] || { budgeted: 0, tracked: 0 };

        return {
            name: current.name,
            currentPeriodBudgeted: parseFloat(current.budgeted),
            currentPeriodTracked: parseFloat(current.tracked),
            comparisonPeriodBudgeted: parseFloat(comparison.budgeted),
            comparisonPeriodTracked: parseFloat(comparison.tracked),
        };
    });

    return combinedProjectData;
}
