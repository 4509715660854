export function emailPatternVerification(email) {

        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

        if (email) {
                if (emailPattern.test(email)) {

                        return true;
                } else {
                        return false;
                }
        } else {
                return false;
        }

}