import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  cliTimeWorkedClient: null,
  cliTimeWorkedProject: null,
  // cliTimeWorkedYear: null,
};

export const setReportingCliTimeWorkedClient = createAsyncThunk(
  "reporting/financial/overview/client",
  async (cliTimeWorkedClient) => {
    return cliTimeWorkedClient;
  }
);

export const setReportingCliTimeWorkedProject = createAsyncThunk(
  "reporting/financial/overview/project",
  async (cliTimeWorkedProject) => {
    return cliTimeWorkedProject;
  }
);

// export const setReportingCliTimeWorkedYear = createAsyncThunk(
//   "reporting/financial/overview/year",
//   async (cliTimeWorkedYear) => {
//     if (cliTimeWorkedYear !== null) {
//     //   const date = startOfYear(new Date(year, 0, 1));
//     //   date.setHours(0, 0, 0, 0);
//       return cliTimeWorkedYear;
//     } else {
//       return null;
//     }
//   }
// );

export const reportingCliTimeWorkedSlice = createSlice({
  name: "reportingCliTimeWorked",
  initialState,
  reducers: {
    reset: (state) => {
      state.cliTimeWorkedClient = null;
      state.cliTimeWorkedProject = null;
      // state.cliTimeWorkedYear = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setReportingCliTimeWorkedClient.fulfilled, (state, action) => {
      state.cliTimeWorkedClient = action.payload;
    });
    builder.addCase(setReportingCliTimeWorkedProject.fulfilled, (state, action) => {
      state.cliTimeWorkedProject = action.payload;
    });
    // builder.addCase(setReportingCliTimeWorkedYear.fulfilled, (state, action) => {
    //   state.cliTimeWorkedYear = action.payload;
    // });
  },
});

export const { reset } = reportingCliTimeWorkedSlice.actions;
export default reportingCliTimeWorkedSlice.reducer;
