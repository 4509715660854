const countriesData = [
  {
    country: "Afghanistan",
    states: [
      { state: "Badakhshan", cities: ["Fayzabad", "Khandud", "Khash"] },
      { state: "Badghis", cities: ["Qala i Naw", "Balamorghab", "Jawand"] },
      // Add more states and cities for Afghanistan...
    ],
  },
  {
    country: "Albania",
    states: [
      { state: "Berat", cities: ["Berat", "Kucove", "Polican"] },
      { state: "Durres", cities: ["Durres", "Shijak", "Cudhi-Kamza"] },
      // Add more states and cities for Albania...
    ],
  },
  {
    country: "Algeria",
    states: [
      { state: "Adrar", cities: ["Adrar", "Aoulef", "Bordj Badji Mokhtar"] },
      { state: "Chlef", cities: ["Chlef", "El Karimia", "Tenes"] },
      // Add more states and cities for Algeria...
    ],
  },
  {
    country: "Panama",
    states: [
      { state: "Bocas del Toro", cities: ["Bocas Town", "Changuinola"] },
      { state: "Chiriquí", cities: ["David", "Boquete", "Volcán"] },
      { state: "Coclé", cities: ["Penonomé", "Aguadulce", "Antón"] },
      { state: "Colón", cities: ["Colón", "Sabanitas"] },
      { state: "Darién", cities: ["La Palma", "Yaviza"] },
      { state: "Herrera", cities: ["Chitré", "Las Minas"] },
      { state: "Los Santos", cities: ["Las Tablas", "Chitré"] },
      {
        state: "Panamá",
        cities: ["Panama City", "San Miguelito", "Arraiján"],
      },
      { state: "Veraguas", cities: ["Santiago de Veraguas", "La Mesa"] },
    ],
  },
  {
    country: "India",
    states: [
      {
        state: "Andhra Pradesh",
        cities: ["Visakhapatnam", "Vijayawada", "Guntur"],
      },
      {
        state: "Arunachal Pradesh",
        cities: ["Itanagar", "Naharlagun", "Pasighat"],
      },
      { state: "Assam", cities: ["Guwahati", "Dibrugarh", "Silchar"] },
      { state: "Bihar", cities: ["Patna", "Gaya", "Muzaffarpur"] },
      { state: "Chhattisgarh", cities: ["Raipur", "Bhilai", "Bilaspur"] },
      { state: "Goa", cities: ["Panaji", "Margao", "Vasco da Gama"] },
      { state: "Gujarat", cities: ["Ahmedabad", "Surat", "Vadodara"] },
      { state: "Haryana", cities: ["Chandigarh", "Faridabad", "Gurugram"] },
      { state: "Himachal Pradesh", cities: ["Shimla", "Mandi", "Dharamshala"] },
      { state: "Jharkhand", cities: ["Ranchi", "Jamshedpur", "Dhanbad"] },
      { state: "Karnataka", cities: ["Bengaluru", "Mysore", "Hubli"] },
      { state: "Kerala", cities: ["Thiruvananthapuram", "Kochi", "Kozhikode"] },
      { state: "Madhya Pradesh", cities: ["Bhopal", "Indore", "Jabalpur"] },
      { state: "Maharashtra", cities: ["Mumbai", "Pune", "Nagpur"] },
      { state: "Manipur", cities: ["Imphal", "Thoubal", "Bishnupur"] },
      { state: "Meghalaya", cities: ["Shillong", "Tura", "Nongpoh"] },
      { state: "Mizoram", cities: ["Aizawl", "Lunglei", "Champhai"] },
      { state: "Nagaland", cities: ["Kohima", "Dimapur", "Mokokchung"] },
      { state: "Odisha", cities: ["Bhubaneswar", "Cuttack", "Rourkela"] },
      { state: "Punjab", cities: ["Chandigarh", "Ludhiana", "Amritsar"] },
      { state: "Rajasthan", cities: ["Jaipur", "Jodhpur", "Udaipur"] },
      { state: "Sikkim", cities: ["Gangtok", "Namchi", "Jorethang"] },
      { state: "Tamil Nadu", cities: ["Chennai", "Coimbatore", "Madurai"] },
      { state: "Telangana", cities: ["Hyderabad", "Warangal", "Nizamabad"] },
      { state: "Tripura", cities: ["Agartala", "Udaipur", "Dharmanagar"] },
      { state: "Uttar Pradesh", cities: ["Lucknow", "Kanpur", "Agra"] },
      { state: "Uttarakhand", cities: ["Dehradun", "Haridwar", "Roorkee"] },
      { state: "West Bengal", cities: ["Kolkata", "Howrah", "Durgapur"] },
      // Add more states and cities for India...
    ],
  },
  {
    country: "United States",
    states: [
      {
        state: "Alabama",
        cities: ["Birmingham", "Montgomery", "Mobile"],
      },
      {
        state: "Alaska",
        cities: ["Anchorage", "Fairbanks", "Juneau"],
      },
      {
        state: "Arizona",
        cities: ["Phoenix", "Tucson", "Mesa"],
      },
      {
        state: "Arkansas",
        cities: ["Little Rock", "Fort Smith", "Fayetteville"],
      },
      {
        state: "California",
        cities: ["Los Angeles", "San Francisco", "San Diego"],
      },
      {
        state: "Colorado",
        cities: ["Denver", "Colorado Springs", "Aurora"],
      },
      {
        state: "Connecticut",
        cities: ["Bridgeport", "New Haven", "Hartford"],
      },
      {
        state: "Delaware",
        cities: ["Wilmington", "Dover", "Newark"],
      },
      {
        state: "Florida",
        cities: ["Miami", "Orlando", "Tampa"],
      },
      {
        state: "Georgia",
        cities: ["Atlanta", "Savannah", "Augusta"],
      },
      {
        state: "Hawaii",
        cities: ["Honolulu", "Hilo", "Kailua"],
      },
      {
        state: "Idaho",
        cities: ["Boise", "Nampa", "Meridian"],
      },
      {
        state: "Illinois",
        cities: ["Chicago", "Aurora", "Rockford"],
      },
      {
        state: "Indiana",
        cities: ["Indianapolis", "Fort Wayne", "Evansville"],
      },
      {
        state: "Iowa",
        cities: ["Des Moines", "Cedar Rapids", "Davenport"],
      },
      {
        state: "Kansas",
        cities: ["Wichita", "Overland Park", "Kansas City"],
      },
      {
        state: "Kentucky",
        cities: ["Louisville", "Lexington", "Bowling Green"],
      },
      {
        state: "Louisiana",
        cities: ["New Orleans", "Baton Rouge", "Shreveport"],
      },
      {
        state: "Maine",
        cities: ["Portland", "Lewiston", "Bangor"],
      },
      {
        state: "Maryland",
        cities: ["Baltimore", "Columbia", "Annapolis"],
      },
      {
        state: "Massachusetts",
        cities: ["Boston", "Worcester", "Springfield"],
      },
      {
        state: "Michigan",
        cities: ["Detroit", "Grand Rapids", "Warren"],
      },
      {
        state: "Minnesota",
        cities: ["Minneapolis", "Saint Paul", "Rochester"],
      },
      {
        state: "Mississippi",
        cities: ["Jackson", "Gulfport", "Southaven"],
      },
      {
        state: "Missouri",
        cities: ["Kansas City", "St. Louis", "Springfield"],
      },
      {
        state: "Montana",
        cities: ["Billings", "Missoula", "Great Falls"],
      },
      {
        state: "Nebraska",
        cities: ["Omaha", "Lincoln", "Bellevue"],
      },
      {
        state: "Nevada",
        cities: ["Las Vegas", "Reno", "Henderson"],
      },
      {
        state: "New Hampshire",
        cities: ["Manchester", "Nashua", "Concord"],
      },
      {
        state: "New Jersey",
        cities: ["Newark", "Jersey City", "Paterson"],
      },
      {
        state: "New Mexico",
        cities: ["Albuquerque", "Las Cruces", "Santa Fe"],
      },
      {
        state: "New York",
        cities: ["New York City", "Buffalo", "Rochester"],
      },
      {
        state: "North Carolina",
        cities: ["Charlotte", "Raleigh", "Greensboro"],
      },
      {
        state: "North Dakota",
        cities: ["Fargo", "Bismarck", "Grand Forks"],
      },
      {
        state: "Ohio",
        cities: ["Columbus", "Cleveland", "Cincinnati"],
      },
      {
        state: "Oklahoma",
        cities: ["Oklahoma City", "Tulsa", "Norman"],
      },
      {
        state: "Oregon",
        cities: ["Portland", "Eugene", "Salem"],
      },
      {
        state: "Pennsylvania",
        cities: ["Philadelphia", "Pittsburgh", "Allentown"],
      },
      {
        state: "Rhode Island",
        cities: ["Providence", "Warwick", "Cranston"],
      },
      {
        state: "South Carolina",
        cities: ["Columbia", "Charleston", "Greenville"],
      },
      {
        state: "South Dakota",
        cities: ["Sioux Falls", "Rapid City", "Aberdeen"],
      },
      {
        state: "Tennessee",
        cities: ["Nashville", "Memphis", "Knoxville"],
      },
      {
        state: "Texas",
        cities: ["Houston", "Dallas", "Austin"],
      },
      {
        state: "Utah",
        cities: ["Salt Lake City", "West Valley City", "Provo"],
      },
      {
        state: "Vermont",
        cities: ["Burlington", "South Burlington", "Rutland"],
      },
      {
        state: "Virginia",
        cities: ["Virginia Beach", "Norfolk", "Richmond"],
      },
      {
        state: "Washington",
        cities: ["Seattle", "Spokane", "Tacoma"],
      },
      {
        state: "West Virginia",
        cities: ["Charleston", "Huntington", "Morgantown"],
      },
      {
        state: "Wisconsin",
        cities: ["Milwaukee", "Madison", "Green Bay"],
      },
      {
        state: "Wyoming",
        cities: ["Cheyenne", "Casper", "Laramie"],
      },
    ],
  },
  {
    country: "United Kingdom",
    states: [
      {
        state: "England",
        cities: ["London", "Manchester", "Birmingham", "Liverpool", "Bristol"],
      },
      {
        state: "Scotland",
        cities: ["Edinburgh", "Glasgow", "Aberdeen", "Dundee", "Inverness"],
      },
      {
        state: "Wales",
        cities: ["Cardiff", "Swansea", "Newport", "Wrexham", "Bangor"],
      },
      {
        state: "Northern Ireland",
        cities: ["Belfast", "Londonderry", "Newry", "Armagh", "Derry"],
      },
    ],
  },
  {
    country: "Romania",
    states: [
      { state: "Alba", cities: ["Alba Iulia", "Aiud", "Sebeș"] },
      { state: "Arad", cities: ["Arad", "Ineu", "Lipova"] },
      { state: "Argeș", cities: ["Pitești", "Câmpulung", "Mioveni"] },
      { state: "Bacău", cities: ["Bacău", "Moinesti", "Comănești"] },
      { state: "Bihor", cities: ["Oradea", "Salonta", "Marghita"] },
      { state: "Bistrița-Năsăud", cities: ["Bistrița", "Beclean", "Năsăud"] },
      { state: "Botoșani", cities: ["Botoșani", "Dorohoi", "Săveni"] },
      { state: "Brașov", cities: ["Brașov", "Râșnov", "Codlea"] },
      { state: "Brăila", cities: ["Brăila", "Ianca", "Făurei"] },
      { state: "Buzău", cities: ["Buzău", "Râmnicu Sărat", "Nehoiu"] },
      { state: "Caraș-Severin", cities: ["Reșița", "Caransebeș", "Oravița"] },
      { state: "Călărași", cities: ["Călărași", "Oltenița", "Fetești"] },
      { state: "Cluj", cities: ["Cluj-Napoca", "Turda", "Dej"] },
      { state: "Constanța", cities: ["Constanța", "Mangalia", "Medgidia"] },
      {
        state: "Covasna",
        cities: ["Sfântu Gheorghe", "Târgu Secuiesc", "Baraolt"],
      },
      { state: "Dâmbovița", cities: ["Târgoviște", "Moreni", "Pucioasa"] },
      { state: "Dolj", cities: ["Craiova", "Băilești", "Dăbuleni"] },
      { state: "Galați", cities: ["Galați", "Târgu Bujor", "Berești"] },
      { state: "Giurgiu", cities: ["Giurgiu", "Bolintin-Vale", "Mihailesti"] },
      { state: "Gorj", cities: ["Târgu Jiu", "Motru", "Rovinari"] },
      {
        state: "Harghita",
        cities: ["Miercurea Ciuc", "Odorheiu Secuiesc", "Gheorgheni"],
      },
      { state: "Hunedoara", cities: ["Deva", "Hunedoara", "Petroșani"] },
      { state: "Ialomița", cities: ["Slobozia", "Fetești", "Urziceni"] },
      { state: "Iași", cities: ["Iași", "Pașcani", "Târgu Frumos"] },
      { state: "Ilfov", cities: ["Voluntari", "Buftea", "Pantelimon"] },
      {
        state: "Maramureș",
        cities: ["Baia Mare", "Sighetu Marmației", "Baia Sprie"],
      },
      {
        state: "Mehedinți",
        cities: ["Drobeta-Turnu Severin", "Orșova", "Strehaia"],
      },
      { state: "Mureș", cities: ["Târgu Mureș", "Sighișoara", "Reghin"] },
      { state: "Neamț", cities: ["Piatra Neamț", "Roman", "Târgu Neamț"] },
      { state: "Olt", cities: ["Slatina", "Caracal", "Piatra-Olt"] },
      { state: "Prahova", cities: ["Ploiești", "Câmpina", "Sinaia"] },
      { state: "Satu Mare", cities: ["Satu Mare", "Carei", "Negrești-Oaș"] },
      { state: "Sălaj", cities: ["Zalău", "Simleu Silvaniei", "Jibou"] },
      { state: "Sibiu", cities: ["Sibiu", "Mediaș", "Cisnădie"] },
      { state: "Suceava", cities: ["Suceava", "Fălticeni", "Rădăuți"] },
      {
        state: "Teleorman",
        cities: ["Alexandria", "Roșiorii de Vede", "Zimnicea"],
      },
      { state: "Timiș", cities: ["Timișoara", "Lugoj", "Recaș"] },
      { state: "Tulcea", cities: ["Tulcea", "Babadag", "Isaccea"] },
      { state: "Vaslui", cities: ["Vaslui", "Bârlad", "Husi"] },
      { state: "Vâlcea", cities: ["Râmnicu Vâlcea", "Drăgășani", "Băbeni"] },
      { state: "Vrancea", cities: ["Focșani", "Adjud", "Mărășești"] },
    ],
  },
  {
    country: "Andorra",
    cities: ["Berat", "Kucove", "Polican"],
  },
  { country: "Angola" },
  { country: "Antigua and Barbuda" },
  { country: "Argentina" },
  { country: "Armenia" },
  { country: "Australia" },
  { country: "Austria" },
  { country: "Azerbaijan" },
  { country: "Bahamas" },
  { country: "Bahrain" },
  { country: "Bangladesh" },
  { country: "Barbados" },
  { country: "Belarus" },
  { country: "Belgium" },
  { country: "Belize" },
  { country: "Benin" },
  { country: "Bhutan" },
  { country: "Bolivia" },
  { country: "Bosnia and Herzegovina" },
  { country: "Botswana" },
  { country: "Brazil" },
  { country: "Brunei" },
  { country: "Bulgaria" },
  { country: "Burkina Faso" },
  { country: "Burundi" },
  { country: "Cabo Verde" },
  { country: "Cambodia" },
  { country: "Cameroon" },
  { country: "Canada" },
  { country: "Central African Republic" },
  { country: "Chad" },
  { country: "Chile" },
  { country: "China" },
  { country: "Colombia" },
  { country: "Comoros" },
  { country: "Congo, Democratic Republic of the" },
  { country: "Congo, Republic of the" },
  { country: "Costa Rica" },
  { country: "Cote d'Ivoire" },
  { country: "Croatia" },
  { country: "Cuba" },
  { country: "Cyprus" },
  { country: "Czech Republic" },
  { country: "Denmark" },
  { country: "Djibouti" },
  { country: "Dominica" },
  { country: "Dominican Republic" },
  { country: "East Timor (Timor-Leste)" },
  { country: "Ecuador" },
  { country: "Egypt" },
  { country: "El Salvador" },
  { country: "Equatorial Guinea" },
  { country: "Eritrea" },
  { country: "Estonia" },
  { country: "Eswatini (formerly Swaziland)" },
  { country: "Ethiopia" },
  { country: "Fiji" },
  { country: "Finland" },
  { country: "France" },
  { country: "Gabon" },
  { country: "Gambia" },
  { country: "Georgia" },
  { country: "Germany" },
  { country: "Ghana" },
  { country: "Greece" },
  { country: "Grenada" },
  { country: "Guatemala" },
  { country: "Guinea" },
  { country: "Guinea-Bissau" },
  { country: "Guyana" },
  { country: "Haiti" },
  { country: "Honduras" },
  { country: "Hungary" },
  { country: "Iceland" },
  { country: "Indonesia" },
  { country: "Iran" },
  { country: "Iraq" },
  { country: "Ireland" },
  { country: "Israel" },
  { country: "Italy" },
  { country: "Jamaica" },
  { country: "Japan" },
  { country: "Jordan" },
  { country: "Kazakhstan" },
  { country: "Kenya" },
  { country: "Kiribati" },
  { country: "Korea, North" },
  { country: "Korea, South" },
  { country: "Kosovo" },
  { country: "Kuwait" },
  { country: "Kyrgyzstan" },
  { country: "Laos" },
  { country: "Latvia" },
  { country: "Lebanon" },
  { country: "Lesotho" },
  { country: "Liberia" },
  { country: "Libya" },
  { country: "Liechtenstein" },
  { country: "Lithuania" },
  { country: "Luxembourg" },
  { country: "Madagascar" },
  { country: "Malawi" },
  { country: "Malaysia" },
  { country: "Maldives" },
  { country: "Mali" },
  { country: "Malta" },
  { country: "Marshall Islands" },
  { country: "Mauritania" },
  { country: "Mauritius" },
  { country: "Mexico" },
  { country: "Micronesia" },
  { country: "Moldova" },
  { country: "Monaco" },
  { country: "Mongolia" },
  { country: "Montenegro" },
  { country: "Morocco" },
  { country: "Mozambique" },
  { country: "Myanmar (formerly Burma)" },
  { country: "Namibia" },
  { country: "Nauru" },
  { country: "Nepal" },
  { country: "Netherlands" },
  { country: "New Zealand" },
  { country: "Nicaragua" },
  { country: "Niger" },
  { country: "Nigeria" },
  { country: "North Macedonia (formerly Macedonia)" },
  { country: "Norway" },
  { country: "Oman" },
  { country: "Pakistan" },
  { country: "Palau" },
  { country: "Palestine" },
  { country: "Papua New Guinea" },
  { country: "Paraguay" },
  { country: "Peru" },
  { country: "Philippines" },
  { country: "Poland" },
  { country: "Portugal" },
  { country: "Qatar" },
  { country: "Russia" },
  { country: "Rwanda" },
  { country: "Saint Kitts and Nevis" },
  { country: "Saint Lucia" },
  { country: "Saint Vincent and the Grenadines" },
  { country: "Samoa" },
  { country: "San Marino" },
  { country: "Sao Tome and Principe" },
  { country: "Saudi Arabia" },
  { country: "Senegal" },
  { country: "Serbia" },
  { country: "Seychelles" },
  { country: "Sierra Leone" },
  { country: "Singapore" },
  { country: "Slovakia" },
  { country: "Slovenia" },
  { country: "Solomon Islands" },
  { country: "Somalia" },
  { country: "South Africa" },
  { country: "South Sudan" },
  { country: "Spain" },
  { country: "Sri Lanka" },
  { country: "Sudan" },
  { country: "Suriname" },
  { country: "Sweden" },
  { country: "Switzerland" },
  { country: "Syria" },
  { country: "Taiwan" },
  { country: "Tajikistan" },
  { country: "Tanzania" },
  { country: "Thailand" },
  { country: "Togo" },
  { country: "Tonga" },
  { country: "Trinidad and Tobago" },
  { country: "Tunisia" },
  { country: "Turkey" },
  { country: "Turkmenistan" },
  { country: "Tuvalu" },
  { country: "Uganda" },
  { country: "Ukraine" },
  { country: "United Arab Emirates" },
  { country: "Uruguay" },
  { country: "Uzbekistan" },
  { country: "Vanuatu" },
  { country: "Vatican City (Holy See)" },
  { country: "Venezuela" },
  { country: "Vietnam" },
  { country: "Yemen" },
  { country: "Zambia" },
  { country: "Zimbabwe" },
];

export default countriesData;
