import axios from "axios";
import "./UserRegister.css";
import { AES } from 'crypto-js';
import { toast } from "react-toastify";
import { useDispatch, useSelector } from 'react-redux';
import { DatePicker } from "rsuite";
import { subYears } from "date-fns";
import Modal from "react-modal";
import "react-toastify/dist/ReactToastify.css";
import { ThreeDots } from "react-loader-spinner";
import { RotatingLines } from 'react-loader-spinner';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Type from "../../../images/icons/type.svg";
import Cost from "../../../images/icons/cost1.svg";
import Clock from "../../../images/icons/timer.svg";
import Tele from "../../../images/icons/tele.svg";
import { Link, useNavigate } from "react-router-dom";
import Countries from "../../../constants/countries.js";
import userTypeName from "../../../constants/userType.js";
import EmailIcon from "../../../images/icons/email.svg";
import PassLock from "../../../images/icons/pass_lock.svg";
import Tick from "../../../images/icons/tick-arrow.svg";
import Company from "../../../images/icons/company.svg";
import Payment from "../../../images/icons/payment.svg";
import location from "../../../images/icons/location.svg";
import JobTitle from "../../../images/icons/job-title.svg";
import React, { useEffect, useRef, useState, createRef } from "react";
import Currencies from "../../../constants/currenciesList.js";
import typeOfContract from "../../../constants/typeOfContract.js";
import overHeadCostTypeConst from "../../../constants/overHeadCostType.js";
import userCategoryName from "../../../constants/userCategory.js";
import PostalCode from "../../../images/icons/postal-code.svg";
import PassportlIcon from "../../../images/icons/passport.svg";
import birthIcon from "../../../images/icons/dat-of-birth.svg";
import PicEdit from "../../../images/icons/profile-pic-edit.svg";
import profile_image from "../../../images/user/Default_pfp.png";
import { logout, reset } from "../../../features/auth/authSlice.js";
import UserIcon from "../../../images/icons/single-user-icon.svg";
import TypeContact from "../../../images/icons/type-of-contact.svg";
import EyeIcon from "../../../images/icons/eye-icon.svg";
import EyeCrossed from "../../../images/icons/eye-crossed.svg";
import { reset as invoiceReset } from "../../../features/invoice/invoiceSlice.js";
import { setNotification } from "../../../features/notification/notificationSlice.js";
import { reset as headerReset } from "../../../features/headerText/headerTextSlice.js";
import { reset as appSettingsReset } from "../../../features/appSettings/appSettingsSlice.js";
import { useContractTypes, useOverheadCostTypes, useUserDepartments, useUserType, useWXCompanies } from "../UserDetails/UserProfile/useUserView.jsx";

export default function UserRegister() {
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const [userCategory, setUserCategory] = useState(userCategoryName.Individual);
  const [userType, setUserType] = useState("");
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyFirstName, setCompanyFirstName] = useState("");
  const [companyLastName, setCompanyLastName] = useState("");
  const [wxComapny, setWxComapny] = useState("");
  const [dept, setDept] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [contract, setContract] = useState("");
  const [overheadCostType, setOverheadCostType] = useState("65dd73c74c4587f420433d5d");
  const [currency, setCurrency] = useState("");
  const [cost, setCost] = useState("");
  const [overheadCost, setOverheadCost] = useState("");
  const [totalHours, setTotalHours] = useState(168);
  const [vat, setVat] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [profileImage, setProfileImage] = useState(null);
  const [profileImageName, setProfileImageName] = useState(null);
  const [dob, setDob] = useState("");
  const [passport, setPassport] = useState("");
  const [registrationNumber, setRegistrationNumber] = useState("");
  const [vatId, setVatId] = useState("");
  const [registrationDocument, setRegistrationDocument] = useState(null);
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [postCode, setPostCode] = useState("");
  const [address, setAddress] = useState("");
  const [uploadedImageUrl, setUploadedImageUrl] = useState("");
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [image, setImage] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [fileSelectIsLoading, setFileSelectIsLoading] = useState(false);
  const [userTypeDropdownOpen, setUserTypeDropdownOpen] = useState(false);
  const [deptDropdownOpen, setDeptDropdownOpen] = useState(false);
  const [wxComapnyDropdownOpen, setWxComapnyDropdownOpen] = useState(false);
  const [contractDropdownOpen, setContractDropdownOpen] = useState(false);
  const [overheadCostTypeDropdownOpen, setOverheadCostTypeDropdownOpen] = useState(false);
  const [currencySearchTerm, setCurrencySearchTerm] = useState('');
  const [currencyDropdownOpen, setCurrencyDropdownOpen] = useState(false);
  const [countrySearchTerm, setCountrySearchTerm] = useState('');
  const [countryDropdownOpen, setCountryDropdownOpen] = useState(false);
  const [stateSearchTerm, setStateSearchTerm] = useState('');
  const [stateDropdownOpen, setStateDropdownOpen] = useState(false);
  const cropperRef = createRef();
  const [currencyList, setCurrencyList] = useState([]);
  const [countriesList, setCountriesList] = useState([]);
  const [passwordMatchError, setPasswordMatchError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const passwordConfirmationRef = useRef(null);
  const passwordRef = useRef(null);
  const userTypeDropdownRef = useRef(null);
  const deptDropdownRef = useRef(null);
  const wxComapnyDropdownRef = useRef(null);
  const contractDropdownRef = useRef(null);
  const overheadCostTypeDropdownRef = useRef(null);
  const currencyDropdownRef = useRef(null);
  const countryDropdownRef = useRef(null);
  const stateDropdownRef = useRef(null);
  const navigate = useNavigate();
  const { appSettings } = useSelector((state) => state.appSettings);
  const url = new URL(window.location.href); // Get the current URL
  const subdomain = url.hostname.split('.')[0];
  const capitalizedSubdomain = subdomain.charAt(0).toUpperCase() + subdomain.slice(1);
  const SUPER_ADMIN = 1;
  const ADMIN = 2;
  let _id = null;
  const user = localStorage.getItem("user");
  const userData = useSelector((state) => state.auth.user);
  if (user) {
    const parsedUser = JSON.parse(user);
    if (parsedUser && parsedUser.token && parsedUser._id) {
      _id = parsedUser._id;
    }
  }

  const { data: typeOverheadCost = [], isLoading: overheadCostTypesLoading, isError: overheadCostTypesError } = useOverheadCostTypes();
  const { data: roleId = [], isLoading: userTypeLoading, isError: userTypeError } = useUserType();
  const { data: wXCompany = [], isLoading: WXCompaniesLoading, isError: WXCompaniesError } = useWXCompanies();
  const { data: userDept = [], isLoading: userDepartmentsLoading, isError: userDepartmentsError } = useUserDepartments();
  const { data: typeContract = [], isLoading: contractTypesLoading, isError: contractTypesError } = useContractTypes();

  useEffect(() => {
    setCurrencyList(Currencies.sourceCurrencies[0].targetCurrencies);
    const sortedCountries = Countries.slice().sort((a, b) => a.country.localeCompare(b.country));
    setCountriesList(sortedCountries);
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        document.activeElement.blur();
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (wXCompany.length === 1 && !wxComapny) {
      setWxComapny(wXCompany[0]._id);
    }
  }, [wXCompany]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitLoading(true);
    const mobileNoRegex = /^[+\d]+$/;
    if (!mobileNoRegex.test(mobileNo)) {
      toast.error("It's not a valid phone number", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setIsSubmitLoading(false);
      return;
    }
    const costRegex = /^\d+(\.\d{1,2})?$/;
    if (!costRegex.test(cost)) {
      toast.error("Cost should contain numbers only (including decimals)", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setIsSubmitLoading(false);
      return;
    }
    if (!costRegex.test(overheadCost)) {
      toast.error("OverHeadCost should contain numbers only", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    const vatRegex = /^\d+|%$/;
    if (!vatRegex.test(vat)) {
      toast.error("VAT should contain numbers or '%' symbol only", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setIsSubmitLoading(false);
      return;
    }

    if (password !== passwordConfirmation) {
      setPasswordMatchError("Passwords do not match");
      passwordConfirmationRef.current.focus();
      setIsSubmitLoading(false);
      return;
    }
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#\$%\^&\*\(\)_\+\-\.\/:;<=>?@[\\\]^_`{|}~])[A-Za-z\d!@#\$%\^&\*\(\)_\+\-\.\/:;<=>?@[\\\]^_`{|}~]{8,}$/;
    if (!passwordRegex.test(password)) {
      setPasswordError("Invalid password format");
      toast.error(
        "Password must be at least 8 charachters long and include atleast one uppercase letter ,one lowercase letter,one digit and one special character from the set !@#$%^&*()_+,-/:;<=>?@_'[\]{|}~", {
        position: "bottom-right",
        autoClose: 3800,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      passwordRef.current.focus();
      setIsSubmitLoading(false);
      return;
    }

    const fileExtension = profileImageName
      ? profileImageName.name.split(".").pop()
      : "";
    const uniqueSuffix = Date.now() + "-" + Math.round(Math.random() * 1e9);
    const profile_img_fileName = profileImageName
      ? `${profileImageName.name
        .split(".")
        .shift()}_${uniqueSuffix}.${fileExtension}`
      : null;

    if (lastName) {

      if (lastName.length <= 1) {

        toast.error("A last name should have more than one character.", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setIsSubmitLoading(false);
        return;
      }
    }

    const requestData = {
      first_name: [userCategoryName.Business, userCategoryName.Vendor, userCategoryName.Client].includes(userCategory) ? "" : firstName,
      middle_name: [userCategoryName.Business, userCategoryName.Vendor, userCategoryName.Client].includes(userCategory) ? "" : middleName,
      last_name: [userCategoryName.Business, userCategoryName.Vendor, userCategoryName.Client].includes(userCategory) ? "" : lastName,
      user_wx_comapny: wxComapny,
      email,
      mobile_no: mobileNo,
      passport_no: passport,
      password,
      role_id: userType,
      user_category: userCategory,
      department: dept,
      typeOfContract: contract,
      overHeadCostType: overheadCostType,
      job_title: jobTitle,
      payment_currency: currency,
      cost,
      overhead_cost: overheadCost,
      vat,
      address,
      pincode: postCode,
      dob,
      country,
      state,
      city,
      totalHours,
      company_name: [userCategoryName.Business, userCategoryName.Vendor, userCategoryName.Client].includes(userCategory) ? companyName : "",
      company_first_name: [userCategoryName.Business, userCategoryName.Vendor, userCategoryName.Client].includes(userCategory) ? companyFirstName : "",
      company_last_name: [userCategoryName.Business, userCategoryName.Vendor, userCategoryName.Client].includes(userCategory) ? companyLastName : "",
      vat_number: [userCategoryName.Business, userCategoryName.Vendor, userCategoryName.Client].includes(userCategory) ? vatId : "",
      register_number: [userCategoryName.Business, userCategoryName.Vendor, userCategoryName.Client].includes(userCategory) ? registrationNumber : "",
      profile_image: profile_img_fileName,
      created_by: _id,
      appId: userData.appId,
    };

    // Create a new FormData object
    const formData = new FormData();
    // Append the profile image to the form data
    formData.append("company_document", registrationDocument);

    // Append the other request data to the form data
    for (let key in requestData) {
      formData.append(key, requestData[key]);
    }

    try {
      const response = await axios.post("/api/user", formData);
      const data = response.data;
      if (data.status === "exists") {
        toast.warning("User with this mail id already exists", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (data.status === "error") {
        toast.error("Something went wrong", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.success("User registered successfully", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        global.config.activityLog(
          window.location.href,
          "User",
          `New User "${userCategory && [userCategoryName.Business, userCategoryName.Vendor, userCategoryName.Client].includes(userCategory) ? companyName : firstName} " registered Successfully!`
        );
        dispatch(setNotification(Date.now()));
        const encryptedId = AES.encrypt(data._id, 'encryptionKey').toString();
        const encodedId = encodeURIComponent(encryptedId).replace(/\//g, '%2F');
        navigate(`../profile/${encodedId}`);
      }

      if (profileImage) {
        const resizedImage = await resizeImage(profileImage, 500);
        const base64Image = resizedImage.split(',')[1];
        const blob = await fetch(`data:image/png;base64,${base64Image}`).then(res => res.blob());

        const formData = new FormData();
        formData.append("profile_image", blob, profile_img_fileName);

        try {
          await axios.post("/api/uploads/profileImage", formData);
          // Handle the response if needed
        } catch (error) {
          console.log(error);
          global.config.slackMessage(error.toString());
          alert("Something went wrong while uploading profile photo");
        }
      }
    } catch (error) {
      console.log(error);
      global.config.slackMessage(error.toString());
      if (error.response?.data?.status === "deleted") {
        localStorage.removeItem("user");
        dispatch(logout());
        dispatch(reset());
        dispatch(invoiceReset());
        dispatch(headerReset());
        dispatch(appSettingsReset());
        toast.error('This user is deleted!', {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/");
        return;
      }
      toast.error('Something went wrong while registering user!', {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } finally {
      setIsSubmitLoading(false);
    }
  };

  const handleProfilePicChange = () => {
    setFileSelectIsLoading(true);
    // Open file input dialog
    fileInputRef.current.click();
  };

  const handleFileInputCancel = () => {
    // Handle the case where the file selection window is canceled
    setFileSelectIsLoading(false);
  };

  // Attach event listeners to the file input
  useEffect(() => {
    const inputElement = fileInputRef.current;

    const handleChange = () => {
      handleFileInputChange({ target: { files: inputElement.files } });
    };

    const handleCancel = () => {
      handleFileInputCancel();
    };

    inputElement.addEventListener('change', handleChange);
    inputElement.addEventListener('cancel', handleCancel);

    return () => {
      inputElement.removeEventListener('change', handleChange);
      inputElement.removeEventListener('cancel', handleCancel);
    };
  }, []);

  const handleFileInputChange = (event) => {
    setFileSelectIsLoading(false);
    const file = event.target.files[0];
    setProfileImageName(event.target.files[0])
    if (file) {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        setImage(fileReader.result);
        setModalIsOpen(true);
      };
      fileReader.readAsDataURL(file);
    }
  };

  const closeModal = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    setFileSelectIsLoading(false);
    setModalIsOpen(false);
  };

  const handleCropClick = () => {
    if (typeof cropperRef.current?.cropper !== "undefined") {
      const croppedImage = (cropperRef.current?.cropper.getCroppedCanvas().toDataURL());
      setUploadedImageUrl(croppedImage);
      setProfileImage(croppedImage);
    }
    closeModal();
  };

  const handleCurrencySearchInputChange = (e) => {
    setCurrencySearchTerm(e.target.value);
  };

  const filteredCurrencies = currencyList.filter((currency) => {
    const currencyName = currency.currencyCode
      && `${currency.currencyCode} (${currency.currencyName})`;
    return currencyName.toLowerCase().includes(currencySearchTerm.toLowerCase());
  });

  const handleCountrySearchInputChange = (e) => {
    setCountrySearchTerm(e.target.value);
  };

  const filteredCountries = countriesList.filter((country) => {
    return country.country.toLowerCase().includes(countrySearchTerm.toLowerCase());
  });

  const handleStateSearchInputChange = (e) => {
    setStateSearchTerm(e.target.value);
  };

  const selectedCountry = countriesList.find((c) => c.country === country);
  const filteredStates = selectedCountry?.states?.filter((stateObj) =>
    stateObj.state.toLowerCase().includes(stateSearchTerm.toLowerCase())
  );

  const handleClickOutsideDropdown = (event) => {
    const isClickInsideUserTypeDropdown = userTypeDropdownRef.current && userTypeDropdownRef.current.contains(event.target);
    const isClickInsideDeptDropdown = deptDropdownRef.current && deptDropdownRef.current.contains(event.target);
    const isClickInsideWXComapnyDropdown = wxComapnyDropdownRef.current && wxComapnyDropdownRef.current.contains(event.target);
    const isClickInsideContractDropdown = contractDropdownRef.current && contractDropdownRef.current.contains(event.target);
    const isClickInsideOverheadCostTypeDropdown = overheadCostTypeDropdownRef.current && overheadCostTypeDropdownRef.current.contains(event.target);
    const isClickInsideCurrencyDropdown = currencyDropdownRef.current && currencyDropdownRef.current.contains(event.target);
    const isClickInsideCountryDropdown = countryDropdownRef.current && countryDropdownRef.current.contains(event.target);
    const isClickInsideStateDropdown = stateDropdownRef.current && stateDropdownRef.current.contains(event.target);
    if (!isClickInsideUserTypeDropdown) {
      setUserTypeDropdownOpen(false);
    }
    if (!isClickInsideDeptDropdown) {
      setDeptDropdownOpen(false);
    }
    if (!isClickInsideWXComapnyDropdown) {
      setWxComapnyDropdownOpen(false);
    }
    if (!isClickInsideContractDropdown) {
      setContractDropdownOpen(false);
    }
    if (!isClickInsideOverheadCostTypeDropdown) {
      setOverheadCostTypeDropdownOpen(false);
    }
    if (!isClickInsideCurrencyDropdown) {
      setCurrencyDropdownOpen(false);
    }
    if (!isClickInsideCountryDropdown) {
      setCountryDropdownOpen(false);
    }
    if (!isClickInsideStateDropdown) {
      setStateDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutsideDropdown);
    return () => {
      document.removeEventListener('click', handleClickOutsideDropdown);
    };
  }, []);

  // Function to resize the image
  const resizeImage = async (base64Image, maxWidth) => {
    return new Promise((resolve) => {
      const image = new Image();
      image.src = base64Image;

      image.onload = () => {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        const aspectRatio = image.width / image.height;
        const newHeight = maxWidth / aspectRatio;

        canvas.width = maxWidth;
        canvas.height = newHeight;

        context.drawImage(image, 0, 0, maxWidth, newHeight);

        const resizedImage = canvas.toDataURL('image/jpeg'); // Change to 'image/png' if needed
        resolve(resizedImage);
      };
    });
  };

  const allowedExtensions = ["pdf", "doc", "docx", "msword", "xls", "xlsx", "csv"];
  const handleRegistrationDocChange = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      const fileExtension = selectedFile.name.split(".").pop().toLowerCase();
      if (allowedExtensions.includes(fileExtension)) {
        setRegistrationDocument(selectedFile);
      } else {
        toast.error("Invalid file format. Allowed formats are pdf, doc, docx, msword, xls, xlsx, csv.!!", {
          position: "bottom-right",
          autoClose: 3500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        // Optionally, you can clear the input
        event.target.value = null;
      }
    }
  };
  const countryData = countriesList.find((c) => c.country === country);
  const states = countryData?.states || [];

  const numberInputOnWheelPreventChange = (e) => {
    // Prevent the input value change
    e.target.blur()

    // Prevent the page/container scrolling
    e.stopPropagation()

    setTimeout(() => {
      e.target.focus()
    }, 0)
  }

  return (
    <>
      <div className="user-register-client">
        <div className='back-to'>
          <Link to="/user/myteam/list">
            <span >
              Back to All Members
            </span>
          </Link>
        </div>
        <div className='tab-outer'>
          <ul className="tabs">
            <li className='active-tab'>
              Register New User
            </li>
          </ul>
          <div className="tabs-content">
            <div className="profile-details-edit" >
              <form onSubmit={handleSubmit}>
                <div className="profile-photo-outer">
                  <div className="profile-photo">
                    <div className="profile-photo-inner">
                      <div className="profile-photo-sec">
                        {uploadedImageUrl ? (
                          <div className="profile-image">
                            <img src={uploadedImageUrl} alt="Uploaded Profile" />
                          </div>
                        ) : (
                          <div className="profile-image">
                            <img src={profile_image} alt="User Profile" />
                          </div>
                        )}
                        <div className="profile-pic-edit">
                          <a href="#" className="pic-edit" onClick={!fileSelectIsLoading && handleProfilePicChange}>
                            {fileSelectIsLoading ? (
                              <RotatingLines
                                strokeColor="grey"
                                strokeWidth="5"
                                animationDuration="0.75"
                                width="18"
                                visible={true}
                              />
                            ) : (
                              <img src={PicEdit} alt="edit" />
                            )}
                          </a>
                        </div>
                      </div>
                      <div className="profile-name">
                        <div className="profile-name-sec">
                          {firstName ? (
                            <>
                              {firstName ? firstName : "First Name"}{" "}
                              {middleName ? middleName : ""}{" "}
                              {lastName ? lastName : ""}{" "}
                            </>
                          ) : (
                            <>
                              {companyName ? companyName : "Name"}
                            </>
                          )}
                        </div>
                        {userType ? (
                          userType === userTypeName.Super_Admin ? (
                            <div className="profile-type">
                              Super Admin
                            </div>
                          ) : userType === userTypeName.Admin ? (
                            <div className="profile-type">
                              Admin
                            </div>
                          ) : userType === userTypeName.User ? (
                            <div className="profile-type">
                              User
                            </div>
                          ) : userType === userTypeName.Project_Manager ? (
                            <div className="profile-type">
                              Project Manager
                            </div>
                          ) : userType === userTypeName.Team_Manager ? (
                            <div className="profile-type">
                              Team Manager
                            </div>
                          )
                            : null
                        ) : (
                          <div className="profile-type">
                            ROLE
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <input
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  ref={fileInputRef}
                />
                <Modal
                  isOpen={modalIsOpen}
                  contentLabel="Image Crop Modal"
                  style={{
                    content: {
                      height: "500%",
                      width: "1000%",
                      position: "fixed",
                      top: "20%",
                      left: "50%",
                      transform: "translate(-17.5%, 0%)",
                      parent: document.querySelector(".admin-outer.time.tracker"),
                    },
                  }}
                >
                  <div>
                    {image && (
                      <Cropper
                        ref={cropperRef}
                        style={{ height: 400, width: "100%" }}
                        zoomTo={0}
                        initialAspectRatio={1}
                        preview=".img-preview"
                        src={image}
                        viewMode={1}
                        minCropBoxHeight={10}
                        minCropBoxWidth={10}
                        background={false}
                        responsive={true}
                        autoCropArea={100}
                        checkOrientation={false}
                        guides={true}
                      />
                    )}
                  </div>
                  <div>
                    <div className="crop-btn">
                      <button onClick={handleCropClick}>Crop and Upload</button>
                      <button className="delete-client" onClick={closeModal}>Cancel</button>
                    </div>
                  </div>
                </Modal>
                <div className="information-outer-section">
                  <div className="information-inner">
                    <div className="information">
                      <div className="information-title">
                        Personal Information
                      </div>
                      <div className="information-outer">
                        <div className="information-left">
                          <div className="inner-detail-outer">
                            <div className="inner-detail">
                              <span className="icon"><img src={Type} alt="category" /></span>
                              <div className="inner-detail-inner">
                                <label>User Category *</label>
                              </div>
                            </div>
                            <div className="inner-detail">
                              <span className="icon"></span>
                              <div className="inner-detail-inner detail-radio">
                                {Object.keys(userCategoryName)
                                  .filter((key) => key !== 'Vendor' && key !== 'Client')
                                  .map((key) => (
                                    <div key={userCategoryName[key]} className="radio-option radio-option1">
                                      <input
                                        type="radio"
                                        id={userCategoryName[key]}
                                        value={userCategoryName[key]}
                                        checked={userCategory === userCategoryName[key]}
                                        onChange={(e) => setUserCategory(userCategoryName[key])}
                                        required
                                      />
                                      <label htmlFor={userCategoryName[key]}>{key}</label>
                                    </div>
                                  ))}
                              </div>
                            </div>
                          </div>
                          <div className="inner-detail-outer">
                            <div className="inner-detail">
                              <span class="icon"><img src={Type} alt="type" /></span>
                              <div className="inner-detail-inner">
                                <label>Type of Account *</label>
                              </div>
                            </div>
                            <div className="inner-detail">
                              <span class="icon"></span>
                              <div className="inner-detail-inner">
                                <div className={`custom-dropdown-user-new-outer custom-dropdown-select ${userTypeDropdownOpen ? 'open' : ''}`} ref={userTypeDropdownRef}>
                                  <div className="selected-option custom-dropdown-user-new" onClick={() => setUserTypeDropdownOpen(!userTypeDropdownOpen)}>
                                    {userType ? roleId.find((role) => role._id === userType)?.user_type : 'Select Role'}
                                  </div>
                                  {userTypeDropdownOpen && (
                                    <div className="custom-dropdown-new">
                                      <ul className="role-list">
                                        {roleId
                                          .filter((role) => !(userData.userType === ADMIN && role._id === userTypeName.Super_Admin))
                                          .map((role) => (
                                            <li
                                              key={role._id}
                                              className={userType === role._id ? 'selected-item' : ''}
                                              onClick={() => {
                                                setUserType(role._id);
                                                setUserTypeDropdownOpen(false);
                                              }}
                                            >
                                              {role.user_type}
                                            </li>
                                          ))}
                                      </ul>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          {userCategory === "" ||
                            userCategory === userCategoryName.Individual ? (
                            <div>
                              <div className="inner-detail-outer">
                                <div className="inner-detail">
                                  <span class="icon"><img src={UserIcon} alt="user icon" /></span>
                                  <div className="inner-detail-inner">
                                    <label for="fname">First Name *</label>
                                  </div>
                                </div>
                                <div className="inner-detail">
                                  <span class="icon"></span>
                                  <div className="inner-detail-inner">
                                    <input
                                      type="text"
                                      placeholder="Enter First name"
                                      value={firstName}
                                      onChange={(e) => setFirstName(e.target.value)}
                                      readOnly={userType === ""}
                                      required
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="inner-detail-outer">
                                <div className="inner-detail">
                                  <span class="icon"><img src={UserIcon} alt="user icon" /></span>
                                  <div className="inner-detail-inner">
                                    <label for="mname">Middle Name</label>
                                  </div>
                                </div>
                                <div className="inner-detail">
                                  <span class="icon"></span>
                                  <div className="inner-detail-inner">
                                    <input
                                      type="text"
                                      placeholder="Enter Middle name"
                                      value={middleName}
                                      readOnly={userType === ""}
                                      onChange={(e) => setMiddleName(e.target.value)}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="inner-detail-outer">
                                <div className="inner-detail">
                                  <span class="icon"><img src={UserIcon} alt="user icon" /></span>
                                  <div className="inner-detail-inner">
                                    <label for="lname">Last Name *</label>
                                  </div>
                                </div>
                                <div className="inner-detail">
                                  <span class="icon"></span>
                                  <div className="inner-detail-inner">
                                    <input
                                      type="text"
                                      placeholder="Enter Last name"
                                      value={lastName}
                                      readOnly={userType === ""}
                                      onChange={(e) => setLastName(e.target.value)}
                                      required
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : null}

                          {(userCategory === userCategoryName.Business ||
                            userCategory === userCategoryName.Vendor ||
                            userCategory === userCategoryName.Client) && (
                              <div>
                                <div className="inner-detail-outer">
                                  <div className="inner-detail">
                                    <span class="icon"><img src={UserIcon} alt="user icon" /></span>
                                    <div className="inner-detail-inner">
                                      <label for="compname">Company Name *</label>
                                    </div>
                                  </div>
                                  <div className="inner-detail">
                                    <span class="icon"></span>
                                    <div className="inner-detail-inner">
                                      <input
                                        type="text"
                                        placeholder="Company name"
                                        value={companyName}
                                        onChange={(e) => setCompanyName(e.target.value)}
                                        readOnly={userType === ""}
                                        required
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="inner-detail-outer">
                                  <div className="inner-detail">
                                    <span class="icon"><img src={UserIcon} alt="user icon" /></span>
                                    <div className="inner-detail-inner">
                                      <label for="compfirstname">First Name *</label>
                                    </div>
                                  </div>
                                  <div className="inner-detail">
                                    <span class="icon"></span>
                                    <div className="inner-detail-inner">
                                      <input
                                        type="text"
                                        placeholder="First Name"
                                        value={companyFirstName}
                                        onChange={(e) => setCompanyFirstName(e.target.value)}
                                        readOnly={userType === ""}
                                        required
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="inner-detail-outer">
                                  <div className="inner-detail">
                                    <span class="icon"><img src={UserIcon} alt="user icon" /></span>
                                    <div className="inner-detail-inner">
                                      <label for="complastname">Last Name</label>
                                    </div>
                                  </div>
                                  <div className="inner-detail">
                                    <span class="icon"></span>
                                    <div className="inner-detail-inner">
                                      <input
                                        type="text"
                                        placeholder="Last name"
                                        value={companyLastName}
                                        onChange={(e) => setCompanyLastName(e.target.value)}
                                        readOnly={userType === ""}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="inner-detail-outer">
                                  <div className="inner-detail">
                                    <span class="icon"><img src={UserIcon} alt="user icon" /></span>
                                    <div className="inner-detail-inner">
                                      <label for="regnum">Registration Number *</label>
                                    </div>
                                  </div>
                                  <div className="inner-detail">
                                    <span class="icon"></span>
                                    <div className="inner-detail-inner">
                                      <input
                                        type="text"
                                        placeholder="Registration number"
                                        value={registrationNumber}
                                        onChange={(e) =>
                                          setRegistrationNumber(e.target.value)
                                        }
                                        readOnly={userType === ""}
                                        required
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                        </div>
                        <div className="information-right">
                          <div className="inner-detail-outer">
                            <div className="inner-detail">
                              <span class="icon"><img src={EmailIcon} alt="user icon" /></span>
                              <div className="inner-detail-inner">
                                <label for="email">Email *</label>
                              </div>
                            </div>
                            <div className="inner-detail">
                              <span class="icon"></span>
                              <div className="inner-detail-inner">
                                <input
                                  type="email"
                                  placeholder="Email"
                                  value={email}
                                  onChange={(e) => setEmail(e.target.value)}
                                  readOnly={userType === ""}
                                  required
                                />
                              </div>
                            </div>
                          </div>
                          <div className="inner-detail-outer">
                            <div className="inner-detail">
                              <span class="icon"><img src={PassLock} alt="user icon" /></span>
                              <div className="inner-detail-inner">
                                <label for="password">Password *</label>
                              </div>
                            </div>
                            <div className="inner-detail">
                              <span class="icon"></span>
                              <div className="inner-detail-inner pass-eye">
                                <input
                                  type={showPassword ? "text" : "password"}
                                  placeholder="Password"
                                  value={password}
                                  onChange={(e) => setPassword(e.target.value)}
                                  ref={passwordRef}
                                  readOnly={userType === ""}
                                  required
                                />
                                <img
                                  src={showPassword ? EyeCrossed : EyeIcon}
                                  className="password-toggle-icon"
                                  alt="Toggle Password"
                                  onClick={() => setShowPassword(!showPassword)}
                                />
                              </div>
                              {passwordError && (
                                <span>{passwordError}</span>
                              )}
                            </div>
                          </div>
                          <div className="inner-detail-outer">
                            <div className="inner-detail">
                              <span class="icon"><img src={PassLock} alt="user icon" /></span>
                              <div className="inner-detail-inner">
                                <label for="confpassword">Confirm Password *</label>
                              </div>
                            </div>
                            <div className="inner-detail">
                              <span class="icon"></span>
                              <div className="inner-detail-inner">
                                <input
                                  type="password"
                                  placeholder="Confirm Password"
                                  value={passwordConfirmation}
                                  onChange={(e) => setPasswordConfirmation(e.target.value)}
                                  ref={passwordConfirmationRef}
                                  readOnly={userType === ""}
                                  required
                                />
                                {passwordMatchError && (
                                  <span style={{ color: "red" }}> {passwordMatchError} </span>
                                )}
                              </div>
                            </div>
                          </div>
                          {/* {userCategory === "" ||
                            userCategory === userCategoryName.Individual ? ( */}
                          <div>
                            <div className="inner-detail-outer">
                              <div className="inner-detail">
                                <span class="icon"><img src={birthIcon} alt="user icon" /></span>
                                <div className="inner-detail-inner">
                                  <label>Date of birth *</label>
                                </div>
                              </div>
                              <div className="inner-detail">
                                <span class="icon"></span>
                                <div className="inner-detail-inner">
                                  <DatePicker
                                    oneTap
                                    className={!userType ? 'datePickerReadOnly' : ''}
                                    selected={dob ? new Date(dob) : null}
                                    onChange={(date) => setDob(date)}
                                    readOnly={userType === ""}
                                    required
                                    format="dd/MM/yyyy"
                                    minDate={subYears(new Date(), 15)}
                                    timeZone={global.config.getTimeZone()}
                                    isoWeek={appSettings.weekStartDay === 'Monday'}

                                  />
                                </div>
                              </div>
                            </div>
                            <div className="inner-detail-outer">
                              <div className="inner-detail">
                                <span class="icon"><img src={PassportlIcon} alt="user icon" /></span>
                                <div className="inner-detail-inner">
                                  <label for="id_number">Passport or ID Number *</label>
                                </div>
                              </div>
                              <div className="inner-detail">
                                <span class="icon"></span>
                                <div className="inner-detail-inner">
                                  <input type="text" id="id_number" value={passport} onChange={(e) => setPassport(e.target.value)} readOnly={userType === ""} required />
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* ) : ( */}
                          {userCategory === "" ||
                            userCategory === userCategoryName.Business && (
                              <div>
                                <div className="inner-detail-outer">
                                  <div className="inner-detail">
                                    <span class="icon"><img src={UserIcon} alt="user icon" /></span>
                                    <div className="inner-detail-inner">
                                      <label for="vatid">Vat Id</label>
                                    </div>
                                  </div>
                                  <div className="inner-detail">
                                    <span class="icon"></span>
                                    <div className="inner-detail-inner">
                                      <input
                                        type="text"
                                        placeholder="VAT ID"
                                        value={vatId}
                                        onChange={(e) => setVatId(e.target.value)}
                                        readOnly={userType === ""}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="inner-detail-outer">
                                  <div className="inner-detail">
                                    <span class="icon"><img src={TypeContact} alt="user icon" /></span>
                                    <div className="inner-detail-inner">
                                      <label for="compregdoc">Company Registration Document *</label>
                                    </div>
                                  </div>
                                  <div className="inner-detail">
                                    <span class="icon"></span>
                                    <div className="inner-detail-inner">
                                      <input
                                        type="file"
                                        onChange={handleRegistrationDocChange}
                                        readOnly={userType === ""}
                                        required
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="information-inner">
                    <div className="information">
                      <div className="information-title">
                        Account Information
                      </div>
                      <div className="information-outer">
                        <div className="information-left">
                          <div className="inner-detail-outer">
                            <div className="inner-detail">
                              <span class="icon"><img src={Type} alt="user icon" /></span>
                              <div className="inner-detail-inner">
                                <label>Department *</label>
                              </div>
                            </div>
                            <div className="inner-detail">
                              <span class="icon"></span>
                              <div className="inner-detail-inner">
                                <div className={`custom-dropdown-user-new-outer custom-dropdown-select ${deptDropdownOpen ? 'open' : ''}`} ref={deptDropdownRef}>
                                  <div className="selected-option custom-dropdown-user-new" onClick={userType ? () => setDeptDropdownOpen(!deptDropdownOpen) : null} style={{ cursor: userType ? 'pointer' : 'no-drop', background: userType ? '#fff' : '#fafafa', color: userType ? '#282828' : '#a6a6a6' }}>
                                    {dept ? userDept.find((department) => department._id === dept)?.user_department : 'Select Dept'}
                                  </div>
                                  {userType && deptDropdownOpen && (
                                    <div className="custom-dropdown-new">
                                      <ul className="dept-list">
                                        {userDept
                                          .slice()
                                          .sort((a, b) => a.user_department.toLowerCase().localeCompare(b.user_department.toLowerCase()))
                                          .map((department) => (
                                            <li
                                              key={department._id}
                                              className={dept === department._id ? 'selected-item' : ''}
                                              onClick={() => {
                                                setDept(department._id);
                                                setDeptDropdownOpen(false);
                                              }}
                                            >
                                              {department.user_department}
                                            </li>
                                          ))}
                                      </ul>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="inner-detail-outer">
                            <div className="inner-detail">
                              <span class="icon"><img src={JobTitle} alt="user icon" /></span>
                              <div className="inner-detail-inner">
                                <label>Job Title *</label>
                              </div>
                            </div>
                            <div className="inner-detail">
                              <span class="icon"></span>
                              <div className="inner-detail-inner">
                                <input
                                  type="text"
                                  placeholder="Enter Job Title"
                                  value={jobTitle}
                                  onChange={(e) => setJobTitle(e.target.value)}
                                  readOnly={userType === ""}
                                  required
                                />
                              </div>
                            </div>
                          </div>
                          <div className="inner-detail-outer">
                            <div className="inner-detail">
                              <span class="icon"><img src={Company} alt="user icon" /></span>
                              <div className="inner-detail-inner">
                                <label>{`${capitalizedSubdomain} Company *`}</label>
                              </div>
                            </div>
                            <div className="inner-detail">
                              <span class="icon"></span>
                              <div className="inner-detail-inner">
                                <div className={`custom-dropdown-user-new-outer custom-dropdown-select ${wxComapnyDropdownOpen ? 'open' : ''}`} ref={wxComapnyDropdownRef}>
                                  <div className="selected-option custom-dropdown-user-new" onClick={userType ? () => setWxComapnyDropdownOpen(!wxComapnyDropdownOpen) : null} style={{ cursor: userType ? 'pointer' : 'no-drop', background: userType ? '#fff' : '#fafafa', color: userType ? '#282828' : '#a6a6a6' }}>
                                    {wxComapny ? wXCompany.find((company) => company._id === wxComapny)?.wx_company : `Select ${capitalizedSubdomain} Company`}
                                  </div>
                                  {userType && wxComapnyDropdownOpen && (
                                    <div className="custom-dropdown-new">
                                      <ul className="company-list">
                                        {wXCompany.map((company) => (
                                          <li
                                            key={company._id}
                                            className={wxComapny === company._id ? 'selected-item' : ''}
                                            onClick={() => {
                                              setWxComapny(company._id);
                                              setWxComapnyDropdownOpen(false);
                                            }}
                                          >
                                            {company.wx_company}
                                          </li>
                                        ))}
                                      </ul>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="inner-detail-outer">
                            <div className="inner-detail">
                              <span class="icon"><img src={TypeContact} alt="user icon" /></span>
                              <div className="inner-detail-inner">
                                <label>Overhead Cost Variable *</label>
                              </div>
                            </div>
                            <div className="inner-detail">
                              <span class="icon"></span>
                              <div className="inner-detail-inner">

                                <input
                                  type="text"
                                  placeholder="Enter Job Title"
                                  className="form-input read-only-input"
                                  value={overheadCostType ? typeOverheadCost.find((item) => item._id === overheadCostType)?.overheadCostType : 'Select Type of Contract'}
                                  readOnly
                                  required
                                />

                                {/* <div className={`custom-dropdown-user-new-outer custom-dropdown-select ${overheadCostTypeDropdownOpen ? 'open' : ''}`} ref={overheadCostTypeDropdownRef}>
                                  <div className="selected-option custom-dropdown-user-new" onClick={userType ? () => setOverheadCostTypeDropdownOpen(!overheadCostTypeDropdownOpen) : null} style={{ cursor: userType ? 'pointer' : 'no-drop', background: userType ? '#fff' : '#fafafa', color: userType ? '#282828' : '#a6a6a6' }}>
                                    {overheadCostType ? typeOverheadCost.find((item) => item._id === overheadCostType)?.overheadCostType : 'Select Type of Contract'}
                                  </div>
                                  {userType && overheadCostTypeDropdownOpen && (
                                    <div className="custom-dropdown-new">
                                      <ul className="contract-list">
                                        {typeOverheadCost.map((item) => (
                                          <li
                                            key={item._id}
                                            className={overheadCostType === item._id ? 'selected-item' : ''}
                                            onClick={userType ? () => {
                                              setOverheadCostType(item._id);
                                              setOverheadCostTypeDropdownOpen(false);
                                            } : null}
                                          >
                                            {item.overheadCostType}
                                          </li>
                                        ))}
                                      </ul>
                                    </div>
                                  )}
                                </div> */}
                              </div>
                            </div>
                          </div>
                          <div className="inner-detail-outer">
                            <div className="inner-detail">
                              <span class="icon"><img src={TypeContact} alt="user icon" /></span>
                              <div className="inner-detail-inner">
                                <label>VAT (%) *</label>
                              </div>
                            </div>
                            <div className="inner-detail">
                              <span class="icon"></span>
                              <div className="inner-detail-inner">
                                <input
                                  type="number"
                                  placeholder="Enter VAT"
                                  value={vat}
                                  onChange={(e) => setVat(e.target.value)}
                                  readOnly={userType === ""}
                                  required
                                  onWheel={numberInputOnWheelPreventChange}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="information-right">
                          <div className="inner-detail-outer">
                            <div className="inner-detail">
                              <span class="icon"><img src={TypeContact} alt="user icon" /></span>
                              <div className="inner-detail-inner">
                                <label>Type of contract *</label>
                              </div>
                            </div>
                            <div className="inner-detail">
                              <span class="icon"></span>
                              <div className="inner-detail-inner">
                                <div className={`custom-dropdown-user-new-outer custom-dropdown-select ${contractDropdownOpen ? 'open' : ''}`} ref={contractDropdownRef}>
                                  <div className="selected-option custom-dropdown-user-new" onClick={userType ? () => setContractDropdownOpen(!contractDropdownOpen) : null} style={{ cursor: userType ? 'pointer' : 'no-drop', background: userType ? '#fff' : '#fafafa', color: userType ? '#282828' : '#a6a6a6' }}>
                                    {contract ? typeContract.find((item) => item._id === contract)?.typeOfContract : 'Select Type of Contract'}
                                  </div>
                                  {userType && contractDropdownOpen && (
                                    <div className="custom-dropdown-new">
                                      <ul className="contract-list">
                                        {typeContract.map((item) => (
                                          <li
                                            key={item._id}
                                            className={contract === item._id ? 'selected-item' : ''}
                                            onClick={userType ? () => {
                                              setContract(item._id);
                                              setContractDropdownOpen(false);
                                            } : null}
                                          >
                                            {item.typeOfContract}
                                          </li>
                                        ))}
                                      </ul>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="inner-detail-outer">
                            <div className="inner-detail">
                              <span class="icon"><img src={Payment} alt="user icon" /></span>
                              <div className="inner-detail-inner">
                                <label>Payment Currency *</label>
                              </div>
                            </div>
                            <div className="inner-detail">
                              <span class="icon"></span>
                              <div className="inner-detail-inner">
                                <div className={`custom-dropdown-user-new-outer custom-dropdown-select ${currencyDropdownOpen ? 'open' : ''}`} ref={currencyDropdownRef}>
                                  <div className="selected-option custom-dropdown-user-new" onClick={userType ? () => setCurrencyDropdownOpen(!currencyDropdownOpen) : null} style={{ cursor: userType ? 'pointer' : 'no-drop', background: userType ? '#fff' : '#fafafa', color: userType ? '#282828' : '#a6a6a6' }}>
                                    {currency ? currencyList.find((c) => c.currencyCode === currency)?.currencyCode : 'Select Currency'}
                                  </div>
                                  {userType && currencyDropdownOpen && (
                                    <div className="custom-dropdown-new">
                                      <input
                                        type="text"
                                        className="search-bar"
                                        placeholder="Search..."
                                        value={currencySearchTerm}
                                        onChange={handleCurrencySearchInputChange}
                                      />
                                      <ul className="currency-list">
                                        {filteredCurrencies.length > 0 &&
                                          filteredCurrencies.map((c) => (
                                            <li
                                              key={c.currencyCode}
                                              className={currency === c.currencyCode ? 'selected-item' : ''}
                                              onClick={() => {
                                                setCurrency(c.currencyCode);
                                                setCurrencyDropdownOpen(false);
                                              }}
                                            >
                                              {`${c.currencyCode} (${c.currencyName})`}
                                            </li>
                                          ))}
                                      </ul>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="inner-detail-outer">
                            <div className="inner-detail">
                              <span class="icon"><img src={Cost} alt="user icon" /></span>
                              <div className="inner-detail-inner">
                                {contract === typeOfContract.Hourly_Rate ? (
                                  <label>
                                    Compensation per Hour {currency && `(${currency})`} *
                                  </label>
                                ) : (
                                  <label>
                                    Monthly Compensation {currency && `(${currency})`} *
                                  </label>
                                )}
                              </div>
                            </div>
                            <div className="inner-detail">
                              <span class="icon"></span>
                              <div className="inner-detail-inner">
                                <input
                                  type="number"
                                  placeholder="Enter Cost"
                                  value={cost}
                                  onChange={(e) => setCost(e.target.value)}
                                  readOnly={userType === ""}
                                  required
                                  onWheel={numberInputOnWheelPreventChange}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="inner-detail-outer">
                            <div className="inner-detail">
                              <span class="icon"><img src={Cost} alt="user icon" /></span>
                              <div className="inner-detail-inner">
                                {overheadCostType === overHeadCostTypeConst.Cost_per_Hour ? (
                                  <label>
                                    Overhead Cost per Hour {currency && `(${currency})`} *
                                  </label>
                                ) : (
                                  <label>
                                    Monthly Overhead Cost {currency && `(${currency})`} *
                                  </label>
                                )}
                              </div>
                            </div>
                            <div className="inner-detail">
                              <span class="icon"></span>
                              <div className="inner-detail-inner">
                                <input
                                  type="number"
                                  placeholder="Enter Cost"
                                  value={overheadCost}
                                  onChange={(e) => setOverheadCost(e.target.value)}
                                  readOnly={userType === ""}
                                  required
                                  onWheel={numberInputOnWheelPreventChange}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="inner-detail-outer">
                            <div className="inner-detail">
                              <span class="icon"><img src={Clock} alt="user icon" /></span>
                              <div className="inner-detail-inner">
                                <label>
                                  Total Hours *
                                </label>
                              </div>
                            </div>
                            <div className="inner-detail">
                              <span class="icon"></span>
                              <div className="inner-detail-inner">
                                <input
                                  type="number"
                                  placeholder="Total Hour"
                                  value={totalHours}
                                  onChange={(e) => setTotalHours(e.target.value)}
                                  readOnly={userType === ""}
                                  required
                                  onWheel={numberInputOnWheelPreventChange}
                                />
                              </div>
                            </div>
                          </div>


                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="information-inner">
                    <div className="information">
                      <div className="information-title">
                        Address
                      </div>
                      <div className="information-outer">
                        <div className="information-left">
                          <div className="inner-detail-outer">
                            <div className="inner-detail">
                              <span className="icon">
                                <img src={location} alt="Location Icon" />
                              </span>
                              <div className="inner-detail-inner">
                                <label>Country *</label>
                              </div>
                            </div>
                            <div className="inner-detail">
                              <span className="icon"></span>
                              <div className="inner-detail-inner">
                                <div className={`custom-dropdown-user-new-outer custom-dropdown-select ${countryDropdownOpen ? 'open' : ''}`} ref={countryDropdownRef}>
                                  <div className="selected-option custom-dropdown-user-new" onClick={userType ? () => setCountryDropdownOpen(!countryDropdownOpen) : null} style={{ cursor: userType ? 'pointer' : 'no-drop', background: userType ? '#fff' : '#fafafa', color: userType ? '#282828' : '#a6a6a6' }}>
                                    {country ? countriesList.find((c) => c.country === country)?.country : 'Select Country'}
                                  </div>
                                  {userType && countryDropdownOpen && (
                                    <div className="custom-dropdown-new">
                                      <input
                                        type="text"
                                        className="search-bar"
                                        placeholder="Search..."
                                        value={countrySearchTerm}
                                        onChange={handleCountrySearchInputChange}
                                      />
                                      <ul className="country-list">
                                        {filteredCountries.length > 0 &&
                                          filteredCountries.map((c) => (
                                            <li
                                              key={c.country}
                                              className={country === c.country ? 'selected-item' : ''}
                                              onClick={() => {
                                                setCountry(c.country);
                                                setCountryDropdownOpen(false);
                                              }}
                                            >
                                              {c.country}
                                            </li>
                                          ))}
                                      </ul>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          {states.length > 0 && (
                            <div className="inner-detail-outer">
                              <div className="inner-detail">
                                <span className="icon">
                                  <img src={location} alt="Location Icon" />
                                </span>
                                <div className="inner-detail-inner">
                                  <label>State *</label>
                                </div>
                              </div>
                              <div className="inner-detail">
                                <span className="icon"></span>
                                <div className="inner-detail-inner">
                                  <div className={`custom-dropdown-user-new-outer custom-dropdown-select ${stateDropdownOpen ? 'open' : ''}`} ref={stateDropdownRef}>
                                    <div className="selected-option custom-dropdown-user-new" onClick={userType ? () => setStateDropdownOpen(!stateDropdownOpen) : null} style={{ cursor: userType ? 'pointer' : 'no-drop', background: userType ? '#fff' : '#fafafa', color: userType ? '#282828' : '#a6a6a6' }}>
                                      {state ? states.find((s) => s.state === state)?.state : 'Select State'}
                                    </div>
                                    {userType && stateDropdownOpen && (
                                      <div className="custom-dropdown-new">
                                        <input
                                          type="text"
                                          className="search-bar"
                                          placeholder="Search..."
                                          value={stateSearchTerm}
                                          onChange={handleStateSearchInputChange}
                                        />
                                        <ul className="state-list">
                                          {filteredStates.length > 0 &&
                                            filteredStates.map((s) => (
                                              <li
                                                key={s.state}
                                                className={state === s.state ? 'selected-item' : ''}
                                                onClick={() => {
                                                  setState(s.state);
                                                  setStateDropdownOpen(false);
                                                }}
                                              >
                                                {s.state}
                                              </li>
                                            ))}
                                        </ul>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                          <div className="inner-detail-outer">
                            <div className="inner-detail">
                              <span className="icon">
                                <img src={location} alt="Location Icon" />
                              </span>
                              <div className="inner-detail-inner">
                                <label>City *</label>
                              </div>
                            </div>
                            <div className="inner-detail">
                              <span className="icon"></span>
                              <div className="inner-detail-inner">
                                <input type="text" id="city" Placeholder="City" value={city} onChange={(e) => setCity(e.target.value)} readOnly={userType === ""} required />
                              </div>
                            </div>
                          </div>
                          <div className="inner-detail-outer">
                            <div className="inner-detail">
                              <span class="icon"><img src={location} alt="user icon" /></span>
                              <div className="inner-detail-inner">
                                <label for="address">Address *</label>
                              </div>
                            </div>
                            <div className="inner-detail">
                              <span class="icon"></span>
                              <div className="inner-detail-inner">
                                <input
                                  type="text"
                                  placeholder="Address"
                                  value={address}
                                  onChange={(e) => setAddress(e.target.value)}
                                  readOnly={userType === ""}
                                  required
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="information-right">
                          <div className="inner-detail-outer">
                            <div className="inner-detail">
                              <span class="icon"><img src={PostalCode} alt="user icon" /></span>
                              <div className="inner-detail-inner">
                                <label for="postal_code">Postal Code</label>
                              </div>
                            </div>
                            <div className="inner-detail">
                              <span class="icon"></span>
                              <div className="inner-detail-inner">
                                <input
                                  type="text"
                                  placeholder="Post Code"
                                  value={postCode}
                                  onChange={(e) => setPostCode(e.target.value)}
                                  readOnly={userType === ""}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="inner-detail-outer">
                            <div className="inner-detail">
                              <span class="icon"><img src={Tele} alt="user icon" /></span>
                              <div className="inner-detail-inner">
                                <label for="number">Mobile No. *</label>
                              </div>
                            </div>
                            <div className="inner-detail">
                              <span class="icon"></span>
                              <div className="inner-detail-inner">
                                <PhoneInput
                                  placeholder="Enter phone number"
                                  value={mobileNo}
                                  onChange={setMobileNo}
                                  readOnly={userType === ""}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="save-button-sec">
                  {isSubmitLoading ? (
                    <span className="cancel-btn-disabled">Cancel</span>
                  ) : (
                    <Link to="../list" className="cancel-btn">Cancel</Link>
                  )}
                  <button type="submit" className="save-btn" disabled={isSubmitLoading} >
                    {isSubmitLoading ?
                      <div className="outter-load-table">
                        <ThreeDots
                          height="38"
                          width="40"
                          radius="9"
                          color="white"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClassName=""
                          visible={true}
                        />
                      </div> :
                      <>
                        <img src={Tick} alt="arrow" />Save changes
                      </>
                    }
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div >
      </div >
    </>
  );
}