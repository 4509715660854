import { useSelector, useDispatch } from "react-redux";
import React, { useEffect, useRef } from 'react';
import MonthGraph from '../../components/Dashboard/Graph.jsx';
import FilterCalendar from '../../components/Dashboard/FilterCalendar';
import AmountOverview from '../../components/Dashboard/AmountOverview';
import RecentInvoices from '../../components/Dashboard/RecentInvoice.jsx';
import { motion } from 'framer-motion';

export default function Dashboard() {
  const dispatch = useDispatch();
  const user = useSelector(
    (state) => state.auth.user
  )
  const sum = useSelector(
    (state) => state.invoice.dashboardCurrencySum
  )
  const componentRef = useRef(null);
  useEffect(() => {
    componentRef.current.scrollTop = 0;
  }, [dispatch, user]);

  const fadeIn = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 }
  };
  

  return (
    <motion.div 
    ref={componentRef}
    initial="hidden"
    animate="visible"
    variants={fadeIn}
    transition={{ duration: 0.3 }}
  >
    <div ref={componentRef}>
      <>
        <div className='filter-sec'>
          <FilterCalendar sum={sum} />
        </div>
        <div className='graph-overview'>
          <div className='overview-left'>
            <AmountOverview sum={sum} />
          </div>
          <MonthGraph sum={sum} />
        </div>
        <div className='recent-invoices'>
          <div className='recent-invoice-inner'>
            <RecentInvoices sum={sum} />
          </div>
        </div>
      </>
    </div>
    </motion.div>

  )
}