import axios from "axios";

export async function getBanks(wiseEmail, token) {
  const response = await axios.get(
    `/api/wise/user/banks/${wiseEmail && wiseEmail}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  );
  return response.data;
}

export async function getBankFromCompany(wiseProfileId, userEmail, token) {
  var filteredBanks = [];
  const banks = await getBanks(userEmail, token);
  filteredBanks = banks && banks.filter((bank) => {
    if (bank.profile === parseInt(wiseProfileId) || bank.business === null) {
      return bank;
    }
  });
  return filteredBanks;
}

export async function getBanksByWiseSettingsId(wiseSettingsId, wiseEmail, token) {
  const response = await axios.get(
    `/api/wise/user/banks/by/wise/settings/id/${wiseSettingsId && wiseSettingsId}/${wiseEmail && wiseEmail}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  );

  return response.data;
}

export async function getBankFromCompanyWiseSettingsId(wiseSettingsId, wiseProfileId, userEmail, token) {
  var filteredBanks = [];
  const banks = await getBanksByWiseSettingsId(wiseSettingsId, userEmail, token);
  filteredBanks = banks && banks.filter((bank) => {
    if (bank.profile === parseInt(wiseProfileId) || bank.business === null) {
      return bank;
    }
  });
  return filteredBanks;
}

export async function getUserInvoice(id, token) {

  const response = await axios.get(`/api/invoice/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;

}

export async function bankDetail(invoiceData, token) {
  try {
    const response = await axios.post(`/api/wise/bank/details`, {
      invoiceId: invoiceData && invoiceData.inv_number
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const result = Array.isArray(response.data) ? response.data[0] : response.data;
    return result;
  } catch (e) {
    console.log(e);
  }
}

export async function venBankDetail(invoiceData, token) {
  try {
    const response = await axios.post(`/api/wise/vendor/bank/details`, {
      invoiceId: invoiceData && invoiceData.ven_inv_number
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const result = Array.isArray(response.data) ? response.data[0] : response.data;
    return result;
  } catch (e) {
    console.log(e);
  }
}