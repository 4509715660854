
import { toast } from "react-toastify";
import moment from "moment";

export function addressValidation(address) {

        var addressValidation = false;

        if (address) {
                if (address.trim() === '') {
                        toast.error("Address cannot be empty", {
                                position: "bottom-right",
                                autoClose: 8000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                        });
                } else if (address.length > 255) {
                        toast.error("Address must be 255 characters or less", {
                                position: "bottom-right",
                                autoClose: 8000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                        });
                } else if (!/^[a-zA-Z0-9\s,.-]*$/.test(address)) {
                        toast.error("Address can only contain letters, numbers, commas, hyphens, and periods", {
                                position: "bottom-right",
                                autoClose: 8000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                        });
                } else {

                        addressValidation = true;
                }
        }

        return addressValidation;

}



export function cityValidation(city) {

        var cityValidation = false;

        if (city) {
                if (city.trim() === '') {
                        toast.error("City cannot be empty", {
                                position: "bottom-right",
                                autoClose: 8000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                        });
                } else if (city.length > 100) {
                        toast.error("City name must be 100 characters or less", {
                                position: "bottom-right",
                                autoClose: 8000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",

                        });
                } else if (!/^[a-zA-Z\s-]*$/.test(city)) {
                        toast.error("City name can only contain letters, spaces, and hyphens", {
                                position: "bottom-right",
                                autoClose: 8000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                        })
                } else {
                        cityValidation = true;
                }
        }

        return cityValidation;
}


export function mobileNumberValidation(mobile) {

        var mobileNumberValidation = false;

        if (mobile) {
                const cleanedValue = mobile.replace(/\D/g, '');

                if (cleanedValue.length < 10 || cleanedValue.length > 15) {
                        toast.error("Mobile number must be between 10 and 15 digits", {
                                position: "bottom-right",
                                autoClose: 8000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                        });
                } else if (!/^[\d\s\-\(\)]+$/.test(cleanedValue)) {
                        toast.error("Mobile number can only contain digits, spaces, parentheses, and dashes", {
                                position: "bottom-right",
                                autoClose: 8000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                        });
                } else {
                        mobileNumberValidation = true;
                }
        }

        return mobileNumberValidation;


}



export function postalCodeValidation(postal) {

        var postalCodevalodation = false;

        if (postal) {
                // Remove spaces and hyphens for length validation
                const cleanedValue = postal.replace(/[\s-]/g, '');

                if (cleanedValue.length < 3 || cleanedValue.length > 10) {
                        toast.error("Postal code must be between 3 and 10 characters", {
                                position: "bottom-right",
                                autoClose: 8000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                        });
                } else if (!/^[a-zA-Z0-9\s-]+$/.test(cleanedValue)) {
                        toast.error("Postal code can only contain letters, digits, spaces, and hyphens", {
                                position: "bottom-right",
                                autoClose: 8000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                        });
                } else {
                        postalCodevalodation = true;
                }
        }
        return postalCodevalodation;
}


export function passwordValidation(password) {

        var passwordValidation = false;


        if (password) {
                if (password.length < 8 || password.length > 16) {
                        toast.error("Password must be between 8 and 16 characters", {
                                position: "bottom-right",
                                autoClose: 8000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                        });
                } else if (
                        !/[A-Z]/.test(password) ||  // Check for uppercase letter
                        !/[a-z]/.test(password) ||  // Check for lowercase letter
                        !/[0-9]/.test(password) ||  // Check for digit
                        !/[!@#$%^&*]/.test(password) // Check for special character
                ) {
                        toast.error("Password must include uppercase, lowercase, digits, and special characters", {
                                position: "bottom-right",
                                autoClose: 8000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                        });
                } else {

                        passwordValidation = true;
                }
        }

        return passwordValidation;
}


export function passportIdValidation(passportId) {

        var passportValodation = false;
        const cleanedValue = passportId.replace(/[^a-zA-Z0-9\s-]/g, '');

        if (passportId.length < 8 || passportId.length > 12) {
                toast.error("Passport ID must be between 8 and 12 characters", {
                        position: "bottom-right",
                        autoClose: 8000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                });
        } else if (!/^[a-zA-Z0-9\s-]*$/.test(cleanedValue)) {
                toast.error("Passport ID can only contain letters, numbers, spaces, and hyphens", {
                        position: "bottom-right",
                        autoClose: 8000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                });

        } else {

                passportValodation = true;

        }

        return passportValodation;
}


export function nameValidation(name) {

        var nameValidation = false;
        if (name) {
                const namePattern = /^[a-zA-Z\s'-]+$/;

                if (name && name.length > 50) {
                        toast.error("Name should be less than 50 characters", {
                                position: "bottom-right",
                                autoClose: 6000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                        });
                } else if (!namePattern.test(name)) {
                        // Add a shaking animation class


                        toast.error("Name should only allow letters, hyphens, and apostrophes.", {
                                position: "bottom-right",
                                autoClose: 6000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                        });

                } else {

                        nameValidation = true;
                }

        }

        return nameValidation;


}


export function dobValidation(date) {

        var dobValidation = false;
        if (date) {
                const selectedDate = moment(date); // Convert to moment object
                const currentDate = moment(); // Current date

                // Avoid future dates and current date
                if (selectedDate.isSameOrAfter(currentDate, 'day')) {
                        toast.error("Date of Birth cannot be today or in the future", {
                                position: "bottom-right",
                                autoClose: 8000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                        });
                } else {

                        dobValidation = true;

                }

        }

        return dobValidation;


}

export function emailValidator(email) {

        var emailValidation = false;

        if (email) {
                // Regular expression for validating email format
                const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

                if (!emailPattern.test(email)) {
                        toast.error("Please enter a valid email address", {
                                position: "bottom-right",
                                autoClose: 8000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                        });
                }
        } else {

                emailValidation = true;

        }

        return emailValidation;

}


export function taxIdvalidator(taxId) {

        var taxValidation = false;

        if (taxId) {
                // Clean value of special characters except for those allowed
                const cleanedValue = taxId.replace(/[^a-zA-Z0-9\s-]/g, '');

                if (cleanedValue.length < 8 || cleanedValue.length > 12) {
                        toast.error("Tax ID must be between 8 and 12 characters", {
                                position: "bottom-right",
                                autoClose: 6000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                        });
                } else if (!/^[a-zA-Z0-9\s-]*$/.test(cleanedValue)) {
                        toast.error("Tax ID can only contain letters, numbers, spaces, and hyphens", {
                                position: "bottom-right",
                                autoClose: 6000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                        });
                } else {

                        taxValidation = true;
                }
        }

        return taxValidation;




}