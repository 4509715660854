import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Dollar from "../../../../images/icons/dollar-kit.svg";
import { calculateOutstandingReimbursement } from './Services/reportReimbursement';

// Functional component
function TotalReimburesentOutstanding({ reimbursementInv }) {
  const [totalOutstandingReimbursement, setTotalOutstandingReimbursement] = useState(null);
  const reportingReimbursment = useSelector((state) => state.reportingReimbursment);
  const { forex } = useSelector((state) => state.forex);
  const settings = JSON.parse(localStorage.getItem("settings"));

  useEffect(() => {
    const totalOutstandingReimbursementAmount = calculateOutstandingReimbursement(reimbursementInv, reportingReimbursment, settings.currency, forex);
    setTotalOutstandingReimbursement(totalOutstandingReimbursementAmount);
  }, [reimbursementInv, reportingReimbursment, settings.currency, forex]);
  return (
    <div>
      <div className="overview-item">
        <div className="overview-title-outer">
          <div className="overview-title">Total Outstanding</div>
          <div className="overview-icon">
            <svg width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g filter="url(#filter0_d_2951_99)">
                <circle cx="24.5" cy="24.5" r="20.5" fill="white" />
              </g>
              <path d="M31.25 14.6667H30V13.4167C30 13.1867 29.8133 13 29.5833 13C29.3533 13 29.1667 13.1867 29.1667 13.4167V14.6667H20.8333V13.4167C20.8333 13.1867 20.6467 13 20.4167 13C20.1867 13 20 13.1867 20 13.4167V14.6667H18.75C16.6825 14.6667 15 16.3492 15 18.4167V29.25C15 31.3175 16.6825 33 18.75 33H24.5833C24.8133 33 25 32.8133 25 32.5833C25 32.3533 24.8133 32.1667 24.5833 32.1667H18.75C17.1417 32.1667 15.8333 30.8583 15.8333 29.25V20.5H34.1667V25.8804C34.1667 26.1104 34.3533 26.2971 34.5833 26.2971C34.8133 26.2971 35 26.1104 35 25.8804V18.4167C35 16.3492 33.3175 14.6667 31.25 14.6667ZM34.1667 19.6667H15.8333V18.4167C15.8333 16.8083 17.1417 15.5 18.75 15.5H31.25C32.8583 15.5 34.1667 16.8083 34.1667 18.4167V19.6667ZM31.405 25.6425C31.7375 25.3433 32.1075 24.955 32.365 24.5167C32.5458 24.2075 32.5467 23.835 32.365 23.52C32.1808 23.2 31.8383 23.0008 31.4717 23.0008H29.3608C28.9942 23.0008 28.6508 23.2 28.4675 23.52C28.2867 23.835 28.2875 24.2075 28.4675 24.5175C28.725 24.9558 29.095 25.3442 29.4275 25.6433C27.4975 26.2117 25.8325 28.3367 25.8325 30.5008C25.8325 31.8742 26.9542 32.9908 28.3325 32.9908H32.4992C33.8775 32.9908 34.9992 31.8742 34.9992 30.5008C34.9992 28.3358 33.3342 26.2117 31.4042 25.6433L31.405 25.6425ZM29.1883 24.0958C29.1508 24.0317 29.17 23.9725 29.1917 23.9342C29.205 23.9108 29.2575 23.8333 29.3617 23.8333H31.4725C31.5767 23.8333 31.6292 23.91 31.6425 23.9342C31.6642 23.9725 31.6833 24.0317 31.6458 24.095C31.3208 24.6492 30.7383 25.1375 30.4167 25.3817C30.095 25.1383 29.5133 24.6508 29.1875 24.095L29.1883 24.0958ZM32.5008 32.1575H28.3342C27.415 32.1575 26.6675 31.4142 26.6675 30.5008C26.6675 28.4683 28.5917 26.405 30.4175 26.3342C32.2433 26.405 34.1675 28.4683 34.1675 30.5008C34.1675 31.4142 33.42 32.1575 32.5008 32.1575Z" fill="#6A7988" stroke="#6A7988" stroke-width="0.2" />
              <defs>
                <filter id="filter0_d_2951_99" x="0" y="0" width="49" height="49" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                  <feOffset />
                  <feGaussianBlur stdDeviation="2" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix type="matrix" values="0 0 0 0 0.468733 0 0 0 0 0.51145 0 0 0 0 0.554167 0 0 0 0.39 0" />
                  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2951_99" />
                  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2951_99" result="shape" />
                </filter>
              </defs>
            </svg>
          </div>
        </div>

        <div className="overview-rice">
          {new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: settings.currency,
          }).format(totalOutstandingReimbursement)}
        </div>
      </div>
    </div>
  );
}

export default TotalReimburesentOutstanding;
