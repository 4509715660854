import axios from 'axios';
import { useQuery, useMutation, useQueryClient } from 'react-query';

const API_BASE_URL = '/api';

export const useAppSettings = (appId, token) => {
    return useQuery(['appSettings', appId], async () => {
        const response = await axios.get(`${API_BASE_URL}/get/app-settings/${appId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    });
};