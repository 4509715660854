import axios from "axios";
import { DatePicker } from "rsuite";
import subDays from "date-fns/subDays";
import { toast } from "react-toastify";
import { DateRangePicker } from "rsuite";
import { ThreeDots } from "react-loader-spinner";
import Tooltip from "../InvoiceCreateManual/Tooltip";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import React, { useState, useEffect, useRef } from "react";
import userCategory from "../../../constants/userCategory.js";
import css from "../InvoiceCreateManual/InvoiceCreateManual.css";
import { logout, reset } from "../../../features/auth/authSlice";
import dletImage from "../../../images/singleinvoice/delete.svg";
import whatis from "../../../images/singleinvoice/whatisthis.svg";
import cancelImage from "../../../images/singleinvoice/cancel.svg";
import addicoplus from "../../../images/singleinvoice/plus-add-icon.svg";
import CompanyDocumentField from "../../User/elements/CompanyDocumentField";
import { reset as invoiceReset } from "../../../features/invoice/invoiceSlice.js";
import { setNotification } from "../../../features/notification/notificationSlice.js";
import { reset as headerReset } from "../../../features/headerText/headerTextSlice.js";
import { reset as appSettingsReset } from "../../../features/appSettings/appSettingsSlice.js";

const InvoiceEditManual = (props) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [userName, setUserName] = useState("");
  const [selUserCategory, setSelUserCategory] = useState("");
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [invType, setInvType] = useState("Regular");
  const [issueDate, setIssueDate] = useState(new Date());
  const [dueDate, setDueDate] = useState(() => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    const lastDayOfMonth = new Date(year, month + 1, 0);
    return lastDayOfMonth;
  });
  const [title, setTitle] = useState("");
  const [subTotal, setSubTotal] = useState("00.00");
  const [taxTotal, setTaxTotal] = useState("00.00");
  const [total, setTotal] = useState("00.00");
  const [productDetails, setProductDetails] = useState([
    { description: "", projectId: "", cost: "", tax: "", price: "" },
  ]);
  const [expenseCategory, setExpenseCategory] = useState([]);
  const [invDocument, setInvDocument] = useState(null);
  const [invDocumentFileName, setInvDocumentFileName] = useState("");
  const [invFiles, setInvFiles] = useState([]);
  const [otherComnt, setOtherComnt] = useState("");
  const [selUserEmail, setSelUserEmail] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [updateIsLoading, setUpdateIsLoading] = useState(false);
  const [invoiceBank, setInvoiceBank] = useState("");
  const [invoiceBankListName, setInvoiceBankListName] = useState("");
  const [invoiceBankCurrency, setInvoiceBankCurrency] = useState("");
  const [projectId, setProjectId] = useState([]);
  const [bankDetails, setBankDetails] = useState([]);
  const [invoiceView, setInvoiceView] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isBankLoading, setBankIsLoading] = useState(true);
  const [invTypeDropdownOpen, setInvTypeDropdownOpen] = useState(false);
  const [searchBankAccountTerm, setSearchBankAccountTerm] = useState('');
  const [bankAccountDropdownOpen, setBankAccountDropdownOpen] = useState(false);
  const [dateRangeIsValid, setDateRangeIsValid] = useState(false);
  const dateRangeRef = useRef(null);
  const [issueDateIsValid, setIssueDateIsValid] = useState(false);
  const issueDateRef = useRef(null);
  const [dueDateIsValid, setDueDateIsValid] = useState(false);
  const dueDateRef = useRef(null);
  const [titleIsValid, setTitleIsValid] = useState(false);
  const titleRef = useRef(null);
  const [descriptionIsValid, setDescriptionIsValid] = useState(false);
  const [expenseCategoryIsValid, setExpenseCategoryIsValid] = useState(false);
  const descriptionRef = useRef(null);
  const [costIsValid, setCostIsValid] = useState(false);
  const costRef = useRef(null);
  const [taxIsValid, setTaxIsValid] = useState(false);
  const taxRef = useRef(null);
  const invTypeDropdownRef = useRef(null);
  const bankAccountDropdownRef = useRef(null);
  const { appSettings } = useSelector((state) => state.appSettings);
  let token = null;
  let createdBy = null;
  const { user } = useSelector((state) => state.auth);
  if (user) {
    if (user && user.token) {
      token = user.token;
      createdBy = user._id;
    }
  }


  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });
  const handleSelect = (ranges) => {
    setDateRange(ranges);
  };

  useEffect(() => {
    async function fetchInvoice() {
      try {
        setIsLoading(true);
        const response = await axios.get(`/api/invoice/${props.id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = response.data;
        const invoiceDetails = data.inv_description;
        var projectIds = data.inv_projectId || projectId.find(project => project.project_name === data.inv_description)._id || null;
        var costs = data.inv_cost;
        var category = data.inv_category;
        const vats = data.inv_tax;
        const prices = data.inv_price;
        const invoiceViewData = invoiceDetails.map((item, index) => {
          return {
            Description: item.trim(),
            ProjectId: projectIds[index],
            Cost: costs[index],
            Category: category[index],
            tax: vats[index],
            amount: prices[index],
          };
        });
        setInvoiceView([{ ...data, Invoice_Details: invoiceViewData }]);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        global.config.slackMessage(error.toString());
        if (error.response?.data?.status === "deleted") {
          localStorage.removeItem("user");
          dispatch(logout());
          dispatch(reset());
          dispatch(invoiceReset());
          dispatch(headerReset());
          dispatch(appSettingsReset());
          toast.error('This user is deleted!', {
            position: "bottom-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          navigate("/");
          return;
        }
        alert("Something went wrong");
      }
    }
    fetchInvoice();

  }, [props.id]);

  useEffect(() => {
    async function fetchUser() {
      try {
        const response = await axios.get(`/api/user/data/${selUserEmail && selUserEmail}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        let userName = '';
        if (response.data.first_name && response.data.first_name?.toLowerCase() !== 'null') {
          userName = response.data.first_name;
          if (response.data.middle_name && response.data.middle_name?.toLowerCase() !== 'null') {
            userName += ' ' + response.data.middle_name;
          }
          userName += ' ' + response.data.last_name;
        } else {
          userName = response.data.company_name;
        }
        if (response.data) {
          setUserName(userName);
          setSelUserCategory(response.data.user_category);
        }
      } catch (err) {
        console.log(err);
        global.config.slackMessage(err.toString());
      }
    }
    fetchUser();
    async function fetchExpenseCategory() {
      try {
        const response = await axios.get("/api/expense-categories", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setExpenseCategory(response.data)
      } catch (error) {
        console.log(error);
        global.config.slackMessage(error.toString());
        alert("Something went wrong while fetching invoice number");
      }
    }
    fetchExpenseCategory();
    async function fetchBank() {

      try {
        setBankIsLoading(true);
        const response = await axios.get(
          `/api/wise/user/banks/${selUserEmail}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
        );
        setBankDetails(response.data);
        setBankIsLoading(false);
      } catch (error) {
        global.config.slackMessage(error.toString());
        if (error.response && error.response.status === 404) {

        } else if (error.response?.data?.status === "deleted") {
          localStorage.removeItem("user");
          dispatch(logout());
          dispatch(reset());
          dispatch(invoiceReset());
          dispatch(headerReset());
          dispatch(appSettingsReset());
          toast.error('This user is deleted!', {
            position: "bottom-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          navigate("/");
          return;
        }
      }
    }
    if (selUserEmail) {
      fetchBank();
    }
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        document.activeElement.blur();
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };

  }, [selUserEmail]);

  useEffect(() => {
    async function fetchProjectId() {
      try {
        const response = await axios.get("/api/all/project-details", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setProjectId(response.data);
      } catch (error) {
        console.log(error);
        global.config.slackMessage(error.toString());
        if (error.response?.data?.status === "deleted") {
          localStorage.removeItem("user");
          dispatch(logout());
          dispatch(reset());
          dispatch(invoiceReset());
          dispatch(headerReset());
          dispatch(appSettingsReset());
          toast.error('This user is deleted!', {
            position: "bottom-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          navigate("/");
          return;
        }
        alert("Something went wrong");
      }
    }
    fetchProjectId();
  }, []);

  useEffect(() => {
    if (!isLoading && invoiceView) {
      const item = invoiceView[0];
      const invoiceDetails = item.Invoice_Details;
      setSelUserEmail(item.inv_email);
      setInvoiceNumber(item.inv_number);
      setInvType(item.inv_type);
      setDateRange([new Date(item.inv_strat_date), new Date(item.inv_end_date)]);
      setIssueDate(new Date(item.inv_issue_date));
      setDueDate(new Date(item.inv_due_date));
      setTitle(item.inv_title);
      setInvoiceBank(item.inv_bank);
      setInvoiceBankCurrency(item.inv_currency);
      setInvoiceBankListName(`${item.inv_bank}_${item.inv_currency}`);

      if (item.inv_type === "Reimbursement") {
        const productDetails = invoiceDetails.map((item1) => ({
          description: item1.Description,
          projectId: item1.ProjectId,
          cost: item1.Cost,
          expenseCategory: item1.Category,
          tax: item1.tax,
          amount: item1.amount,
        }));
        setProductDetails(productDetails);
      } else {
        const productDetails = invoiceDetails.map((item1) => ({
          description: item1.Description,
          projectId: item1.ProjectId,
          cost: item1.Cost,
          tax: item1.tax,
          amount: item1.amount,
        }));
        setProductDetails(productDetails);
      }
      setOtherComnt(item.inv_other_comments);
      if (item.inv_file) {
        setInvDocumentFileName(item.inv_file);
      }
      setTotal(item.inv_total_amount);
      setTaxTotal(item.inv_total_tax);
      setSubTotal(item.inv_total_amount);
    }

  }, [invoiceView, isLoading])

  const handleIssueDateChange = (date) => {
    if (!dueDate || new Date(date) <= new Date(dueDate)) {
      setIssueDate(date);
    } else {
      toast.error("Issue date must be before due date", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const ranges = [
    {
      label: "today",
      value: new Date(), // Use a single date instead of an array
    },
    {
      label: "yesterday",
      value: subDays(new Date(), 1),
    },
  ];

  const handleDueDateChange = (date) => {
    if (!issueDate || new Date(date) >= new Date(issueDate)) {
      setDueDate(date);
    } else {
      toast.error("Due date must be after issue date", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const handleAddRow = (e) => {
    e.preventDefault();
    const newProductDetails = [
      ...productDetails,
      { description: "", projectId: "", cost: "", tax: "", price: "" },
    ];
    setProductDetails(newProductDetails);
  };

  const handleRemoveRow = (indexToRemove, e) => {
    const newProductDetails = [...productDetails];
    newProductDetails.splice(indexToRemove, 1);
    setProductDetails(newProductDetails);
    const { subTotal, taxTotal } = calculateSubTotalAndTax(newProductDetails);
    setSubTotal(subTotal);
    setTaxTotal(taxTotal);
    const total = calculateTotal(subTotal, taxTotal);
    setTotal(subTotal);
  };

  const calculateSubTotalAndTax = (details) => {
    let subTotal = 0;
    let taxTotal = 0;
    details.forEach((detail) => {
      var cost = parseFloat(detail.cost) || 0;
      var tax = parseFloat(detail.tax) || 0;
      const amount = cost + cost * (tax / 100);
      subTotal += amount;
      taxTotal += amount - cost;
    });
    return { subTotal: subTotal.toFixed(2), taxTotal: taxTotal.toFixed(2) };
  };

  const handleInputChange = (event, index, field) => {
    const { name, value } = event.target;
    const newProductDetails = [...productDetails];
    newProductDetails[index][name] = value;
    if (field === "cost" || field === "tax") {
      const cost = parseFloat(newProductDetails[index].cost) || 0;
      const tax = parseFloat(newProductDetails[index].tax) || 0;
      if (!isNaN(cost) && !isNaN(tax)) {
        newProductDetails[index].amount = (cost + cost * (tax / 100)).toFixed(
          2
        );
      }
    }
    setProductDetails(newProductDetails);
    const tax = calculateTax();
    setTaxTotal(tax);
    const subtotal = calculateSubtotal();
    setSubTotal(subtotal);
    const total = calculateSubtotal();
    setTotal(total);
  };

  const handleProjIdInputChange = (event, index, field) => {
    const newProductDetails = [...productDetails];
    newProductDetails[index]["projectId"] = field;
    setProductDetails(newProductDetails);
  };

  const calculateSubtotal = () => {
    let subtotal = 0;
    productDetails.forEach((detail) => {
      const cost = parseFloat(detail.cost) || 0;
      const tax = parseFloat(detail.tax) || 0;
      const amount = cost + cost * (tax / 100);
      subtotal += amount;
    });
    return subtotal.toFixed(2);
  };

  const calculateTax = () => {
    let taxs = 0;
    productDetails.forEach((detail) => {
      const cost = parseFloat(detail.cost) || 0;
      const tax = parseFloat(detail.tax) || 0;
      const amount = cost + cost * (tax / 100);
      taxs += amount - cost;
    });
    return taxs.toFixed(2);
  };

  const calculateTotal = () => {
    const subtotal = parseFloat(calculateSubtotal());
    const tax = parseFloat(calculateTax());
    return (subtotal + tax).toFixed(2);
  };

  const handleInvTypeToggleDropdown = () => {
    setInvTypeDropdownOpen(!invTypeDropdownOpen);
  };

  const handleInvTypeSelectOption = (value) => {
    setInvType(value);
    setInvTypeDropdownOpen(false);
  };

  const handleToggleDropdown = () => {
    setSearchBankAccountTerm('');
    setBankAccountDropdownOpen(!bankAccountDropdownOpen);
  };

  const handleSelectOption = (invoiceBank) => {
    const bankSelect = invoiceBank.split("_");
    setInvoiceBankCurrency(bankSelect[1]);
    setInvoiceBank(bankSelect[0]);
    setInvoiceBankListName(invoiceBank);
    setBankAccountDropdownOpen(false);
  };

  const handleSearchInputChange = (e) => {
    setSearchBankAccountTerm(e.target.value);
  };

  const filteredBankDetails = bankDetails.filter((bank) =>
    `${bank.details.accountNumber || ''} ${bank.details.IBAN || ''} ${bank.currency || ''} ${bank.country || ''}`
      ?.toLowerCase()
      .includes(searchBankAccountTerm?.toLowerCase())
  );

  const handleSubmit = async (event) => {
    event.preventDefault();
    setDateRangeIsValid(false);
    setIssueDateIsValid(false);
    setDueDateIsValid(false);
    if (
      invoiceNumber === "" ||
      issueDate === null ||
      dueDate === null ||
      dateRange === null ||
      title === "" ||
      selUserEmail === "" ||
      productDetails[0].description.length === 0 ||
      productDetails[0].cost.length === 0 ||
      productDetails[0].amount.length === 0 ||
      invoiceBankCurrency === "" ||
      invType === "" ||
      invoiceBank === ""
    ) {
      if (dateRange === null) {
        setDateRangeIsValid(true);
        toast.error("Date range cannot be empty!", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        dateRangeRef.current.focus();
        return;
      } else if (issueDate === null) {
        setIssueDateIsValid(true);
        toast.error("Issue date cannot be empty!", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        issueDateRef.current.focus();
        return;
      } else if (dueDate === null) {
        setDueDateIsValid(true);
        toast.error("Due date cannot be empty!", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        dueDateRef.current.focus();
        return;
      }
      toast.error("Please fill all required fields!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    setUpdateIsLoading(true);
    const descriptions = productDetails.map((product) => product.description);
    const projectIds = productDetails.map((product) => product.projectId);
    var expenseCategory = [];
    if (invType === "Reimbursement") {
      expenseCategory = productDetails.map((product) => product.expenseCategory);
    }
    const prices = productDetails.map((product) => product.amount);
    const costs = productDetails.map((product) => product.cost);
    const taxes = productDetails.map((product) => product.tax);
    const invoiceData = {
      inv_number: invoiceNumber,
      inv_strat_date: dateRange[0],
      inv_end_date: dateRange[1],
      inv_issue_date: issueDate,
      inv_due_date: dueDate,
      inv_title: title,
      inv_description: JSON.stringify(descriptions),
      inv_projectId: JSON.stringify(projectIds),
      inv_category: JSON.stringify(expenseCategory),
      inv_price: JSON.stringify(prices),
      inv_cost: JSON.stringify(costs),
      inv_tax: JSON.stringify(taxes),
      inv_sub_total: subTotal,
      inv_total_tax: taxTotal,
      inv_total_amount: total,
      inv_currency: invoiceBankCurrency,
      inv_type: invType,
      inv_bank: invoiceBank,
      inv_other_comments: otherComnt,
      inv_created_by: createdBy,
    };

    const formData = new FormData();
    // formData.append("inv_file", invDocument);
    for (let i = 0; i < invFiles.length; i++) {
      formData.append("inv_file", invFiles[i]);
    }
    for (let key in invoiceData) {
      formData.append(key, invoiceData[key]);
    }

    try {
      const response = await axios.put(
        `/api/invoice/update/${props.id}`,
        formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = response.data;
      if (data.status === "Error") {
        global.config.activityLog(window.location.href, 'Invoice', `Something went wrong while updating invoice "${invoiceNumber}"`);
        alert("Something Went Wrong");
      } else if (data.status === "statusMonthlyError") {
        alert("Cannot update this invoice as it is already Reviwed or is Monthly Invoice");
      } else {
        toast.success("Invoice updated successfully", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        dispatch(setNotification(Date.now()));
        global.config.activityLog(window.location.href, 'Invoice', `Invoice "${invoiceNumber}" Updated successfully.`);
        navigate(`../invoice/view/${props.id}`);
      }
    } catch (error) {
      console.log(error);
      global.config.slackMessage(error.toString());
      if (error.response?.data?.status === "deleted") {
        localStorage.removeItem("user");
        dispatch(logout());
        dispatch(reset());
        dispatch(invoiceReset());
        dispatch(headerReset());
        dispatch(appSettingsReset());
        toast.error('This user is deleted!', {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/");
        return;
      }
      global.config.activityLog(window.location.href, 'Invoice', `Something went wrong for invoice update`);
      alert("Something went wrong");
    }
    setUpdateIsLoading(false);
  };

  const handleClickOutsideDropdown = (event) => {
    const isClickInsideInvTypeDropdown = invTypeDropdownRef.current && invTypeDropdownRef.current.contains(event.target);
    const isClickInsideBankAccountDropdown = bankAccountDropdownRef.current && bankAccountDropdownRef.current.contains(event.target);
    if (!isClickInsideInvTypeDropdown) {
      setInvTypeDropdownOpen(false);
    }
    if (!isClickInsideBankAccountDropdown) {
      setBankAccountDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutsideDropdown);
    return () => {
      document.removeEventListener('click', handleClickOutsideDropdown);
    };
  }, []);

  const allowedExtensions = ["pdf", "doc", "docx", "msword", "xls", "xlsx", "csv"];
  // const handleInvDocChange = (event) => {
  //   const selectedFile = event.target.files[0];
  //   if (selectedFile) {
  //     const fileExtension = selectedFile.name.split(".").pop().toLowerCase();
  //     if (allowedExtensions.includes(fileExtension)) {
  //       setInvDocument(selectedFile);
  //       const fileName = selectedFile ? selectedFile.name : '';
  //       setInvDocumentFileName(fileName);
  //     } else {
  //       toast.error("Invalid file format. Allowed formats are pdf, doc, docx, msword, xls, xlsx, csv.!!", {
  //         position: "bottom-right",
  //         autoClose: 3500,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //       });
  //       // You can also clear the file input if needed
  //       fileInputRef.current.value = "";
  //     }
  //   }
  // };
  const handleInvDocChange = (event) => {
    const selectedFiles = event.target.files;

    if (selectedFiles.length > 0) {
      if (selectedFiles.length + invFiles.length > 10) {
        // Display toast error message when the total number of files exceeds 10
        toast.error('Maximum of 10 files can be uploaded!', {
          position: 'bottom-right',
          autoClose: 3500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return;
      }
      const newFiles = Array.from(selectedFiles);

      // Validate file extensions and update state
      const validFiles = newFiles.filter((file) => {
        const fileExtension = file.name.split('.').pop().toLowerCase();
        if (allowedExtensions.includes(fileExtension)) {
          return true;
        } else {
          toast.error(`Invalid file format for ${file.name}. Allowed formats are pdf, doc, docx, msword, xls, xlsx, csv.!!`, {
            position: 'bottom-right',
            autoClose: 3500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          return false;
        }
      });

      // Update the state with the valid files
      setInvFiles((prevFiles) => [...prevFiles, ...validFiles]);

      // Clear the file input for multiple selections
      fileInputRef.current.value = '';
    }
  };
  const removeFile = (index) => {
    // Remove the file at the specified index
    setInvFiles((prevFiles) => {
      const updatedFiles = [...prevFiles];
      updatedFiles.splice(index, 1);
      return updatedFiles;
    });
  };
  const handleDropdownChange = (event) => {
    selectBankAccount(event.target.value);
  };
  const selectBankAccount = (invoiceBank) => {
    const bankSelect = invoiceBank.split("_");
    setInvoiceBankCurrency(bankSelect[1]);
    setInvoiceBank(bankSelect[0]);
    setInvoiceBankListName(invoiceBank);

  };
  const handleTooltipToggle = () => {
    setShowTooltip(!showTooltip);
  };
  const fileInputRef = useRef(null);
  const handleClick = () => {
    fileInputRef.current.click();
  };

  const numberInputOnWheelPreventChange = (e) => {
    // Prevent the input value change
    e.target.blur()

    // Prevent the page/container scrolling
    e.stopPropagation()

    setTimeout(() => {
      e.target.focus()
    }, 0)
  }

  return (
    <div className="s1-containauto">
      <form onSubmit={handleSubmit}>
        <div className="fs-head-autoinvo">
          <div className="bill-num-si">
            <Link className="invoice-num-01">{invoiceNumber}</Link>
          </div>
          <div className="btn-aary">
            <Link to={`/user/invoice/view/${props.id}`} className="cancelbtn-link">
              <img src={cancelImage} alt="cancel" /> Cancel Invoice
            </Link>
          </div>
        </div>
        <div className="m-edit-main">
          <div className="edit-sheet-manual01">
            <div className="headder-outter-res-mainu">
              <div className="sheet-manual-header">
                <div className="type-dropdown-em">
                  <div className="dp-main-head-em">Type</div>
                  <div className="custom-dropdown">
                    {isLoading ? (
                      <ThreeDots
                        height="18"
                        width="20"
                        radius="9"
                        color="#6479f9"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                      />
                    ) : (
                      // <select
                      //   className="type-select-me"
                      //   name="invoice_type"
                      //   value={invType}
                      //   onChange={(e) => setInvType(e.target.value)}
                      // >
                      //   <option value="" disabled hidden></option>
                      //   <option value="Regular">Regular Invoice</option>
                      //   <option value="Reimbursement">
                      //     Reimbursement Invoice
                      //   </option>
                      // </select>
                      <div ref={invTypeDropdownRef} className={`custom-dropdown-user-new-outer custom-dropdown-select ${invTypeDropdownOpen ? 'open' : ''}`}>
                        <div className="selected-option custom-dropdown-user-new" onClick={handleInvTypeToggleDropdown}>
                          {invType ? invType : 'Select Type'}
                        </div>
                        {invTypeDropdownOpen && (
                          <div className="custom-dropdown-new">
                            <ul className="type-list">
                              <li className={invType === 'Regular' ? 'selected-item' : ''} onClick={() => handleInvTypeSelectOption('Regular')}>Regular Invoice</li>
                              <li className={invType === 'Reimbursement' ? 'selected-item' : ''} onClick={() => handleInvTypeSelectOption('Reimbursement')}>Reimbursement Invoice</li>
                            </ul>
                          </div>
                        )}
                      </div>
                    )}
                    <span className="custom-arrow"></span>
                  </div>
                  <div className="tactic00i">
                    <img
                      src={whatis}
                      alt="tool-tip"
                      onMouseEnter={handleTooltipToggle}
                      onMouseLeave={handleTooltipToggle}
                    />
                  </div>
                </div>
                <div className="invo-date-rangeme">
                  <div className="invo-date-range-tile">Date Range</div>
                  <div className="invo-date-range-cal" ref={dateRangeRef}>
                    {isLoading ? (
                      <ThreeDots
                        height="18"
                        width="20"
                        radius="9"
                        color="#6479f9"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                      />
                    ) : (
                      <div className="date-picker-outer date-picker-outer-all">
                        <div className="custom-picker-icon custom-picker-icon-all">
                          <svg
                            width="1em"
                            height="1em"
                            viewBox="0 0 14 14"
                            fill="currentColor"
                            aria-hidden="true"
                            focusable="false"
                            class="rs-picker-toggle-caret rs-icon"
                            aria-label="calendar"
                            data-category="legacy"
                          >
                            <path d="M1 4v8.5a.5.5 0 00.5.5H8c0-.128.049-.256.146-.354.555-.555.854-1.6.854-3.146a.5.5 0 01.621-.485l.119.03A2.623 2.623 0 0012.999 6.5V4h-12zm3-2h6V0h1v2h1.5A1.5 1.5 0 0114 3.5v3a3.623 3.623 0 01-4.015 3.603c-.064 1.245-.335 2.212-.831 2.898H12.5a.5.5 0 00.5-.5v-2a.5.5 0 011 0v2a1.5 1.5 0 01-1.5 1.5h-11a1.5 1.5 0 01-1.5-1.5v-9a1.5 1.5 0 011.5-1.5H3v-2h1v2zm5 4V5h1v1h2v1h-2v1H9V7H7v2h1v1H7v2H6v-2H4v2H3v-2H1V9h2V7H1V6h2V5h1v1h2V5h1v1h2zM6 9V7H4v2h2z"></path>
                          </svg>
                        </div>
                        <div className="custoom-icon-calender custoom-icon-calender-all">
                          <DateRangePicker
                            showOneCalendar
                            format="dd/MM/yyyy"
                            value={dateRange}
                            onChange={handleSelect}
                            isoWeek={appSettings.weekStartDay === 'Monday'}
                            timeZone={global.config.getTimeZone()}

                          />
                        </div>
                      </div>

                    )}
                  </div>
                  {dateRangeIsValid && (
                    <span className="text-red-500 text-xs italic">
                      Date Range Cannot be empty
                    </span>
                  )}
                </div>
                <div className="invo-date-issueme">
                  <div className="invo-date-issue-tile">Issue Date</div>
                  <div className="invo-date-due-cal">
                    <div className="calender-date-due-pic" ref={issueDateRef}>
                      {isLoading ? (
                        <ThreeDots
                          height="18"
                          width="20"
                          radius="9"
                          color="#6479f9"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClassName=""
                          visible={true}
                        />
                      ) : (
                        <div className="date-picker-outer date-picker-outer-all">
                          <div className="custom-picker-icon custom-picker-icon-all">
                            <svg
                              width="1em"
                              height="1em"
                              viewBox="0 0 14 14"
                              fill="currentColor"
                              aria-hidden="true"
                              focusable="false"
                              class="rs-picker-toggle-caret rs-icon"
                              aria-label="calendar"
                              data-category="legacy"
                            >
                              <path d="M1 4v8.5a.5.5 0 00.5.5H8c0-.128.049-.256.146-.354.555-.555.854-1.6.854-3.146a.5.5 0 01.621-.485l.119.03A2.623 2.623 0 0012.999 6.5V4h-12zm3-2h6V0h1v2h1.5A1.5 1.5 0 0114 3.5v3a3.623 3.623 0 01-4.015 3.603c-.064 1.245-.335 2.212-.831 2.898H12.5a.5.5 0 00.5-.5v-2a.5.5 0 011 0v2a1.5 1.5 0 01-1.5 1.5h-11a1.5 1.5 0 01-1.5-1.5v-9a1.5 1.5 0 011.5-1.5H3v-2h1v2zm5 4V5h1v1h2v1h-2v1H9V7H7v2h1v1H7v2H6v-2H4v2H3v-2H1V9h2V7H1V6h2V5h1v1h2V5h1v1h2zM6 9V7H4v2h2z"></path>
                            </svg>
                          </div>
                          <div className="custoom-icon-calender custoom-icon-calender-all">
                            <DatePicker
                              oneTap
                              showOneCalendar
                              ranges={ranges}
                              format="dd/MM/yyyy"
                              value={issueDate}
                              onChange={handleIssueDateChange}
                              placement="auto"
                              required
                              isoWeek={appSettings.weekStartDay === 'Monday'}
                              timeZone={global.config.getTimeZone()}
                            />
                          </div>
                        </div>

                      )}
                    </div>
                    {issueDateIsValid && (
                      <span className="text-red-500 text-xs italic">
                        Issue Date Cannot be empty
                      </span>
                    )}
                  </div>
                </div>
                <div className="date-due-manual-edm">
                  <div className="invo-date-issue-tile also-for-dued-cal">
                    Due Date
                  </div>
                  <div className="invo-date-due-cal">
                    <div className="calender-date-due-pic" ref={dueDateRef}>
                      {isLoading ? (
                        <ThreeDots
                          height="18"
                          width="20"
                          radius="9"
                          color="#6479f9"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClassName=""
                          visible={true}
                        />
                      ) : (
                        <div className="date-picker-outer date-picker-outer-all">
                          <div className="custom-picker-icon custom-picker-icon-all">
                            <svg
                              width="1em"
                              height="1em"
                              viewBox="0 0 14 14"
                              fill="currentColor"
                              aria-hidden="true"
                              focusable="false"
                              class="rs-picker-toggle-caret rs-icon"
                              aria-label="calendar"
                              data-category="legacy"
                            >
                              <path d="M1 4v8.5a.5.5 0 00.5.5H8c0-.128.049-.256.146-.354.555-.555.854-1.6.854-3.146a.5.5 0 01.621-.485l.119.03A2.623 2.623 0 0012.999 6.5V4h-12zm3-2h6V0h1v2h1.5A1.5 1.5 0 0114 3.5v3a3.623 3.623 0 01-4.015 3.603c-.064 1.245-.335 2.212-.831 2.898H12.5a.5.5 0 00.5-.5v-2a.5.5 0 011 0v2a1.5 1.5 0 01-1.5 1.5h-11a1.5 1.5 0 01-1.5-1.5v-9a1.5 1.5 0 011.5-1.5H3v-2h1v2zm5 4V5h1v1h2v1h-2v1H9V7H7v2h1v1H7v2H6v-2H4v2H3v-2H1V9h2V7H1V6h2V5h1v1h2V5h1v1h2zM6 9V7H4v2h2z"></path>
                            </svg>
                          </div>
                          <div className="custoom-icon-calender custoom-icon-calender-all">
                            <DatePicker
                              oneTap
                              showOneCalendar
                              ranges={ranges}
                              format="dd/MM/yyyy"
                              value={dueDate}
                              onChange={handleDueDateChange}
                              placement="bottomEnd"
                              required
                              isoWeek={appSettings.weekStartDay === 'Monday'}
                              timeZone={global.config.getTimeZone()}
                            />
                          </div>
                        </div>

                      )}
                    </div>
                    {dueDateIsValid && (
                      <span className="text-red-500 text-xs italic">
                        Due Date Cannot be empty
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="sheed-sec-after-headem">
              <div className="tooltip-boxmain">
                {showTooltip && (
                  <Tooltip
                    className="custom-tooltip"
                    content={[
                      {
                        heading: "Regular Invoice",
                        content:
                          "Records the products or services delivered, the total amount due, and preferred payment method",
                        headingClass: "heading1tool",
                        contentClass: "content1tool",
                      },
                      {
                        heading: "Reinbursement Invoice",
                        content:
                          "Used when the subject is paid back for expenses.",
                        headingClass: "heading1tool second-toolhead",
                        contentClass: "content1tool",
                      },
                    ]}
                  />
                )}
              </div>
              <div className="inp-sec2-with2">
                <div className="input-field-inof">
                  <label htmlFor="invoice-title">User Name</label>
                  {isLoading ? (
                    <div>
                      <ThreeDots
                        height="18"
                        width="20"
                        radius="9"
                        color="#6479f9"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        className="read-only-input"
                        wrapperClassName=""
                        visible={true}
                      />
                    </div>
                  ) : (
                    <input
                      type="text"
                      id="user-name"
                      className="read-only-input"
                      name="user-name"
                      value={userName}
                      required
                      readOnly
                    />
                  )}
                </div>
                <div className="input-field-inof">
                  <label htmlFor="bank-account">Bank Account</label>
                  {(isLoading || isBankLoading) ? (
                    <ThreeDots
                      height="18"
                      width="20"
                      radius="9"
                      color="#6479f9"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  ) : (
                    // <select
                    //   onChange={handleDropdownChange}
                    //   value={invoiceBankListName}
                    //   className="type-select-me"
                    //   required
                    // >
                    //   <option value="" disabled hidden></option>
                    //   {bankDetails.length > 0 && bankDetails.map((bank) => (
                    //     <option
                    //       key={bank.id}
                    //       value={`${bank.id}_${bank.currency}`}

                    //     >
                    //       {bank.details.accountNumber && (
                    //         <>{bank.details.accountNumber}</>
                    //       )}
                    //       {bank.details.IBAN && <>{bank.details.IBAN}</>}
                    //       {bank.currency && (
                    //         <div>
                    //           {" ("}
                    //           {bank.country}
                    //           {")"}
                    //         </div>
                    //       )}
                    //     </option>
                    //   ))}
                    // </select>
                    <div ref={bankAccountDropdownRef} className="custom-dropdown-user-new-outer">
                      <div className="selected-option custom-dropdown-user-new" onClick={handleToggleDropdown}>
                        {invoiceBankListName ? (
                          <>
                            {`${bankDetails.find((bank) => `${bank.id}_${bank.currency}` === invoiceBankListName)?.details.accountNumber || ''}`}
                            {`${bankDetails.find((bank) => `${bank.id}_${bank.currency}` === invoiceBankListName)?.details.accountNumber &&
                              bankDetails.find((bank) => `${bank.id}_${bank.currency}` === invoiceBankListName)?.details.IBAN
                              ? ' '
                              : ''
                              }`}
                            {`${bankDetails.find((bank) => `${bank.id}_${bank.currency}` === invoiceBankListName)?.details.IBAN || ''} (${invoiceBankCurrency})`}
                          </>
                        ) : 'Select Bank'}
                      </div>
                      {bankAccountDropdownOpen && (
                        <div className="custom-dropdown-new">
                          <input
                            type="text"
                            className="search-bar"
                            placeholder="Search..."
                            value={searchBankAccountTerm}
                            onChange={handleSearchInputChange}
                          />
                          <ul className="bank-account-list">
                            {filteredBankDetails.length > 0 ? (
                              filteredBankDetails.map((bank) => (
                                <li
                                  key={`${bank.id}_${bank.currency}`}
                                  onClick={() => handleSelectOption(`${bank.id}_${bank.currency}`)}
                                >
                                  {`${bank.details.accountNumber || ''}${bank.details.accountNumber &&
                                    bank.details.IBAN
                                    ? ' '
                                    : ''
                                    }${bank.details.IBAN || ''} (${bank.currency})`}
                                </li>
                              ))
                            ) : (
                              <li>No results found</li>
                            )}
                          </ul>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>

              <div className="inp-sec2-with2 inp-sec2-with2-one">
                <div className="input-field-inof">
                  <label htmlFor="invoice-title">Invoice Title</label>
                  <input
                    type="text"
                    id="invoice-title"
                    placeholder="Enter invoice Title"
                    name="invoice-title"
                    onChange={(e) => setTitle(e.target.value)}
                    value={title}
                    required
                    className={titleIsValid ? "input-validation-error" : ""}
                    ref={titleRef}
                  />
                  {titleIsValid && (
                    <span className="text-red-500 text-xs italic">
                      Title Cannot be empty
                    </span>
                  )}
                </div>

              </div>

              {invType === 'Regular' ? (
                <div className="detail-add-parteditmanual">
                  <table className="invoice-table-editmanual">
                    <thead className="the-abantant-edittxh">
                      <tr>
                        <th>Description</th>
                        <th>Cost</th>
                        <th>VAT %</th>
                        <th>Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {productDetails.map((product, index) => (
                        <tr key={index}>
                          <td className="description-manual">
                            {isLoading ? (
                              <ThreeDots
                                height="18"
                                width="20"
                                radius="9"
                                color="#6479f9"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                              />
                            ) : (
                              <>
                                <input
                                  type="text"
                                  list="projectOptions"
                                  value={product.description}
                                  name="description"
                                  onChange={(e) => handleInputChange(e, index, "description")}
                                  required
                                  className={descriptionIsValid ? "input-validation-error" : ""}
                                  placeholder="Description"
                                  ref={descriptionRef}
                                  autoComplete="off"
                                />
                                {selUserCategory !== userCategory.Vendor && (
                                  <datalist id="projectOptions" style={{ position: 'absolute', top: '20%', left: 0 }}>
                                    <option value="">Select Project</option>
                                    {projectId
                                      .slice()
                                      .sort((a, b) => a.project_name.toLowerCase().localeCompare(b.project_name.toLowerCase()))
                                      .map((project) => (
                                        <option
                                          key={project._id}
                                          value={project.project_name}
                                        >
                                          {project.project_name}
                                        </option>
                                      ))}
                                  </datalist>
                                )}
                                {/* <select
                                  type="select"
                                  value={product.description}
                                  name="description"
                                  onChange={(e) => {
                                    const projecttestId = projectId.find(project => project.project_name === e.target.value);
                                    handleInputChange(e, index, "description");
                                    handleProjIdInputChange(e, index, projecttestId?._id);
                                  }}
                                  required
                                  className={descriptionIsValid ? "input-validation-error" : ""}
                                  placeholder="Description"
                                  ref={descriptionRef}
                                >
                                  <option value="">Select Project</option>
                                  {projectId
                                    .slice()
                                    .sort((a, b) => a.project_name.toLowerCase().localeCompare(b.project_name.toLowerCase()))
                                    .map((project) => (
                                      <option
                                        key={project._id}
                                        value={project.project_name}
                                      >
                                        {project.project_name}
                                      </option>
                                    ))}
                                </select> */}
                              </>
                            )}
                          </td>
                          <td className="edit-manual-costtr">
                            {isLoading ? (
                              <ThreeDots
                                height="18"
                                width="20"
                                radius="9"
                                color="#6479f9"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                              />
                            ) : (
                              <input
                                type="number"
                                step="any"
                                data-message="Please add Cost"
                                name="cost"
                                min="0"
                                data-no={index + 1}
                                required
                                placeholder="Cost"
                                onChange={(e) =>
                                  handleInputChange(e, index, "cost")
                                }
                                className={costIsValid ? "input-validation-error" : ""}
                                value={product.cost}
                                ref={costRef}
                                onWheel={numberInputOnWheelPreventChange}
                              />
                            )}
                          </td>
                          <td className="editmanual-vattd">
                            {isLoading ? (
                              <ThreeDots
                                height="18"
                                width="20"
                                radius="9"
                                color="#6479f9"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                              />
                            ) : (
                              <input
                                type="number"
                                step="any"
                                data-message="Please add VAT"
                                name="tax"
                                min="0"
                                data-no={index + 1}
                                required
                                placeholder="VAT %"
                                onChange={(e) => handleInputChange(e, index, "tax")}
                                className={taxIsValid ? "input-validation-error" : ""}
                                value={product.tax}
                                ref={taxRef}
                                onWheel={numberInputOnWheelPreventChange}
                              />
                            )}
                          </td>
                          <td className="editmanual-amount-tr">
                            <div className="amount-cell">
                              {isLoading ? (
                                <ThreeDots
                                  height="18"
                                  width="20"
                                  radius="9"
                                  color="#6479f9"
                                  ariaLabel="three-dots-loading"
                                  wrapperStyle={{}}
                                  wrapperClassName=""
                                  visible={true}
                                />
                              ) : (
                                <span className="delete-icon">
                                  {invoiceBankCurrency ? (
                                    <>
                                      {product.amount ? (
                                        <>
                                          {new Intl.NumberFormat("en-US", {
                                            style: "currency",
                                            currency: invoiceBankCurrency,
                                          }).format(product.amount)}
                                        </>
                                      ) : (
                                        <>
                                          {new Intl.NumberFormat("en-US", {
                                            style: "currency",
                                            currency: invoiceBankCurrency,
                                          }).format("00.00")}
                                        </>
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      {product.amount ? (
                                        <span>{product.amount}</span>
                                      ) : (
                                        <span>00.00</span>
                                      )}
                                    </>
                                  )}
                                  {productDetails.length !== 1 && (
                                    <button className="del-icon-main-sel"
                                      type="button"
                                      onClick={() => handleRemoveRow(index)}
                                    >
                                      <img src={dletImage} alt="delete-icon" />{" "}
                                    </button>
                                  )}
                                </span>
                              )}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <button className="add-item-button" onClick={handleAddRow}>
                    <img src={addicoplus} alt="addicon" /> Add Item
                  </button>
                </div>
              ) : (
                <div className="detail-add-parteditmanual">
                  <table className="invoice-table-editmanual">
                    <thead className="the-abantant-edittxh ssss">
                      <tr>
                        <th>Descriptions</th>
                        <th>Category</th>
                        <th>VAT %</th>
                        <th>Cost</th>
                        <th>Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {productDetails.map((product, index) => (
                        <tr key={index}>
                          <td className="description-manual">
                            {isLoading ? (
                              <ThreeDots
                                height="18"
                                width="20"
                                radius="9"
                                color="#6479f9"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                              />
                            ) : (
                              <input
                                type="text"
                                data-message="Please add Description"
                                name="description"
                                required
                                placeholder="Description"
                                onChange={(e) =>
                                  handleInputChange(e, index, "description")
                                }
                                className={descriptionIsValid ? "input-validation-error" : ""}
                                value={product.description}
                                ref={descriptionRef}
                              />
                            )}
                          </td>
                          <td className="description-manual">
                            {isLoading ? (
                              <ThreeDots
                                height="18"
                                width="20"
                                radius="9"
                                color="#6479f9"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                              />
                            ) : (
                              <select
                                type="select"
                                value={product.expenseCategory}
                                name="expenseCategory"
                                onChange={(e) =>
                                  handleInputChange(e, index, "expenseCategory")
                                }
                                required
                                className={expenseCategoryIsValid ? "input-validation-error" : ""}
                                placeholder="Expense Category"
                                ref={descriptionRef}
                              >
                                <option value="">Select Categoty</option>
                                
                                {expenseCategory?.data && expenseCategory?.data
                                  .slice()
                                  .sort((a, b) => a.exp_category_name.toLowerCase().localeCompare(b.exp_category_name.toLowerCase()))
                                  .map((expense) => (
                                    <option
                                      key={expense._id}
                                      value={expense.exp_category_name}
                                    >
                                      {expense.exp_category_name}
                                    </option>
                                  ))}
                              </select>
                            )}
                          </td>
                          <td className="editmanual-vattd">
                            {isLoading ? (
                              <ThreeDots
                                height="18"
                                width="20"
                                radius="9"
                                color="#6479f9"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                              />
                            ) : (
                              <input
                                type="number"
                                step="any"
                                data-message="Please add VAT"
                                name="tax"
                                min="0"
                                data-no={index + 1}
                                required
                                placeholder="VAT %"
                                onChange={(e) => handleInputChange(e, index, "tax")}
                                className={taxIsValid ? "input-validation-error" : ""}
                                value={product.tax}
                                ref={taxRef}
                                onWheel={numberInputOnWheelPreventChange}
                              />
                            )}
                          </td>
                          <td className="edit-manual-costtr">
                            {isLoading ? (
                              <ThreeDots
                                height="18"
                                width="20"
                                radius="9"
                                color="#6479f9"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                              />
                            ) : (
                              <input
                                type="number"
                                step="any"
                                data-message="Please add Cost"
                                name="cost"
                                min="0"
                                data-no={index + 1}
                                required
                                placeholder="Cost"
                                onChange={(e) =>
                                  handleInputChange(e, index, "cost")
                                }
                                className={costIsValid ? "input-validation-error" : ""}
                                value={product.cost}
                                ref={costRef}
                                onWheel={numberInputOnWheelPreventChange}
                              />
                            )}
                          </td>

                          <td className="editmanual-amount-tr">
                            <div className="amount-cell">
                              <button className="delete-icon">
                                {invoiceBankCurrency ? (
                                  <>
                                    {product.amount ? (
                                      <>
                                        {new Intl.NumberFormat("en-US", {
                                          style: "currency",
                                          currency: invoiceBankCurrency,
                                        }).format(product.amount)}
                                      </>
                                    ) : (
                                      <>
                                        {new Intl.NumberFormat("en-US", {
                                          style: "currency",
                                          currency: invoiceBankCurrency,
                                        }).format("00.00")}
                                      </>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {product.amount ? (
                                      <span>{product.amount}</span>
                                    ) : (
                                      <span>00.00</span>
                                    )}
                                  </>
                                )}
                                {productDetails.length !== 1 && (
                                  <button className="del-icon-main-sel"
                                    type="button"
                                    onClick={() => handleRemoveRow(index)}
                                  >
                                    <img src={dletImage} alt="delete-icon" />{" "}
                                  </button>
                                )}
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <button className="add-item-button" onClick={handleAddRow}>
                    <img src={addicoplus} alt="addicon" /> Add Item
                  </button>
                </div>
              )}
            </div>
            <div className="sec-after-tab-ls-part">
              <div className="sec-withother-and-add">
                <div className="other-secp1">
                  <div className="other-head1">Other comments</div>
                  <div className="other-subhead1">
                    {isLoading ? (
                      <ThreeDots
                        height="18"
                        width="20"
                        radius="9"
                        color="#6479f9"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                      />
                    ) : (
                      <textarea
                        type="text"
                        className=""
                        placeholder="Comment here"
                        value={otherComnt}
                        onChange={(e) => setOtherComnt(e.target.value)}
                        style={{ resize: "none" }}
                      ></textarea>
                    )}
                  </div>
                </div>
                <div className="file-add-p1-attachment">
                  <div className="attachment-head">Attachments</div>
                  {isLoading ? (
                    <ThreeDots
                      height="18"
                      width="20"
                      radius="9"
                      color="#6479f9"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  ) : (
                    // <div className="style-portion-browsetext" style={{ cursor: "pointer" }}>
                    //   <label className="add-item-button-end-browse" style={{ cursor: "pointer" }}>
                    //     <img src={addicoplus} alt="addicon" /> Browse
                    //     <input
                    //       ref={fileInputRef}
                    //       type="file"
                    //       style={{ display: 'none' }}
                    //       onChange={handleInvDocChange}
                    //     />
                    //   </label>
                    //   <div className="upload-file-name-br">
                    //     {invDocumentFileName && (
                    //       <>
                    //         <CompanyDocumentField companyDocument={invDocumentFileName} />
                    //       </>
                    //     )}
                    //   </div>
                    // </div>
                    <div className="style-portion-browsetext" style={{ cursor: 'pointer' }}>
                      <label className="add-item-button-end-browse">
                        <img src={addicoplus} alt="addicon" /> Browse
                        <input
                          ref={fileInputRef}
                          type="file"
                          style={{ display: 'none' }}
                          onChange={handleInvDocChange}
                          multiple // Allow multiple file selection
                        />
                      </label>
                      <div className="upload-file-name-br">
                        {invFiles.map((file, index) => (
                          <div key={index} className="uploaded-file-item">
                            <span>{file.name}</span>
                            <a onClick={() => removeFile(index)}>Remove</a>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="invo-total-specialcard">
                <div className="specialcard-outter">
                  <table className="to-ino-to-special">
                    <tr>
                      <th className="sub-lem-tb-fisl">Subtotal</th>
                      <td className="sub-price-tb-fisl">
                        {isLoading ? (
                          <ThreeDots
                            height="18"
                            width="20"
                            radius="9"
                            color="#6479f9"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                          />
                        ) : (
                          <>
                            {invoiceBankCurrency ? (
                              <>
                                {new Intl.NumberFormat("en-US", {
                                  style: "currency",
                                  currency: invoiceBankCurrency,
                                }).format(subTotal)}
                              </>
                            ) : (
                              <span>{subTotal}</span>
                            )}
                          </>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th className="sub-vat-tb-fisl">VAT</th>
                      <td className="sub-vatpr-tb-fisl">
                        {isLoading ? (
                          <ThreeDots
                            height="18"
                            width="20"
                            radius="9"
                            color="#6479f9"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                          />
                        ) : (
                          <>
                            {invoiceBankCurrency ? (
                              <>
                                {new Intl.NumberFormat("en-US", {
                                  style: "currency",
                                  currency: invoiceBankCurrency,
                                }).format(taxTotal)}
                              </>
                            ) : (
                              <span>{taxTotal}</span>
                            )}
                          </>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th className="sub-invoto-tb-fisl">Invoice Total</th>
                      <td className="sub-ivvoto-price-tb-fisl">
                        {isLoading ? (
                          <ThreeDots
                            height="18"
                            width="20"
                            radius="9"
                            color="#6479f9"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                          />
                        ) : (
                          <>
                            {invoiceBankCurrency ? (
                              <>
                                {new Intl.NumberFormat("en-US", {
                                  style: "currency",
                                  currency: invoiceBankCurrency,
                                }).format(total)}
                              </>
                            ) : (
                              <span>{total}</span>
                            )}
                          </>
                        )}
                      </td>
                    </tr>
                  </table>
                  <button
                    className="largecreate-invo-btn"
                    type="submit"
                    disabled={updateIsLoading}
                  >
                    {updateIsLoading ? "Updating Invoice..." : "Update Invoice"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default InvoiceEditManual;