import React from "react";
import Moment from "react-moment";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import TimeTotal from "../helpers/TimeTotal.jsx";
import zoneConverter from "../helpers/zoneConverter.jsx";

const CardHeaderImportData = ({ item }) => {
    const formatDateWithLabel = (date) => {
        const today = new Date();
        const yesterday = new Date(today);
        yesterday.setDate(yesterday.getDate() - 1);

        if (date.toDateString() === today.toDateString()) {
            return "Today";
        } else if (date.toDateString() === yesterday.toDateString()) {
            return "Yesterday";
        } else {
            return <Moment format="ddd, MMM D" tz={global.config.getTimeZone()}>{date}</Moment>;
        }
    };

    return (
        <Card.Header>
            <Row className="card-header-sec">
                <Col className="card-header-date">
                    {formatDateWithLabel(new Date(item[0]))}
                </Col>
                <div className="separation-head"></div>
                {item[0] === Date.now() ? (
                    <Col className="card-timer">
                        Total:{" "}
                        <Moment
                            interval={1000}
                            date={item[0]}
                            format="hh:mm:ss"
                            durationFromNow
                            tz={global.config.getTimeZone()}
                        />{" "}
                    </Col>
                ) : (
                    <Col className="card-timer">
                        <TimeTotal item={item} />
                    </Col>
                )}
            </Row>
        </Card.Header>
    );
};

export default CardHeaderImportData;