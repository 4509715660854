const cliInvPaymStatus = {
  cli_inv_paym_status: [
    {
      _id: 0,
      status: "Pending",
    },
    // {
    //   _id: 1,
    //   status: "Payment Processing",
    // },
    {
      _id: 2,
      status: "Payment Processed",
    },
    // {
    //   _id: 3,
    //   status: "Payment Processed",
    // },
    {
      _id: 4,
      status: "Overdue",
    },
    {
      _id: 5,
      status: "Rejected",
    },
    // {
    //   _id: 6,
    //   status: "Cancelled",
    // },
    // {
    //   _id: 7,
    //   status: "Refunded",
    // },
  ],
};
export default cliInvPaymStatus;
