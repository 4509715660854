import moment from "moment";
import Modal from "react-modal";
import "../equipment/equipment.css";
import "./expenseTable.css";
import axios from "axios";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { DatePicker } from "rsuite";
import subDays from "date-fns/subDays";
import { toast } from "react-toastify";
import { ThreeDots } from "react-loader-spinner";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import PlusIcon from "../../images/icons/plus-icon.svg";
import Plus from "../../images/icons/plus-black.svg";
import React, { useState, useEffect, useRef } from "react";
import crssrImage from "../../images/singleinvoice/cross-red.svg";
import { setHeaderText } from "../../features/headerText/headerTextSlice";
import { logout, reset } from "../../features/auth/authSlice";
import { reset as invoiceReset } from "../../features/invoice/invoiceSlice.js";
import { reset as headerReset } from "../../features/headerText/headerTextSlice.js";
import { reset as appSettingsReset } from "../../features/appSettings/appSettingsSlice.js";
import { setNotification } from "../../features/notification/notificationSlice.js";
import TruncateFieldView from "../../components/helpers/truncateFieldView.js";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Delete from "../../images/icons/delete.svg";
import profile_image from "../../images/user/Default_pfp.png";
import editImage from "../../images/singleinvoice/edit.svg";
import Close from "../../images/icons/black-close.svg";
import Tick from "../../images/singleinvoice/tick-green.svg";
import { useActiveUser, useUserById } from "../../components/TimeOff/timeOffApi.jsx";
import Currencies from "../../constants/currenciesList.js";
import DownloadImage from "../../images/icons/download-icon.svg";
import { useFetchExpCategory } from "../../components/AppSettings/expenseCategoryApi.jsx";
import { useGetClients, useGetProjects } from "../../components/TimeTracker/TimeTrackerOverview/useTimeTracker.jsx";
import { useWXCompanies } from "../../components/User/UserDetails/UserProfile/useUserView.jsx";

const ranges = [
  {
    label: "today",
    value: new Date(), // Use a single date instead of an array
  },
  {
    label: "yesterday",
    value: subDays(new Date(), 1),
  },
];

const useRenderExpenseUser = (rowData) => {
  const [showFullname, setShowFullname] = useState(false);

  const fullName = rowData.exp_user?.first_name
    ? `${rowData.exp_user.first_name} ${rowData.exp_user.middle_name && rowData.exp_user.middle_name !== "null"
      ? rowData.exp_user.middle_name + " "
      : ""
    }${rowData.exp_user.last_name}`
    : rowData.exp_user?.company_first_name
      ? `${rowData.exp_user.company_first_name} ${rowData.exp_user.company_last_name ? rowData.exp_user.company_last_name : ""
      }`
      : rowData.exp_user?.company_name;
  const displayName = showFullname
    ? fullName
    : fullName.length <= 15
      ? fullName
      : fullName.slice(0, 15) + "...";

  return (
    <div
      className={`member-name${showFullname ? " active" : ""}`}
      // onMouseEnter={() => setShowFullname(true)}
      onMouseEnter={() => {
        if (fullName.length > 15) {
          setShowFullname(true);
        }
      }}
      onMouseLeave={() => setShowFullname(false)}
    >
      {rowData.exp_user?.profile_image !== null &&
        rowData.exp_user?.profile_image !== "null" ? (
        <img
          src={`/uploads/lowProfileImage/${rowData.exp_user?.profile_image}`}
          alt={fullName}
          className="member-image"
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = "/uploads/lowProfileImage/user_default.png";
          }}
        />
      ) : (
        <img src={profile_image} alt={fullName} className="member-image" />
      )}
      <span>{displayName}</span>
    </div>
  );
};

const ExpenseTable = (props) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [equipEditModalOpen, setEquipEditModalOpen] = useState(false);
  const [filter, setFilter] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [expenseIdEdit, setExpenseIdEdit] = useState("");
  const [expDescriptionEdit, setExpDescriptionEdit] = useState("");
  const [expDescriptionView, setExpDescriptionView] = useState("");
  const [expenseDateEdit, setExpenseDateEdit] = useState(null);
  const [expenseDateView, setExpenseDateView] = useState(null);
  const [expCategoryEdit, setExpCategoryEdit] = useState("");
  const [expCategoryView, setExpCategoryView] = useState("");
  const [expCurrencyEdit, setExpCurrencyEdit] = useState("");
  const [expCurrencyView, setExpCurrencyView] = useState("");
  const [expSubTotalEdit, setExpSubTotalEdit] = useState("");
  const [expSubTotalView, setExpSubTotalView] = useState("");
  const [expTaxEdit, setExpTaxEdit] = useState("");
  const [expTaxView, setExpTaxView] = useState("");
  const [expAmountEdit, setExpAmountEdit] = useState(0);
  const [expAmountView, setExpAmountView] = useState(0);
  const [expIsBillableEdit, setExpIsBillableEdit] = useState(true);
  const [expIsBillableView, setExpIsBillableView] = useState(true);
  const [expenseUserEdit, setExpenseUserEdit] = useState("");
  const [expenseUserView, setExpenseUserView] = useState("");
  const [expenseClientEdit, setExpenseClientEdit] = useState("");
  const [expenseClientView, setExpenseClientView] = useState("");
  const [expenseProjectEdit, setExpenseProjectEdit] = useState("");
  const [expenseProjectView, setExpenseProjectView] = useState("");
  const [expenseCompanyEdit, setExpenseCompanyEdit] = useState("");
  const [expenseCompanyView, setExpenseCompanyView] = useState("");
  const [expNoteEdit, setExpNoteEdit] = useState("");
  const [expNoteView, setExpNoteView] = useState("");
  const [expFileAttchFilesEdit, setExpFileAttchFilesEdit] = useState([]);
  const [removedFiles, setRemovedFiles] = useState([]);
  const [expFileAttchFileNameEdit, setExpFileAttchFileNameEdit] = useState([]);
  const [expFileAttchFileNameView, setExpFileAttchFileNameView] = useState([]);
  const [expFileAttchFileSizeView, setExpFileAttchFileSizeView] = useState([]);
  const [expClientSearchTerm, setExpClientSearchTerm] = useState('');
  const [expProjectSearchTerm, setExpProjectSearchTerm] = useState('');
  const [expCompanySearchTerm, setExpCompanySearchTerm] = useState('');
  const [userSearchTerm, setUserSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [selectAllByPage, setSelectAllByPage] = useState({});
  const [isEqpDeleteModalOpen, setIsEqpDeleteModalOpen] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [expCurrencySearchTerm, setExpCurrencySearchTerm] = useState('');
  const [expCurrencyEditDropdownOpen, setExpCurrencyEditDropdownOpen] = useState(false);
  const [expenseCategoryEditDropdownOpen, setExpenseCategoryEditDropdownOpen] = useState(false);
  const [expenseUserEditDropdownOpen, setExpenseUserEditDropdownOpen] = useState(false);
  const [expenseClientEditDropdownOpen, setExpenseClientEditDropdownOpen] = useState(false);
  const [expenseProjectEditDropdownOpen, setExpenseProjectEditDropdownOpen] = useState(false);
  const [expenseCompanyEditDropdownOpen, setExpenseCompanyEditDropdownOpen] = useState(false);
  const [expenseDescriptionEditIsValid, setExpenseDescriptionEditIsValid] = useState(false);
  const [expenseDateEditIsValid, setExpenseDateEditIsValid] = useState(false);
  const [expenseCategoryEditIsValid, setExpenseCategoryEditIsValid] = useState(false);
  const [expenseCurrencyEditIsValid, setExpenseCurrencyEditIsValid] = useState(false);
  const [expSubTotalEditIsValid, setExpSubTotalEditIsValid] = useState(false);
  const [expTaxEditIsValid, setExpTaxEditIsValid] = useState(false);
  const [expenseUserEditIsValid, setExpenseUserEditIsValid] = useState(false);
  const [expenseClientEditIsValid, setExpenseClientEditIsValid] = useState(false);
  const [expenseCompanyEditIsValid, setExpenseCompanyEditIsValid] = useState(false);
  const [isExpenseEdit, setIsExpenseEdit] = useState(false);
  const [expCurrencyList, setExpCurrencyList] = useState([]);
  const expenseDateEditRef = useRef(null);
  const expenseDescriptionEditRef = useRef(null);
  const expenseCategoryEditRef = useRef(null);
  const expenseCurrencyEditRef = useRef(null);
  const expSubTotalEditRef = useRef(null);
  const expTaxEditRef = useRef(null);
  const expenseUserEditRef = useRef(null);
  const expenseClientEditRef = useRef(null);
  const expenseCompanyEditRef = useRef(null);
  const expFileAttchRef = useRef(null);


  const expenseCategoryEditDropdownRef = useRef(null);
  const expCurrencyEditDropdownRef = useRef(null);
  const expenseUserSelectEditDropdownRef = useRef(null);
  const expenseClientSelectEditDropdownRef = useRef(null);
  const expenseProjectSelectEditDropdownRef = useRef(null);
  const expenseCompanySelectEditDropdownRef = useRef(null);

  const { appSettings } = useSelector((state) => state.appSettings);
  const expenseData = useSelector((state) => state.expenseData)

  let token = null;
  const { user } = useSelector((state) => state.auth);
  if (user) {
    if (user && user.token) {
      token = user.token;
    }
  }

  const { data: userIds = [], isLoading: isLoadingUser } = useUserById(token);
  const { data: activeUser = [], isLoading: isLoadingActiveUser } = useActiveUser(token);
  const { data: clients = [], isLoading: isLoadingClient, isError: clientError } = useGetClients(token);
  const { data: projects = [], isLoading: isLoadingProject, isError: projectError } = useGetProjects(token);
  const { data: wXCompany = [], isLoading: WXCompaniesLoading, isError: WXCompaniesError } = useWXCompanies();
  const { data: expenseCategory = [], isLoading: isLoadingExpCategory } = useFetchExpCategory(token);

  const handleClickOutsideDropdown = (event) => {
    const isClickInsideExpenseCategoryEditDropdown = expenseCategoryEditDropdownRef.current && expenseCategoryEditDropdownRef.current.contains(event.target);
    const isClickInsideExpCurrencyEditDropdown = expCurrencyEditDropdownRef.current && expCurrencyEditDropdownRef.current.contains(event.target);
    const isClickInsideExpenseUserEditDropdown = expenseUserSelectEditDropdownRef.current && expenseUserSelectEditDropdownRef.current.contains(event.target);
    const isClickInsideExpenseClientEditDropdown = expenseClientSelectEditDropdownRef.current && expenseClientSelectEditDropdownRef.current.contains(event.target);
    const isClickInsideExpenseProjectEditDropdown = expenseProjectSelectEditDropdownRef.current && expenseProjectSelectEditDropdownRef.current.contains(event.target);
    const isClickInsideExpenseCompanyEditDropdown = expenseCompanySelectEditDropdownRef.current && expenseCompanySelectEditDropdownRef.current.contains(event.target);

    if (!isClickInsideExpenseCategoryEditDropdown) {
      setExpenseCategoryEditDropdownOpen(false);
    }
    if (!isClickInsideExpCurrencyEditDropdown) {
      setExpCurrencyEditDropdownOpen(false);
    }
    if (!isClickInsideExpenseUserEditDropdown) {
      setExpenseUserEditDropdownOpen(false);
    }
    if (!isClickInsideExpenseClientEditDropdown) {
      setExpenseClientEditDropdownOpen(false);
    }
    if (!isClickInsideExpenseProjectEditDropdown) {
      setExpenseProjectEditDropdownOpen(false);
    }
    if (!isClickInsideExpenseCompanyEditDropdown) {
      setExpenseCompanyEditDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutsideDropdown);
    return () => {
      document.removeEventListener('click', handleClickOutsideDropdown);
    };
  }, []);

  function detailCTA(rowData) {
    return (
      <div className="detail-button">
        <button className="cta-button" onClick={() => handleDetailClick(rowData)}>
          Details
        </button>
      </div>
    );
  }

  const handleDetailClick = async (rowData) => {
    setSelectedId(rowData._id);
    if (rowData.exp_id) {
      setExpenseIdEdit(rowData.exp_id);
    }
    if (rowData.exp_description) {
      setExpDescriptionEdit(rowData.exp_description);
      setExpDescriptionView(rowData.exp_description);
    } else {
      setExpDescriptionEdit("");
      setExpDescriptionView("");
    }
    if (rowData.exp_date) {
      const expenseDate = new Date(rowData.exp_date);
      const formattedExpenseDate = expenseDate.toLocaleDateString('en-US', {
        month: 'long',
        day: '2-digit',
        year: 'numeric'
      });
      setExpenseDateEdit(new Date(rowData.exp_date));
      setExpenseDateView(formattedExpenseDate);
    }
    if (rowData.exp_category) {
      setExpCategoryEdit(rowData.exp_category._id);
      setExpCategoryView(rowData.exp_category.exp_category_name);
    }
    if (rowData.exp_currency) {
      setExpCurrencyEdit(rowData.exp_currency);
      setExpCurrencyView(rowData.exp_currency);
    }
    if (rowData.exp_sub_total) {
      setExpSubTotalEdit(rowData.exp_sub_total);
      setExpSubTotalView(rowData.exp_sub_total);
    } else {
      setExpSubTotalEdit("");
      setExpSubTotalView("");
    }
    if (rowData.exp_tax) {
      setExpTaxEdit(rowData.exp_tax);
      setExpTaxView(rowData.exp_tax);
    } else {
      setExpTaxEdit("");
      setExpTaxView("");
    }
    if (rowData.exp_amount) {
      setExpAmountEdit(rowData.exp_amount);
      setExpAmountView(rowData.exp_amount);
    }
    setExpIsBillableEdit(rowData.exp_is_billable);
    setExpIsBillableView(rowData.exp_is_billable);
    if (rowData.exp_user) {
      setExpenseUserEdit(rowData.exp_user._id);
      setExpenseUserView(rowData.exp_user._id);
    }
    if (rowData.exp_client) {
      setExpenseClientEdit(rowData.exp_client._id);
      setExpenseClientView(rowData.exp_client._id);
    } else {
      setExpenseClientEdit("");
      setExpenseClientView("");
    }
    if (rowData.exp_project) {
      setExpenseProjectEdit(rowData.exp_project._id);
      setExpenseProjectView(rowData.exp_project._id);
    } else {
      setExpenseProjectEdit("");
      setExpenseProjectView("");
    }
    if (rowData.exp_wx_company) {
      setExpenseCompanyEdit(rowData.exp_wx_company._id);
      setExpenseCompanyView(rowData.exp_wx_company._id);
    } else {
      setExpenseCompanyEdit("");
      setExpenseCompanyView("");
    }
    if (rowData.exp_note) {
      setExpNoteEdit(rowData.exp_note);
      setExpNoteView(rowData.exp_note);
    }
    if (rowData.exp_file_attch) {
      setExpFileAttchFileNameEdit(rowData.exp_file_attch);
      setExpFileAttchFileNameView(rowData.exp_file_attch);
      setExpFileAttchFileSizeView(rowData.exp_file_attch_size);
      setRemovedFiles([]);
    } else {
      setExpFileAttchFilesEdit([]);
      setExpFileAttchFileNameEdit([]);
      setExpFileAttchFileNameView([]);
      setExpFileAttchFileSizeView([]);
      setRemovedFiles([]);
    }
    setEquipEditModalOpen(true);
  }

  const handleCloseDetailsModal = () => {
    setSelectedId("");
    setExpenseIdEdit("");
    setExpenseDateEdit(null);
    setExpenseDateView(null);
    setExpDescriptionEdit("");
    setExpDescriptionView("");
    setExpCategoryEdit("");
    setExpCategoryView("");
    setExpCurrencyEdit("");
    setExpCurrencyView("");
    setExpSubTotalEdit("");
    setExpSubTotalView("");
    setExpTaxEdit("");
    setExpTaxView("");
    setExpAmountEdit(0);
    setExpAmountView(0);
    setExpIsBillableEdit(true);
    setExpIsBillableView(true);
    setExpenseUserEdit("");
    setExpenseUserView("");
    setExpenseClientEdit("");
    setExpenseClientView("");
    setExpenseProjectEdit("");
    setExpenseProjectView("");
    setExpenseCompanyEdit("");
    setExpenseCompanyView("");
    setExpNoteEdit("");
    setExpNoteView("");
    setExpFileAttchFilesEdit([]);
    setExpFileAttchFileNameEdit([]);
    setExpFileAttchFileNameView([]);
    setExpFileAttchFileSizeView([]);
    setRemovedFiles([]);
    setIsExpenseEdit(false);
    setEquipEditModalOpen(false);
  };

  const handleSetExpenseEdit = () => {
    setIsExpenseEdit(true);
  };

  const handleResetExpenseEdit = () => {
    setIsExpenseEdit(false);
  };

  const handleDateEditChange = (date) => {
    setExpenseDateEdit(date);
  };

  const disabledDate = date => {
    const tomorrow = new Date();
    // today.setHours(0, 0, 0, 0);
    tomorrow.setDate(tomorrow.getDate() + 1);
    return date > tomorrow;
  };

  const handleToggleExpCurrencyEditDropdown = () => {
    setExpCurrencySearchTerm('');
    setExpCurrencyEditDropdownOpen((prevOpen) => !prevOpen);
  };

  const handleExpCurrencySearchInputChange = (e) => {
    setExpCurrencySearchTerm(e.target.value);
  };

  const filteredExpCurrencies = expCurrencyList.filter((expCurrency) => {
    const expCurrencyName = expCurrency.currencyCode
      && `${expCurrency.currencyCode} (${expCurrency.currencyName})`;
    return expCurrencyName?.toLowerCase().includes(expCurrencySearchTerm?.toLowerCase());
  });

  const handleToggleExpenseUserEditDropdown = () => {
    setUserSearchTerm('');
    setExpenseUserEditDropdownOpen((prevOpen) => !prevOpen);
  };

  const handleSelectExpenseUserEdit = (selectedExpenseUser) => {
    setExpenseUserEdit(selectedExpenseUser);
    setUserSearchTerm('');
    setExpenseUserEditDropdownOpen(false);
  };

  const handleExpenseUserSearchInputChange = (e) => {
    setUserSearchTerm(e.target.value);
  };

  const filteredUsers = activeUser.filter((user) => {
    const fullName = user.first_name
      ? `${user.first_name} ${user.middle_name || ''} ${user.last_name}`
      : `${user.company_first_name ? user.company_first_name + (user.company_last_name && user.company_last_name) : user.company_name}`;
    return fullName?.toLowerCase().includes(userSearchTerm?.toLowerCase());
  });

  const handleToggleExpenseClientEditDropdown = () => {
    setExpClientSearchTerm('');
    setExpenseClientEditDropdownOpen((prevOpen) => !prevOpen);
  };

  const handleSelectExpenseClientEdit = (selectedExpenseClient) => {
    setExpenseClientEdit(selectedExpenseClient);
    setExpClientSearchTerm('');
    setExpenseClientEditDropdownOpen(false);
  };

  const handleExpenseClientSearchInputChange = (e) => {
    setExpClientSearchTerm(e.target.value);
  };

  const filteredClients = clients
    .filter((expClient) =>
      expClient.client_name?.toLowerCase().includes(expClientSearchTerm?.toLowerCase())
    )
    .sort((a, b) =>
      a.client_name?.toLowerCase().localeCompare(b.client_name?.toLowerCase())
    );

  const handleToggleExpenseProjectEditDropdown = () => {
    setExpProjectSearchTerm('');
    setExpenseProjectEditDropdownOpen((prevOpen) => !prevOpen);
  };

  const handleSelectExpenseProjectEdit = (selectedExpenseProject) => {
    setExpenseProjectEdit(selectedExpenseProject);
    setExpProjectSearchTerm('');
    setExpenseProjectEditDropdownOpen(false);
  };

  const handleExpenseProjectSearchInputChange = (e) => {
    setExpProjectSearchTerm(e.target.value);
  };

  const filteredProjects = projects
    .filter((expProject) =>
      expProject.client_name?._id === expenseClientEdit &&
      expProject.project_name?.toLowerCase().includes(expProjectSearchTerm?.toLowerCase())
    )
    .sort((a, b) =>
      a.project_name?.toLowerCase().localeCompare(b.project_name?.toLowerCase())
    );

  const handleToggleExpenseCompanyEditDropdown = () => {
    setExpCompanySearchTerm('');
    setExpenseCompanyEditDropdownOpen((prevOpen) => !prevOpen);
  };

  const handleSelectExpenseCompanyEdit = (selectedExpenseCompany) => {
    setExpenseCompanyEdit(selectedExpenseCompany);
    setExpCompanySearchTerm('');
    setExpenseCompanyEditDropdownOpen(false);
  };

  const handleExpenseCompanySearchInputChange = (e) => {
    setExpCompanySearchTerm(e.target.value);
  };

  const filteredCompany = wXCompany
    .filter((expCompany) =>
      expCompany.wx_company?.toLowerCase().includes(expCompanySearchTerm?.toLowerCase())
    )
    .sort((a, b) =>
      a.wx_company?.toLowerCase().localeCompare(b.wx_company?.toLowerCase())
    );

  const allowedExtensions = ["pdf", "doc", "docx", "msword", "xls", "xlsx", "csv", "jpeg", "jpg", "png", "gif"];

  // const handleExpFileAttchEditUpload = (e) => {
  //   const file = e.target.files[0];
  //   if (file) {
  //     const fileExtension = file.name.split(".").pop().toLowerCase();
  //     if (!allowedExtensions.includes(fileExtension)) {
  //       toast.error("Invalid file format. Allowed formats are pdf, doc, docx, msword, xls, xlsx, csv, jpeg, jpg, png, gif.", {
  //         position: "bottom-right",
  //         autoClose: 2000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //       });
  //       e.target.value = null;
  //       setExpFileAttchEdit(null);
  //       setExpFileAttchFileNameEdit("");
  //       return;
  //     }
  //     setExpFileAttchEdit(e.target.files[0])
  //     setExpFileAttchFileNameEdit(file.name)
  //   } else {
  //     setExpFileAttchEdit(null)
  //     setExpFileAttchFileNameEdit("")
  //   }
  // };

  const handleExpFileAttchEditUpload = (event) => {
    const selectedFiles = event.target.files;

    if (selectedFiles.length > 0) {
      if (selectedFiles.length + expFileAttchFilesEdit.length > 10) {
        // Display toast error message when the total number of files exceeds 10
        toast.error('Maximum of 10 files can be uploaded!', {
          position: 'bottom-right',
          autoClose: 3500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return;
      }
      const newFiles = Array.from(selectedFiles);

      // Validate file extensions and update state
      const validFiles = newFiles.filter((file) => {
        const fileExtension = file.name.split('.').pop()?.toLowerCase();
        if (allowedExtensions.includes(fileExtension)) {
          return true;
        } else {
          toast.error(`Invalid file format for ${file.name}. Allowed formats are pdf, doc, docx, msword, xls, xlsx, csv.!!`, {
            position: 'bottom-right',
            autoClose: 3500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          return false;
        }
      });

      // Update the state with the valid files
      setExpFileAttchFilesEdit((prevFiles) => [...prevFiles, ...validFiles]);

      // Clear the file input for multiple selections
      expFileAttchRef.current.value = '';
    }
  };

  const removeFile = (index) => {
    // Remove the file at the specified index
    setExpFileAttchFilesEdit((prevFiles) => {
      const updatedFiles = [...prevFiles];
      updatedFiles.splice(index, 1);
      return updatedFiles;
    });
  };

  const handleRemoveFile = (file, index) => {
    const updatedFiles = [...expFileAttchFileNameEdit];
    updatedFiles.splice(index, 1);
    setExpFileAttchFileNameEdit(updatedFiles);
    setRemovedFiles([...removedFiles, file]);
  };

  function getFileExtension(filename) {
    const lastIndex = filename.lastIndexOf(".");
    if (lastIndex !== -1) {
      return filename.slice(lastIndex + 1);
    }
    return "";
  }

  const downloadExpenseDoc = async (expfile) => {
    try {
      const response = await axios.get(`/api/expense/doc/download/${expfile}`, {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const pdfBlob = new Blob([response.data], { type: "application/pdf" });
      saveAs(pdfBlob, `${expfile}`);
    } catch (error) {
      console.error(error);
      global.config.slackMessage(error.toString());
      if (error.response?.data?.status === "deleted") {
        localStorage.removeItem("user");
        dispatch(logout());
        dispatch(reset());
        dispatch(invoiceReset());
        dispatch(headerReset());
        dispatch(appSettingsReset());
        toast.error("This user is deleted!", {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/");
        return;
      }
      // Handle error
    }
  };

  const handleEqpDelete = () => {
    setIsEqpDeleteModalOpen(true);
  };

  const handleConfirmEqpDelete = async () => {
    try {
      setIsSubmitLoading(true);

      const response = await axios.get(`/api/expense/delete/${selectedId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      toast.success("Expense data removed successfully", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      dispatch(setNotification(Date.now()));
      handleCloseDetailsModal();
      global.config.activityLog(window.location.href, 'Expense', `Expense (${selectedId}) deleted successfully`);
      // expenseRefetch();
    } catch (error) {
      console.error(error);
      global.config.slackMessage(error.toString());
      if (error.response?.data?.status === "deleted") {
        localStorage.removeItem("user");
        dispatch(logout());
        dispatch(reset());
        dispatch(invoiceReset());
        dispatch(headerReset());
        dispatch(appSettingsReset());
        toast.error('This user is deleted!', {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/");
        return;
      }
      toast.error("Something went wrong", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } finally {
      setIsSubmitLoading(false);
      handleCancelEqpDelete();
    }
  };

  const handleCancelEqpDelete = () => {
    setSelectedId("");
    setIsEqpDeleteModalOpen(false);
  };


  const onPageChange = (e) => {
    const newPage = e.page;
    // Store the current "Select All" state for the current page
    setSelectAllByPage((prevSelectAllByPage) => ({
      ...prevSelectAllByPage,
    }));
    setCurrentPage(e.page);
  };

  useEffect(() => {
    dispatch(setHeaderText("Expenses"));
    setExpCurrencyList(Currencies.sourceCurrencies[0].targetCurrencies);
  }, [props.expenseData]);

  useEffect(() => {
    setCurrentPage(0);
  }, [expenseData]);


  const useRenderExpCategory = (rowData) => {
    return (
      <span>{rowData?.exp_category && rowData.exp_category.exp_category_name}</span>
    );
  }

  const renderExpAmount = (rowData) => {
    var totalAmount = rowData.exp_amount.toLocaleString("en-US", {
      style: "currency",
      currency: rowData.exp_currency,
    });

    return <span> {totalAmount}</span>;
  };

  const renderExpIsBillable = (rowData) => {
    // Determine the class based on the payment_status value
    let statusClass;
    let statusName;
    if (rowData.exp_is_billable === true) {
      statusClass = "yes";
      statusName = "Yes";
    } else if (rowData.exp_is_billable === false) {
      statusClass = "no";
      statusName = "No";
    }
    return (
      <span className={`payment-status ${statusClass}`}>{statusName}</span>
    );
  };

  const convertExpenseDate = (rowData) => {
    const timestamp = rowData.exp_date;
    const formattedDate = moment(timestamp).format('MMMM DD, YYYY');
    return formattedDate;
  }

  const calculateAmount = (expSubTotalEdit, expTaxEdit) => {
    const subTotalAmount = expSubTotalEdit ? parseFloat(expSubTotalEdit) : 0;
    const taxPercentage = expTaxEdit ? parseFloat(expTaxEdit) : 0;

    if (!isNaN(subTotalAmount) && !isNaN(taxPercentage)) {
      const taxAmount = (subTotalAmount * taxPercentage) / 100;
      return subTotalAmount + taxAmount;
    } else {
      return '';
    }
  };

  const handleExpEditSubmit = async (event) => {
    event.preventDefault();
    setExpenseDateEditIsValid(false);
    setExpenseDescriptionEditIsValid(false);
    setExpenseCategoryEditIsValid(false);
    setExpenseCurrencyEditIsValid(false);
    setExpSubTotalEditIsValid(false);
    setExpTaxEditIsValid(false);
    setExpenseUserEditIsValid(false);
    setExpenseClientEditIsValid(false);
    setExpenseCompanyEditIsValid(false);
    if (
      expenseDateEdit === null ||
      expDescriptionEdit === "" ||
      expCurrencyEdit === "" ||
      expCategoryEdit === "" ||
      expSubTotalEdit === "" ||
      isNaN(expSubTotalEdit) ||
      expTaxEdit === "" ||
      isNaN(expTaxEdit) ||
      expenseUserEdit === "" ||
      (expIsBillableEdit && expenseClientEdit === "" ? true : false) ||
      (!expIsBillableEdit && expenseCompanyEdit === "" ? true : false)
    ) {
      if (expDescriptionEdit === "") {
        setExpenseDescriptionEditIsValid(true);
        expenseDescriptionEditRef && expenseDescriptionEditRef.current.focus();
        toast.error("Expense Description cannot be empty!", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return;
      } else if (expenseDateEdit === null) {
        setExpenseDateEditIsValid(true);
        expenseDateEditRef && expenseDateEditRef.current.focus();
        toast.error("Expense date cannot be empty!", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return;
      } else if (expCategoryEdit === "") {
        setExpenseCategoryEditIsValid(true);
        expenseCategoryEditRef && expenseCategoryEditRef.current.focus();
        toast.error("Expense category cannot be empty!", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return;
      } else if (expCurrencyEdit === "") {
        setExpenseCurrencyEditIsValid(true);
        expenseCurrencyEditRef && expenseCurrencyEditRef.current.focus();
        toast.error("Expense currency cannot be empty!", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return;
      } else if (expSubTotalEdit === "" || isNaN(expSubTotalEdit)) {
        setExpSubTotalEditIsValid(true);
        expSubTotalEditRef && expSubTotalEditRef.current.focus();
        toast.error("Expense sub total cannot be empty!", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return;
      } else if (expTaxEdit === "" || isNaN(expTaxEdit)) {
        setExpTaxEditIsValid(true);
        expTaxEditRef && expTaxEditRef.current.focus();
        toast.error("Expense Tax cannot be empty!", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return;
      } else if (expenseUserEdit === "") {
        setExpenseUserEditIsValid(true);
        expenseUserEditRef && expenseUserEditRef.current.focus();
        toast.error("User has to be seleted!", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return;
      } else if (expIsBillableEdit && expenseClientEdit === "") {
        setExpenseClientEditIsValid(true);
        expenseClientEditRef && expenseClientEditRef.current.focus();
        toast.error("Client has to be seleted!", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return;
      } else if (!expIsBillableEdit && expenseCompanyEdit === "") {
        setExpenseCompanyEditIsValid(true);
        expenseCompanyEditRef && expenseCompanyEditRef.current.focus();
        toast.error("Company has to be seleted!", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return;
      }

      toast.error("Please fill all required fields!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    const requestData = {
      exp_date: expenseDateEdit,
      exp_description: expDescriptionEdit,
      exp_category: expCategoryEdit,
      exp_currency: expCurrencyEdit,
      exp_sub_total: expSubTotalEdit,
      exp_tax: expTaxEdit,
      exp_amount: expAmountEdit,
      exp_is_billable: expIsBillableEdit,
      exp_user: expenseUserEdit,
      expenseClientEdit: expIsBillableEdit ? expenseClientEdit : null,
      expenseProjectEdit: expIsBillableEdit ? expenseProjectEdit : null,
      expenseCompanyEdit: !expIsBillableEdit ? expenseCompanyEdit : null,
      exp_note: expNoteEdit,
    };

    const formData = new FormData();
    // if (expFileAttchEdit) {
    //   formData.append("exp_file_attch", expFileAttchEdit);
    // }
    if (removedFiles.length > 0) {
      requestData.removed_files = JSON.stringify(removedFiles);
    }
    if (expFileAttchFilesEdit) {
      for (let i = 0; i < expFileAttchFilesEdit.length; i++) {
        formData.append("exp_file_attch", expFileAttchFilesEdit[i]);
      }
    }
    for (let key in requestData) {
      formData.append(key, requestData[key]);
    }
    try {
      setIsSubmitLoading(true);
      await axios.put(`/api/expense/update/${selectedId}`, formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })

      toast.success("Expenses updated successfully", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      dispatch(setNotification(Date.now()));
      handleCloseDetailsModal();
      global.config.activityLog(window.location.href, 'Expense', `Expense data of (${expenseIdEdit}) updated successfully`);
    } catch (error) {
      console.log(error);
      global.config.slackMessage(error.toString());
      if (error.response?.data?.status === "deleted") {
        localStorage.removeItem("user");
        dispatch(logout());
        dispatch(reset());
        dispatch(invoiceReset());
        dispatch(headerReset());
        dispatch(appSettingsReset());
        toast.error('This user is deleted!', {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/");
        return;
      }
      alert("Something went wrong");
    } finally {
      setIsSubmitLoading(false); // Set isLoading to false after the approval process is complete
    }
  };

  const numberInputOnWheelPreventChange = (e) => {
    // Prevent the input value change
    e.target.blur()

    // Prevent the page/container scrolling
    e.stopPropagation()

    setTimeout(() => {
      e.target.focus()
    }, 0)
  }

  return (
    <div className="client-document-outer">

      <div className="user-outer-timeoff document-client-top-sec expense-table"  >


        <Modal
          className="client-modal project-modal"
          isOpen={equipEditModalOpen}
          onRequestClose={handleCloseDetailsModal}
          contentLabel="View and Edit Expense"
          style={{
            content: {
              height: "41%",
              width: "30%",
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              parent: document.querySelector(".admin-outer.time.tracker"),
            },
          }}
        >
          <div className="modal">
            <div className="modal-close-invite outter-modal-main">
              <a className="" onClick={handleCloseDetailsModal}>
                <img src={crssrImage} alt="Edit Icon" />{" "}
              </a>
            </div>
            <div className="project-detail-modal">
              <div className="modal-client-head ">
                {isExpenseEdit ? (
                  <h2 className="">Edit Expense Details</h2>
                ) : (
                  <h2 className="">Expense Details</h2>
                )}
              </div>
              <div className="form-field-client-outer">
                <form>
                  <div className="form-field-client">
                    {isExpenseEdit || expenseIdEdit ? (
                      <label htmlFor="expenseId" className="form-label">
                        Expense ID
                      </label>
                    ) : null}
                    {expenseIdEdit && (
                      <p className="form-input">{expenseIdEdit}</p>
                    )}
                  </div>
                  {isExpenseEdit || expDescriptionView ? (
                    <div className="form-field-client">
                      <label htmlFor="expDescriptionEdit" className="form-label">
                        Description
                      </label>
                      {isExpenseEdit ? (
                        <>
                          <input
                            type="text"
                            id="expDescriptionEdit"
                            name="expDescriptionEdit"
                            className="form-input"
                            value={expDescriptionEdit}
                            onChange={(e) => setExpDescriptionEdit(e.target.value)}
                            ref={expenseDescriptionEditRef}
                            required
                          />
                          {expenseDescriptionEditIsValid && (
                            <span className="text-red-500 text-xs italic">
                              Equipment Description Field Cannot be empty
                            </span>
                          )}
                        </>
                      ) : (
                        <p className="form-textarea">
                          {expDescriptionView}
                        </p>
                      )}
                    </div>
                  ) : null}
                  {isExpenseEdit || expenseDateView ? (
                    <div className="form-field-client">
                      <label htmlFor="time" className="form-label">
                        Date
                      </label>
                      {isExpenseEdit ? (
                        <>
                          <div className="date-picker-outer date-picker-outer-all">
                            <div className="custoom-icon-calender custoom-icon-calender-all" ref={expenseDateEditRef}>
                              <DatePicker
                                oneTap
                                format="dd/MM/yyyy"
                                ranges={ranges}
                                value={expenseDateEdit}
                                onChange={handleDateEditChange}
                                placement="auto"
                                className="invoice-filter-top-date"
                                isoWeek={appSettings.weekStartDay && appSettings.weekStartDay === 'Monday'}
                                timeZone={global.config.getTimeZone()}
                                disabledDate={disabledDate}
                              />
                            </div>
                            <div className="custom-picker-icon custom-picker-icon-all">
                              <svg
                                width="1em"
                                height="1em"
                                viewBox="0 0 14 14"
                                fill="currentColor"
                                aria-hidden="true"
                                focusable="false"
                                class="rs-picker-toggle-caret rs-icon"
                                aria-label="calendar"
                                data-category="legacy"
                              >
                                <path d="M1 4v8.5a.5.5 0 00.5.5H8c0-.128.049-.256.146-.354.555-.555.854-1.6.854-3.146a.5.5 0 01.621-.485l.119.03A2.623 2.623 0 0012.999 6.5V4h-12zm3-2h6V0h1v2h1.5A1.5 1.5 0 0114 3.5v3a3.623 3.623 0 01-4.015 3.603c-.064 1.245-.335 2.212-.831 2.898H12.5a.5.5 0 00.5-.5v-2a.5.5 0 011 0v2a1.5 1.5 0 01-1.5 1.5h-11a1.5 1.5 0 01-1.5-1.5v-9a1.5 1.5 0 011.5-1.5H3v-2h1v2zm5 4V5h1v1h2v1h-2v1H9V7H7v2h1v1H7v2H6v-2H4v2H3v-2H1V9h2V7H1V6h2V5h1v1h2V5h1v1h2zM6 9V7H4v2h2z"></path>
                              </svg>
                            </div>
                          </div>

                          {expenseDateEditIsValid && (
                            <span className="text-red-500 text-xs italic">
                              Select the Expense date
                            </span>
                          )}
                        </>
                      ) : (
                        <p className="form-input">{expenseDateView}</p>
                      )}
                    </div>
                  ) : null}

                  <div className="form-field-client">
                    {isExpenseEdit || expCategoryView ? (
                      <label htmlFor="category" className="form-label">
                        Category
                      </label>
                    ) : null}
                    {isExpenseEdit ? (
                      <>
                        {isLoadingExpCategory == true ? (
                          <div className="outter-load-table">
                            <ThreeDots
                              height="18"
                              width="20"
                              radius="9"
                              color="#405BFF"
                              ariaLabel="three-dots-loading"
                              wrapperStyle={{}}
                              wrapperClassName=""
                              visible={true}
                            />
                          </div>
                        ) : (
                          <div
                            ref={expenseCategoryEditDropdownRef}
                            className={`cursor ${expenseCategoryEditDropdownOpen ? 'open' : ''}`}
                          >
                            <span
                              id="user-select"
                              onClick={() => setExpenseCategoryEditDropdownOpen(!expenseCategoryEditDropdownOpen)}
                              className={`custom-dropdown-user-new custom-dropdown-select ${expenseCategoryEditIsValid ? 'input-validation-error' : ''}`}
                              ref={expenseCategoryEditRef}
                            >
                              {expCategoryEdit ? expenseCategory.find((eqp) => eqp._id === expCategoryEdit)?.exp_category_name : 'Select'}
                            </span>
                            {expenseCategoryEditDropdownOpen && (
                              <div className="custom-dropdown-new">
                                <ul className="leave-type-list">
                                  {expenseCategory &&
                                    expenseCategory
                                      .slice()
                                      .sort((a, b) => a.exp_category_name?.toLowerCase().localeCompare(b.exp_category_name?.toLowerCase()))
                                      .map((exp) => (
                                        <li
                                          key={exp._id}
                                          className={expCategoryEdit === exp._id ? 'selected-item' : ''}
                                          onClick={() => {
                                            setExpCategoryEdit(exp._id);
                                            setExpenseCategoryEditDropdownOpen(false);
                                          }}
                                        >
                                          {exp.exp_category_name}
                                        </li>
                                      ))}
                                </ul>
                              </div>
                            )}
                          </div>
                        )}
                        {expenseCategoryEditIsValid && (
                          <span className="text-red-500 text-xs italic">
                            Expense Category Field Cannot be empty
                          </span>
                        )}
                      </>
                    ) : (
                      <p className="form-input">{expCategoryView}</p>
                    )}
                  </div>

                  <div className="form-field-client">
                    {isExpenseEdit || expCurrencyView ? (
                      <label htmlFor="currency" className="form-label">
                        Currency
                      </label>
                    ) : null}
                    {isExpenseEdit ? (
                      <>
                        <div ref={expCurrencyEditDropdownRef} className={`cursor ${expCurrencyEditDropdownOpen ? 'open' : ''}`} >
                          <span
                            id="user-select"
                            onClick={handleToggleExpCurrencyEditDropdown}
                            className={`custom-dropdown-user-new custom-dropdown-user ${expenseCurrencyEditIsValid ? 'input-validation-error' : ''}`}
                            ref={expenseCurrencyEditRef}
                          >
                            {expCurrencyEdit ? expCurrencyList.find((c) => c.currencyCode === expCurrencyEdit)?.currencyCode : 'Select Currency'}
                          </span>
                          {expCurrencyEditDropdownOpen && (
                            <div className="custom-dropdown-new">
                              <input
                                type="text"
                                className="search-bar"
                                placeholder="Search..."
                                value={expCurrencySearchTerm}
                                onChange={handleExpCurrencySearchInputChange}
                              />
                              <ul className="currency-list">
                                {filteredExpCurrencies.length > 0 &&
                                  filteredExpCurrencies.map((c) => (
                                    <li
                                      key={c.currencyCode}
                                      className={expCurrencyEdit === c.currencyCode ? 'selected-item' : ''}
                                      onClick={() => {
                                        setExpCurrencyEdit(c.currencyCode);
                                        setExpCurrencyEditDropdownOpen(false);
                                      }}
                                    >
                                      {`${c.currencyCode} (${c.currencyName})`}
                                    </li>
                                  ))}
                              </ul>
                            </div>
                          )}
                        </div>

                        {expenseCurrencyEditIsValid && (
                          <span className="text-red-500 text-xs italic">
                            Expense currency needs to be selected
                          </span>
                        )}
                      </>
                    ) : (
                      <p className="form-input">{expCurrencyEdit ? expCurrencyList.find((c) => c.currencyCode === expCurrencyEdit)?.currencyName : 'Select Currency'}</p>
                    )}
                  </div>

                  {isExpenseEdit || expSubTotalView ? (
                    <div className="form-field-client">
                      <label htmlFor="expSubTotalEdit" className="form-label">
                        Sub Total
                      </label>
                      {isExpenseEdit ? (
                        <>
                          <input
                            type="number"
                            id="expSubTotalEdit"
                            name="expSubTotalEdit"
                            className="form-input"
                            value={expSubTotalEdit}
                            // onChange={(e) => setExpSubTotalEdit(e.target.value)}
                            onChange={(e) => {
                              setExpSubTotalEdit(parseFloat(e.target.value));
                              setExpAmountEdit(calculateAmount(parseFloat(e.target.value), expTaxEdit));
                            }}
                            ref={expSubTotalEditRef}
                            required
                            onWheel={numberInputOnWheelPreventChange}
                          />
                          {expSubTotalEditIsValid && (
                            <span className="text-red-500 text-xs italic">
                              Expense sub total field cannot be empty
                            </span>
                          )}
                        </>
                      ) : (
                        <p className="form-textarea">
                          {expSubTotalView}
                        </p>
                      )}
                    </div>
                  ) : null}

                  {isExpenseEdit || expTaxView ? (
                    <div className="form-field-client">
                      <label htmlFor="amountEdit" className="form-label">
                        Tax (%)
                      </label>
                      {isExpenseEdit ? (
                        <>
                          <input
                            type="number"
                            id="expTaxEdit"
                            name="expTaxEdit"
                            className="form-input"
                            value={expTaxEdit}
                            // onChange={(e) => setExpTaxEdit(e.target.value)}
                            onChange={(e) => {
                              setExpTaxEdit(parseFloat(e.target.value));
                              setExpAmountEdit(calculateAmount(expSubTotalEdit, parseFloat(e.target.value)));
                            }}
                            ref={expTaxEditRef}
                            onWheel={numberInputOnWheelPreventChange}
                            required
                          />
                          {expTaxEditIsValid && (
                            <span className="text-red-500 text-xs italic">
                              Expense tax field cannot be empty
                            </span>
                          )}
                        </>
                      ) : (
                        <p className="form-textarea">
                          {expTaxView}
                        </p>
                      )}
                    </div>
                  ) : null}

                  {isExpenseEdit || expAmountView ? (
                    <div className="form-field-client">
                      <label htmlFor="amountEdit" className="form-label">
                        Amount (Subtotal + Tax)
                      </label>
                      {isExpenseEdit ? (
                        <input
                          type="number"
                          id="expAmountEdit"
                          name="expAmountEdit"
                          className="form-input read-only-input"
                          // value={expAmountEdit}
                          // onChange={(e) => setExpAmountEdit(e.target.value)}
                          value={calculateAmount(expSubTotalEdit, expTaxEdit)}
                          onWheel={numberInputOnWheelPreventChange}
                          readOnly
                          required
                        />
                      ) : (
                        <p className="form-textarea">
                          {expAmountView}
                        </p>
                      )}
                    </div>
                  ) : null}

                  {/* {isExpenseEdit || expIsBillableView ? ( */}
                  <div className="form-field-client">
                    <label htmlFor="time" className="form-label">
                      Billable
                    </label>
                    {isExpenseEdit ? (
                      <div className="radio-container">
                        <div className="radio-option yes">
                          <input
                            type="radio"
                            id="yes"
                            name="expIsBillable"
                            value={expIsBillableEdit}
                            checked={expIsBillableEdit === true}
                            onChange={(e) => setExpIsBillableEdit(true)}
                          />
                          <label htmlFor="yes">
                            Yes <img src={Tick} alt="Tick" />
                          </label>
                        </div>
                        <div className="radio-option no">
                          <input
                            type="radio"
                            id="no"
                            name="expIsBillable"
                            value={expIsBillableEdit}
                            checked={expIsBillableEdit === false}
                            onChange={(e) => setExpIsBillableEdit(false)}
                          />
                          <label htmlFor="no">
                            No <img src={Close} alt="Edit Icon" />
                          </label>
                        </div>
                      </div>
                    ) : (
                      <p className="form-input">{expIsBillableView ? "Yes" : "No"}</p>
                    )}
                  </div>
                  {/* ) : null} */}

                  <div className="form-field-client">
                    {isExpenseEdit || expenseUserView ? (
                      <label htmlFor="category" className="form-label">
                        User
                      </label>
                    ) : null}
                    {isExpenseEdit ? (
                      <>
                        {(isLoadingUser == true || isLoadingActiveUser) ? (
                          <div className="outter-load-table">
                            <ThreeDots
                              height="18"
                              width="20"
                              radius="9"
                              color="#405BFF"
                              ariaLabel="three-dots-loading"
                              wrapperStyle={{}}
                              wrapperClassName=""
                              visible={true}
                            />
                          </div>
                        ) : (
                          <div ref={expenseUserSelectEditDropdownRef} className={`cursor ${expenseUserEditDropdownOpen ? 'open' : ''}`} >
                            <span
                              id="user-select"
                              onClick={handleToggleExpenseUserEditDropdown}
                              className={`custom-dropdown-user-new custom-dropdown-user ${expenseUserEditIsValid ? 'input-validation-error' : ''}`}
                              ref={expenseUserEditRef}
                            >
                              {expenseUserEdit ? (
                                userIds?.find((user) => user._id === expenseUserEdit)?.first_name ? (
                                  <>
                                    {userIds?.find((user) => user._id === expenseUserEdit)?.first_name}{' '}
                                    {userIds?.find((user) => user._id === expenseUserEdit)?.middle_name &&
                                      userIds?.find((user) => user._id === expenseUserEdit)?.middle_name !== 'null'
                                      ? userIds?.find((user) => user._id === expenseUserEdit)?.middle_name + ' '
                                      : ''}
                                    {userIds?.find((user) => user._id === expenseUserEdit)?.last_name}{' '}
                                  </>
                                ) : (
                                  <>
                                    {userIds.find((user) => user._id === expenseUserEdit)?.company_first_name
                                      ? `${userIds.find((user) => user._id === expenseUserEdit)?.company_first_name} ${userIds.find((user) => user._id === expenseUserEdit)?.company_last_name
                                        ? userIds.find((user) => user._id === expenseUserEdit)?.company_last_name
                                        : ''
                                      }`
                                      : `${userIds.find((user) => user._id === expenseUserEdit)?.company_name || ''}(Company)`}
                                  </>
                                )
                              ) : (
                                'Select Expense User'
                              )}
                            </span>
                            {expenseUserEditDropdownOpen && (
                              <div className="custom-dropdown-new">
                                <input
                                  type="text"
                                  className="search-bar"
                                  placeholder="Search..."
                                  value={userSearchTerm}
                                  onChange={handleExpenseUserSearchInputChange}
                                />
                                <ul className="client-list">
                                  {filteredUsers.length > 0 &&
                                    filteredUsers
                                      .slice()
                                      .sort((a, b) => {
                                        const nameA = a.first_name
                                          ? `${a.first_name} ${a.middle_name || ''} ${a.last_name}`
                                          : `${a.company_first_name ? a.company_first_name + (a.company_last_name && a.company_last_name) : a.company_name}`;
                                        const nameB = b.first_name
                                          ? `${b.first_name} ${b.middle_name || ''} ${b.last_name}`
                                          : `${b.company_first_name ? b.company_first_name + (b.company_last_name && b.company_last_name) : b.company_name}`;
                                        return nameA.localeCompare(nameB);
                                      })
                                      .map((item) => (
                                        <li key={item._id} className={expenseUserEdit === item._id ? 'selected-item' : ''} onClick={() => handleSelectExpenseUserEdit(item._id)}>
                                          {item.first_name ? (
                                            <>
                                              {item.first_name}{' '}
                                              {item.middle_name && item.middle_name !== 'null' ? item.middle_name + ' ' : ''}
                                              {item.last_name}{' '}
                                            </>
                                          ) : (
                                            <>
                                              {item.company_first_name
                                                ? `${item.company_first_name} ${item.company_last_name ? item.company_last_name : ''}`
                                                : `${item.company_name || ''}(Company)`}
                                            </>
                                          )}
                                        </li>
                                      ))
                                  }
                                </ul>
                              </div>
                            )}
                          </div>
                        )}

                        {expenseUserEditIsValid && (
                          <span className="text-red-500 text-xs italic">
                            Expense User needs to be selected
                          </span>
                        )}
                      </>
                    ) : (
                      <p className="form-input">
                        {isLoadingUser == true ? (
                          <div className="outter-load-table">
                            <ThreeDots
                              height="18"
                              width="20"
                              radius="9"
                              color="#405BFF"
                              ariaLabel="three-dots-loading"
                              wrapperStyle={{}}
                              wrapperClassName=""
                              visible={true}
                            />
                          </div>
                        ) : (
                          <>
                            {expenseUserView ? (
                              userIds?.find((user) => user._id === expenseUserView)?.first_name ? (
                                <>
                                  {userIds?.find((user) => user._id === expenseUserView)?.first_name}{' '}
                                  {userIds?.find((user) => user._id === expenseUserView)?.middle_name &&
                                    userIds?.find((user) => user._id === expenseUserView)?.middle_name !== 'null'
                                    ? userIds?.find((user) => user._id === expenseUserView)?.middle_name + ' '
                                    : ''}
                                  {userIds?.find((user) => user._id === expenseUserView)?.last_name}{' '}
                                </>
                              ) : (
                                <>
                                  {userIds.find((user) => user._id === expenseUserView)?.company_first_name
                                    ? `${userIds.find((user) => user._id === expenseUserView)?.company_first_name} ${userIds.find((user) => user._id === expenseUserView)?.company_last_name
                                      ? userIds.find((user) => user._id === expenseUserView)?.company_last_name
                                      : ''
                                    }`
                                    : `${userIds.find((user) => user._id === expenseUserView)?.company_name || ''}(Company)`}
                                </>
                              )
                            ) : (
                              ''
                            )}
                          </>
                        )}
                      </p>
                    )}
                  </div>

                  {expIsBillableEdit ? (
                    <>
                      <div className="form-field-client">
                        {isExpenseEdit || expenseClientView ? (
                          <label htmlFor="expClient" className="form-label">
                            Client
                          </label>
                        ) : null}
                        {isExpenseEdit ? (
                          <>
                            {isLoadingClient == true ? (
                              <div className="outter-load-table">
                                <ThreeDots
                                  height="18"
                                  width="20"
                                  radius="9"
                                  color="#405BFF"
                                  ariaLabel="three-dots-loading"
                                  wrapperStyle={{}}
                                  wrapperClassName=""
                                  visible={true}
                                />
                              </div>
                            ) : (
                              <div ref={expenseClientSelectEditDropdownRef} className={`cursor ${expenseClientEditDropdownOpen ? 'open' : ''}`} >
                                <span
                                  id="user-select"
                                  onClick={handleToggleExpenseClientEditDropdown}
                                  className={`custom-dropdown-user-new custom-dropdown-user ${expenseClientEditIsValid ? 'input-validation-error' : ''}`}
                                  ref={expenseClientEditRef}
                                >
                                  {expenseClientEdit ? (
                                    clients.find((client) => client._id === expenseClientEdit)?.client_name
                                  ) : (
                                    'Select'
                                  )}
                                </span>
                                {expenseClientEditDropdownOpen && (
                                  <div className="custom-dropdown-new">
                                    <input
                                      type="text"
                                      className="search-bar"
                                      placeholder="Search..."
                                      value={expClientSearchTerm}
                                      onChange={handleExpenseClientSearchInputChange}
                                    />
                                    <ul className="client-list">
                                      {filteredClients.map((client) => (
                                        <li
                                          key={client._id}
                                          className={expenseClientEdit === client._id ? 'selected-item' : ''}
                                          onClick={() => {
                                            setExpenseClientEdit(client._id);
                                            setExpClientSearchTerm('');
                                            setExpenseClientEditDropdownOpen(false);
                                          }}
                                        >
                                          {client.client_name}
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                )}
                              </div>
                            )}

                            {expenseClientEditIsValid && (
                              <span className="text-red-500 text-xs italic">
                                Expense User needs to be selected
                              </span>
                            )}
                          </>
                        ) : (
                          <p className="form-input">
                            {isLoadingClient == true ? (
                              <div className="outter-load-table">
                                <ThreeDots
                                  height="18"
                                  width="20"
                                  radius="9"
                                  color="#405BFF"
                                  ariaLabel="three-dots-loading"
                                  wrapperStyle={{}}
                                  wrapperClassName=""
                                  visible={true}
                                />
                              </div>
                            ) : (
                              <>
                                {expenseClientEdit ? (
                                  clients.find((client) => client._id === expenseClientEdit)?.client_name
                                ) : (
                                  ''
                                )}
                              </>
                            )}
                          </p>
                        )}
                      </div>
                      {expenseClientEdit && (
                        <div className="form-field-client">
                          {isExpenseEdit || expenseProjectView ? (
                            <label htmlFor="expProject" className="form-label">
                              Project
                            </label>
                          ) : null}
                          {isExpenseEdit ? (
                            <>
                              {isLoadingProject == true ? (
                                <div className="outter-load-table">
                                  <ThreeDots
                                    height="18"
                                    width="20"
                                    radius="9"
                                    color="#405BFF"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                  />
                                </div>
                              ) : (
                                <div ref={expenseProjectSelectEditDropdownRef} className={`cursor ${expenseProjectEditDropdownOpen ? 'open' : ''}`} >
                                  <span
                                    id="user-select"
                                    onClick={handleToggleExpenseProjectEditDropdown}
                                    className={`custom-dropdown-user-new custom-dropdown-user`}
                                  >
                                    {expenseProjectEdit ? (
                                      projects.find((project) => project._id === expenseProjectEdit)?.project_name
                                    ) : (
                                      'Select'
                                    )}
                                  </span>
                                  {expenseProjectEditDropdownOpen && (
                                    <div className="custom-dropdown-new">
                                      <input
                                        type="text"
                                        className="search-bar"
                                        placeholder="Search..."
                                        value={expProjectSearchTerm}
                                        onChange={handleExpenseProjectSearchInputChange}
                                      />
                                      {filteredProjects.length > 0 ? (
                                        <ul className="client-list">
                                          {filteredProjects.map((project) => (
                                            <li
                                              key={project._id}
                                              className={expenseProjectEdit === project._id ? 'selected-item' : ''}
                                              onClick={() => {
                                                setExpenseProjectEdit(project._id);
                                                setExpProjectSearchTerm('');
                                                setExpenseProjectEditDropdownOpen(false);
                                              }}
                                            >
                                              {project.project_name}
                                            </li>
                                          ))}
                                        </ul>
                                      ) : (
                                        <div className="no-projects-message">
                                          No projects available for the selected client.
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </div>
                              )}
                            </>
                          ) : (
                            <p className="form-input">
                              {isLoadingProject == true ? (
                                <div className="outter-load-table">
                                  <ThreeDots
                                    height="18"
                                    width="20"
                                    radius="9"
                                    color="#405BFF"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                  />
                                </div>
                              ) : (
                                <>
                                  {expenseProjectEdit ? (
                                    projects.find((project) => project._id === expenseProjectEdit)?.project_name
                                  ) : (
                                    ''
                                  )}
                                </>
                              )}
                            </p>
                          )}
                        </div>
                      )}
                    </>
                  ) : (
                    <div className="form-field-client">
                      {isExpenseEdit || expenseCompanyView ? (
                        <label htmlFor="expCompany" className="form-label">
                          Company
                        </label>
                      ) : null}
                      {isExpenseEdit ? (
                        <>
                          {WXCompaniesLoading == true ? (
                            <div className="outter-load-table">
                              <ThreeDots
                                height="18"
                                width="20"
                                radius="9"
                                color="#405BFF"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                              />
                            </div>
                          ) : (
                            <div ref={expenseCompanySelectEditDropdownRef} className={`cursor ${expenseCompanyEditDropdownOpen ? 'open' : ''}`} >
                              <span
                                id="user-select"
                                onClick={handleToggleExpenseCompanyEditDropdown}
                                className={`custom-dropdown-user-new custom-dropdown-user ${expenseCompanyEditIsValid ? 'input-validation-error' : ''}`}
                                ref={expenseCompanyEditRef}
                              >
                                {expenseCompanyEdit ? (
                                  wXCompany.find((company) => company._id === expenseCompanyEdit)?.wx_company
                                ) : (
                                  'Select'
                                )}
                              </span>
                              {expenseCompanyEditDropdownOpen && (
                                <div className="custom-dropdown-new">
                                  <input
                                    type="text"
                                    className="search-bar"
                                    placeholder="Search..."
                                    value={expCompanySearchTerm}
                                    onChange={handleExpenseCompanySearchInputChange}
                                  />
                                  <ul className="client-list">
                                    {filteredCompany.map((company) => (
                                      <li
                                        key={company._id}
                                        className={expenseCompanyEdit === company._id ? 'selected-item' : ''}
                                        onClick={() => {
                                          setExpenseCompanyEdit(company._id);
                                          setExpCompanySearchTerm('');
                                          setExpenseCompanyEditDropdownOpen(false);
                                        }}
                                      >
                                        {company.wx_company}
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              )}
                            </div>
                          )}

                          {expenseCompanyEditIsValid && (
                            <span className="text-red-500 text-xs italic">
                              Expense Company needs to be selected
                            </span>
                          )}
                        </>
                      ) : (
                        <p className="form-input">
                          {WXCompaniesLoading == true ? (
                            <div className="outter-load-table">
                              <ThreeDots
                                height="18"
                                width="20"
                                radius="9"
                                color="#405BFF"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                              />
                            </div>
                          ) : (
                            <>
                              {expenseCompanyEdit ? (
                                wXCompany.find((company) => company._id === expenseCompanyEdit)?.wx_company
                              ) : (
                                ''
                              )}
                            </>
                          )}
                        </p>
                      )}
                    </div>
                  )}

                  {isExpenseEdit || expFileAttchFileNameView.length > 0 ? (
                    <div className="form-field-client">
                      <label htmlFor="amountEdit" className="form-label">
                        File Attachments
                      </label>
                      {isExpenseEdit ? (
                        <div className={`file-input-wrapper equipment-doc-download equipment-doc-download-edit ${(expFileAttchFilesEdit.length > 0 || expFileAttchFileNameEdit.length > 0) ? 'equipment-doc-download-create-active' : ''}`}>
                          {(expFileAttchFilesEdit.length > 0 || expFileAttchFileNameEdit.length > 0) ? (
                            <div className="upload-file-name-br">
                              {expFileAttchFileNameEdit.map((file, index) => (
                                <div className="the-src-pdf-file equipment-doc-download">
                                  <div className="bottom-download-outer">
                                    <div className="bottom-download-inner">
                                      <div className="bottom-download-item">
                                        <div className="attachment-file-sib-pname">
                                          <span className="shortened-name" title={file}>{file}</span>
                                        </div>
                                        <div className="attachment-file-sib-psize">{file && getFileExtension(file)?.toUpperCase()}{"     "}
                                          {expFileAttchFileSizeView && expFileAttchFileSizeView[index] && (
                                            <span>{expFileAttchFileSizeView[index]}</span>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="bottom-download-inner-right" onClick={() => handleRemoveFile(file, index)}>
                                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" viewBox="0 0 20 22" fill="none">
                                        <path d="M16.9576 19.6445L17.4362 4.37763H19.577V3.85209H14.0107V2.45942C14.0107 1.69739 13.4314 1.09302 12.701 1.09302H7.96587C7.23545 1.09302 6.65615 1.69739 6.65615 2.45942V3.85209H1.08984V4.37763H3.23073L3.70928 19.6445C3.73447 20.354 4.28858 20.9058 4.96863 20.9058H15.6731C16.3783 20.9058 16.9324 20.354 16.9576 19.6445ZM7.15989 2.45942C7.15989 1.98643 7.51251 1.61856 7.96587 1.61856H12.701C13.1544 1.61856 13.507 1.98643 13.507 2.45942V3.85209H7.15989V2.45942ZM4.21302 19.6182L3.73447 4.37763H16.9324L16.4539 19.6182C16.4287 20.0387 16.1012 20.3803 15.6983 20.3803H4.96863C4.56564 20.3803 4.23821 20.0387 4.21302 19.6182Z" fill="#282828" stroke="#282828" stroke-width="0.5" />
                                        <path d="M15.6736 21H4.96874C4.23877 21 3.64643 20.4064 3.61944 19.6486L3.14359 4.4723H1V3.75907H6.56631V2.46024C6.56631 1.64144 7.18114 1 7.96598 1H12.7011C13.486 1 14.1008 1.64144 14.1008 2.46024V3.75907H19.6671V4.4723H17.5231L17.0504 19.5515H17.0508L17.0472 19.6486C17.0202 20.4064 16.4166 21 15.6732 21H15.6736ZM1.17991 4.28414H3.3181L3.79935 19.6416C3.82273 20.2976 4.33637 20.8118 4.96874 20.8118H15.6736C16.3267 20.8118 16.8376 20.3149 16.8673 19.6538V19.6416L17.349 4.28414H19.4872V3.9463H13.9209V2.45978C13.9209 1.74608 13.3852 1.18722 12.7011 1.18722H7.96598C7.28189 1.18722 6.74622 1.74608 6.74622 2.45978V3.9463H1.17991V4.28414ZM4.96874 20.4745C4.52347 20.4745 4.15196 20.101 4.12363 19.6242V19.6214L3.64193 4.28367H17.0256L16.5439 19.6214C16.5151 20.101 16.1441 20.474 15.6984 20.474H4.96874V20.4745ZM4.30308 19.6139C4.32647 19.9912 4.61837 20.2868 4.96874 20.2868H15.6984C16.0492 20.2868 16.3415 19.9907 16.364 19.6125L16.8394 4.47137H3.82723L4.30263 19.6134L4.30308 19.6139ZM13.5971 3.9463H7.07005V2.45978C7.07005 1.93565 7.46359 1.52507 7.96598 1.52507H12.7011C13.2035 1.52507 13.5971 1.93565 13.5971 2.45978V3.9463ZM7.24995 3.7586H13.4172V2.45978C13.4172 2.04075 13.1028 1.71276 12.7011 1.71276H7.96598C7.56434 1.71276 7.24995 2.04075 7.24995 2.45978V3.7586Z" fill="#282828" stroke="#282828" stroke-width="0.5" />
                                        <path d="M10.0811 8.37158H10.5848V16.2284H10.0811V8.37158Z" fill="#282828" stroke="#282828" stroke-width="0.5" />
                                        <path d="M10.6749 16.3223H9.99121V8.27783H10.6749V16.3223ZM10.1711 16.1346H10.4949V8.46552H10.1711V16.1346Z" fill="#282828" stroke="#282828" stroke-width="0.5" />
                                        <path d="M7.05859 8.37158H7.56233V16.2284H7.05859V8.37158Z" fill="#282828" stroke="#282828" stroke-width="0.5" />
                                        <path d="M7.65239 16.3223H6.96875V8.27783H7.65239V16.3223ZM7.14866 16.1346H7.47249V8.46552H7.14866V16.1346Z" fill="#282828" stroke="#282828" stroke-width="0.5" />
                                        <path d="M13.1045 8.37158H13.6082V16.2284H13.1045V8.37158Z" fill="#282828" stroke="#282828" stroke-width="0.5" />
                                        <path d="M13.6983 16.3223H13.0146V8.27783H13.6983V16.3223ZM13.1946 16.1346H13.5184V8.46552H13.1946V16.1346Z" fill="#282828" stroke="#282828" stroke-width="0.5" />
                                      </svg>
                                    </div>
                                  </div>
                                </div>
                              ))}
                              {expFileAttchFilesEdit.map((file, index) => (
                                <div key={index} className="uploaded-file-item equipment-doc-download-edit">
                                  <div className="the-src-pdf-file equipment-doc-download">
                                    <div className="bottom-download-outer">
                                      <div className="bottom-download-inner">
                                        <div className="bottom-download-item">
                                          <div className="attachment-file-sib-pname">
                                            <span className="shortened-name" title={file.name}>{file.name}</span>
                                          </div>
                                          <div className="attachment-file-sib-psize">{file.name && file.name.split('.').pop()?.toUpperCase()}{"     "} <span>127.2 KB</span></div>
                                        </div>
                                      </div>
                                      <div className="bottom-download-inner-right" onClick={() => removeFile(index)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" viewBox="0 0 20 22" fill="none">
                                          <path d="M16.9576 19.6445L17.4362 4.37763H19.577V3.85209H14.0107V2.45942C14.0107 1.69739 13.4314 1.09302 12.701 1.09302H7.96587C7.23545 1.09302 6.65615 1.69739 6.65615 2.45942V3.85209H1.08984V4.37763H3.23073L3.70928 19.6445C3.73447 20.354 4.28858 20.9058 4.96863 20.9058H15.6731C16.3783 20.9058 16.9324 20.354 16.9576 19.6445ZM7.15989 2.45942C7.15989 1.98643 7.51251 1.61856 7.96587 1.61856H12.701C13.1544 1.61856 13.507 1.98643 13.507 2.45942V3.85209H7.15989V2.45942ZM4.21302 19.6182L3.73447 4.37763H16.9324L16.4539 19.6182C16.4287 20.0387 16.1012 20.3803 15.6983 20.3803H4.96863C4.56564 20.3803 4.23821 20.0387 4.21302 19.6182Z" fill="#282828" stroke="#282828" stroke-width="0.5" />
                                          <path d="M15.6736 21H4.96874C4.23877 21 3.64643 20.4064 3.61944 19.6486L3.14359 4.4723H1V3.75907H6.56631V2.46024C6.56631 1.64144 7.18114 1 7.96598 1H12.7011C13.486 1 14.1008 1.64144 14.1008 2.46024V3.75907H19.6671V4.4723H17.5231L17.0504 19.5515H17.0508L17.0472 19.6486C17.0202 20.4064 16.4166 21 15.6732 21H15.6736ZM1.17991 4.28414H3.3181L3.79935 19.6416C3.82273 20.2976 4.33637 20.8118 4.96874 20.8118H15.6736C16.3267 20.8118 16.8376 20.3149 16.8673 19.6538V19.6416L17.349 4.28414H19.4872V3.9463H13.9209V2.45978C13.9209 1.74608 13.3852 1.18722 12.7011 1.18722H7.96598C7.28189 1.18722 6.74622 1.74608 6.74622 2.45978V3.9463H1.17991V4.28414ZM4.96874 20.4745C4.52347 20.4745 4.15196 20.101 4.12363 19.6242V19.6214L3.64193 4.28367H17.0256L16.5439 19.6214C16.5151 20.101 16.1441 20.474 15.6984 20.474H4.96874V20.4745ZM4.30308 19.6139C4.32647 19.9912 4.61837 20.2868 4.96874 20.2868H15.6984C16.0492 20.2868 16.3415 19.9907 16.364 19.6125L16.8394 4.47137H3.82723L4.30263 19.6134L4.30308 19.6139ZM13.5971 3.9463H7.07005V2.45978C7.07005 1.93565 7.46359 1.52507 7.96598 1.52507H12.7011C13.2035 1.52507 13.5971 1.93565 13.5971 2.45978V3.9463ZM7.24995 3.7586H13.4172V2.45978C13.4172 2.04075 13.1028 1.71276 12.7011 1.71276H7.96598C7.56434 1.71276 7.24995 2.04075 7.24995 2.45978V3.7586Z" fill="#282828" stroke="#282828" stroke-width="0.5" />
                                          <path d="M10.0811 8.37158H10.5848V16.2284H10.0811V8.37158Z" fill="#282828" stroke="#282828" stroke-width="0.5" />
                                          <path d="M10.6749 16.3223H9.99121V8.27783H10.6749V16.3223ZM10.1711 16.1346H10.4949V8.46552H10.1711V16.1346Z" fill="#282828" stroke="#282828" stroke-width="0.5" />
                                          <path d="M7.05859 8.37158H7.56233V16.2284H7.05859V8.37158Z" fill="#282828" stroke="#282828" stroke-width="0.5" />
                                          <path d="M7.65239 16.3223H6.96875V8.27783H7.65239V16.3223ZM7.14866 16.1346H7.47249V8.46552H7.14866V16.1346Z" fill="#282828" stroke="#282828" stroke-width="0.5" />
                                          <path d="M13.1045 8.37158H13.6082V16.2284H13.1045V8.37158Z" fill="#282828" stroke="#282828" stroke-width="0.5" />
                                          <path d="M13.6983 16.3223H13.0146V8.27783H13.6983V16.3223ZM13.1946 16.1346H13.5184V8.46552H13.1946V16.1346Z" fill="#282828" stroke="#282828" stroke-width="0.5" />
                                        </svg>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          ) : (
                            <input
                              type="text"
                              className="form-input form-input-file"
                              value={expFileAttchFileNameView}
                              readOnly
                            />
                          )}
                          <input
                            type="file"
                            id="expFileAttchEdit"
                            name="expFileAttchEdit"
                            style={{ display: 'none' }}
                            ref={expFileAttchRef}
                            className="form-input hidden-input"
                            onChange={handleExpFileAttchEditUpload}
                            multiple
                          />
                          <label
                            htmlFor="expFileAttchEdit"
                            className="file-input-label"
                          >
                            <img src={Plus} alt="Browse" />Browse
                          </label>
                        </div>
                      ) : (
                        <>
                          {expFileAttchFileNameView.map((file, index) => (
                            <p className="form-textarea"
                              onClick={() => downloadExpenseDoc(file)}
                              style={{ cursor: 'pointer' }}
                            >
                              <div className="the-src-pdf-file equipment-doc-download">
                                <div className="bottom-download-outer">
                                  <div className="bottom-download-inner">
                                    <div className="bottom-download-item">
                                      <div className="attachment-file-sib-pname">
                                        <span className="shortened-name" title={file}>{file}</span>
                                      </div>
                                      <div className="attachment-file-sib-psize">{file && getFileExtension(file)?.toUpperCase()}{"     "}
                                        {expFileAttchFileSizeView && expFileAttchFileSizeView[index] && (
                                          <span>{expFileAttchFileSizeView[index]}</span>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="bottom-download-inner-right">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="15" viewBox="0 0 12 15" fill="none">
                                      <path d="M5.67409 13.8727C5.69551 13.8928 5.71891 13.9108 5.74395 13.9266L5.77888 13.9439L5.82304 13.9665L5.86918 13.9804L5.90971 13.9909C5.97034 14.003 6.03328 14.003 6.09425 13.9909L6.13478 13.9804L6.18091 13.9665L6.22507 13.9439L6.26 13.9266C6.28537 13.9108 6.30877 13.8928 6.33019 13.8727L10.8939 9.57954C11.0458 9.40745 11.0333 9.1549 10.8649 8.99674C10.6965 8.83858 10.4276 8.82682 10.2444 8.9695L6.46134 12.526V1.43331C6.46134 1.19406 6.25473 1 6 1C5.74527 1 5.53865 1.19406 5.53865 1.43331V12.526L1.75563 8.9695C1.57241 8.82682 1.30351 8.83858 1.13512 8.99674C0.966727 9.1549 0.954205 9.40745 1.10612 9.57954L5.67343 13.8727H5.67409Z" fill="#282828" stroke="#282828" stroke-width="0.5"></path>
                                    </svg>
                                  </div>
                                </div>
                              </div>
                            </p>
                          ))}
                        </>
                      )}
                    </div>
                  ) : null}

                  {isExpenseEdit || expNoteView ? (
                    <div className="form-field-client">
                      <label htmlFor="expNoteEdit" className="form-label">
                        Notes
                      </label>
                      {isExpenseEdit ? (
                        <textarea
                          id="expNoteEdit"
                          name="expNoteEdit"
                          value={expNoteEdit}
                          onChange={(e) => setExpNoteEdit(e.target.value)}
                          className="form-textarea"
                          rows="3"
                        ></textarea>
                      ) : (
                        <p className="form-textarea">
                          {expNoteView}
                        </p>
                      )}
                    </div>
                  ) : null}

                  {isExpenseEdit ? (
                    <>
                      {isSubmitLoading ?
                        (
                          <div className="outter-load-table">
                            <ThreeDots
                              height="38"
                              width="40"
                              radius="9"
                              color="#6479f9"
                              ariaLabel="three-dots-loading"
                              wrapperStyle={{}}
                              wrapperClassName=""
                              visible={true}
                            />
                          </div>
                        ) : (
                          <div className="sprint-btn-outer">
                            <button
                              type="button"
                              onClick={handleResetExpenseEdit}
                              className="submit-client cancel-sprint"
                            >
                              Cancel
                            </button>
                            <button
                              type="button"
                              onClick={handleExpEditSubmit}
                              className="submit-client save-sprint"
                            >
                              Save Changes
                            </button>
                          </div>
                        )}
                    </>
                  ) : (
                    <div className="sprint-btn-outer">
                      <button
                        type="button"
                        className="submit-client cancel-sprint"
                        onClick={handleEqpDelete}
                        disabled={isSubmitLoading}
                      >
                        <img src={Delete} alt="Delete" />  Delete
                      </button>
                      <Modal
                        className="delete-modal"
                        isOpen={isEqpDeleteModalOpen}
                        onRequestClose={handleCancelEqpDelete}
                        contentLabel="Confirm Delete"
                        style={{
                          content: {
                            height: "41%",
                            width: "30%",
                            position: "fixed",
                            top: "36%",
                            left: "50%",
                            transform: "translate(-19.75%, 0%)",
                            parent: document.querySelector(".admin-outer.time.tracker"),
                          },
                        }}
                      >
                        <div className="delete-modal-content">
                          <h2>Confirm Delete</h2>
                          <p>
                            Are you sure you want to delete this Expense Data?
                          </p>
                          <div className="delete-modal-buttons">
                            <button className="buttons-rejected" onClick={handleConfirmEqpDelete} disabled={isSubmitLoading}>
                              {isSubmitLoading ?
                                <div className="outter-load-table">
                                  <ThreeDots
                                    height="18"
                                    width="20"
                                    radius="9"
                                    color="white"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                  />
                                </div> : "Delete"}
                            </button>
                            <button onClick={handleCancelEqpDelete} disabled={isSubmitLoading}>
                              Cancel
                            </button>
                          </div>
                        </div>
                      </Modal>
                      <button
                        type="button"
                        onClick={handleSetExpenseEdit}
                        className="submit-client save-sprint"
                      >
                        <img alt="" src={editImage} />{" "}
                        Edit Expense
                      </button>
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
        </Modal>



        {
          props.expenseLoading == true ? (
            <div className="outter-load-table">
              <ThreeDots
                height="80"
                width="80"
                radius="9"
                color="#405BFF"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            </div>
          ) : (
            <div className="datatable-team-maintb">


              <DataTable
                value={props.allCurrency === true ? props.expenseData : props.expenseData.filter(item => item.exp_currency === props.selectedCurrency)}
                removableSort
                tableStyle={{ minWidth: "50rem", width: "100%", textAlign: "left" }}
                paginator={props?.expenseData?.length > 10}
                paginatorClassName="recent-invoices-pagination"
                rows={props.perPage}
                first={currentPage * props.perPage}
                onPage={onPageChange}
                emptyMessage="No results found"
              >
                <Column
                  field="exp_id"
                  header="Expense ID"
                  sortable
                  style={{ width: "19.5%", paddingRight: "20px" }}
                ></Column>
                <Column
                  field="exp_user"
                  body={useRenderExpenseUser}
                  header="User"
                  sortable
                  style={{ width: "17.4%", paddingRight: "20px" }}
                ></Column>
                <Column
                  field="exp_date"
                  body={convertExpenseDate}
                  header="Date"
                  sortable
                  style={{ width: "20.4%", paddingRight: "20px" }}
                ></Column>
                <Column
                  field="exp_category.exp_category_name"
                  header="Category"
                  body={useRenderExpCategory}
                  sortable
                  style={{ width: "16.4%", paddingRight: "20px" }}
                ></Column>
                <Column
                  field="exp_amount"
                  header="Amount"
                  body={renderExpAmount}
                  className="amount-bold"
                  sortable
                  style={{ paddingRight: "44px" }}
                ></Column>
                <Column
                  field="exp_is_billable"
                  body={renderExpIsBillable}
                  header="Billable"
                  sortable
                  style={{ paddingRight: "44px" }}
                ></Column>

                <Column
                  field="_id"
                  body={detailCTA}
                  style={{ paddingRight: "20px" }}
                ></Column>
              </DataTable>
            </div>
          )
        }
      </div >
    </div >
  );
};

export default ExpenseTable;