import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { startOfYear } from 'date-fns';

const initialState = {
  servicesTag: null,
  servicesClient: null,
  servicesDateRange: null,
  servicesCurrency: null,
};

export const setReportServicesClient = createAsyncThunk(
  "reporting/servicesClient",
  async (servicesClient) => {
    return servicesClient;
  }
);

export const setReportServicesTag = createAsyncThunk(
  "reporting/servicesTag",
  async (servicesTag) => {
    return servicesTag;
  }
);

export const setReportServicesDateRange = createAsyncThunk(
  "reporting/servicesDateRange",
  async (servicesDateRange) => {
    if (servicesDateRange !== null && Array.isArray(servicesDateRange) && servicesDateRange.length === 2) {
      //   const date = startOfDateRange(new Date(dateRange, 0, 1));
      //   date.setHours(0, 0, 0, 0);
      let [start, end] = servicesDateRange;

      start = new Date(start);
      // start.setDate(start.getDate() + 1);

      end = new Date(end);
      // end.setDate(end.getDate() + 1);

      const startOfDay = new Date(start);
      startOfDay.setHours(0, 0, 0, 0);

      const endOfDay = new Date(end);
      endOfDay.setHours(23, 59, 59, 999);

      const adjustedDateRange = [startOfDay, endOfDay];

      return adjustedDateRange;
    } else {
      return null;
    }
  }
);

export const setReportServicesCurrency = createAsyncThunk(
  "reporting/servicesCurrency",
  async (servicesCurrency) => {
    return servicesCurrency;
  }
);

export const reportingServicesSlice = createSlice({
  name: "reporting",
  initialState,
  reducers: {
    reset: (state) => {
      state.servicesTag = null;
      state.servicesClient = null;
      state.servicesDateRange = null;
      state.servicesCurrency = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setReportServicesClient.fulfilled, (state, action) => {
      state.servicesClient = action.payload;
    });
    builder.addCase(setReportServicesTag.fulfilled, (state, action) => {
      state.servicesTag = action.payload;
    });
    builder.addCase(setReportServicesDateRange.fulfilled, (state, action) => {
      state.servicesDateRange = action.payload;
    });
    builder.addCase(setReportServicesCurrency.fulfilled, (state, action) => {
      state.servicesCurrency = action.payload;
    });
  },
});

export const { reset } = reportingServicesSlice.actions;
export default reportingServicesSlice.reducer;
