import moment from "moment";
import axios from "axios";
import Select from 'react-select';
import "rsuite/dist/rsuite.min.css";
import { DateRangePicker } from 'rsuite';
import getCurrencyByCode from 'currency-codes';
import { useNavigate } from 'react-router-dom';
import "react-toastify/dist/ReactToastify.css";
import React, { useState, useEffect, useRef } from "react";
import { RotatingLines } from 'react-loader-spinner';
import { useSelector, useDispatch } from "react-redux";
import { useFetchVendorDashboardCurrency } from "./VendorDashboardCurrencyApi";
import { setDashboardDate, setDashboardCurrencyRx, setVendorDashboardCurrencySum } from "../../../features/invoice/invoiceSlice";
import { setForex } from "../../../features/forex/forexSlice.js";
import Calendar from "../../../helpers/dateComponents/Calendar";

export default function FilterVendorCalendar() {

  const dispatch = useDispatch();
  const [showCalendar, setShowCalendar] = useState(false);
  const [selectedRange, setSelectedRange] = useState(null);
  const [allCurrency, setAllCurrency] = useState(false);
  const dashboradCurrency = useSelector((state) => state.invoice.dashboardCurrency);
  const vendorDashboardCurrencySum = useSelector((state) => state.invoice.vendorDashboardCurrencySum);
  const dashboradDateRange = useSelector((state) => state.invoice.dashboardDateRange);
  const { appSettings } = useSelector((state) => state.appSettings);
  const calendarRef = useRef(null);

  const onDateChange = async (ranges) => {
    // moment.tz(item.date,'YYYY-MM-DD HH:mm:ss',global.config.getTimeZone()).format()
    const dateRangeArray = [ranges.startDate, ranges.endDate];
    setSelectedRange(dateRangeArray);
    await dispatch(setDashboardDate(dateRangeArray));
    setShowCalendar(false);
  };

  const toggleCalendar = () => {
    setShowCalendar(!showCalendar);
  };

  const cancelCalendar = () => {
    setShowCalendar(false);
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: 'white',
      border: '0.2px solid rgba(106, 121, 136, 0.7)',
      borderRadius: '10px',
      padding: '8px 16px',
      fontSize: '17px',
      width: '251px',
      color: '#78828D',
      height: '43px',
      padding: '0px',
      cursor: 'pointer',
      textAlign: 'left',
      fontWeight: 300,
      lineHeight: '21px',
    }),
    option: (provided, state) => ({
      ...provided,
      padding: '8px 16px',
      color: state.isSelected ? 'white' : '#78828D', // Change color for selected item
      backgroundColor: state.isSelected ? '#D1D8FF' : 'transparent', // Change background color for selected item
      fontWeight: 300,
      fontSize: '17px',
      lineHeight: '38px',
      textAlign: 'left',
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      padding: '0px 21px 0px 0px',
      color: state.isFocused ? '#405BFF' : '#405BFF',
    }),
    // Add more custom styles as needed
  };


  const [selectedCurrency, setSelectedCurrency] = useState(dashboradCurrency);
  const [selDashboardCurrency, setSelDashboardCurrency] = useState("");
  const [selectedForex, setSelectedForex] = useState([]);

  const getForex = async (currency) => {

    const response = await axios.post('/api/forex/get', { base_currency: "USD" }, {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    });

    if (response.status === 200) {
      return response.data ? response.data.json : null;
    } else {
      console.error(`Request failed with status code: ${response.status}`);
    }

  }

  const handleCurrencyChange = async (option) => {
    setSelectedCurrency(option.value);
    setSelDashboardCurrency(option.value);
    const forex = await getForex(option.value);
    setSelectedForex(forex);
    await dispatch(setForex(forex));
    await dispatch(setDashboardCurrencyRx(option.value));
    // Do something with the selected currency value, such as dispatching an action
  };
  const user = useSelector(
    (state) => state.auth.user
  )
  // Use the React Query hook to fetch dashboard currency data
  const { data: dashboardCurrency = [], isLoading: isLoadingFetchCurrency } = useFetchVendorDashboardCurrency(user.token);

  useEffect(() => {

    const ForexUpdate = async () => {
      const forex = await getForex('USD');
      setSelectedForex(forex);
      await dispatch(setForex(forex));
    }
    ForexUpdate();

    setSelectedCurrency(dashboradCurrency);
    const changeSumCur = async () => {
      await dispatch(setVendorDashboardCurrencySum(vendorDashboardCurrencySum));
    }
    setAllCurrency(vendorDashboardCurrencySum);
    changeSumCur();

  }, [vendorDashboardCurrencySum, dashboradCurrency]);

  const handleClickOutsideDropdown = (event) => {
    const isClickInsideCalendarDropdown = calendarRef.current && calendarRef.current.contains(event.target);
    if (!isClickInsideCalendarDropdown) {
      setShowCalendar(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutsideDropdown);
    return () => {
      document.removeEventListener('click', handleClickOutsideDropdown);
    };
  }, []);

  useEffect(() => {

    dispatch(setDashboardCurrencyRx(global.config.appCurrency()));

  }, []);

  const selectCurrency = (selDashboardCurrency) => {
    setSelDashboardCurrency(selDashboardCurrency);
    dispatch(setDashboardCurrencyRx(selDashboardCurrency));
  };

  const options = dashboardCurrency.map((currency) => ({
    value: currency,
    label: `${getCurrencyByCode.code(currency).currency}`
  }));

  const formatOptionLabel = ({ value, label }) => {
    return (
      <div className="currency-option">
        <span className="currency-symbol">{new Intl.NumberFormat('en-US', { style: 'currency', currency: value, }).format(0).replace(/[\d.,]/g, '')}</span>
        <span className="dropdown-text">{label}</span>
      </div>
    );
  };

  const sumAllCurrencies = async (val) => {
    setAllCurrency(val);
    setSelectedCurrency(global.config.appCurrency());
    await dispatch(setDashboardCurrencyRx(global.config.appCurrency()));
    await dispatch(setVendorDashboardCurrencySum(val));
  }

  return (
    <>
      <div className="curency-dropdown filter-dashbord-calendar">
        <div className="curency-dropdown-inner">
          <div className="currency-title">{global.config.locate(appSettings && appSettings.language, 'Currency')}</div>
          {(dashboardCurrency && dashboardCurrency.length > 0) ?
            (
              <Select
                key={selectedCurrency ? selectedCurrency : "USD"}
                // defaultValue={{ value: global.config.appCurrency() ? global.config.appCurrency() : (dashboradCurrency && dashboradCurrency), label: global.config.appCurrency() ? global.config.appCurrency() && getCurrencyByCode.code(global.config.appCurrency() && global.config.appCurrency()).currency : dashboradCurrency && getCurrencyByCode.code(dashboradCurrency && dashboradCurrency).currency }}
                defaultValue={{ value: selectedCurrency ? selectedCurrency : "USD", label: getCurrencyByCode.code(selectedCurrency ? selectedCurrency : "USD").currency }}
                options={options}
                styles={customStyles}
                onChange={handleCurrencyChange}
                formatOptionLabel={dashboradCurrency && formatOptionLabel}
              />
            ) : isLoadingFetchCurrency === true ?
              (
                <RotatingLines
                  strokeColor="grey"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="40"
                  visible={true}
                />
              ) : global.config.locate(appSettings && appSettings.language, 'No Invoices Generated in this Account')
          }

          {(dashboardCurrency && dashboardCurrency.length > 0) &&
            <p>{global.config.locate(appSettings && appSettings.language, 'Sum all Currencies')}
            <input type="checkbox" checked={allCurrency} name="allCurrency" value={allCurrency} onChange={async (e) => {
              sumAllCurrencies(!allCurrency); localStorage.setItem("allCur", !allCurrency);
              const forex = await getForex(appSettings && appSettings.currency);
              setSelectedForex(forex);
              await dispatch(setForex(forex));
              if (!allCurrency === true) {
                await setDashboardCurrencyRx(global.config.appCurrency());
                setSelectedCurrency(global.config.appCurrency());
              }
            }} /></p>
          }
        </div>

        <div className="date-picker-outer date-picker-outer-all" ref={calendarRef}>
          <div className="custom-picker-icon custom-picker-icon-all">
            <svg
              width="1em"
              height="1em"
              viewBox="0 0 14 14"
              fill="currentColor"
              aria-hidden="true"
              focusable="false"
              class="rs-picker-toggle-caret rs-icon"
              aria-label="calendar"
              data-category="legacy"
            >
              <path d="M1 4v8.5a.5.5 0 00.5.5H8c0-.128.049-.256.146-.354.555-.555.854-1.6.854-3.146a.5.5 0 01.621-.485l.119.03A2.623 2.623 0 0012.999 6.5V4h-12zm3-2h6V0h1v2h1.5A1.5 1.5 0 0114 3.5v3a3.623 3.623 0 01-4.015 3.603c-.064 1.245-.335 2.212-.831 2.898H12.5a.5.5 0 00.5-.5v-2a.5.5 0 011 0v2a1.5 1.5 0 01-1.5 1.5h-11a1.5 1.5 0 01-1.5-1.5v-9a1.5 1.5 0 011.5-1.5H3v-2h1v2zm5 4V5h1v1h2v1h-2v1H9V7H7v2h1v1H7v2H6v-2H4v2H3v-2H1V9h2V7H1V6h2V5h1v1h2V5h1v1h2zM6 9V7H4v2h2z"></path>
            </svg>
          </div>
          <div className="custoom-icon-calender custoom-icon-calender-all custom-date-range" >
            <input
              type="text"
              value={
                selectedRange ?
                  `${new Date(selectedRange[0]).toLocaleDateString("en-GB", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                  })} to ${new Date(selectedRange[1]).toLocaleDateString("en-GB", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                  })}`
                  : null
              }
              id="date-range"
              name="date-range"
              placeholder="All time"
              onClick={toggleCalendar}
              autocomplete="off"
              readOnly
            />
          </div>
          <div className="date-range-new right-align">
            {showCalendar &&
              <React.Fragment>
                <Calendar onCancel={cancelCalendar} onChange={onDateChange} initialRange={{ startDate: selectedRange ? selectedRange[0] : null, endDate: selectedRange ? selectedRange[1] : null }} />
              </React.Fragment>
            }
          </div>
        </div>
      </div>
    </>
  );
}