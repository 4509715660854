import React from 'react';
import { useSelector } from 'react-redux';
import { calculateHighestExpense } from './Services/reportExpense';

// Functional component
function HighestExpense({ expenseData }) {
  const reportingFinancialExpense = useSelector((state) => state.reportingExpense);
  const { forex } = useSelector((state) => state.forex);
  const settings = JSON.parse(localStorage.getItem("settings"));

  const setReportingCurrency = useSelector((state) => state.reportingExpense.expCurrency);
  var currencies = "USD";
  if (setReportingCurrency && setReportingCurrency.length === 1) {
    currencies = setReportingCurrency[0];
  } else {
    currencies = settings.currency;
  }


  const { highestExpense, highestExpenseCategoryName } = calculateHighestExpense(expenseData, reportingFinancialExpense, currencies, forex);

  return (
    <div>
      <div className="overview-item">
        <div className="overview-title-outer">
          <div className="overview-title">
            Highest Expenses
            <div className='client-name-report'>{highestExpenseCategoryName}</div>
          </div>
          <div className="overview-icon">
            <svg width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g filter="url(#filter0_d_2991_10)">
                <circle cx="24.5" cy="24.5" r="20.5" fill="white" />
              </g>
              <path d="M32.9999 20.3224V36.9708C32.9999 37.1825 32.8875 37.359 32.6625 37.4648C32.5874 37.5 32.5126 37.5 32.4375 37.5C32.2877 37.5 32.1749 37.4648 32.0624 37.359L30.7501 36.2651L29.4374 37.359C29.2125 37.5354 28.9125 37.5354 28.6875 37.359L27.3749 36.2651L26.0623 37.359C25.8373 37.5354 25.5373 37.5354 25.3123 37.359L24 36.2651L22.6873 37.359C22.4624 37.5354 22.1624 37.5354 21.9374 37.359L20.6251 36.2651L19.3124 37.359C19.0875 37.5354 18.7874 37.5354 18.5625 37.359L17.2499 36.2651L15.9372 37.359C15.7873 37.5 15.5247 37.5354 15.3372 37.4296C15.1125 37.359 15 37.1825 15 36.9708V15.0292C15 14.7469 15.2626 14.5 15.5624 14.5H26.8123H26.9248C26.9622 14.5 26.9622 14.5 26.9999 14.5352H27.0373C27.0747 14.5352 27.0747 14.5704 27.1124 14.5704H27.1498C27.1872 14.6056 27.2249 14.6056 27.2249 14.641L30.0373 17.2876C31.6497 18.8049 32.3623 19.4754 32.7 19.8284C32.8499 19.899 32.9626 20.0401 33 20.2165C32.9997 20.2517 32.9999 20.2872 32.9999 20.3224ZM27.375 16.2996V19.7932H31.0875C30.0375 18.8051 28.4249 17.2876 27.375 16.2996ZM31.8751 35.8063V20.8517H26.8125C26.5125 20.8517 26.2501 20.6046 26.2501 20.3225V15.5585H16.1252V35.8063L16.8751 35.1712C16.9876 35.0654 17.1001 35.0302 17.2502 35.0302C17.4001 35.0302 17.5128 35.0654 17.6253 35.1712L18.9379 36.2651L20.2506 35.1712C20.4755 34.9947 20.7756 34.9947 21.0005 35.1712L22.3131 36.2651L23.6258 35.1712C23.8507 34.9947 24.1507 34.9947 24.3757 35.1712L25.6883 36.2651L27.001 35.1712C27.2259 34.9947 27.5259 34.9947 27.7509 35.1712L29.0635 36.2651L30.3761 35.1712C30.6011 34.9947 30.9011 34.9947 31.1261 35.1712L31.8751 35.8063ZM29.1809 30.3668H18.8784C18.5783 30.3668 18.316 30.614 18.316 30.8961C18.316 31.1784 18.5786 31.4253 18.8784 31.4253H29.1809C29.481 31.4253 29.7433 31.1781 29.7433 30.8961C29.7433 30.614 29.481 30.3668 29.1809 30.3668Z" fill="#6A7988" />
              <path d="M18.8783 27.3799H29.1809C29.4809 27.3799 29.7433 27.627 29.7433 27.9091C29.7433 28.1912 29.4809 28.4383 29.1809 28.4383H18.8783C18.5785 28.4383 18.3159 28.1915 18.3159 27.9091C18.3159 27.627 18.5783 27.3799 18.8783 27.3799Z" fill="#6A7988" />
              <path d="M18.8783 24.4814H29.1809C29.4809 24.4814 29.7433 24.7285 29.7433 25.0106C29.7433 25.2927 29.4809 25.5398 29.1809 25.5398H18.8783C18.5785 25.5398 18.3159 25.2929 18.3159 25.0106C18.3159 24.7285 18.5783 24.4814 18.8783 24.4814Z" fill="#6A7988" />
              <path d="M35 17.5C35 20.2614 32.7614 22.5 30 22.5C27.2386 22.5 25 20.2614 25 17.5C25 14.7386 27.2386 12.5 30 12.5C32.7614 12.5 35 14.7386 35 17.5Z" fill="white" />
              <path fill-rule="evenodd" clip-rule="evenodd" d="M30 13C27.5147 13 25.5 15.0147 25.5 17.5C25.5 19.9853 27.5147 22 30 22C32.4853 22 34.5 19.9853 34.5 17.5C34.5 15.0147 32.4853 13 30 13ZM24.5 17.5C24.5 14.4624 26.9624 12 30 12C33.0376 12 35.5 14.4624 35.5 17.5C35.5 20.5376 33.0376 23 30 23C26.9624 23 24.5 20.5376 24.5 17.5Z" fill="#6A7988" />
              <path d="M31.0242 14.9431C30.4555 14.3524 29.5327 14.3522 28.9646 14.9432L27 16.987L27.7797 17.7981L29.4533 16.057V20.5H30.556V16.078L32.2203 17.8086L33 16.9975L31.0242 14.9431Z" fill="#6A7988" />
              <defs>
                <filter id="filter0_d_2991_10" x="0" y="0" width="49" height="49" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                  <feOffset />
                  <feGaussianBlur stdDeviation="2" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix type="matrix" values="0 0 0 0 0.468733 0 0 0 0 0.51145 0 0 0 0 0.554167 0 0 0 0.39 0" />
                  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2991_10" />
                  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2991_10" result="shape" />
                </filter>
              </defs>
            </svg>
          </div>
        </div>

        <div className="overview-rice">
          {new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: currencies,
          }).format(highestExpense)}
        </div>
      </div>

    </div>
  );
}

export default HighestExpense;
