import axios from "axios";
import React, { useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import Modal from "react-modal";
import { Link, useNavigate, } from "react-router-dom";
import "./SetDefaultModal.css";
import crssrImage from "../../images/singleinvoice/cross-red.svg";
import { setAppSettings } from "../../features/appSettings/appSettingsSlice.js";

const TimeZoneModal = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [preferenceModalIsOpen, setPreferenceModalIsOpen] = useState(true);
  const { appSettings } = useSelector((state) => state.appSettings);
  const user = useSelector((state) => state.auth.user);
  let token = null;
  if (user) {
    if (user && user.token) {
      token = user.token;
    }
  }

  const from = props?.from;

  const handlePreference = () => {
    localStorage.setItem('activeTab', String(3));
    navigate(`/user/profile/settings`);
    setPreferenceModalIsOpen(false);
  }

  const closeModal = () => {
    setPreferenceModalIsOpen(false);
  }

  const zoneContinue = () => {
    setPreferenceModalIsOpen(false);
    localStorage.setItem("zoneChangeConfirm", true);
  }
  const switchTimeZone = async (browser, app) => {

    const updatedSettings = { ...appSettings, timeZone: browser };
    await dispatch(setAppSettings(updatedSettings));
    localStorage.setItem("zoneChangeConfirm", true);
    setPreferenceModalIsOpen(false);

    try {
      const response = await axios.post(
        `/api/post/user-settings`,
        {
          timeZone: browser,
          currency: null,
          language: null,
          timerLock: null,
          timerLockTime: null,
          seoTitle: null,
          metaDescription: null,
          timerLockOption: null,
          weekStartDay: null,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      global.config.slackMessage(error.toString());
      console.log(error);
    }
  };

  return (
    <>
      <Modal
        isOpen={preferenceModalIsOpen}
        contentLabel="Timezone Type"
        className="time-zone-name-modal"
        style={{
          content: {
            height: '50%',
            width: '50%',
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: "translate(-17.5%, -46%)",
            zIndex: '99999',
          },
        }}
      >
        <div className="modal default-modal-set">
          <div className="outter-modal-main">
            <a className="close-image-clr1" onClick={() => { setPreferenceModalIsOpen(!preferenceModalIsOpen); }}>
              <img src={crssrImage} alt="Close Icon" />{" "}
            </a>
          </div>
          <>
            {from === 'zoneChanged' ? (
              // Zone mismatch alert message
              <div className="outter-modal-main-2">
                <p>Your browser timezone differs from the application timezone. Would you like to continue?</p>
                <div className="modal-btn-main center-button">
                  <a onClick={() => { switchTimeZone(props?.data?.browser, props?.data?.appSettings) }} >Switch Time Zone</a> < a onClick={() => { zoneContinue() }} >Continue</a>
                </div>
              </div>
            ) : (
              // Preferences settings update notification
              <div className="outter-modal-main-2">
                <p>
                  <p>Please complete your preferences.</p>
                </p>
                <div className="modal-btn-main center-button" onClick={handlePreference}>
                  <span className="pre-center-button">
                  Profile Settings
                  </span>
                  {/* <a href="/user/profile/settings" > */}
                  
                  {/* </a> */}
                </div>
              </div>
            )}
          </>
        </div>
      </Modal>
    </>
  );
};

export default TimeZoneModal;
