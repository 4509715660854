import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import { Outlet, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
// components
import MasterSidebar from "../components/Management/Sidebar/Sidebar.jsx";
import MasterAdminNavbar from "../components/Management/Navbar/MasterAdminNavbar.jsx";
import { logout, reset } from "../features/auth/authSlice";
import { reset as invoiceReset } from "../features/invoice/invoiceSlice.js";
import { reset as headerReset } from "../features/headerText/headerTextSlice.js";
import { reset as appSettingsReset } from "../features/appSettings/appSettingsSlice.js";

export default function MasterUserLayout() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const headerText = useSelector(
    (state) =>
      state.headerText.headerTextState &&
      state.headerText.headerTextState
  );

  const sideBarText = useSelector(
    (state) => state.sideBarText.sideBarTextState
  );
  useEffect(() => {
    localStorage.removeItem("user");
    dispatch(reset());
    dispatch(invoiceReset());
    dispatch(headerReset());
    dispatch(appSettingsReset());
    let token = null;
    const masterUser = localStorage.getItem("masterUser");
    if (masterUser) {
      const parsedMasterUser = JSON.parse(masterUser);
      if (parsedMasterUser && parsedMasterUser.token) {
        token = parsedMasterUser.token;
      }
    }

    if (!token) {
      navigate("/");
    }
  }, []);

  const location = useLocation();
  return (
    <>
      <div className={`admin-outer ${headerText}`}>
        <div className="admin-inner">
          <div className="sidebar-nav">
            <MasterSidebar />
          </div>
          <div className="outer-nav">
            <MasterAdminNavbar />
            <div className="page-top-section-header">
              <div className="page-top-section-left">
                <div className="inner-page-head">
                  <span className='icon-menu'>
                    <svg className='f-rec4' width="18" height="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect x="1" y="1" width="7" height="7" stroke="#6A7988" strokeWidth="1.5" strokeLinejoin="round" />
                      <rect x="12" y="1" width="7" height="7" stroke="#6A7988" strokeWidth="1.5" strokeLinejoin="round" />
                      <rect x="12" y="12" width="7" height="7" stroke="#6A7988" strokeWidth="1.5" strokeLinejoin="round" />
                      <rect x="1" y="12" width="7" height="7" stroke="#6A7988" strokeWidth="1.5" strokeLinejoin="round" />
                    </svg>
                  </span>
                  Dashboard
                </div>
              </div>
              <div className="page-top-section-right">
                WX AGENCY
              </div>
            </div>
            <div
              className={`content-outer ${headerText}`}
            >
              <div className="content-inner">
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div></div>
    </>
  );
}
