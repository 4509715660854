import axios from "axios";
import "./CheckSubDomain.css";
import { toast } from "react-toastify";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { validate } from "react-email-validator";
import CryptoJS from "crypto-js";
import Logo from "../../images/logo/to-logo.svg";
import { ThreeDots } from "react-loader-spinner";
import LoginImage from "../../images/login/lg-img.png";
import LoginImageMobile from "../../images/login/lg-img-mob.png";
import { AES, enc } from "crypto-js";
import { decodeData, encodeURL } from "../../helpers/encrypt";

const CheckSubDomain = () => {
  const navigate = useNavigate();
  const { email: encodedEmail, subdomain: encodedSubDomain, } = useParams();
  const [subDomain, setSubDomain] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [teams, setTeams] = useState([]);
  const domainName = window.location.hostname;
  const [loadingSubDomain, setLoadingSubDomain] = useState(null);
  const [loadingTeams, setLoadingTeams] = useState(false);

  const handleLinkClick = (subDomain) => {
    setLoadingSubDomain(subDomain);
  };


  useEffect(() => {
    setIsLoading(true);

    const getTeam = async () => {

      try {
        setLoadingTeams(true);
        const deCodedEmail = decodeData(encodedEmail);

        await new Promise(resolve => setTimeout(resolve, 500)); // 3-second delay
        const getTeam = await axios.get(`/api/tenent/teams/${encodeURL(deCodedEmail)}`, {
          headers: {}
        });

        setTeams(getTeam.data);
        if (getTeam.data.length === 0) {
          navigate("/");
          toast.dismiss(); // Clears all current toasts
          toast.error("User not registered", {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

        }
        setLoadingTeams(false);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };

    getTeam();
  }, []);

    useEffect(() => {
    if (teams.length === 1) {
      const singleTeam = teams[0];
      setLoadingSubDomain(singleTeam.subDomain);
      window.location.href = `https://${singleTeam.subDomain}.${domainName}/login`;
    }
  }, [teams, domainName]);


  useEffect(() => {
    const decodedEmail = decodeURIComponent(
      atob(encodedEmail.replace(/\-/g, "+").replace(/_/g, "/"))
    );
    const decodedSubDomain = decodeURIComponent(
      atob(encodedSubDomain.replace(/\-/g, "+").replace(/_/g, "/"))
    );
    setEmail(decodedEmail);
    setSubDomain(decodedSubDomain);
  }, [encodedEmail]);

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (validate(email)) {
      try {
        const requestData = { email, subDomain: subDomain };

        const res = await axios.post("/api/master/user/check-subdomain", requestData);
        const data = res.data;
        console.log(data);
        if (data.status === "success") {
          setLoading(true);
          const redirectUrl = `${data.redirectUrl}`;
          window.location.href = redirectUrl;
        } else {
          toast.error("Email not found in master records. Please ensure it is registered.", {
            position: "bottom-right",
            autoClose: 2000,
          });
        }
      } catch (error) {
        setLoading(false);
        console.error(error.message);
        toast.error("Team not found!", {
          position: "bottom-right",
          autoClose: 1500,
        });
      }
    } else {
      toast.error("Invalid email address. Please enter a valid email ID", {
        position: "bottom-right",
        autoClose: 1500,
      });
    }


  };

  return (
    <>
      <div className="login-outer-forget check-email">
        <div className="login-inner">
          <div className="top-logo-mobile">
            <img src={Logo} alt="Talent On" />
          </div>
          <div className="lg-lft">
            <img src={LoginImage} alt="desktop" className="desktop-lg-img" />
            <img
              src={LoginImageMobile}
              alt="mobile"
              className="mobile-lg-img"
            />
          </div>
          <div className="lg-rgt">
            <div className="top-logo">
              <img src={Logo} alt="Talent On" />
            </div>
            <div className="login-form-sec">
              <form onSubmit={onSubmit}>
                <h3>Please confirm the team workspace.</h3>
                <div className="form-outer">

                  {/* <div className="email-outer-decoration">
                    <input
                      type="text"
                      name="subdomain"
                      id="subdomain"
                      placeholder="eg: My-team"
                      value={subDomain}
                      onChange={(e) => setSubDomain(e.target.value)}
                      required
                    />
                    <span className="domain-label">.talenton.app</span>
                  </div> */}

                  <div className="form-outer">
                    <label>Choose your team URL</label>

                    {loadingTeams ? (
                      <div className="loading-teams">
                        <ThreeDots
                          height="60"
                          width="60"
                          radius="9"
                          color="blue"
                          ariaLabel="loading-teams"
                          visible={true}
                        />

                      </div>
                    ) : (
                      teams.length > 0 &&
                      teams.map((item) => (
                        <a
                          key={item.subDomain}
                          className="button-link"
                          href={`https://${item.subDomain}.${domainName}/login`}
                          onClick={() => handleLinkClick(item.subDomain)}
                        >
                          {loadingSubDomain === item.subDomain ? (
                            <ThreeDots
                              height="38"
                              width="40"
                              radius="9"
                              color="white"
                              ariaLabel="three-dots-loading"
                              visible={true}
                            />
                          ) : (
                            `${item.subDomain}.${domainName}`
                          )}
                        </a>
                      ))
                    )}

                  </div>

                </div>
                <div className="reset-button"></div>
                {/* <button type="submit" disabled={loading}>
                  {loading ?
                    <div className="outter-load-table">
                      <ThreeDots
                        height="38"
                        width="40"
                        radius="9"
                        color="white"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                      />
                    </div> : "Next"
                  }
                </button> */}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CheckSubDomain;