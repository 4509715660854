import React from "react";
import ClientsInvoices from "../../../components/Clients/ClientsInvoices/ClientsInvoices";

function ClientInvoice()  {
    return(
        <div className="react-outer">
            <ClientsInvoices />
        </div>
    )
}

export default ClientInvoice;