import axios from "axios";
import qs from "qs";
import { useSelector } from 'react-redux';

const ForexConverter = async (baseCurrency, currency, amount, forex) => {

    const token = useSelector(
        (state) => state.auth.token
    )

    const response = await axios.get(
        '/api/forex/get',
        { base_currency:"USD" },
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
    );

  

    if (forex && forex.length > 0) {
        if (baseCurrency === currency) {
            return amount;
        }
        const convertedAmount = amount * forex[currency];
        return convertedAmount;
    } else {
        return amount;
    }
};

export default ForexConverter;
