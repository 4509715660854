import React, { useState } from "react";
import GoogleLoginButton from "./googleLogin/GoogleLoginButton";
import EmailLogin from "./emailLogin/emailLogin";

function Home() {

    const GOOGLE = 1;
    const EMAIL = 2;

    const [loginMethod, setLofginMethod] = useState(null)
    return (
        <div className="react-outerme">
           
           <EmailLogin /> 

        </div>
    );
}

export default Home;




