import axios from 'axios';
import { useQuery, useMutation, useQueryClient } from 'react-query';

const API_BASE_URL = '/api';

export const useFetchLeaveTypes = (token) => {
    return useQuery('leaveTypes', async () => {
        const response = await axios.get(`${API_BASE_URL}/leave-type`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    });
};

// Delete a time-off request
export const useDeleteLeaveType = (token) => {
    const queryClient = useQueryClient(); // Get the queryClient
    return useMutation(
        (selectedId) =>
            axios.get(`/api/leave-type/delete/${selectedId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }),
        {
            onSuccess: () => {
                // You can perform any actions needed after the request is successfully deleted.
                // For example, refetch the time-off requests.
                queryClient.invalidateQueries('leaveTypes');
            },
        }
    );
};

// Create new Leave Type
export const useCreateLeaveType = (token) => {
    const queryClient = useQueryClient(); // Get the queryClient
    return useMutation(
        (requestData) =>
            axios.post(`${API_BASE_URL}/leave-type/create`, requestData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }).then((response) => response.data),
        {
            onSuccess: () => {
                // You can perform any actions needed after the request is successfully created.
                // For example, refetch the time-off requests.
                queryClient.invalidateQueries('leaveTypes');
            },
        }
    );
};

// update Leave Type
export const useUpdateLeaveType = (token) => {
    const queryClient = useQueryClient();
    return useMutation(
        ({ selectedId, leave_type }) =>
            axios.put(`${API_BASE_URL}/leave-type/update/${selectedId}`, { leave_type }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }),
        {
            onSuccess: () => {
                // You can perform any actions needed after the request is successfully created.
                // For example, refetch the users.
                queryClient.invalidateQueries('leaveTypes');
            },
        }
    );
};