import axios from "axios";
import { useQuery, queryCache } from "react-query";

export function useTagDetails(token) {
    return useQuery("tagDetails", async () => {
        const response = await axios.get("/api/tag-details", {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    });
}

export function useProjectDetails(token) {
    return useQuery("projectDetails", async () => {
        const response = await axios.get("/api/project-details", {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    });
}

export function useClientProjectDetails(token) {
    return useQuery("clientProjectDetails", async () => {
        const response = await axios.get("/api/client-project-details", {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    });
}

export function useGroupedTimerData(token , selectedUser) {
    return useQuery("groupTimerData", async () => {
        const response = await axios.get("/api/time-track/grouped/get-timer", {
            headers: {
                Authorization: `Bearer ${token}`,
            }
            ,
            params: {
                tz: global.config.getTimeZone(),
                userId:selectedUser
            },
            params: {
                tz: global.config.getTimeZone(),
                userId:selectedUser
            },
        });


        return response.data;
    });
}

export function useTimerData(token,selectedUser) {
    return useQuery("timerData", async () => {
        const response = await axios.get("/api/time-track/get-timer", {
            headers: {
                Authorization: `Bearer ${token}`,

            },
            params: {
                tz: global.config.getTimeZone(),
                userId:selectedUser
            },
        });
        var result = Object.keys(response.data).map((key) => [
            key,
            response.data[key],
        ]);
        return result;
    });
}

export function useTimeZone(userId, token) {
    return useQuery(['timeZone', userId], async () => {
        try {
            const response = await axios.get(`/api/get/user-settings/${userId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return response.data;
        } catch (error) {
            global.config.slackMessage(error.toString());
            console.log(error);
        }
    });
}

export function useUserData(userId, token) {
    return useQuery(['userData', userId], async () => {
        try {
            const response = await axios.get(`/api/user/view/${userId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return response.data;
        } catch (error) {
            global.config.slackMessage(error.toString());
            console.log(error);
        }
    });
}