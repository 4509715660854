import Modal from "react-modal";
import { toast } from "react-toastify";
import "./TimetrackerTeamDepartment.css";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import { useSelector, useDispatch } from "react-redux";
import Delete from "../../../../images/icons/delete.svg";
import React, { useEffect, useState, useRef } from "react";
import { logout, reset } from "../../../../features/auth/authSlice.js";
import addicon from "../../../../images/team-member-tab/add-icon.svg";
import crssrImage from "../../../../images/singleinvoice/cross-red.svg";
import editimgblue from "../../../../images/team-member-tab/edit-btn-blue.svg";
import { reset as invoiceReset } from "../../../../features/invoice/invoiceSlice.js";
import { reset as headerReset } from "../../../../features/headerText/headerTextSlice.js";
import { reset as appSettingsReset } from "../../../../features/appSettings/appSettingsSlice.js";
import {
  useCreateUserDepartment,
  useDeleteUserDepartment,
  useFetchUserDepartments,
  useUpdateUserDepartment,
} from "./teamDepartmentApi.jsx";

const TimeTrackerTeamDepartment = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [userDeptCreate, setUserDeptCreate] = useState("");
  const userDeptCreateRef = useRef(null);
  const [userDeptEdit, setUserDeptEdit] = useState("");
  const [updatedDeptList, setUpdatedDeptList] = useState([]);
  const user = useSelector((state) => state.auth.user);
  let token = null;
  if (user) {
    if (user && user.token) {
      token = user.token;
    }
  }
  const {
    data: userDept = [],
    isLoading,
    isError,
  } = useFetchUserDepartments(token);

  const deleteUserDepartmentMutation = useDeleteUserDepartment(token);
  const createUserDepartmentMutation = useCreateUserDepartment(token);
  const updateUserDepartmentMutation = useUpdateUserDepartment(token);

  useEffect(() => {
    setUpdatedDeptList(userDept);
  }, [userDept]);
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        document.activeElement.blur();
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleOpenModal = (item) => {
    setIsModalOpen(true);
    setSelectedId(item._id);
    setUserDeptEdit(item.user_department);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedId("");
    setUserDeptEdit("");
  };

  const handleDelete = () => {
    setIsDeleteModalOpen(true);
  };

  const handleConfirmDelete = async () => {
    try {
      setIsSubmitLoading(true);
      await deleteUserDepartmentMutation.mutateAsync(selectedId);
      setUpdatedDeptList((prevState) =>
        prevState.filter((deptList) => deptList._id !== selectedId)
      );
      toast.success("Department removed successfully", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      global.config.activityLog(
        window.location.href,
        "Department",
        `Department (${selectedId}) removed successfully`
      );
      handleCloseModal();
    } catch (error) {
      global.config.slackMessage(error.toString());
      toast.error("Something went wrong", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      console.log(error.response.data.message); // print server error message
      if (error.response?.data?.status === "deleted") {
        localStorage.removeItem("user");
        dispatch(logout());
        dispatch(reset());
        dispatch(invoiceReset());
        dispatch(headerReset());
        dispatch(appSettingsReset());
        toast.error("This user is deleted!", {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/");
        return;
      }
    } finally {
      setIsSubmitLoading(false);
    }
    setIsDeleteModalOpen(false);
  };

  const handleCancelDelete = () => {
    setIsDeleteModalOpen(false);
  };

  const handleCreateSubmit = async (event) => {
    event.preventDefault();
    if (userDeptCreate === "") {
      toast.error("User department cannot be empty!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      userDeptCreateRef.current.focus();
      return;
    }

    const requestData = {
      user_department: userDeptCreate,
    };
    try {
      setIsSubmitLoading(true);
      const data = await createUserDepartmentMutation.mutateAsync(requestData);
      if (data.status === "error") {
        toast.error("Something went wrong", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        global.config.activityLog(
          window.location.href,
          "TaDepartment",
          `Something went wrong while adding Department`
        );
      } else if (data.status === "exists") {
        toast.error("Department already exists", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        global.config.activityLog(
          window.location.href,
          "TaDepartment",
          `Department already exist`
        );
      } else {
        toast.success("User department added successfully", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        global.config.activityLog(
          window.location.href,
          "TaDepartment",
          `Department ${userDeptCreate} added successfully`
        );
        setUserDeptCreate("");
      }
    } catch (error) {
      console.log(error);
      global.config.slackMessage(error.toString());
      if (error.response?.data?.status === "deleted") {
        localStorage.removeItem("user");
        dispatch(logout());
        dispatch(reset());
        dispatch(invoiceReset());
        dispatch(headerReset());
        dispatch(appSettingsReset());
        toast.error("This user is deleted!", {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/");
        return;
      }
      toast.error("Something went wrong");
      global.config.activityLog(
        window.location.href,
        "TaDepartment",
        `Something went wrong`
      );
    } finally {
      setIsSubmitLoading(false); // Set isLoading to false after the approval process is complete
    }
  };

  const handleUpdateSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsSubmitLoading(true);
      const response = await updateUserDepartmentMutation.mutateAsync({
        selectedId,
        user_department: userDeptEdit,
      });
      const data = response.data;

      if (data.status === "error") {
        toast.error("Something went wrong", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (data.status === "exists") {
        toast.error("Department already exists", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        setUpdatedDeptList((prevState) => {
          const updatedDepartment = prevState.map((item) => {
            if (item._id === selectedId) {
              return { ...item, user_department: userDeptEdit };
            }
            return item;
          });
          return updatedDepartment;
        });
        toast.success("Department updated successfully", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        global.config.activityLog(
          window.location.href,
          "Department",
          `Department ${userDeptEdit} updated successfully`
        );
        handleCloseModal();
      }
    } catch (error) {
      console.log(error.response.data.message); // print server error message
      global.config.slackMessage(error.toString());
      if (error.response?.data?.status === "deleted") {
        localStorage.removeItem("user");
        dispatch(logout());
        dispatch(reset());
        dispatch(invoiceReset());
        dispatch(headerReset());
        dispatch(appSettingsReset());
        toast.error("This user is deleted!", {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/");
        return;
      }
      alert("Something went wrong");
    } finally {
      setIsSubmitLoading(false);
    }
  };

  return (
    <div>
      <div className="div-outter-for-department">
        <div className="add-department-section">
          <input
            type="text"
            placeholder="Add new department"
            value={userDeptCreate}
            onChange={(e) => setUserDeptCreate(e.target.value)}
            ref={userDeptCreateRef}
          />

          <button
            className="add-btn-in-department-b1"
            onClick={handleCreateSubmit}
            disabled={isSubmitLoading}
          >
            {isSubmitLoading ? (
              <div className="outter-load-table">
                <ThreeDots
                  height="21"
                  width="25"
                  radius="9"
                  color="white"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
              </div>
            ) : (
              <img src={addicon} alt="add" />
            )}
          </button>
        </div>
        <div className="department-section-listing">
          {isLoading == true ? (
            <div className="outter-load-table">
              <ThreeDots
                height="80"
                width="80"
                radius="9"
                color="#405BFF"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            </div>
          ) : (
            <>
              {updatedDeptList.map((item, index) => (
                <div className="grid-item" key={item._id}>
                  <input
                    type="text"
                    className="input-tag"
                    value={item.user_department}
                    readOnly
                  />
                  <button
                    className="edit-button"
                    onClick={() => handleOpenModal(item)}
                  >
                    <img src={editimgblue} alt="edit" />
                    Edit
                  </button>
                </div>
              ))}
            </>
          )}
        </div>
      </div>
      <div className="modal-invite-main-outter">
        <Modal
          className="client-modal leave"
          isOpen={isModalOpen}
          onRequestClose={handleCloseModal}
          contentLabel="New Client Account"
          style={{
            content: {
              height: "41%",
              width: "30%",
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              parent: document.querySelector(".admin-outer.time.tracker"),
            },
          }}
        >
          <div className="modal">
            <div className="modal-close-invite outter-modal-main">
              <a className="" onClick={handleCloseModal}>
                <img src={crssrImage} alt="Edit Icon" />{" "}
              </a>
            </div>
            <div className="modal-client-head">
              <h2 className="">Edit Department</h2>
            </div>
            <div className="form-field-client-outer">
              <form onSubmit={handleUpdateSubmit}>
                <div className="form-field-client">
                  <label htmlFor="clientName" className="form-label">
                    Department Name
                  </label>
                  <input
                    type="text"
                    id="clientName"
                    name="clientName"
                    className="form-input"
                    value={userDeptEdit}
                    onChange={(e) => setUserDeptEdit(e.target.value)}
                    required
                  />
                </div>
                {isSubmitLoading ? (
                  <div className="outter-load-table">
                    <ThreeDots
                      height="38"
                      width="40"
                      radius="9"
                      color="#6479f9"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  </div>
                ) : (
                  <div className="form-field-client-btns">
                    <button
                      type="button"
                      className="delete-client"
                      onClick={handleDelete}
                      disabled={isSubmitLoading}
                    >
                      <img src={Delete} alt="Delete" /> Delete
                    </button>
                    <Modal
                      className="delete-modal"
                      isOpen={isDeleteModalOpen}
                      onRequestClose={handleCancelDelete}
                      contentLabel="Confirm Delete"
                      style={{
                        content: {
                          height: "41%",
                          width: "30%",
                          position: "fixed",
                          top: "36%",
                          left: "50%",
                          transform: "translate(-20.75%, -5%)",
                          parent: document.querySelector(
                            ".admin-outer.time.tracker"
                          ),
                        },
                      }}
                    >
                      <div className="delete-modal-content">
                        <h2>Confirm Delete</h2>
                        <p>Are you sure you want to delete this client?</p>
                        {isSubmitLoading ? (
                          <div className="outter-load-table">
                            <ThreeDots
                              height="38"
                              width="40"
                              radius="9"
                              color="#6479f9"
                              ariaLabel="three-dots-loading"
                              wrapperStyle={{}}
                              wrapperClassName=""
                              visible={true}
                            />
                          </div>
                        ) : (
                          <div className="delete-modal-buttons">
                            <button
                              className="delete"
                              onClick={handleConfirmDelete}
                              disabled={isSubmitLoading}
                            >
                              Delete
                            </button>
                            <button onClick={handleCancelDelete}>Cancel</button>
                          </div>
                        )}
                      </div>
                    </Modal>
                    <button
                      type="submit"
                      className="save-changes"
                      disabled={isSubmitLoading}
                    >
                      Save Changes
                    </button>
                  </div>
                )}
              </form>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default TimeTrackerTeamDepartment;
