import React, { useState } from "react";
import "../OnboardingCompanyDetails/CompanyDetaislForm.css";

function OnboardingSecurity({ onSubmit, onBack }) {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    confirmPassword: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData); // Call the passed down onSubmit function
    setFormData({
      email: "",
      password: "",
      confirmPassword: "",
    });
  };

  return (
    <div className="onboarding-detail-outer">
      <div className="company-detail-title">Security Information</div>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="email">Email address</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password*</label>
          <input
            type="password"
            id="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="confirm-password">Confirm Password*</label>
          <input
            type="password"
            id="confirm-password"
            name="confirmPassword"
            value={formData.confirmPassword}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-button-group">
          <div className="form-back-button">
            <button type="button" onClick={onBack}>
              Back
            </button>
          </div>
          <div className="continue-button">
            <button type="submit">Continue</button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default OnboardingSecurity;
