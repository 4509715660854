import axios from "axios";
import Modal from "react-modal";
import "../../../../components/TimeTracker/TimeTrackerProject/TimeTrackerProject.css";
import "rsuite/dist/rsuite.min.css";
import { toast } from "react-toastify";
import { AES, enc } from 'crypto-js';
import "react-toastify/dist/ReactToastify.css";
import { useParams, useNavigate } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import { useSelector, useDispatch } from 'react-redux';
import Select from "react-select";
import Close from "../../../../images/icons/black-close.svg";
import PlusIcon from "../../../../images/icons/plus-icon.svg";
import React, { useState, useEffect, useRef } from "react";
import Tick from "../../../../images/singleinvoice/tick-green.svg";
// import { logout, reset } from "../../../features/auth/authSlice";
import crssrImage from "../../../../images/singleinvoice/cross-red.svg";
// import { reset as invoiceReset } from "../../../features/invoice/invoiceSlice.js";
// import { reset as headerReset } from "../../../features/headerText/headerTextSlice.js";
// import { reset as appSettingsReset } from "../../../features/appSettings/appSettingsSlice.js";
import { setProjectAdded } from "../../../../features/projects/projectSlice.js";
import { useFetchClientProjectManager } from "../../../../components/TimeTracker/TimeTrackerProject/useProjectDetails.jsx";
import { useFetchClientDetails } from "../../../../components/TimeTracker/TimeTrackerClient/useClientDetails.jsx";


const NewProjectsModal = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isProjectModalOpen, setIsProjectModalOpen] = useState(false);
  const [projectName, setProjectName] = useState("");
  const [clientDropdownOpen, setClientDropdownOpen] = useState(false);
  const [clientSearchTerm, setClientSearchTerm] = useState('');
  const [clientId, setClientId] = useState("");
  const [discription, setDiscription] = useState("");
  const [billable, setBillable] = useState(true);
  // const [budget, setBudget] = useState("");
  const [selectedProjectManagers, setSelectedProjectManagers] = useState([]);
  const [totalHours, setTotalHours] = useState("");
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [sprints, setSprints] = useState([]);
  const [sprint, setSprint] = useState("");
  const [sprintDiscription, setSprintDiscription] = useState("");

  const [projectNameIsValid, setProjectNameIsValid] = useState(false);
  const projectNameRef = useRef(null);
  const [clientIdIsValid, setClientIdIsValid] = useState(false);
  const clientIdRef = useRef(null);
  const clientDropdownRef = useRef(null);

  const [shouldFetchProject, setShouldFetchProject] = useState(false);

  const token = useSelector(
    (state) => state.auth.token
  )

  const { data: userId = [], isLoading: isUserLoading, refetch: refetchProjectManager } = useFetchClientProjectManager(token, clientId);
  const { data: clientName = [], isLoading: clientLoading, refetch: refetchClients } = useFetchClientDetails(token);

  const handleOpenProjectModal = () => {
    setIsProjectModalOpen(true);
  };
  const handleCloseProjectModal = () => {
    setIsProjectModalOpen(false);
    setProjectName("");
    setClientId("");
    setDiscription("");
    setBillable(true);
    // setBudget("");
    setSelectedProjectManagers([]);
    setTotalHours("");
    setSprint("");
    setSprintDiscription("");
    setProjectNameIsValid(false);
    setClientIdIsValid(false);
  };

  useEffect(() => {

    //refetchProjectManager();

    const radioLabels = document.querySelectorAll(".radio-option label");
    radioLabels.forEach((label) => {
      label.addEventListener("click", (e) => {
        const radioInput = label.previousElementSibling;
        radioInput.checked = true;
      });
    });
  }, [userId, clientId]);

  const projectManagerOptions = userId
    .slice()
    .sort((a, b) => {
      const nameA = a.first_name
        ? `${a.first_name} ${a.middle_name || ""} ${a.last_name}`
        : `${a.company_first_name ? a.company_first_name + (a.company_last_name && a.company_last_name) : a.company_name}`;
      const nameB = b.first_name
        ? `${b.first_name} ${b.middle_name || ""} ${b.last_name}`
        : `${b.company_first_name ? b.company_first_name + (b.company_last_name && b.company_last_name) : b.company_name}`;
      return nameA.localeCompare(nameB);
    })
    .map((item) => ({
      label: item.first_name ? `${item.first_name} ${item.middle_name || ""} ${item.last_name}` : (item.company_first_name ? `${item.company_first_name + (item.company_last_name && item.company_last_name)}` : `${item.company_name}(Company)`),
      value: item._id,
    }));

  const handleToggleClientDropdown = () => {
    setClientSearchTerm('');
    setClientDropdownOpen(!clientDropdownOpen);
  };

  const handleClientSearchInputChange = (e) => {
    setClientSearchTerm(e.target.value);
  };

  const filteredClients = clientName
    .filter((client) =>
      client.client_name.toLowerCase().includes(clientSearchTerm.toLowerCase())
    )
    .sort((a, b) =>
      a.client_name.toLowerCase().localeCompare(b.client_name.toLowerCase())
    );



  const handleClickOutsideDropdown = (event) => {
    const isClickInsideClienDropdown = clientDropdownRef.current && clientDropdownRef.current.contains(event.target);
    // const isClickInsideSprintProjectDropdown = sprintProjectDropdownRef.current && sprintProjectDropdownRef.current.contains(event.target);
    // if (!isClickInsideSprintProjectDropdown) {
    //   setSprintProjectDropdownOpen(false);
    // }
    if (!isClickInsideClienDropdown) {
      setClientDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutsideDropdown);
    return () => {
      document.removeEventListener('click', handleClickOutsideDropdown);
    };
  }, []);

  const handleProjectSubmit = async () => {
    setIsSubmitLoading(true);
    setProjectNameIsValid(false);
    setClientIdIsValid(false);
    if (projectName === "" || clientId === "") {
      if (projectName === "") {
        setProjectNameIsValid(true);
        projectNameRef.current.focus();
        return;
      } else if (clientId === "") {
        setClientIdIsValid(true);
        clientIdRef.current.focus();
        return;
      }
      toast.error("Please fill all required the fields", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    let startdate, enddate;

    const requestData = {
      project_name: projectName.trim(),
      client_name: clientId,
      discription,
      is_billable: billable,
      // budget,
      total_hours: totalHours,
      project_manager: selectedProjectManagers.map(manager => manager.value),
    };
    const allSprints = [
      ...sprints,
      {
        sprint: sprint,
        sprintDiscription: sprintDiscription,
        sprintStartDate: startdate,
        sprintEndDate: enddate,
        id: Date.now(),
      },
    ]; // combine all Sprints including the last added one
    const response = await fetch("/api/project-details", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        ...requestData,
        sprint: allSprints.map((obj) => obj.sprint), // use all Sprints to send
        sprintDiscription: allSprints.map((obj) => obj.sprintDiscription), // use all Sprints to send
        sprintStartDate: allSprints.map((obj) => obj.sprintStartDate), // use all Sprints to send
        sprintEndDate: allSprints.map((obj) => obj.sprintEndDate), // use all Sprints to send
      }),
    });
    setSprints([]);
    setSprint("");
    if (response.ok) {
      if (!sprint) {
        toast.success("Project added successfully", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        global.config.activityLog(window.location.href, 'Project', `Project (${projectName}) added Successfully`);
        dispatch(setProjectAdded());
        handleCloseProjectModal();
      } else {
        global.config.activityLog(window.location.href, 'Project', `Project (${projectName}) and Sprints added Successfully`);
        toast.success("Project and sprint added successfully", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        handleCloseProjectModal();
      }
    } else {
      global.config.activityLog(window.location.href, 'Project', `Failed to add Project (${projectName}) successfully`);
      toast.error("Failed to add project and sprint successfully", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    if (shouldFetchProject === false) {
      setShouldFetchProject(true);
    } else {
      setShouldFetchProject(false);
    }

    setIsSubmitLoading(false);
    dispatch(setProjectAdded());
  };

  const numberInputOnWheelPreventChange = (e) => {
    // Prevent the input value change
    e.target.blur()

    // Prevent the page/container scrolling
    e.stopPropagation()

    setTimeout(() => {
      e.target.focus()
    }, 0)
  }



  return (

    <>
      <a className="new-client" href="#" onClick={handleOpenProjectModal}>
        <span className="icon-invoice">
          <img src={PlusIcon} alt="New Client" />
        </span>
        New Project
      </a>
      
        <Modal
          className="client-modal project-modal"
          isOpen={isProjectModalOpen}
          onRequestClose={handleCloseProjectModal}
          contentLabel="New Project"
          style={{
            content: {
              height: "41%",
              width: "30%",
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              parent: document.querySelector(".admin-outer.time.tracker"),
            },
          }}
        >
          <div className="modal">
            <div className="modal-close-invite outter-modal-main">
              <a className="" onClick={handleCloseProjectModal}>
                <img src={crssrImage} alt="Edit Icon" />{" "}
              </a>
            </div>
            <div>
              <div className="modal-client-head">
                <h2 className="">New Project</h2>
              </div>
              <div className="form-field-client-outer">
                <form>
                  <div className="form-field-client">
                    <label htmlFor="projectName" className="form-label">
                      Project Name
                    </label>
                    <input
                      type="text"
                      id="projectName"
                      name="projectName"
                      value={projectName}
                      onChange={(e) => setProjectName(e.target.value)}
                      className={projectNameIsValid ? "input-validation-error form-input" : "form-input"}
                      ref={projectNameRef}
                    />
                    {projectNameIsValid && (
                      <span className="text-red-500 text-xs italic">
                        Project Name Field Cannot be empty
                      </span>
                    )}
                  </div>

                  <div className="form-field-client">
                    <label htmlFor="client" className="form-label">
                      Client Name
                    </label>

                    <div
                      className={`custom-dropdown-user-new-outer custom-dropdown-select ${clientDropdownOpen ? 'open' : ''
                        }`}
                      ref={clientDropdownRef}
                    >
                      <div
                        className="selected-option custom-dropdown-user-new"
                        onClick={handleToggleClientDropdown}
                        style={{ cursor: 'pointer' }}
                      >
                        {clientId ? (
                          clientName.find((client) => client._id === clientId)?.client_name
                        ) : (
                          'Select'
                        )}
                      </div>
                      {clientDropdownOpen && (
                        <div className="custom-dropdown-new">
                          <input
                            type="text"
                            className="search-bar"
                            placeholder="Search..."
                            value={clientSearchTerm}
                            onChange={handleClientSearchInputChange}
                          />
                          <ul className="client-list">
                            {filteredClients.map((client) => (
                              <li
                                key={client._id}
                                className={clientId === client._id ? 'selected-item' : ''}
                                onClick={() => {
                                  setClientId(client._id);
                                  setClientDropdownOpen(false);
                                }}
                              >
                                {client.client_name}
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                    {clientIdIsValid && (
                      <span className="text-red-500 text-xs italic">
                        Client Name Field Cannot be empty
                      </span>
                    )}
                  </div>

                  <div className="form-field-client">
                    <label htmlFor="description" className="form-label">
                      Description
                    </label>
                    <textarea
                      id="description"
                      name="description"
                      value={discription}
                      onChange={(e) => setDiscription(e.target.value)}
                      className="form-textarea"
                      rows="3"
                    ></textarea>
                  </div>
                  <div className="form-field-client">
                    <label htmlFor="tome" className="form-label">
                      Time Allocated(in hours)
                    </label>
                    <input
                      type="number"
                      id="time"
                      name="time"
                      value={totalHours}
                      onChange={(e) => setTotalHours(e.target.value)}
                      className="form-input"
                      onWheel={numberInputOnWheelPreventChange}
                    />
                  </div>
                  {/* <div className="form-field-client">
                    <label htmlFor="tome" className="form-label">
                      Budget
                    </label>
                    <input
                      type="number"
                      id="budget"
                      name="budget"
                      value={budget}
                      onChange={(e) => setBudget(e.target.value)}
                      className="form-input"
                      onWheel={numberInputOnWheelPreventChange}
                    />
                  </div> */}
                  <div className="form-field-client">
                    <label htmlFor="projectManager" className="form-label">
                     Assigned team
                    </label>
                    {isUserLoading ? (
                      <div className="outter-load-table">
                        <ThreeDots
                          height="38"
                          width="40"
                          radius="9"
                          color="#6479f9"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClassName=""
                          visible={true}
                        />
                      </div>
                    ) : (
                      <Select
                        isMulti
                        options={projectManagerOptions}
                        value={selectedProjectManagers}
                        onChange={(selectedOptions) => setSelectedProjectManagers(selectedOptions)}
                      />
                    )}
                  </div>
                  <div className="form-field-client radio-sec">
                    <label htmlFor="billable" className="form-label">
                      Billable
                    </label>
                    <div className="radio-container">
                      <div className="radio-option yes">
                        <input
                          type="radio"
                          id="yes"
                          name="billable"
                          value={billable}
                          checked={billable === true}
                          onChange={(e) => setBillable(true)}
                        />
                        <label htmlFor="yes">
                          Yes <img src={Tick} alt="Tick" />
                        </label>
                      </div>
                      <div className="radio-option no">
                        <input
                          type="radio"
                          id="no"
                          name="billable"
                          value={billable}
                          checked={billable === false}
                          onChange={(e) => setBillable(false)}
                        />
                        <label htmlFor="no">
                          No <img src={Close} alt="Edit Icon" />
                        </label>
                      </div>
                    </div>
                  </div>

                  {isSubmitLoading ?
                    (
                      <div className="outter-load-table">
                        <ThreeDots
                          height="38"
                          width="40"
                          radius="9"
                          color="#6479f9"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClassName=""
                          visible={true}
                        />
                      </div>
                    ) : (
                      <button type="button" onClick={handleProjectSubmit} className="submit-client">
                        Create Project
                      </button>
                    )}
                </form>
              </div>
            </div>

          </div>
        </Modal>
      
    </>
  )
}

export default NewProjectsModal;