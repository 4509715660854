// components/SubdomainGuard.js
import React, { useState, useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { IsValidSubdomain } from '../utils/subdomainUtils';

const SubdomainGuard = ({ children }) => {
  const navigate = useNavigate();
  const [isValid, setIsValid] = useState(null); // null initially to represent the loading state

  useEffect(() => {
    const checkSubdomain = async () => {
      const isValidSubdomain = await IsValidSubdomain();
      setIsValid(isValidSubdomain);
    };

    checkSubdomain();
  }, []);

  if (isValid === null) {
    // Render a loading indicator or nothing while loading
    // return <div>Loading...</div>;
  }

  if (isValid === false) {
    // If the subdomain is invalid, redirect to the error page
    //return <Navigate to="/" replace />;
       window.location.href = 'https://app.talenton.io';
       return null; // Render nothing while redirecting

  }

  // If valid, render the wrapped component(s)
  return children;
};

export default SubdomainGuard;