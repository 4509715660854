import React from "react";
import InviteRegisteration from "../../../components/User/invite/InviteRegisteration";

function InviteRegister()  {
    return(
        <div className="react-outer">
            <InviteRegisteration/>
        </div>
    )
}

export default InviteRegister;