import React, { PureComponent, useMemo } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { ThreeDots } from "react-loader-spinner";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, LabelList } from 'recharts';
import { processedTotalHoursByService } from './Services/reportServices';

const data = [
  { name: 'United States', closedAmount: 44 },
  { name: 'Belgium', closedAmount: 55 },
  { name: 'Panama', closedAmount: 13 },
  { name: 'Germany', closedAmount: 43 },
  { name: 'Netherlands', closedAmount: 46 },
  { name: 'Mexico', closedAmount: 55 },
  { name: 'Country G', closedAmount: 32 },
];

const TotalHoursPerServiceGraph = ({ trackReport, trackReportLoading, trackReportError }) => {
  const reportingServices = useSelector((state) => state.reportingServices);

  const processedData = useMemo(() => {
    return processedTotalHoursByService(trackReport, reportingServices);
  }, [trackReport, reportingServices]);

  if (trackReportLoading) return <div className="outter-load-table">
    <ThreeDots
      height="80"
      width="80"
      radius="9"
      color="#6479f9"
      ariaLabel="three-dots-loading"
      wrapperStyle={{}}
      wrapperClassName=""
      visible={true}
    />
  </div>;
  if (trackReportError) return <div>Error loading data.</div>;

  const changeDurationFormat = (sec) => {
    const hours = Math.floor(sec / 3600);
    const minutes = Math.floor((sec % 3600) / 60);
    const seconds = sec % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  const calculateXTicks = () => {
    const maxDataValue = Math.max(...processedData.map(item => item.closedAmount));
    const tickInterval = Math.ceil(maxDataValue / 10); // Set the interval between ticks
    const numOfTicks = Math.min(Math.ceil(maxDataValue / tickInterval), 10);
    const ticks = [];

    for (let i = 0; i <= numOfTicks + 4; i++) { // Adjust the number of ticks
      ticks.push(i * tickInterval);
    }

    return ticks;
  }

  const xAxisTicks = calculateXTicks();

  const calculateChartHeight = (dataLength) => {
    const rowHeight = 50;
    const baseHeight = 100;
    return dataLength * rowHeight + baseHeight;
  };

  const calculateLeftMargin = (data) => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    context.font = '12px Arial';

    const longestWordLength = data.reduce((maxLength, item) => {
      const firstWord = item.name.split(' ')[0];
      const wordWidth = context.measureText(firstWord).width;
      return Math.max(maxLength, wordWidth);
    }, 0);

    return Math.max(20, longestWordLength - 150);
  };

  const leftMargin = calculateLeftMargin(processedData);

  return (
    <div className='graph-project-per-hour' style={{ width: '100%', height: Math.max(542, calculateChartHeight(processedData.length)), }}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          layout="vertical"
          data={processedData}
          barSize={13}
          margin={{
            top: 20,
            right: 42,
            left: leftMargin,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" horizontal={false} vertical={true} />
          <XAxis
            type="number"
            domain={[0, 'dataMax + 10']}
            tickFormatter={(tick) => changeDurationFormat(tick)}
            ticks={xAxisTicks}
          />
          <YAxis
            type="category"
            dataKey="name"
            // width={150} // Ensure enough width for client names
            tickMargin={10}
          />
          <Tooltip formatter={(value) => changeDurationFormat(value)} />
          <Bar dataKey="closedAmount" name="Closed amount in HH:MM:SS" fill="#87ABE2">
            <LabelList dataKey="closedAmount" position="right" fill="#282828" formatter={(value) => changeDurationFormat(value)} />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}

export default TotalHoursPerServiceGraph;
