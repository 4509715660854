import axios from 'axios';
import { useQuery, useMutation, useQueryClient } from 'react-query';
const API_BASE_URL = '/api';

export const useFetchTag = (token) => {
    return useQuery('tags', async () => {
        const response = await axios.get(`${API_BASE_URL}/tag-details`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    });
};

// Delete a Tag
export const useDeleteTag = (token) => {
    const queryClient = useQueryClient(); // Get the queryClient
    return useMutation(
        (selectedId) =>
            axios.get(`/api/tag-details/delete/${selectedId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }),
        {
            onSuccess: () => {
                // You can perform any actions needed after the request is successfully deleted.
                // For example, refetch the time-off requests.
                queryClient.invalidateQueries('tags');
            },
        }
    );
};

// Create new Tag
export const useCreateTag = (token) => {
    const queryClient = useQueryClient(); // Get the queryClient
    return useMutation(
        (requestData) =>
            axios.post(`${API_BASE_URL}/tag-details`, requestData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }).then((response) => response.data),
        {
            onSuccess: () => {
                // You can perform any actions needed after the request is successfully created.
                // For example, refetch the time-off requests.
                queryClient.invalidateQueries('tags');
            },
        }
    );
};

// update Tag
export const useUpdateTag = (token) => {
    const queryClient = useQueryClient();
    return useMutation(
        ({ selectedId, tag_name }) =>
            axios.put(`${API_BASE_URL}/tag-details/update/${selectedId}`, { tag_name }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }),
        {
            onSuccess: () => {
                // You can perform any actions needed after the request is successfully created.
                // For example, refetch the users.
                queryClient.invalidateQueries('tags');
            },
        }
    );
};