const API_URL = '/api/user/'

//Register user
const register = async (userData) => {

    localStorage.setItem('user', JSON.stringify(userData))
}

const logout = async () => {
    localStorage.removeItem('user')
}

const login = async (userData) => {

    localStorage.setItem('user', JSON.stringify(userData))
}

const automaticInvoiceuser = async (autoData) => {

    var user = autoData;
    localStorage.setItem('selectedUser', JSON.stringify(autoData))
    return user;
}
const setBankId = async (bankId) => {
    return bankId;
}

const setDateRange = async (dateRange) => {
    return dateRange;
}

const setCurrency = (currency) => {
    return currency;
}

const invoiceService = {
    register: register,
    logout: logout,
    login: login,
    automaticInvoiceuser: automaticInvoiceuser,
    setBankId,
    setDateRange,
    setCurrency
}

export default invoiceService;