import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  financialOverviewClient: null,
  financialOverviewProject: null,
  financialOverviewYear: null,
  financialOverviewCurrency: null,
};

export const setReportingFinancialOverviewClient = createAsyncThunk(
  "reporting/financial/overview/client",
  async (financialOverviewClient) => {
    return financialOverviewClient;
  }
);

export const setReportingFinancialOverviewProject = createAsyncThunk(
  "reporting/financial/overview/project",
  async (financialOverviewProject) => {
    return financialOverviewProject;
  }
);

export const setReportingFinancialOverviewYear = createAsyncThunk(
  "reporting/financial/overview/year",
  async (financialOverviewYear) => {
    if (financialOverviewYear !== null) {
    //   const date = startOfYear(new Date(year, 0, 1));
    //   date.setHours(0, 0, 0, 0);
      return financialOverviewYear;
    } else {
      return null;
    }
  }
);

export const setReportingFinancialOverviewCurrency = createAsyncThunk(
  "reporting/financial/overview/currency",
  async (financialOverviewCurrency) => {
    return financialOverviewCurrency;
  }
);

export const reportingFinancialOverviewSlice = createSlice({
  name: "reportingFinancialOverview",
  initialState,
  reducers: {
    reset: (state) => {
      state.financialOverviewClient = null;
      state.financialOverviewProject = null;
      state.financialOverviewYear = null;
      state.financialOverviewCurrency = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setReportingFinancialOverviewClient.fulfilled, (state, action) => {
      state.financialOverviewClient = action.payload;
    });
    builder.addCase(setReportingFinancialOverviewProject.fulfilled, (state, action) => {
      state.financialOverviewProject = action.payload;
    });
    builder.addCase(setReportingFinancialOverviewYear.fulfilled, (state, action) => {
      state.financialOverviewYear = action.payload;
    });
    builder.addCase(setReportingFinancialOverviewCurrency.fulfilled, (state, action) => {
      state.financialOverviewCurrency = action.payload;
    });
  },
});

export const { reset } = reportingFinancialOverviewSlice.actions;
export default reportingFinancialOverviewSlice.reducer;
