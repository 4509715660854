import React from 'react';
import moment from "moment";
import css from './Sidebar.css';
import { Link } from 'react-router-dom';
import logo from '../../../images/icons/logo-managment.svg';
import { useSelector, useDispatch } from "react-redux";
import { setDashboardDate, setInvoiceType } from "../../../features/invoice/invoiceSlice";
import { setHeaderText } from "../../../features/headerText/headerTextSlice";
import { resetAccountDetails } from "../../../features/accountDetails/accountDetailsSlice.js";
import { setSideBarText } from '../../../features/sideBarText/sideBarTextSlice.js';


const MasterSidebar = () => {
  const dispatch = useDispatch();
  const headerText = useSelector(
    (state) => state.headerText.headerTextState
  );
  const sideBarText = useSelector(
    (state) => state.sideBarText.sideBarTextState
  );
  const masterUser = useSelector(
    (state) => state.masterAuth.masterUser
  );
  const { appSettings } = useSelector((state) => state.appSettings);

  const handleTabClick = () => {
    // dispatch(resetAccountDetails());
    // dispatch(setSideBarText(""));
    // dispatch(setInvoiceType(null));
    // dispatch(setDashboardDate([
    //   new Date(moment().tz(global.config.getTimeZone()).subtract(30, 'years').startOf('year').format()).toISOString(),
    //   new Date(moment().tz(global.config.getTimeZone()).endOf('year').format()).toISOString()
    // ]));
  };

  return (
    <div className='sidebar-outer'>
      <div className='logo-sec mobile-hide-logo-secsl'>
        <Link to="dashboard" onClick={handleTabClick}>
          <img src={logo} alt="Logo" />
        </Link>
      </div>
      <div className='main-menu-outer'>
        <div className='main-menu-header'>
        </div>
        <div className='main-menu-inner'>
          <ul>
            <div className='own-name'>{appSettings && appSettings.app_ownername ? appSettings.app_ownername : "TalenTon"}</div>
            {masterUser && (
              <li className={headerText === "Dashboard" && "active"}>
                <Link to="/master-user/dashboard" className={headerText === "Dashboard" && "active"} onClick={handleTabClick}>
                  <span className='icon-menu'>
                    <svg className='f-rec4' width="18" height="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect x="1" y="1" width="7" height="7" stroke="#6A7988" strokeWidth="1.5" strokeLinejoin="round" />
                      <rect x="12" y="1" width="7" height="7" stroke="#6A7988" strokeWidth="1.5" strokeLinejoin="round" />
                      <rect x="12" y="12" width="7" height="7" stroke="#6A7988" strokeWidth="1.5" strokeLinejoin="round" />
                      <rect x="1" y="12" width="7" height="7" stroke="#6A7988" strokeWidth="1.5" strokeLinejoin="round" />
                    </svg>
                  </span>
                  {global.config.locate(appSettings && appSettings.language, 'Dashboard')}
                </Link>
              </li>
            )}


            <li>
              <Link to="/master-user/tenants/list" className={headerText === "Tenant Management" && "active"} onClick={handleTabClick}>
                <span className='icon-menu'>
                  <svg className='f-rec4' width="21" height="17" viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.81082 11.95C4.552 11.95 2.37945 14.0792 1.70053 15.1438C-0.336229 17.6988 2.54918 18.3375 4.24649 18.3375H10.3568C12.2238 18.3375 15.6524 17.6988 14.4303 15.1438C13.2082 12.5888 9.50812 11.95 7.81082 11.95Z" stroke="#6A7988" strokeWidth="1.5" strokeLinecap="square" />
                    <path d="M15.5996 11.95C17.3918 11.95 20.4251 12.5888 21.7155 15.1438C23.0059 17.6988 19.3857 18.3375 17.4142 18.3375" stroke="#6A7988" strokeWidth="1.5" strokeLinecap="square" />
                    <circle cx="7.84355" cy="5.10625" r="4.10625" stroke="#6A7988" strokeWidth="1.5" />
                    <path d="M14.6875 9.2125C16.7033 9.2125 18.3375 7.37407 18.3375 5.10625C18.3375 2.83843 16.7033 1 14.6875 1" stroke="#6A7988" strokeWidth="1.5" />
                  </svg>
                </span>
                Tenants
              </Link>
            </li>

            {/* <li>
              <Link to="/master-user/subscriptions" className={headerText === "Subscriptions" && "active"} onClick={handleTabClick}>
                <span className='icon-menu'>
                  <svg width="19" height="25" viewBox="0 0 19 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18.9999 6.32871V24.4248C18.9999 24.6549 18.8812 24.8467 18.6438 24.9617C18.5645 25 18.4855 25 18.4063 25C18.2481 25 18.1291 24.9617 18.0104 24.8467L16.6251 23.6577L15.2395 24.8467C15.0021 25.0385 14.6854 25.0385 14.4479 24.8467L13.0624 23.6577L11.6768 24.8467C11.4394 25.0385 11.1227 25.0385 10.8852 24.8467L9.49997 23.6577L8.11442 24.8467C7.87696 25.0385 7.56027 25.0385 7.32282 24.8467L5.93755 23.6577L4.552 24.8467C4.31455 25.0385 3.99785 25.0385 3.76041 24.8467L2.37486 23.6577L0.989303 24.8467C0.831091 25 0.553875 25.0385 0.355909 24.9235C0.118728 24.8467 0 24.6549 0 24.4248V0.575237C0 0.268351 0.277217 0 0.593635 0H12.4686H12.5873C12.6268 0 12.6268 -2.64496e-07 12.6666 0.0382596H12.706C12.7455 0.0382596 12.7455 0.0765197 12.7853 0.0765197H12.8248C12.8643 0.11478 12.904 0.114779 12.904 0.153308L15.8728 3.03004C17.5747 4.67923 18.3268 5.40808 18.6833 5.79171C18.8415 5.8685 18.9605 6.02181 19 6.21364C18.9997 6.2519 18.9999 6.29045 18.9999 6.32871ZM13.0625 1.95607V5.75343H16.9813C15.8729 4.67945 14.1707 3.02998 13.0625 1.95607ZM17.8127 23.159V6.90405H12.4688C12.1521 6.90405 11.8752 6.63543 11.8752 6.32882V1.15056H1.18769V23.159L1.97929 22.4687C2.09801 22.3536 2.21674 22.3154 2.37523 22.3154C2.53344 22.3154 2.65245 22.3536 2.77118 22.4687L4.15673 23.6577L5.54228 22.4687C5.77974 22.2769 6.09643 22.2769 6.33387 22.4687L7.71943 23.6577L9.10498 22.4687C9.34244 22.2769 9.65913 22.2769 9.89657 22.4687L11.2821 23.6577L12.6677 22.4687C12.9051 22.2769 13.2218 22.2769 13.4593 22.4687L14.8448 23.6577L16.2304 22.4687C16.4678 22.2769 16.7845 22.2769 17.022 22.4687L17.8127 23.159ZM14.9688 17.2466H4.09382C3.77712 17.2466 3.50019 17.5152 3.50019 17.8218C3.50019 18.1287 3.7774 18.397 4.09382 18.397H14.9688C15.2855 18.397 15.5624 18.1284 15.5624 17.8218C15.5624 17.5152 15.2855 17.2466 14.9688 17.2466Z" fill="#6A7988" />
                    <path d="M4.09364 14H14.9686C15.2853 14 15.5622 14.2686 15.5622 14.5752C15.5622 14.8818 15.2853 15.1505 14.9686 15.1505H4.09364C3.77722 15.1505 3.5 14.8821 3.5 14.5752C3.5 14.2686 3.77693 14 4.09364 14Z" fill="#6A7988" />
                    <path d="M4.09364 10.8495H14.9686C15.2853 10.8495 15.5622 11.1181 15.5622 11.4248C15.5622 11.7314 15.2853 12 14.9686 12H4.09364C3.77722 12 3.5 11.7316 3.5 11.4248C3.5 11.1181 3.77693 10.8495 4.09364 10.8495Z" fill="#6A7988" />
                  </svg>
                </span>
                Subscriptions
              </Link>
            </li> */}

            <li>
              <Link to="/master-user/list" className={headerText === "Master Users" && "active"} onClick={handleTabClick}>
                <span className='icon-menu'>
                  <svg width="19" height="25" viewBox="0 0 19 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18.9999 6.32871V24.4248C18.9999 24.6549 18.8812 24.8467 18.6438 24.9617C18.5645 25 18.4855 25 18.4063 25C18.2481 25 18.1291 24.9617 18.0104 24.8467L16.6251 23.6577L15.2395 24.8467C15.0021 25.0385 14.6854 25.0385 14.4479 24.8467L13.0624 23.6577L11.6768 24.8467C11.4394 25.0385 11.1227 25.0385 10.8852 24.8467L9.49997 23.6577L8.11442 24.8467C7.87696 25.0385 7.56027 25.0385 7.32282 24.8467L5.93755 23.6577L4.552 24.8467C4.31455 25.0385 3.99785 25.0385 3.76041 24.8467L2.37486 23.6577L0.989303 24.8467C0.831091 25 0.553875 25.0385 0.355909 24.9235C0.118728 24.8467 0 24.6549 0 24.4248V0.575237C0 0.268351 0.277217 0 0.593635 0H12.4686H12.5873C12.6268 0 12.6268 -2.64496e-07 12.6666 0.0382596H12.706C12.7455 0.0382596 12.7455 0.0765197 12.7853 0.0765197H12.8248C12.8643 0.11478 12.904 0.114779 12.904 0.153308L15.8728 3.03004C17.5747 4.67923 18.3268 5.40808 18.6833 5.79171C18.8415 5.8685 18.9605 6.02181 19 6.21364C18.9997 6.2519 18.9999 6.29045 18.9999 6.32871ZM13.0625 1.95607V5.75343H16.9813C15.8729 4.67945 14.1707 3.02998 13.0625 1.95607ZM17.8127 23.159V6.90405H12.4688C12.1521 6.90405 11.8752 6.63543 11.8752 6.32882V1.15056H1.18769V23.159L1.97929 22.4687C2.09801 22.3536 2.21674 22.3154 2.37523 22.3154C2.53344 22.3154 2.65245 22.3536 2.77118 22.4687L4.15673 23.6577L5.54228 22.4687C5.77974 22.2769 6.09643 22.2769 6.33387 22.4687L7.71943 23.6577L9.10498 22.4687C9.34244 22.2769 9.65913 22.2769 9.89657 22.4687L11.2821 23.6577L12.6677 22.4687C12.9051 22.2769 13.2218 22.2769 13.4593 22.4687L14.8448 23.6577L16.2304 22.4687C16.4678 22.2769 16.7845 22.2769 17.022 22.4687L17.8127 23.159ZM14.9688 17.2466H4.09382C3.77712 17.2466 3.50019 17.5152 3.50019 17.8218C3.50019 18.1287 3.7774 18.397 4.09382 18.397H14.9688C15.2855 18.397 15.5624 18.1284 15.5624 17.8218C15.5624 17.5152 15.2855 17.2466 14.9688 17.2466Z" fill="#6A7988" />
                    <path d="M4.09364 14H14.9686C15.2853 14 15.5622 14.2686 15.5622 14.5752C15.5622 14.8818 15.2853 15.1505 14.9686 15.1505H4.09364C3.77722 15.1505 3.5 14.8821 3.5 14.5752C3.5 14.2686 3.77693 14 4.09364 14Z" fill="#6A7988" />
                    <path d="M4.09364 10.8495H14.9686C15.2853 10.8495 15.5622 11.1181 15.5622 11.4248C15.5622 11.7314 15.2853 12 14.9686 12H4.09364C3.77722 12 3.5 11.7316 3.5 11.4248C3.5 11.1181 3.77693 10.8495 4.09364 10.8495Z" fill="#6A7988" />
                  </svg>
                </span>
                Master Users
              </Link>
            </li>

            {/* <li>
              <Link to="/master-user/payments" className={headerText === "Payments" && "active"} onClick={handleTabClick}>
                <span className='icon-menu'>
                  <svg width="19" height="25" viewBox="0 0 19 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18.9999 6.32871V24.4248C18.9999 24.6549 18.8812 24.8467 18.6438 24.9617C18.5645 25 18.4855 25 18.4063 25C18.2481 25 18.1291 24.9617 18.0104 24.8467L16.6251 23.6577L15.2395 24.8467C15.0021 25.0385 14.6854 25.0385 14.4479 24.8467L13.0624 23.6577L11.6768 24.8467C11.4394 25.0385 11.1227 25.0385 10.8852 24.8467L9.49997 23.6577L8.11442 24.8467C7.87696 25.0385 7.56027 25.0385 7.32282 24.8467L5.93755 23.6577L4.552 24.8467C4.31455 25.0385 3.99785 25.0385 3.76041 24.8467L2.37486 23.6577L0.989303 24.8467C0.831091 25 0.553875 25.0385 0.355909 24.9235C0.118728 24.8467 0 24.6549 0 24.4248V0.575237C0 0.268351 0.277217 0 0.593635 0H12.4686H12.5873C12.6268 0 12.6268 -2.64496e-07 12.6666 0.0382596H12.706C12.7455 0.0382596 12.7455 0.0765197 12.7853 0.0765197H12.8248C12.8643 0.11478 12.904 0.114779 12.904 0.153308L15.8728 3.03004C17.5747 4.67923 18.3268 5.40808 18.6833 5.79171C18.8415 5.8685 18.9605 6.02181 19 6.21364C18.9997 6.2519 18.9999 6.29045 18.9999 6.32871ZM13.0625 1.95607V5.75343H16.9813C15.8729 4.67945 14.1707 3.02998 13.0625 1.95607ZM17.8127 23.159V6.90405H12.4688C12.1521 6.90405 11.8752 6.63543 11.8752 6.32882V1.15056H1.18769V23.159L1.97929 22.4687C2.09801 22.3536 2.21674 22.3154 2.37523 22.3154C2.53344 22.3154 2.65245 22.3536 2.77118 22.4687L4.15673 23.6577L5.54228 22.4687C5.77974 22.2769 6.09643 22.2769 6.33387 22.4687L7.71943 23.6577L9.10498 22.4687C9.34244 22.2769 9.65913 22.2769 9.89657 22.4687L11.2821 23.6577L12.6677 22.4687C12.9051 22.2769 13.2218 22.2769 13.4593 22.4687L14.8448 23.6577L16.2304 22.4687C16.4678 22.2769 16.7845 22.2769 17.022 22.4687L17.8127 23.159ZM14.9688 17.2466H4.09382C3.77712 17.2466 3.50019 17.5152 3.50019 17.8218C3.50019 18.1287 3.7774 18.397 4.09382 18.397H14.9688C15.2855 18.397 15.5624 18.1284 15.5624 17.8218C15.5624 17.5152 15.2855 17.2466 14.9688 17.2466Z" fill="#6A7988" />
                    <path d="M4.09364 14H14.9686C15.2853 14 15.5622 14.2686 15.5622 14.5752C15.5622 14.8818 15.2853 15.1505 14.9686 15.1505H4.09364C3.77722 15.1505 3.5 14.8821 3.5 14.5752C3.5 14.2686 3.77693 14 4.09364 14Z" fill="#6A7988" />
                    <path d="M4.09364 10.8495H14.9686C15.2853 10.8495 15.5622 11.1181 15.5622 11.4248C15.5622 11.7314 15.2853 12 14.9686 12H4.09364C3.77722 12 3.5 11.7316 3.5 11.4248C3.5 11.1181 3.77693 10.8495 4.09364 10.8495Z" fill="#6A7988" />
                  </svg>
                </span>
                Payments
              </Link>
            </li> */}

          </ul>
        </div>

        <div className='main-menu-bottom'>
          <ul>

          </ul>
        </div>
      </div>
    </div>
  )
}

export default MasterSidebar;