import "./expenseOverviewPiechart.css";
import ReactApexChart from "react-apexcharts";
import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';

function ConvertHelper(currencyFrom, currencyTo, amount, forex) {

  const currencyFromRate = forex[currencyFrom];
  const currencyToRate = forex[currencyTo];
  const amountConvertedUSD = amount / currencyFromRate;
  const amountConvertTo = amountConvertedUSD * currencyToRate;
  return amountConvertTo;

}

const ExpenseOverviewPieChart = (props) => {

  const [expenseCategories, setExpenseCategories] = useState([]);
  const [totalAmount, setTotalAmount] = useState([]);
  const { forex } = useSelector((state) => state.forex);
  const settings = JSON.parse(localStorage.getItem("settings"));

  // function decimalToTime(decimalTime) {
  //   // Calculate hours, minutes, and seconds
  //   const hours = Math.floor(decimalTime);
  //   const remainingMinutes = (decimalTime - hours) * 60;
  //   const minutes = Math.floor(remainingMinutes);
  //   const seconds = Math.round((remainingMinutes - minutes) * 60);

  //   // Format the result
  //   const formattedHours = String(hours).padStart(2, '0');
  //   const formattedMinutes = String(minutes).padStart(2, '0');
  //   const formattedSeconds = String(seconds).padStart(2, '0');

  //   return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  // }

  useEffect(() => {
    const piChart = () => {
      let expenses = props.allCurrency
        ? props.expenseData
        : props.expenseData.filter(item => item.exp_currency === props.selectedCurrency);

      let result = [];
      let sum = 0;

      expenses.forEach((entry) => {
        const convertedAmount = ConvertHelper(entry.exp_currency, props.selectedCurrency || settings.currency, entry.exp_amount, forex);
        sum += convertedAmount;

        const existingEntry = result.find((item) => item.name === entry.exp_category?.exp_category_name);
        if (existingEntry) {
          existingEntry.expenseAmount += convertedAmount;
        } else {
          result.push({ name: entry.exp_category?.exp_category_name, expenseAmount: convertedAmount });
        }
      });

      const updatedData = result.map(item => {
        return { name: item.name, expenseAmount: item.expenseAmount };
      });

      setExpenseCategories(updatedData);
      setTotalAmount(sum);
    };

    piChart();
  }, [props.allCurrency, props.expenseData, props.selectedCurrency, forex, settings.currency]);

  // Move these inside the useEffect so they update when expenseCategories change
  const series = expenseCategories.map((expAmt) => expAmt.expenseAmount);
  const expCategoryNames = expenseCategories.map((expAmt) => expAmt.name);

  const options = {
    chart: {
      width: 188,
      height: 188,
      type: "donut",
    },
    plotOptions: {
      pie: {
        startAngle: -45,
        endAngle: 315,
        expandOnClick: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: "gradient",
    },
    legend: {
      show: false,
    },
    labels: expCategoryNames,
    tooltip: {
      enabled: true,
      y: {
        formatter: function (val) {
          return val + "%";
        },
      },
      custom: function ({ seriesIndex }) {
        const expenceCat = expCategoryNames[seriesIndex];
        const expenseAmount = expenseCategories[seriesIndex]?.expenseAmount || 0;
        const totalAmts = expenseCategories.reduce(
          (total, project) => total + project.expenseAmount,
          0
        );
        const percentage = ((expenseAmount / totalAmts) * 100).toFixed(2);

        return `<div class="apexcharts-tooltip-custom" style="background: #FFFFFF; box-shadow: 0px 1px 8px rgba(110, 110, 110, 0.1); border-radius: 0px;height: 44pxxpx;padding: 5px 10px;">
                  <span class="apexcharts-tooltip-marker"></span>
                  <span class="apexcharts-tooltip-text" style="font-weight: 300; font-size: 11px; line-height: 13.42px; letter-spacing: 0.5px; color: #282828;">${expenceCat}</span>
                  <span class="apexcharts-tooltip-value" style="font-weight: 300; font-size: 11px; line-height: 13.42px; letter-spacing: 0.5px; color: #282828;">${new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: props.selectedCurrency || settings.currency
        }).format(expenseAmount)} - ${percentage}%</span>
                </div>`;
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    colors: [
      "#8AA9D2", "#234266", "#6D98CF", "#4263A5", "#6BA9E2", "#9ACBFB", "#75AECF", "#3f4b57",
      "#6A9DCB", "#A4C4E6", "#9AC9F3", "#000", "#89A7C4", "#8EA9C6", "#BBCFE7", "#B7D4F0",
      "#A6C0D6", "#4B6F98", "#A2B5CB", "#86A0BC"
    ], // Set the bar colors
    states: {
      active: {
        filter: {
          type: "none", // No visual change for active state
        },
      },
      normal: {
        filter: {
          type: "none", // No visual change for normal state
        },
      },
    },
  };
  const allNegative = series.every(value => value <= 0);
  const [showMessage, setShowMessage] = useState(false);
  useEffect(() => {
    if (allNegative) {
      // Set a delay of 2 seconds before showing the message
      const timer = setTimeout(() => {
        setShowMessage(true);
      }, 2000);

      // Clear the timer if component unmounts
      return () => clearTimeout(timer);
    }
  }, [allNegative]);
  return (
    <div className="pie-outer">
      <div className="pie-chart-top">
        <div className="top-graph">
          <div className="top-inner-graph">
            <div className="graph-title">Per Category</div>
            {/* {props.allCurrency === false ? (
              <div className="icon-graph">{new Intl.NumberFormat('en-US', { style: 'currency', currency: props.selectedCurrency }).format(totalAmount)} </div>
            ) : */}
            <div className="icon-graph">{new Intl.NumberFormat('en-US', { style: 'currency', currency: props.selectedCurrency ? props.selectedCurrency : settings.currency||"USD" }).format(totalAmount)} </div>
            {/* } */}
          </div>
        </div>
      </div>
      <div className="pie-chart-inner">
      {allNegative && showMessage ? (
          <p className='no-data-text'>No data to display</p>

        ) : (
        <ReactApexChart
          options={options}
          series={series}
          type="donut"
          width={290}
        />
        )}
      </div>
    </div>
  );
};

export default ExpenseOverviewPieChart;