import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

var forex = null;
if(localStorage.getItem("forex")!=='undefined'){

   forex = JSON.parse(localStorage.getItem("forex"));

}


const initialState = {
  forex: forex ? forex : null,
  fxAllCurrency: null
};

export const setForex = createAsyncThunk(
  "forex/set",
  async (forex) => {

    //localStorage.setItem('headerTextLocal',JSON.stringify(headerText));
    const response = await axios.post('/api/forex/get', { base_currency: "USD" }, {
      headers: {
      },
    });

    if (response.status === 200) {
      const forex = response.data ? response.data.json : null;
      localStorage.setItem('forex', JSON.stringify(forex));
      return forex;
    } else {
      console.error(`Request failed with status code: ${response.status}`);
      // handle error
    }



  }
);

export const setAllCurrencies = createAsyncThunk(
  "forex/allcurrency/set",
  async (currency) => {
    //localStorage.setItem('headerTextLocal',JSON.stringify(headerText))
    return currency;
  }
);


export const forexSlice = createSlice({
  name: "forex",
  initialState,
  reducers: {
    reset: (state) => {
      state.forex = null;
      state.fxAllCurrency = null;
    },
  },
  extraReducers: (builder) => {

    builder.addCase(setForex.fulfilled, (state, action) => {
      state.forex = action.payload;
    });
    builder.addCase(setAllCurrencies.fulfilled, (state, action) => {
      state.fxAllCurrency = action.payload;
    });

  },
});

export const { reset } = forexSlice.actions;
export default forexSlice.reducer;