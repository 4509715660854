import { useQuery } from 'react-query';
import axios from 'axios';

const fetchActivityLog = async (token) => {
  const response = await axios.get('/api/activity-log', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};
export const useActivityLog = (token) => {
  return useQuery('activityLog', () => fetchActivityLog(token));
};