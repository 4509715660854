import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  userid: null,
  userRolename: null,
};

export const setUserid = createAsyncThunk(
  "invoice/setUserid",
  async (userId) => {
    return userId;
  }
);

export const setUserRoleName = createAsyncThunk(
  "invoice/setUserRolename",
  async (userRoleName) => {
    return userRoleName;
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    reset: (state) => {
      state.userid = null;
      state.userRolename = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setUserid.fulfilled, (state, action) => {
        state.userid = action.payload;
      })
      .addCase(setUserRoleName.fulfilled, (state, action) => {
        state.userRolename = action.payload;
      });
  },
});

export const { reset } = userSlice.actions;
export default userSlice.reducer;