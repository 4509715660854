
import { convertCurrencyHelper } from '../../../../../helpers/forexConvertor';
import moment from "moment";

export function calculateTotalReimbursement(reimbursementInv, reportingReimbursment, preferredCurrency, forex) {
    const timeZone = global.config.getTimeZone();
    const firstDayOfYear = moment.tz(timeZone).startOf("year").toDate();
    let totalReimbursedAmount = 0;

    reimbursementInv.forEach(invoice => {
        const dateRange = reportingReimbursment.reimbursmentDateRange || [firstDayOfYear, new Date()];
        const invoiceIssueDate = moment.tz(invoice?.inv_issue_date, timeZone);
        const invStatus = invoice?.inv_status || 0;
        const invPaymentStatus = invoice?.inv_payment_status || 0;
        const userId = invoice.inv_for._id;
        const startDate = moment.tz(dateRange[0], timeZone);
        const endDate = moment.tz(dateRange[1], timeZone);
        if (
            !invoice.is_delete &&
            userId &&
            (reportingReimbursment.reimbursmentUser && reportingReimbursment.reimbursmentUser.includes(userId)) &&
            (reportingReimbursment.reimbursmentInvStatus && reportingReimbursment.reimbursmentInvStatus.includes(invStatus)) &&
            (reportingReimbursment.reimbursmentInvPaymentStatus && reportingReimbursment.reimbursmentInvPaymentStatus.includes(invPaymentStatus)) &&
            invoiceIssueDate.isBetween(startDate, endDate, null, '[]')
        ) {
            const invoiceCurrency = invoice.inv_currency || 'USD';
            totalReimbursedAmount += convertCurrencyHelper(invoiceCurrency, preferredCurrency, invoice.inv_total_amount, forex);
        }
    });

    return parseFloat(totalReimbursedAmount).toFixed(2);
};

export function calculateOutstandingReimbursement(reimbursementInv, reportingReimbursment, preferredCurrency, forex) {
    const timeZone = global.config.getTimeZone();
    const firstDayOfYear = moment.tz(timeZone).startOf("year").toDate();
    let totalOutstandingAmount = 0;

    reimbursementInv.forEach(invoice => {
        const dateRange = reportingReimbursment.reimbursmentDateRange || [firstDayOfYear, new Date()];
        const invoiceIssueDate = moment.tz(invoice?.inv_issue_date, timeZone);
        const invStatus = invoice?.inv_status || 0;
        const invPaymentStatus = invoice?.inv_payment_status || 0;
        const userId = invoice.inv_for._id;
        const startDate = moment.tz(dateRange[0], timeZone);
        const endDate = moment.tz(dateRange[1], timeZone);

        if (
            !invoice.is_delete &&
            userId &&
            (reportingReimbursment.reimbursmentUser && reportingReimbursment.reimbursmentUser.includes(userId)) &&
            (reportingReimbursment.reimbursmentInvStatus && reportingReimbursment.reimbursmentInvStatus.includes(invStatus)) &&
            (reportingReimbursment.reimbursmentInvPaymentStatus && reportingReimbursment.reimbursmentInvPaymentStatus.includes(invPaymentStatus)) &&
            invoiceIssueDate.isBetween(startDate, endDate, null, '[]') &&
            ![1, 2, 3].includes(invPaymentStatus)
        ) {
            const invoiceCurrency = invoice.inv_currency || 'USD';
            totalOutstandingAmount += convertCurrencyHelper(invoiceCurrency, preferredCurrency, invoice.inv_total_amount, forex);
        }
    });

    return parseFloat(totalOutstandingAmount).toFixed(2);
};

export function calculateOverdueReimbursement(reimbursementInv, reportingReimbursment, preferredCurrency, forex) {
    const timeZone = global.config.getTimeZone();
    const today = moment.tz(timeZone).startOf('day');
    const firstDayOfYear = moment.tz(timeZone).startOf("year").toDate();
    let totalOverdueAmount = 0;

    reimbursementInv.forEach(invoice => {
        const dateRange = reportingReimbursment.reimbursmentDateRange || [firstDayOfYear, new Date()];
        const invoiceIssueDate = moment.tz(invoice?.inv_issue_date, timeZone);
        const invoiceDueDate = moment.tz(invoice?.inv_due_date, timeZone);
        const invStatus = invoice?.inv_status || 0;
        const invPaymentStatus = invoice?.inv_payment_status || 0;
        const userId = invoice.inv_for._id;
        const startDate = moment.tz(dateRange[0], timeZone);
        const endDate = moment.tz(dateRange[1], timeZone);

        if (
            !invoice.is_delete &&
            userId &&
            (reportingReimbursment.reimbursmentUser && reportingReimbursment.reimbursmentUser.includes(userId)) &&
            (reportingReimbursment.reimbursmentInvStatus && reportingReimbursment.reimbursmentInvStatus.includes(invStatus)) &&
            (reportingReimbursment.reimbursmentInvPaymentStatus && reportingReimbursment.reimbursmentInvPaymentStatus.includes(invPaymentStatus)) &&
            invoiceIssueDate.isBetween(startDate, endDate, null, '[]') &&
            invoiceDueDate.isBefore(today) &&
            ![1, 2, 3].includes(invPaymentStatus)
        ) {
            const invoiceCurrency = invoice.inv_currency || 'USD';
            totalOverdueAmount += convertCurrencyHelper(invoiceCurrency, preferredCurrency, invoice.inv_total_amount, forex);
        }
    });

    return parseFloat(totalOverdueAmount).toFixed(2);
}

export function rebInvCreateDateConvert(rowData) {
    const [isoDate, desiredFormat] = rowData.inv_created_at.split("/");
    const dateObj = new Date(isoDate);
    const options = { month: "long", day: "numeric", year: "numeric" };
    return dateObj.toLocaleDateString("en-US", options);
}

export function rebInvDueDateConvert(rowData) {
    const [isoDate, desiredFormat] = rowData.inv_due_date.split("/");
    const dateObj = new Date(isoDate);
    const options = { month: "long", day: "numeric", year: "numeric" };
    return dateObj.toLocaleDateString("en-US", options);
}