import css from "./MasterUser.css";
import Modal from "react-modal";
import axios from "axios";
import * as XLSX from "xlsx";
import { toast } from "react-toastify";
import { Column } from "primereact/column";
import { ThreeDots } from "react-loader-spinner";
import { DataTable } from "primereact/datatable";
import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import isEqual from "lodash/isEqual";
import addicon from "../../../images/icons/addicon.svg";
import InviteIcon from "../../../images/icons/invite.svg";
import { useSelector, useDispatch } from "react-redux";
import UserIcon from "../../../images/icons/user-icon.svg";
import { RenderMasterUserCTA } from "../../../helpers/invoiceHelpers";
import profile_image from "../../../images/user/Default_pfp.png";
import dletImage from "../../../images/singleinvoice/delete.svg";
import crssrImage from "../../../images/singleinvoice/cross-red.svg";
import Plus from "../../../images/icons/plus-black.svg";
import { setHeaderText } from "../../../features/headerText/headerTextSlice";
import { setNotification } from "../../../features/notification/notificationSlice.js";
import userCategory from "../../../constants/userCategory";
import userTypeName from "../../../constants/userType";
import DownloadImage from "../../../images/icons/download-icon.svg";
import { motion } from "framer-motion";
import TruncateFilterFieldView from "../../../components/helpers/truncateFilterFieldView.js";
import { useMasterUserProfiles, useMasterUserType } from "./masterUserProfileQueries.jsx";
import { dateMasterCreateConvert } from "../../../helpers/dateHelper.js";

const useRenderMemberName = (rowData) => {
  const [showFullname, setShowFullname] = useState(false);

  const fullName =
    rowData.first_name ? `${rowData.first_name} ${rowData.last_name}`
      : rowData.company_first_name
        ? `${rowData.company_first_name} ${rowData.company_last_name ? rowData.company_last_name : ""
        }`
        : `${rowData.company_name} (Company)`

  const displayName = showFullname
    ? fullName
    : fullName && fullName.length <= 21
      ? fullName
      : fullName && fullName.slice(0, 21) + "...";

  return (
    <div
      className={`member-name${showFullname ? " active" : ""}`}
      // onMouseEnter={toggleShowFullname}
      onMouseEnter={() => {
        if (fullName.length > 21) {
          setShowFullname(true);
        }
      }}
      onMouseLeave={() => setShowFullname(false)}
    >
      {rowData.profile_image !== null && rowData.profile_image !== "null" ? (
        <img
          src={`/uploads/lowProfileImage/${rowData.profile_image}`}
          alt={fullName}
          className="member-image"
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = "/uploads/lowProfileImage/user_default.png";
          }}
        />
      ) : (
        <img src={profile_image} alt={fullName} className="member-image" />
      )}
      <span>{displayName}</span>
    </div>
  );
};

const MasterUsers = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [filter, setFilter] = useState("");
  const [users, setUsers] = useState([]);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [emails, setEmails] = useState([]);
  const [userType, setUserType] = useState("");
  const roleDropdownRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMailLoading, setIsMailLoading] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [roleDropdownOpen, setRoleDropdownOpen] = useState(false);
  const userTypeDropdownRef = useRef(null);
  const [isResendModalOpen, setIsResendModalOpen] = useState(false);
  const [statusDropdownOpen, setStatusDropdownOpen] = useState(false);
  const [userFilter, setUserFilter] = useState("All");
  const [selectAllTeam, setSelectAllTeam] = useState(true);
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [isTeamDropdownOpen, setIsTeamDropdownOpen] = useState(false);
  const [isTeamModalDropdownOpen, setIsTeamModalDropdownOpen] = useState(false);
  const [accountTypeFilter, setAccountTypeFilter] = useState("All");
  const [selectAllAccountType, setSelectAllAccountType] = useState(true);
  const [selectedAccountType, setSelectedAccountType] = useState([]);
  const [isAccountTypeDropdownOpen, setIsAccountTypeDropdownOpen] =
    useState(false);
  const [isAccountTypeModalDropdownOpen, setIsAccountTypeModalDropdownOpen] =
    useState(false);
  const [statusModalDropdownOpen, setStatusModalDropdownOpen] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [userTypeDropdownOpen, setUserTypeDropdownOpen] = useState(false);
  const [alreadySentEmailsList, setAlreadySentEmailsList] = useState([]);
  const teamDropdownRef = useRef(null);
  const teamModalDropdownRef = useRef(null);
  const accountTypeDropdownRef = useRef(null);
  const accountTypeModalDropdownRef = useRef(null);
  const statusDropdownRef = useRef(null);
  const statusModalDropdownRef = useRef(null);

  let token = null;
  const { masterUser } = useSelector((state) => state.masterAuth);
  const { appSettings } = useSelector((state) => state.appSettings);
  if (masterUser) {
    if (masterUser && masterUser.token) {
      token = masterUser.token;
    }
  }

  useEffect(() => {
    dispatch(setHeaderText("Master Users"));
  }, []);
  const { data: userProfiles = [], refetch: refetchUserProfiles, isLoading, isError, } = useMasterUserProfiles(token);
  const { data: roleId = [] } = useMasterUserType(token);
  // roleId = roleId.filter(
  //   (role) => !(user.userType === 'ADMIN' && role._id === userTypeName.Super_Admin)
  // );

  useEffect(() => {
    if (userProfiles) {
      // setUsers(prevUsers => [...prevUsers, ...userProfiles]);
      setUsers(userProfiles);
    }
  }, [userProfiles]);

  const renderUserStatus = (rowData) => {
    // Determine the class based on the payment_ssdstatus value
    let statusClass;
    let statusName;
    if (rowData.is_active === true) {
      statusClass = "active";
      statusName = "Active";
    } else if (rowData.is_active === false) {
      statusClass = "inactive";
      statusName = "Inactive";
    }
    return (
      <span className={`payment-status ${statusClass}`}>{statusName}</span>
    );
  };
  const [emailValidation, setEmailValodation] = useState(false);
  const [selectValidation, setSelectValodation] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("emails", emails && emails.length);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmails = emails.every(
      (obj) => !obj.email || emailRegex.test(obj.email)
    );
    if (email === "") {
      setEmailValodation(true);
    } else {
      setEmailValodation(false);
    }
    if (userType === "") {
      setSelectValodation(true);
    } else {
      setSelectValodation(false);
    }
    if (!isValidEmails) {
      toast.error("Please enter valid email addresses!!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      handleCloseModal();
      return;
    }
    var allEmails = emails;
    if (email !== "") {
      allEmails = [
        ...emails,
        { email: email, id: Date.now(), userType: userType },
      ]; // combine all emails including the last added one
    }
    // Validate email and userType fields
    if (!email || !userType) {
      toast.error("Email and user type cannot be empty!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    if (allEmails.some((obj) => !obj.email || !obj.userType)) {
      toast.error("Email and user type cannot be empty!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    try {
      setIsMailLoading(true);

      const response = await fetch("/api/master/user-invite", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          emails: allEmails.map((obj) => ({
            email: obj.email,
            userType: obj.userType,
          })),
          invite_status: "0",
        }),
      });

      const data = await response.json();
      setEmails([]);
      setEmail("");
      setIsModalOpen(false);
      if (response.ok) {
        if (
          data.alreadySentEmails.length > 0 ||
          data.alreadyRegisteredEmails.length > 0
        ) {
          const alreadySentEmails = data.alreadySentEmails.join(", ");
          const alreadyRegisteredEmails =
            data.alreadyRegisteredEmails.join(", ");
          const successSentEmails = data.successSentEmails.join(", ");

          if (alreadySentEmails) {
            setAlreadySentEmailsList(data.alreadySentEmails);
            const sentEmailsList = data.alreadySentEmails
              .map((entry) => entry.email)
              .join(", ");
            handleOpenResendModal();
            toast.success(`Invitation already sent to ${sentEmailsList}`, {
              position: "bottom-right",
              autoClose: 1500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }

          if (alreadyRegisteredEmails) {
            toast.warning(
              `User with email ${alreadyRegisteredEmails} is already registered`,
              {
                position: "bottom-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              }
            );
          }

          if (successSentEmails) {
            dispatch(setNotification(Date.now()));
            toast.success(
              `Invitation successfully sent to ${successSentEmails}`,
              {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              }
            );
            global.config.activityLog(
              window.location.href,
              "User Invite",
              `Invitation successfully sent to ${successSentEmails}`
            );
          }
        } else {
          const successSentEmails = data.successSentEmails.join(", ");
          dispatch(setNotification(Date.now()));
          toast.success("Mail sent successfully!", {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          global.config.activityLog(
            window.location.href,
            "User Invite",
            `Invitation successfully sent to ${successSentEmails}`
          );
        }
      } else {
        toast.error("Failed to send mail.", {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      console.log(error);
      global.config.slackMessage(error.toString());
      alert("Something went wrong");
    } finally {
      setIsMailLoading(false);
      handleCloseModal();
    }
  };

  const handleResendEmails = async () => {
    try {
      setIsMailLoading(true);
      const response = await fetch("/api/master/user/resend-invitations", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          emails: alreadySentEmailsList, // List of emails to resend
        }),
      });

      if (response.ok) {
        // Handle success
        // Close the resend modal
        setIsResendModalOpen(false);
        // Show a success message
        toast.success("Invitations resent successfully!", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        global.config.activityLog(
          window.location.href,
          "User Invite",
          `Invitation successfully resent to ${alreadySentEmailsList}`
        );
      } else {
        // Handle failure
        // Show an error message
        toast.error("Failed to resend invitations.", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.log(error);
      global.config.slackMessage(error.toString());
      // Handle network or server error
      // Show an error message
      toast.error("Failed to resend invitations.", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } finally {
      setIsMailLoading(false);
      handleCloseResendModal();
    }
  };

  const handleAddEmail = (e) => {
    e.preventDefault();
    // if (email !== "") {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmails = emailRegex.test(email);
    if (email && !isValidEmails) {
      toast.error("Please enter valid email addresses!!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    // if (email) {
    const newEmailEntry = { email: email, id: Date.now(), userType: userType };
    setEmails([...emails, newEmailEntry]);
    // }
    setEmail("");
    setUserType("");
    // }
  };

  const handleToggleRoleDropdown = () => {
    setRoleDropdownOpen(!roleDropdownOpen);
  };

  const handleToggleUserTypeDropdown = () => {
    setUserTypeDropdownOpen(!userTypeDropdownOpen);
  };

  const handleUserTypeOptionClick = (selectedValue) => {
    setUserType(selectedValue);
    setSelectValodation(false);
    setUserTypeDropdownOpen(false);
  };

  const handleCheckboxTeamChange = (teamId) => {
    const updatedSelection = [...selectedTeams];
    const index = updatedSelection.indexOf(teamId);

    if (index === -1) {
      // Add to selection
      updatedSelection.push(teamId);
    } else {
      // Remove from selection
      updatedSelection.splice(index, 1);
    }
    setFirst(0);
    setSelectedTeams(updatedSelection);
  };

  const handleSelectAllTeamChange = () => {
    if (!selectAllTeam) {
      setSelectedTeams(userProfiles.map((team) => team._id));
    } else {
      setSelectedTeams([]);
    }
    setSelectAllTeam(!selectAllTeam);
  };

  useEffect(() => {
    setSelectAllTeam(selectedTeams.length === userProfiles.length);
    if (selectedTeams.length === userProfiles.length) {
      setUserFilter("All");
    } else {
      setUserFilter(selectedTeams);
    }
  }, [selectedTeams, userProfiles]);

  const toggleTeamDropdown = () => {
    setIsTeamDropdownOpen(!isTeamDropdownOpen);
  };

  const toggleTeamModalDropdown = () => {
    setIsTeamModalDropdownOpen(!isTeamModalDropdownOpen);
  };

  const toggleAccountTypeDropdown = () => {
    setIsAccountTypeDropdownOpen(!isAccountTypeDropdownOpen);
  };

  const toggleAccountTypeModalDropdown = () => {
    setIsAccountTypeModalDropdownOpen(!isAccountTypeModalDropdownOpen);
  };

  const handleCheckboxAccountTypeChange = (value) => {
    const updatedSelection = [...selectedAccountType];
    const index = updatedSelection.indexOf(value);

    if (index === -1) {
      updatedSelection.push(value);
    } else {
      updatedSelection.splice(index, 1);
    }
    // setCurrentPage(0);
    setSelectedAccountType(updatedSelection);
  };

  const handleSelectAllAccountTypeChange = () => {
    setSelectAllAccountType(!selectAllAccountType);
    if (!selectAllAccountType) {
      setSelectedAccountType(roleId.map((item) => item._id));
    } else {
      setSelectedAccountType([]);
    }
  };

  useEffect(() => {
    setSelectAllAccountType(
      selectedAccountType.length === roleId.length
    );
    if (selectedAccountType.length === roleId.length) {
      setAccountTypeFilter("All");
    } else {
      setAccountTypeFilter(selectedTeams);
    }
  }, [selectedAccountType, roleId]);

  const toggleStatusDropdown = () => {
    setStatusDropdownOpen(!statusDropdownOpen);
  };

  const toggleStatusModalDropdown = () => {
    setStatusModalDropdownOpen(!statusModalDropdownOpen);
  };

  const selectStatusOption = (selectedStatus) => {
    setSelectedStatus(selectedStatus);
    setStatusDropdownOpen(false);
  };

  const applyFilters = () => {
    setShowFilterModal(false);
  };

  const clearFilters = () => {
    setSelectedTeams(userProfiles.map((team) => team._id));
    setSelectedAccountType(roleId.map((type) => type._id));
    setShowFilterModal(false);
  };

  const checkAndShowConfirmationModal = () => {
    const isTeamsModified = !isEqual(
      selectedTeams,
      userProfiles.map((team) => team._id)
    );
    const isAccountTypeModified = !isEqual(
      selectedAccountType,
      roleId.map((type) => type._id)
    );
    if (isTeamsModified || isAccountTypeModified) {
      setShowConfirmationModal(true);
    } else {
      setShowFilterModal(false);
    }
  };

  const handleCancel = () => {
    setShowConfirmationModal(false);
  };

  const handleApplyConfirm = () => {
    setShowConfirmationModal(false);
    setShowFilterModal(false);
  };

  const toggleFilterModal = () => {
    setShowConfirmationModal(false);
    setShowFilterModal(!showFilterModal);
  };

  useEffect(() => {
    setSelectedTeams(userProfiles.map((team) => team._id));
    setSelectedAccountType(roleId.map((type) => type._id));
  }, [userProfiles, roleId]);

  const handleClickOutsideDropdown = (event) => {
    const isClickInsideRoleDropdown =
      roleDropdownRef.current && roleDropdownRef.current.contains(event.target);
    const isClickInsideUserTypeDropdown =
      userTypeDropdownRef.current &&
      userTypeDropdownRef.current.contains(event.target);
    const isClickInsideTeamDropdown =
      teamDropdownRef.current && teamDropdownRef.current.contains(event.target);
    const isClickInsideTeamModalDropdown =
      teamModalDropdownRef.current &&
      teamModalDropdownRef.current.contains(event.target);
    const isClickInsideAccountTypeDropdown =
      accountTypeDropdownRef.current &&
      accountTypeDropdownRef.current.contains(event.target);
    const isClickInsideAccountTypeModalDropdown =
      accountTypeModalDropdownRef.current &&
      accountTypeModalDropdownRef.current.contains(event.target);
    const isClickInsideStatusDropdown =
      statusDropdownRef.current &&
      statusDropdownRef.current.contains(event.target);
    const isClickInsideStatusModalDropdown =
      statusModalDropdownRef.current &&
      statusModalDropdownRef.current.contains(event.target);

    if (!isClickInsideRoleDropdown) {
      setRoleDropdownOpen(false);
    }
    if (!isClickInsideUserTypeDropdown) {
      setUserTypeDropdownOpen(false);
    }
    if (!isClickInsideTeamDropdown) {
      setIsTeamDropdownOpen(false);
    }
    if (!isClickInsideTeamModalDropdown) {
      setIsTeamModalDropdownOpen(false);
    }
    if (!isClickInsideAccountTypeDropdown) {
      setIsAccountTypeDropdownOpen(false);
    }
    if (!isClickInsideAccountTypeModalDropdown) {
      setIsAccountTypeModalDropdownOpen(false);
    }
    if (!isClickInsideStatusDropdown) {
      setStatusDropdownOpen(false);
    }
    if (!isClickInsideStatusModalDropdown) {
      setStatusModalDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutsideDropdown);
    return () => {
      document.removeEventListener("click", handleClickOutsideDropdown);
    };
  }, []);

  const handleEditEmail = (id, field, value) => {
    // Find the index of the email entry with the given id
    const index = emails.findIndex((obj) => obj.id === id);
    if (index !== -1) {
      // Create a copy of the emails array to avoid mutating state directly
      const updatedEmails = [...emails];
      // Update the specified field of the email entry
      updatedEmails[index][field] = value;
      // Update the state with the new array
      setEmails(updatedEmails);
    }
  };

  const handleRemoveEmail = (id) => {
    const filteredEmails = emails.filter((obj) => obj.id !== id);
    setEmails(filteredEmails);
  };

  const handleRemoveLatestEmail = () => {
    const updatedEmails = [...emails];
    const lastEmail = updatedEmails.pop(); // Remove the last element
    setEmails(updatedEmails);

    // Set state variables with the latest values
    setEmail(lastEmail.email);
    setUserType(lastEmail.userType);
  };

  const handleRemoveResendEmail = (emailToRemove) => {
    const updatedEmails = alreadySentEmailsList.filter(
      (email) => email !== emailToRemove
    );
    setAlreadySentEmailsList(updatedEmails);
  };

  const handleOpenResendModal = () => {
    // Set the list of already sent emails here
    setIsResendModalOpen(true);
  };

  const handleCloseResendModal = () => {
    setIsResendModalOpen(false);
    setAlreadySentEmailsList([]);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setEmails([]);
    setEmail("");
    setUserType("");
  };

  const filteredData = users.filter((item) => {
    const searchTerm = filter.trim().toLowerCase();
    const fullName =
      (item.first_name ||
        (item.company_first_name
          ? `${item.company_first_name} ${item.company_last_name ? item.company_last_name : ""
          }`
          : `${item.company_name} (Company)`)) +
      (item.middle_name && item.middle_name !== "null"
        ? " " + item.middle_name
        : "") +
      " " +
      item.last_name;

    const hasMatch =
      (fullName?.toLowerCase().includes(searchTerm) ||
        item.email?.toLowerCase().includes(searchTerm)) &&
      (selectedStatus === "Active" ? item.is_active : true) &&
      (selectedStatus === "Inactive" ? !item.is_active : true);

    const isUserFilterMatched = userFilter === "All" || selectedTeams.includes(item._id);
    const isAccountTypeFilterMatched = accountTypeFilter === "All" || selectedAccountType.includes(item?.role_id?._id);
    return (
      hasMatch &&
      isUserFilterMatched &&
      isAccountTypeFilterMatched
    );
  });

  const componentRef = useRef(null);
  const fadeIn = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };

  return (
    <motion.div
      ref={componentRef}
      initial="hidden"
      animate="visible"
      variants={fadeIn}
      transition={{ duration: 0.3 }}
    >
      <div className="user-outer-overview invoice-outer-invoice">
        <div className="invoice-top">
          <div className="new-top-flter">
            <div className="button-sec-invoice">
              <Link to="/master-user/register">
                <span className="icon-invoice">
                  <img
                    src={UserIcon}
                    alt="Create Invoice"
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = "/uploads/lowProfileImage/user_default.png";
                    }}
                  />
                </span>
                New User
              </Link>
              <a className="btn-forpop-initem" onClick={handleOpenModal}>
                <span className="icon-invoice">
                  <img src={InviteIcon} alt="Export" />
                </span>
                Invite Users
              </a>

              <Modal
                isOpen={isModalOpen}
                onRequestClose={!isMailLoading ? handleCloseModal : undefined}
                contentLabel="Invite a User"
                className="user-invite-modal-main-outer"
                style={{
                  content: {
                    height: "41%",
                    width: "30%",
                    position: "fixed",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-25%, -50%)",
                  },
                }}
              >
                <div className="modal">
                  <div className="modal-top-section-user-invite">
                    <div className="modal-invite-head">
                      <h2 className="">Invite a new user</h2>
                    </div>
                    <div className="modal-close-invite outter-modal-main outter-modal-main-user">
                      <a
                        className=""
                        onClick={handleCloseModal}
                        disabled={isMailLoading}
                      >
                        <img src={crssrImage} alt="Edit Icon" />{" "}
                      </a>
                    </div>
                  </div>

                  <form onSubmit={handleSubmit}>
                    <div>
                      {emails.map((obj) => (
                        <div key={obj.id} className="the-modal-inuser">
                          <div className="user-add-outer-innner">
                            <div className="invite-user">
                              <label htmlFor="grid-password" className="">
                                Email Address
                              </label>
                              <input
                                type="email"
                                id={`email-${obj.id}`}
                                className=""
                                name="email"
                                required
                                placeholder="Enter Email ID"
                                value={obj.email}
                                onChange={(e) =>
                                  handleEditEmail(obj.id, "email", e.target.value)
                                }
                              />
                              <label
                                htmlFor="grid-password"
                                className="type-of-acc"
                              >
                                Role
                              </label>

                              <div
                                className={`custom-dropdown-user-new-outer ${roleDropdownOpen ? "open" : ""
                                  }`}
                                ref={roleDropdownRef}
                              >
                                <div
                                  className="selected-option custom-dropdown-user-new"
                                  onClick={handleToggleRoleDropdown}
                                >
                                  {obj.userType
                                    ? roleId.find(
                                      (option) => option._id === obj.userType
                                    )?.user_type
                                    : "Select Role"}
                                </div>
                                {roleDropdownOpen && (
                                  <div className="custom-dropdown-new">
                                    <ul className="role-list">
                                      {roleId.map((option) => (
                                        <li
                                          className={
                                            obj.userType === option._id
                                              ? "selected-item"
                                              : ""
                                          }
                                          key={option._id}
                                          onClick={() => {
                                            handleEditEmail(
                                              obj.id,
                                              "userType",
                                              option._id
                                            );
                                            setRoleDropdownOpen(false);
                                          }}
                                        >
                                          {option.user_type}
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div
                              className="invite-user-delete"
                              onClick={() => handleRemoveEmail(obj.id)}
                            >
                              <img src={dletImage} alt="delete-icon" />{" "}
                            </div>
                          </div>
                        </div>
                      ))}
                      <div className="the-modal-inuser">
                        <div className="user-add-outer-innner">
                          <div className="invite-user">
                            <label htmlFor="grid-password" className="">
                              Email Address
                            </label>
                            <input
                              type="email"
                              name="email"
                              className={emailValidation && "error-input"}
                              placeholder="Enter Email ID"
                              value={email}
                              onChange={(e) => {
                                setEmail(e.target.value);
                                setEmailValodation(false);
                              }}
                            />
                            <label
                              htmlFor="grid-password"
                              className="type-of-acc"
                            >
                              Role
                            </label>

                            <div
                              className={`custom-dropdown-user-new-outer ${userTypeDropdownOpen ? "open" : ""
                                }`}
                              ref={userTypeDropdownRef}
                            >
                              <div
                                className={
                                  selectValidation
                                    ? "selected-option custom-dropdown-user-new error-input"
                                    : "selected-option custom-dropdown-user-new"
                                }
                                onClick={handleToggleUserTypeDropdown}
                              >
                                {userType
                                  ? roleId.find(
                                    (option) => option._id === userType
                                  )?.user_type
                                  : "Select Role"}
                              </div>
                              {userTypeDropdownOpen && (
                                <div className="custom-dropdown-new">
                                  <ul className="role-list">
                                    {roleId.map((option) => (
                                      <li
                                        className={
                                          userType === option._id
                                            ? "selected-item"
                                            : ""
                                        }
                                        key={option._id}
                                        onClick={() =>
                                          handleUserTypeOptionClick(option._id)
                                        }
                                      >
                                        {option.user_type}
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              )}
                            </div>
                          </div>
                          {emails.length > 0 && (
                            <div
                              className="invite-user-delete"
                              onClick={handleRemoveLatestEmail}
                            >
                              <img src={dletImage} alt="delete-icon" />
                            </div>
                          )}
                        </div>
                      </div>
                      <div
                        className="add-email-sec-modal"
                        onClick={handleAddEmail}
                        style={{ cursor: "pointer" }}
                      >
                        <img src={addicon} alt="Edit Icon" />
                        <div className="add-icon-main-text">Add new email</div>
                      </div>
                    </div>
                    <div className="invite-mail-popup-btn">
                      <button type="submit" className="" disabled={isMailLoading}>
                        {isMailLoading ? (
                          <div className="outter-load-table">
                            <ThreeDots
                              height="18"
                              width="20"
                              radius="9"
                              color="white"
                              ariaLabel="three-dots-loading"
                              wrapperStyle={{}}
                              wrapperClassName=""
                              visible={true}
                            />
                          </div>
                        ) : (
                          "Send Invite"
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </Modal>

              <Modal
                isOpen={isResendModalOpen}
                onRequestClose={
                  !isMailLoading ? handleCloseResendModal : undefined
                }
                contentLabel="Resend Invitations"
                style={{
                  content: {
                    height: "41%",
                    width: "30%",
                    position: "fixed",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-25%, -50%)",
                  },
                }}
              >
                <div className="modal">
                  <div className="modal-close-invite outter-modal-main">
                    <a
                      className=""
                      onClick={handleCloseResendModal}
                      disabled={isMailLoading}
                    >
                      <img src={crssrImage} alt="Edit Icon" />{" "}
                    </a>
                  </div>
                  <div className="modal-invite-head">
                    <h2 className="">Resend Invitations</h2>
                  </div>
                  {/* List the already sent emails here */}
                  <div>
                    <div className="the-modal-inuser">
                      <label htmlFor="grid-password" className="">
                        Email
                      </label>
                      {alreadySentEmailsList.map((email) => (
                        <div key={email.email} className="the-modal-inusers">
                          <div className="resend-invitation">
                            <input
                              type="email"
                              className=""
                              name="email"
                              required
                              placeholder="Enter Email ID"
                              value={email.email}
                              disabled
                            />
                            <label
                              htmlFor="grid-password"
                              className="type-of-acc"
                            >
                              Role
                            </label>
                            <select value={email.userType} disabled>
                              <option value="">Select Role</option>
                              {roleId.map((role) => (
                                <option key={role._id} value={role._id}>
                                  {role.user_type}
                                </option>
                              ))}
                            </select>
                            {alreadySentEmailsList.length > 1 && (
                              <button
                                className="del-resend"
                                onClick={() => handleRemoveResendEmail(email)}
                              >
                                <img src={dletImage} alt="delete-icon" />{" "}
                              </button>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="sprint-btn-outer resend-invoice">
                    <button
                      type="button"
                      onClick={handleCloseResendModal}
                      disabled={isMailLoading}
                      className="submit-client cancel-sprint"
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="submit-client save-sprint"
                      disabled={isMailLoading}
                      onClick={handleResendEmails}
                    >
                      {isMailLoading ? (
                        <div className="outter-load-table">
                          <ThreeDots
                            height="18"
                            width="20"
                            radius="9"
                            color="white"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                          />
                        </div>
                      ) : (
                        "Resend Invitations"
                      )}
                    </button>
                  </div>
                </div>
              </Modal>
            </div>
          </div>
        </div>
        <div className="master-dropdown-with-search">
          <div className="invoice-filter">
            <div className="invoice-filter-inner-section no-flter-all">
              <div className="filter-inner invoice-status">
                <label for="user">User Name</label>
                <div className="custom-dropdown1" ref={teamDropdownRef}>
                  <div className="drop-down-inner">
                    <div className="selected-options1" onClick={toggleTeamDropdown} >
                      {selectedTeams.length === 0 ? (
                        <div className="placeholder">Select</div>
                      ) : (
                        <>
                          <div
                            className="selected-option1"
                            key={selectedTeams[0]}
                          >
                            {selectedTeams.length === userProfiles.length ? (
                              `All`
                            ) : (
                              <>
                                <TruncateFilterFieldView
                                  textContent={
                                    userProfiles.find((item) => item._id === selectedTeams[0])?.first_name
                                      ? `${userProfiles.find((item) => item._id === selectedTeams[0]).first_name} ${userProfiles.find((item) => item._id === selectedTeams[0]).middle_name
                                        ? userProfiles.find((item) => item._id === selectedTeams[0]).middle_name : ""} ${userProfiles.find((item) => item._id === selectedTeams[0]).last_name
                                          ? userProfiles.find((item) => item._id === selectedTeams[0]).last_name : ""}`
                                      : userProfiles.find((item) => item._id === selectedTeams[0]).company_first_name ?
                                        `${userProfiles.find((item) => item._id === selectedTeams[0]).company_first_name} ${userProfiles.find((item) => item._id === selectedTeams[0]).company_last_name ?
                                          userProfiles.find((item) => item._id === selectedTeams[0]).company_last_name : ""}`
                                        : `${userProfiles.find((item) => item._id === selectedTeams[0]).company_name} (Company)`
                                  }
                                />
                                {selectedTeams.length > 1 &&
                                  selectedTeams.length !== userProfiles.length && (
                                    <div className="selected-option-count">
                                      {selectedTeams.length - 1}+
                                    </div>
                                  )}
                              </>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                    {isTeamDropdownOpen && (
                      <div className="dropdown-list1">
                        {userProfiles.length > 0 && (
                          <>
                            <label className="checkbox-label1" key="selectAll" >
                              <input type="checkbox" checked={selectAllTeam} onChange={handleSelectAllTeamChange} />
                              All Teams
                            </label>
                            {userProfiles
                              .slice()
                              .sort((a, b) => {
                                // Define the name to sort by (either individual or company)
                                const nameA = a.first_name
                                  ? `${a.first_name} ${a.middle_name || ''} ${a.last_name || ''}`.trim()
                                  : a.company_first_name
                                    ? `${a.company_first_name} ${a.company_last_name || ''}`.trim()
                                    : a.company_name || '';

                                const nameB = b.first_name
                                  ? `${b.first_name} ${b.middle_name || ''} ${b.last_name || ''}`.trim()
                                  : b.company_first_name
                                    ? `${b.company_first_name} ${b.company_last_name || ''}`.trim()
                                    : b.company_name || '';

                                // Sort alphabetically
                                return nameA.localeCompare(nameB);
                              })
                              .map((team) => (
                                <label className="checkbox-label1" key={team._id}>
                                  <input
                                    type="checkbox"
                                    checked={selectedTeams.includes(team._id)}
                                    onChange={() => handleCheckboxTeamChange(team._id)}
                                  />
                                  {team.first_name
                                    ? `${team.first_name} ${team.middle_name ? team.middle_name : ""} ${team.last_name}`
                                    : team.company_first_name
                                      ? `${team.company_first_name} ${team.company_last_name ? team.company_last_name : ""}`
                                      : `${team.company_name} (Company)`}
                                </label>
                              ))}
                          </>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="filter-inner account-type">
                <label htmlFor="accountType">Type of Account</label>
                <div className="custom-dropdown1" ref={accountTypeDropdownRef}>
                  <div className="drop-down-inner">
                    <div className="selected-options1" onClick={toggleAccountTypeDropdown}>
                      {selectedAccountType.length === 0 ? (
                        <div className="placeholder">Select</div>
                      ) : (
                        <>
                          <div className="selected-option1" key={selectedAccountType[0]}>
                            {selectedAccountType.length === roleId.length ? (
                              "All"
                            ) : (
                              <>
                                <TruncateFilterFieldView
                                  textContent={
                                    roleId.find(
                                      (item) => item._id === selectedAccountType[0]
                                    )?.user_type
                                  }
                                />
                                {selectedAccountType.length > 1 &&
                                  selectedAccountType.length !== roleId.length && (
                                    <div className="selected-option-count">
                                      {selectedAccountType.length - 1}+
                                    </div>
                                  )}
                              </>
                            )}
                          </div>
                        </>
                      )}
                    </div>

                    {isAccountTypeDropdownOpen && (
                      <div className="dropdown-list1">
                        {roleId.length > 0 && (
                          <>
                            <label className="checkbox-label1" key="selectAll">
                              <input
                                type="checkbox"
                                checked={selectAllAccountType}
                                onChange={handleSelectAllAccountTypeChange}
                              />
                              All
                            </label>
                            {roleId
                              .sort((a, b) => (a.user_type || '').localeCompare(b.user_type || '')) // Sort by user_type alphabetically
                              .map((accountType) => (
                                <label className="checkbox-label1" key={accountType._id}>
                                  <input
                                    type="checkbox"
                                    checked={selectedAccountType.includes(accountType._id)}
                                    onChange={() => handleCheckboxAccountTypeChange(accountType._id)}
                                  />
                                  {accountType.user_type && `${accountType.user_type}`}
                                </label>
                              ))}
                          </>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="filter-inner invoice-type">
                <label for="invoice-type">Status</label>
                <div className="custom-dropdown1" ref={statusDropdownRef}>
                  <div className="drop-down-inner">
                    <div
                      className="selected-options1"
                      onClick={toggleStatusDropdown}
                    >
                      {selectedStatus ? selectedStatus : 'ALL'}
                    </div>
                    {statusDropdownOpen && (
                      <div className="custom-dropdown-new">
                        <ul className="type-list">
                          <li onClick={() => selectStatusOption(null)}>All</li>
                          <li onClick={() => selectStatusOption('Active')}>Active</li>
                          <li onClick={() => selectStatusOption('Inactive')}>Inactive</li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="filter-invoice-right apply-fliter-right">
              <button onClick={toggleFilterModal}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="13"
                  height="17"
                  viewBox="0 0 13 17"
                  fill="none"
                >
                  <path
                    d="M11.7363 8.81108C11.4276 9.81451 10.4925 10.5444 9.38889 10.5444C8.28471 10.5444 7.35016 9.8141 7.04147 8.81108L0.722221 8.81108C0.323349 8.81108 -1.07845e-06 8.48773 -1.06101e-06 8.08886C-1.04357e-06 7.68998 0.323349 7.36663 0.722221 7.36663L7.04144 7.36663C7.35022 6.3632 8.28529 5.6333 9.38889 5.6333C10.4931 5.6333 11.4276 6.36362 11.7363 7.36663L12.2778 7.36663C12.6767 7.36663 13 7.68998 13 8.08886C13 8.48773 12.6766 8.81108 12.2778 8.81108L11.7363 8.81108ZM9.38889 7.07775C8.83162 7.07775 8.37778 7.53123 8.37778 8.08886C8.37778 8.64613 8.83127 9.09997 9.38889 9.09997C9.94616 9.09997 10.4 8.64648 10.4 8.08886C10.4 7.53158 9.94651 7.07775 9.38889 7.07775Z"
                    fill="#6479F8"
                  />
                  <path
                    d="M5.38078 3.17778C5.072 4.18121 4.13693 4.91111 3.03333 4.91111C1.92882 4.91111 0.994035 4.18035 0.68563 3.17687C0.303759 3.15781 -1.07791e-06 2.84216 -1.06101e-06 2.45556C-1.04411e-06 2.06896 0.303748 1.75331 0.685606 1.73425C0.994098 0.730343 1.9294 -4.83911e-07 3.03333 -4.35657e-07C4.13752 -3.87392e-07 5.07206 0.730315 5.38076 1.73333L12.2778 1.73333C12.6767 1.73333 13 2.05668 13 2.45556C13 2.85443 12.6766 3.17778 12.2778 3.17778L5.38078 3.17778ZM3.03333 1.44444C2.47606 1.44444 2.02222 1.89793 2.02222 2.45556C2.02222 3.01283 2.47571 3.46667 3.03333 3.46667C3.5906 3.46667 4.04444 3.01318 4.04444 2.45556C4.04444 1.89828 3.59096 1.44444 3.03333 1.44444Z"
                    fill="#6479F8"
                  />
                  <path
                    d="M7.403 14.4444C7.09422 15.4478 6.15916 16.1777 5.05556 16.1777C3.95137 16.1777 3.01682 15.4474 2.70813 14.4444L0.722221 14.4444C0.323349 14.4444 -1.07845e-06 14.121 -1.06101e-06 13.7222C-1.04357e-06 13.3233 0.323349 12.9999 0.722221 12.9999L2.70811 12.9999C3.01689 11.9965 3.95195 11.2666 5.05556 11.2666C6.15974 11.2666 7.09429 11.9969 7.40298 12.9999L12.2778 12.9999C12.6767 12.9999 13 13.3233 13 13.7222C13 14.121 12.6766 14.4444 12.2778 14.4444L7.403 14.4444ZM5.05556 12.711C4.49828 12.711 4.04444 13.1645 4.04444 13.7222C4.04444 14.2794 4.49793 14.7333 5.05556 14.7333C5.61283 14.7333 6.06667 14.2798 6.06667 13.7222C6.06667 13.1649 5.61318 12.711 5.05556 12.711Z"
                    fill="#6479F8"
                  />
                </svg>
                All Filters
              </button>
            </div>
            <Modal
              isOpen={showFilterModal}
              onRequestClose={checkAndShowConfirmationModal}
              contentLabel="Filter Modal"
              className="new-filter-modal"
              style={{
                content: {
                  height: "50%",
                  width: "50%",
                  position: "fixed",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-25%, -25%)",
                  borderRadius: "10px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  backgroundColor: "#fff",
                  padding: "20px",
                  // overflow: "auto",
                },
                overlay: {
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                },
              }}
            >
              <div className="new-nodal-invoice-outer-sec">
                <div className="new-modal-top-sec">
                  <div className="new-modal-heading">
                    All filters
                  </div>
                  <a className="close-image-clr1" onClick={checkAndShowConfirmationModal}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                      <path d="M1 13L13 1" stroke="black" />
                      <path d="M1 0.999999L13 13" stroke="black" />
                    </svg>{" "}
                  </a>
                </div>
                <div className="invoice-filter-inner-section">
                  <div className="filter-inner invoice-status">
                    <label for="user">User Name</label>
                    <div className="custom-dropdown1" ref={teamModalDropdownRef}>
                      <div className="drop-down-inner">
                        <div className="selected-options1" onClick={toggleTeamModalDropdown} >
                          {selectedTeams.length === 0 ? (
                            <div className="placeholder">Select</div>
                          ) : (
                            <>
                              <div
                                className="selected-option1"
                                key={selectedTeams[0]}
                              >
                                {selectedTeams.length === userProfiles.length ? (
                                  `All`
                                ) : (
                                  <>
                                    <TruncateFilterFieldView
                                      textContent={
                                        userProfiles.find((item) => item._id === selectedTeams[0])?.first_name
                                          ? `${userProfiles.find((item) => item._id === selectedTeams[0]).first_name} ${userProfiles.find((item) => item._id === selectedTeams[0]).middle_name
                                            ? userProfiles.find((item) => item._id === selectedTeams[0]).middle_name : ""} ${userProfiles.find((item) => item._id === selectedTeams[0]).last_name
                                              ? userProfiles.find((item) => item._id === selectedTeams[0]).last_name : ""}`
                                          : userProfiles.find((item) => item._id === selectedTeams[0]).company_first_name ?
                                            `${userProfiles.find((item) => item._id === selectedTeams[0]).company_first_name} ${userProfiles.find((item) => item._id === selectedTeams[0]).company_last_name ?
                                              userProfiles.find((item) => item._id === selectedTeams[0]).company_last_name : ""}`
                                            : `${userProfiles.find((item) => item._id === selectedTeams[0]).company_name} (Company)`
                                      }
                                    />
                                    {selectedTeams.length > 1 &&
                                      selectedTeams.length !== userProfiles.length && (
                                        <div className="selected-option-count">
                                          {selectedTeams.length - 1}+
                                        </div>
                                      )}
                                  </>
                                )}
                              </div>
                            </>
                          )}
                        </div>
                        {isTeamModalDropdownOpen && (
                          <div className="dropdown-list1">
                            {userProfiles.length > 0 && (
                              <>
                                <label className="checkbox-label1" key="selectAll" >
                                  <input type="checkbox" checked={selectAllTeam} onChange={handleSelectAllTeamChange} />
                                  All Teams
                                </label>
                                {userProfiles
                                  .slice()
                                  .sort((a, b) => {
                                    const nameA = a.first_name
                                      ? `${a.first_name} ${a.middle_name || ''} ${a.last_name || ''}`.trim()
                                      : a.company_first_name
                                        ? `${a.company_first_name} ${a.company_last_name || ''}`.trim()
                                        : a.company_name || '';

                                    const nameB = b.first_name
                                      ? `${b.first_name} ${b.middle_name || ''} ${b.last_name || ''}`.trim()
                                      : b.company_first_name
                                        ? `${b.company_first_name} ${b.company_last_name || ''}`.trim()
                                        : b.company_name || '';

                                    return nameA.localeCompare(nameB);
                                  })
                                  .map((team) => (
                                    <label className="checkbox-label1" key={team._id} >
                                      <input
                                        type="checkbox"
                                        checked={selectedTeams.includes(team._id)}
                                        onChange={() =>
                                          handleCheckboxTeamChange(team._id)
                                        }
                                      />
                                      {team.first_name
                                        ? `${team.first_name} ${team.middle_name ? team.middle_name : ""
                                        } ${team.last_name}`
                                        : team.company_first_name
                                          ? `${team.company_first_name} ${team.company_last_name
                                            ? team.company_last_name
                                            : ""
                                          }`
                                          : `${team.company_name} (Company)`}
                                    </label>
                                  ))}
                              </>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="filter-inner payment-status">
                    <label htmlFor="typeofAccount">Type of Account</label>
                    <div className="custom-dropdown1" ref={accountTypeModalDropdownRef}>
                      <div className="drop-down-inner">
                        <div className="selected-options1" onClick={toggleAccountTypeModalDropdown}>
                          {selectedAccountType.length === 0 ? (
                            <div className="placeholder">Select</div>
                          ) : (
                            <>
                              <div className="selected-option1" key={selectedAccountType[0]}>
                                {selectedAccountType.length === roleId.length ? (
                                  "All"
                                ) : (
                                  <>
                                    <TruncateFilterFieldView
                                      textContent={
                                        roleId.find(
                                          (item) => item._id === selectedAccountType[0]
                                        )?.user_type
                                      }
                                    />
                                    {selectedAccountType.length > 1 &&
                                      selectedAccountType.length !== roleId.length && (
                                        <div className="selected-option-count">
                                          {selectedAccountType.length - 1}+
                                        </div>
                                      )}
                                  </>
                                )}
                              </div>
                            </>
                          )}
                        </div>

                        {isAccountTypeModalDropdownOpen && (
                          <div className="dropdown-list1">
                            {roleId.length > 0 && (
                              <>
                                <label className="checkbox-label1" key="selectAll">
                                  <input
                                    type="checkbox"
                                    checked={selectAllAccountType}
                                    onChange={handleSelectAllAccountTypeChange}
                                  />
                                  All
                                </label>
                                {roleId.map((accountType) => (
                                  <label className="checkbox-label1" key={accountType._id}>
                                    <input
                                      type="checkbox"
                                      checked={selectedAccountType.includes(accountType._id)}
                                      onChange={() =>
                                        handleCheckboxAccountTypeChange(accountType._id)
                                      }
                                    />
                                    {accountType.user_type && `${accountType.user_type}`}
                                  </label>
                                ))}
                              </>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="filter-inner invoice-type">
                    <label for="invoice-type">Status</label>
                    <div className="custom-dropdown1" ref={statusModalDropdownRef}>
                      <div className="drop-down-inner">
                        <div className="selected-options1" onClick={toggleStatusModalDropdown} >
                          {selectedStatus ? selectedStatus : 'ALL'}
                        </div>
                        {statusModalDropdownOpen && (
                          <div className="custom-dropdown-new">
                            <ul className="type-list">
                              <li onClick={() => selectStatusOption(null)}>All</li>
                              <li onClick={() => selectStatusOption('Active')}>Active</li>
                              <li onClick={() => selectStatusOption('Inactive')}>Inactive</li>
                            </ul>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <Modal
                    className="delete-modal"
                    isOpen={showConfirmationModal}
                    onRequestClose={handleCancel}
                    contentLabel="Confirm Delete"
                    style={{
                      content: {
                        height: "41%",
                        width: "30%",
                        position: "fixed",
                        top: "36%",
                        left: "50%",
                        transform: "translate(-24.75%, 0%)",
                      },
                    }}
                  >
                    <div className="delete-modal-content">
                      <h2>Apply or Discard changes?</h2>
                      <p>
                        Do you want to Apply or Discard filter changes?
                      </p>

                      <div className="delete-modal-buttons">
                        <button className="delete" onClick={handleApplyConfirm} >
                          Apply Changes
                        </button>
                        <button onClick={clearFilters}>
                          Discard Changes
                        </button>
                      </div>
                    </div>
                  </Modal>
                </div>
              </div>
              <div className="filter-buttons">
                <button className="clear-all" onClick={clearFilters}>Clear All</button>
                <button className="apply-filter" onClick={applyFilters}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="13" height="17" viewBox="0 0 13 17" fill="none">
                    <path d="M11.7363 8.81108C11.4276 9.81451 10.4925 10.5444 9.38889 10.5444C8.28471 10.5444 7.35016 9.8141 7.04147 8.81108L0.722221 8.81108C0.323349 8.81108 -1.07845e-06 8.48773 -1.06101e-06 8.08886C-1.04357e-06 7.68998 0.323349 7.36663 0.722221 7.36663L7.04144 7.36663C7.35022 6.3632 8.28529 5.6333 9.38889 5.6333C10.4931 5.6333 11.4276 6.36362 11.7363 7.36663L12.2778 7.36663C12.6767 7.36663 13 7.68998 13 8.08886C13 8.48773 12.6766 8.81108 12.2778 8.81108L11.7363 8.81108ZM9.38889 7.07775C8.83162 7.07775 8.37778 7.53123 8.37778 8.08886C8.37778 8.64613 8.83127 9.09997 9.38889 9.09997C9.94616 9.09997 10.4 8.64648 10.4 8.08886C10.4 7.53158 9.94651 7.07775 9.38889 7.07775Z" fill="white" />
                    <path d="M5.38078 3.17778C5.072 4.18121 4.13693 4.91111 3.03333 4.91111C1.92882 4.91111 0.994035 4.18035 0.68563 3.17687C0.303759 3.15781 -1.07791e-06 2.84216 -1.06101e-06 2.45556C-1.04411e-06 2.06896 0.303748 1.75331 0.685606 1.73425C0.994098 0.730343 1.9294 -4.83911e-07 3.03333 -4.35657e-07C4.13752 -3.87392e-07 5.07206 0.730315 5.38076 1.73333L12.2778 1.73333C12.6767 1.73333 13 2.05668 13 2.45556C13 2.85443 12.6766 3.17778 12.2778 3.17778L5.38078 3.17778ZM3.03333 1.44444C2.47606 1.44444 2.02222 1.89793 2.02222 2.45556C2.02222 3.01283 2.47571 3.46667 3.03333 3.46667C3.5906 3.46667 4.04444 3.01318 4.04444 2.45556C4.04444 1.89828 3.59096 1.44444 3.03333 1.44444Z" fill="white" />
                    <path d="M7.403 14.4444C7.09422 15.4478 6.15916 16.1777 5.05556 16.1777C3.95137 16.1777 3.01682 15.4474 2.70813 14.4444L0.722221 14.4444C0.323349 14.4444 -1.07845e-06 14.121 -1.06101e-06 13.7222C-1.04357e-06 13.3233 0.323349 12.9999 0.722221 12.9999L2.70811 12.9999C3.01689 11.9965 3.95195 11.2666 5.05556 11.2666C6.15974 11.2666 7.09429 11.9969 7.40298 12.9999L12.2778 12.9999C12.6767 12.9999 13 13.3233 13 13.7222C13 14.121 12.6766 14.4444 12.2778 14.4444L7.403 14.4444ZM5.05556 12.711C4.49828 12.711 4.04444 13.1645 4.04444 13.7222C4.04444 14.2794 4.49793 14.7333 5.05556 14.7333C5.61283 14.7333 6.06667 14.2798 6.06667 13.7222C6.06667 13.1649 5.61318 12.711 5.05556 12.711Z" fill="white" />
                  </svg>
                  Apply Filters
                </button>
              </div>
            </Modal>
          </div>
          <div className="search-sec search-sec-user-overview">
            <div class="search-container">
              <input
                type="text"
                onChange={(e) => setFilter(e.target.value)}
                value={filter}
                class="search-input"
                placeholder="Search"
              />
            </div>
          </div>
        </div>
        <div className="card-outer1">
          <div className="card-outer">
            <div className="card">
              {isLoading === true ? (
                <div className="outter-load-table">
                  <ThreeDots
                    height="80"
                    width="80"
                    radius="9"
                    color="#405BFF"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                </div>
              ) : (
                <DataTable
                  value={filteredData}
                  first={first}
                  rows={rows}
                  removableSort
                  paginator={true}
                  paginatorPosition="bottom"
                  totalRecords={filteredData.length}
                  onPage={(e) => setFirst(e.first)}
                  tableStyle={{
                    minWidth: "50rem",
                    width: "100%",
                    textAlign: "left",
                  }}
                  paginatorClassName="recent-invoices-pagination"
                >
                  <Column
                    body={useRenderMemberName}
                    field="fullName"
                    sortField="fullName"
                    header="User Name"
                    sortable
                    style={{ minWidth: "300px", paddingRight: "44px" }}
                    sortFunction={(e) => {
                      const dataCopy = [...filteredData];
                      dataCopy.sort((rowData1, rowData2) => {
                        const fullName1 =
                          rowData1?.user_category &&
                            [
                              userCategory.Business,
                              userCategory.Vendor,
                              userCategory.Client,
                            ].includes(rowData1.user_category)
                            ? rowData1.company_first_name
                              ? `${rowData1.company_first_name} ${rowData1.company_last_name
                                ? rowData1.company_last_name
                                : ""
                              }`
                              : `${rowData1.company_name} (Company)`
                            : rowData1.first_name + " " + rowData1.last_name;

                        const fullName2 =
                          rowData2?.user_category &&
                            [
                              userCategory.Business,
                              userCategory.Vendor,
                              userCategory.Client,
                            ].includes(rowData2.user_category)
                            ? rowData2.company_first_name
                              ? `${rowData2.company_first_name} ${rowData2.company_last_name
                                ? rowData2.company_last_name
                                : ""
                              }`
                              : `${rowData2.company_name} (Company)`
                            : rowData2.first_name + " " + rowData2.last_name;

                        // Implement your custom comparison logic here
                        return e.order === 1
                          ? fullName1.localeCompare(fullName2)
                          : fullName2.localeCompare(fullName1);
                      });
                      return dataCopy;
                    }}
                  ></Column>
                  <Column
                    field="email"
                    header="Email"
                    sortable
                    style={{ paddingRight: "44px" }}
                  ></Column>
                  <Column
                    field="role_id.user_type"
                    header="Account Type"
                    sortable
                    style={{ paddingRight: "44px" }}
                  ></Column>
                  <Column
                    field="created_at"
                    body={dateMasterCreateConvert}
                    header="Due Added"
                    className="fixed-sec3"
                    sortable
                    style={{ paddingRight: "44px", left: "22px" }}
                  ></Column>
                  <Column
                    field="is_active"
                    body={(rowData) => renderUserStatus(rowData)}
                    header="Status"
                    sortable
                    style={{ paddingRight: "44px" }}
                  ></Column>
                  <Column
                    field="_id"
                    body={RenderMasterUserCTA}
                    style={{ paddingRight: "8px", width: "80px" }}
                  ></Column>
                </DataTable>
              )}
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};
export default MasterUsers;