import axios from 'axios';
import { useQuery } from 'react-query';
import userTypeName from "../../../../constants/userType";

export function useUserView(userId, token, profileEdit) {
  return useQuery(['userView', userId], async () => {
    const response = await axios.get(`/api/user/view/${userId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  },
    {
      enabled: !profileEdit, // Use this to conditionally enable or disable the query
    }
  );
}

export function useUserType() {
  return useQuery('userType', async () => {
    const response = await axios.get('/api/user-type');
    return response.data.filter(
      (role) =>
        role._id !== userTypeName.Collaborator &&
        role._id !== userTypeName.Business
    );
  });
}

export function useWXCompanies() {
  return useQuery('wxCompanies', async () => {
    const response = await axios.get('/api/get-wx-comapies');
    return response.data;
  });
}

export function useUserDepartments() {
  return useQuery('userDepartments', async () => {
    const response = await axios.get('/api/user-department');
    return response.data;
  });
}

export function useContractTypes() {
  return useQuery('contractTypes', async () => {
    const response = await axios.get('/api/contract-type');
    return response.data;
  });
}

export function useOverheadCostTypes() {
  return useQuery('overheadCostTypes', async () => {
    const response = await axios.get('/api/overhead-cost-type');
    return response.data;
  });
}