import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ThreeDots } from "react-loader-spinner";
import moment from "moment";
import profileImage from "../../../../images/user/Default_pfp.png";
import TruncateUserName from "../../../helpers/truncateUserName";
import DateConverter from "../../../../helpers/dateFormatChange";

// Dummy data for testing
const dummyData = [
  {
    user: {
      name: "Rey Bugawan",
      profilePhoto: profileImage,
    },
    type: "Personal",
    issueDate: "2023-07-01",
    totalDays: 5,
    startDate: "2023-07-10",
    endDate: "2023-07-14",
  },
  {
    user: {
      name: "Calin Tatar",
      profilePhoto: profileImage,
    },
    type: "Medical",
    issueDate: "2023-07-01",
    totalDays: 0.5,
    startDate: "2023-07-10",
    endDate: "2023-07-14",
  },
  {
    user: {
      name: "Jane Smith",
      profilePhoto: profileImage,
    },
    type: "Sick Leave",
    issueDate: "2023-07-02",
    totalDays: 3,
    startDate: "2023-07-15",
    endDate: "2023-07-17",
  },
  // Add more dummy data as needed
];

const TimeOffRequestTable = ({ timeoffReq, timeoffReqLoading, timeoffReqError }) => {
  const reportingTimeoffRequest = useSelector((state) => state.reportingTimeoffRequest);
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    if (timeoffReq && reportingTimeoffRequest) {
      const filtered = timeoffReq.filter(tor =>
        !tor.is_delete &&
        reportingTimeoffRequest.timeoffReqUser && reportingTimeoffRequest.timeoffReqUser.includes(tor.user._id) &&
        reportingTimeoffRequest.timeoffReqReqStatus && reportingTimeoffRequest.timeoffReqReqStatus.includes(tor.status) &&
        reportingTimeoffRequest.timeoffReqYear && new Date(tor?.end_date).getFullYear() === reportingTimeoffRequest.timeoffReqYear &&
        reportingTimeoffRequest.timeoffReqCategory && reportingTimeoffRequest.timeoffReqCategory.includes(tor.type_of_leave._id)
      );
      const sortedData = filtered.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
      setFilteredData(sortedData);
    }
  }, [timeoffReq, reportingTimeoffRequest]);

  const renderUser = (rowData) => {
    const userDetails = rowData.user;
    const fullName = userDetails.first_name ? userDetails.first_name +
      (userDetails.middle_name && userDetails.middle_name !== "null" ? " " + userDetails.middle_name : "") +
      " " +
      userDetails.last_name
      : userDetails.company_name;
    return (
      <div className='member-name' style={{ display: "flex", alignItems: "center" }} >
        {userDetails.profile_image !== null && userDetails.profile_image !== "null" ? (
          <img
            src={`/uploads/lowProfileImage/${userDetails.profile_image}`}
            alt={fullName}
            className="member-image"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = '/uploads/lowProfileImage/user_default.png';
            }}
          />
        ) : (
          <img src={profileImage} alt={fullName} className="member-image" />
        )}
        <TruncateUserName
          textContent={userDetails.first_name ? (
            <>
              {userDetails.first_name}{' '}
              {userDetails.middle_name && userDetails.middle_name !== 'null' ? userDetails.middle_name + ' ' : ''}
              {userDetails.last_name}{' '}
            </>
          ) : (
            <>
              {userDetails.company_first_name
                ? `${userDetails.company_first_name} ${userDetails.company_last_name ? userDetails.company_last_name : ''}`
                : `${userDetails.company_name || ''}(Company)`}
            </>
          )} />
      </div>
    );
  };

  const calculateNumberOfDays = (startDate, startTime, endDate, endTime) => {
    if (!startTime || !endTime || startDate !== endDate) {
      const startDateTime = moment(startDate).tz(global.config.getTimeZone()).startOf('day');
      const endDateTime = moment(endDate).tz(global.config.getTimeZone()).startOf('day');
      const duration = moment.duration(endDateTime.diff(startDateTime));
      const numberOfDays = Math.ceil(duration.asDays());
      return `${numberOfDays + 1} days`;
    } else {
      const startDateTime = moment(startDate).tz(global.config.getTimeZone()).startOf('day').add(startTime, 'hours');
      const endDateTime = moment(endDate).tz(global.config.getTimeZone()).startOf('day').add(endTime, 'hours');
      const duration = moment.duration(endDateTime.diff(startDateTime));
      const numberOfDays = Math.ceil(duration.asDays());
      if (numberOfDays === 1) {
        const numberOfHours = (endTime - startTime) * numberOfDays;
        const numberOfDaysDecimal = (numberOfHours / 8).toFixed(2);
        const cappedNumberOfDaysDecimal = Math.min(numberOfDaysDecimal, 1);
        return `${cappedNumberOfDaysDecimal} days`;
      } else {
        return `${numberOfDays} days`;
      }
    }
  };

  return (
    <div className="datatable-team-maintb">
      <DataTable
        value={filteredData.slice(0, 5)}
        sort
        emptyMessage={timeoffReqLoading ? (
          <div className="outter-load-table">
            <ThreeDots
              height="80"
              width="80"
              radius="9"
              color="#405BFF"
              ariaLabel="three-dots-loading"
              visible={true}
            />
          </div>
        ) : "No time off request data found"}
        tableStyle={{ minWidth: "50rem", width: "100%", textAlign: "left" }}
      >
        <Column
          field="user.first_name"
          header="User"
          sortable
          body={renderUser}
          style={{ width: "20%", paddingRight: "20px" }}
        />
        <Column
          field="type_of_leave.leave_type"
          header="Type"
          sortable
          style={{ width: "15%", paddingRight: "20px" }}
        />
        <Column
          field="created_at"
          header="Issue Date"
          sortable
          body={(rowData) =>
            <DateConverter date={rowData.created_at} />
          }
          style={{ width: "15%", paddingRight: "20px" }}
        />
        <Column
          field="totalDays"
          header="Total Days"
          body={(rowData) => calculateNumberOfDays(
            rowData.start_date,
            rowData.start_time,
            rowData.end_date,
            rowData.end_time
          )}
          style={{ width: "10%", paddingRight: "20px" }}
        />
        <Column
          field="start_date"
          header="Start Date"
          sortable
          body={(rowData) =>
            <DateConverter date={rowData.start_date} />
          }
          style={{ width: "15%", paddingRight: "20px" }}
        />
        <Column
          field="end_date"
          header="End Date"
          sortable
          body={(rowData) =>
            <DateConverter date={rowData.end_date} />
          }
          style={{ width: "15%", paddingRight: "20px" }}
        />
      </DataTable>
    </div>
  );
};

export default TimeOffRequestTable;
