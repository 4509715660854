import React, { useState, useRef, } from 'react';
import axios from "axios";
import { toast } from "react-toastify";
import { ThreeDots } from "react-loader-spinner";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { logout, reset } from "../../../features/auth/authSlice.js";
import { reset as invoiceReset } from "../../../features/invoice/invoiceSlice.js";
import { reset as headerReset } from "../../../features/headerText/headerTextSlice.js";
import { reset as appSettingsReset } from "../../../features/appSettings/appSettingsSlice.js";
import UserIcon from "../../../images/icons/single-user-icon.svg";
import EyeIcon from "../../../images/icons/eye-icon.svg";
import EyeCrossed from "../../../images/icons/eye-crossed.svg";
import EmailIcon from "../../../images/icons/email.svg";
import PassLock from "../../../images/icons/pass_lock.svg";
import Tick from "../../../images/icons/tick-arrow.svg";

const MasterTenantRegister = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [companyName, setCompanyName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [passwordConfirmation, setPasswordConfirmation] = useState("");
    const [isSubmitLoading, setIsSubmitLoading] = useState(false);
    const [passwordMatchError, setPasswordMatchError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const passwordConfirmationRef = useRef(null);
    const passwordRef = useRef(null);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsSubmitLoading(true);

        if (password !== passwordConfirmation) {
            setPasswordMatchError("Passwords do not match");
            passwordConfirmationRef.current.focus();
            setIsSubmitLoading(false);
            return;
        }
        const passwordRegex =
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#\$%\^&\*\(\)_\+\-\.\/:;<=>?@[\\\]^_`{|}~])[A-Za-z\d!@#\$%\^&\*\(\)_\+\-\.\/:;<=>?@[\\\]^_`{|}~]{8,}$/;
        if (!passwordRegex.test(password)) {
            setPasswordError("Invalid password format");
            toast.error(
                "Password must be at least 8 charachters long and include atleast one uppercase letter ,one lowercase letter,one digit and one special character from the set !@#$%^&*()_+,-/:;<=>?@_'[\]{|}~", {
                position: "bottom-right",
                autoClose: 3800,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            passwordRef.current.focus();
            setIsSubmitLoading(false);
            return;
        }

        const requestData = {
            email: email,
            companyName: companyName,
            password: password,
            google: false,
        };

        try {
            const response = await axios.post("/api/app/register", requestData);
            const data = response.data;
            if (data.status === "failed") {
                toast.warning(data.message || "User with this mail id already exists", {
                    position: "bottom-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else if (data.status === "exists") {
                toast.warning(data.data.message || "User with this mail id already exists", {
                    position: "bottom-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else if (data.status === "error") {
                toast.error(data.data.message || "Something went wrong", {
                    position: "bottom-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                toast.success("User registered successfully", {
                    position: "bottom-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                navigate(`/master-user/tenants/list`);
            }
        } catch (error) {
            console.log(error);
            global.config.slackMessage(error.toString());
            if (error.response?.data?.status === "deleted") {
                localStorage.removeItem("user");
                dispatch(logout());
                dispatch(reset());
                dispatch(invoiceReset());
                dispatch(headerReset());
                dispatch(appSettingsReset());
                toast.error('This user is deleted!', {
                    position: "bottom-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                navigate("/");
                return;
            }
            toast.error('Something went wrong while registering user!', {
                position: "bottom-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } finally {
            setIsSubmitLoading(false);
        }
    };

    return (
        <div className="user-register-client">
            <div className='back-to'>
                <Link to="/master-user/tenants/list">
                    <span >
                        Back to All Tenants
                    </span>
                </Link>
            </div>
            <div className='tab-outer'>
                <ul className="tabs">
                    <li className='active-tab'>
                        Register New Tenant
                    </li>
                </ul>
                <div className="tabs-content">
                    <div className="profile-details-edit" >
                        <form onSubmit={handleSubmit}>
                            <div className="information-outer-section">
                                <div className="information-inner">
                                    <div className="information">
                                        <div className="information-title">
                                            Personal Information
                                        </div>
                                        <div className="information-outer">
                                            <div className="information-left">
                                                <div className="inner-detail-outer">
                                                    <div className="inner-detail">
                                                        <span class="icon"><img src={UserIcon} alt="user icon" /></span>
                                                        <div className="inner-detail-inner">
                                                            <label for="fname">Company Name *</label>
                                                        </div>
                                                    </div>
                                                    <div className="inner-detail">
                                                        <span class="icon"></span>
                                                        <div className="inner-detail-inner">
                                                            <input
                                                                type="text"
                                                                placeholder="Enter Company name"
                                                                value={companyName}
                                                                onChange={(e) => setCompanyName(e.target.value)}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="inner-detail-outer">
                                                    <div className="inner-detail">
                                                        <span class="icon"><img src={EmailIcon} alt="user icon" /></span>
                                                        <div className="inner-detail-inner">
                                                            <label for="email">Email *</label>
                                                        </div>
                                                    </div>
                                                    <div className="inner-detail">
                                                        <span class="icon"></span>
                                                        <div className="inner-detail-inner">
                                                            <input
                                                                type="email"
                                                                placeholder="Email"
                                                                value={email}
                                                                onChange={(e) => setEmail(e.target.value)}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="information-right">
                                                <div className="inner-detail-outer">
                                                    <div className="inner-detail">
                                                        <span class="icon"><img src={PassLock} alt="user icon" /></span>
                                                        <div className="inner-detail-inner">
                                                            <label for="password">Password *</label>
                                                        </div>
                                                    </div>
                                                    <div className="inner-detail">
                                                        <span class="icon"></span>
                                                        <div className="inner-detail-inner pass-eye">
                                                            <input
                                                                type={showPassword ? "text" : "password"}
                                                                placeholder="Password"
                                                                value={password}
                                                                onChange={(e) => setPassword(e.target.value)}
                                                                ref={passwordRef}
                                                                required
                                                            />
                                                            <img
                                                                src={showPassword ? EyeCrossed : EyeIcon}
                                                                className="password-toggle-icon"
                                                                alt="Toggle Password"
                                                                onClick={() => setShowPassword(!showPassword)}
                                                            />
                                                        </div>
                                                        {passwordError && (
                                                            <span>{passwordError}</span>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="inner-detail-outer">
                                                    <div className="inner-detail">
                                                        <span class="icon"><img src={PassLock} alt="user icon" /></span>
                                                        <div className="inner-detail-inner">
                                                            <label for="confpassword">Confirm Password *</label>
                                                        </div>
                                                    </div>
                                                    <div className="inner-detail">
                                                        <span class="icon"></span>
                                                        <div className="inner-detail-inner">
                                                            <input
                                                                type="password"
                                                                placeholder="Confirm Password"
                                                                value={passwordConfirmation}
                                                                onChange={(e) => setPasswordConfirmation(e.target.value)}
                                                                ref={passwordConfirmationRef}
                                                                required
                                                            />
                                                            {passwordMatchError && (
                                                                <span style={{ color: "red" }}> {passwordMatchError} </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="save-button-sec">
                                {isSubmitLoading ? (
                                    <span className="cancel-btn-disabled">Cancel</span>
                                ) : (
                                    <Link to="/master-user/tenants/list" className="cancel-btn">Cancel</Link>
                                )}
                                <button type="submit" className="save-btn" disabled={isSubmitLoading} >
                                    {isSubmitLoading ?
                                        <div className="outter-load-table">
                                            <ThreeDots
                                                height="38"
                                                width="40"
                                                radius="9"
                                                color="white"
                                                ariaLabel="three-dots-loading"
                                                wrapperStyle={{}}
                                                wrapperClassName=""
                                                visible={true}
                                            />
                                        </div> :
                                        <>
                                            <img src={Tick} alt="arrow" />Save changes
                                        </>
                                    }
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div >
        </div >
    );
}

export default MasterTenantRegister;