import React from "react";
import { AES, enc } from 'crypto-js';
import { useParams } from "react-router-dom";
import InvoiceEditManual from "../../components/Invoices/InvoiceEditManual/InvoiceEditManual";

function InvoiceEditManualPage() {
    const { encryptedId } = useParams();
    const decodedId = decodeURIComponent(encryptedId.replace(/%2F/g, '/'));
    const decryptedBytes = AES.decrypt(decodedId, 'encryptionKey');
    const id = decryptedBytes.toString(enc.Utf8);

    return (
        <div className="react-outerme">
            <div><InvoiceEditManual id={id}/></div>
        </div>
    );
}

export default InvoiceEditManualPage;
