import React, { useEffect, useState } from "react";
import "./landingPage.css";
import axios from "axios";
import moment from "moment";
import Cookies from 'js-cookie';
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import Logo from "../../../images/logo/to-logo.svg";
import EyeIcon from "../../../images/icons/eye-icon.svg";
import { useSelector, useDispatch } from "react-redux";
import LoginImage from "../../../images/login/lg-img.png";
import { loginTemp } from "../../../features/auth/authSlice";
import Spinner from "../../../components/Animations/Spinner";
import EyeCrossed from "../../../images/icons/eye-crossed.svg";
import LoginImageMobile from "../../../images/login/lg-img-mob.png";
import { setOnboard, setOnboardUserType } from "../../../features/onBoard/onBoardSlice";

function LandingPage() {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [userType, setUserType] = useState(null);
  if(userType){
    dispatch(setOnboardUserType(userType));
  }

 
  useEffect(() => {

    if (userType) {

      navigate('/on-boarding/email-verification');

    }


  }, [userType, navigate]);



  return (
    <div>
      <div className="login-outer onboarding">
        <div className="login-inner">
          <div className="top-logo-mobile">
            <img src={Logo} alt="Talent On" />
          </div>
          <div className="lg-lft">
            <img src={LoginImage} alt="desktop" className="desktop-lg-img" />
            <img src={LoginImageMobile} alt="mobile" className="mobile-lg-img" />
          </div>
          <div className="lg-rgt">
            <div className="top-logo">
              <img src={Logo} alt="Talent On" />
            </div>
            <div className="login-form-sec">

              <form onSubmit="#">

                <h3>Create your Talenton account.</h3>
                <h4>What type of account do you want to create?</h4>
                <div className="form-outer email personal">

                  <button className="active" type="button" onClick={() => setUserType("personal")} >Personal</button>

                </div>
                <div className="form-outer email business">

                  <button type="button" onClick={() => setUserType("business")}  >Business</button>

                </div>


              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );


}

export default LandingPage;