import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  expTeam: null,
  expClient: null,
  expProject: null,
  expDepartment: null,
  filterExpCategory: null,
  filterExpCurrency: null,
  // expenseUpdate: false,
};

export const setExpTeam = createAsyncThunk("expense/team", async (expTeam) => {
  return expTeam;
});

export const setExpClient = createAsyncThunk(
  "expense/client",
  async (expClient) => {
    return expClient;
  }
);

export const setExpProject = createAsyncThunk(
  "expense/project",
  async (expProject) => {
    return expProject;
  }
);

export const setExpDepartment = createAsyncThunk(
  "expense/department",
  async (expDepartment) => {
    return expDepartment;
  }
);

export const setFilterExpCategory = createAsyncThunk(
  "expense/category",
  async (filterExpCategory) => {
    return filterExpCategory;
  }
);

export const setFilterExpCurrency = createAsyncThunk(
  "expense/currency",
  async (filterExpCurrency) => {
    return filterExpCurrency;
  }
);

// export const setDate = createAsyncThunk("expense/date", async (date) => {
//   if (date != null) {
//     date.setHours(0, 0, 0, 0);
//     return date.toISOString();
//   } else {
//     return null;
//   }
// });

// export const expenseUpdate = createAsyncThunk(
//   "expense/expenseUpdate",
//   async (expenseUpdate) => {
//     return expenseUpdate;
//   }
// );

export const expenseSlice = createSlice({
  name: "expenseData",
  initialState,
  reducers: {
    reset: (state) => {
      state.expTeam = null;
      state.expClient = null;
      state.expProject = null;
      state.expDepartment = null;
      state.filterExpCategory = null;
      state.filterExpCurrency = null;
      // state.expenseUpdate = false;
      // state.perPage = 10;
      // state.date = new Date();
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setExpTeam.fulfilled, (state, action) => {
      state.expTeam = action.payload;
    });
    builder.addCase(setExpClient.fulfilled, (state, action) => {
      state.expClient = action.payload;
    });
    builder.addCase(setExpProject.fulfilled, (state, action) => {
      state.expProject = action.payload;
    });
    builder.addCase(setExpDepartment.fulfilled, (state, action) => {
      state.expDepartment = action.payload;
    });
    builder.addCase(setFilterExpCategory.fulfilled, (state, action) => {
      state.filterExpCategory = action.payload;
    });
    builder.addCase(setFilterExpCurrency.fulfilled, (state, action) => {
      state.filterExpCurrency = action.payload;
    });
    // builder.addCase(setDate.fulfilled, (state, action) => {
    //   state.date = action.payload;
    // });
    // builder.addCase(setPerPage.fulfilled, (state, action) => {
    //   state.perPage = action.payload;
    // });
    // builder.addCase(expenseUpdate.fulfilled, (state, action) => {
    //   state.expenseUpdate = action.payload;
    // });
  },
});

export const { reset } = expenseSlice.actions;
export default expenseSlice.reducer;
