import React from "react";
import InvoiceCreateAutocom from "../../components/Invoices/InvoiceCreateAuto/InvoiceCreateAuto";

function InvoiceCreateAuto() {
    return (
        <div className="react-outerme">
            <div><InvoiceCreateAutocom /></div>
        </div>
    );
}

export default InvoiceCreateAuto;
