import axios from "axios";
import "./UserAccountEdit.css";
import Modal from "react-modal";
import React, { useState} from "react";
import Type from "../../../../../../images/icons/type.svg";
import Delete from "../../../../../../images/icons/delete.svg";
import EmailIcon from "../../../../../../images/icons/email.svg";
import Tick from "../../../../../../images/icons/tick-arrow.svg";
import Payment from "../../../../../../images/icons/payment.svg";
import location from "../../../../../../images/icons/location.svg";
import PostalCode from "../../../../../../images/icons/postal-code.svg";
import PassportlIcon from "../../../../../../images/icons/passport.svg";
import UserIcon from "../../../../../../images/icons/single-user-icon.svg";
import TypeContact from "../../../../../../images/icons/type-of-contact.svg";

const AccountDetailsEdit = () => {

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  
  const handleDeleteClick = () => {
    setShowConfirmation(true);
  };

  const deleteBank = (profileId,url,key) =>{
    axios.post('/api/wise/profile/delete',{
      profileId:profileId,
      url:url,
      key:key
    })
    .then((response)=>{
    })
  }
  return (
    <div className="account-details-edit">
      <div className="profile-photo-outer">
        <div class="profile-photo">
          <h2>Account #1</h2>
        </div>
        <div className="profile-button">
          <a href="#" className="delete-profile" onClick={handleDeleteClick}>
            <span class="bt-ico">
              <img src={Delete} alt="delete" />
            </span>
            Delete
          </a>
        </div>
      </div>

      {showConfirmation && (
        <Modal
          isOpen={showConfirmation}
          onRequestClose={() => setShowConfirmation(false)}
          contentLabel="Confirmation Modal"
          style={{
            content: {
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              transform: "translate(-50%, -50%)",
              maxHeight: "80vh",
              overflow: "auto",
            },
          }}
        >
          <p>Are you sure you want to delete?</p>
          <div>
            <button onClick={deleteBank()}>OK</button>
            <button onClick={() => setShowConfirmation(false)}>Cancel</button>
          </div>
        </Modal>
      )}

      {showSuccessMessage && (
        <div className="success-message">Profile deleted successfully!</div>
      )}

      <div className="information-outer-section">
        <div className="information-inner">
          <div className="information">
            <div className="information-title">General Information</div>
            <div className="information-outer">
              <div className="information-left">
                <div className="inner-detail-outer">
                  <div className="inner-detail">
                    <span class="icon">
                      <img alt="" src={UserIcon} />
                    </span>
                    <div className="inner-detail-inner">
                      <label for="fname">Account Holder Name</label>
                    </div>
                  </div>
                  <div className="inner-detail">
                    <span class="icon"></span>
                    <div className="inner-detail-inner">
                      <input type="text" id="fname" />
                    </div>
                  </div>
                </div>
                <div className="inner-detail-outer">
                  <div className="inner-detail">
                    <span class="icon">
                      <img alt="" src={UserIcon} />
                    </span>
                    <div className="inner-detail-inner">
                      <label for="mname">Wise Profile</label>
                    </div>
                  </div>
                  <div className="inner-detail">
                    <span class="icon"></span>
                    <div className="inner-detail-inner">
                      <input type="text" id="mname" />
                    </div>
                  </div>
                </div>
                <div className="inner-detail-outer">
                  <div className="inner-detail">
                    <span class="icon">
                      <img alt="" src={Type} />
                    </span>
                    <div className="inner-detail-inner">
                      <label for="lname">Account Type</label>
                    </div>
                  </div>
                  <div className="inner-detail">
                    <span class="icon"></span>
                    <div className="inner-detail-inner">
                      <input type="text" id="lname" />
                    </div>
                  </div>
                </div>
                <div className="inner-detail-outer">
                  <div className="inner-detail">
                    <span class="icon">
                      <img alt="" src={TypeContact} />
                    </span>
                    <div className="inner-detail-inner">
                      <label for="lname">Transit Number</label>
                    </div>
                  </div>
                  <div className="inner-detail">
                    <span class="icon"></span>
                    <div className="inner-detail-inner">
                      <input type="text" id="lname" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="information-right">
                <div className="inner-detail-outer">
                  <div className="inner-detail">
                    <span class="icon">
                      <img alt="" src={PassportlIcon} />
                    </span>
                    <div className="inner-detail-inner">
                      <label for="email">Wise ID</label>
                    </div>
                  </div>
                  <div className="inner-detail">
                    <span class="icon"></span>
                    <div className="inner-detail-inner">
                      <input id="email" />
                    </div>
                  </div>
                </div>
                <div className="inner-detail-outer">
                  <div className="inner-detail">
                    <span class="icon">
                      <img alt="" src={Payment} />
                    </span>
                    <div className="inner-detail-inner">
                      <label>Currency</label>
                    </div>
                  </div>
                  <div className="inner-detail">
                    <span class="icon"></span>
                    <div className="inner-detail-inner">
                      <input id="email" />
                    </div>
                  </div>
                </div>
                <div className="inner-detail-outer">
                  <div className="inner-detail">
                    <span class="icon">
                      <img alt="" src={TypeContact} />
                    </span>
                    <div className="inner-detail-inner">
                      <label for="id_number">Account Number</label>
                    </div>
                  </div>
                  <div className="inner-detail">
                    <span class="icon"></span>
                    <div className="inner-detail-inner">
                      <input type="text" id="id_number" />
                    </div>
                  </div>
                </div>
                <div className="inner-detail-outer">
                  <div className="inner-detail">
                    <span class="icon">
                      <img alt="" src={TypeContact} />
                    </span>
                    <div className="inner-detail-inner">
                      <label for="id_number">Institution Number</label>
                    </div>
                  </div>
                  <div className="inner-detail">
                    <span class="icon"></span>
                    <div className="inner-detail-inner">
                      <input type="text" id="id_number" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="information-inner">
          <div className="information">
            <div className="information-title">Address</div>
            <div className="information-outer">
              <div className="information-left">
                <div className="inner-detail-outer">
                  <div className="inner-detail">
                    <span class="icon">
                      <img alt="" src={location} />
                    </span>
                    <div className="inner-detail-inner">
                      <label>City</label>
                    </div>
                  </div>
                  <div className="inner-detail">
                    <span class="icon"></span>
                    <div className="inner-detail-inner">
                      <select>
                        <option>Panama</option>
                        <option>India</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="inner-detail-outer">
                  <div className="inner-detail">
                    <span class="icon">
                      <img alt="" src={location} />
                    </span>
                    <div className="inner-detail-inner">
                      <label>Country</label>
                    </div>
                  </div>
                  <div className="inner-detail">
                    <span class="icon"></span>
                    <div className="inner-detail-inner">
                      <select>
                        <option>Panamá</option>
                        <option>Colón</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="inner-detail-outer">
                  <div className="inner-detail">
                    <span class="icon">
                      <img alt="" src={location} />
                    </span>
                    <div className="inner-detail-inner">
                      <label>State</label>
                    </div>
                  </div>
                  <div className="inner-detail">
                    <span class="icon"></span>
                    <div className="inner-detail-inner">
                      <select>
                        <option>Panamá</option>
                        <option>Colón</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="information-right">
                <div className="inner-detail-outer">
                  <div className="inner-detail">
                    <span class="icon">
                      <img alt="" src={location} />
                    </span>
                    <div className="inner-detail-inner">
                      <label for="address">Address</label>
                    </div>
                  </div>
                  <div className="inner-detail">
                    <span class="icon"></span>
                    <div className="inner-detail-inner">
                      <input type="text" id="address" />
                    </div>
                  </div>
                </div>
                <div className="inner-detail-outer">
                  <div className="inner-detail">
                    <span class="icon">
                      <img alt="" src={PostalCode} />
                    </span>
                    <div className="inner-detail-inner">
                      <label for="postal_code">Postal Code</label>
                    </div>
                  </div>
                  <div className="inner-detail">
                    <span class="icon"></span>
                    <div className="inner-detail-inner">
                      <input type="text" id="postal_code" />
                    </div>
                  </div>
                </div>
                <div className="inner-detail-outer">
                  <div className="inner-detail">
                    <span class="icon">
                      <img alt="" src={EmailIcon} />
                    </span>
                    <div className="inner-detail-inner">
                      <label for="number">Email</label>
                    </div>
                  </div>
                  <div className="inner-detail">
                    <span class="icon"></span>
                    <div className="inner-detail-inner">
                      <input type="" id="number" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="save-button-sec">
        <a href="#" className="cancel-btn">
          Cancel
        </a>
        <a href="" className="save-btn">
          <img src={Tick} alt="arrow" />
          Save changes
        </a>
      </div>
    </div>
  );
};

export default AccountDetailsEdit;