import React from 'react'
import { Link } from "react-router-dom";

const Company = () => {
    return (
        <div>
            <div>
                <div className='back-to'>
                    <Link to="../list">
                        <span >
                            &lt; Back to All Members
                        </span>
                    </Link>
                </div>
                <div className='tab-outer'>
                    <ul className="tabs">
                        <li className='active-tab'>
                            Company
                        </li>
                    </ul>
                    <div className="tabs-content">
                        <div className="profile-details-edit" >
                            <form onSubmit="">
                                <div className="inner-detail-outer" >
                                    <div className="inner-detail">
                                        <span class="icon"></span>
                                        <div className="inner-detail-inner" style={{ width: "40%" }}>
                                            <div className="inner-detail-inner">
                                                <label for="lname">App Owner</label>
                                            </div>
                                            <select name="cars" id="cars" >
                                                <option value="volvo">Volvo</option>
                                                <option value="saab">Saab</option>
                                                <option value="mercedes">Mercedes</option>
                                                <option value="audi">Audi</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Company;