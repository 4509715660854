import { useSelector } from 'react-redux';
import React, { useEffect, useState } from "react";
import { useAllProfile } from "../bankApiQuery";
import axios from "axios";

const BankComapny = ({ profileId }) => {
  let token = null;
  const [company, setCompany] = useState(null);
  const { user } = useSelector((state) => state.auth);
  if (user) {
    if (user && user.token) {
      token = user.token;
    }
  }

  useEffect(() => {
    const checkCompany = async () => {
      try {
        var profileComapany = await axios.get(`/api/wise/comapny/from-profile/${profileId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        });
        setCompany(profileComapany.data);
      } catch (error) {
        console.log(error);
      }
    }
    checkCompany();
  }, [profileId]);

  // const appId = useSelector((state) => state.appSettings.appSettings.appId);
  // const { data: deactivatedProfiles, isLoading, refetch: refetchDeactivatedProfiles } = useAllProfile(token, appId);

  // const filtered = deactivatedProfiles.id && deactivatedProfiles.id.filter(item => {
  //   return item.id === parseInt(profileId);
  // });

  return (
    <>
      {/* {filtered && filtered.length > 0 ? filtered[0].businessName : "Not Available"} */}
      {company}
    </>
  );
};

export default BankComapny;