import React from "react";
import Users from "../../components/User/UserOverview";

function User()  {
    return(
        <div className="react-outer">
            <Users/>
        </div>
    )
}

export default User;