import React, { useRef, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import "./TimeEntryLockModal.css";
import moment from "moment";

const TimeEntryPopup = ({ onClose }) => {
  const popupRef = useRef(null);
  const { appSettings } = useSelector((state) => state.appSettings);

  useEffect(() => {
    // Function to handle clicks outside of the popup
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose();
      }
    };

    // Add event listener when the component mounts
    document.addEventListener('mousedown', handleClickOutside);

    // Remove event listener when the component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);


  const hours = (days, time) => {

    try {

      const currentTime = moment();
      currentTime.add(days, 'days');
      let newDate = currentTime.format("YYYY-MM-DD");
      // Parse the time

      const newtime = moment(time, 'h:mm A');
      const date = moment(newDate);
      const combinedDateTime = date.set({
        'hour': newtime.hours(),
        'minute': newtime.minutes(),
        'second': newtime.seconds()
      });

      const currentTimeNew = moment();
      const targetTime = moment(combinedDateTime.format('YYYY-MM-DD HH:mm:ss'));
      const hoursDifference = targetTime.diff(currentTimeNew, 'hours');
      return hoursDifference;

    } catch (e) {
      let value = 48;
      return value;
    }

  }

  return (
    <div className="popup" ref={popupRef}>
      {/* Popup content */}
      <div className="popup-content">
        {/* Content of your popup */}
        <h6>Time Entry Locked</h6>
        <p>The time entry has been set up to lock after {hours(appSettings.timerLock, appSettings.timerLockTime)} hours.</p>
        <p>Please contact your admin to make edits to your time entry.</p>
      </div>
    </div>
  );
};

export default TimeEntryPopup;