import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { ThreeDots } from "react-loader-spinner";
import axios from 'axios';

function ThisWeekTotalDisplay({ startDate, endDate , selectedUser }) {
    const [weekTotal, setWeekTotal] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const token = useSelector((state) => state.auth.token);
    const timerChange = useSelector((state) => state.timerData.timerDataState);

    function formatTime(value) {
        return value < 10 ? `0${value}` : value;
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.post("/api/time-track/week-total", { startDate, endDate, zone: global.config.getTimeZone() ,selectedUser }, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                const weekTotal = (`${formatTime(Math.floor(parseInt(response.data) / 3600))}:${formatTime(Math.floor(parseInt((response.data % 3600) / 60)))}:${formatTime(Math.floor(parseInt(response.data) % 60))}`);
                setWeekTotal(weekTotal);
                setIsLoading(false);
            } catch (error) {
                global.config.slackMessage(error.toString());
                console.error("Error calculating week total:", error);
                setIsLoading(false);
            }
        };

        fetchData();
    }, [startDate, endDate, token, timerChange , selectedUser]);

    if (isLoading) {
        return <div>
            <ThreeDots
                height="18"
                width="20"
                radius="9"
                color="#6479f9"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
            />
        </div>;
    }

    if (weekTotal === null) {
        return <span>Error loading data</span>;
    }
    return <span>{weekTotal}</span>;
}

export default ThisWeekTotalDisplay;