import React, { useState, useEffect, useRef, createRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import Modal from "react-modal";
import { toast } from "react-toastify";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { ThreeDots } from "react-loader-spinner";
import { RotatingLines } from "react-loader-spinner";
import { Link, useNavigate } from "react-router-dom";
import { AES, enc } from "crypto-js";
import { useParams } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import LeftArrow from "../../../images/icons/blue-left-arrow.svg";
import RoundImage from "../../../images/icons/round-image.svg";
import SingleUser from "../../../images/icons/single-user-icon.svg";
import CompanyUrl from "../../../images/icons/webpage.svg";
import EmailIcon from "../../../images/icons/email.svg";
import BottomArrow from "../../../images/icons/down-arrow.svg";
import LocationIcon from "../../../images/icons/location.svg";
import PostalIcon from "../../../images/icons/postal-code.svg";
import PassportIcon from "../../../images/icons/passport.svg";
import PaymentIcon from "../../../images/icons/payment.svg";
import PhoneIcon from "../../../images/icons/phone-icon.svg";
import CostIcon from "../../../images/icons/cost1.svg";
import CompanyIcon from "../../../images/icons/company-type.svg";
import WhiteTick from "../../../images/icons/tick-arrow.svg";
import profile_image from "../../../images/user/Default_pfp.png";
import Edit from "../../../images/icons/edit.svg";
import Currencies from "../../../constants/currenciesList";
import Countries from "../../../constants/countries";
import { setNotification } from "../../../features/notification/notificationSlice.js";
import { logout, reset } from "../../../features/auth/authSlice";
import { reset as invoiceReset } from "../../../features/invoice/invoiceSlice.js";
import { reset as headerReset } from "../../../features/headerText/headerTextSlice.js";
import { reset as appSettingsReset } from "../../../features/appSettings/appSettingsSlice.js";
import {
  useClientView,
  useDeleteClientDetails,
  usePosition,
  useUpdateClientDetails,
} from "../../TimeTracker/TimeTrackerClient/useClientDetails.jsx";
import ClientUserProfileEdit from "./ClientUserProfileEdit.jsx";

const ClientUserProfile = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const { encryptedId } = useParams();
  // const decodedId = decodeURIComponent(encryptedId.replace(/%2F/g, "/"));
  // const decryptedBytes = AES.decrypt(decodedId, "encryptionKey");
  // const id = decryptedBytes.toString(enc.Utf8);
  const [uploadedImageUrl, setUploadedImageUrl] = useState("");
  const [image, setImage] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [profileEdit, setProfileEdit] = useState(false);
  const [removeProfileImage, setRemoveProfileImage] = useState(false);
  const [fileSelectIsLoading, setFileSelectIsLoading] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [clientName, setClientName] = useState("");
  const [clientNameIsValid, setClientNameIsValid] = useState(false);
  const [legalEntityName, setLegalEntityName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [accManager, setAccManager] = useState("");
  const [accManagerDropdownOpen, setAccManagerDropdownOpen] = useState(false);
  const [userSearchTerm, setUserSearchTerm] = useState("");
  const [accManagerIsValid, setAccManagerIsValid] = useState(false);
  const [contactPoint, setContactPoint] = useState("");
  const [contactPointDropdownOpen, setContactPointDropdownOpen] =
    useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  // const [contactPointSearchTerm, setContactPointSearchTerm] = useState('');
  const [contactPointIsValid, setContactPointIsValid] = useState(false);
  const [companyWebsite, setCompanyWebsite] = useState("");
  const [isValidUrl, setIsValidUrl] = useState(false);
  const [companyLogo, setCompanyLogo] = useState(null);
  const [country, setCountry] = useState("");
  const [countryIsValid, setCountryIsValid] = useState(true);
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [pincode, setPincode] = useState("");
  const [vatNumber, setVatNumber] = useState("");
  const [billingContactName, setBillingContactName] = useState("");
  const [vatPercentage, setVatPercentage] = useState("");
  const [billingContactPosition, setBillingContactPosition] = useState("");
  const [billContPosIsValid, setBillContPosIsValid] = useState(false);
  const [preferredCurrency, setPreferredCurrency] = useState("");
  const [currencyIsValid, setCurrencyIsValid] = useState(false);
  const [billingContactEmail, setBillingContactEmail] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [signatoryUserName, setSignatoryUserName] = useState("");
  const [signatoryEmail, setSignatoryEmail] = useState("");
  const [signatoryUserPosition, setSignatoryUserPosition] = useState("");
  const [signUserPosIsValid, setSignUserPosIsValid] = useState(false);
  const [signatoryMobileNo, setSignatoryMobileNo] = useState("");
  const [pocUserName, setPocUserName] = useState("");
  const [pocEmail, setPocEmail] = useState("");
  const [pocUserPosition, setPocUserPosition] = useState("");
  const [pocUserPosIsValid, setPocUserPosIsValid] = useState(false);
  const [additionalInfo, setAdditionalInfo] = useState("");
  const [countrySearchTerm, setCountrySearchTerm] = useState("");
  const [countryDropdownOpen, setCountryDropdownOpen] = useState(false);
  const [countriesList, setCountriesList] = useState([]);
  const [updatedClientProfiles, setUpdatedClientProfiles] = useState([]);
  const [isCompanyInfoOpen, setCompanyInfoOpen] = useState(true);
  const [isAddressOpen, setAddressOpen] = useState(true);
  const [isBillingOpen, setBillingOpen] = useState(true);
  const [isSignatoryOpen, setSignatoryOpen] = useState(true);
  const [isPointOfContactOpen, setPointOfContactOpen] = useState(true);
  const [currencyDropdownOpen, setCurrencyDropdownOpen] = useState(false);
  const [signatoryPositionDropdownOpen, setSignatoryPositionDropdownOpen] =
    useState(false);
  const [positionContactDropdownOpen, setPositionContactDropdownOpen] =
    useState(false);
  const [currency, setCurrency] = useState("");
  const [currencySearchTerm, setCurrencySearchTerm] = useState("");
  const [currencyList, setCurrencyList] = useState([]);
  const currencyDropdownRef = useRef(null);
  const clientNameRef = useRef(null);
  const compWebsiteRef = useRef(null);
  const countryRef = useRef(null);
  const billContPosRef = useRef(null);
  const currencyRef = useRef(null);
  const signUserPosRef = useRef(null);
  const pocUserPosRef = useRef(null);
  const signatoryPositionDropdownRef = useRef(null);
  const positionContactDropdownRef = useRef(null);
  const countryDropdownRef = useRef(null);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [paymentMethodDropdownOpen, setPaymentMethodDropdownOpen] =
    useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const paymentMethodDropdownRef = useRef(null);
  const user = useSelector((state) => state.auth.user);
  const { appSettings } = useSelector((state) => state.appSettings);
  let token = null;
  if (user) {
    if (user && user.token) {
      token = user.token;
    }
  }

  const { data: clientView = [], isLoading, refetch: refetchClientProfiles, isError: clientViewError, } = useClientView(props.id, token);

  useEffect(() => {
    if (!isLoading && clientView) {
      setClientName(clientView.client_name);
      if (clientView.legal_entity_name) {
        setLegalEntityName(clientView.legal_entity_name);
      } else {
        setLegalEntityName("");
      }
      setEmail(clientView.email);
      setMobileNo(clientView.mobile_no);
      if (clientView.acc_manager) {
        setAccManager(clientView.acc_manager._id);
      } else {
        setAccManager("");
      }
      setUserSearchTerm("");
      setAccManagerDropdownOpen(false);
      setAccManagerIsValid(false);
      if (clientView.contact_point) {
        setContactPoint(clientView.contact_point._id);
      } else {
        setContactPoint(null);
      }
      setContactPointDropdownOpen(false);
      setContactPointIsValid(false);
      setAddress(clientView.address);
      setCountry(clientView.country);
      if (clientView.city) {
        setCity(clientView.city);
      } else {
        setCity("");
      }
      if (clientView.pincode) {
        setPincode(clientView.pincode);
      } else {
        setPincode("");
      }
      if (clientView.vat_number) {
        setVatNumber(clientView.vat_number);
      } else {
        setVatNumber("");
      }
      if (clientView.billing_contact_name) {
        setBillingContactName(clientView.billing_contact_name);
      } else {
        setBillingContactName("");
      }
      if (clientView.vat_percentage) {
        setVatPercentage(clientView.vat_percentage);
      } else {
        setVatPercentage("");
      }
      if (clientView.billing_contact_position) {
        setBillingContactPosition(clientView.billing_contact_position);
      } else {
        setBillingContactPosition("");
      }
      if (clientView.preferred_currency) {
        setPreferredCurrency(clientView.preferred_currency);
      } else {
        setPreferredCurrency("");
      }
      if (clientView.billing_contact_email) {
        setBillingContactEmail(clientView.billing_contact_email);
      } else {
        setBillingContactEmail("");
      }
      if (clientView.payment_method) {
        setPaymentMethod(clientView.payment_method);
      } else {
        setPaymentMethod("");
      }
      if (clientView.company_website) {
        setCompanyWebsite(clientView.company_website);
      } else {
        setCompanyWebsite("");
      }
      if (clientView.signatory_user_name) {
        setSignatoryUserName(clientView.signatory_user_name);
      } else {
        setSignatoryUserName("");
      }
      if (clientView.signatory_email) {
        setSignatoryEmail(clientView.signatory_email);
      } else {
        setSignatoryEmail("");
      }
      if (clientView.signatory_user_position) {
        setSignatoryUserPosition(clientView.signatory_user_position);
      } else {
        setSignatoryUserPosition("");
      }
      if (clientView.signatory_mobile_no) {
        setSignatoryMobileNo(clientView.signatory_mobile_no);
      } else {
        setSignatoryMobileNo("");
      }
      if (clientView.poc_user_name) {
        setPocUserName(clientView.poc_user_name);
      } else {
        setPocUserName("");
      }
      if (clientView.poc_email) {
        setPocEmail(clientView.poc_email);
      } else {
        setPocEmail("");
      }
      if (clientView.poc_user_position) {
        setPocUserPosition(clientView.poc_user_position);
      } else {
        setPocUserPosition("");
      }
      // setCompanyLogoName(clientView.company_logo);
      setAdditionalInfo(clientView.additional_info);
    }
  }, [isLoading, clientView]);

  const togglePaymentMethodDropdown = () => {
    setPaymentMethodDropdownOpen(!paymentMethodDropdownOpen);
  };
  const selectPaymentMethodOption = (selectedPaymentMethod) => {
    setPaymentMethod(selectedPaymentMethod);
    setPaymentMethodDropdownOpen(false);
  };

  const [billingContactDropdownOpen, setBillingContactDropdownOpen] =
    useState(false);
  const billingContactDropdownRef = useRef(null);

  useEffect(() => {
    setCurrencyList(Currencies.sourceCurrencies[0].targetCurrencies);
    const sortedCountries = Countries.slice().sort((a, b) =>
      a.country.localeCompare(b.country)
    );
    setCountriesList(sortedCountries);
  }, []);

  const handleCurrencySearchInputChange = (e) => {
    setCurrencySearchTerm(e.target.value);
  };

  const filteredCurrencies = currencyList.filter((currency) => {
    const currencyName =
      currency.currencyCode &&
      `${currency.currencyCode} (${currency.currencyName})`;
    return currencyName
      ?.toLowerCase()
      .includes(currencySearchTerm?.toLowerCase());
  });

  const handleCountryChange = (selectedCountry) => {
    setCountry(selectedCountry);
    setCountryDropdownOpen(false);
  };

  const handleCountrySearchInputChange = (e) => {
    setCountrySearchTerm(e.target.value);
  };

  const filteredCountries = countriesList.filter((country) => {
    return country.country
      ?.toLowerCase()
      .includes(countrySearchTerm?.toLowerCase());
  });

  const handleToggleCountryDropdown = () => {
    setCountrySearchTerm("");
    setCountryDropdownOpen(!countryDropdownOpen);
  };

  const handleClickOutsideDropdown = (event) => {
    const isClickInsideCountryDropdown =
      countryDropdownRef.current &&
      countryDropdownRef.current.contains(event.target);
    const isClickInsideCurrencyDropdown =
      currencyDropdownRef.current &&
      currencyDropdownRef.current.contains(event.target);
    const isClickInsidePaymentMethodDropdown =
      paymentMethodDropdownRef.current &&
      paymentMethodDropdownRef.current.contains(event.target);
    const isClickInsideBillingContactDropdown =
      billingContactDropdownRef.current &&
      billingContactDropdownRef.current.contains(event.target);
    const isClickInsideSignatoryPositionDropdown =
      signatoryPositionDropdownRef.current &&
      signatoryPositionDropdownRef.current.contains(event.target);
    const isClickInsidePositionContactDropdown =
      positionContactDropdownRef.current &&
      positionContactDropdownRef.current.contains(event.target);

    if (!isClickInsideCountryDropdown) {
      setCountryDropdownOpen(false);
    }
    if (!isClickInsideCurrencyDropdown) {
      setCurrencyDropdownOpen(false);
    }
    if (!isClickInsidePaymentMethodDropdown) {
      setPaymentMethodDropdownOpen(false);
    }
    if (!isClickInsideBillingContactDropdown) {
      setBillingContactDropdownOpen(false);
    }
    if (!isClickInsideSignatoryPositionDropdown) {
      setSignatoryPositionDropdownOpen(false);
    }
    if (!isClickInsidePositionContactDropdown) {
      setPositionContactDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutsideDropdown);
    return () => {
      document.removeEventListener("click", handleClickOutsideDropdown);
    };
  }, []);

  const numberInputOnWheelPreventChange = (e) => {
    // Prevent the input value change
    e.target.blur();

    // Prevent the page/container scrolling
    e.stopPropagation();

    setTimeout(() => {
      e.target.focus();
    }, 0);
  };

  const goToEditProfile = (e) => {
    setProfileEdit(true);
  };

  const handleCancelEdit = () => {
    setProfileEdit(false);
  };

  return (
    <>
      {profileEdit ? (
        <>
          <ClientUserProfileEdit id={props.id} onCancel={handleCancelEdit} />
        </>
      ) : (
        <div className="profile-details">

          <div className="profile-photo-outer">
            <div className="profile-photo">
              <div className="profile-photo-inner">
                <div className="profile-photo-sec">
                  {isLoading ? (
                    <div>
                      <ThreeDots
                        height="18"
                        width="20"
                        radius="9"
                        color="#6479f9"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                      />
                    </div>
                  ) : (
                    <div className="profile-image">
                      {clientView.company_logo !== "null" &&
                        clientView.company_logo !== null ? (
                        <img
                          src={`/uploads/clientCompyLogo/lowQuality/${clientView.company_logo}`}
                          alt="User Profile "
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src =
                              "/uploads/clientCompyLogo/lowQuality/DefaultCompLogo.png";
                          }}
                        />
                      ) : (
                        <img
                          src={profile_image}
                          alt="User Profile Image"
                        />
                      )}
                    </div>
                  )}
                </div>

                <div className="profile-name">
                  {isLoading ? (
                    <div>
                      <ThreeDots
                        height="18"
                        width="20"
                        radius="9"
                        color="#6479f9"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                      />
                    </div>
                  ) : (
                    <div className="profile-name-sec">
                      {clientView.client_name && clientView.client_name}
                    </div>
                  )}
                  <div class="profile-type">Client </div>
                  {isLoading ? (
                    <div>
                      <ThreeDots
                        height="18"
                        width="20"
                        radius="9"
                        color="#6479f9"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                      />
                    </div>
                  ) : (
                    <div className="profile-status">
                      {clientView && clientView.is_active === false ? (
                        <div className="inactive inactive" >
                          Inactive
                        </div>
                      ) : clientView.is_active === true ? (
                        <div className="active">
                          Active
                        </div>
                      ) : (
                        clientView.is_active
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
            {isLoading ? (
              <div>
                <ThreeDots
                  height="18"
                  width="20"
                  radius="9"
                  color="#6479f9"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
              </div>
            ) : (
              <div className="profile-button">
                <a onClick={goToEditProfile} className="edit-profile">
                  <span class="bt-ico">
                    <img src={Edit} alt="edit" />
                  </span>
                  Edit Profile
                </a>{" "}
              </div>
            )}
          </div >

          <div className="information-outer-section">
            <div className="information-inner">
              <div className="information">
                <div className="information-title">
                  <span>Company Information</span>
                </div>
                <div className="information-outer">
                  <div className="information-left">
                    {clientView.client_name && (
                      <div className="inner-detail-outer">
                        <div className="inner-detail">
                          <div className="icon">
                            <img src={SingleUser} alt="single-user" />
                          </div>
                          <div className="inner-detail-inner">
                            <label>Commercial Name *</label>
                          </div>
                        </div>
                        <div className="inner-detail">
                          <span className="icon"></span>
                          <div className="inner-detail-inner">
                            {isLoading ? (
                              <ThreeDots
                                height="18"
                                width="20"
                                radius="9"
                                color="#6479f9"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                              />
                            ) : (
                              <div className="value-field">{clientView.client_name}</div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                    {clientView.legal_entity_name && (
                      <div className="inner-detail-outer">
                        <div className="inner-detail">
                          <div className="icon">
                            <img src={SingleUser} alt="single-user" />
                          </div>
                          <div className="inner-detail-inner">
                            <label>Legal Entity Name </label>
                          </div>
                        </div>
                        <div className="inner-detail">
                          <span className="icon"></span>
                          <div className="inner-detail-inner">
                            {isLoading ? (
                              <ThreeDots
                                height="18"
                                width="20"
                                radius="9"
                                color="#6479f9"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                              />
                            ) : (
                              <div className="value-field">{clientView.legal_entity_name}</div>)}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="right-section">
                    {clientView.email && (
                      <div className="inner-detail-outer">
                        <div className="inner-detail">
                          <span class="icon">
                            <img src={EmailIcon} alt="email-icon" />
                          </span>

                          <div className="inner-detail-inner">
                            <label>Email</label>
                          </div>
                        </div>
                        <div className="inner-detail">
                          <span className="icon"></span>
                          <div className="inner-detail-inner">
                            {isLoading ? (
                              <ThreeDots
                                height="18"
                                width="20"
                                radius="9"
                                color="#6479f9"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                              />
                            ) : (
                              <div className="value-field">{clientView.email}</div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                    {clientView.company_website && (
                      <div className="inner-detail-outer">
                        <div className="inner-detail">
                          <div className="icon">
                            <img src={CompanyUrl} alt="company-url" />
                          </div>
                          <div className="inner-detail-inner">
                            <label>Company Url</label>
                          </div>
                        </div>
                        <div className="inner-detail">
                          <span className="icon"></span>
                          <div className="inner-detail-inner">
                            {isLoading ? (
                              <ThreeDots
                                height="18"
                                width="20"
                                radius="9"
                                color="#6479f9"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                              />
                            ) : (
                              <div className="value-field1">{clientView.company_website}</div>)}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="information-inner">
              <div className="information">
                <div className="information-title" >
                  <span>Address</span>
                </div>
                <div className="information-outer">
                  <div className="information-left">
                    {clientView.city && (
                      <div className="inner-detail-outer">
                        <div className="inner-detail">
                          <div className="icon">
                            <img src={LocationIcon} alt="location-icon" />
                          </div>

                          <div className="inner-detail-inner">
                            <label>City</label>
                          </div>
                        </div>
                        <div className="inner-detail">
                          <span className="icon"></span>
                          <div className="inner-detail-inner">
                            {isLoading ? (
                              <ThreeDots
                                height="18"
                                width="20"
                                radius="9"
                                color="#6479f9"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                              />
                            ) : (
                              <div className="value-field">{clientView.city}</div>)}
                          </div>
                        </div>
                      </div>
                    )}
                    {clientView.country && (
                      <div className="inner-detail-outer">
                        <div className="inner-detail">
                          <div className="icon">
                            <img src={LocationIcon} alt="location-icon" />
                          </div>
                          <div className="inner-detail-inner">
                            <label>Country</label>
                          </div>
                        </div>
                        <div className="inner-detail">
                          <span class="icon"></span>
                          <div className="inner-detail-inner">
                            {isLoading ? (
                              <ThreeDots
                                height="18"
                                width="20"
                                radius="9"
                                color="#6479f9"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                              />
                            ) : (
                              <div className="value-field">{clientView.country}</div>)}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="right-section">
                    {clientView.address && (
                      <div className="inner-detail-outer">
                        <div className="inner-detail">
                          <div className="icon">
                            <img src={LocationIcon} alt="location-icon" />
                          </div>
                          <div className="inner-detail-inner">
                            <label>Address</label>
                          </div>
                        </div>
                        <div className="inner-detail">
                          <span className="icon"></span>
                          <div className="inner-detail-inner">
                            {isLoading ? (
                              <ThreeDots
                                height="18"
                                width="20"
                                radius="9"
                                color="#6479f9"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                              />
                            ) : (
                              <div className="value-field1">{clientView.address}</div>)}
                          </div>
                        </div>
                      </div>
                    )}
                    {clientView.pincode && (
                      <div className="inner-detail-outer">
                        <div className="inner-detail">
                          <div className="icon">
                            <img src={PostalIcon} alt="postal-icon" />
                          </div>

                          <div className="inner-detail-inner">
                            <label>Postal Code</label>
                          </div>
                        </div>
                        <div className="inner-detail">
                          <span className="icon"></span>
                          <div className="inner-detail-inner">
                            {isLoading ? (
                              <ThreeDots
                                height="18"
                                width="20"
                                radius="9"
                                color="#6479f9"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                              />
                            ) : (
                              <div className="value-field">{clientView.pincode}</div>)}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="information-inner">
              <div className="information">
                <div className="information-title" >
                  <span>Billing Information</span>
                </div>
                <div className="information-outer">
                  <div className="information-left">
                    {clientView.vat_number && (
                      <div className="inner-detail-outer">
                        <div className="inner-detail">
                          <div className="icon">
                            <img src={PassportIcon} alt="passport-icon" />
                          </div>

                          <div className="inner-detail-inner">
                            <label>Company VAT Number</label>
                          </div>
                        </div>
                        <div className="inner-detail">
                          <span class="icon"></span>
                          <div className="inner-detail-inner">
                            {isLoading ? (
                              <ThreeDots
                                height="18"
                                width="20"
                                radius="9"
                                color="#6479f9"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                              />
                            ) : (
                              <div className="value-field">{clientView.vat_number}</div>)}
                          </div>
                        </div>
                      </div>
                    )}
                    {clientView.vat_percentage > 0 && (
                      <div className="inner-detail-outer">
                        <div className="inner-detail">
                          <div className="icon">
                            <img src={PassportIcon} alt="passport-icon" />
                          </div>

                          <div className="inner-detail-inner">
                            <label>Company VAT applicable Percentage</label>
                          </div>
                        </div>

                        <div className="inner-detail">
                          <span class="icon"></span>
                          <div className="inner-detail-inner">
                            {isLoading ? (
                              <ThreeDots
                                height="18"
                                width="20"
                                radius="9"
                                color="#6479f9"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                              />
                            ) : (
                              <div className="value-field">{clientView.vat_percentage}</div>)}
                          </div>
                        </div>

                      </div>
                    )}
                    {clientView.preferred_currency && (
                      <div className="inner-detail-outer">
                        <div className="inner-detail">
                          <div className="icon">
                            <img src={PaymentIcon} alt="payment-icon" />
                          </div>

                          <div className="inner-detail-inner">
                            <label>Preferred Currency</label>
                          </div>
                        </div>

                        <div className="inner-detail">
                          <span class="icon"></span>
                          <div className="inner-detail-inner">
                            {isLoading ? (
                              <ThreeDots
                                height="18"
                                width="20"
                                radius="9"
                                color="#6479f9"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                              />
                            ) : (
                              <div className="value-field">{clientView.preferred_currency}</div>)}
                          </div>
                        </div>
                      </div>
                    )}
                    {clientView.payment_method && (
                      <div className="inner-detail-outer">
                        <div className="inner-detail">
                          <div className="icon">
                            <img src={CostIcon} alt="cost-icon" />
                          </div>
                          <div className="inner-detail-inner">
                            <label>Preferred Payment Method</label>
                          </div>
                        </div>
                        <div className="inner-detail">
                          <span class="icon"></span>
                          <div className="inner-detail-inner">
                            {isLoading ? (
                              <ThreeDots
                                height="18"
                                width="20"
                                radius="9"
                                color="#6479f9"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                              />
                            ) : (
                              <div className="value-field">{clientView.payment_method}</div>)}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="right-section">
                    {clientView.billing_contact_name && (
                      <div className="inner-detail-outer">
                        <div className="inner-detail">
                          <div className="icon">
                            <img src={SingleUser} alt="single-user" />
                          </div>
                          <div className="inner-detail-inner">
                            <label>Billing contact - Full Name</label>
                          </div>
                        </div>
                        <div className="inner-detail">
                          <span className="icon"></span>
                          <div className="inner-detail-inner">
                            {isLoading ? (
                              <ThreeDots
                                height="18"
                                width="20"
                                radius="9"
                                color="#6479f9"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                              />
                            ) : (
                              <div className="value-field">{clientView.billing_contact_name}</div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                    {clientView.billing_contact_position && (
                      <div className="inner-detail-outer">
                        <div className="inner-detail">
                          <div className="icon">
                            <img src={CompanyIcon} alt="company-icon" />
                          </div>
                          <div className="inner-detail-inner">
                            <label>Billing contact - Position</label>
                          </div>
                        </div>
                        <div className="text-section">
                          <div className="inner-detail">
                            <span className="icon"></span>
                            <div className="inner-detail-inner">
                              {isLoading ? (
                                <ThreeDots
                                  height="18"
                                  width="20"
                                  radius="9"
                                  color="#6479f9"
                                  ariaLabel="three-dots-loading"
                                  wrapperStyle={{}}
                                  wrapperClassName=""
                                  visible={true}
                                />
                              ) : (
                                <div className="value-field">{clientView.billing_contact_position}</div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {clientView.billing_contact_email && (
                      <div className="inner-detail-outer">
                        <div className="inner-detail">
                          <div className="icon">
                            <img src={EmailIcon} alt="email-icon" />
                          </div>

                          <div className="inner-detail-inner">
                            <label>Billing contact - Email Address</label>
                          </div>
                        </div>
                        <div className="inner-detail">
                          <span className="icon"></span>
                          <div className="inner-detail-inner">
                            {isLoading ? (
                              <ThreeDots
                                height="18"
                                width="20"
                                radius="9"
                                color="#6479f9"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                              />
                            ) : (
                              <div className="value-field">{clientView.billing_contact_email}</div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                    {clientView.mobile_no && (
                      <div className="inner-detail-outer">
                        <div className="inner-detail">
                          <div className="icon">
                            <img src={PhoneIcon} alt="Phone-icon" />
                          </div>
                          <div className="inner-detail-inner">
                            <label>Contact Number</label>
                          </div>
                        </div>
                        <div className="inner-detail">
                          <span className="icon"></span>
                          <div className="inner-detail-inner">
                            {isLoading ? (
                              <ThreeDots
                                height="18"
                                width="20"
                                radius="9"
                                color="#6479f9"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                              />
                            ) : (
                              <div className="value-field">{clientView.mobile_no}</div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="information-inner">
              <div className="information">
                <div className="information-title" >
                  <span>Signatory Information</span>
                </div>
                <div className="information-outer">
                  <div className="information-left">
                    {clientView.signatory_user_name && (
                      <div className="inner-detail-outer">
                        <div className="inner-detail">
                          <div className="icon">
                            <img src={SingleUser} alt="single-user" />
                          </div>
                          <div className="inner-detail-inner">
                            <label>Full Name</label>
                          </div>
                        </div>
                        <div className="inner-detail">
                          <span className="icon"></span>
                          <div className="inner-detail-inner">
                            {isLoading ? (
                              <ThreeDots
                                height="18"
                                width="20"
                                radius="9"
                                color="#6479f9"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                              />
                            ) : (
                              <div className="value-field">{clientView.signatory_user_name}</div>)}
                          </div>
                        </div>
                      </div>
                    )}
                    {clientView.signatory_user_position && (
                      <div className="inner-detail-outer">
                        <div className="inner-detail">
                          <div className="icon">
                            <img src={CompanyIcon} alt="company-icon" />
                          </div>

                          <div className="inner-detail-inner">
                            <label>Position</label>
                          </div>
                        </div>
                        <div className="text-section">
                          <div className="inner-detail">
                            <span className="icon"></span>
                            <div className="inner-detail-inner">
                              {isLoading ? (
                                <ThreeDots
                                  height="18"
                                  width="20"
                                  radius="9"
                                  color="#6479f9"
                                  ariaLabel="three-dots-loading"
                                  wrapperStyle={{}}
                                  wrapperClassName=""
                                  visible={true}
                                />
                              ) : (
                                <div className="value-field">{clientView.signatory_user_position}</div>)}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="right-section">
                    {clientView.signatory_email && (
                      <div className="inner-detail-outer">
                        <div className="inner-detail">
                          <div className="icon">
                            <img src={EmailIcon} alt="email-icon" />
                          </div>
                          <div className="inner-detail-inner">
                            <label>Email</label>
                          </div>
                        </div>
                        <div className="inner-detail">
                          <span className="icon"></span>
                          <div className="inner-detail-inner">
                            {isLoading ? (
                              <ThreeDots
                                height="18"
                                width="20"
                                radius="9"
                                color="#6479f9"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                              />
                            ) : (
                              <div className="value-field">{clientView.signatory_email}</div>)}
                          </div>
                        </div>
                      </div>
                    )}
                    {clientView.signatory_mobile_no && (
                      <div className="inner-detail-outer">
                        <div className="inner-detail">
                          <div className="icon">
                            <img src={PhoneIcon} alt="phone-icon" />
                          </div>
                          <div className="inner-detail-inner">
                            <label>Contact Number</label>
                          </div>
                        </div>
                        <div className="inner-detail">
                          <span className="icon"></span>
                          <div className="inner-detail-inner">
                            {isLoading ? (
                              <ThreeDots
                                height="18"
                                width="20"
                                radius="9"
                                color="#6479f9"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                              />
                            ) : (
                              <div className="value-field">{clientView.signatory_mobile_no}</div>)}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="information-inner">
              <div className="information">
                <div className="information-title">
                  <span>Point of Contact</span>
                </div>

                <div className="information-outer">
                  <div className="information-left">
                    {clientView.poc_user_name && (
                      <div className="inner-detail-outer">
                        <div className="inner-detail">
                          <div className="icon">
                            <img src={SingleUser} alt="single-user" />
                          </div>

                          <div className="inner-detail-inner">
                            <label>Full Name</label>
                          </div>
                        </div>
                        <div className="inner-detail">
                          <span className="icon"></span>
                          <div className="inner-detail-inner">
                            {isLoading ? (
                              <ThreeDots
                                height="18"
                                width="20"
                                radius="9"
                                color="#6479f9"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                              />
                            ) : (
                              <div className="value-field">{clientView.poc_user_name}</div>)}
                          </div>
                        </div>
                      </div>
                    )}
                    {clientView.poc_user_position && (
                      <div className="inner-detail-outer">
                        <div className="inner-detail">
                          <div className="icon">
                            <img src={CompanyIcon} alt="company-icon" />
                          </div>
                          <div className="inner-detail-inner">
                            <label>Position</label>
                          </div>
                        </div>
                        <div className="text-section">
                          <div className="inner-detail">
                            <span className="icon"></span>
                            <div className="inner-detail-inner">
                              {isLoading ? (
                                <ThreeDots
                                  height="18"
                                  width="20"
                                  radius="9"
                                  color="#6479f9"
                                  ariaLabel="three-dots-loading"
                                  wrapperStyle={{}}
                                  wrapperClassName=""
                                  visible={true}
                                />
                              ) : (
                                <div className="value-field">{clientView.poc_user_position}</div>)}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="right-section">
                    {clientView.poc_email && (
                      <div className="inner-detail-outer">
                        <div className="inner-detail">
                          <div className="icon">
                            <img src={EmailIcon} alt="email-icon" />
                          </div>
                          <div className="inner-detail-inner">
                            <label>Email</label>
                          </div>
                        </div>
                        <div className="inner-detail">
                          <span className="icon"></span>
                          <div className="inner-detail-inner">
                            {isLoading ? (
                              <ThreeDots
                                height="18"
                                width="20"
                                radius="9"
                                color="#6479f9"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                              />
                            ) : (
                              <div className="value-field">{clientView.poc_email}</div>)}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="information-inner bottom-save-sec">
              <div className="information">
                <div className="information-title">
                  <span>Additional Notes</span>
                </div>

                <div className="additional-notes-section">
                  <div className="total-section">
                    {isLoading ? (
                      <ThreeDots
                        height="18"
                        width="20"
                        radius="9"
                        color="#6479f9"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                      />
                    ) : (
                      <div className="value-field">{clientView.additional_info}</div>)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div >
      )}
    </>
  );
};

export default ClientUserProfile;