export function dateConvert(rowData){
  const [isoDate, desiredFormat] = rowData.inv_due_date.split("/");
  const dateObj = new Date(isoDate);
  const options = { month: "long", day: "numeric", year: "numeric" };
  return dateObj.toLocaleDateString("en-US", options);
}

export function dateCliInvConvert(rowData){
  const [isoDate, desiredFormat] = rowData.cli_inv_due_date.split("/");
  const dateObj = new Date(isoDate);
  const options = { month: "long", day: "numeric", year: "numeric" };
  return dateObj.toLocaleDateString("en-US", options);
}

export function dateVenInvConvert(rowData){
  const [isoDate, desiredFormat] = rowData.ven_inv_due_date.split("/");
  const dateObj = new Date(isoDate);
  const options = { month: "long", day: "numeric", year: "numeric" };
  return dateObj.toLocaleDateString("en-US", options);
}

export function createDateConvert(rowData){
  const [isoDate, desiredFormat] = rowData.inv_created_at.split("/");
  const dateObj = new Date(isoDate);
  const options = { month: "long", day: "numeric", year: "numeric" };
  return dateObj.toLocaleDateString("en-US", options);
}

export function createDateCliInvConvert(rowData){
  const [isoDate, desiredFormat] = rowData.cli_inv_created_at.split("/");
  const dateObj = new Date(isoDate);
  const options = { month: "long", day: "numeric", year: "numeric" };
  return dateObj.toLocaleDateString("en-US", options);
}

export function createDateVenInvConvert(rowData){
  const [isoDate, desiredFormat] = rowData.ven_inv_created_at.split("/");
  const dateObj = new Date(isoDate);
  const options = { month: "long", day: "numeric", year: "numeric" };
  return dateObj.toLocaleDateString("en-US", options);
}

export function dateMasterCreateConvert(rowData){
  const [isoDate, desiredFormat] = rowData.created_at.split("/");
  const dateObj = new Date(isoDate);
  const options = { month: "long", day: "numeric", year: "numeric" };
  return dateObj.toLocaleDateString("en-US", options);
}


