import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import timerDataService from "./timerDataService";

const initialState = {
  timerDataState: null
};

export const setTimerData = createAsyncThunk(
  "timerData/setTimerData",
   (timerData) => {
    return Date.now();
  }
);

export const timerDataSlice = createSlice({
  name: "timerData",
  initialState,
  reducers: {
    reset: (state) => {
      state.timerDataState = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setTimerData.fulfilled, (state, action) => {
      state.timerDataState = action.payload;
    });
  },
});

export const { reset } = timerDataSlice.actions;
export default timerDataSlice.reducer;
