import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useParams, useNavigate } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import Select from "react-select";
import AES from "crypto-js/aes";
import axios from "axios";
import Modal from "react-modal";
import "./ProjectOverviewListingSingleDetails.css";
import editImage from "../../../../images/singleinvoice/edit.svg";
import Delete from "../../../../images/icons/delete.svg";
import Close from "../../../../images/icons/black-close.svg";
import Tick from "../../../../images/singleinvoice/tick-green.svg";
import profile_image from "../../../../images/user/Default_pfp.png";
import crssrImage from "../../../../images/singleinvoice/cross-red.svg";
import LeftArrow from "../../../../images/icons/blue-left-arrow.svg";
import { useClientView } from "../../../../components/TimeTracker/TimeTrackerClient/useClientDetails.jsx";
import { setProjectAdded } from "../../../../features/projects/projectSlice.js";
import { setNotification } from "../../../../features/notification/notificationSlice.js";
import { logout, reset } from "../../../../features/auth/authSlice";
import { reset as invoiceReset } from "../../../../features/invoice/invoiceSlice.js";
import { reset as headerReset } from "../../../../features/headerText/headerTextSlice.js";
import { reset as appSettingsReset } from "../../../../features/appSettings/appSettingsSlice.js";
import { useGetClients } from "../../../../components/TimeTracker/TimeTrackerOverview/useTimeTracker.jsx";
import ProjectOverview from "./ProjectOverviewListingSingleActivities.jsx";
import SprintTable from "./SprintTable";
import { Link } from "react-router-dom";
import { useFetchClientProjectManager } from "../../../../components/TimeTracker/TimeTrackerProject/useProjectDetails.jsx";
import TruncateUserName from "../../../../components/helpers/truncateUserName.js";
import Teams from "../../details/modules/Teams.jsx";
import { useProjectDetails } from "../../../../features/projects/ProjectList.jsx";

const ProjectDetails = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { projectId } = useParams();
  const [projectName, setProjectName] = useState("");
  const [clientName, setClientName] = useState("");
  const [projectDiscription, setProjectDiscription] = useState("");
  const [billable, setBillable] = useState(true);
  // const [budget, setBudget] = useState("");
  const [totalHours, setTotalHours] = useState("");
  const [selectedProjectManagers, setSelectedProjectManagers] = useState([]);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  // const [countriesList, setCountriesList] = useState([]);
  const [isProjectDeleteModalOpen, setIsProjectDeleteModalOpen] =
    useState(false);
  const [clientNameIsValid, setClientNameIsValid] = useState(false);
  const [projectNameIsValid, setProjectNameIsValid] = useState(false);
  // const [budgetIsValid, setBudgetIsValid] = useState(false);
  const projectNameRef = useRef(null);
  const clientNameRef = useRef(null);
  // const budgetRef = useRef(null);
  const clientDropdownRef = useRef(null);
  const clientCurrency = props.clientCurrency;


  const user = useSelector((state) => state.auth.user);
  // const { appSettings } = useSelector((state) => state.appSettings);
  let token = null;
  if (user) {
    if (user && user.token) {
      token = user.token;
    }
  }

  const {
    data: clients = [],
    // isLoading: clientLoading,
    // isError: clientError,
  } = useGetClients(token);
  const {
    data: project = [],
    isLoading: isLoadingProject,
    refetch: refetchProject,
  } = useProjectDetails(token, projectId);
  const {
    data: userId = [],
    // isLoading: isUserLoading,
    // refetch: refetchProjectManager,
  } = useFetchClientProjectManager(token, props.id);
  const {
    data: clientView = [],
    isLoading,
    isError: clientViewError,
  } = useClientView(props.id, token);

  useEffect(() => {
    // setSelectedClient(props.id);
    // const sortedCountries = Countries.slice().sort((a, b) => a.country.localeCompare(b.country));
    // setCountriesList(sortedCountries);
    refetchProject();
    if (clientViewError) {
      if (clientViewError.response?.data?.status === "deleted") {
        // Handle the case where the user is deleted
        localStorage.removeItem("user");
        dispatch(logout());
        dispatch(reset());
        dispatch(invoiceReset());
        dispatch(headerReset());
        dispatch(appSettingsReset());
        toast.error("This Client is deleted!", {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/");
        return;
      }
      // Handle other errors
      alert(`Error fetching users`);
    }
  }, [projectId]);

  const handleClickOutsideDropdown = (event) => {
    // const isClickInsideCountryDropdown = countryDropdownRef.current && countryDropdownRef.current.contains(event.target);
    // const isClickInsideClientDropdown = clientDropdownRef.current && clientDropdownRef.current.contains(event.target);
    // const isClickInsideAccManagerSelectDropdown = accManagerSelectDropdownRef.current && accManagerSelectDropdownRef.current.contains(event.target);
    // const isClickInsideContactPointSelectDropdown = contactPointSelectDropdownRef.current && contactPointSelectDropdownRef.current.contains(event.target);
    // if (!isClickInsideCountryDropdown) {
    //     setCountryDropdownOpen(false);
    // }
    // if (!isClickInsideClientDropdown) {
    //     setClientDropdownOpen(false);
    // }
    // if (!isClickInsideAccManagerSelectDropdown) {
    //     setAccManagerDropdownOpen(false);
    // }
    // if (!isClickInsideContactPointSelectDropdown) {
    //     setContactPointDropdownOpen(false);
    // }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutsideDropdown);
    return () => {
      document.removeEventListener("click", handleClickOutsideDropdown);
    };
  }, []);

  //   const handleOpenModal = () => {
  //     setIsModalOpen(true);
  //   };

  const projectManagerOptions = userId
    .slice()
    .sort((a, b) => {
      const nameA = a.first_name
        ? `${a.first_name} ${a.middle_name || ""} ${a.last_name}`
        : `${a.company_first_name
          ? a.company_first_name +
          (a.company_last_name && a.company_last_name)
          : a.company_name
        }`;
      const nameB = b.first_name
        ? `${b.first_name} ${b.middle_name || ""} ${b.last_name}`
        : `${b.company_first_name
          ? b.company_first_name +
          (b.company_last_name && b.company_last_name)
          : b.company_name
        }`;
      return nameA.localeCompare(nameB);
    })
    .map((item) => ({
      label: item.first_name
        ? `${item.first_name} ${item.middle_name || ""} ${item.last_name}`
        : item.company_first_name
          ? `${item.company_first_name +
          (item.company_last_name && item.company_last_name)
          }`
          : `${item.company_name}(Company)`,
      value: item._id,
    }));

  const StatusChangeActive = async (id) => {
    try {
      setIsSubmitLoading(true);
      await axios.get(`/api/project/status-change/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      toast.success(
        `Project ${project?.project_name} has been deactivated successfully!`,
        {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      global.config.activityLog(
        window.location.href,
        "User",
        `Project ${project?.project_name} has been deactivated successfully!`
      );
      dispatch(setNotification(Date.now()));
      dispatch(setProjectAdded());
    } catch (error) {
      console.log("Something went wrong while deactivating project: ", error);
      toast.error(
        `Something went wrong while deactivating project ${project?.project_name}`,
        {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      global.config.activityLog(
        window.location.href,
        "User",
        `Something went wrong while deactivating Project ${project?.project_name}!!`
      );
      console.log(error);
      global.config.slackMessage(error.toString());
      if (error.response?.data?.status === "deleted") {
        localStorage.removeItem("user");
        dispatch(logout());
        dispatch(reset());
        dispatch(invoiceReset());
        dispatch(headerReset());
        dispatch(appSettingsReset());
        toast.error("This user is deleted!", {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/");
        return;
      }
    } finally {
      setIsSubmitLoading(false);
    }
  };

  const StatusChangeInactive = async (id) => {
    try {
      setIsSubmitLoading(true);
      await axios.get(`/api/project/status-change/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      toast.success(
        `Project ${project?.project_name} has been activated successfully!`,
        {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      dispatch(setNotification(Date.now()));
      global.config.activityLog(
        window.location.href,
        "User",
        `Project ${project?.project_name} has been activated successfully!`
      );
      dispatch(setProjectAdded());
    } catch (error) {
      console.log("Something went wrong while activating project: ", error);
      toast.error("Something went wrong in changing project status!",
        {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      global.config.activityLog(
        window.location.href,
        "User",
        `Something went wrong while activating Project ${project?.project_name}!!`
      );
      console.log(error);
      global.config.slackMessage(error.toString());
      if (error.response?.data?.status === "deleted") {
        localStorage.removeItem("user");
        dispatch(logout());
        dispatch(reset());
        dispatch(invoiceReset());
        dispatch(headerReset());
        dispatch(appSettingsReset());
        toast.error("This user is deleted!", {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/");
        return;
      }
    } finally {
      setIsSubmitLoading(false);
    }
  };

  const handleOpenDetailsModal = async (item) => {
    if (item.project_name) {
      setProjectName(item.project_name);
    }
    if (item.client_name) {
      setClientName(item.client_name._id);
    }
    if (item.discription) {
      setProjectDiscription(item.discription);
    }
    // if (item.budget) {
    //   setBudget(item.budget);
    // }
    if (item.total_hours) {
      setTotalHours(item.total_hours);
    }
    if (item.project_manager) {
      const findProjectManagerOptions = (
        selectedProjectManagers,
        projectManagerOptions
      ) => {
        return selectedProjectManagers.map((manager) => {
          const option = projectManagerOptions.find(
            (opt) => opt.value === manager._id
          );
          return option
            ? option
            : {
              label: manager.first_name
                ? `${manager.first_name} ${manager.middle_name || ""} ${manager.last_name
                }`
                : manager.company_first_name
                  ? `${manager.company_first_name +
                  (manager.company_last_name && manager.company_last_name)
                  }`
                  : `${manager.company_name}(Company)`,
              value: manager._id,
            };
        });
      };
      setSelectedProjectManagers(
        findProjectManagerOptions(item.project_manager, projectManagerOptions)
      );
    }
    setBillable(item.is_billable);

    setIsDetailsModalOpen(true);
  };

  const handleCloseDetailsModal = () => {
    setProjectName("");
    setClientName("");
    setProjectDiscription("");
    setTotalHours("");
    // setBudget("");
    setBillable(true);
    setIsDetailsModalOpen(false);
  };

  const handleProjectDelete = () => {
    setIsProjectDeleteModalOpen(true);
  };

  const handleConfirmProjectDelete = async () => {
    try {
      setIsSubmitLoading(true);
      const response = await axios.get(
        `/api/project-details/delete/${project._id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      toast.success("Project removed successfully", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      dispatch(setProjectAdded());
      global.config.activityLog(
        window.location.href,
        "Project",
        `Project (${project._id}) deleted successfully`
      );
      handleCloseDetailsModal();
      navigate(
        `/client/projects/${encodeURIComponent(
          AES.encrypt(props.id, "encryptionKey")
            .toString()
            .replace(/\//g, "%2F")
        )}`
      );
    } catch (error) {
      console.error(error);
      global.config.slackMessage(error.toString());
      if (error.response?.data?.status === "deleted") {
        localStorage.removeItem("user");
        dispatch(logout());
        dispatch(reset());
        dispatch(invoiceReset());
        dispatch(headerReset());
        dispatch(appSettingsReset());
        toast.error("This user is deleted!", {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/");
        return;
      }
      toast.error("Something went wrong", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } finally {
      setIsSubmitLoading(false);
    }
    setIsProjectDeleteModalOpen(false);
  };

  const handleCancelProjectDelete = () => {
    setIsProjectDeleteModalOpen(false);
  };

  const handleProjectEdit = async (event) => {
    event.preventDefault();
    setProjectNameIsValid(false);
    setClientNameIsValid(false);
    // setBudgetIsValid(false);
    // || budget === ""
    if (projectName === "" || clientName === "") {
      if (projectName === "") {
        setProjectNameIsValid(true);
        projectNameRef.current.focus();
        return;
      } else if (clientName === "") {
        setClientNameIsValid(true);
        clientNameRef.current.focus();
        return;
      }
      // else if (budget === "") {
      //   setBudgetIsValid(true);
      //   budgetRef.current.focus();
      //   return;
      // }
      toast.error("Please fill all required the fields", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    const requestData = {
      project_name: projectName,
      client_name: clientName,
      discription: projectDiscription,
      is_billable: billable,
      total_hours: totalHours,
      // budget: budget,
      project_manager: selectedProjectManagers.map((manager) => manager.value),
    };
    try {
      setIsSubmitLoading(true);
      const response = await axios.put(
        `/api/project-details/update/${project._id}`,
        requestData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = response.data.message;
      // setUpdatedProject((prevState) => {
      //     const updatedProjectList = prevState.map((item) => {
      //         if (item._id === project._id) {
      //             return {
      //                 ...item,
      //                 project_name: projectName,
      //                 discription: projectDiscription,
      //                 is_billable: billable,
      //                 total_hours: totalHours,
      //                 project_manager: selectedProjectManagers.map(manager => manager.value),
      //             };
      //         }
      //         return item;
      //     });
      //     return updatedProjectList;
      // });
      toast.success("Project updated successfully", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      global.config.activityLog(
        window.location.href,
        "Project",
        `Project "${projectName}" updated successfully`
      );
      handleCloseDetailsModal();
      dispatch(setProjectAdded());
    } catch (error) {
      console.log(error);
      global.config.slackMessage(error.toString());
      if (error.response?.data?.status === "deleted") {
        localStorage.removeItem("user");
        dispatch(logout());
        dispatch(reset());
        dispatch(invoiceReset());
        dispatch(headerReset());
        dispatch(appSettingsReset());
        toast.error("This user is deleted!", {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/");
        return;
      }
      toast.error("Something went wrong", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } finally {
      setIsSubmitLoading(false); // Set isLoading to false after the approval process is complete
    }
  };

  const numberInputOnWheelPreventChange = (e) => {
    // Prevent the input value change
    e.target.blur();

    // Prevent the page/container scrolling
    e.stopPropagation();

    setTimeout(() => {
      e.target.focus();
    }, 0);
  };

  return (
    <div className="client-details-overview-outer">
      <div className="text-arrow-outer">
        <div className="text-arrow-inner">
          <Link
            to={`/client/projects/${encodeURIComponent(
              AES.encrypt(props.id, "encryptionKey")
                .toString()
                .replace(/\//g, "%2F")
            )}`}
          >
            <div className="text-arrow">
              <div className="left-arrow">
                <img src={LeftArrow} alt="left-arrow" />
              </div>
              <div className="top-text">Back to All Projects</div>
            </div>
          </Link>
        </div>
      </div>

      <div className="client-with-invitation client-with-invitation-clietnt">
        <button
          type="button"
          className="btn-blue-sec"
          onClick={() => handleOpenDetailsModal(project)}
          disabled={isSubmitLoading || isLoadingProject}
        >
          <img src={editImage} alt="Edit Icon" /> Edit Project
        </button>
        <div className="modal-invite-main-outter">
          <Modal
            className="client-modal project-modal"
            isOpen={isDetailsModalOpen}
            onRequestClose={handleCloseDetailsModal}
            contentLabel="New Project"
            style={{
              content: {
                height: "41%",
                width: "30%",
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                parent: document.querySelector(".admin-outer.time.tracker"),
              },
            }}
          >
            <div className="modal">
              <div className="modal-close-invite outter-modal-main">
                <a className="" onClick={handleCloseDetailsModal}>
                  <img src={crssrImage} alt="Edit Icon" />{" "}
                </a>
              </div>
              <div className="project-detail-modal">
                <div className="modal-client-head ">
                  <h2 className="">Project Details</h2>
                </div>
                <div className="form-field-client-outer">
                  <form>
                    <div className="form-field-client">
                      <label htmlFor="projectName" className="form-label">
                        Project Name
                      </label>
                      <input
                        type="text"
                        id="projectName"
                        name="projectName"
                        value={projectName}
                        onChange={(e) => setProjectName(e.target.value)}
                        className={
                          projectNameIsValid
                            ? "input-validation-error form-input"
                            : "form-input"
                        }
                        ref={projectNameRef}
                      />
                      {projectNameIsValid && (
                        <span className="text-red-500 text-xs italic">
                          Project Name Field Cannot be empty
                        </span>
                      )}
                    </div>
                    <div className="form-field-client">
                      <label htmlFor="client" className="form-label">
                        Client Name
                      </label>
                      <div
                        className={`custom-dropdown-user-new-outer custom-dropdown-select `}
                        ref={clientDropdownRef}
                      >
                        <div className="selected-option custom-dropdown-user-new form-input readonly">
                          {clientName
                            ? clients.find(
                              (client) => client._id === clientName
                            )?.client_name
                            : "Select"}
                        </div>
                        {/* <div className="selected-option custom-dropdown-user-new">
                                                    {clientName ? (
                                                        clients.find((client) => client._id === clientName)?.client_name
                                                    ) : (
                                                        'Select'
                                                    )}
                                                </div> */}
                      </div>
                      {clientNameIsValid && (
                        <span className="text-red-500 text-xs italic">
                          Client Name Field Cannot be empty
                        </span>
                      )}
                    </div>

                    <div className="form-field-client">
                      <label htmlFor="description" className="form-label">
                        Description
                      </label>
                      <textarea
                        id="description"
                        name="description"
                        value={projectDiscription}
                        onChange={(e) => setProjectDiscription(e.target.value)}
                        className="form-textarea"
                        rows="3"
                      ></textarea>
                    </div>

                    <div className="form-field-client">
                      <label htmlFor="time" className="form-label">
                        Time Allocated (in hours)
                      </label>
                      <input
                        type="number"
                        id="time"
                        name="time"
                        value={totalHours}
                        onChange={(e) => setTotalHours(e.target.value)}
                        className="form-input"
                        onWheel={numberInputOnWheelPreventChange}
                      />
                    </div>

                    {/* <div className="form-field-client">
                      <label htmlFor="time" className="form-label">
                        Budget
                      </label>
                      <input
                        type="number"
                        id="budget"
                        name="budget"
                        value={budget}
                        onChange={(e) => setBudget(e.target.value)}
                        className={
                          budgetIsValid
                            ? "input-validation-error form-input"
                            : "form-input"
                        }
                        ref={budgetRef}
                        onWheel={numberInputOnWheelPreventChange}
                      />
                      {budgetIsValid && (
                        <span className="text-red-500 text-xs italic">
                          Budget Field Cannot be empty
                        </span>
                      )}
                    </div> */}

                    <div className="form-field-client">
                      <label htmlFor="projectManager" className="form-label">
                        Assigned Team
                      </label>
                      <Select
                        isMulti
                        options={projectManagerOptions}
                        value={selectedProjectManagers}
                        onChange={(selectedOptions) =>
                          setSelectedProjectManagers(selectedOptions)
                        }
                      />
                    </div>

                    <div className={`form-field-client radio-sec`}>
                      <label htmlFor="billable" className="form-label">
                        Billable
                      </label>
                      <div className="radio-container">
                        <div className="radio-option yes">
                          <input
                            type="radio"
                            id="yes"
                            name="billable"
                            value={billable}
                            checked={billable === true}
                            onChange={(e) => setBillable(true)}
                          />
                          <label htmlFor="yes">
                            Yes <img src={Tick} alt="Tick" />
                          </label>
                        </div>
                        <div className="radio-option no">
                          <input
                            type="radio"
                            id="no"
                            name="billable"
                            value={billable}
                            checked={billable === false}
                            onChange={(e) => setBillable(false)}
                          />
                          <label htmlFor="no">
                            No <img src={Close} alt="Edit Icon" />
                          </label>
                        </div>
                      </div>
                    </div>

                    {isSubmitLoading ? (
                      <div className="outter-load-table">
                        <ThreeDots
                          height="38"
                          width="40"
                          radius="9"
                          color="#6479f9"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClassName=""
                          visible={true}
                        />
                      </div>
                    ) : (
                      <div className="sprint-btn-outer">
                        <button
                          type="button"
                          onClick={handleCloseDetailsModal}
                          className="submit-client cancel-sprint"
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          onClick={handleProjectEdit}
                          className="submit-client save-sprint"
                        >
                          Save Changes
                        </button>
                      </div>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </Modal>
        </div>
        <button
          type="button"
          className="delete-client"
          onClick={handleProjectDelete}
          disabled={isSubmitLoading}
        >
          <img src={Delete} alt="Delete" /> Delete
        </button>
        <Modal
          className="delete-modal"
          isOpen={isProjectDeleteModalOpen}
          onRequestClose={handleCancelProjectDelete}
          contentLabel="Confirm Delete"
          style={{
            content: {
              height: "41%",
              width: "30%",
              position: "fixed",
              top: "36%",
              left: "50%",
              transform: "translate(-19.75%, 0%)",
              parent: document.querySelector(".admin-outer.time.tracker"),
            },
          }}
        >
          <div className="delete-modal-content">
            <h2>Confirm Delete</h2>
            <p>Are you sure you want to delete this Project?</p>
            <div className="delete-modal-buttons">
              <button
                className="buttons-rejected"
                onClick={handleConfirmProjectDelete}
                disabled={isSubmitLoading}
              >
                {isSubmitLoading ? (
                  <div className="outter-load-table">
                    <ThreeDots
                      height="18"
                      width="20"
                      radius="9"
                      color="white"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  </div>
                ) : (
                  "Delete"
                )}
              </button>
              <button
                onClick={handleCancelProjectDelete}
                disabled={isSubmitLoading}
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal>
      </div>
      <div className="client-professional-details-outer project-detai-outer-iner">
        <div className="client-detail-row-one">
          <div className="client-detail-row-one-left">
            <div className="client-status">
              {isLoadingProject ? (
                <div>
                  <ThreeDots
                    height="18"
                    width="20"
                    radius="9"
                    color="#6479f9"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                </div>
              ) : (
                <div className="client-status-text client-status-text-title">
                  {project.project_name}
                </div>
              )}
            </div>
          </div>
          <div className="client-detail-row-one-right">
            <div className="account-manager">
              <div className="account-manager-text">Account Manager</div>
              {isLoading ? (
                <div>
                  <ThreeDots
                    height="18"
                    width="20"
                    radius="9"
                    color="#6479f9"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                </div>
              ) : clientView.acc_manager ? (
                <div className="account-manager-image-with-name">
                  <span className="manager-profile-icon">
                    {clientView.acc_manager?.profile_image ? (
                      <img
                        src={`/uploads/lowProfileImage/${clientView.acc_manager.profile_image}`}
                        alt={clientView.acc_manager.first_name}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src =
                            "/uploads/lowProfileImage/user_default.png";
                        }}
                      />
                    ) : (
                      <img
                        src={profile_image}
                      // alt={clientView.acc_manager.first_name}
                      />
                    )}
                  </span>
                  <div className="account-manager-name">
                    <TruncateUserName
                      textContent={
                        clientView.acc_manager?.first_name ? (
                          <>
                            {clientView.acc_manager.first_name}{" "}
                            {clientView.acc_manager.middle_name &&
                              clientView.acc_manager.middle_name !== "null"
                              ? clientView.acc_manager.middle_name + " "
                              : ""}
                            {clientView.acc_manager.last_name}{" "}
                          </>
                        ) : (
                          <>
                            {clientView.acc_manager?.company_first_name
                              ? `${clientView.acc_manager.company_first_name} ${clientView.acc_manager.company_last_name
                                ? clientView.acc_manager.company_last_name
                                : ""
                              }`
                              : `${clientView.acc_manager.company_name || ""
                              }(Company)`}
                          </>
                        )
                      }
                    />
                  </div>
                </div>
              ) : (
                <div>Account manager is not assigned</div>
              )}
            </div>

            <div className="client-status">
              <div className="client-status-text">Project Status</div>
              {isLoading || isLoadingProject ? (
                <div>
                  <ThreeDots
                    height="18"
                    width="20"
                    radius="9"
                    color="#6479f9"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                </div>
              ) : (
                <>
                  {project?.is_active === false ? (
                    <div
                      className="inactive"
                      onClick={() =>
                        (!isSubmitLoading || !isLoadingProject) &&
                        StatusChangeInactive(project?._id)
                      }
                      style={{ cursor: "pointer" }}
                    >
                      {isSubmitLoading ? (
                        <div className="outter-load-table">
                          <ThreeDots
                            height="18"
                            width="18"
                            radius="9"
                            color="#405BFF"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                          />
                        </div>
                      ) : (
                        "Inactive"
                      )}
                    </div>
                  ) : project?.is_active === true ? (
                    <div
                      className="client-status-bar"
                      onClick={() =>
                        !isSubmitLoading && StatusChangeActive(project?._id)
                      }
                      style={{ cursor: "pointer" }}
                    >
                      {isSubmitLoading ? (
                        <div className="outter-load-table">
                          <ThreeDots
                            height="18"
                            width="18"
                            radius="9"
                            color="#405BFF"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                          />
                        </div>
                      ) : (
                        "Active"
                      )}
                    </div>
                  ) : (
                    project?.is_active
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        <div className="account-manager project-manager">
          <div className="account-manager-text">Assigned Team </div>
          <div className="account-manager-image-with-name-outer">
            {isLoading || isLoadingProject ? (
              <div>
                <ThreeDots
                  height="18"
                  width="20"
                  radius="9"
                  color="#6479f9"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
              </div>
            ) : (
              project?.project_manager &&
              project?.project_manager.map((manager, index) => (
                <div className="account-manager-image-with-name">
                  <span className="manager-profile-icon">
                    {manager.profile_image ? (
                      <img
                        src={`/uploads/lowProfileImage/${manager.profile_image}`}
                        alt={manager.first_name || manager.company_name}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src =
                            "/uploads/lowProfileImage/user_default.png";
                        }}
                      />
                    ) : (
                      <img
                        src={profile_image}
                        alt={manager.first_name || manager.company_name}
                      />
                    )}
                  </span>
                  <div className="account-manager-name">
                    <TruncateUserName
                      textContent={
                        manager.first_name ? (
                          <>
                            {manager.first_name}{" "}
                            {manager.middle_name &&
                              manager.middle_name !== "null"
                              ? manager.middle_name + " "
                              : ""}
                            {manager.last_name}{" "}
                          </>
                        ) : (
                          <>
                            {manager.company_first_name
                              ? `${manager.company_first_name} ${manager.company_last_name
                                ? manager.company_last_name
                                : ""
                              }`
                              : `${manager.company_name || ""}(Company)`}
                          </>
                        )
                      }
                    />
                    {/* {index < project.project_manager.length - 1 && ', '} */}
                  </div>
                </div>
              ))
            )}
          </div>
        </div>

        <ProjectOverview
          id={props.id}
          project={props.project}
          isLoadingProject={props.isLoadingProject}
          clientCurrency={clientCurrency}
        />
        <SprintTable
          sprints={props.sprints}
          isLoadingSprint={props.isLoadingSprint}
          project={props.project}
          isLoadingProject={props.isLoadingProject}
          clientCurrency={clientCurrency}
        />
        <Teams
          id={props.id}
          project={props.project}
          projectManager={project?.project_manager}
          isLoadingProject={props.isLoadingProject}
          clientCurrency={clientCurrency}
        />
      </div>
    </div>
  );
};
export default ProjectDetails;
