import React from "react";
import VendorInvoices from "../../../components/Vendors/VendorInvoice/VendorInvoices";

function VendorInvoiceSection()  {
    return(
        <div className="react-outer">
            <VendorInvoices />
        </div>
    )
}

export default VendorInvoiceSection;