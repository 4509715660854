const reportingFilterComparisonConst =
{
    Last_Year: '1',
    Last_Quarter: '2',
    Second_Half: '3',
    Last_Month: '4',
    Year_Select: '5',
    Month_Select: '6',
};

module.exports = {
    reportingFilterComparisonConst
}
