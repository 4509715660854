import moment from "moment";
import invoiceService from './invoiceService';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
//get user from localStaorage
const invoice = JSON.parse(localStorage.getItem('selectedUser'));
const dashCurrency = JSON.parse(localStorage.getItem('DashboardCurrency'));
const initialState = {
    selectedUser: invoice ? invoice : null,
    invoicebank: null,
    invoicebankCurrency: null,
    dashboardCurrency: dashCurrency ? dashCurrency : "USD",
    testRedux: null,
    invoiceType: null,
    dashboardCurrencySum: true,
    vendorDashboardCurrencySum: false,
    clientDashboardCurrencySum: false,
    dashboardDateRange: [
        new Date(moment().utc().subtract(30, 'years').startOf('year').format()).toISOString(),
        new Date(moment().utc().endOf('year').format()).toISOString()
    ]
}
//Register user

export const setAutomaticInvoiceUser = createAsyncThunk('invoice/setAutomaticInvoiceUser', async (autoData) => {
    return await invoiceService.automaticInvoiceuser(autoData)
})

export const setInvoiceBank = createAsyncThunk('invoice/setInvoiceBank', async (bankId) => {
    return await invoiceService.setBankId(bankId);
})

export const setInvoiceBankCurrency = createAsyncThunk('invoice/setInvoiceBankCurrency', async (bankCurrency) => {
    return bankCurrency;
})

export const setDashboardDate = createAsyncThunk('invoice/setDashboardDate', async (dateRange) => {
    return await invoiceService.setDateRange(dateRange);
})

export const setDashboardCurrencyRx = createAsyncThunk('invoice/setDashboardCurrency', async (currency) => {
    return currency;
})

export const setInvoiceType = createAsyncThunk('invoice/setInvoiceType', async (invoiceType) => {
    return invoiceType;
})

export const setDashboardCurrencySum = createAsyncThunk('invoice/setDashboardCurrencySum', async (val) => {
    return val;
})

export const setVendorDashboardCurrencySum = createAsyncThunk('invoice/setVendorDashboardCurrencySum', async (val) => {
    return val;
})

export const setClientDashboardCurrencySum = createAsyncThunk('invoice/setClientDashboardCurrencySum', async (val) => {
    return val;
})

export const testRedux = createAsyncThunk('redux/test', async (testData) => {
    return testData;
})

export const invoiceSlice = createSlice({
    name: 'invoice',
    initialState,
    reducers: {
        reset: (state) => {
            state.selectedUser = null
            state.invoicebank = null
            state.invoicebankCurrency = null
            state.testRedux = null
            state.invoiceType = null
            state.dashboardCurrencySum = false
            state.vendorDashboardCurrencySum = false
            state.clientDashboardCurrencySum = false
            state.dashboardDateRange = [new Date(moment().utc().subtract(30, 'years').startOf('year').format()).toISOString(),
            new Date(moment().utc().endOf('year').format()).toISOString()]
            state.dashboardCurrency = null
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(setAutomaticInvoiceUser.fulfilled, (state, action) => {
                state.selectedUser = action.payload
            })
            .addCase(setInvoiceBank.fulfilled, (state, action) => {
                state.invoicebank = action.payload
            })
            .addCase(setInvoiceBankCurrency.fulfilled, (state, action) => {
                state.invoicebankCurrency = action.payload
            })
            .addCase(testRedux.fulfilled, (state, action) => {
                state.testRedux = action.payload
            })
            .addCase(setDashboardDate.fulfilled, (state, action) => {
                state.dashboardDateRange = action.payload
            })
            .addCase(setDashboardCurrencyRx.fulfilled, (state, action) => {
                state.dashboardCurrency = action.payload
            })
            .addCase(setInvoiceType.fulfilled, (state, action) => {
                state.invoiceType = action.payload
            })
            .addCase(setDashboardCurrencySum.fulfilled, (state, action) => {
                state.dashboardCurrencySum = action.payload
            })
            .addCase(setVendorDashboardCurrencySum.fulfilled, (state, action) => {
                state.vendorDashboardCurrencySum = action.payload
            })
            .addCase(setClientDashboardCurrencySum.fulfilled, (state, action) => {
                state.clientDashboardCurrencySum = action.payload
            })
    }
})

export const { reset } = invoiceSlice.actions
export default invoiceSlice.reducer 