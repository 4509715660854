import axios from "axios";
import "./Profiles.css";
import React, { useState, useRef, useEffect } from "react";
import Modal from "react-modal";
import validator from 'validator';
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { ThreeDots } from "react-loader-spinner";
import { Parser } from 'html-to-react';
import PhoneInput from 'react-phone-number-input';
import Countries from "../../../constants/countries";
import editImage from "../../../images/singleinvoice/edit.svg";
import Tick from "../../../images/singleinvoice/tick-green.svg";
import Close from "../../../images/icons/close.svg";
import Delete from "../../../images/icons/delete.svg";
import crssrImage from "../../../images/icons/close.svg";
import PlusIcon from "../../../images/icons/plus-icon.svg";
import userTypeConsts from "../../../constants/userTypeConsts";
import { useFetchCompanyProfiles, useFetchWiseProfiles } from "./elements/useProfiles";
import { logout, reset } from "../../../features/auth/authSlice";
import { reset as invoiceReset } from "../../../features/invoice/invoiceSlice.js";
import { reset as headerReset } from "../../../features/headerText/headerTextSlice.js";
import { reset as appSettingsReset } from "../../../features/appSettings/appSettingsSlice.js";
import { setNotification } from "../../../features/notification/notificationSlice.js";


const Profiles = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [filter, setFilter] = useState("");
  const [newProfileModal, setNewProfileModal] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [wxCompany, setWxCompany] = useState("");
  const [wxCompanyEdit, setWxCompanyEdit] = useState("");
  const [wxCompanyView, setWxCompanyView] = useState("");
  const [wxCompanyIsValid, setWxCompanyIsValid] = useState(false);
  const [wxCompanyEditIsValid, setWxCompanyEditIsValid] = useState(false);
  const wxCompanyRef = useRef(null);
  const wxCompanyEditRef = useRef(null);
  const [legalName, setLegalName] = useState("");
  const [legalNameEdit, setLegalNameEdit] = useState("");
  const [legalNameIsValid, setLegalNameIsValid] = useState(false);
  const [legalNameEditIsValid, setLegalNameEditIsValid] = useState(false);
  const legalNameRef = useRef(null);
  const legalNameEditRef = useRef(null);
  const [legalNameView, setLegalNameView] = useState("");
  const [countriesList, setCountriesList] = useState([]);
  const [country, setCountry] = useState("");
  const [countryEdit, setCountryEdit] = useState("");
  const [countryView, setCountryView] = useState("");
  const [countrySearchTerm, setCountrySearchTerm] = useState('');
  const [countryDropdownOpen, setCountryDropdownOpen] = useState(false);
  const [countryIsValid, setCountryIsValid] = useState(false);
  const [countryEditIsValid, setCountryEditIsValid] = useState(false);
  const countryRef = useRef(null);
  const countryEditRef = useRef(null);
  const countryDropdownRef = useRef(null);
  const [email, setEmail] = useState("");
  const [emailEdit, setEmailEdit] = useState("");
  const [emailView, setEmailView] = useState("");
  const [emailIsValid, setEmailIsValid] = useState(false);
  const [emailEditIsValid, setEmailEditIsValid] = useState(false);
  const emailRef = useRef(null);
  const emailEditRef = useRef(null);
  const [mobileNo, setMobileNo] = useState("");
  const [mobileNoEdit, setMobileNoEdit] = useState("");
  const [mobileNoView, setMobileNoView] = useState("");
  const [mobileNoIsValid, setMobileNoIsValid] = useState(false);
  const [mobileNoEditIsValid, setMobileNoEditIsValid] = useState(false);
  const mobileNoRef = useRef(null);
  const mobileNoEditRef = useRef(null);
  const [address, setAddress] = useState("");
  const [addressEdit, setAddressEdit] = useState("");
  const [addressView, setAddressView] = useState("");
  const [addressIsValid, setAddressIsValid] = useState(false);
  const addressRef = useRef(null);
  const addressEditRef = useRef(null);
  const [vatNumber, setVatNumber] = useState("");
  const [vatNumberEdit, setVatNumberEdit] = useState("");
  const [vatNumberView, setVatNumberView] = useState("");
  const [vatNumberIsValid, setVatNumberIsValid] = useState(false);
  const vatNumberRef = useRef(null);
  const vatNumberEditRef = useRef(null);
  const [bankAccountSearchTerm, setBankAccountSearchTerm] = useState('');
  const [bankAccountDropdownOpen, setBankAccountDropdownOpen] = useState(false);
  const [bankAccountEditDropdownOpen, setBankAccountEditDropdownOpen] = useState(false);
  const [selectedBankAccount, setSelectedBankAccount] = useState("");
  const [bankAccountEdit, setBankAccountEdit] = useState("");
  const [bankAccountView, setBankAccountView] = useState("");
  const [bankAccountIsValid, setBankAccountIsValid] = useState(false);
  const [bankAccountEditIsValid, setBankAccountEditIsValid] = useState(false);
  const bankAccountRef = useRef(null);
  const bankAccountEditRef = useRef(null);
  const bankAccountDropdownRef = useRef(null);
  const bankAccountEditDropdownRef = useRef(null);
  const [profileId, setProfileId] = useState("");
  const [profileIdEdit, setProfileIdEdit] = useState("");
  const [userId, setUserId] = useState("");
  const [userIdEdit, setUserIdEdit] = useState("");
  const [wiseSetId, setWiseSetId] = useState("");
  const [wiseSetIdEdit, setWiseSetIdEdit] = useState("");
  const [isVendor, setIsVendor] = useState(true);
  const [isVendorEdit, setIsVendorEdit] = useState(true);
  const [isVendorView, setIsVendorView] = useState(true);
  const [isProfileDeleteModalOpen, setIsProfileDeleteModalOpen] = useState(false);
  const [profileEditModalOpen, setProfileEditModalOpen] = useState(false);
  const [isProfileEdit, setIsProfileEdit] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [wiseModalIsOpen, setWiseModalIsOpen] = useState(false);
  const [isWiseProfileLoading, setIsWiseProfileLoading] = useState(false);
  const token = useSelector(
    (state) => state.auth.token
  );
  const appId = useSelector(
    (state) => state.appSettings.appSettings.appId
  );
  const { user } = useSelector((state) => state.auth);
  const { appSettings } = useSelector((state) => state.appSettings);
  const [perPage, setPerPage] = useState(10);
  const [showingDropdownOpen, setShowingDropdownOpen] = useState(false);
  const showingDropdownRef = useRef(null);

  const { data: profiles = [], isLoading: loadingCompProfiles, refetch: refetchProfiles } = useFetchCompanyProfiles(token);
  const { data: wiseProfiles = [], isLoading: loadingWiseProfiles, refetch: refetchWiseProfiles } = useFetchWiseProfiles(token);

  const handleClickOutsideDropdown = (event) => {
    const isClickInsideShowingDropdown = showingDropdownRef.current && showingDropdownRef.current.contains(event.target);
    const isClickInsideCountryDropdown = countryDropdownRef.current && countryDropdownRef.current.contains(event.target);
    const isClickInsideBankAccountDropdown = bankAccountDropdownRef.current && bankAccountDropdownRef.current.contains(event.target);
    const isClickInsideBankAccountEditDropdown = bankAccountEditDropdownRef.current && bankAccountEditDropdownRef.current.contains(event.target);
    if (!isClickInsideShowingDropdown) {
      setShowingDropdownOpen(false);
    }
    if (!isClickInsideCountryDropdown) {
      setCountryDropdownOpen(false);
    }
    if (!isClickInsideBankAccountDropdown) {
      setBankAccountDropdownOpen(false);
    }
    if (!isClickInsideBankAccountEditDropdown) {
      setBankAccountEditDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutsideDropdown);
    return () => {
      document.removeEventListener('click', handleClickOutsideDropdown);
    };
  }, []);

  const handleFilterChange = (e) => {
    setFilter(e.target.value)
  };

  const filteredData = profiles.filter((item) => {
    const searchTerm = filter.trim()?.toLowerCase();
    if (!loadingCompProfiles) {
      const hasMatch =
        item.wx_company?.toLowerCase().includes(searchTerm) ||
        item.legal_entity_company_name?.toLowerCase().includes(searchTerm) ||
        item.country?.toLowerCase().includes(searchTerm) ||
        item.email?.toLowerCase().includes(searchTerm);
      return hasMatch;
    }
  });

  const handleOpenCreateModal = () => {
    refetchWiseProfiles();
    setNewProfileModal(true);
  };

  const handleToggleBankAccountDropdown = () => {
    setBankAccountSearchTerm('');
    setBankAccountDropdownOpen((prevOpen) => !prevOpen);
  };

  const handleToggleBankAccountEditDropdown = () => {
    setBankAccountSearchTerm('');
    setBankAccountEditDropdownOpen((prevOpen) => !prevOpen);
  };

  const handleBankAccountSearchInputChange = (e) => {
    setBankAccountSearchTerm(e.target.value);
  };

  const filteredBankAccount = wiseProfiles.filter((profiles) => {
    if (bankAccountSearchTerm) {
      return profiles.businessName?.toLowerCase().includes(bankAccountSearchTerm?.toLowerCase());
    } else {

      return profiles;
    }
  });

  const handleBankAccountSelect = (profile) => {
    setSelectedBankAccount(profile.businessName);
    setProfileId(profile.id);
    setUserId(profile.userId);
    setWiseSetId(profile.wiseSettingsId);
    setBankAccountDropdownOpen(false);
  };

  const handleBankAccountEditSelect = (profile) => {
    setBankAccountEdit(profile.businessName);
    setProfileIdEdit(profile.id);
    setUserIdEdit(profile.userId);
    setWiseSetIdEdit(profile.wiseSettingsId);
    setBankAccountEditDropdownOpen(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setWxCompanyIsValid(false);
    setLegalNameIsValid(false);
    setMobileNoIsValid(false);
    setEmailIsValid(false);
    setAddressIsValid(false);
    setVatNumberIsValid(false);
    setBankAccountIsValid(false);
    setCountryIsValid(false);
    if (
      wxCompany === "" ||
      legalName === "" ||
      country === "" ||
      email === "" ||
      mobileNo === "" ||
      mobileNo === undefined ||
      address === "" ||
      vatNumber === "" ||
      profileId === "" ||
      userId === "" ||
      wiseSetId === ""
    ) {
      if (wxCompany === "") {
        setWxCompanyIsValid(true);
        wxCompanyRef?.current?.focus();
        toast.error("Company name cannot be empty!", { position: "bottom-right", autoClose: 2000 });
        return;
      }
      if (legalName === "") {
        setLegalNameIsValid(true);
        legalNameRef?.current?.focus();
        toast.error("Legal entity name cannot be empty!", { position: "bottom-right", autoClose: 2000 });
        return;
      }
      if (country === "") {
        setCountryIsValid(true);
        countryRef?.current?.focus();
        toast.error("Country cannot be empty!", { position: "bottom-right", autoClose: 2000 });
        return;
      }
      if (mobileNo === "" || mobileNo === undefined) {
        setMobileNoIsValid(true);
        mobileNoRef?.current?.focus();
        toast.error("Mobile number cannot be empty!", { position: "bottom-right", autoClose: 2000 });
        return;
      }
      if (email === "") {
        setEmailIsValid(true);
        emailRef?.current?.focus();
        toast.error("Email cannot be empty!", { position: "bottom-right", autoClose: 2000 });
        return;
      }
      if (address === "") {
        setAddressIsValid(true);
        addressRef?.current?.focus();
        toast.error("Address cannot be empty!", { position: "bottom-right", autoClose: 2000 });
        return;
      }
      if (vatNumber === "") {
        setVatNumberIsValid(true);
        vatNumberRef?.current?.focus();
        toast.error("Vat number cannot be empty!", { position: "bottom-right", autoClose: 2000 });
        return;
      }
      if (profileId === "" || userId === "" || wiseSetId === "") {
        setBankAccountIsValid(true);
        bankAccountRef?.current?.focus();
        toast.error("Bank account cannot be empty!", { position: "bottom-right", autoClose: 2000 });
        return;
      }

      toast.error("Please fill all required fields!", { position: "bottom-right", autoClose: 2000 });
      return;
    }

    const requestData = {
      wx_company: wxCompany,
      legal_entity_company_name: legalName,
      country: country,
      email: email,
      mobile_no: mobileNo,
      address: address,
      vat_number: vatNumber,
      profile_id: profileId,
      user_id: userId,
      wiseSettingsId: wiseSetId,
      is_vendor: isVendor,
      appId: appId,
    };

    try {
      setIsSubmitLoading(true);

      await axios.post(`/api/company-profile/create`, requestData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      toast.success("Profile created successfully", { position: "bottom-right", autoClose: 2000 });

      dispatch(setNotification(Date.now()));
      refetchProfiles();
      handleCloseCreateModal();
    } catch (error) {
      console.error(error);

      if (error.response?.data?.status === "deleted") {
        localStorage.removeItem("user");
        dispatch(logout());
        toast.error("This user is deleted!", { position: "bottom-right", autoClose: 1500 });
        navigate("/");
        return;
      }

      toast.error("Something went wrong while updating the profile!", { position: "bottom-right", autoClose: 2000 });
    } finally {
      setIsSubmitLoading(false);
    }
  };

  const handleCloseCreateModal = () => {
    setSelectedId("");
    setWxCompany("");
    setLegalName("");
    setCountry("");
    setEmail("");
    setMobileNo("");
    setAddress("");
    setVatNumber("");
    setSelectedBankAccount("");
    setProfileId("");
    setUserId("");
    setWiseSetId("");
    setIsVendor(true);
    setNewProfileModal(false);
  };

  const handleDetailClick = async (rowData) => {
    setSelectedId(rowData._id);
    if (rowData.wx_company) {
      setWxCompanyEdit(rowData.wx_company);
      setWxCompanyView(rowData.wx_company);
    }
    if (rowData.legal_entity_company_name) {
      setLegalNameEdit(rowData.legal_entity_company_name);
      setLegalNameView(rowData.legal_entity_company_name);
    }
    if (rowData.country) {
      setCountryEdit(rowData.country);
      setCountryView(rowData.country);
    }
    if (rowData.email) {
      setEmailEdit(rowData.email);
      setEmailView(rowData.email);
    }
    if (rowData.mobile_no) {
      setMobileNoEdit(rowData.mobile_no);
      setMobileNoView(rowData.mobile_no);
    }
    if (rowData.address) {
      setAddressEdit(rowData.address);
      setAddressView(rowData.address);
    }
    if (rowData.vat_number) {
      setVatNumberEdit(rowData.vat_number);
      setVatNumberView(rowData.vat_number);
    }
    if (rowData.profile_id) {
      setProfileIdEdit(rowData.profile_id);
    }
    if (rowData.user_id) {
      setUserIdEdit(rowData.user_id);
    }
    if (rowData.wiseSettingsId) {
      setWiseSetIdEdit(rowData.wiseSettingsId);
    }
    if (rowData.user_id && rowData.profile_id) {
      const selectedProfile = wiseProfiles.find(profile =>
        parseInt(profile.id) === parseInt(rowData.profile_id)
      );
      if (selectedProfile) {
        setBankAccountEdit(selectedProfile.businessName);
        setBankAccountView(selectedProfile.businessName);
        if (!rowData.wiseSettingsId) {
          setWiseSetIdEdit(selectedProfile.wiseSettingsId);
        }
      } else {
        setBankAccountEdit("");
        setBankAccountView("");
      }
    }
    setIsVendorEdit(rowData.is_vendor);
    setIsVendorView(rowData.is_vendor);
    setProfileEditModalOpen(true);
  }

  const handleCloseDetailsModal = () => {
    setSelectedId("");
    setWxCompanyEdit("");
    setWxCompanyView("");
    setLegalNameEdit("");
    setLegalNameView("");
    setCountryEdit("");
    setCountryView("");
    setEmailEdit("");
    setEmailView("");
    setMobileNoEdit("");
    setMobileNoView("");
    setAddressEdit("");
    setAddressView("");
    setVatNumberEdit("");
    setVatNumberView("");
    setProfileIdEdit("");
    setProfileIdEdit("");
    setUserIdEdit("");
    setWiseSetIdEdit("");
    setBankAccountEdit("");
    setBankAccountView("");
    setIsVendorEdit(true);
    setIsVendorView(true);
    setIsProfileEdit(false);
    setProfileEditModalOpen(false);
    setWxCompanyEditIsValid(false);
    setLegalNameEditIsValid(false);
    setMobileNoEditIsValid(false);
    setEmailEditIsValid(false);
    setAddressIsValid(false);
    setVatNumberIsValid(false);
    setBankAccountEditIsValid(false);
  };

  const handleSetProfileEdit = () => {
    setIsProfileEdit(true);
  };

  useEffect(() => {
    async function checkWiseSettings() {
      try {
        setIsWiseProfileLoading(true);
        const response = await axios.get("/api/check/wise/settings/exists", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (!response.data.exists) {
          setWiseModalIsOpen(true);
        }
        return response.data.exists;
      } catch (error) {
        console.log("Error checking Wise settings:", error);
        return false;
      } finally {
        setIsWiseProfileLoading(false);
      }
    }
    const sortedCountries = Countries.slice().sort((a, b) => a.country.localeCompare(b.country));
    setCountriesList(sortedCountries);
    refetchProfiles();
    refetchWiseProfiles();
    checkWiseSettings();
  }, []);

  const closeWiseModal = () => {
    setWiseModalIsOpen(false);
    if (user.userType === userTypeConsts.SUPER_ADMIN || user.userType === userTypeConsts.ADMIN) {
      localStorage.setItem('activeTab', '1');
      navigate("/user/account/settings");
    } else {
      navigate("/user/dashboard");
    }
  };

  const goToWisePage = () => {
    setWiseModalIsOpen(false);
    if (user.userType === userTypeConsts.SUPER_ADMIN || user.userType === userTypeConsts.ADMIN) {
      localStorage.setItem('activeTab', '1');
      navigate("/user/account/settings");
    } else {
      navigate("/user/dashboard");
    }
  };

  const handleToggleCountryDropdown = () => {
    setCountrySearchTerm('');
    setCountryDropdownOpen((prevOpen) => !prevOpen);
  };

  const handleCountrySearchInputChange = (e) => {
    setCountrySearchTerm(e.target.value);
  };

  const filteredCountries = countriesList.filter((country) => {
    return country.country?.toLowerCase().includes(countrySearchTerm?.toLowerCase());
  });

  const handleProfileEditSubmit = async (event) => {
    event.preventDefault();
    setWxCompanyEditIsValid(false);
    setLegalNameEditIsValid(false);
    setMobileNoEditIsValid(false);
    setEmailEditIsValid(false);
    setAddressIsValid(false);
    setVatNumberIsValid(false);
    setBankAccountEditIsValid(false);
    setCountryEditIsValid(false);
    if (
      wxCompanyEdit === "" ||
      legalNameEdit === "" ||
      countryEdit === "" ||
      emailEdit === "" ||
      mobileNoEdit === "" ||
      mobileNoEdit === undefined ||
      addressEdit === "" ||
      vatNumberEdit === "" ||
      profileIdEdit === "" ||
      userIdEdit === "" ||
      wiseSetIdEdit === ""
    ) {
      if (wxCompanyEdit === "") {
        setWxCompanyEditIsValid(true);
        wxCompanyEditRef?.current?.focus();
        toast.error("Company name cannot be empty!", { position: "bottom-right", autoClose: 2000 });
        return;
      }
      if (legalNameEdit === "") {
        setLegalNameEditIsValid(true);
        legalNameEditRef?.current?.focus();
        toast.error("Legal entity name cannot be empty!", { position: "bottom-right", autoClose: 2000 });
        return;
      }
      if (countryEdit === "") {
        setCountryEditIsValid(true);
        countryEditRef?.current?.focus();
        toast.error("Country cannot be empty!", { position: "bottom-right", autoClose: 2000 });
        return;
      }
      if (mobileNoEdit === "" || mobileNoEdit === undefined) {
        setMobileNoEditIsValid(true);
        mobileNoEditRef?.current?.focus();
        toast.error("Mobile number cannot be empty!", { position: "bottom-right", autoClose: 2000 });
        return;
      }
      if (emailEdit === "") {
        setEmailEditIsValid(true);
        emailEditRef?.current?.focus();
        toast.error("Email cannot be empty!", { position: "bottom-right", autoClose: 2000 });
        return;
      }
      if (addressEdit === "") {
        setAddressIsValid(true);
        addressEditRef?.current?.focus();
        toast.error("Address cannot be empty!", { position: "bottom-right", autoClose: 2000 });
        return;
      }
      if (vatNumberEdit === "") {
        setVatNumberIsValid(true);
        vatNumberEditRef?.current?.focus();
        toast.error("Vat number cannot be empty!", { position: "bottom-right", autoClose: 2000 });
        return;
      }
      if (profileIdEdit === "" || userIdEdit === "" || wiseSetIdEdit === "") {
        setBankAccountEditIsValid(true);
        bankAccountEditRef?.current?.focus();
        toast.error("Bank account cannot be empty!", { position: "bottom-right", autoClose: 2000 });
        return;
      }

      toast.error("Please fill all required fields!", { position: "bottom-right", autoClose: 2000 });
      return;
    }

    const requestData = {
      wx_company: wxCompanyEdit,
      legal_entity_company_name: legalNameEdit,
      country: countryEdit,
      email: emailEdit,
      mobile_no: mobileNoEdit,
      address: addressEdit,
      vat_number: vatNumberEdit,
      profile_id: profileIdEdit,
      user_id: userIdEdit,
      wiseSettingsId: wiseSetIdEdit,
      is_vendor: isVendorEdit,
    };

    try {
      setIsSubmitLoading(true);

      await axios.put(`/api/company-profile/update/${selectedId}`, requestData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      toast.success("Profile updated successfully", { position: "bottom-right", autoClose: 2000 });

      dispatch(setNotification(Date.now()));
      refetchProfiles();
      handleCloseDetailsModal();
    } catch (error) {
      console.error(error);

      if (error.response?.data?.status === "deleted") {
        localStorage.removeItem("user");
        dispatch(logout());
        toast.error("This user is deleted!", { position: "bottom-right", autoClose: 1500 });
        navigate("/");
        return;
      }

      toast.error("Something went wrong while updating the profile!", { position: "bottom-right", autoClose: 2000 });
    } finally {
      setIsSubmitLoading(false);
    }
  };

  const handleResetProfileEdit = () => {
    setIsProfileEdit(false);
  };

  const renderDetailButton = (rowData) => {
    return (
      <button
        className="cta-link account-payment"
        onClick={() => handleDetailClick(rowData)}
      >
        {loadingCompProfiles ?
          <div className="outter-load-table">
            <ThreeDots
              height="18"
              width="20"
              radius="9"
              color="white"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div> : global.config.locate(appSettings && appSettings.language, 'Details')}
      </button>
    );
  };

  const handleProfileDelete = () => {
    setIsProfileDeleteModalOpen(true);
  };

  const handleConfirmProfileDelete = async () => {
    try {
      setIsSubmitLoading(true);

      const response = await axios.get(`/api/company-profile/delete/${selectedId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      toast.success("Company profile data removed successfully", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      dispatch(setNotification(Date.now()));
      refetchProfiles();
      handleCloseDetailsModal();
      global.config.activityLog(window.location.href, 'Company profile', `Company profile (${selectedId}) deleted successfully`);
      // profileRefetch();
    } catch (error) {
      console.error(error);
      global.config.slackMessage(error.toString());
      if (error.response?.data?.status === "deleted") {
        localStorage.removeItem("user");
        dispatch(logout());
        dispatch(reset());
        dispatch(invoiceReset());
        dispatch(headerReset());
        dispatch(appSettingsReset());
        toast.error('This user is deleted!', {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/");
        return;
      }
      toast.error("Something went wrong", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } finally {
      setIsSubmitLoading(false);
      handleCancelProfileDelete();
    }
  };

  const handleCancelProfileDelete = () => {
    setSelectedId("");
    setIsProfileDeleteModalOpen(false);
  };

  const totalResults = filteredData.length;

  return (
    <div className="user-outer-overview add-bank-account">
      <div className="invoice-top">
        <div className="button-sec-invoice">
          <Link onClick={handleOpenCreateModal}>
            <span className="icon-invoice">
              <img src={PlusIcon} alt="Add" />
            </span>
            New Profile Account
          </Link>
          <div className="modal-invite-main-outter add-bank-account">

          </div>
        </div>
        <div className="search-sec">
          <div class="search-container">
            <input
              type="text"
              onChange={handleFilterChange}
              value={filter}
              class="search-input"
              placeholder={global.config.locate(appSettings && appSettings.language, 'Search')}
            />
          </div>
        </div>
      </div>
      <Modal
        className="delete-modal"
        isOpen={wiseModalIsOpen}
        onRequestClose={closeWiseModal}
        contentLabel="No Wise Account Modal"
        style={{
          content: {
            height: "41%",
            width: "30%",
            position: "fixed",
            top: "40%",
            left: "50%",
            transform: "translate(-17.5%, 0%)",
            parent: document.querySelector(".admin-outer.time.tracker"),
          },
        }}
      >
        <div className="delete-modal-content">
          <div className="outter-modal-main">
            <a className="close-image-clr1" onClick={closeWiseModal}>
              <img src={crssrImage} alt="Close Icon" />{" "}
            </a>
          </div>
          <h2>No Wise Account Registered</h2>
          <p>
            There is no Wise account registered for this account. Please add a Wise account to proceed further.
          </p>
          {isWiseProfileLoading ? (
            <div className="outter-load-table">
              <ThreeDots
                height="38"
                width="40"
                radius="9"
                color="#6479f9"
                ariaLabel="three-dots-loading"
                visible={true}
              />
            </div>
          ) : (
            (user.userType === userTypeConsts.SUPER_ADMIN || user.userType === userTypeConsts.ADMIN) ? (
              <div className="delete-modal-buttons">
                <button className="buttons-approved" onClick={goToWisePage}>
                  Add Wise Account
                </button>
                <button onClick={closeWiseModal}>Close</button>
              </div>
            ) : (
              <div style={{ color: "red", textAlign: "center", marginTop: "20px" }}>
                Please contact admin to create a bank account
              </div>
            )
          )}
        </div>
      </Modal>
      <div className="showing-count">
        <div className="filter-inner showing-result">
          <label>{global.config.locate(appSettings && appSettings.language, 'Showing')}</label>
          <div ref={showingDropdownRef} className="select-modal-mainM custom-dropdown-user-new-outer">
            <div className="selected-option custom-dropdown-user-new" onClick={() => setShowingDropdownOpen(!showingDropdownOpen)}>
              {totalResults < 10 ? totalResults.toString() : perPage.toString()}
            </div>
            {showingDropdownOpen && (
              <div className="custom-dropdown-new">
                <ul className="showing-options-list">
                  {totalResults >= 10 && <li className={perPage === 10 ? 'selected-item' : ''} onClick={() => { setPerPage(10); setShowingDropdownOpen(false); }}>10</li>}
                  {totalResults >= 20 && <li className={perPage === 20 ? 'selected-item' : ''} onClick={() => { setPerPage(20); setShowingDropdownOpen(false); }}>20</li>}
                  {totalResults >= 30 && <li className={perPage === 30 ? 'selected-item' : ''} onClick={() => { setPerPage(30); setShowingDropdownOpen(false); }}>30</li>}
                  {totalResults >= 40 && <li className={perPage === 40 ? 'selected-item' : ''} onClick={() => { setPerPage(40); setShowingDropdownOpen(false); }}>40</li>}
                  {totalResults >= 10 && <li className={totalResults >= 10 && perPage === totalResults ? 'selected-item' : ''} onClick={() => { setPerPage(totalResults); setShowingDropdownOpen(false); }}>ALL</li>}
                  {/* Add more conditions as needed */}
                  {totalResults < 10 ? (
                    <li className={totalResults < 10 ? 'selected-item' : ''} onClick={() => { setPerPage(totalResults); setShowingDropdownOpen(false); }}>{totalResults}</li>
                  ) : null}
                </ul>
              </div>
            )}
          </div>
          <label>{global.config.locate(appSettings && appSettings.language, 'of')} {totalResults} {global.config.locate(appSettings && appSettings.language, 'Results')}</label>
        </div>
      </div>

      <div className="card-outer1">
        <div className="card-outer card-outer-account">
          <div className="card-outer-user">
            <Modal
              className="client-modal project-modal"
              isOpen={newProfileModal}
              onRequestClose={!isSubmitLoading ? handleCloseCreateModal : undefined}
              contentLabel="Create Profile"
              style={{
                content: {
                  height: "41%",
                  width: "30%",
                  position: "fixed",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-40%, -50%)",
                  parent: document.querySelector(".admin-outer.time.tracker"),
                },
              }}
            >
              <div className="modal timeoff-modal">
                <div className="modal-close-invite outter-modal-main">
                  <a className="" onClick={handleCloseCreateModal} disabled={isSubmitLoading}>
                    <img src={crssrImage} alt="Edit Icon" />{" "}
                  </a>
                </div>
                <div className="modal-client-head">
                  <h2 className="">Create Profile Details</h2>
                </div>
                <div className="form-field-client-outer profile-details-edit">
                  <form onSubmit={handleSubmit}>

                    <div className="form-field-client">
                      <label htmlFor="wxCompany" className="form-label">
                        Company Name
                      </label>

                      <input
                        type="text"
                        id="wxCompany"
                        name="wxCompany"
                        className="form-input"
                        value={wxCompany}
                        onChange={(e) => setWxCompany(e.target.value)}
                        ref={wxCompanyRef}
                        required
                      />
                      {wxCompanyIsValid && (
                        <span className="text-red-500 text-xs italic">
                          Company Name Field Cannot be empty
                        </span>
                      )}
                    </div>
                    <div className="form-field-client">
                      <label htmlFor="legalName" className="form-label">
                        Legal Entity Company Name
                      </label>
                      <input
                        type="text"
                        id="legalName"
                        name="legalName"
                        className="form-input"
                        value={legalName}
                        onChange={(e) => setLegalName(e.target.value)}
                        ref={legalNameRef}
                        required
                      />
                      {legalNameIsValid && (
                        <span className="text-red-500 text-xs italic">
                          Legal Entity Name Field Cannot be empty
                        </span>
                      )}
                    </div>

                    <div className="form-field-client">
                      <label htmlFor="email" className="form-label">
                        Email Address
                      </label>
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="form-input"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        ref={emailRef}
                        required
                      />
                      {emailIsValid && (
                        <span className="text-red-500 text-xs italic">
                          Email Field Cannot be empty
                        </span>
                      )}
                    </div>

                    <div className="form-field-client">
                      <label htmlFor="mobileNo" className="form-label">
                        Mobile Number
                      </label>
                      <PhoneInput
                        placeholder="Enter phone number"
                        value={mobileNo}
                        onChange={setMobileNo}
                        ref={mobileNoRef}
                      />
                      {mobileNoIsValid && (
                        <span className="text-red-500 text-xs italic">
                          Mobile Number Field Cannot be empty
                        </span>
                      )}
                    </div>

                    

                    <div className="form-field-client">
                      <label htmlFor="vatNumber" className="form-label">
                        Vat Number
                      </label>
                      <input
                        type="text"
                        id="vatNumber"
                        name="vatNumber"
                        className="form-input"
                        value={vatNumber}
                        ref={vatNumberRef}
                        onChange={(e) => setVatNumber(e.target.value)}
                      />
                      {vatNumberIsValid && (
                        <span className="text-red-500 text-xs italic">
                          Vat number Cannot be empty
                        </span>
                      )}
                    </div>

                    <div className="form-field-client">
                      <label htmlFor="bankAccount" className="form-label">
                        Bank Account
                      </label>
                      <div ref={bankAccountDropdownRef} className="select-modal-mainM custom-dropdown-user-new-outer">
                        <span
                          id="bankAccount"
                          onClick={handleToggleBankAccountDropdown}
                          className="custom-dropdown-user-new"
                          ref={bankAccountRef}
                        >
                          {selectedBankAccount || "Select Bank Account"}
                        </span>
                        {bankAccountDropdownOpen && (
                          <div className="custom-dropdown-new">
                            <input
                              type="text"
                              className="search-bar"
                              placeholder={global.config.locate(appSettings && appSettings.language, 'Search')}
                              value={bankAccountSearchTerm}
                              onChange={handleBankAccountSearchInputChange}
                            />
                            <ul className="bank-account-list">
                              {filteredBankAccount.length > 0 &&
                                filteredBankAccount.map((profile) => (
                                  <li
                                    key={profile.id}
                                    className={selectedBankAccount === profile.businessName ? 'selected-item' : ''}
                                    onClick={() => {
                                      handleBankAccountSelect(profile);
                                      setBankAccountDropdownOpen(false);
                                    }}
                                  >
                                    {profile.businessName}
                                  </li>
                                ))}
                            </ul>
                          </div>
                        )}
                      </div>
                      {bankAccountIsValid && (
                        <span className="text-red-500 text-xs italic">
                          Bank Account needs to be selected
                        </span>
                      )}
                    </div>

                    <div className="form-field-client">
                      <label htmlFor="address" className="form-label">
                        Address
                      </label>
                      <textarea
                        id="address"
                        name="address"
                        className="form-input"
                        value={address}
                        ref={addressRef}
                        onChange={(e) => setAddress(e.target.value)}
                        style={{ height: '150px' }} // You can adjust the height as needed
                      />
                      {addressIsValid && (
                        <span className="text-red-500 text-xs italic">
                          Address Cannot be empty
                        </span>
                      )}
                    </div>

                    <div className="form-field-client">
                      <label htmlFor="country" className="form-label">
                        Country
                      </label>
                      <div ref={countryDropdownRef} className="select-modal-mainM custom-dropdown-user-new-outer">
                        <span
                          id="country"
                          onClick={handleToggleCountryDropdown}
                          className="custom-dropdown-user-new"
                          ref={countryRef}
                        >
                          {country ? country : (appSettings && appSettings.language ? global.config.locate(appSettings.language, 'Select Country') : null)}
                        </span>
                        {countryDropdownOpen && (
                          <div className="custom-dropdown-new">
                            <input
                              type="text"
                              className="search-bar"
                              placeholder={global.config.locate(appSettings && appSettings.language, 'Search')}
                              value={countrySearchTerm}
                              onChange={handleCountrySearchInputChange}
                            />
                            <ul className="country-list">
                              {filteredCountries.length > 0 &&
                                filteredCountries.map((c) => (
                                  <li
                                    key={c.country}
                                    className={country === c.country ? 'selected-item' : ''}
                                    onClick={() => {
                                      setCountry(c.country);
                                      setCountryDropdownOpen(false);
                                    }}
                                  >
                                    {c.country}
                                  </li>
                                ))}
                            </ul>
                          </div>
                        )}
                      </div>
                      {countryIsValid && (
                        <span className="text-red-500 text-xs italic">
                          Country needs to be selected
                        </span>
                      )}
                    </div>

                    {/* <div className="form-field-client">
                      <label htmlFor="time" className="form-label">
                        Is Vendor
                      </label>
                      <div className="radio-container">
                        <div className="radio-option yes">
                          <input
                            type="radio"
                            id="yes"
                            name="expIsBillable"
                            value={isVendor}
                            checked={isVendor === true}
                            onChange={(e) => setIsVendor(true)}
                          />
                          <label htmlFor="yes">
                            Yes <img src={Tick} alt="Tick" />
                          </label>
                        </div>
                        <div className="radio-option no">
                          <input
                            type="radio"
                            id="no"
                            name="expIsBillable"
                            value={isVendor}
                            checked={isVendor === false}
                            onChange={(e) => setIsVendor(false)}
                          />
                          <label htmlFor="no">
                            No <img src={Close} alt="Edit Icon" />
                          </label>
                        </div>
                      </div>
                    </div> */}

                    <button type="submit" className="submit-client" disabled={isSubmitLoading}>
                      {isSubmitLoading ?
                        <div className="outter-load-table">
                          <ThreeDots
                            height="18"
                            width="20"
                            radius="9"
                            color="white"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                          />
                        </div> : 'Create Profile'}
                    </button>
                  </form>
                </div>
              </div>
            </Modal>
          </div>
          <div className="modal-invite-main-outter modal-add-bank-account">
            <Modal
              className="client-modal project-modal"
              isOpen={profileEditModalOpen}
              onRequestClose={handleCloseDetailsModal}
              contentLabel="View and Edit Profile"
              style={{
                content: {
                  height: "41%",
                  width: "30%",
                  position: "fixed",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  parent: document.querySelector(".admin-outer.time.tracker"),
                },
              }}
            >
              <div className="modal">
                <div className="modal-close-invite outter-modal-main">
                  <a className="" onClick={handleCloseDetailsModal}>
                    <img src={crssrImage} alt="Edit Icon" />{" "}
                  </a>
                </div>
                <div className="project-detail-modal">
                  <div className="modal-client-head ">
                    {isProfileEdit ? (
                      <h2 className="">Edit Profile Details</h2>
                    ) : (
                      <h2 className="">Profile Details</h2>
                    )}
                  </div>
                  <div className="form-field-client-outer">
                    <form>
                      {isProfileEdit || wxCompanyView ? (
                        <div className="form-field-client">
                          <label htmlFor="wxCompanyEdit" className="form-label">
                            Company Name
                          </label>
                          {isProfileEdit ? (
                            <>
                              <input
                                type="text"
                                id="wxCompanyEdit"
                                name="wxCompanyEdit"
                                className="form-input"
                                value={wxCompanyEdit}
                                onChange={(e) => setWxCompanyEdit(e.target.value)}
                                ref={wxCompanyEditRef}
                                required
                              />
                              {wxCompanyEditIsValid && (
                                <span className="text-red-500 text-xs italic">
                                  Company Name Field Cannot be empty
                                </span>
                              )}
                            </>
                          ) : (
                            <p className="form-textarea">
                              {wxCompanyView}
                            </p>
                          )}
                        </div>
                      ) : null}

                      {isProfileEdit || legalNameView ? (
                        <div className="form-field-client">
                          <label htmlFor="legalNameEdit" className="form-label">
                            Legal Entity Company Name
                          </label>
                          {isProfileEdit ? (
                            <>
                              <input
                                type="text"
                                id="legalNameEdit"
                                name="legalNameEdit"
                                className="form-input"
                                value={legalNameEdit}
                                onChange={(e) => setLegalNameEdit(e.target.value)}
                                ref={legalNameEditRef}
                                required
                              />
                              {legalNameEditIsValid && (
                                <span className="text-red-500 text-xs italic">
                                  Legal Entity Name Field Cannot be empty
                                </span>
                              )}
                            </>
                          ) : (
                            <p className="form-textarea">
                              {legalNameView}
                            </p>
                          )}
                        </div>
                      ) : null}

                      {isProfileEdit || emailView ? (
                        <div className="form-field-client">
                          <label htmlFor="emailEdit" className="form-label">
                            Email Address
                          </label>
                          {isProfileEdit ? (
                            <>
                              <input
                                type="email"
                                id="emailEdit"
                                name="emailEdit"
                                className="form-input"
                                value={emailEdit}
                                onChange={(e) => setEmailEdit(e.target.value)}
                                ref={emailEditRef}
                                required
                              />
                              {emailEditIsValid && (
                                <span className="text-red-500 text-xs italic">
                                  Email Field Cannot be empty
                                </span>
                              )}
                            </>
                          ) : (
                            <p className="form-textarea">
                              {emailView}
                            </p>
                          )}
                        </div>
                      ) : null}

                      {isProfileEdit || mobileNoView ? (
                        <div className="form-field-client">
                          <label htmlFor="mobileNoEdit" className="form-label">
                            Mobile Number
                          </label>
                          {isProfileEdit ? (
                            <>
                              <PhoneInput
                                placeholder="Enter phone number"
                                value={mobileNoEdit}
                                onChange={setMobileNoEdit}
                                ref={mobileNoEditRef}
                              />
                              {mobileNoEditIsValid && (
                                <span className="text-red-500 text-xs italic">
                                  Mobile Number Field Cannot be empty
                                </span>
                              )}
                            </>
                          ) : (
                            <p className="form-textarea">
                              {mobileNoView}
                            </p>
                          )}
                        </div>
                      ) : null}

                      {isProfileEdit || addressView ? (
                       <div className="form-field-client">
                       <label htmlFor="addressEdit" className="form-label">
                         Address
                       </label>
                       {isProfileEdit ? (
                         <>
                           <textarea
                             id="addressEdit"
                             name="addressEdit"
                             className="form-textarea"
                             style={{ height: '150px' }}  // Adjust height as needed
                             value={addressEdit}
                             ref={addressEditRef}
                             onChange={(e) => setAddressEdit(e.target.value)}
                           />
                           {addressIsValid && (
                             <span className="text-red-500 text-xs italic">
                               Address Cannot be empty
                             </span>
                           )}
                         </>
                       ) : (
                         <p className="form-textarea">
                         <pre style={{ fontFamily: 'inherit', whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
                          { Parser().parse(addressView ? addressView : (
                             <ThreeDots
                               height="18"
                               width="20"
                               radius="9"
                               color="#6479f9"
                               ariaLabel="three-dots-loading"
                               wrapperStyle={{}}
                               wrapperClassName=""
                               visible={true}
                             />
                           ))}
                           </pre></p>
                       )}
                     </div>
                      ) : null}

                      {isProfileEdit || vatNumberView ? (
                        <div className="form-field-client">
                          <label htmlFor="vatNumberEdit" className="form-label">
                            Vat Number
                          </label>
                          {isProfileEdit ? (
                            <>
                              <input
                                type="text"
                                id="vatNumberEdit"
                                name="vatNumberEdit"
                                className="form-input"
                                value={vatNumberEdit}
                                ref={vatNumberEditRef}
                                onChange={(e) => setVatNumberEdit(e.target.value)}
                              />
                              {vatNumberIsValid && (
                                <span className="text-red-500 text-xs italic">
                                  Vat number Cannot be empty
                                </span>
                              )}
                            </>
                          ) : (
                            <p className="form-textarea">
                              {Parser().parse(vatNumberView ? (vatNumberView) : (<ThreeDots height="18" width="20" radius="9" color="#6479f9" ariaLabel="three-dots-loading" wrapperStyle={{}} wrapperClassName="" visible={true} />))}
                            </p>
                          )}
                        </div>
                      ) : null}

                      {isProfileEdit || bankAccountView ? (
                        <div className="form-field-client">
                          <label htmlFor="bankAccountEdit" className="form-label">
                            Bank Account
                          </label>
                          {isProfileEdit ? (
                            <>
                              <div ref={bankAccountEditDropdownRef} className="select-modal-mainM custom-dropdown-user-new-outer">
                                <span
                                  id="bankAccount"
                                  onClick={handleToggleBankAccountEditDropdown}
                                  className="custom-dropdown-user-new"
                                  ref={bankAccountEditRef}
                                >
                                  {bankAccountEdit
                                    ? bankAccountEdit
                                    : (appSettings && appSettings.language
                                      ? global.config.locate(appSettings.language, 'Select Bank Account')
                                      : null)}
                                </span>
                                {bankAccountEditDropdownOpen && (
                                  <div className="custom-dropdown-new">
                                    <input
                                      type="text"
                                      className="search-bar"
                                      placeholder={global.config.locate(appSettings && appSettings.language, 'Search')}
                                      value={bankAccountSearchTerm}
                                      onChange={handleBankAccountSearchInputChange}
                                    />
                                    <ul className="bank-account-list">
                                      {filteredBankAccount.length > 0 &&
                                        filteredBankAccount.map((profile) => (
                                          <li
                                            key={profile.id}
                                            className={bankAccountEdit === profile.businessName ? 'selected-item' : ''}
                                            onClick={() => {
                                              handleBankAccountEditSelect(profile);
                                              setBankAccountDropdownOpen(false);
                                            }}
                                          >
                                            {profile.businessName}
                                          </li>
                                        ))}
                                    </ul>
                                  </div>
                                )}
                              </div>

                              {bankAccountEditIsValid && (
                                <span className="text-red-500 text-xs italic">
                                  Bank Account needs to be selected
                                </span>
                              )}
                            </>
                          ) : (
                            <p className="form-input">
                              {bankAccountView ? bankAccountView : null}
                            </p>
                          )}
                        </div>
                      ) : null}

                      <div className="form-field-client">
                        {isProfileEdit || countryView ? (
                          <label htmlFor="countryEdit" className="form-label">
                            Country
                          </label>
                        ) : null}
                        {isProfileEdit ? (
                          <>
                            <div ref={countryDropdownRef} className="select-modal-mainM custom-dropdown-user-new-outer">
                              <span
                                id="country"
                                onClick={handleToggleCountryDropdown}
                                className="custom-dropdown-user-new"
                                ref={countryEditRef}
                              >
                                {countryEdit ? countryEdit : (appSettings && appSettings.language ? global.config.locate(appSettings.language, 'Select Country') : null)}
                              </span>
                              {countryDropdownOpen && (
                                <div className="custom-dropdown-new">
                                  <input
                                    type="text"
                                    className="search-bar"
                                    placeholder={global.config.locate(appSettings && appSettings.language, 'Search')}
                                    value={countrySearchTerm}
                                    onChange={handleCountrySearchInputChange}
                                  />
                                  <ul className="country-list">
                                    {filteredCountries.length > 0 &&
                                      filteredCountries.map((c) => (
                                        <li
                                          key={c.country}
                                          className={countryEdit === c.country ? 'selected-item' : ''}
                                          onClick={() => {
                                            setCountryEdit(c.country);
                                            setCountryDropdownOpen(false);
                                          }}
                                        >
                                          {c.country}
                                        </li>
                                      ))}
                                  </ul>
                                </div>
                              )}
                            </div>

                            {countryEditIsValid && (
                              <span className="text-red-500 text-xs italic">
                                Country needs to be selected
                              </span>
                            )}
                          </>
                        ) : (
                          <p className="form-input">
                            {countryView ? countryView : null}
                          </p>
                        )}
                      </div>

                      {/* <div className="form-field-client">
                        <label htmlFor="time" className="form-label">
                          Is Vendor
                        </label>
                        {isProfileEdit ? (
                          <div className="radio-container">
                            <div className="radio-option yes">
                              <input
                                type="radio"
                                id="yes"
                                name="expIsBillable"
                                value={isVendorEdit}
                                checked={isVendorEdit === true}
                                onChange={(e) => setIsVendorEdit(true)}
                              />
                              <label htmlFor="yes">
                                Yes <img src={Tick} alt="Tick" />
                              </label>
                            </div>
                            <div className="radio-option no">
                              <input
                                type="radio"
                                id="no"
                                name="expIsBillable"
                                value={isVendorEdit}
                                checked={isVendorEdit === false}
                                onChange={(e) => setIsVendorEdit(false)}
                              />
                              <label htmlFor="no">
                                No <img src={Close} alt="Edit Icon" />
                              </label>
                            </div>
                          </div>
                        ) : (
                          <p className="form-input">{isVendorView ? "Yes" : "No"}</p>
                        )}
                      </div> */}

                      {isProfileEdit ? (
                        <>
                          {isSubmitLoading ?
                            (
                              <div className="outter-load-table">
                                <ThreeDots
                                  height="38"
                                  width="40"
                                  radius="9"
                                  color="#6479f9"
                                  ariaLabel="three-dots-loading"
                                  wrapperStyle={{}}
                                  wrapperClassName=""
                                  visible={true}
                                />
                              </div>
                            ) : (
                              <div className="sprint-btn-outer">
                                <button
                                  type="button"
                                  onClick={handleResetProfileEdit}
                                  className="submit-client cancel-sprint"
                                >
                                  Cancel
                                </button>
                                <button
                                  type="button"
                                  onClick={handleProfileEditSubmit}
                                  className="submit-client save-sprint"
                                >
                                  Save Changes
                                </button>
                              </div>
                            )}
                        </>
                      ) : (
                        <div className="sprint-btn-outer">
                          <button
                            type="button"
                            className="submit-client cancel-sprint"
                            onClick={handleProfileDelete}
                            disabled={isSubmitLoading}
                          >
                            <img src={Delete} alt="Delete" />  Delete
                          </button>
                          <Modal
                            className="delete-modal"
                            isOpen={isProfileDeleteModalOpen}
                            onRequestClose={handleCancelProfileDelete}
                            contentLabel="Confirm Delete"
                            style={{
                              content: {
                                height: "41%",
                                width: "30%",
                                position: "fixed",
                                top: "36%",
                                left: "50%",
                                transform: "translate(-19.75%, 0%)",
                                parent: document.querySelector(".admin-outer.time.tracker"),
                              },
                            }}
                          >
                            <div className="delete-modal-content">
                              <h2>Confirm Delete</h2>
                              <p>
                                Are you sure you want to delete this Profile Data?
                              </p>
                              <div className="delete-modal-buttons">
                                <button className="buttons-rejected" onClick={handleConfirmProfileDelete} disabled={isSubmitLoading}>
                                  {isSubmitLoading ?
                                    <div className="outter-load-table">
                                      <ThreeDots
                                        height="18"
                                        width="20"
                                        radius="9"
                                        color="white"
                                        ariaLabel="three-dots-loading"
                                        wrapperStyle={{}}
                                        wrapperClassName=""
                                        visible={true}
                                      />
                                    </div> : "Delete"}
                                </button>
                                <button onClick={handleCancelProfileDelete} disabled={isSubmitLoading}>
                                  Cancel
                                </button>
                              </div>
                            </div>
                          </Modal>
                          <button
                            type="button"
                            onClick={handleSetProfileEdit}
                            className="submit-client save-sprint"
                          >
                            <img alt="" src={editImage} />{" "}
                            Edit Profile
                          </button>
                        </div>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </Modal>
          </div>
          <div className="card">
            {loadingCompProfiles === true ? (
              <div className="outter-load-table">
                <ThreeDots
                  height="80"
                  width="80"
                  radius="9"
                  color="#405BFF"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
              </div>
            ) : (
              <DataTable
                value={filteredData}
                removableSort
                tableStyle={{
                  minWidth: "50rem",
                  width: "100%",
                  textAlign: "left",
                }}
                paginator={filteredData.length > perPage}
                paginatorClassName="recent-invoices-pagination"
                rows={perPage}
              >
                <Column
                  body=""
                  field="wx_company"
                  sortField="id"
                  header={global.config.locate(appSettings && appSettings.language, 'Company_name')}
                  sortable
                  style={{ paddingRight: "44px", maxWidth: "297px" }}
                ></Column>
                <Column
                  field="country"
                  header="Country"
                  sortable
                  style={{ paddingRight: "20px" }}
                ></Column>
                <Column
                  field="email"
                  header="Email"
                  sortable
                  style={{ paddingRight: "20px" }}
                ></Column>
                <Column
                  field="mobile_no"
                  header="Mobile No"
                  sortable
                  style={{ paddingRight: "20px" }}
                ></Column>
                <Column
                  body={renderDetailButton}
                  style={{ width: "76px", textAlign: "center" }}
                ></Column>
              </DataTable>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Profiles;