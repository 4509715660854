
import { convertCurrencyHelper } from '../../../../helpers/forexConvertor';

export function totalInvoiced(invoices, reportingOverview, preferredCurrency, forex) {

    let totalBilledAmount = 0;
    invoices.forEach(invoice => {
        const year = reportingOverview.year || new Date().getFullYear();
        const invoiceYear = new Date(invoice?.cli_inv_end_date).getFullYear();
        const preferredClientCurrency = invoice?.cli_inv_currency || 'USD';
        const clientId = invoice.cli_inv_for._id;
        if (invoiceYear === year && !invoice.is_delete && clientId && (reportingOverview.client ? reportingOverview.client.includes(clientId) : true) && (reportingOverview.currency && reportingOverview.currency.includes(preferredClientCurrency))) {
            const invoiceCurrency = invoice.cli_inv_currency || 'USD';
            totalBilledAmount += convertCurrencyHelper(invoiceCurrency, preferredCurrency, invoice.cli_inv_total_amount, forex);
        }
    });

    return totalBilledAmount;
}

// export function clientRioHigh(trackReport, invoices, reportingOverview, preferredCurrency, forex, typeOfContract, expenses) {

//     const totalBilled = totalInvoiced(invoices, reportingOverview, preferredCurrency, forex);
//     const clientData = {};

//     trackReport.forEach(entry => {
//         const { project, clientId, user, startTime, duration } = entry;
//         const year = reportingOverview.year || new Date().getFullYear();
//         const projectYear = new Date(startTime).getFullYear();
//         const projectId = project && project._id;

//         if (projectYear === year && project && !project.is_delete && reportingOverview.project.includes(projectId) && reportingOverview.currency.includes(clientId?.preferred_currency || 'USD')) {

//             const preferredClientCurrency = clientId?.preferred_currency || 'USD';
//             if (!clientData[clientId?._id]) {
//                 clientData[clientId?._id] = {
//                     budget: 0,
//                     cost: 0,
//                     details: clientId
//                 };
//             }

//             clientData[clientId?._id].budget += convertCurrencyHelper(preferredClientCurrency, preferredCurrency, project.budget, forex);
//             const userCost = user.cost || 0;
//             const userOverheadCost = user.overhead_cost || 0;
//             const userTypeOfContract = user.typeOfContract || typeOfContract.Hourly_Rate;
//             const paymentCurrency = user.payment_currency || 'USD';

//             let finalCost;
//             if (userTypeOfContract === typeOfContract.Hourly_Rate) {
//                 finalCost = userCost;
//             } else {
//                 finalCost = userCost / 168; // Assuming a different type of contract with monthly rate divided by 168 hours
//             }

//             const cost = (finalCost * (duration / 3600)) + (userOverheadCost * (duration / 3600));
//             clientData[clientId?._id].cost += convertCurrencyHelper(paymentCurrency, preferredCurrency, cost, forex);
//         }
//     });

//     var totalExpense = 0;
//     expenses.forEach(expense => {
//         const clientId = expense?.exp_client?._id;
//         const year = reportingOverview.year || new Date().getFullYear();
//         const expenseYear = new Date(expense.exp_date).getFullYear();
//         // if (reportingOverview.currency.includes(expense?.exp_currency || 'USD')&& clientId && (reportingOverview.client ? reportingOverview.client.includes(clientId) : true)) {
//         if (expenseYear === year && !expense.is_delete && clientId && (reportingOverview.client ? reportingOverview.client.includes(clientId) : true)) {
//             totalExpense += convertCurrencyHelper(expense.exp_currency, preferredCurrency, expense.exp_amount, forex);
//         }

//     });

//     let highestROIClient = null;
//     for (const clientId in clientData) {
//         const client = clientData[clientId];
//         const roi = ((totalBilled - (client.cost + totalExpense)) / (client.cost + totalExpense)) * 100;
//         client.roi = roi;
//         client.totalExpense = totalExpense;
//         if (!highestROIClient || roi > highestROIClient.roi) {
//             highestROIClient = client;
//         }
//     }
//     return highestROIClient;
// }

export function clientRioHigh(trackReport, projectCost, invoices, reportingOverview, preferredCurrency, forex, typeOfContract, expenses) {
    const currentYear = reportingOverview.year || new Date().getFullYear();
    const clientData = {};

    // Calculate total billed amount per client
    const totalBilledAmountPerClient = {};
    invoices.forEach(invoice => {
        const invoiceYear = new Date(invoice?.cli_inv_end_date).getFullYear();
        const clientId = invoice.cli_inv_for._id;
        const preferredClientCurrency = invoice?.cli_inv_currency || 'USD';

        if (invoiceYear === currentYear && !invoice.is_delete && clientId && (reportingOverview.client && reportingOverview.client.includes(clientId)) && reportingOverview.currency.includes(preferredClientCurrency)) {
            const invoiceCurrency = invoice.cli_inv_currency || 'USD';
            const convertedAmount = convertCurrencyHelper(invoiceCurrency, preferredCurrency, invoice.cli_inv_total_amount, forex);

            if (!totalBilledAmountPerClient[clientId]) {
                totalBilledAmountPerClient[clientId] = 0;
            }
            totalBilledAmountPerClient[clientId] += convertedAmount;
        }
    });

    // Calculate project costs per client
    trackReport.forEach(entry => {
        const { project, clientId, user, startTime, duration } = entry;
        const projectYear = new Date(startTime).getFullYear();
        const projectId = project && project._id;

        if (projectYear === currentYear && project && !project.is_delete && (reportingOverview.project && reportingOverview.project.includes(projectId)) && (reportingOverview.client && reportingOverview.client.includes(clientId?._id)) && (reportingOverview.currency && reportingOverview.currency.includes(clientId?.preferred_currency || 'USD'))) {
            const preferredClientCurrency = clientId?.preferred_currency || 'USD';
            if (!clientData[clientId?._id]) {
                clientData[clientId?._id] = {
                    budget: 0,
                    cost: 0,
                    totalExpense: 0,
                    details: clientId
                };
            }

            // clientData[clientId?._id].budget += convertCurrencyHelper(preferredClientCurrency, preferredCurrency, project.budget, forex);
            const projectSpecificCost = projectCost.find(cost => cost.userId === user._id && cost.projectId === projectId && !cost.is_delete);
            // const userCost = projectSpecificCost && projectSpecificCost.amount > 0 ? projectSpecificCost.amount : (user.cost || 0);
            let userCost;
            if (projectSpecificCost && projectSpecificCost.amount > 0) {
                userCost = convertCurrencyHelper(
                    preferredClientCurrency,
                    user.payment_currency,
                    projectSpecificCost.amount,
                    forex
                );
            } else {
                userCost = user.cost || 0;
            }
            const userOverheadCost = user.overhead_cost || 0;
            const userTypeOfContract = user.typeOfContract || typeOfContract.Hourly_Rate;
            const paymentCurrency = user.payment_currency || 'USD';

            let finalCost;
            if (userTypeOfContract === typeOfContract.Hourly_Rate) {
                finalCost = userCost;
            } else {
                finalCost = userCost / parseInt(user?.monthly_hours ? user?.monthly_hours : 168); // Assuming a different type of contract with monthly rate divided by 168 hours
            }

            const cost = (finalCost * (duration / 3600)) + (userOverheadCost * (duration / 3600));
            clientData[clientId?._id].cost += convertCurrencyHelper(paymentCurrency, preferredCurrency, cost, forex);
            // // Ensure clientData is defined and contains the specific client ID
            // const cliId = '64995ed19df980f6dae4c0e6'; // Replace with the actual client ID you want to check
            // const client = clientData ? clientData[cliId] : null;

            // if (client) {
            //     console.log(`Cost for client ${cliId}: ${client.cost}`);
            //     console.log("projectId", projectId);
            // } else {
            //     console.warn(`Client with ID ${cliId} not found in clientData`);
            // }
        }
    });

    // Calculate total expenses per client
    expenses.forEach(expense => {
        const clientId = expense?.exp_client?._id;
        const expenseYear = new Date(expense.exp_date).getFullYear();

        if (expenseYear === currentYear && !expense.is_delete && clientId && (reportingOverview.client ? reportingOverview.client.includes(clientId) : true)) {
            const convertedAmount = convertCurrencyHelper(expense.exp_currency, preferredCurrency, expense.exp_amount, forex);

            if (!clientData[clientId]) {
                clientData[clientId] = {
                    budget: 0,
                    cost: 0,
                    totalExpense: 0,
                    details: expense.exp_client
                };
            }

            clientData[clientId].totalExpense += convertedAmount;
        }
    });

    // Calculate ROI and find the client with the highest ROI
    let highestROIClient = null;
    for (const clientId in clientData) {
        const client = clientData[clientId];
        const totalBilled = totalBilledAmountPerClient[clientId] || 0;
        const totalCostAndExpense = client.cost + client.totalExpense;
        const roi = totalCostAndExpense !== 0 ? (((totalBilled - totalCostAndExpense) / totalCostAndExpense) * 100) : 0;

        client.roi = roi;

        if (!highestROIClient || roi > highestROIClient.roi) {
            highestROIClient = client;
        }
    }

    return highestROIClient;
}

export function clientRioLow(trackReport, projectCost, invoices, reportingOverview, preferredCurrency, forex, typeOfContract, expenses) {
    const currentYear = reportingOverview.year || new Date().getFullYear();
    const clientData = {};

    // Calculate total billed amount per client
    const totalBilledAmountPerClient = {};
    invoices.forEach(invoice => {
        const invoiceYear = new Date(invoice?.cli_inv_end_date).getFullYear();
        const clientId = invoice.cli_inv_for._id;
        const preferredClientCurrency = invoice?.cli_inv_currency || 'USD';

        if (invoiceYear === currentYear && !invoice.is_delete && clientId && (reportingOverview.client ? reportingOverview.client.includes(clientId) : true) && (reportingOverview.currency && reportingOverview.currency.includes(preferredClientCurrency))) {
            const invoiceCurrency = invoice.cli_inv_currency || 'USD';
            const convertedAmount = convertCurrencyHelper(invoiceCurrency, preferredCurrency, invoice.cli_inv_total_amount, forex);

            if (!totalBilledAmountPerClient[clientId]) {
                totalBilledAmountPerClient[clientId] = 0;
            }
            totalBilledAmountPerClient[clientId] += convertedAmount;
        }
    });

    // Calculate project costs per client
    trackReport.forEach(entry => {
        const { project, clientId, user, startTime, duration } = entry;
        const projectYear = new Date(startTime).getFullYear();
        const projectId = project && project._id;

        if (projectYear === currentYear && project && !project.is_delete && (reportingOverview.project && reportingOverview.project.includes(projectId)) && (reportingOverview.currency && reportingOverview.currency.includes(clientId?.preferred_currency || 'USD'))) {
            const preferredClientCurrency = clientId?.preferred_currency || 'USD';

            if (!clientData[clientId?._id]) {
                clientData[clientId?._id] = {
                    budget: 0,
                    cost: 0,
                    totalExpense: 0,
                    details: clientId
                };
            }

            // clientData[clientId?._id].budget += convertCurrencyHelper(preferredClientCurrency, preferredCurrency, project.budget, forex);
            // const userCost = user.cost || 0;
            const projectSpecificCost = projectCost.find(cost => cost.userId === user._id && cost.projectId === projectId && !cost.is_delete);
            // const userCost = projectSpecificCost && projectSpecificCost.amount > 0 ? projectSpecificCost.amount : (user.cost || 0);
            let userCost;
            if (projectSpecificCost && projectSpecificCost.amount > 0) {
                userCost = convertCurrencyHelper(
                    preferredClientCurrency,
                    user.payment_currency,
                    projectSpecificCost.amount,
                    forex
                );
            } else {
                userCost = user.cost || 0;
            }
            const userOverheadCost = user.overhead_cost || 0;
            const userTypeOfContract = user.typeOfContract || typeOfContract.Hourly_Rate;
            const paymentCurrency = user.payment_currency || 'USD';

            let finalCost;
            if (userTypeOfContract === typeOfContract.Hourly_Rate) {
                finalCost = userCost;
            } else {
                finalCost = userCost / parseInt(user?.monthly_hours ? user?.monthly_hours : 168); // Assuming a different type of contract with monthly rate divided by 168 hours
            }

            const cost = (finalCost * (duration / 3600)) + (userOverheadCost * (duration / 3600));
            clientData[clientId?._id].cost += convertCurrencyHelper(paymentCurrency, preferredCurrency, cost, forex);
        }
    });

    // Calculate total expenses per client
    expenses.forEach(expense => {
        const clientId = expense?.exp_client?._id;
        const expenseYear = new Date(expense.exp_date).getFullYear();

        if (expenseYear === currentYear && !expense.is_delete && clientId && (reportingOverview.client ? reportingOverview.client.includes(clientId) : true)) {
            const convertedAmount = convertCurrencyHelper(expense.exp_currency, preferredCurrency, expense.exp_amount, forex);

            if (!clientData[clientId]) {
                clientData[clientId] = {
                    budget: 0,
                    cost: 0,
                    totalExpense: 0,
                    details: expense.exp_client
                };
            }

            clientData[clientId].totalExpense += convertedAmount;
        }
    });

    // Calculate ROI and find the client with the lowest ROI
    let lowestROIClient = null;
    for (const clientId in clientData) {
        const client = clientData[clientId];
        const totalBilled = totalBilledAmountPerClient[clientId] || 0;
        const totalCostAndExpense = client.cost + client.totalExpense;
        const roi = totalCostAndExpense !== 0 ? (((totalBilled - totalCostAndExpense) / totalCostAndExpense) * 100) : 0;

        client.roi = roi;

        if (!lowestROIClient || roi < lowestROIClient.roi) {
            lowestROIClient = client;
        }
    }

    return lowestROIClient;
}

// export function clientRoiOverviewPerClient(trackReport, invoices, reportingOverview, preferredCurrency, forex, typeOfContract, expenses) {
//     const currentYear = reportingOverview.year || new Date().getFullYear();
//     const calculateClientROI = (projects) => {
//         const clientData = {};
//         // const totalBilled = totalInvoiced(invoices, reportingOverview, preferredCurrency, forex);

//         projects.forEach(project => {
//             const projectYear = new Date(project.startTime).getFullYear();
//             const preferredClientCurrency = project?.clientId?.preferred_currency || 'USD';

//             if (projectYear === currentYear && !project?.project?.is_delete && reportingOverview.project.includes(project?.project?._id) && reportingOverview.currency.includes(preferredClientCurrency)) {
//                 const clientId = project?.clientId?._id;
//                 // const budget = convertCurrencyHelper(preferredClientCurrency, preferredCurrency, project.project.budget, forex);
//                 const totalBilledAmountPerClient = {};

//                 invoices.forEach(invoice => {
//                     const year = reportingOverview.year || new Date().getFullYear();
//                     const invoiceYear = new Date(invoice?.cli_inv_end_date).getFullYear();
//                     const preferredClientCurrency = invoice?.cli_inv_currency || 'USD';
//                     if (invoiceYear === year && !invoice.is_delete && clientId && (reportingOverview.client ? reportingOverview.client.includes(clientId) : true) && reportingOverview.currency.includes(preferredClientCurrency || 'USD')) {
//                         const invoiceCurrency = invoice.cli_inv_currency || 'USD';
//                         const convertedAmount = convertCurrencyHelper(invoiceCurrency, preferredCurrency, invoice.cli_inv_total_amount, forex);

//                         if (!totalBilledAmountPerClient[clientId]) {
//                             totalBilledAmountPerClient[clientId] = 0;
//                         }
//                         totalBilledAmountPerClient[clientId] += convertedAmount;
//                     }
//                 });
//                 const userCost = project.user.cost || 0;
//                 const userTypeOfContract = project.user.typeOfContract || typeOfContract.Hourly_Rate;
//                 const userOverheadCost = project.user.overhead_cost || 0;
//                 const paymentCurrency = project.user.payment_currency || 'USD';
//                 let finalCost;
//                 if (userTypeOfContract === typeOfContract.Hourly_Rate) {
//                     finalCost = userCost;
//                 } else {
//                     finalCost = userCost / 168; // Assuming a different type of contract with monthly rate divided by 168 hours
//                 }
//                 const cost = (finalCost * (project.duration / 3600)) + (userOverheadCost * (project.duration / 3600));
//                 // const cost = (userCost + userOverheadCost) * (project.duration / 3600);
//                 const costConverted = convertCurrencyHelper(paymentCurrency, preferredCurrency, cost, forex);

//                 if (!clientData[clientId]) {
//                     clientData[clientId] = {
//                         name: project.clientId?.client_name,
//                         totalBilledAmountPerClient: 0,
//                         cost: 0
//                     };
//                 }

//                 clientData[clientId].totalBilledAmountPerClient += totalBilledAmountPerClient;
//                 clientData[clientId].cost += costConverted;
//             }
//         });

//         var totalExpense = 0;
//         expenses.forEach(expense => {
//             const clientId = expense?.exp_client?._id;
//             const year = reportingOverview.year || new Date().getFullYear();
//             const expenseYear = new Date(expense.exp_date).getFullYear();
//             // if (reportingOverview.currency.includes(expense?.exp_currency || 'USD')&& clientId && (reportingOverview.client ? reportingOverview.client.includes(clientId) : true)) {
//             if (expenseYear === year && !expense.is_delete && clientId && (reportingOverview.client ? reportingOverview.client.includes(clientId) : true)) {
//                 totalExpense += convertCurrencyHelper(expense.exp_currency, preferredCurrency, expense.exp_amount, forex);
//             }

//         });

//         // return Object.values(clientData).map(client => ({
//         //     name: client.name,
//         //     roi: client.cost ? ((totalBilled - (client.cost + totalExpense)) / (client.cost + totalExpense)).toFixed(2) * 100 : 0
//         // }));
//         return Object.values(clientData).map(client => {
//             const totalCostAndExpense = client.cost + totalExpense;
//             const totalBilled = client.totalBilledAmountPerClient
//             const roi = totalCostAndExpense !== 0 ? (((totalBilled - totalCostAndExpense) / totalCostAndExpense) * 100).toFixed(2) : 0;

//             return {
//                 name: client.name,
//                 roi: parseFloat(roi) // Ensuring the ROI is a number
//             };
//         });
//     };
//     return calculateClientROI(trackReport);
// };

export function clientRoiOverviewPerClient(trackReport, projectCost, invoices, reportingOverview, preferredCurrency, forex, typeOfContract, expenses) {
    const currentYear = reportingOverview.year || new Date().getFullYear();

    const calculateClientROI = (projects) => {
        const clientData = {};

        // Calculate total billed amount per client
        const totalBilledAmountPerClient = {};
        invoices.forEach(invoice => {
            const invoiceYear = new Date(invoice?.cli_inv_end_date).getFullYear();
            const preferredClientCurrency = invoice?.cli_inv_currency || 'USD';
            const clientId = invoice.cli_inv_for._id;

            if (invoiceYear === currentYear && !invoice.is_delete && clientId && (reportingOverview.client ? reportingOverview.client.includes(clientId) : true) && (reportingOverview.currency && reportingOverview.currency.includes(preferredClientCurrency))) {
                const invoiceCurrency = invoice.cli_inv_currency || 'USD';
                const convertedAmount = convertCurrencyHelper(invoiceCurrency, preferredCurrency, invoice.cli_inv_total_amount, forex);

                if (!totalBilledAmountPerClient[clientId]) {
                    totalBilledAmountPerClient[clientId] = 0;
                }
                totalBilledAmountPerClient[clientId] += convertedAmount;
            }
        });

        // Process each project
        projects.forEach(project => {
            const projectYear = new Date(project.startTime).getFullYear();
            const preferredClientCurrency = project?.clientId?.preferred_currency || 'USD';

            if (projectYear === currentYear && (project?.project && !project?.project?.is_delete) && (reportingOverview.project && reportingOverview.project.includes(project?.project?._id)) && (reportingOverview.currency && reportingOverview.currency.includes(preferredClientCurrency))) {
                const clientId = project?.clientId?._id;
                // const userCost = project.user.cost || 0;
                const paymentCurrency = project?.user?.payment_currency || 'USD';
                const projectSpecificCost = projectCost.find(cost => cost.userId === project?.user?._id && cost.projectId === project?.project?._id && !cost.is_delete);
                // const userCost = projectSpecificCost && projectSpecificCost.amount > 0 ? projectSpecificCost.amount : (project.user?.cost || 0);
                let userCost;
                if (projectSpecificCost && projectSpecificCost.amount > 0) {
                    userCost = convertCurrencyHelper(
                        preferredClientCurrency,
                        paymentCurrency,
                        projectSpecificCost.amount,
                        forex
                    );
                } else {
                    userCost = project.user?.cost || 0;
                }
                const userTypeOfContract = project?.user?.typeOfContract || typeOfContract.Hourly_Rate;
                const userOverheadCost = project.user.overhead_cost || 0;

                let finalCost;
                if (userTypeOfContract === typeOfContract.Hourly_Rate) {
                    finalCost = userCost;
                } else {
                    finalCost = userCost / parseInt(project?.user?.monthly_hours ? project?.user?.monthly_hours : 168); // Assuming a different type of contract with monthly rate divided by 168 hours
                }
                const cost = (finalCost * (project.duration / 3600)) + (userOverheadCost * (project.duration / 3600));
                const costConverted = convertCurrencyHelper(paymentCurrency, preferredCurrency, cost, forex);

                if (!clientData[clientId]) {
                    clientData[clientId] = {
                        name: project?.clientId?.client_name,
                        cost: 0,
                        totalBilled: totalBilledAmountPerClient[clientId] || 0,
                        totalExpense: 0
                    };
                }

                clientData[clientId].cost += costConverted;
            }
        });

        // Calculate total expenses per client
        expenses.forEach(expense => {
            const clientId = expense?.exp_client?._id;
            const expenseYear = new Date(expense.exp_date).getFullYear();

            if (expenseYear === currentYear && !expense.is_delete && clientId && (reportingOverview.client ? reportingOverview.client.includes(clientId) : true)) {
                const convertedAmount = convertCurrencyHelper(expense.exp_currency, preferredCurrency, expense.exp_amount, forex);

                if (!clientData[clientId]) {
                    clientData[clientId] = {
                        name: expense.exp_client.client_name,
                        cost: 0,
                        totalBilled: totalBilledAmountPerClient[clientId] || 0,
                        totalExpense: 0
                    };
                }

                clientData[clientId].totalExpense += convertedAmount;
            }
        });

        // Calculate ROI for each client
        return Object.values(clientData).map(client => {
            const totalCostAndExpense = client.cost + client.totalExpense;
            const roi = totalCostAndExpense !== 0 ? (((client.totalBilled - totalCostAndExpense) / totalCostAndExpense) * 100).toFixed(2) : 0;

            return {
                name: client.name,
                roi: parseFloat(roi) // Ensuring the ROI is a number
            };
        });
    };

    return calculateClientROI(trackReport);
};