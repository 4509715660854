import { useSelector, useDispatch } from "react-redux";
import React, { useEffect, useRef } from 'react';
import ClientMonthGraph from '../../components/Clients/Dashboard/ClientGraph.jsx';
import FilterClientCalendar from '../../components/Clients/Dashboard/FilterClientCalendar';
import ClientAmountOverview from '../../components/Clients/Dashboard/ClientAmountOverview';
import RecentClientInvoices from "../../components/Clients/Dashboard/RecentClientInvoice.jsx";
import { motion } from 'framer-motion';

export default function ClientDashboard() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user)
  const sum = useSelector((state) => state.invoice.clientDashboardCurrencySum)
  const componentRef = useRef(null);
  useEffect(() => {
    componentRef.current.scrollTop = 0;
  }, [dispatch, user]);

  const fadeIn = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 }
  };

  return (
    <motion.div
      ref={componentRef}
      initial="hidden"
      animate="visible"
      variants={fadeIn}
      transition={{ duration: 0.3 }}
    >
      <div ref={componentRef}>
        <>
          <div className='filter-sec'>
            <FilterClientCalendar sum={sum} />
          </div>
          <div className='graph-overview'>
            <div className='overview-left'>
              <ClientAmountOverview sum={sum} />
            </div>
            <ClientMonthGraph sum={sum} />
          </div>
          <div className='recent-invoices'>
            <div className='recent-invoice-inner'>
              <RecentClientInvoices sum={sum} />
            </div>
          </div>
        </>
      </div>
    </motion.div>
  )
}