import axios from "axios";
import "./MasterUserRegister.css";
import { AES } from 'crypto-js';
import { toast } from "react-toastify";
import { useDispatch, useSelector } from 'react-redux';
import { DatePicker } from "rsuite";
import { subYears } from "date-fns";
import Modal from "react-modal";
import "react-toastify/dist/ReactToastify.css";
import { ThreeDots } from "react-loader-spinner";
import { RotatingLines } from 'react-loader-spinner';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Type from "../../../../images/icons/type.svg";
import Cost from "../../../../images/icons/cost1.svg";
import Tele from "../../../../images/icons/tele.svg";
import { Link, useNavigate } from "react-router-dom";
import Countries from "../../../../constants/countries.js";
import userTypeName from "../../../../constants/userType.js";
import EmailIcon from "../../../../images/icons/email.svg";
import PassLock from "../../../../images/icons/pass_lock.svg";
import Tick from "../../../../images/icons/tick-arrow.svg";
import location from "../../../../images/icons/location.svg";
import React, { useEffect, useRef, useState, createRef } from "react";
import Currencies from "../../../../constants/currenciesList.js";
import userCategoryName from "../../../../constants/userCategory.js";
import PostalCode from "../../../../images/icons/postal-code.svg";
import PassportlIcon from "../../../../images/icons/passport.svg";
import birthIcon from "../../../../images/icons/dat-of-birth.svg";
import PicEdit from "../../../../images/icons/profile-pic-edit.svg";
import profile_image from "../../../../images/user/Default_pfp.png";
import { logout, reset } from "../../../../features/auth/authSlice.js";
import UserIcon from "../../../../images/icons/single-user-icon.svg";
import TypeContact from "../../../../images/icons/type-of-contact.svg";
import EyeIcon from "../../../../images/icons/eye-icon.svg";
import EyeCrossed from "../../../../images/icons/eye-crossed.svg";
import { reset as invoiceReset } from "../../../../features/invoice/invoiceSlice.js";
import { setNotification } from "../../../../features/notification/notificationSlice.js";
import { reset as headerReset } from "../../../../features/headerText/headerTextSlice.js";
import { reset as appSettingsReset } from "../../../../features/appSettings/appSettingsSlice.js";
import { useMasterUserType } from "../MasterUserProfile/useMasterUserView.jsx";


export default function MasterUserRegister() {
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const [userType, setUserType] = useState("");
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [profileImage, setProfileImage] = useState(null);
  const [profileImageName, setProfileImageName] = useState(null);
  const [dob, setDob] = useState("");
  const [passport, setPassport] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [postCode, setPostCode] = useState("");
  const [address, setAddress] = useState("");
  const [uploadedImageUrl, setUploadedImageUrl] = useState("");
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [image, setImage] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [fileSelectIsLoading, setFileSelectIsLoading] = useState(false);
  const [userTypeDropdownOpen, setUserTypeDropdownOpen] = useState(false);
  const [countrySearchTerm, setCountrySearchTerm] = useState('');
  const [countryDropdownOpen, setCountryDropdownOpen] = useState(false);
  const [stateSearchTerm, setStateSearchTerm] = useState('');
  const [stateDropdownOpen, setStateDropdownOpen] = useState(false);
  const cropperRef = createRef();
  const [countriesList, setCountriesList] = useState([]);
  const [passwordMatchError, setPasswordMatchError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const passwordConfirmationRef = useRef(null);
  const passwordRef = useRef(null);
  const userTypeDropdownRef = useRef(null);
  const countryDropdownRef = useRef(null);
  const stateDropdownRef = useRef(null);
  const navigate = useNavigate();
  const { appSettings } = useSelector((state) => state.appSettings);
  const url = new URL(window.location.href); // Get the current URL
  const subdomain = url.hostname.split('.')[0];
  const capitalizedSubdomain = subdomain.charAt(0).toUpperCase() + subdomain.slice(1);
  const SUPER_ADMIN = 1;
  const ADMIN = 2;
  let _id = null;
  const user = localStorage.getItem("masterUser");
  const userData = useSelector((state) => state.masterAuth.masterUser);
  if (user) {
    const parsedUser = JSON.parse(user);
    if (parsedUser && parsedUser.token && parsedUser._id) {
      _id = parsedUser._id;
    }
  }

  const { data: roleId = [], isLoading: userTypeLoading, isError: userTypeError } = useMasterUserType();

  useEffect(() => {
    const sortedCountries = Countries.slice().sort((a, b) => a.country.localeCompare(b.country));
    setCountriesList(sortedCountries);
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        document.activeElement.blur();
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitLoading(true);
    const mobileNoRegex = /^[+\d]+$/;
    if (!mobileNoRegex.test(mobileNo)) {
      toast.error("It's not a valid phone number", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setIsSubmitLoading(false);
      return;
    }

    if (password !== passwordConfirmation) {
      setPasswordMatchError("Passwords do not match");
      passwordConfirmationRef.current.focus();
      setIsSubmitLoading(false);
      return;
    }
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#\$%\^&\*\(\)_\+\-\.\/:;<=>?@[\\\]^_`{|}~])[A-Za-z\d!@#\$%\^&\*\(\)_\+\-\.\/:;<=>?@[\\\]^_`{|}~]{8,}$/;
    if (!passwordRegex.test(password)) {
      setPasswordError("Invalid password format");
      toast.error(
        "Password must be at least 8 charachters long and include atleast one uppercase letter ,one lowercase letter,one digit and one special character from the set !@#$%^&*()_+,-/:;<=>?@_'[\]{|}~", {
        position: "bottom-right",
        autoClose: 3800,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      passwordRef.current.focus();
      setIsSubmitLoading(false);
      return;
    }

    const fileExtension = profileImageName
      ? profileImageName.name.split(".").pop()
      : "";
    const uniqueSuffix = Date.now() + "-" + Math.round(Math.random() * 1e9);
    const profile_img_fileName = profileImageName
      ? `${profileImageName.name
        .split(".")
        .shift()}_${uniqueSuffix}.${fileExtension}`
      : null;

    if (lastName) {

      if (lastName.length <= 1) {

        toast.error("A last name should have more than one character.", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setIsSubmitLoading(false);
        return;
      }
    }

    const requestData = {
      first_name: firstName,
      middle_name: middleName,
      last_name: lastName,
      email,
      mobile_no: mobileNo,
      passport_no: passport,
      password,
      role_id: userType,
      address,
      pincode: postCode,
      dob,
      country,
      state,
      city,
      profile_image: profile_img_fileName,
      created_by: _id,
    };

    try {
      const response = await axios.post("/api/create/master/user", requestData);
      const data = response.data;
      if (data.status === "exists") {
        toast.warning("User with this mail id already exists", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (data.status === "error") {
        toast.error("Something went wrong", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.success("User registered successfully", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        global.config.activityLog(
          window.location.href,
          "User",
          `New User "${firstName} " registered Successfully!`
        );
        dispatch(setNotification(Date.now()));
        const encryptedId = AES.encrypt(data._id, 'encryptionKey').toString();
        const encodedId = encodeURIComponent(encryptedId).replace(/\//g, '%2F');
        navigate(`../profile/${encodedId}`);
      }

      if (profileImage) {
        const resizedImage = await resizeImage(profileImage, 500);
        const base64Image = resizedImage.split(',')[1];
        const blob = await fetch(`data:image/png;base64,${base64Image}`).then(res => res.blob());

        const formData = new FormData();
        formData.append("profile_image", blob, profile_img_fileName);

        try {
          await axios.post("/api/uploads/profileImage", formData);
          // Handle the response if needed
        } catch (error) {
          console.log(error);
          global.config.slackMessage(error.toString());
          alert("Something went wrong while uploading profile photo");
        }
      }
    } catch (error) {
      console.log(error);
      global.config.slackMessage(error.toString());
      if (error.response?.data?.status === "deleted") {
        localStorage.removeItem("user");
        dispatch(logout());
        dispatch(reset());
        dispatch(invoiceReset());
        dispatch(headerReset());
        dispatch(appSettingsReset());
        toast.error('This user is deleted!', {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/");
        return;
      }
      toast.error('Something went wrong while registering user!', {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } finally {
      setIsSubmitLoading(false);
    }
  };

  const handleProfilePicChange = () => {
    setFileSelectIsLoading(true);
    // Open file input dialog
    fileInputRef.current.click();
  };

  const handleFileInputCancel = () => {
    // Handle the case where the file selection window is canceled
    setFileSelectIsLoading(false);
  };

  // Attach event listeners to the file input
  useEffect(() => {
    const inputElement = fileInputRef.current;

    const handleChange = () => {
      handleFileInputChange({ target: { files: inputElement.files } });
    };

    const handleCancel = () => {
      handleFileInputCancel();
    };

    inputElement.addEventListener('change', handleChange);
    inputElement.addEventListener('cancel', handleCancel);

    return () => {
      inputElement.removeEventListener('change', handleChange);
      inputElement.removeEventListener('cancel', handleCancel);
    };
  }, []);

  const handleFileInputChange = (event) => {
    setFileSelectIsLoading(false);
    const file = event.target.files[0];
    setProfileImageName(event.target.files[0])
    if (file) {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        setImage(fileReader.result);
        setModalIsOpen(true);
      };
      fileReader.readAsDataURL(file);
    }
  };

  const closeModal = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    setFileSelectIsLoading(false);
    setModalIsOpen(false);
  };

  const handleCropClick = () => {
    if (typeof cropperRef.current?.cropper !== "undefined") {
      const croppedImage = (cropperRef.current?.cropper.getCroppedCanvas().toDataURL());
      setUploadedImageUrl(croppedImage);
      setProfileImage(croppedImage);
    }
    closeModal();
  };

  const handleCountrySearchInputChange = (e) => {
    setCountrySearchTerm(e.target.value);
  };

  const filteredCountries = countriesList.filter((country) => {
    return country.country.toLowerCase().includes(countrySearchTerm.toLowerCase());
  });

  const handleStateSearchInputChange = (e) => {
    setStateSearchTerm(e.target.value);
  };

  const selectedCountry = countriesList.find((c) => c.country === country);
  const filteredStates = selectedCountry?.states?.filter((stateObj) =>
    stateObj.state.toLowerCase().includes(stateSearchTerm.toLowerCase())
  );

  const handleClickOutsideDropdown = (event) => {
    const isClickInsideUserTypeDropdown = userTypeDropdownRef.current && userTypeDropdownRef.current.contains(event.target);
    const isClickInsideCountryDropdown = countryDropdownRef.current && countryDropdownRef.current.contains(event.target);
    const isClickInsideStateDropdown = stateDropdownRef.current && stateDropdownRef.current.contains(event.target);
    if (!isClickInsideUserTypeDropdown) {
      setUserTypeDropdownOpen(false);
    }
    if (!isClickInsideCountryDropdown) {
      setCountryDropdownOpen(false);
    }
    if (!isClickInsideStateDropdown) {
      setStateDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutsideDropdown);
    return () => {
      document.removeEventListener('click', handleClickOutsideDropdown);
    };
  }, []);

  // Function to resize the image
  const resizeImage = async (base64Image, maxWidth) => {
    return new Promise((resolve) => {
      const image = new Image();
      image.src = base64Image;

      image.onload = () => {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        const aspectRatio = image.width / image.height;
        const newHeight = maxWidth / aspectRatio;

        canvas.width = maxWidth;
        canvas.height = newHeight;

        context.drawImage(image, 0, 0, maxWidth, newHeight);

        const resizedImage = canvas.toDataURL('image/jpeg'); // Change to 'image/png' if needed
        resolve(resizedImage);
      };
    });
  };

  const countryData = countriesList.find((c) => c.country === country);
  const states = countryData?.states || [];
  const numberInputOnWheelPreventChange = (e) => {
    // Prevent the input value change
    e.target.blur()

    // Prevent the page/container scrolling
    e.stopPropagation()

    setTimeout(() => {
      e.target.focus()
    }, 0)
  }
  return (
    <div className="user-register-client">
      <div className='back-to'>
        <Link to="/master-user/list">
          <span >
            Back to All Members
          </span>
        </Link>
      </div>
      <div className='tab-outer'>
        <ul className="tabs">
          <li className='active-tab'>
            Register New User
          </li>
        </ul>
        <div className="tabs-content">
          <div className="profile-details-edit" >
            <form onSubmit={handleSubmit}>
              <div className="profile-photo-outer">
                <div className="profile-photo">
                  <div className="profile-photo-inner">
                    <div className="profile-photo-sec">
                      {uploadedImageUrl ? (
                        <div className="profile-image">
                          <img src={uploadedImageUrl} alt="Uploaded Profile" />
                        </div>
                      ) : (
                        <div className="profile-image">
                          <img src={profile_image} alt="User Profile" />
                        </div>
                      )}
                      <div className="profile-pic-edit">
                        <a href="#" className="pic-edit" onClick={!fileSelectIsLoading && handleProfilePicChange}>
                          {fileSelectIsLoading ? (
                            <RotatingLines
                              strokeColor="grey"
                              strokeWidth="5"
                              animationDuration="0.75"
                              width="18"
                              visible={true}
                            />
                          ) : (
                            <img src={PicEdit} alt="edit" />
                          )}
                        </a>
                      </div>
                    </div>
                    <div className="profile-name">
                      <div className="profile-name-sec">
                        {firstName ? firstName : "First Name"}{" "}
                        {middleName ? middleName : ""}{" "}
                        {lastName ? lastName : ""}{" "}
                      </div>
                      {userType ? (
                        userType === userTypeName.Super_Admin ? (
                          <div className="profile-type">
                            Super Admin
                          </div>
                        ) : userType === userTypeName.Admin ? (
                          <div className="profile-type">
                            Admin
                          </div>
                        ) : userType === userTypeName.User ? (
                          <div className="profile-type">
                            User
                          </div>
                        ) : userType === userTypeName.Project_Manager ? (
                          <div className="profile-type">
                            Project Manager
                          </div>
                        ) : userType === userTypeName.Team_Manager ? (
                          <div className="profile-type">
                            Team Manager
                          </div>
                        )
                          : null
                      ) : (
                        <div className="profile-type">
                          ROLE
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <input
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                ref={fileInputRef}
              />
              <Modal
                isOpen={modalIsOpen}
                contentLabel="Image Crop Modal"
                style={{
                  content: {
                    height: "500%",
                    width: "1000%",
                    position: "fixed",
                    top: "20%",
                    left: "50%",
                    transform: "translate(-17.5%, 0%)",
                    parent: document.querySelector(".admin-outer.time.tracker"),
                  },
                }}
              >
                <div>
                  {image && (
                    <Cropper
                      ref={cropperRef}
                      style={{ height: 400, width: "100%" }}
                      zoomTo={0}
                      initialAspectRatio={1}
                      preview=".img-preview"
                      src={image}
                      viewMode={1}
                      minCropBoxHeight={10}
                      minCropBoxWidth={10}
                      background={false}
                      responsive={true}
                      autoCropArea={100}
                      checkOrientation={false}
                      guides={true}
                    />
                  )}
                </div>
                <div>
                  <div className="crop-btn">
                    <button onClick={handleCropClick}>Crop and Upload</button>
                    <button className="delete-client" onClick={closeModal}>Cancel</button>
                  </div>
                </div>
              </Modal>
              <div className="information-outer-section">
                <div className="information-inner">
                  <div className="information">
                    <div className="information-title">
                      Personal Information
                    </div>
                    <div className="information-outer">
                      <div className="information-left">
                        <div className="inner-detail-outer">
                          <div className="inner-detail">
                            <span class="icon"><img src={Type} alt="type" /></span>
                            <div className="inner-detail-inner">
                              <label>Type of Account *</label>
                            </div>
                          </div>
                          <div className="inner-detail">
                            <span class="icon"></span>
                            <div className="inner-detail-inner">
                              <div className={`custom-dropdown-user-new-outer custom-dropdown-select ${userTypeDropdownOpen ? 'open' : ''}`} ref={userTypeDropdownRef}>
                                <div className="selected-option custom-dropdown-user-new" onClick={() => setUserTypeDropdownOpen(!userTypeDropdownOpen)}>
                                  {userType ? roleId.find((role) => role._id === userType)?.user_type : 'Select Role'}
                                </div>
                                {userTypeDropdownOpen && (
                                  <div className="custom-dropdown-new">
                                    <ul className="role-list">
                                      {roleId
                                        .filter((role) => !(userData.userType === ADMIN && role._id === userTypeName.Super_Admin))
                                        .map((role) => (
                                          <li
                                            key={role._id}
                                            className={userType === role._id ? 'selected-item' : ''}
                                            onClick={() => {
                                              setUserType(role._id);
                                              setUserTypeDropdownOpen(false);
                                            }}
                                          >
                                            {role.user_type}
                                          </li>
                                        ))}
                                    </ul>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="inner-detail-outer">
                          <div className="inner-detail">
                            <span class="icon"><img src={UserIcon} alt="user icon" /></span>
                            <div className="inner-detail-inner">
                              <label for="fname">First Name *</label>
                            </div>
                          </div>
                          <div className="inner-detail">
                            <span class="icon"></span>
                            <div className="inner-detail-inner">
                              <input
                                type="text"
                                placeholder="Enter First name"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                readOnly={userType === ""}
                                required
                              />
                            </div>
                          </div>
                        </div>
                        <div className="inner-detail-outer">
                          <div className="inner-detail">
                            <span class="icon"><img src={UserIcon} alt="user icon" /></span>
                            <div className="inner-detail-inner">
                              <label for="mname">Middle Name</label>
                            </div>
                          </div>
                          <div className="inner-detail">
                            <span class="icon"></span>
                            <div className="inner-detail-inner">
                              <input
                                type="text"
                                placeholder="Enter Middle name"
                                value={middleName}
                                readOnly={userType === ""}
                                onChange={(e) => setMiddleName(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="inner-detail-outer">
                          <div className="inner-detail">
                            <span class="icon"><img src={UserIcon} alt="user icon" /></span>
                            <div className="inner-detail-inner">
                              <label for="lname">Last Name *</label>
                            </div>
                          </div>
                          <div className="inner-detail">
                            <span class="icon"></span>
                            <div className="inner-detail-inner">
                              <input
                                type="text"
                                placeholder="Enter Last name"
                                value={lastName}
                                readOnly={userType === ""}
                                onChange={(e) => setLastName(e.target.value)}
                                required
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="information-right">
                        <div className="inner-detail-outer">
                          <div className="inner-detail">
                            <span class="icon"><img src={EmailIcon} alt="user icon" /></span>
                            <div className="inner-detail-inner">
                              <label for="email">Email *</label>
                            </div>
                          </div>
                          <div className="inner-detail">
                            <span class="icon"></span>
                            <div className="inner-detail-inner">
                              <input
                                type="email"
                                placeholder="Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                readOnly={userType === ""}
                                required
                              />
                            </div>
                          </div>
                        </div>
                        <div className="inner-detail-outer">
                          <div className="inner-detail">
                            <span class="icon"><img src={PassLock} alt="user icon" /></span>
                            <div className="inner-detail-inner">
                              <label for="password">Password *</label>
                            </div>
                          </div>
                          <div className="inner-detail">
                            <span class="icon"></span>
                            <div className="inner-detail-inner pass-eye">
                              <input
                                type={showPassword ? "text" : "password"}
                                placeholder="Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                ref={passwordRef}
                                readOnly={userType === ""}
                                required
                              />
                              <img
                                src={showPassword ? EyeCrossed : EyeIcon}
                                className="password-toggle-icon"
                                alt="Toggle Password"
                                onClick={() => setShowPassword(!showPassword)}
                              />
                            </div>
                            {passwordError && (
                              <span>{passwordError}</span>
                            )}
                          </div>
                        </div>
                        <div className="inner-detail-outer">
                          <div className="inner-detail">
                            <span class="icon"><img src={PassLock} alt="user icon" /></span>
                            <div className="inner-detail-inner">
                              <label for="confpassword">Confirm Password *</label>
                            </div>
                          </div>
                          <div className="inner-detail">
                            <span class="icon"></span>
                            <div className="inner-detail-inner">
                              <input
                                type="password"
                                placeholder="Confirm Password"
                                value={passwordConfirmation}
                                onChange={(e) => setPasswordConfirmation(e.target.value)}
                                ref={passwordConfirmationRef}
                                readOnly={userType === ""}
                                required
                              />
                              {passwordMatchError && (
                                <span style={{ color: "red" }}> {passwordMatchError} </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="inner-detail-outer">
                            <div className="inner-detail">
                              <span class="icon"><img src={birthIcon} alt="user icon" /></span>
                              <div className="inner-detail-inner">
                                <label>Date of birth *</label>
                              </div>
                            </div>
                            <div className="inner-detail">
                              <span class="icon"></span>
                              <div className="inner-detail-inner">
                                <DatePicker
                                  oneTap
                                  className={!userType ? 'datePickerReadOnly' : ''}
                                  selected={dob ? new Date(dob) : null}
                                  onChange={(date) => setDob(date)}
                                  readOnly={userType === ""}
                                  required
                                  format="dd/MM/yyyy"
                                  minDate={subYears(new Date(), 15)}
                                  timeZone={global.config.getTimeZone()}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="inner-detail-outer">
                            <div className="inner-detail">
                              <span class="icon"><img src={PassportlIcon} alt="user icon" /></span>
                              <div className="inner-detail-inner">
                                <label for="id_number">Passport or ID Number *</label>
                              </div>
                            </div>
                            <div className="inner-detail">
                              <span class="icon"></span>
                              <div className="inner-detail-inner">
                                <input type="text" id="id_number" value={passport} onChange={(e) => setPassport(e.target.value)} readOnly={userType === ""} required />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="information-inner">
                  <div className="information">
                    <div className="information-title">
                      Address
                    </div>
                    <div className="information-outer">
                      <div className="information-left">
                        <div className="inner-detail-outer">
                          <div className="inner-detail">
                            <span className="icon">
                              <img src={location} alt="Location Icon" />
                            </span>
                            <div className="inner-detail-inner">
                              <label>Country *</label>
                            </div>
                          </div>
                          <div className="inner-detail">
                            <span className="icon"></span>
                            <div className="inner-detail-inner">
                              <div className={`custom-dropdown-user-new-outer custom-dropdown-select ${countryDropdownOpen ? 'open' : ''}`} ref={countryDropdownRef}>
                                <div className="selected-option custom-dropdown-user-new" onClick={userType ? () => setCountryDropdownOpen(!countryDropdownOpen) : null} style={{ cursor: userType ? 'pointer' : 'no-drop', background: userType ? '#fff' : '#fafafa', color: userType ? '#282828' : '#a6a6a6' }}>
                                  {country ? countriesList.find((c) => c.country === country)?.country : 'Select Country'}
                                </div>
                                {userType && countryDropdownOpen && (
                                  <div className="custom-dropdown-new">
                                    <input
                                      type="text"
                                      className="search-bar"
                                      placeholder="Search..."
                                      value={countrySearchTerm}
                                      onChange={handleCountrySearchInputChange}
                                    />
                                    <ul className="country-list">
                                      {filteredCountries.length > 0 &&
                                        filteredCountries.map((c) => (
                                          <li
                                            key={c.country}
                                            className={country === c.country ? 'selected-item' : ''}
                                            onClick={() => {
                                              setCountry(c.country);
                                              setCountryDropdownOpen(false);
                                            }}
                                          >
                                            {c.country}
                                          </li>
                                        ))}
                                    </ul>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        {states.length > 0 && (
                          <div className="inner-detail-outer">
                            <div className="inner-detail">
                              <span className="icon">
                                <img src={location} alt="Location Icon" />
                              </span>
                              <div className="inner-detail-inner">
                                <label>State *</label>
                              </div>
                            </div>
                            <div className="inner-detail">
                              <span className="icon"></span>
                              <div className="inner-detail-inner">
                                <div className={`custom-dropdown-user-new-outer custom-dropdown-select ${stateDropdownOpen ? 'open' : ''}`} ref={stateDropdownRef}>
                                  <div className="selected-option custom-dropdown-user-new" onClick={userType ? () => setStateDropdownOpen(!stateDropdownOpen) : null} style={{ cursor: userType ? 'pointer' : 'no-drop', background: userType ? '#fff' : '#fafafa', color: userType ? '#282828' : '#a6a6a6' }}>
                                    {state ? states.find((s) => s.state === state)?.state : 'Select State'}
                                  </div>
                                  {userType && stateDropdownOpen && (
                                    <div className="custom-dropdown-new">
                                      <input
                                        type="text"
                                        className="search-bar"
                                        placeholder="Search..."
                                        value={stateSearchTerm}
                                        onChange={handleStateSearchInputChange}
                                      />
                                      <ul className="state-list">
                                        {filteredStates.length > 0 &&
                                          filteredStates.map((s) => (
                                            <li
                                              key={s.state}
                                              className={state === s.state ? 'selected-item' : ''}
                                              onClick={() => {
                                                setState(s.state);
                                                setStateDropdownOpen(false);
                                              }}
                                            >
                                              {s.state}
                                            </li>
                                          ))}
                                      </ul>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="inner-detail-outer">
                          <div className="inner-detail">
                            <span className="icon">
                              <img src={location} alt="Location Icon" />
                            </span>
                            <div className="inner-detail-inner">
                              <label>City *</label>
                            </div>
                          </div>
                          <div className="inner-detail">
                            <span className="icon"></span>
                            <div className="inner-detail-inner">
                              <input type="text" id="city" Placeholder="City" value={city} onChange={(e) => setCity(e.target.value)} readOnly={userType === ""} required />
                            </div>
                          </div>
                        </div>
                        <div className="inner-detail-outer">
                          <div className="inner-detail">
                            <span class="icon"><img src={location} alt="user icon" /></span>
                            <div className="inner-detail-inner">
                              <label for="address">Address *</label>
                            </div>
                          </div>
                          <div className="inner-detail">
                            <span class="icon"></span>
                            <div className="inner-detail-inner">
                              <input
                                type="text"
                                placeholder="Address"
                                value={address}
                                onChange={(e) => setAddress(e.target.value)}
                                readOnly={userType === ""}
                                required
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="information-right">
                        <div className="inner-detail-outer">
                          <div className="inner-detail">
                            <span class="icon"><img src={PostalCode} alt="user icon" /></span>
                            <div className="inner-detail-inner">
                              <label for="postal_code">Postal Code</label>
                            </div>
                          </div>
                          <div className="inner-detail">
                            <span class="icon"></span>
                            <div className="inner-detail-inner">
                              <input
                                type="text"
                                placeholder="Post Code"
                                value={postCode}
                                onChange={(e) => setPostCode(e.target.value)}
                                readOnly={userType === ""}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="inner-detail-outer">
                          <div className="inner-detail">
                            <span class="icon"><img src={Tele} alt="user icon" /></span>
                            <div className="inner-detail-inner">
                              <label for="number">Mobile No. *</label>
                            </div>
                          </div>
                          <div className="inner-detail">
                            <span class="icon"></span>
                            <div className="inner-detail-inner">
                              <PhoneInput
                                placeholder="Enter phone number"
                                value={mobileNo}
                                onChange={setMobileNo}
                                readOnly={userType === ""}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="save-button-sec">
                {isSubmitLoading ? (
                  <span className="cancel-btn-disabled">Cancel</span>
                ) : (
                  <Link to="../list" className="cancel-btn">Cancel</Link>
                )}
                <button type="submit" className="save-btn" disabled={isSubmitLoading} >
                  {isSubmitLoading ?
                    <div className="outter-load-table">
                      <ThreeDots
                        height="38"
                        width="40"
                        radius="9"
                        color="white"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                      />
                    </div> :
                    <>
                      <img src={Tick} alt="arrow" />Save changes
                    </>
                  }
                </button>
              </div>
            </form>
          </div>
        </div>
      </div >
    </div >
  );
}