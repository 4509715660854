import axios from "axios";
import Modal from "react-modal";
import "../../../../components/TimeTracker/TimeTrackerProject/TimeTrackerProject.css";
import "rsuite/dist/rsuite.min.css";
import { toast } from "react-toastify";
import { AES, enc } from 'crypto-js';
import "react-toastify/dist/ReactToastify.css";
import { useParams, useNavigate } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import { useSelector, useDispatch } from 'react-redux';
import PlusIcon from "../../../../images/icons/plus-icon.svg";
import React, { useState, useEffect, useRef } from "react";
import { logout, reset } from "../../../../features/auth/authSlice";
import crssrImage from "../../../../images/singleinvoice/cross-red.svg";
import { reset as invoiceReset } from "../../../../features/invoice/invoiceSlice.js";
import { reset as headerReset } from "../../../../features/headerText/headerTextSlice.js";
import { reset as appSettingsReset } from "../../../../features/appSettings/appSettingsSlice.js";
import { useCreateExpCategory } from "../../../../components/AppSettings/expenseCategoryApi.jsx";


const NewExpCatModal = (props) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [expCategoryCreate, setExpCategoryCreate] = useState("");
  const [expCatBudgetCreate, setExpCatBudgetCreate] = useState("");
  const expCategoryCreateRef = useRef(null);
  const [isExpCatCreateModalOpen, setIsExpCatCreateModalOpen] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const { appSettings } = useSelector((state) => state.appSettings);
  
  const token = useSelector(
    (state) => state.auth.token
  )

  const createExpCategoryMutation = useCreateExpCategory(token);

  const handleOpenExpCatCreateModal = () => {
    setIsExpCatCreateModalOpen(true);
  };
  const handleCloseExpCatCreateModal = () => {
    setIsExpCatCreateModalOpen(false);
    setExpCategoryCreate("");
    setExpCatBudgetCreate("");
  };


  const handleClickOutsideDropdown = (event) => {

    // const isClickInsideSprintExpCatCreateDropdown = sprintExpCatCreateDropdownRef.current && sprintExpCatCreateDropdownRef.current.contains(event.target);
    // if (!isClickInsideSprintExpCatCreateDropdown) {
    //   setSprintExpCatCreateDropdownOpen(false);
    // }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutsideDropdown);
    return () => {
      document.removeEventListener('click', handleClickOutsideDropdown);
    };
  }, []);

  const handleCreateSubmit = async (event) => {
    event.preventDefault();
    if (expCategoryCreate === "") {
      toast.error("Expense category cannot be empty!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      expCategoryCreateRef.current.focus();
      return;
    }

    const requestData = {
      exp_category_name: expCategoryCreate,
      exp_cat_budget: expCatBudgetCreate,
      exp_cat_currency: appSettings.currency || "USD",
    };
    try {
      setIsSubmitLoading(true);
      const data = await createExpCategoryMutation.mutateAsync(requestData);
      if (data.status === "error") {
        toast.error("Something went wrong", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        global.config.activityLog(window.location.href, 'Expense Category', `Something went wrong while adding Expense Category "${expCategoryCreate}"`);
      } else if (data.status === "exists") {
        toast.error(`Expense category with the name "${expCategoryCreate}" already exists`, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        global.config.activityLog(window.location.href, 'TaExpCategory', `Expense Category with the name "${expCategoryCreate}" already exist`);
      } else {
        toast.success("Expense category added successfully", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        global.config.activityLog(window.location.href, 'Expense Category', `Expense Category "${expCategoryCreate}" added successfully`);
        handleCloseExpCatCreateModal();
      }
    } catch (error) {
      console.log(error);
      global.config.slackMessage(error.toString());
      if (error.response?.data?.status === "deleted") {
        localStorage.removeItem("user");
        dispatch(logout());
        dispatch(reset());
        dispatch(invoiceReset());
        dispatch(headerReset());
        dispatch(appSettingsReset());
        toast.error('This user is deleted!', {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/");
        return;
      }
      toast.error("Something went wrong");
      global.config.activityLog(window.location.href, 'Expense Category', `Something went wrong`);
    } finally {
      setIsSubmitLoading(false);
    }
  };

  const numberInputOnWheelPreventChange = (e) => {
    // Prevent the input value change
    e.target.blur()

    // Prevent the page/container scrolling
    e.stopPropagation()

    setTimeout(() => {
      e.target.focus()
    }, 0)
  }

  return (

    <div className="button-sec-invoice">
      <a className="new-client" onClick={handleOpenExpCatCreateModal}>
        <span className="icon-invoice">
          <img src={PlusIcon} alt="New Client" />
        </span>
        New Category
      </a>
      
        <Modal
          className="client-modal project-modal"
          isOpen={isExpCatCreateModalOpen}
          onRequestClose={handleCloseExpCatCreateModal}
          contentLabel="New Expense Category"
          style={{
            content: {
              height: "41%",
              width: "30%",
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              parent: document.querySelector(".admin-outer.time.tracker"),
            },
          }}
        >
          <div className="modal">
            <div className="modal-close-invite outter-modal-main">
              <a className="" onClick={handleCloseExpCatCreateModal}>
                <img src={crssrImage} alt="Edit Icon" />{" "}
              </a>
            </div>
            <div>
              <div className="modal-client-head">
                <h2 className="">New Category</h2>
              </div>
              <div className="form-field-client-outer">
                <form>
                  <div className="form-field-client">
                    <label htmlFor="clientName" className="form-label">
                      Category Name
                    </label>
                    <input
                      type="text"
                      id="clientName"
                      name="clientName"
                      className="form-input"
                      placeholder="Category Name"
                      value={expCategoryCreate}
                      onChange={(e) => setExpCategoryCreate(e.target.value)}
                      required
                    />
                  </div>
                  <div className="form-field-client">
                    <label htmlFor="expCatBudget" className="form-label">
                      Budget ({global.config.appCurrency()})
                    </label>
                    <input
                      type="number"
                      id="expCatBudget"
                      name="expCatBudget"
                      className="form-input"
                      placeholder="Category Budget"
                      value={expCatBudgetCreate}
                      onChange={(e) => setExpCatBudgetCreate(e.target.value)}
                      required
                      onWheel={numberInputOnWheelPreventChange}
                    />
                  </div>
                  {isSubmitLoading ?
                    (
                      <div className="outter-load-table">
                        <ThreeDots
                          height="38"
                          width="40"
                          radius="9"
                          color="#6479f9"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClassName=""
                          visible={true}
                        />
                      </div>
                    ) : (
                      <button type="button" onClick={handleCreateSubmit} className="submit-client">
                        Create Category
                      </button>
                    )}
                </form>
              </div>
            </div>

          </div>
        </Modal>
    </div>
  )
}

export default NewExpCatModal;