import "./expenseOverviewTotal.css";
import { ThreeDots } from 'react-loader-spinner';
import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import Clock from "../../images/icons/clock.svg";
import Billable from "../../images/icons/billable.svg";
import NonBillable from "../../images/icons/non-billable.svg";
import PurseMmoney from "../../images/icons/purses-money.svg";
import NotepadIcon from "../../images/icons/notepad.svg";
import { useTotalBudgetOfExpense } from "../../features/expenseData/useExpenseData";

const ExpenseOverviewTotal = (props) => {
  const [totalAmountSpent, setTotalAmountSpent] = useState('0.00');
  const [totalNoExpense, setTotalNoExpense] = useState("0");
  // const [totalBudget, setTotalBudget] = useState("00:00:00");

  const { forex } = useSelector((state) => state.forex);
  const settings = JSON.parse(localStorage.getItem("settings"));
  const token = useSelector(
    (state) => state.auth.token
  )
  // const { data: totalBudget = [], isLoading: totalBudgetLoading, refetch: refetchTotalBudget, isError } = useTotalBudgetOfExpense(props.expCategories, token, forex, settings.currency, props.selectedCurrency);
  const { data: totalBudget = [], isLoading: totalBudgetLoading, refetch: refetchTotalBudget, isError } = useTotalBudgetOfExpense(props.expCategories, token, forex, props.selectedCurrency);

  useEffect(() => {
    const expAmount = props.allCurrency === true ? props.expenseData : props.expenseData.filter(item => item.exp_currency === props.selectedCurrency);
    const setAmountSpent = () => {
      let totalExpAmount = 0;
      for (let i = 0; i < expAmount.length; i++) {

        // if (props.allCurrency === false) {
        //   totalExpAmount += expAmount[i].exp_amount;
        // } else {
        totalExpAmount += ConvertHelper(expAmount[i].exp_currency, props.selectedCurrency ? props.selectedCurrency : settings.currency, expAmount[i].exp_amount, forex);
        // }
      }
      totalExpAmount = parseFloat(totalExpAmount).toFixed(2);
      setTotalAmountSpent(totalExpAmount);
    }

    setAmountSpent();

    const setTotalExpenses = () => {
      setTotalNoExpense(expAmount.length);
    }
    setTotalExpenses()


    // const setTotalNonBillable = () => {
    //   setTotalBudget("0.00");
    // }
    // setTotalNonBillable();
    refetchTotalBudget()

  }, [props.expenseData, props.selectedCurrency, props.allCurrency]);

  function ConvertHelper(currencyFrom, currencyTo, amount, forex) {
    const currencyFromRate = forex[currencyFrom];
    const currencyToRate = forex[currencyTo];
    const amountConvertedUSD = amount / currencyFromRate;
    const amountConvertTo = amountConvertedUSD * currencyToRate;
    return amountConvertTo;
  }

  return (
    <div className="total-overview-outer">
      <div className="inner-overview-hours">
        <div className="overview-item">
          <div className="overview-title-outer">
            <div className="overview-title">Total Spend</div>
            <div className="overview-icon">
              <img src={Billable} alt="Billable" />
            </div>
          </div>
          <div className="overview-rice">
            {/* {new Intl.NumberFormat('en-US', { style: 'currency', currency: settings.currency }).format(0).replace(/[\d.,]/g, '')} */}
            <time datetime="PT1800H">{props.isLoading === true ?
              (<ThreeDots
                height="40"
                width="80"
                radius="9"
                color="#405BFF"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />)
              :
              // props.allCurrency === false ? (
              new Intl.NumberFormat('en-US', { style: 'currency', currency: props.selectedCurrency ? props.selectedCurrency : settings.currency }).format(totalAmountSpent)
              // ) :
              //   new Intl.NumberFormat('en-US', { style: 'currency', currency: settings.currency }).format(totalAmountSpent)
            }</time>
          </div>
        </div>
        <div className="overview-item">
          <div className="overview-title-outer">
            <div className="overview-title">Total Expenses</div>
            <div className="overview-icon">
              <img src={NotepadIcon} alt="notepad-iccon" />
            </div>
          </div>
          <div className="overview-rice">
            <time datetime="PT1640H">{props.isLoading === true ?
              (<ThreeDots
                height="40"
                width="80"
                radius="9"
                color="#405BFF"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />)
              :
              totalNoExpense
            }</time>
          </div>
        </div>
        <div className="overview-item">
          <div className="overview-title-outer">
            <div className="overview-title">Total Budget</div>
            <div className="overview-icon">
              <img src={PurseMmoney} alt="purses-of-money" />
            </div>
          </div>
          <div className="overview-rice">
            {/* {new Intl.NumberFormat('en-US', { style: 'currency', currency: settings.currency }).format(0).replace(/[\d.,]/g, '')} */}
            <time datetime="PT160H">{(props.isLoading === true || totalBudgetLoading) ?
              (<ThreeDots
                height="40"
                width="80"
                radius="9"
                color="#405BFF"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />)
              :
              new Intl.NumberFormat('en-US', { style: 'currency', currency: props.selectedCurrency ? props.selectedCurrency : settings.currency }).format(totalBudget)
            }</time>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExpenseOverviewTotal;