import { useSelector, useDispatch } from "react-redux";
import React, { useEffect, useRef } from 'react';
import MasterFilterCalendar from "../../../components/Master/MasterDashboard/MasterFilterCalendar.jsx";
import MasterAmountOverview from "../../../components/Master/MasterDashboard/MasterAmountOverview.jsx";
import MasterRecentTenants from "../../../components/Master/MasterDashboard/MasterRecentTenants.jsx";
import { motion } from 'framer-motion';

export default function MasterDashboard() {
  const dispatch = useDispatch();
  const masterUser = useSelector(
    (state) => state.masterAuth.masterUser
  )
  const componentRef = useRef(null);
  useEffect(() => {
    componentRef.current.scrollTop = 0;
  }, [dispatch, masterUser]);

  const fadeIn = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 }
  };


  return (
    <motion.div
      ref={componentRef}
      initial="hidden"
      animate="visible"
      variants={fadeIn}
      transition={{ duration: 0.3 }}
    >
      <div ref={componentRef}>
        <div className='filter-sec'>
          <MasterFilterCalendar />
        </div>
        <div className='graph-overview1'>  
            <MasterAmountOverview />
        </div>
        <div className='recent-invoices'>
          <div className='recent-invoice-inner'>
            <MasterRecentTenants />
          </div>
        </div>
      </div>
    </motion.div>

  )
}