import React,{useRef} from "react";
import Invoices from "../../components/Invoices/InvoicesSingle/InvoiceSingle";

function InvoiceSingle()  {
    const componentRef = useRef(0);
   
    return(
        <div className="react-outeroi" ref={componentRef}>
            <Invoices />
        </div>
    )

}

export default InvoiceSingle;