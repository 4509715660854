import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  expCategory: null,
  expYear: null,
  expCurrency: null,
};

export const setReportingExpCategory = createAsyncThunk(
  "reporting/exp/category",
  async (expCategory) => {
    return expCategory;
  }
);

export const setReportingExpYear = createAsyncThunk(
  "reporting/exp/year",
  async (expYear) => {
    if (expYear !== null) {
      //   const date = startOfYear(new Date(year, 0, 1));
      //   date.setHours(0, 0, 0, 0);
      return expYear;
    } else {
      return null;
    }
  }
);

export const setReportingExpCurrency = createAsyncThunk(
  "reporting/exp/currency",
  async (expCurrency) => {
    return expCurrency;
  }
);

export const reportingExpenseSlice = createSlice({
  name: "reportingExpense",
  initialState,
  reducers: {
    reset: (state) => {
      state.expCategory = null;
      state.expYear = null;
      state.expCurrency = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setReportingExpCategory.fulfilled, (state, action) => {
      state.expCategory = action.payload;
    });
    builder.addCase(setReportingExpYear.fulfilled, (state, action) => {
      state.expYear = action.payload;
    });
    builder.addCase(setReportingExpCurrency.fulfilled, (state, action) => {
      state.expCurrency = action.payload;
    });
  },
});

export const { reset } = reportingExpenseSlice.actions;
export default reportingExpenseSlice.reducer;
