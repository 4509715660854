import { Buffer } from "buffer";
import AES from "crypto-js/aes";
import CryptoJS from "crypto-js";

export function encodeData (data){
        const encodedString = base64Encode(data);
        return encodedString;
}


export function  decodeData (data){
        const decodedData = base64Decode(data);
        return decodedData;
}

const base64Decode = (str) => {
        return Buffer.from(str, "base64").toString("utf-8");
};

const base64Encode = (str) => {
        return Buffer.from(str, "utf-8").toString("base64");
};



export function  encodeURL (data) {
        
        const encodedURI =  encodeURIComponent(AES.encrypt(data, 'TalentOnSecuredData').toString().replace(/\//g, '%2F'));
        return encodedURI;
 }
 
 export function  decodeURL (encodedData){
         // Step 1: Decode the URL-encoded data
         const decodedURI = decodeURIComponent(encodedData).replace(/%2F/g, "/");
     
         // Step 2: Decrypt the AES encrypted data
         const decryptedBytes = CryptoJS.AES.decrypt(decodedURI, 'TalentOnSecuredData');
         const originalData = decryptedBytes.toString(CryptoJS.enc.Utf8);
     
         return originalData;
 };

