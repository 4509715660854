import { useSelector, useDispatch } from "react-redux";
import React, { useEffect, useRef } from 'react';
import { AES, enc } from 'crypto-js';
import { useParams } from "react-router-dom";
import ClientDetails from "../../components/Clients/ClientOverviewListingSingle/ClientOverviewListingSingleDetails";
import ClientActivities from "../../components/Clients/ClientOverviewListingSingle/ClientOverviewListingSingleActivities";
import ClientLatestProject from "../../components/Clients/ClientOverviewListingSingle/ClientOverviewListingLatestProject";
import RecentInvoices from '../../components/Clients/ClientOverviewListingSingle/ClientOverviewListingRecentInvoices';
import RecentDocuments from '../../components/Clients/ClientOverviewListingSingle/ClientOverviewListingRecentDocuments';
import { setHeaderText } from "../../features/headerText/headerTextSlice";
import RecentExpenses from "../../components/Clients/ClientOverviewListingSingle/ClientOverviewListingRecentExpense";

export default function Dashboard() {
  const dispatch = useDispatch();
  const { encryptedId } = useParams();
  const decodedId = decodeURIComponent(encryptedId.replace(/%2F/g, '/'));
  const decryptedBytes = AES.decrypt(decodedId, 'encryptionKey');
  const id = decryptedBytes.toString(enc.Utf8);

  const user = useSelector(
    (state) => state.auth.user
  )
  const sum = useSelector(
    (state) => state.invoice.dashboardCurrencySum
  )
  const componentRef = useRef(null);
  useEffect(() => {
    dispatch(setHeaderText("Client Overview"));
    componentRef.current.scrollTop = 0;

  }, [dispatch, user]);

  return (
    <div ref={componentRef}>
      <div className="client-inner-details">
        <div className="clint-managment">
          <ClientDetails id={id} />
        </div>
        <div className="client-activity">
          <ClientActivities id={id} />
        </div>
        <div className="clint-latest-project">
          <ClientLatestProject id={id} />
        </div>
        <div className='client-recent-invoice'>
          <RecentInvoices id={id} />
        </div>
        <div className='client-recent-invoice'>
          <RecentExpenses id={id} />
        </div>
        <div className='client-recent-documents'>
          <RecentDocuments id={id} />
        </div>
      </div>
    </div>
  )
}