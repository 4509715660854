import Modal from "react-modal";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ThreeDots } from 'react-loader-spinner';
import Delete from "../../../images/icons/delete.svg";
import { useSelector, useDispatch } from 'react-redux';
import React, { useEffect, useState, useRef } from "react";
import { logout, reset } from "../../../features/auth/authSlice";
import LeftArrow from "../../../images/icons/blue-left-arrow.svg";
import addicon from "../../../images/team-member-tab/add-icon.svg";
import crssrImage from "../../../images/singleinvoice/cross-red.svg";
import editimgblue from "../../../images/team-member-tab/edit-btn-blue.svg";
import { reset as invoiceReset } from "../../../features/invoice/invoiceSlice.js";
import { reset as headerReset } from "../../../features/headerText/headerTextSlice.js";
import { reset as appSettingsReset } from "../../../features/appSettings/appSettingsSlice.js";
import { setHeaderText } from "../../../features/headerText/headerTextSlice";
import { useCreateEqpCategory, useDeleteEqpCategory, useFetchEqpCategory, useUpdateEqpCategory } from "./eqpCategoryApi";

const EqpCategory = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [eqpCategoryCreate, setEqpCategoryCreate] = useState("");
  const eqpCategoryCreateRef = useRef(null);
  const [eqpCategoryEdit, setEqpCategoryEdit] = useState("");
  const [sortedEqpCategory, setSortedEqpCategory] = useState([]);
  const [filter, setFilter] = useState("");
  const [selectedSortOption, setSelectedSortOption] = useState(null);
  const [checkedOption, setCheckedOption] = useState(null);
  const [sortDropdownOpen, setSortDropdownOpen] = useState(false);
  const sortDropdownRef = useRef(null);
  const token = useSelector(
    (state) => state.auth.token
  )

  const { data: eqpCategory = [], isLoading, isError } = useFetchEqpCategory(token);
  const deleteEqpCategoryMutation = useDeleteEqpCategory(token);
  const createEqpCategoryMutation = useCreateEqpCategory(token);
  const updateEqpCategoryMutation = useUpdateEqpCategory(token);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        document.activeElement.blur();
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const selectSortOption = (option) => {
    setSelectedSortOption(option);
    setCheckedOption(option);
    setSortDropdownOpen(false);
  };

  const toggleSortDropdown = () => {
    setSortDropdownOpen(!sortDropdownOpen);
  };

  const sortData = (data, sortOption) => {
    switch (sortOption) {
      case 'By name: A - Z':
        return data.slice().sort((a, b) => a.eqp_category_name.localeCompare(b.eqp_category_name));
      case 'By name: Z - A':
        return data.slice().sort((a, b) => b.eqp_category_name.localeCompare(a.eqp_category_name));
      case 'Date: old to new':
        return data.slice().sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
      case 'Date: new to old':
        return data.slice().sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
      default:
        return data;
    }
  };

  const sortedData = sortData(sortedEqpCategory, selectedSortOption);

  const filteredData = sortedData.filter((item) => {
    const searchTerm = filter.trim().toLowerCase();
    const hasMatch =
      item.eqp_category_name.toLowerCase().includes(searchTerm);
    return hasMatch;
  });

  useEffect(() => {
    dispatch(setHeaderText("Equipment"));
    setSortedEqpCategory(eqpCategory);
  }, [eqpCategory]);

  const handleOpenModal = (item) => {
    setIsModalOpen(true);
    setSelectedId(item._id);
    setEqpCategoryEdit(item.eqp_category_name);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedId("");
    setEqpCategoryEdit("");
  };

  const handleDelete = () => {
    setIsDeleteModalOpen(true);
  };

  const handleConfirmDelete = async () => {
    try {
      setIsSubmitLoading(true);
      await deleteEqpCategoryMutation.mutateAsync(selectedId);
      setSortedEqpCategory((prevState) => prevState.filter((eqpCategory) => eqpCategory._id !== selectedId));
      toast.success("Equipment category removed successfully", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      global.config.activityLog(window.location.href, 'Equipment Category', `Equipment Category (${selectedId}) removed successfully`);
      handleCloseModal();
    } catch (error) {
      global.config.slackMessage(error.toString());
      if (error.response?.data?.status === "deleted") {
        localStorage.removeItem("user");
        dispatch(logout());
        dispatch(reset());
        dispatch(invoiceReset());
        dispatch(headerReset());
        dispatch(appSettingsReset());
        toast.error('This user is deleted!', {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/");
        return;
      }
      toast.error("Something went wrong", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      console.log(error.response.data.message); // print server error message
    } finally {
      setIsSubmitLoading(false);
    }
    setIsDeleteModalOpen(false);
  };

  const handleCancelDelete = () => {
    setIsDeleteModalOpen(false);
  };


  const handleCreateSubmit = async (event) => {
    event.preventDefault();
    if (eqpCategoryCreate === "") {
      toast.error("Equipment category cannot be empty!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      eqpCategoryCreateRef.current.focus();
      return;
    }

    const requestData = {
      eqp_category_name: eqpCategoryCreate,
    };
    try {
      setIsSubmitLoading(true);
      const data = await createEqpCategoryMutation.mutateAsync(requestData);
      if (data.status === "error") {
        toast.error("Something went wrong", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        global.config.activityLog(window.location.href, 'Equipment Category', `Something went wrong while adding Equipment Category "${eqpCategoryCreate}"`);
      } else if (data.status === "exists") {
        toast.error(`Equipment category with the name "${eqpCategoryCreate}" already exists`, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        global.config.activityLog(window.location.href, 'TaEqpCategory', `Equipment Category with the name "${eqpCategoryCreate}" already exist`);
      } else {
        toast.success("Equipment category added successfully", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        global.config.activityLog(window.location.href, 'Equipment Category', `Equipment Category "${eqpCategoryCreate}" added successfully`);
        setEqpCategoryCreate("");
      }
    } catch (error) {
      console.log(error);
      global.config.slackMessage(error.toString());
      if (error.response?.data?.status === "deleted") {
        localStorage.removeItem("user");
        dispatch(logout());
        dispatch(reset());
        dispatch(invoiceReset());
        dispatch(headerReset());
        dispatch(appSettingsReset());
        toast.error('This user is deleted!', {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/");
        return;
      }
      toast.error("Something went wrong");
      global.config.activityLog(window.location.href, 'Equipment Category', `Something went wrong`);
    } finally {
      setIsSubmitLoading(false);
    }
  };

  const handleUpdateSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsSubmitLoading(true);
      const response = await updateEqpCategoryMutation.mutateAsync({
        selectedId,
        eqp_category_name: eqpCategoryEdit,
      });
      const data = response.data;
      if (data.status === "error") {
        toast.error("Something went wrong", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (data.status === "exists") {
        toast.error("Equipment category with the same name already exists", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        setSortedEqpCategory((prevState) => {
          const updatedEqpCategory = prevState.map((item) => {
            if (item._id === selectedId) {
              return { ...item, eqp_category_name: eqpCategoryEdit };
            }
            return item;
          });
          return updatedEqpCategory;
        });
        toast.success("Equipment category updated successfully", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        global.config.activityLog(window.location.href, 'Equipment Category', `Equipment Category ${eqpCategoryEdit} updated successfully`);
        handleCloseModal();

      }
    } catch (error) {
      console.log(error.response.data.message); // print server error message
      global.config.slackMessage(error.toString());
      if (error.response?.data?.status === "deleted") {
        localStorage.removeItem("user");
        dispatch(logout());
        dispatch(reset());
        dispatch(invoiceReset());
        dispatch(headerReset());
        dispatch(appSettingsReset());
        toast.error('This user is deleted!', {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/");
        return;
      }
      alert("Something went wrong");
    } finally {
      setIsSubmitLoading(false);
    }
  };

  return (
    <div>
      <div className="text-arrow-outer">
        <div className="text-arrow-inner">
          <Link to={`/user/equipment/overview`}>
            <div className="text-arrow">
              <div className="left-arrow">
                <img src={LeftArrow} alt="left-arrow" />
              </div>
              <div className="top-text">
                Back to Equipments
              </div>
            </div>
          </Link>
        </div>
      </div>
      <div className='div-outter-for-department'>
        <div className='add-department-section top-filter-client-inner'>
          <div className="top-filter-client-inner-left">
            <input
              type='text'
              placeholder='Add new category'
              value={eqpCategoryCreate}
              onChange={(e) => setEqpCategoryCreate(e.target.value)}
              ref={eqpCategoryCreateRef}
            />
            <button className='add-btn-in-department-b1' onClick={handleCreateSubmit} disabled={isSubmitLoading}>
              {isSubmitLoading ? (
                <div className="outter-load-table">
                  <ThreeDots
                    height="21"
                    width="25"
                    radius="9"
                    color="white"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                </div>
              ) : (
                <img src={addicon} alt="add" />
              )}
            </button>
          </div>

          <div className="top-filter-client-sort">

            <div className="custom-dropdown1">
              <div
                ref={sortDropdownRef}
                className={`custom-dropdown-user-new-outer custom-dropdown-select ${sortDropdownOpen ? 'open' : ''}`}
              >
                <div className="selected-option custom-dropdown-user-new" onClick={toggleSortDropdown}>
                  <div className="center-sort">
                    {selectedSortOption ? selectedSortOption : 'Sort By'}
                  </div>
                </div>
                {sortDropdownOpen && (
                  <div className="custom-dropdown-new">
                    <ul className="type-list">
                      <li
                        className={checkedOption === 'By name: A - Z' ? 'selected' : ''}
                        onClick={() => selectSortOption('By name: A - Z')}
                      >
                        <span className={`checkbox ${checkedOption === 'By name: A - Z' ? 'checked' : ''}`}></span>
                        By name: A - Z
                      </li>
                      <li
                        className={checkedOption === 'By name: Z - A' ? 'selected' : ''}
                        onClick={() => selectSortOption('By name: Z - A')}
                      >
                        <span className={`checkbox ${checkedOption === 'By name: Z - A' ? 'checked' : ''}`}></span>
                        By name: Z - A
                      </li>
                      <li
                        className={checkedOption === 'Date: old to new' ? 'selected' : ''}
                        onClick={() => selectSortOption('Date: old to new')}
                      >
                        <span className={`checkbox ${checkedOption === 'Date: old to new' ? 'checked' : ''}`}></span>
                        Date: old to new
                      </li>
                      <li
                        className={checkedOption === 'Date: new to old' ? 'selected' : ''}
                        onClick={() => selectSortOption('Date: new to old')}
                      >
                        <span className={`checkbox ${checkedOption === 'Date: new to old' ? 'checked' : ''}`}></span>
                        Date: new to old
                      </li>
                      {/* Add more sorting options as needed */}
                    </ul>
                  </div>
                )}
              </div>
            </div>
            <div className="top-filter-client-sort-search">
              <div className="top-filter-client-search">
                <div className="search-sec">
                  <div className="search-container">
                    <input type="text" className="search-input" placeholder="Search by equipment category name" onChange={(e) => setFilter(e.target.value)} value={filter} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='department-section-listing'>
          {isLoading == true ? (
            <div className="outter-load-table">
              <ThreeDots
                height="80"
                width="80"
                radius="9"
                color="#405BFF"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            </div>
          ) : (
            <>
              {filteredData.map((item, index) => (
                <div className='grid-item' key={item._id}>
                  <input type='text' className='input-tag' value={item.eqp_category_name} readOnly />
                  <button className='edit-button' onClick={() => handleOpenModal(item)}><img src={editimgblue} alt="edit" />Edit</button>
                </div>
              ))}
            </>
          )}
        </div>
      </div>
      <div className="modal-invite-main-outter">
        <Modal
          className="client-modal leave"
          isOpen={isModalOpen}
          onRequestClose={handleCloseModal}
          contentLabel="Edit Equipment Category"
          style={{
            content: {
              height: "41%",
              width: "30%",
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              parent: document.querySelector(".admin-outer.time.tracker"),
            },
          }}
        >
          <div className="modal">
            <div className="modal-close-invite outter-modal-main">
              <a className="" onClick={handleCloseModal}>
                <img src={crssrImage} alt="Edit Icon" />{" "}
              </a>
            </div>
            <div className="modal-client-head">
              <h2 className="">Edit Equipment Category</h2>
            </div>
            <div className="form-field-client-outer">
              <form onSubmit={handleUpdateSubmit}>
                <div className="form-field-client">
                  <label htmlFor="clientName" className="form-label">
                    Equipment Category Name
                  </label>
                  <input
                    type="text"
                    id="clientName"
                    name="clientName"
                    className="form-input"
                    value={eqpCategoryEdit}
                    onChange={(e) => setEqpCategoryEdit(e.target.value)}
                    required
                  />
                </div>
                {isSubmitLoading ?
                  (
                    <div className="outter-load-table">
                      <ThreeDots
                        height="38"
                        width="40"
                        radius="9"
                        color="#6479f9"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                      />
                    </div>
                  ) : (
                    <div className="form-field-client-btns">
                      <button
                        type="button"
                        className="delete-client"
                        onClick={handleDelete}
                        disabled={isSubmitLoading}
                      >
                        <img src={Delete} alt="Delete" />  Delete
                      </button>
                      <Modal
                        className="delete-modal"
                        isOpen={isDeleteModalOpen}
                        onRequestClose={handleCancelDelete}
                        contentLabel="Confirm Delete"
                        style={{
                          content: {
                            height: "41%",
                            width: "30%",
                            position: "fixed",
                            top: "36%",
                            left: "50%",
                            transform: "translate(-24.75%, 0%)",
                            parent: document.querySelector(".admin-outer.time.tracker"),
                          },
                        }}
                      >
                        <div className="delete-modal-content">
                          <h2>Confirm Delete</h2>
                          <p>
                            Are you sure you want to delete this Equipment Category?
                          </p>
                          {isSubmitLoading ? (
                            <div className="outter-load-table">
                              <ThreeDots
                                height="38"
                                width="40"
                                radius="9"
                                color="#6479f9"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                              />
                            </div>
                          ) : (
                            <div className="delete-modal-buttons">
                              <button className="delete" onClick={handleConfirmDelete} disabled={isSubmitLoading}>
                                Delete
                              </button>
                              <button onClick={handleCancelDelete}>
                                Cancel
                              </button>
                            </div>
                          )}
                        </div>
                      </Modal>
                      <button
                        type="submit"
                        className="save-changes"
                        disabled={isSubmitLoading}
                      >
                        Save Changes
                      </button>
                    </div>
                  )}
              </form>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default EqpCategory;