import "./TimeTrackerOverviewTable.css";
import { useSelector } from "react-redux";
import { ThreeDots } from 'react-loader-spinner';
import React, { useState, useEffect } from "react";
import userTypeConsts from "../../../constants/userTypeConsts";

const TimeTrackerOverviewPrintTable = (props) => {
  const [data, setData] = useState(props.timerData);
  const tracker = useSelector((state) => state.tracker);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const userType = props.user.userType;

  useEffect(() => {
    const getTableData = async () => {
      setIsTableLoading(true);
      try {
        setData(props.timerData);
      } catch (err) {
        global.config.slackMessage(err.toString());
        console.log(err);
      }
      setIsTableLoading(false);
    }
    getTableData();
  }, [props.timerData, tracker]);

  const chnageDurationFormat = (sec) => {
    let second = sec;
    const hours = Math.floor(second / 3600);
    const minutes = Math.floor((second % 3600) / 60);
    const seconds = second % 60;
    const formattedDuration = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    return formattedDuration;
  }
  return (
    <div className="outer-time-table">
      <div className="time-table" ref={props.componentRef}>
        <table>
          <thead>
            <tr>
              {(userType === userTypeConsts.SUPER_ADMIN || userType === userTypeConsts.ADMIN) &&
                (<><th>
                  User
                </th></>)}
              <th>
                Client
              </th>
              <th>
                Project
              </th>
              <th>
                Duration
              </th>
              <th>
                Date
              </th>
              <th>
                Billable
              </th>
            </tr>
          </thead>
          <tbody>
            {data.length > 0 ? (
              data.map((row, index) => (
                <tr key={index}>
                  {(userType === userTypeConsts.SUPER_ADMIN || userType === userTypeConsts.ADMIN) &&
                    (<>
                      <td>
                        <div className="member-name">
                          <img
                            src={`/uploads/lowProfileImage/${row.user && row.user.profile_image}`}
                            alt={row.user && row.user.first_name}
                            className="member-image"
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = '/uploads/lowProfileImage/user_default.png';
                            }}
                          />
                          <span>{row.user.first_name ? (
                            <>
                              {row.user.first_name}{" "}
                              {row.user.middle_name &&
                                row.user.middle_name !== "null"
                                ? row.user.middle_name + " "
                                : ""}
                              {row.user.last_name}{" "}
                            </>
                          ) : (
                            <>
                              {row.user.company_first_name
                                ? `${row.user.company_first_name} ${row.user.company_last_name ? row.user.company_last_name : ''}`
                                : `${row.user.company_name || ''}(Company)`
                              }
                            </>
                          )}</span>
                        </div>
                      </td></>)}
                  <td>{row?.project?.client_name !== null && row?.project?.client_name?.client_name}</td>
                  <td>{row?.project?.project_name}</td>
                  <td>{chnageDurationFormat(row.duration)}</td>
                  <td>{new Date(row.date).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}</td>
                  <td>
                    <span className={row?.project?.is_billable === true ? "yes" : "no"}>
                      {row?.project?.is_billable ? "Yes" : "No"}
                    </span>
                  </td>
                </tr>
              ))
            ) : (
              isTableLoading ? (
                <tr>
                  <td>
                    <ThreeDots
                      height="80"
                      width="80"
                      radius="9"
                      color="#405BFF"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  </td>
                </tr>
              ) : (
                <tr>
                  <td>No Results</td>
                </tr>
              )
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TimeTrackerOverviewPrintTable;