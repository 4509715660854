import React, { useState } from 'react';
import { registerUser } from "../../../../src/services/emailRegister/emailRegisterServices.js";
import { toast } from "react-toastify";
import { AES, enc } from 'crypto-js';
import { Link, useNavigate, useParams } from "react-router-dom";

const TenantInviteRegister = () => {
    const { email } = useParams();
    const deEmail = decodeURIComponent(
        atob(email.replace(/\-/g, "+").replace(/_/g, "/"))
    );
    console.log("deEmail", deEmail);
    const navigate = useNavigate();

    const [companyName, setCompanyName] = useState("");
    const [location, setlocation] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [subDomain, setSubDomain] = useState("");
    const [loading, setLoading] = useState(false); // Loading state
    const domainName = window.location.hostname;
    const currentPort = window.location.port;

    const submitHandler = async () => {
        if (password !== confirmPassword) {
            alert("Password and confirm password do not match");
            return;
        }

        setLoading(true); // Start loading
        try {
            const result = await registerUser(deEmail, subDomain, companyName, location, password);
            if (result.status === "success") {
                if (result.data.subDomain) {
                    if (currentPort === "3000") {
                        window.location.href = `http://${result.data.subDomain}.${domainName}:3000`;
                    } else {
                        window.location.href = `https://${result.data.subDomain}.${domainName}`;
                    }

                }
                toast.success(result.data.message || "App owner registered successfully.", {
                    position: "bottom-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else if (result.status === "fail") {
                toast.error(result.message || "Something went wrong.", {
                    position: "bottom-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            } else if (result.status === "failed") {
                toast.error(result.message || "Company name or email already exists.", {
                    position: "bottom-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        } catch (error) {
            console.error("Error:", error);
        } finally {
            setLoading(false); // End loading
        }
    };

    return (
        <div>
            {loading ? (
                // Show progress animation while loading is true
                <div className="spinner-container">
                    <div className="spinner"></div>
                    <p>Loading...</p>
                </div>
            ) : (
                <>
                    <h2>Register Form</h2>
                    <div>
                        <label>Email:</label>
                        <input type='email' value={deEmail} disabled /><br />
                    </div>
                    <div>
                        <label>Company Name:</label>
                        <input type='text' value={companyName} onChange={(e) => setCompanyName(e.target.value)} /><br />
                    </div>
                    <div>
                        <label>Password:</label>
                        <input type='password' value={password} onChange={(e) => setPassword(e.target.value)} /><br />
                    </div>
                    <div>
                        <label>Confirm Password:</label>
                        <input type='password' value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} /><br />
                    </div>
                    <button onClick={submitHandler}>Submit</button>
                </>
            )}
        </div>
    );
};

export default TenantInviteRegister;