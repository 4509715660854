import React from "react";
import VendorProfileView from "../../../components/Vendors/VendorProfile/VendorProfileView";

function VendorProfileViewSection() {
    return (
        <div className="profile-section-outer">
            <VendorProfileView />
        </div>
    )
}

export default VendorProfileViewSection;