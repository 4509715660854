import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  bankFetchUpdate: Math.floor(Math.random() * 100) + 1,
};

const bankFetchUpdateSlice = createSlice({
  name: "bankFetchUpdate",
  initialState,
  reducers: {
    setBankFetchUpdate: (state) => {
      state.bankFetchUpdate = Math.floor(Math.random() * 100) + 1;
    },
  },
});

export const { setBankFetchUpdate } = bankFetchUpdateSlice.actions;
export default bankFetchUpdateSlice.reducer;
