import React from "react";
import ClientInviteRegisteration from "../../../components/Clients/ClientInvite/ClientInviteRegisteration";

function ClientInviteRegister()  {
    return(
        <div className="react-outer">
            <ClientInviteRegisteration/>
        </div>
    )
}

export default ClientInviteRegister;