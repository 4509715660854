const reportingFilterDateRangesConst =
{
    This_Year: '1',
    This_Quarter: '2',
    First_Half: '3',
    This_Month: '4',
    Year_Selected: '5',
    Month_Selected: '6',
};

module.exports = {
    reportingFilterDateRangesConst
}
