import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  accountDetails: false,
  typeOfAccount: "Transaction Accounts",
};

export const setTypeOfAccount = createAsyncThunk("type/account", async (typeOfAccount) => {
  return typeOfAccount;
});

const accountDetailsSlice = createSlice({
  name: "accountDetails",
  initialState,
  reducers: {
    setAccountDetails: (state) => {
      state.accountDetails = true;
    },
    resetAccountDetails: (state) => {
      state.accountDetails = false;
      state.typeOfAccount = "Transaction Accounts";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setTypeOfAccount.fulfilled, (state, action) => {
      state.typeOfAccount = action.payload;
    });
  },
});

export const { setAccountDetails, resetAccountDetails } = accountDetailsSlice.actions;
export default accountDetailsSlice.reducer;