import axios from "axios";
import "./CheckEmail.css";
import { toast } from "react-toastify";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { validate } from "react-email-validator";
import CryptoJS from "crypto-js";
import Logo from "../../images/logo/to-logo.svg";
import { ThreeDots } from "react-loader-spinner";
import LoginImage from "../../images/login/lg-img.png";
import LoginImageMobile from "../../images/login/lg-img-mob.png";
import Login from "../../pages/auth/Login.jsx";
import { GoogleOAuthProvider, GoogleLogin, googleLogout, useGoogleLogin } from '@react-oauth/google';
import { AES, enc } from "crypto-js";

const CustomGoogleButton = ({ onSuccess, onError }) => {
  const login = useGoogleLogin({
    onSuccess: credentialResponse => {
      onSuccess(credentialResponse);
    },
    onError: errorResponse => {
      onError(errorResponse);
    }
  });

  return (



    <button
      onClick={() => login()}
      className="google-btn"
    >
      {/* Google Logo */}
      <svg
        className="w-5 h-5"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
          fill="#4285F4"
        />
        <path
          d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
          fill="#34A853"
        />
        <path
          d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
          fill="#FBBC05"
        />
        <path
          d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
          fill="#EA4335"
        />
      </svg>

      <span  >
        Continue with Google
      </span>
    </button>
  );
};


const CheckEmail = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const domainName = window.location.hostname;
  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (validate(email)) {
      setIsLoading(true);
      try {
        const requestData = { email: email };
        const res = await axios.post("/api/master/user/check-email", requestData);
        const data = res.data;
        if (data.status === "success") {
          navigate(`/auth/myteam/${data.encodedSubDomain}/${data.encodedEmail}`);
        } else {
          toast.error("Email not found in master records. Please ensure it is registered.", {
            position: "bottom-right",
            autoClose: 2000,
          });
        }
      } catch (error) {
        console.error(error.message);
        toast.error("An error occurred. Please try again later.", {
          position: "bottom-right",
          autoClose: 1500,
        });
      }
      setIsLoading(false);
    } else {
      toast.error("Invalid email address. Please enter a valid email ID", {
        position: "bottom-right",
        autoClose: 1500,
      });
    }
    setLoading(false);
  };


  const getGooglePrifileFromToken = async (googleToken) => {
    setIsLoading(true);
    try {
      var googleProfile = await axios.get(`https://www.googleapis.com/oauth2/v3/userinfo`, {
        headers: {
          Authorization: `Bearer ${googleToken}`,
        }
      });
      return googleProfile.data;
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  }

  const handleSuccess = async (credentialResponse) => {
    setIsLoading(true);
    try {
      console.log('Login Success:', credentialResponse);
      localStorage.setItem('googleToken', credentialResponse);
      const googleProfile = await getGooglePrifileFromToken(credentialResponse.access_token);
      localStorage.setItem('googleToken', credentialResponse.access_token);
      // login part
      navigate(
        `/app/google/auth/team/${encodeURIComponent(AES.encrypt(googleProfile.email, "encryptionKey").toString().replace(/\//g, "%2F")
        )}`
      );
      setIsLoading(true);
      // login part end
    } catch (error) {
      console.error('Error processing login:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleError = () => {
    console.error('Login Failed');
  };

  return (
    domainName === "app.talenton.io" || domainName === "talenton.app" || domainName === "localhost" ?
      (
        <div className="login-outer-forget auth-divide">
          <div className="login-inner">
            <div className="top-logo-mobile">
              <a href="/"><img src={Logo} alt="Talent On" /></a>
            </div>
            <div className="lg-lft">
              <img src={LoginImage} alt="desktop" className="desktop-lg-img" />
              <img
                src={LoginImageMobile}
                alt="mobile"
                className="mobile-lg-img"
              />
            </div>
            <div className="lg-rgt">
              <div className="top-logo">
                <a href="/"><img src={Logo} alt="Talent On" /></a>
              </div>
              <div className="login-form-sec">

                {isLoading ?


                  (<>
                    <ThreeDots
                      height="38"
                      width="100"
                      radius="9"
                      color="blue"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  </>)
                  : (

                    <form onSubmit={onSubmit}>
                      <h3>Welcome!</h3>
                      <div className="form-outer">
                        <label>Please enter your work email address.</label>
                        <input
                          type="email"
                          name="email"
                          id="email"
                          placeholder="example@company.com"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />
                      </div>
                      <div className="reset-button"></div>
                      <button type="submit" disabled={loading}>
                        {loading ?
                          <div className="outter-load-table">
                            <ThreeDots
                              height="38"
                              width="40"
                              radius="9"
                              color="white"
                              ariaLabel="three-dots-loading"
                              wrapperStyle={{}}
                              wrapperClassName=""
                              visible={true}
                            />
                          </div> : "Next"
                        }
                      </button>

                      <div className="divider">
                        Or log in with your google account
                      </div>
                      <div className="g-btn-outer">

                        <GoogleOAuthProvider clientId="150723980220-pd771tque2fo4nao94qeid76fc1guuhq.apps.googleusercontent.com">
                          <CustomGoogleButton
                            onSuccess={handleSuccess}
                            onError={handleError}
                          />
                        </GoogleOAuthProvider>

                      </div>
                      <div className="dnt-acnt-chek-email">Don't have an account yet?
                        <a href="/auth"> Sign up</a></div>
                    </form>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <Login />
        </>
      )
  );
};

export default CheckEmail;