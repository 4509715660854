import Modal from "react-modal";
import { toast } from "react-toastify";
import "./TimeTrackerClientListing.css";
import "react-phone-number-input/style.css";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ThreeDots } from "react-loader-spinner";
import PhoneInput from "react-phone-number-input";
import React, { useEffect, useRef, useState } from "react";
import Countries from "../../../constants/countries.js";
import Edit from "../../../images/icons/edit-pen.svg";
import Delete from "../../../images/icons/delete.svg";
import { useSelector, useDispatch } from "react-redux";
import Plus from "../../../images/icons/plus-black.svg";
import DefultCompLogo from "../../../images/icons/logo-default.svg";
import { logout, reset } from "../../../features/auth/authSlice.js";
import crssrImage from "../../../images/singleinvoice/cross-red.svg";
import { reset as invoiceReset } from "../../../features/invoice/invoiceSlice.js";
import { reset as headerReset } from "../../../features/headerText/headerTextSlice.js";
import { reset as appSettingsReset } from "../../../features/appSettings/appSettingsSlice.js";
import {
  useDeleteClientDetails,
  useFetchClientDetails,
  useUpdateClientDetails,
} from "./useClientDetails.jsx";
import { useUserById } from "../../TimeOff/timeOffApi.jsx";

const TimeTrackerClientListing = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [clientName, setClientName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [accManager, setAccManager] = useState("");
  const [accManagerDropdownOpen, setAccManagerDropdownOpen] = useState(false);
  const [userSearchTerm, setUserSearchTerm] = useState("");
  const [accManagerIsValid, setAccManagerIsValid] = useState(false);
  const [contactPoint, setContactPoint] = useState("");
  const [contactPointDropdownOpen, setContactPointDropdownOpen] =
    useState(false);
  // const [contactPointSearchTerm, setContactPointSearchTerm] = useState('');
  const [contactPointIsValid, setContactPointIsValid] = useState(false);
  const [companyWebsite, setCompanyWebsite] = useState("");
  const [isValidUrl, setIsValidUrl] = useState(false);
  const [companyLogo, setCompanyLogo] = useState(null);
  const [companyLogoName, setCompanyLogoName] = useState("");
  const [country, setCountry] = useState("");
  const [address, setAddress] = useState("");
  const [additionalInfo, setAdditionalInfo] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [countrySearchTerm, setCountrySearchTerm] = useState("");
  const [countryDropdownOpen, setCountryDropdownOpen] = useState(false);
  const [countriesList, setCountriesList] = useState([]);
  const [updatedClientProfiles, setUpdatedClientProfiles] = useState([]);
  const compWebsiteRef = useRef(null);
  const accManagerRef = useRef(null);
  const accManagerSelectDropdownRef = useRef(null);
  const contactPointRef = useRef(null);
  const contactPointSelectDropdownRef = useRef(null);
  const countryDropdownRef = useRef(null);
  let token = null;
  const { user } = useSelector((state) => state.auth);
  const { appSettings } = useSelector((state) => state.appSettings);
  if (user) {
    if (user && user.token) {
      token = user.token;
    }
  }

  const {
    data: clientProfiles = [],
    isLoading,
    refetch: refetchClientProfiles,
  } = useFetchClientDetails(token);
  const { data: userIds = [], isLoading: isLoadingUser } = useUserById(token);
  const deleteClientMutation = useDeleteClientDetails(token); // Use the custom delete hook
  const updateClientMutation = useUpdateClientDetails(token); // Use the custom update hook

  useEffect(() => {
    refetchClientProfiles();
    if (props.updateClientList) {
      refetchClientProfiles();
    }
    setUpdatedClientProfiles(clientProfiles);
    const sortedCountries = Countries.slice().sort((a, b) =>
      a.country.localeCompare(b.country)
    );
    setCountriesList(sortedCountries);
  }, [props.updateClientList, clientProfiles]);

  const filteredData = updatedClientProfiles.filter((item) => {
    const searchTerm = props.filter.trim().toLowerCase();
    const hasMatch = item.client_name.toLowerCase().includes(searchTerm);
    return hasMatch;
  });

  const handleToggleCountryDropdown = () => {
    setCountrySearchTerm("");
    setCountryDropdownOpen((prevOpen) => !prevOpen);
  };

  const handleCountrySearchInputChange = (e) => {
    setCountrySearchTerm(e.target.value);
  };

  const filteredCountries = countriesList.filter((country) => {
    return country.country
      .toLowerCase()
      .includes(countrySearchTerm.toLowerCase());
  });

  const handleToggleAccManagerDropdown = () => {
    setUserSearchTerm("");
    setAccManagerDropdownOpen((prevOpen) => !prevOpen);
  };

  const handleSelectAccManager = (selectedAccManager) => {
    setAccManager(selectedAccManager);
    setUserSearchTerm("");
    setAccManagerDropdownOpen(false);
  };

  const handleAccManagerSearchInputChange = (e) => {
    setUserSearchTerm(e.target.value);
  };

  const handleToggleContactPointDropdown = () => {
    setUserSearchTerm("");
    setContactPointDropdownOpen((prevOpen) => !prevOpen);
  };

  const handleSelectContactPoint = (selectedContactPoint) => {
    setContactPoint(selectedContactPoint);
    setUserSearchTerm("");
    setContactPointDropdownOpen(false);
  };

  const handleContactPointSearchInputChange = (e) => {
    setUserSearchTerm(e.target.value);
  };

  const filteredUsers = userIds.filter((user) => {
    const fullName = user.first_name
      ? `${user.first_name} ${user.middle_name || ""} ${user.last_name}`
      : `${
          user.company_first_name
            ? user.company_first_name +
              (user.company_last_name && user.company_last_name)
            : user.company_name
        }`;
    return fullName.toLowerCase().includes(userSearchTerm.toLowerCase());
  });

  const handleClickOutsideDropdown = (event) => {
    const isClickInsideCountryDropdown =
      countryDropdownRef.current &&
      countryDropdownRef.current.contains(event.target);
    const isClickInsideAccManagerSelectDropdown =
      accManagerSelectDropdownRef.current &&
      accManagerSelectDropdownRef.current.contains(event.target);
    const isClickInsideContactPointSelectDropdown =
      contactPointSelectDropdownRef.current &&
      contactPointSelectDropdownRef.current.contains(event.target);
    if (!isClickInsideCountryDropdown) {
      setCountryDropdownOpen(false);
    }
    if (!isClickInsideAccManagerSelectDropdown) {
      setAccManagerDropdownOpen(false);
    }
    if (!isClickInsideContactPointSelectDropdown) {
      setContactPointDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutsideDropdown);
    return () => {
      document.removeEventListener("click", handleClickOutsideDropdown);
    };
  }, []);

  const handleOpenModal = (item) => {
    setIsModalOpen(true);
    setSelectedId(item._id);
    setClientName(item.client_name);
    setEmail(item.email);
    setMobileNo(item.mobile_no);
    if (item.acc_manager) {
      setAccManager(item.acc_manager._id);
    } else {
      setAccManager("");
    }
    setUserSearchTerm("");
    setAccManagerDropdownOpen(false);
    setAccManagerIsValid(false);
    if (item.contact_point) {
      setContactPoint(item.contact_point._id);
    } else {
      setContactPoint("");
    }
    setContactPointDropdownOpen(false);
    setContactPointIsValid(false);
    setAddress(item.address);
    setCountry(item.country);
    setCompanyWebsite(item.company_website);
    setCompanyLogoName(item.company_logo);
    setAdditionalInfo(item.additional_info);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleDelete = () => {
    setIsDeleteModalOpen(true);
  };

  const handleConfirmDelete = async () => {
    try {
      setIsSubmitLoading(true);
      await deleteClientMutation.mutateAsync(selectedId);
      setUpdatedClientProfiles((prevState) =>
        prevState.filter((clientProfiles) => clientProfiles._id !== selectedId)
      );
      toast.success("Client removed successfully", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      global.config.activityLog(
        window.location.href,
        "Client",
        `Client (${selectedId}) deleted successfully`
      );
      handleCloseModal();
      refetchClientProfiles();
    } catch (error) {
      console.error(error);
      global.config.slackMessage(error.toString());
      if (error.response?.data?.status === "deleted") {
        localStorage.removeItem("user");
        dispatch(logout());
        dispatch(reset());
        dispatch(invoiceReset());
        dispatch(headerReset());
        dispatch(appSettingsReset());
        toast.error("This user is deleted!", {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/");
        return;
      }
      toast.error("Something went wrong", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } finally {
      setIsSubmitLoading(false);
    }
    setIsDeleteModalOpen(false);
  };

  const handleCancelDelete = () => {
    setIsDeleteModalOpen(false);
  };

  const handleLogoUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setCompanyLogo(e.target.files[0]);
      setCompanyLogoName(file.name);
    } else {
      setCompanyLogo(null);
      setCompanyLogoName("");
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setAccManagerIsValid(false);
    setContactPointIsValid(false);
    setIsValidUrl(false);
    const pattern = /^(ftp|http|https):\/\/[^ "]+$/;
    if (accManager === "" || contactPoint === "" || clientName === "") {
      if (accManager === "") {
        setAccManagerIsValid(true);
        accManagerRef && accManagerRef.current.focus();
        return;
      } else if (contactPoint === "") {
        setContactPointIsValid(true);
        contactPointRef && contactPointRef.current.focus();
        return;
      }

      toast.error("Please fill all required fields!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    if (companyWebsite && !pattern.test(companyWebsite)) {
      toast.error("It's not a valid URL.", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setIsValidUrl(true);
      compWebsiteRef && compWebsiteRef.current.focus();
      return;
    }
    const requestData = {
      client_name: clientName,
      mobile_no: mobileNo,
      address,
      country,
      acc_manager: accManager,
      contact_point: contactPoint,
      company_website: companyWebsite,
      additional_info: additionalInfo,
    };

    const formData = new FormData();
    formData.append("company_logo", companyLogo);
    for (let key in requestData) {
      formData.append(key, requestData[key]);
    }
    try {
      setIsSubmitLoading(true);
      await updateClientMutation.mutateAsync({
        selectedId,
        data: formData,
      });

      setUpdatedClientProfiles((prevState) => {
        const updatedClientProfiles = prevState.map((item) => {
          if (item._id === selectedId) {
            return {
              ...item,
              client_name: clientName,
              mobile_no: mobileNo,
              address,
              country,
              acc_manager: accManager,
              contact_point: contactPoint,
              company_website: companyWebsite,
              additional_info: additionalInfo,
            };
          }
          return item;
        });
        return updatedClientProfiles;
      });
      toast.success("Client updated successfully", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      handleCloseModal();
      global.config.activityLog(
        window.location.href,
        "Client",
        `Client (${clientName}) updated successfully`
      );
      refetchClientProfiles();
    } catch (error) {
      console.log(error);
      global.config.slackMessage(error.toString());
      if (error.response?.data?.status === "deleted") {
        localStorage.removeItem("user");
        dispatch(logout());
        dispatch(reset());
        dispatch(invoiceReset());
        dispatch(headerReset());
        dispatch(appSettingsReset());
        toast.error("This user is deleted!", {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/");
        return;
      }
      alert("Something went wrong");
    } finally {
      setIsSubmitLoading(false); // Set isLoading to false after the approval process is complete
    }
  };

  return (
    <div className="client-ls-outer">
      <div className="modal-invite-main-outter">
        <Modal
          className="client-modal"
          isOpen={isModalOpen}
          onRequestClose={handleCloseModal}
          contentLabel="Edit Client Account"
          style={{
            content: {
              height: "41%",
              width: "30%",
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              parent: document.querySelector(".admin-outer.time.tracker"),
            },
          }}
        >
          <div className="modal">
            <div className="modal-close-invite outter-modal-main">
              <a className="" onClick={handleCloseModal}>
                <img src={crssrImage} alt="Edit Icon" />{" "}
              </a>
            </div>
            <div className="modal-client-head">
              <h2 className="">Edit Client Account</h2>
            </div>
            <div className="form-field-client-outer profile-details-edit">
              <form onSubmit={handleSubmit}>
                <div className="form-field-client">
                  <label htmlFor="clientName" className="form-label">
                    {global.config.locate(
                      appSettings && appSettings.language,
                      "Client Name"
                    )}
                  </label>
                  <input
                    type="text"
                    id="clientName"
                    name="clientName"
                    className="form-input"
                    value={clientName}
                    onChange={(e) => setClientName(e.target.value)}
                    required
                  />
                </div>
                <div className="form-field-client">
                  <label htmlFor="emailAddress" className="form-label">
                    {global.config.locate(
                      appSettings && appSettings.language,
                      "Email Address"
                    )}
                  </label>
                  <input
                    type="email"
                    id="emailAddress"
                    name="emailAddress"
                    className="form-input readonly"
                    value={email}
                    readOnly
                    required
                  />
                </div>
                <div className="inner-detail-inner form-field-client">
                  <label htmlFor="mobileNo" className="form-label">
                    {global.config.locate(
                      appSettings && appSettings.language,
                      "Mobile_No"
                    )}
                  </label>
                  <PhoneInput
                    placeholder="Enter phone number"
                    value={mobileNo}
                    onChange={setMobileNo}
                  />
                </div>
                <div className="form-field-client">
                  <label htmlFor="companyWebsite" className="form-label">
                    {global.config.locate(
                      appSettings && appSettings.language,
                      "Company Website"
                    )}
                  </label>
                  <input
                    type="text"
                    id="companyWebsite"
                    name="companyWebsite"
                    className="form-input"
                    value={companyWebsite}
                    onChange={(e) => setCompanyWebsite(e.target.value)}
                    ref={compWebsiteRef}
                    required
                  />
                  {isValidUrl && (
                    <span className="text-red-500 text-xs italic">
                      Please enter a valid URL!!
                    </span>
                  )}
                </div>
                <div className="form-field-client">
                  <label htmlFor="companyLogo" className="form-label">
                    {global.config.locate(
                      appSettings && appSettings.language,
                      "Company Logo"
                    )}
                  </label>
                  <div className="file-input-wrapper">
                    <input
                      type="text"
                      className="form-input form-input-file"
                      value={companyLogoName}
                      readOnly
                    />
                    <input
                      type="file"
                      id="companyLogo"
                      name="companyLogo"
                      className="form-input hidden-input"
                      accept="image/*"
                      onChange={handleLogoUpload}
                    />
                    <label htmlFor="companyLogo" className="file-input-label">
                      <img src={Plus} alt="Browse" />
                      Browse
                    </label>
                  </div>
                </div>
                <div className="form-field-client">
                  <label htmlFor="client" className="form-label">
                    {global.config.locate(
                      appSettings && appSettings.language,
                      "Account Manager"
                    )}
                  </label>

                  <div
                    ref={accManagerSelectDropdownRef}
                    className={`cursor ${accManagerDropdownOpen ? "open" : ""}`}
                  >
                    <span
                      id="user-select"
                      onClick={handleToggleAccManagerDropdown}
                      className={`custom-dropdown-user-new custom-dropdown-user ${
                        accManagerIsValid ? "input-validation-error" : ""
                      }`}
                      ref={accManagerRef}
                    >
                      {accManager ? (
                        userIds?.find((user) => user._id === accManager)
                          ?.first_name ? (
                          <>
                            {
                              userIds?.find((user) => user._id === accManager)
                                ?.first_name
                            }{" "}
                            {userIds?.find((user) => user._id === accManager)
                              ?.middle_name &&
                            userIds?.find((user) => user._id === accManager)
                              ?.middle_name !== "null"
                              ? userIds?.find((user) => user._id === accManager)
                                  ?.middle_name + " "
                              : ""}
                            {
                              userIds?.find((user) => user._id === accManager)
                                ?.last_name
                            }{" "}
                          </>
                        ) : (
                          <>
                            {userIds.find((user) => user._id === accManager)
                              ?.company_first_name
                              ? `${
                                  userIds.find(
                                    (user) => user._id === accManager
                                  )?.company_first_name
                                } ${
                                  userIds.find(
                                    (user) => user._id === accManager
                                  )?.company_last_name
                                    ? userIds.find(
                                        (user) => user._id === accManager
                                      )?.company_last_name
                                    : ""
                                }`
                              : `${
                                  userIds.find(
                                    (user) => user._id === accManager
                                  )?.company_name || ""
                                }(Company)`}
                          </>
                        )
                      ) : (
                        global.config.locate(
                          appSettings && appSettings.language,
                          "Select Account Manager"
                        )
                      )}
                    </span>
                    {accManagerDropdownOpen && (
                      <div className="custom-dropdown-new">
                        <input
                          type="text"
                          className="search-bar"
                          placeholder={global.config.locate(
                            appSettings && appSettings.language,
                            "Search"
                          )}
                          value={userSearchTerm}
                          onChange={handleAccManagerSearchInputChange}
                        />
                        <ul className="client-list">
                          {filteredUsers.length > 0 &&
                            filteredUsers
                              .slice()
                              .sort((a, b) => {
                                const nameA = a.first_name
                                  ? `${a.first_name} ${a.middle_name || ""} ${
                                      a.last_name
                                    }`
                                  : `${
                                      a.company_first_name
                                        ? a.company_first_name +
                                          (a.company_last_name &&
                                            a.company_last_name)
                                        : a.company_name
                                    }`;
                                const nameB = b.first_name
                                  ? `${b.first_name} ${b.middle_name || ""} ${
                                      b.last_name
                                    }`
                                  : `${
                                      b.company_first_name
                                        ? b.company_first_name +
                                          (b.company_last_name &&
                                            b.company_last_name)
                                        : b.company_name
                                    }`;
                                return nameA.localeCompare(nameB);
                              })
                              .map((item) => (
                                <li
                                  key={item._id}
                                  className={
                                    accManager === item._id
                                      ? "selected-item"
                                      : ""
                                  }
                                  onClick={() =>
                                    handleSelectAccManager(item._id)
                                  }
                                >
                                  {item.first_name ? (
                                    <>
                                      {item.first_name}{" "}
                                      {item.middle_name &&
                                      item.middle_name !== "null"
                                        ? item.middle_name + " "
                                        : ""}
                                      {item.last_name}{" "}
                                    </>
                                  ) : (
                                    <>
                                      {item.company_first_name
                                        ? `${item.company_first_name} ${
                                            item.company_last_name
                                              ? item.company_last_name
                                              : ""
                                          }`
                                        : `${item.company_name || ""}(Company)`}
                                    </>
                                  )}
                                </li>
                              ))}
                        </ul>
                      </div>
                    )}
                  </div>

                  {accManagerIsValid && (
                    <span className="text-red-500 text-xs italic">
                      Account Manager needs to be selected
                    </span>
                  )}
                </div>
                <div className="form-field-client">
                  <label htmlFor="client" className="form-label">
                    Contact Point
                  </label>
                  <div
                    ref={contactPointSelectDropdownRef}
                    className={`cursor ${
                      contactPointDropdownOpen ? "open" : ""
                    }`}
                  >
                    <span
                      id="user-select"
                      onClick={handleToggleContactPointDropdown}
                      className={`custom-dropdown-user-new custom-dropdown-user ${
                        contactPointIsValid ? "input-validation-error" : ""
                      }`}
                      ref={contactPointRef}
                    >
                      {contactPoint ? (
                        userIds?.find((user) => user._id === contactPoint)
                          ?.first_name ? (
                          <>
                            {
                              userIds?.find((user) => user._id === contactPoint)
                                ?.first_name
                            }{" "}
                            {userIds?.find((user) => user._id === contactPoint)
                              ?.middle_name &&
                            userIds?.find((user) => user._id === contactPoint)
                              ?.middle_name !== "null"
                              ? userIds?.find(
                                  (user) => user._id === contactPoint
                                )?.middle_name + " "
                              : ""}
                            {
                              userIds?.find((user) => user._id === contactPoint)
                                ?.last_name
                            }{" "}
                          </>
                        ) : (
                          <>
                            {userIds.find((user) => user._id === contactPoint)
                              ?.company_first_name
                              ? `${
                                  userIds.find(
                                    (user) => user._id === contactPoint
                                  )?.company_first_name
                                } ${
                                  userIds.find(
                                    (user) => user._id === contactPoint
                                  )?.company_last_name
                                    ? userIds.find(
                                        (user) => user._id === contactPoint
                                      )?.company_last_name
                                    : ""
                                }`
                              : `${
                                  userIds.find(
                                    (user) => user._id === contactPoint
                                  )?.company_name || ""
                                }(Company)`}
                          </>
                        )
                      ) : (
                        "Select Contact Point Person"
                      )}
                    </span>
                    {contactPointDropdownOpen && (
                      <div className="custom-dropdown-new">
                        <input
                          type="text"
                          className="search-bar"
                          placeholder="Search..."
                          value={userSearchTerm}
                          onChange={handleContactPointSearchInputChange}
                        />
                        <ul className="client-list">
                          {filteredUsers.length > 0 &&
                            filteredUsers
                              .slice()
                              .sort((a, b) => {
                                const nameA = a.first_name
                                  ? `${a.first_name} ${a.middle_name || ""} ${
                                      a.last_name
                                    }`
                                  : `${
                                      a.company_first_name
                                        ? a.company_first_name +
                                          (a.company_last_name &&
                                            a.company_last_name)
                                        : a.company_name
                                    }`;
                                const nameB = b.first_name
                                  ? `${b.first_name} ${b.middle_name || ""} ${
                                      b.last_name
                                    }`
                                  : `${
                                      b.company_first_name
                                        ? b.company_first_name +
                                          (b.company_last_name &&
                                            b.company_last_name)
                                        : b.company_name
                                    }`;
                                return nameA.localeCompare(nameB);
                              })
                              .map((item) => (
                                <li
                                  key={item._id}
                                  className={
                                    contactPoint === item._id
                                      ? "selected-item"
                                      : ""
                                  }
                                  onClick={() =>
                                    handleSelectContactPoint(item._id)
                                  }
                                >
                                  {item.first_name ? (
                                    <>
                                      {item.first_name}{" "}
                                      {item.middle_name &&
                                      item.middle_name !== "null"
                                        ? item.middle_name + " "
                                        : ""}
                                      {item.last_name}{" "}
                                    </>
                                  ) : (
                                    <>
                                      {item.company_first_name
                                        ? `${item.company_first_name} ${
                                            item.company_last_name
                                              ? item.company_last_name
                                              : ""
                                          }`
                                        : `${item.company_name || ""}(Company)`}
                                    </>
                                  )}
                                </li>
                              ))}
                        </ul>
                      </div>
                    )}
                  </div>

                  {contactPointIsValid && (
                    <span className="text-red-500 text-xs italic">
                      Contact Point needs to be selected
                    </span>
                  )}
                </div>
                <div className="form-field-client">
                  <label htmlFor="address" className="form-label">
                    {global.config.locate(
                      appSettings && appSettings.language,
                      "Address"
                    )}
                  </label>
                  <input
                    type="text"
                    id="address"
                    name="address"
                    className="form-input"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    required
                  />
                </div>
                <div className="form-field-client">
                  <label htmlFor="country" className="form-label">
                    {global.config.locate(
                      appSettings && appSettings.language,
                      "Country"
                    )}
                  </label>

                  <div
                    ref={countryDropdownRef}
                    className="select-modal-mainM custom-dropdown-user-new-outer"
                  >
                    <span
                      id="country"
                      onClick={handleToggleCountryDropdown}
                      className="custom-dropdown-user-new"
                    >
                      {country
                        ? country
                        : appSettings && appSettings.language
                        ? global.config.locate(
                            appSettings.language,
                            "Select Country"
                          )
                        : null}
                    </span>
                    {countryDropdownOpen && (
                      <div className="custom-dropdown-new">
                        <input
                          type="text"
                          className="search-bar"
                          placeholder={global.config.locate(
                            appSettings && appSettings.language,
                            "Search"
                          )}
                          value={countrySearchTerm}
                          onChange={handleCountrySearchInputChange}
                        />
                        <ul className="country-list">
                          {filteredCountries.length > 0 &&
                            filteredCountries.map((c) => (
                              <li
                                key={c.country}
                                className={
                                  country === c.country ? "selected-item" : ""
                                }
                                onClick={() => {
                                  setCountry(c.country);
                                  setCountryDropdownOpen(false);
                                }}
                              >
                                {c.country}
                              </li>
                            ))}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-field-client">
                  <label htmlFor="additionalInformation" className="form-label">
                    {global.config.locate(
                      appSettings && appSettings.language,
                      "Additional Information"
                    )}
                  </label>
                  <textarea
                    id="additionalInformation"
                    name="additionalInformation"
                    className="form-textarea"
                    rows="4"
                    value={additionalInfo}
                    onChange={(e) => setAdditionalInfo(e.target.value)}
                  ></textarea>
                </div>
                <div className="form-field-client-btns">
                  <button
                    type="button"
                    className="delete-client"
                    onClick={handleDelete}
                    disabled={isSubmitLoading}
                  >
                    <img src={Delete} alt="Delete" />{" "}
                    {global.config.locate(
                      appSettings && appSettings.language,
                      "Delete"
                    )}
                  </button>

                  <Modal
                    className="delete-modal"
                    isOpen={isDeleteModalOpen}
                    onRequestClose={handleCancelDelete}
                    contentLabel="Confirm Delete"
                    style={{
                      content: {
                        height: "41%",
                        width: "30%",
                        position: "fixed",
                        top: "36%",
                        left: "50%",
                        transform: "translate(-19.75%, 0%)",
                        parent: document.querySelector(
                          ".admin-outer.time.tracker"
                        ),
                      },
                    }}
                  >
                    <div className="delete-modal-content">
                      <h2>
                        {global.config.locate(
                          appSettings && appSettings.language,
                          "Confirm_Delete"
                        )}
                      </h2>
                      <p>
                        {global.config.locate(
                          appSettings && appSettings.language,
                          "Are you sure you want to delete this client?"
                        )}
                      </p>
                      <div className="delete-modal-buttons">
                        <button
                          className="buttons-rejected"
                          onClick={handleConfirmDelete}
                          disabled={isSubmitLoading}
                        >
                          {isSubmitLoading ? (
                            <div className="outter-load-table">
                              <ThreeDots
                                height="18"
                                width="20"
                                radius="9"
                                color="white"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                              />
                            </div>
                          ) : (
                            global.config.locate(
                              appSettings && appSettings.language,
                              "Delete"
                            )
                          )}
                        </button>
                        <button
                          onClick={handleCancelDelete}
                          disabled={isSubmitLoading}
                        >
                          {global.config.locate(
                            appSettings && appSettings.language,
                            "Cancel"
                          )}
                        </button>
                      </div>
                    </div>
                  </Modal>
                  <button
                    type="submit"
                    className="save-changes"
                    disabled={isSubmitLoading}
                  >
                    {isSubmitLoading ? (
                      <div className="outter-load-table">
                        <ThreeDots
                          height="18"
                          width="20"
                          radius="9"
                          color="white"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClassName=""
                          visible={true}
                        />
                      </div>
                    ) : (
                      global.config.locate(
                        appSettings && appSettings.language,
                        "Save_changes"
                      )
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal>
      </div>
      <div className="client-ls-inner">
        {isLoading == true ? (
          <div className="outter-load-table">
            <ThreeDots
              height="80"
              width="80"
              radius="9"
              color="#405BFF"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
        ) : (
          <>
            {filteredData.map((item, index) => (
              <div className="ls-box" key={item._id}>
                <div className="ls-box-logo">
                  {item.company_logo !== "null" &&
                  item.company_logo !== null ? (
                    <img
                      src={`/uploads/clientCompyLogo/lowQuality/${item.company_logo}`}
                      alt="Logo"
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src =
                          "/uploads/clientCompyLogo/lowQuality/DefaultCompLogo.png";
                      }}
                    />
                  ) : (
                    <img src={DefultCompLogo} alt="Logo" />
                  )}
                </div>
                <div className="client-name">{item.client_name}</div>
                <div className="hover-box">
                  <a href="#" onClick={() => handleOpenModal(item)}>
                    <span className="edit-icon">
                      <img src={Edit} alt="Edit" />
                    </span>
                    {global.config.locate(
                      appSettings && appSettings.language,
                      "Edit"
                    )}
                  </a>
                </div>
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default TimeTrackerClientListing;
