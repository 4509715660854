import axios from 'axios';
import { useQuery } from 'react-query';

const fetchTeamMembers = async (teamId, token) => {
  const response = await axios.get(`/api/team-members/list/${teamId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

const fetchUserList = async (token) => {
  const response = await axios.get('/api/user/user-users', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const useTeamMembers = (teamId, token) => {
  return useQuery(['teamMembers', teamId], () => fetchTeamMembers(teamId, token));
};

export const useUserList = (token) => {
  return useQuery('userList', () => fetchUserList(token));
};