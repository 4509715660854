import React from "react";
import ActivityLogs from "../../components/ActivityLog/ActivityLogs";

function ActivityLog()  {
    return(
        <div className="react-outer">
            <ActivityLogs/>
        </div>
    )
}

export default ActivityLog;