import React from "react";
import VendorInviteRegisteration from "../../../components/Vendors/VendorInvite/VendorInviteRegisteration";

function VendorInviteRegister()  {
    return(
        <div className="react-outer">
            <VendorInviteRegisteration/>
        </div>
    )
}

export default VendorInviteRegister;