import moment from "moment";
import clientInvoiceService from './clientInvoiceService';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
//get user from localStaorage
const clientInvoice = JSON.parse(localStorage.getItem('clientInvoiceData'));
const initialState = {
    clientInvoiceData: clientInvoice ? clientInvoice : null,
}
//Register user

export const setClientInvoiceData = createAsyncThunk('invoice/setClientInvoiceData', async (autoData) => {
    return await clientInvoiceService.clientInvoiceData(autoData)
})



export const clientInvoiceSlice = createSlice({
    name: 'clientInvoice',
    initialState,
    reducers: {
        reset: (state) => {
            state.clientInvoiceData = null
            
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(setClientInvoiceData.fulfilled, (state, action) => {
                state.clientInvoiceData = action.payload
            })
    }
})

export const { reset } = clientInvoiceSlice.actions
export default clientInvoiceSlice.reducer 