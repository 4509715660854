import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./OnboardingFormResponse.css";

function OnboardingFormResponce() {
  return (
    <div className="onboarding-form-response">
      <div className="response-title">You’re all set!</div>
      <div className="response-sub-text">
        Thank you for registering! Your account has been created. To access your
        Talenton account, please log in using your credentials.
      </div>
      <div className="form-backto-menu">
        <Link to="/user/profile/settings">Go to my account</Link>
      </div>
    </div>
  );
}

export default OnboardingFormResponce;
