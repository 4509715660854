import axios from 'axios';
import { useQuery, useMutation, useQueryClient } from 'react-query';

const API_BASE_URL = '/api';

// Fetch users department
export const useFetchUserDepartments = (token) => {
    return useQuery('userDepartments', async () => {
        const response = await axios.get(`${API_BASE_URL}/user-department`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    });
};

// Delete a time-off request
export const useDeleteUserDepartment = (token) => {
    const queryClient = useQueryClient(); // Get the queryClient
    return useMutation(
        (selectedId) =>
            axios.get(`/api/user-department/delete/${selectedId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }),
        {
            onSuccess: () => {
                // You can perform any actions needed after the request is successfully deleted.
                // For example, refetch the time-off requests.
                queryClient.invalidateQueries('userDepartments');
            },
        }
    );
};

// Create User Department
export const useCreateUserDepartment = (token) => {
    const queryClient = useQueryClient(); // Get the queryClient
    return useMutation(
        (requestData) =>
            axios.post(`${API_BASE_URL}/user-department/create`, requestData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }).then((response) => response.data),
        {
            onSuccess: () => {
                // You can perform any actions needed after the request is successfully created.
                // For example, refetch the time-off requests.
                queryClient.invalidateQueries('userDepartments');
            },
        }
    );
};

// update User Department
export const useUpdateUserDepartment = (token) => {
    const queryClient = useQueryClient();
    return useMutation(
        ({ selectedId, user_department }) =>
            axios.put(`${API_BASE_URL}/user-department/update/${selectedId}`, { user_department }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }),
        {
            onSuccess: () => {
                // You can perform any actions needed after the request is successfully created.
                // For example, refetch the users.
                queryClient.invalidateQueries('userDepartments');
            },
        }
    );
};