import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import TruncateFieldView from '../../../helpers/truncateFieldView';
import { ClientColumn, ClientLoggedHours, ClientRoi, ClientTotalCost, ClientTotalRevenue, ProjectLoggedHours, ProjectRoi, ProjectTotalCost } from '../../Services/reportingOverview';
import { convertCurrencyHelper } from '../../../../helpers/forexConvertor';

// import 'primereact/resources/themes/saga-blue/theme.css';
// import 'primereact/resources/primereact.min.css';
// import 'primeicons/primeicons.css';

const CostPerClientOverview = ({ clients, clientLoading, clientError }) => {
    const reportingFinancialOverview = useSelector((state) => state.reportingFinancialOverview);
    const { forex } = useSelector((state) => state.forex);
    const settings = JSON.parse(localStorage.getItem("settings"));
    const [filteredData, setFilteredData] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);

    useEffect(() => {
        if (clients && reportingFinancialOverview) {
            const filtered = clients.filter(client =>
                !client.is_delete &&
                (reportingFinancialOverview.financialOverviewClient && reportingFinancialOverview.financialOverviewClient.includes(client._id)) &&
                (reportingFinancialOverview.financialOverviewYear && new Date(client.created_at).getFullYear() === reportingFinancialOverview.financialOverviewYear) &&
                (reportingFinancialOverview.financialOverviewCurrency && reportingFinancialOverview.financialOverviewCurrency.includes(client?.preferred_currency || 'USD'))
            );
            const sortedData = filtered.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
            setFilteredData(sortedData);
        }
    }, [clients, reportingFinancialOverview]);

    const onPageChange = (e) => {
        setCurrentPage(e.first / rowsPerPage);
    };

    return (
        <div className="datatable-team-maintb">
            <DataTable
                value={filteredData}
                removableSort
                tableStyle={{
                    minWidth: "50rem",
                    width: "100%",
                    textAlign: "left",
                }}
                paginator={filteredData.length > 10}
                paginatorClassName="recent-invoices-pagination"
                rows={rowsPerPage} // Set the number of rows per page
                first={currentPage * rowsPerPage} // Set the current page using currentPage
                onPage={onPageChange} // Update currentPage when changing pages
            >
                <Column
                    field="client_name"
                    // body={(rowData) => {
                    //     const clientName = rowData?.client_name || rowData?.legal_entity_name || 'Client Name';
                    //     return <TruncateFieldView textContent={clientName} />;
                    // }}
                    body={(rowData) => <ClientColumn rowData={rowData} />}
                    header="Client Name"
                    // className="fixed-sec3"
                    sortable
                    style={{ paddingRight: "44px", minWidth: "230px" }}
                ></Column>
                <Column
                    field="total_revenue"
                    header="Total Net Profit"
                    body={ClientTotalRevenue}
                    sortable
                    style={{ paddingRight: '44px' }}
                />
                <Column
                    field="tracked_hours"
                    header="Time Logged"
                    className="fixed-sec4"
                    body={ClientLoggedHours}
                    // sortable
                    style={{ paddingRight: "44px" }}
                ></Column>
                <Column
                    field="cost"
                    header="Cost"
                    body={ClientTotalCost}
                    // className=""
                    // sortable
                    style={{ paddingRight: "44px" }}
                ></Column>
                <Column
                    field="roi"
                    header="ROI"
                    body={ClientRoi}
                    // className="amount-bold"
                    // sortable
                    style={{ paddingRight: "44px" }}
                ></Column>
            </DataTable>
        </div>
    );
};

export default CostPerClientOverview;
