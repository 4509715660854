import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  sprintid: null,
  sprintname: null,
};

export const setSprintid = createAsyncThunk(
  "invoice/setSprintid",
  async (sprintId) => {
    return sprintId;
  }
);

export const setSprintname = createAsyncThunk(
  "invoice/setSprintname",
  async (sprintName) => {
    return sprintName;
  }
);

export const sprintSlice = createSlice({
  name: "sprint",
  initialState,
  reducers: {
    reset: (state) => {
      state.sprintid = null;
      state.sprintname = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setSprintid.fulfilled, (state, action) => {
        state.sprintid = action.payload;
      })
      .addCase(setSprintname.fulfilled, (state, action) => {
        state.sprintname = action.payload;
      });
  },
});

export const { reset } = sprintSlice.actions;
export default sprintSlice.reducer;