import axios from 'axios';

export async function sendVerificationMail(email, userType, token) {
    try {
        const response = await axios.post(
            '/api/onboard/user-invite',
            {
                email: email,
                userType: userType
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                  //  Authorization: `Bearer ${token}`
                }
            }
        );
        
        // You can handle the response here if needed
        console.log('test',response.data);
        return response.data;
    } catch (error) {
        // Handle error
        console.error('Error sending verification email:', error);
        throw error;
    }
}