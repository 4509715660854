const venInvoiceStatus = {
  ven_inv_status: [
    {
      _id: 0,
      status: "Not Reviewed",
    },
    {
      _id: 1,
      status: "Approved",
    },
    {
      _id: 2,
      status: "Rejected",
    },
    {
      _id: 3,
      status: "Canceled",
    },
  ],
};
export default venInvoiceStatus;
