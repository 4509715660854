import React from "react";
import VendorProfile from "../../../components/Vendors/VendorProfile/VendorProfile";

function VendorProfileSection()  {
    return(
        <div className="profile-section-outer">
            <VendorProfile />
        </div>
    )
}

export default VendorProfileSection;