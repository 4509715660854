import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { DateRangePicker, defaultStaticRanges, createStaticRanges } from "react-date-range";
import { useSelector, useDispatch } from 'react-redux';
import { enUS } from "date-fns/locale";
import { addDays, subDays } from "date-fns";

const Calendar = ({ onCancel, onChange, initialRange }) => {
  const [state, setState] = useState([
    {
      startDate: initialRange.startDate || null,
      endDate: initialRange.endDate || null,
      key: "selection",
    },
  ]);

  const [isDatesSelected, setIsDatesSelected] = useState(false);
  const [dateState, setDateState] = useState(null);
  const [dateRangeState, setDateRangeState] = useState(null);
  const [months, setMonths] = useState(2);
  const { appSettings } = useSelector((state) => state.appSettings);

  useEffect(() => {
    setState([
      {
        startDate: initialRange.startDate || null,
        endDate: initialRange.endDate || null,
        key: "selection",
      },
    ]);
  }, [initialRange]);

  useEffect(() => {
    setIsDatesSelected(state[0].startDate !== null && state[0].endDate !== null);
  }, [state]);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 1200px)');

    const handleMediaQueryChange = (e) => {
      if (e.matches) {
        setMonths(1);
      } else {
        setMonths(2);
      }
    };

    // Set the initial value
    handleMediaQueryChange(mediaQuery);

    // Add the event listener
    mediaQuery.addEventListener('change', handleMediaQueryChange);

    // Cleanup event listener on component unmount
    return () => {
      mediaQuery.removeEventListener('change', handleMediaQueryChange);
    };
  }, []);

  const staticRanges = [
    ...defaultStaticRanges.slice(0, 4),
    ...createStaticRanges([
      {
        range: () => {
          const today = new Date();
          const weekStartDay = appSettings.weekStartDay === 'Sunday' ? 1 : 0;

          // Calculate the end date (Sunday of this week if week starts on Monday, Saturday of last week otherwise)
          const endDate = new Date();
          if (today.getDay() !== weekStartDay) {
            endDate.setDate(
              today.getDate() - ((today.getDay() + 7 + weekStartDay) % 7)
            );
          } else if (weekStartDay === 0) {
            // If week starts on Sunday
            endDate.setDate(today.getDate() - 1); // Set end date to yesterday
          }

          // Calculate the start date (Monday of the week before last if week starts on Monday, Sunday of the week before last otherwise)
          const startDate = new Date(endDate);
          startDate.setDate(startDate.getDate() - 13); // Start date is 14 days before the end date

          return {
            startDate,
            endDate,
            key: "selection",
          };
        },
        isSelected: (range) => {
          const today = new Date();
          const weekStartDay = appSettings.weekStartDay === 'Sunday' ? 1 : 0;

          // Calculate the end date (Sunday of this week if week starts on Monday, Saturday of last week otherwise)
          const endDate = new Date();
          if (today.getDay() !== weekStartDay) {
            endDate.setDate(
              today.getDate() - ((today.getDay() + 7 + weekStartDay) % 7)
            );
          } else if (weekStartDay === 0) {
            // If week starts on Sunday
            endDate.setDate(today.getDate() - 1); // Set end date to yesterday
          }

          // Calculate the start date (Monday of the week before last if week starts on Monday, Sunday of the week before last otherwise)
          const startDate = new Date(endDate);
          startDate.setDate(startDate.getDate() - 13); // Start date is 14 days before the end date

          // Adjust the time to compare only dates, not times
          const adjustedRangeStartDate = new Date(
            range.startDate.getFullYear(),
            range.startDate.getMonth(),
            range.startDate.getDate()
          );
          const adjustedStartDate = new Date(
            startDate.getFullYear(),
            startDate.getMonth(),
            startDate.getDate()
          );
          const adjustedRangeEndDate = new Date(
            range.endDate.getFullYear(),
            range.endDate.getMonth(),
            range.endDate.getDate()
          );
          const adjustedEndDate = new Date(
            endDate.getFullYear(),
            endDate.getMonth(),
            endDate.getDate()
          );

          return (
            adjustedRangeStartDate.getTime() === adjustedStartDate.getTime() &&
            adjustedRangeEndDate.getTime() === adjustedEndDate.getTime()
          );
        },
        label: 'Past Two Weeks',
      },
    ]),
    ...defaultStaticRanges.slice(4),
    ...createStaticRanges([
      {
        range: (value) => ({
          startDate: new Date(new Date().getFullYear(), 0, 1), // This year
          endDate: new Date(new Date().getFullYear(), 11, 31), // End of this year
          key: "selection",
        }),
        isSelected: (range) => range.startDate.getTime() === new Date(new Date().getFullYear(), 0, 1).getTime(),
        label: 'This Year',
      },
      {
        range: (value) => ({
          startDate: new Date(new Date().getFullYear() - 1, 0, 1), // Last year
          endDate: new Date(new Date().getFullYear() - 1, 11, 31),
          key: "selection",
        }),
        isSelected: (range) =>
          range.startDate.getTime() ===
          new Date(new Date().getFullYear() - 1, 0, 1).getTime(),
        label: "Last Year",
      },
    ]),
  ];

  const customLocale = {
    ...enUS,
    formatLong: {
      ...enUS.formatLong,
      month: () => 'MMMM',
    },
    localize: {
      ...enUS.localize,
      day: (narrow) => {
        const shortDayNames = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
        return shortDayNames[narrow];
      },
      month: (narrow) => {
        const fullMonthNames = [
          'January', 'February', 'March', 'April', 'May', 'June',
          'July', 'August', 'September', 'October', 'November', 'December'
        ];
        return fullMonthNames[narrow];
      },
    },
  };

  const handleOnChange = (ranges) => {

    const { selection } = ranges;



    const start = new Date(selection.startDate);
    const startOfDay = new Date(start.getFullYear(), start.getMonth(), start.getDate());
    const end = new Date(selection.endDate);
    const endOfDay = new Date(end.getFullYear(), end.getMonth(), end.getDate());
    const dateRangeArray = [startOfDay, endOfDay];

    if (selection && selection.startDate && selection.endDate) {
      setDateState({
        startDate: startOfDay && startOfDay,
        endDate: endOfDay && endOfDay,
        key: "selection",
      });
      // onChange(selection);
      // setState([selection]);
      setState([
        {
          startDate: startOfDay && startOfDay,
          endDate: endOfDay && endOfDay,
          key: "selection",
        },
      ]);
    }


  };

  const handleDateChange = () => {
    if (dateState && dateState.startDate && dateState.endDate) {
      setDateRangeState(dateState);
      onChange(dateState);
    }

    // setState([dateState]);
  };

  const handleCancelDateChange = () => {
    onCancel();
    setState([
      {
        startDate: (dateRangeState && dateRangeState.startDate) || initialRange.startDate || null,
        endDate: (dateRangeState && dateRangeState.endDate) || initialRange.endDate || null,
        key: "selection",
      },
    ]);
  };

  return (
    <React.Fragment>
      <DateRangePicker
        onChange={handleOnChange}
        showSelectionPreview={true}
        moveRangeOnFirstSelection={false}
        months={months}
        ranges={state}
        staticRanges={staticRanges}
        timeZone="Asia/Kolkata" //{global.config.getTimeZone()}
        locale={customLocale}
        weekStartsOn={
          appSettings.weekStartDay && appSettings.weekStartDay === "Monday"
            ? 1
            : 0
        }
        direction="horizontal"
      />

      <div className="new-calendar-button">
        <button className="calendar-button-new" onClick={handleCancelDateChange}>Cancel</button>
        <button className="calendar-button-new" onClick={handleDateChange} disabled={!isDatesSelected}>OK</button>
      </div>
    </React.Fragment>
  );
};

Calendar.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  initialRange: PropTypes.shape({
    startDate: PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date),
  }).isRequired,
};

export default Calendar;
