import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import TotalUsers from "../../../../images/icons/total-users.svg";
import { calculateTotalUsers } from './Services/reportTimeWorked';

// Functional component
function TimeWorkedTotalUsers({ trackReport }) {
  const [totalUsers, setTotalUsers] = useState(0);
  const reportingTimeWorked = useSelector((state) => state.reportingTimeWorked);

  useEffect(() => {
    const totalUsersTracked = calculateTotalUsers(trackReport, reportingTimeWorked);
    setTotalUsers(totalUsersTracked);
  }, [trackReport, reportingTimeWorked]);
  return (
    <div>
      <div className="overview-item">
        <div className="overview-title-outer">
          <div className="overview-title">Total Users</div>
          <div className="overview-icon">
            <img src={TotalUsers} alt="cLock" />
          </div>
        </div>

        <div className="overview-rice">
          {totalUsers}
        </div>
      </div>
    </div>
  );
}

export default TimeWorkedTotalUsers;
