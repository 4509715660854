import moment from "moment";
import vendorInvoiceService from './vendorInvoiceService';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
//get user from localStaorage
const vendorInvoice = JSON.parse(localStorage.getItem('vendorInvoiceData'));
const initialState = {
    vendorInvoiceData: vendorInvoice ? vendorInvoice : null,
}
//Register user

export const setVendorInvoiceData = createAsyncThunk('invoice/setVendorInvoiceData', async (autoData) => {
    return await vendorInvoiceService.vendorInvoiceData(autoData)
})



export const vendorInvoiceSlice = createSlice({
    name: 'vendorInvoice',
    initialState,
    reducers: {
        reset: (state) => {
            state.vendorInvoiceData = null
            
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(setVendorInvoiceData.fulfilled, (state, action) => {
                state.vendorInvoiceData = action.payload
            })
    }
})

export const { reset } = vendorInvoiceSlice.actions
export default vendorInvoiceSlice.reducer 