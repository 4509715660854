import axios from "axios";
import "./UserNotificationCenter.css";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { logout, reset } from "../../../../features/auth/authSlice";
import { reset as invoiceReset } from "../../../../features/invoice/invoiceSlice.js";
import { setNotification } from "../../../../features/notification/notificationSlice.js";
import { reset as headerReset } from "../../../../features/headerText/headerTextSlice.js";
import { reset as appSettingsReset } from "../../../../features/appSettings/appSettingsSlice.js";
import { useAppSettings } from "../../../../features/appSettings/useAppSettings.jsx";
import { useUserSettings } from "../../../../features/users/useUserSettings.jsx";
import userCategory from "../../../../constants/userCategory.js";

const UserNotificationCenter = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isUserTimeoffSubmissionStatusLoading, setIsUserTimeoffSubmissionStatusLoading] = useState(false);
    const [isUserTimeoffDecisionStatusLoading, setIsUserTimeoffDecisionStatusLoading] = useState(false);
    const [isUserReimbursementSubmissionStatusLoading, setIsUserReimbursementSubmissionStatusLoading] = useState(false);
    const [isUserReimbursementDecisionStatusLoading, setIsUserReimbursementDecisionStatusLoading] = useState(false);
    const [isUserRunningTimerAlertStatusLoading, setIsUserRunningTimerAlertStatusLoading] = useState(false);
    const [isUserInvoiceCreationStatusLoading, setIsUserInvoiceCreationStatusLoading] = useState(false);
    const [isUserInvoiceDecisionStatusLoading, setIsUserInvoiceDecisionStatusLoading] = useState(false);
    const [isUserInvoicePaymentStatusLoading, setIsUserInvoicePaymentStatusLoading] = useState(false);
    const [isUserBankAccountStatusLoading, setIsUserBankAccountStatusLoading] = useState(false);
    const [userSettingView, setUserSettingView] = useState([]);
    const user = useSelector((state) => state.auth.user);
    const [isTimeOffOpen, setIsTimeOffOpen] = useState(true);
    const [isReimbursementOpen, setIsReimbursementOpen] = useState(true);
    const [isUserActivityOpen, setIsUserActivityOpen] = useState(true);
    const [isInvoiceNotificationOpen, setIsInvoiceNotificationOpen] = useState(true);
    const [isFinancialSettingsOpen, setIsFinancialSettingsOpen] = useState(true);
    const { appSettings } = useSelector((state) => state.appSettings);

    let token = null;
    if (user) {
        if (user && user.token) {
            token = user.token;
        }
    }

    let userType = user.userType;


    const { data: userSettingsData = [], isLoading: userSettingsLoading, refetch: refetchUserSettingData } = useUserSettings(props && props.id, token,);

    useEffect(() => {
        refetchUserSettingData();
        setUserSettingView(userSettingsData)
    }, [props.id, userSettingsData, userSettingsLoading]);

    const handleClickOutsideDropdown = (event) => {
        // const isClickInsideCurrencyDropdown = currencyDropdownRef.current && currencyDropdownRef.current.contains(event.target);
        // if (!isClickInsideCurrencyDropdown) {
        //     setCurrencyDropdownOpen(false);
        // }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutsideDropdown);
        return () => {
            document.removeEventListener('click', handleClickOutsideDropdown);
        };
    }, []);


    const toggleTimeOffAccordion = () => {
        setIsTimeOffOpen(!isTimeOffOpen);
    };

    const handleEnableUserTimeoffSubmissionStatusChange = async (id) => {
        try {
            setIsUserTimeoffSubmissionStatusLoading(true);
            const response = await axios.get(`/api/user-settings/time-off/submission/status-change/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            toast.success(`User time-off submission email notification updated successfully`, {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            global.config.activityLog(
                window.location.href,
                "User",
                `User "${id}'s" time-off submission email notification updated successfully`
            );
            refetchUserSettingData();
            dispatch(setNotification(Date.now()));
            setUserSettingView((prevState) => {
                if (prevState) {
                    if (prevState.userId === id) {
                        return { ...prevState, user_setting_timeoff_submission: !prevState.user_setting_timeoff_submission };
                    }
                    return prevState;
                } else {
                    return {
                        userId: id,
                        user_setting_timeoff_submission: true
                    };
                }
            });
        } catch (error) {
            global.config.slackMessage(error.toString());
            if (error.response?.data?.status === "deleted") {
                localStorage.removeItem("user");
                dispatch(logout());
                dispatch(reset());
                dispatch(invoiceReset());
                dispatch(headerReset());
                dispatch(appSettingsReset());
                toast.error('This user is deleted!', {
                    position: "bottom-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                navigate("/");
                return;
            }
            toast.error("Something went wrong in changing app time-off submission email status!", {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            global.config.activityLog(
                window.location.href,
                "User",
                `Something went wrong while changing app "${id}'s" time-off submission email status!!`
            );
            console.log(error);
        } finally {
            setIsUserTimeoffSubmissionStatusLoading(false);
        }
    };

    const handleEnableUserTimeoffDecisionStatusChange = async (id) => {
        try {
            setIsUserTimeoffDecisionStatusLoading(true);
            const response = await axios.get(`/api/user-settings/time-off/decision/status-change/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            toast.success(`User time-off decision email notification updated successfully`, {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            global.config.activityLog(
                window.location.href,
                "User",
                `User "${id}'s" time-off decision email notification updated successfully`
            );
            refetchUserSettingData();
            dispatch(setNotification(Date.now()));
            setUserSettingView((prevState) => {
                if (prevState) {
                    if (prevState.userId === id) {
                        return { ...prevState, user_setting_timeoff_decision: !prevState.user_setting_timeoff_decision };
                    }
                    return prevState;
                } else {
                    return {
                        userId: id,
                        user_setting_timeoff_decision: true
                    };
                }
            });
        } catch (error) {
            global.config.slackMessage(error.toString());
            if (error.response?.data?.status === "deleted") {
                localStorage.removeItem("user");
                dispatch(logout());
                dispatch(reset());
                dispatch(invoiceReset());
                dispatch(headerReset());
                dispatch(appSettingsReset());
                toast.error('This user is deleted!', {
                    position: "bottom-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                navigate("/");
                return;
            }
            toast.error("Something went wrong in changing user time-off decision email status!", {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            global.config.activityLog(
                window.location.href,
                "User",
                `Something went wrong while changing app "${id}'s" time-off decision email status!!`
            );
            console.log(error);
        } finally {
            setIsUserTimeoffDecisionStatusLoading(false);
        }
    };

    const toggleReimbursementAccordion = () => {
        setIsReimbursementOpen(!isReimbursementOpen);
    };

    const handleEnableUserReimbursementSubmissionStatusChange = async (id) => {
        try {
            setIsUserReimbursementSubmissionStatusLoading(true);
            const response = await axios.get(`/api/user-settings/reimbursement/submission/status-change/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            toast.success(`User reimbursement submission email notification updated successfully`, {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            global.config.activityLog(
                window.location.href,
                "User",
                `User "${id}'s" reimbursement submission email notification updated successfully`
            );
            refetchUserSettingData();
            dispatch(setNotification(Date.now()));
            setUserSettingView((prevState) => {
                if (prevState) {
                    if (prevState.userId === id) {
                        return { ...prevState, user_setting_reimbursement_submission: !prevState.user_setting_reimbursement_submission };
                    }
                    return prevState;
                } else {
                    return {
                        userId: id,
                        user_setting_reimbursement_submission: true
                    };
                }
            });
        } catch (error) {
            global.config.slackMessage(error.toString());
            if (error.response?.data?.status === "deleted") {
                localStorage.removeItem("user");
                dispatch(logout());
                dispatch(reset());
                dispatch(invoiceReset());
                dispatch(headerReset());
                dispatch(appSettingsReset());
                toast.error('This user is deleted!', {
                    position: "bottom-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                navigate("/");
                return;
            }
            toast.error("Something went wrong in changing app reimbursement submission email status!", {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            global.config.activityLog(
                window.location.href,
                "User",
                `Something went wrong while changing app "${id}'s" reimbursement submission email status!!`
            );
            console.log(error);
        } finally {
            setIsUserReimbursementSubmissionStatusLoading(false);
        }
    };

    const handleEnableUserReimbursementDecisionStatusChange = async (id) => {
        try {
            setIsUserReimbursementDecisionStatusLoading(true);
            const response = await axios.get(`/api/user-settings/reimbursement/decision/status-change/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            toast.success(`User reimbursement decision email notification updated successfully`, {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            global.config.activityLog(
                window.location.href,
                "User",
                `User "${id}'s" reimbursement decision email notification updated successfully`
            );
            refetchUserSettingData();
            dispatch(setNotification(Date.now()));
            setUserSettingView((prevState) => {
                if (prevState) {
                    if (prevState.userId === id) {
                        return { ...prevState, user_setting_reimbursement_decision: !prevState.user_setting_reimbursement_decision };
                    }
                    return prevState;
                } else {
                    return {
                        userId: id,
                        user_setting_reimbursement_decision: true
                    };
                }
            });
        } catch (error) {
            global.config.slackMessage(error.toString());
            if (error.response?.data?.status === "deleted") {
                localStorage.removeItem("user");
                dispatch(logout());
                dispatch(reset());
                dispatch(invoiceReset());
                dispatch(headerReset());
                dispatch(appSettingsReset());
                toast.error('This user is deleted!', {
                    position: "bottom-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                navigate("/");
                return;
            }
            toast.error("Something went wrong in changing app reimbursement decision email status!", {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            global.config.activityLog(
                window.location.href,
                "User",
                `Something went wrong while changing app "${id}'s" reimbursement decision email status!!`
            );
            console.log(error);
        } finally {
            setIsUserReimbursementDecisionStatusLoading(false);
        }
    };

    const toggleUserActivityAccordion = () => {
        setIsUserActivityOpen(!isUserActivityOpen);
    };

    const handleEnableUserRunningTimerAlertStatusChange = async (id) => {
        try {
            setIsUserRunningTimerAlertStatusLoading(true);
            const response = await axios.get(`/api/user-settings/running-timer/alert/status-change/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            toast.success(`User running timer alert email notification updated successfully`, {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            global.config.activityLog(
                window.location.href,
                "User",
                `User "${id}'s" running timer alert email notification updated successfully`
            );
            refetchUserSettingData();
            dispatch(setNotification(Date.now()));
            setUserSettingView((prevState) => {
                if (prevState) {
                    if (prevState.userId === id) {
                        return { ...prevState, user_setting_running_timer_alert: !prevState.user_setting_running_timer_alert };
                    }
                    return prevState;
                } else {
                    return {
                        userId: id,
                        user_setting_running_timer_alert: true
                    };
                }
            });
        } catch (error) {
            if (error.response?.data?.status === "deleted") {
                localStorage.removeItem("user");
                dispatch(logout());
                dispatch(reset());
                dispatch(invoiceReset());
                dispatch(headerReset());
                dispatch(appSettingsReset());
                toast.error('This user is deleted!', {
                    position: "bottom-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                navigate("/");
                return;
            }
            toast.error("Something went wrong in changing user running timer alert email status!", {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            global.config.activityLog(
                window.location.href,
                "User",
                `Something went wrong while changing user "${id}'s" running timer alert email status!!`
            );
            global.config.slackMessage(error.toString());
            console.log(error);
        } finally {
            setIsUserRunningTimerAlertStatusLoading(false);
        }
    };

    const toggleInvoiceNotificationAccordion = () => {
        setIsInvoiceNotificationOpen(!isInvoiceNotificationOpen);
    }

    const handleEnableUserInvoiceCreationStatusChange = async (id) => {
        try {
            setIsUserInvoiceCreationStatusLoading(true);
            const response = await axios.get(`/api/user-settings/invoice-creation/status-change/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            toast.success(`User invoice creation alert email notification updated successfully`, {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            global.config.activityLog(
                window.location.href,
                "User",
                `User "${id}'s" invoice creation email notification updated successfully`
            );
            refetchUserSettingData();
            dispatch(setNotification(Date.now()));
            setUserSettingView((prevState) => {
                if (prevState) {
                    if (prevState.userId === id) {
                        return { ...prevState, user_setting_invoice_creation: !prevState.user_setting_invoice_creation };
                    }
                    return prevState;
                } else {
                    return {
                        userId: id,
                        user_setting_invoice_creation: true
                    };
                }
            });
        } catch (error) {
            if (error.response?.data?.status === "deleted") {
                localStorage.removeItem("user");
                dispatch(logout());
                dispatch(reset());
                dispatch(invoiceReset());
                dispatch(headerReset());
                dispatch(appSettingsReset());
                toast.error('This user is deleted!', {
                    position: "bottom-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                navigate("/");
                return;
            }
            toast.error("Something went wrong in changing user invoice creation email status!", {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            global.config.activityLog(
                window.location.href,
                "User",
                `Something went wrong while changing user "${id}'s" invoice creation email status!!`
            );
            global.config.slackMessage(error.toString());
            console.log(error);
        } finally {
            setIsUserInvoiceCreationStatusLoading(false);
        }
    };

    const handleEnableUserInvoiceDecisionStatusChange = async (id) => {
        try {
            setIsUserInvoiceDecisionStatusLoading(true);
            const response = await axios.get(`/api/user-settings/invoice-decision/status-change/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            toast.success(`User invoice decision alert email notification updated successfully`, {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            global.config.activityLog(
                window.location.href,
                "User",
                `User "${id}'s" invoice decision email notification updated successfully`
            );
            refetchUserSettingData();
            dispatch(setNotification(Date.now()));
            setUserSettingView((prevState) => {
                if (prevState) {
                    if (prevState.userId === id) {
                        return { ...prevState, user_setting_invoice_decision: !prevState.user_setting_invoice_decision };
                    }
                    return prevState;
                } else {
                    return {
                        userId: id,
                        user_setting_invoice_decision: true
                    };
                }
            });
        } catch (error) {
            if (error.response?.data?.status === "deleted") {
                localStorage.removeItem("user");
                dispatch(logout());
                dispatch(reset());
                dispatch(invoiceReset());
                dispatch(headerReset());
                dispatch(appSettingsReset());
                toast.error('This user is deleted!', {
                    position: "bottom-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                navigate("/");
                return;
            }
            toast.error("Something went wrong in changing user invoice decision email status!", {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            global.config.activityLog(
                window.location.href,
                "User",
                `Something went wrong while changing user "${id}'s" invoice decision email status!!`
            );
            global.config.slackMessage(error.toString());
            console.log(error);
        } finally {
            setIsUserInvoiceDecisionStatusLoading(false);
        }
    };

    const handleEnableUserInvoicePaymentStatusChange = async (id) => {
        try {
            setIsUserInvoicePaymentStatusLoading(true);
            const response = await axios.get(`/api/user-settings/invoice-payment/status-change/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            toast.success(`User invoice payment alert email notification updated successfully`, {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            global.config.activityLog(
                window.location.href,
                "User",
                `User "${id}'s" invoice payment email notification updated successfully`
            );
            refetchUserSettingData();
            dispatch(setNotification(Date.now()));
            setUserSettingView((prevState) => {
                if (prevState) {
                    if (prevState.userId === id) {
                        return { ...prevState, user_setting_invoice_payment: !prevState.user_setting_invoice_payment };
                    }
                    return prevState;
                } else {
                    return {
                        userId: id,
                        user_setting_invoice_payment: true
                    };
                }
            });
        } catch (error) {
            if (error.response?.data?.status === "deleted") {
                localStorage.removeItem("user");
                dispatch(logout());
                dispatch(reset());
                dispatch(invoiceReset());
                dispatch(headerReset());
                dispatch(appSettingsReset());
                toast.error('This user is deleted!', {
                    position: "bottom-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                navigate("/");
                return;
            }
            toast.error("Something went wrong in changing user invoice payment email status!", {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            global.config.activityLog(
                window.location.href,
                "User",
                `Something went wrong while changing user "${id}'s" invoice payment email status!!`
            );
            global.config.slackMessage(error.toString());
            console.log(error);
        } finally {
            setIsUserInvoicePaymentStatusLoading(false);
        }
    };

    const toggleFinancialSettingsAccordion = () => {
        setIsFinancialSettingsOpen(!isFinancialSettingsOpen);
    }

    const handleEnableUserBankAccountStatusChange = async (id) => {
        try {
            setIsUserBankAccountStatusLoading(true);
            const response = await axios.get(`/api/user-settings/bank-account/status-change/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            toast.success(`User bank account email notification updated successfully`, {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            global.config.activityLog(
                window.location.href,
                "User",
                `User "${id}'s" bank account email notification updated successfully`
            );
            refetchUserSettingData();
            dispatch(setNotification(Date.now()));
            setUserSettingView((prevState) => {
                if (prevState) {
                    if (prevState.userId === id) {
                        return { ...prevState, user_setting_bank_account_updates: !prevState.user_setting_bank_account_updates };
                    }
                    return prevState;
                } else {
                    return {
                        userId: id,
                        user_setting_bank_account_updates: true
                    };
                }
            });
        } catch (error) {
            if (error.response?.data?.status === "deleted") {
                localStorage.removeItem("user");
                dispatch(logout());
                dispatch(reset());
                dispatch(invoiceReset());
                dispatch(headerReset());
                dispatch(appSettingsReset());
                toast.error('This user is deleted!', {
                    position: "bottom-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                navigate("/");
                return;
            }
            toast.error("Something went wrong in changing user bank account email status!", {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            global.config.activityLog(
                window.location.href,
                "User",
                `Something went wrong while changing user "${id}'s" bank account email status!!`
            );
            global.config.slackMessage(error.toString());
            console.log(error);
        } finally {
            setIsUserBankAccountStatusLoading(false);
        }
    };

    return (
        <div>
            {(user && (user.userCategory !== userCategory.Vendor)) && (
                <div className="notification-accordion">
                    <div>
                        <div
                            onClick={toggleTimeOffAccordion}
                            className={isTimeOffOpen ? "accordion-trigger active" : "accordion-trigger"}
                            style={{ cursor: "pointer" }}
                        >
                            <div className="accordion-head">
                                <span className="accordion-arrow">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="8" viewBox="0 0 21 10" fill="none">
                                        <path d="M1 1L10.6 9L20.2 1" stroke="#282828" stroke-width="1.5" />
                                    </svg>
                                </span>
                                Time Off
                            </div>
                        </div>
                        {isTimeOffOpen && (
                            <div className="accordion-content">
                                <div className="acoordion-inner">
                                    <div className="accordion-left">
                                        <div className="accordion-title">
                                            Time Off Request Submission
                                        </div>
                                        <div className="accordion-subtitle">
                                            Get notified when you submits a new time off request
                                        </div>
                                    </div>
                                    <div className="accordion-right">
                                        {isUserTimeoffSubmissionStatusLoading === true && (
                                            <ThreeDots
                                                height="18"
                                                width="20"
                                                radius="9"
                                                color="#6479f9"
                                                ariaLabel="three-dots-loading"
                                                wrapperStyle={{}}
                                                wrapperClassName=""
                                                visible={true}
                                            />
                                        )}
                                        {userSettingsLoading === true ? (
                                            <ThreeDots
                                                height="18"
                                                width="20"
                                                radius="9"
                                                color="#6479f9"
                                                ariaLabel="three-dots-loading"
                                                wrapperStyle={{}}
                                                wrapperClassName=""
                                                visible={true}
                                            />
                                        ) : (
                                            <label className="custom-switch">
                                                <input
                                                    type="checkbox"
                                                    className="custom-switch-input"
                                                    id="emailnotification"
                                                    name="enable_emailnotification"
                                                    value="1"
                                                    checked={userSettingView && userSettingView?.user_setting_timeoff_submission ? userSettingView.user_setting_timeoff_submission === true : false}
                                                    onChange={() => handleEnableUserTimeoffSubmissionStatusChange(props.id)}
                                                />
                                                <span style={{ cursor: "pointer" }} className="custom-switch-slider"></span>
                                            </label>
                                        )}
                                    </div>
                                </div>
                                <div className="acoordion-inner">
                                    <div className="accordion-left">
                                        <div className="accordion-title">
                                            Time Off Request Decision
                                        </div>
                                        <div className="accordion-subtitle">
                                            Get notified when a time off request is approved or declined by the admin.
                                        </div>
                                    </div>
                                    <div className="accordion-right">
                                        {isUserTimeoffDecisionStatusLoading === true && (
                                            <ThreeDots
                                                height="18"
                                                width="20"
                                                radius="9"
                                                color="#6479f9"
                                                ariaLabel="three-dots-loading"
                                                wrapperStyle={{}}
                                                wrapperClassName=""
                                                visible={true}
                                            />
                                        )}
                                        {userSettingsLoading === true ? (
                                            <ThreeDots
                                                height="18"
                                                width="20"
                                                radius="9"
                                                color="#6479f9"
                                                ariaLabel="three-dots-loading"
                                                wrapperStyle={{}}
                                                wrapperClassName=""
                                                visible={true}
                                            />
                                        ) : (
                                            <label className="custom-switch">
                                                <input
                                                    type="checkbox"
                                                    className="custom-switch-input"
                                                    id="timeoff_decision_emailnotification"
                                                    name="enable_timeoff_decision_emailnotification"
                                                    value="1"
                                                    checked={userSettingView && userSettingView?.user_setting_timeoff_decision ? userSettingView.user_setting_timeoff_decision === true : false}
                                                    onChange={() => handleEnableUserTimeoffDecisionStatusChange(props.id)}
                                                />
                                                <span style={{ cursor: "pointer" }} className="custom-switch-slider"></span>
                                            </label>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}

            {(user && (user.userCategory !== userCategory.Vendor)) && (
                <div className="notification-accordion">
                    <div>
                        <div
                            onClick={toggleReimbursementAccordion}
                            className={isReimbursementOpen ? "accordion-trigger active" : "accordion-trigger"}
                            style={{ cursor: "pointer" }}
                        >
                            <div className="accordion-head">
                                <span className="accordion-arrow">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="8" viewBox="0 0 21 10" fill="none">
                                        <path d="M1 1L10.6 9L20.2 1" stroke="#282828" stroke-width="1.5" />
                                    </svg>
                                </span>
                                Reimbursement
                            </div>
                        </div>
                        {isReimbursementOpen && (
                            <div className="accordion-content">
                                <div className="acoordion-inner">
                                    <div className="accordion-left">
                                        <div className="accordion-title">
                                            Reimbursement Request Submission
                                        </div>
                                        <div className="accordion-subtitle">
                                            Get notified when you submits a new reimbursement request
                                        </div>
                                    </div>
                                    <div className="accordion-right">
                                        {isUserReimbursementSubmissionStatusLoading === true && (
                                            <ThreeDots
                                                height="18"
                                                width="20"
                                                radius="9"
                                                color="#6479f9"
                                                ariaLabel="three-dots-loading"
                                                wrapperStyle={{}}
                                                wrapperClassName=""
                                                visible={true}
                                            />
                                        )}
                                        {userSettingsLoading === true ? (
                                            <ThreeDots
                                                height="18"
                                                width="20"
                                                radius="9"
                                                color="#6479f9"
                                                ariaLabel="three-dots-loading"
                                                wrapperStyle={{}}
                                                wrapperClassName=""
                                                visible={true}
                                            />
                                        ) : (
                                            <label className="custom-switch">
                                                <input
                                                    type="checkbox"
                                                    className="custom-switch-input"
                                                    id="emailnotification"
                                                    name="enable_emailnotification"
                                                    value="1"
                                                    checked={userSettingView && userSettingView?.user_setting_reimbursement_submission ? userSettingView.user_setting_reimbursement_submission === true : false}
                                                    onChange={() => handleEnableUserReimbursementSubmissionStatusChange(props.id)}
                                                />
                                                <span style={{ cursor: "pointer" }} className="custom-switch-slider"></span>
                                            </label>
                                        )}
                                    </div>
                                </div>
                                <div className="acoordion-inner">
                                    <div className="accordion-left">
                                        <div className="accordion-title">
                                            Reimbursement Request Decision
                                        </div>
                                        <div className="accordion-subtitle">
                                            Get notified when a reimbursement request is approved or declined by the admin.
                                        </div>
                                    </div>
                                    <div className="accordion-right">
                                        {isUserReimbursementDecisionStatusLoading === true && (
                                            <ThreeDots
                                                height="18"
                                                width="20"
                                                radius="9"
                                                color="#6479f9"
                                                ariaLabel="three-dots-loading"
                                                wrapperStyle={{}}
                                                wrapperClassName=""
                                                visible={true}
                                            />
                                        )}
                                        {userSettingsLoading === true ? (
                                            <ThreeDots
                                                height="18"
                                                width="20"
                                                radius="9"
                                                color="#6479f9"
                                                ariaLabel="three-dots-loading"
                                                wrapperStyle={{}}
                                                wrapperClassName=""
                                                visible={true}
                                            />
                                        ) : (
                                            <label className="custom-switch">
                                                <input
                                                    type="checkbox"
                                                    className="custom-switch-input"
                                                    id="reimbursement_decision_emailnotification"
                                                    name="enable_reimbursement_decision_emailnotification"
                                                    value="1"
                                                    checked={userSettingView && userSettingView?.user_setting_reimbursement_decision ? userSettingView.user_setting_reimbursement_decision === true : false}
                                                    onChange={() => handleEnableUserReimbursementDecisionStatusChange(props.id)}
                                                />
                                                <span style={{ cursor: "pointer" }} className="custom-switch-slider"></span>
                                            </label>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}

            {(user && (user.userCategory !== userCategory.Vendor)) && (
                <div className="notification-accordion">
                    <div>
                        <div
                            onClick={toggleUserActivityAccordion}
                            className={isUserActivityOpen ? "accordion-trigger active" : "accordion-trigger"}
                            style={{ cursor: "pointer" }}
                        >
                            <div className="accordion-head">
                                <span className="accordion-arrow">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="8" viewBox="0 0 21 10" fill="none">
                                        <path d="M1 1L10.6 9L20.2 1" stroke="#282828" stroke-width="1.5" />
                                    </svg>
                                </span>
                                User Activity Notification
                            </div>
                        </div>
                        {isUserActivityOpen && (
                            <div className="accordion-content">
                                <div className="acoordion-inner">
                                    <div className="accordion-left">
                                        <div className="accordion-title">
                                            Running Timer Alert
                                        </div>
                                        <div className="accordion-subtitle">
                                            Get notified when the timer exceeds a set amount hours
                                        </div>
                                    </div>
                                    <div className="accordion-right">
                                        {isUserRunningTimerAlertStatusLoading === true && (
                                            <ThreeDots
                                                height="18"
                                                width="20"
                                                radius="9"
                                                color="#6479f9"
                                                ariaLabel="three-dots-loading"
                                                wrapperStyle={{}}
                                                wrapperClassName=""
                                                visible={true}
                                            />
                                        )}
                                        {userSettingsLoading === true ? (
                                            <ThreeDots
                                                height="18"
                                                width="20"
                                                radius="9"
                                                color="#6479f9"
                                                ariaLabel="three-dots-loading"
                                                wrapperStyle={{}}
                                                wrapperClassName=""
                                                visible={true}
                                            />
                                        ) : (
                                            <label className="custom-switch">
                                                <input
                                                    type="checkbox"
                                                    className="custom-switch-input"
                                                    id="runningtimeralert_emailnotification"
                                                    name="enable_runningtimeralert_emailnotification"
                                                    value="1"
                                                    checked={userSettingView && userSettingView?.user_setting_running_timer_alert ? userSettingView.user_setting_running_timer_alert === true : false}
                                                    onChange={() => handleEnableUserRunningTimerAlertStatusChange(props.id)}
                                                />
                                                <span style={{ cursor: "pointer" }} className="custom-switch-slider"></span>
                                            </label>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}

            <div className="notification-accordion">
                <div>
                    <div
                        onClick={toggleInvoiceNotificationAccordion}
                        className={isInvoiceNotificationOpen ? "accordion-trigger active" : "accordion-trigger"}
                        style={{ cursor: "pointer" }}
                    >
                        <div className="accordion-head">
                            <span className="accordion-arrow">
                                <svg xmlns="http://www.w3.org/2000/svg" width="19" height="8" viewBox="0 0 21 10" fill="none">
                                    <path d="M1 1L10.6 9L20.2 1" stroke="#282828" stroke-width="1.5" />
                                </svg>
                            </span>
                            Invoice Notification
                        </div>
                    </div>
                    {isInvoiceNotificationOpen && (
                        <div className="accordion-content">
                            <div className="acoordion-inner">
                                <div className="accordion-left">
                                    <div className="accordion-title">
                                        Invoice Creation
                                    </div>
                                    <div className="accordion-subtitle">
                                        Get notified when you create a new invoice
                                    </div>
                                </div>
                                <div className="accordion-right">
                                    {isUserInvoiceCreationStatusLoading === true && (
                                        <ThreeDots
                                            height="18"
                                            width="20"
                                            radius="9"
                                            color="#6479f9"
                                            ariaLabel="three-dots-loading"
                                            wrapperStyle={{}}
                                            wrapperClassName=""
                                            visible={true}
                                        />
                                    )}
                                    {userSettingsLoading === true ? (
                                        <ThreeDots
                                            height="18"
                                            width="20"
                                            radius="9"
                                            color="#6479f9"
                                            ariaLabel="three-dots-loading"
                                            wrapperStyle={{}}
                                            wrapperClassName=""
                                            visible={true}
                                        />
                                    ) : (
                                        <label className="custom-switch">
                                            <input
                                                type="checkbox"
                                                className="custom-switch-input"
                                                id="usersettingsinvoicecreation"
                                                name="enable_usersettingsinvoicecreation"
                                                value="1"
                                                checked={userSettingView && userSettingView?.user_setting_invoice_creation ? userSettingView.user_setting_invoice_creation === true : false}
                                                onChange={() => handleEnableUserInvoiceCreationStatusChange(props.id)}
                                            />
                                            <span style={{ cursor: "pointer" }} className="custom-switch-slider"></span>
                                        </label>
                                    )}
                                </div>
                            </div>

                            <div className="acoordion-inner">
                                <div className="accordion-left">
                                    <div className="accordion-title">
                                        Invoice Decision
                                    </div>
                                    <div className="accordion-subtitle">
                                        Get notified when an invoice is approved or declined by the admin
                                    </div>
                                </div>
                                <div className="accordion-right">
                                    {isUserInvoiceDecisionStatusLoading === true && (
                                        <ThreeDots
                                            height="18"
                                            width="20"
                                            radius="9"
                                            color="#6479f9"
                                            ariaLabel="three-dots-loading"
                                            wrapperStyle={{}}
                                            wrapperClassName=""
                                            visible={true}
                                        />
                                    )}
                                    {userSettingsLoading === true ? (
                                        <ThreeDots
                                            height="18"
                                            width="20"
                                            radius="9"
                                            color="#6479f9"
                                            ariaLabel="three-dots-loading"
                                            wrapperStyle={{}}
                                            wrapperClassName=""
                                            visible={true}
                                        />
                                    ) : (
                                        <label className="custom-switch">
                                            <input
                                                type="checkbox"
                                                className="custom-switch-input"
                                                id="usersettingsinvoicedecision"
                                                name="enable_usersettingsinvoicedecision"
                                                value="1"
                                                checked={userSettingView && userSettingView?.user_setting_invoice_decision ? userSettingView.user_setting_invoice_decision === true : false}
                                                onChange={() => handleEnableUserInvoiceDecisionStatusChange(props.id)}
                                            />
                                            <span style={{ cursor: "pointer" }} className="custom-switch-slider"></span>
                                        </label>
                                    )}
                                </div>
                            </div>

                            <div className="acoordion-inner">
                                <div className="accordion-left">
                                    <div className="accordion-title">
                                        Invoice Payment
                                    </div>
                                    <div className="accordion-subtitle">
                                        Get notified when an invoice is marked as paid by the admin
                                    </div>
                                </div>
                                <div className="accordion-right">
                                    {isUserInvoicePaymentStatusLoading === true && (
                                        <ThreeDots
                                            height="18"
                                            width="20"
                                            radius="9"
                                            color="#6479f9"
                                            ariaLabel="three-dots-loading"
                                            wrapperStyle={{}}
                                            wrapperClassName=""
                                            visible={true}
                                        />
                                    )}
                                    {userSettingsLoading === true ? (
                                        <ThreeDots
                                            height="18"
                                            width="20"
                                            radius="9"
                                            color="#6479f9"
                                            ariaLabel="three-dots-loading"
                                            wrapperStyle={{}}
                                            wrapperClassName=""
                                            visible={true}
                                        />
                                    ) : (
                                        <label className="custom-switch">
                                            <input
                                                type="checkbox"
                                                className="custom-switch-input"
                                                id="usersettingsinvoicepayment"
                                                name="enable_usersettingsinvoicepayment"
                                                value="1"
                                                checked={userSettingView && userSettingView?.user_setting_invoice_payment ? userSettingView.user_setting_invoice_payment === true : false}
                                                onChange={() => handleEnableUserInvoicePaymentStatusChange(props.id)}
                                            />
                                            <span style={{ cursor: "pointer" }} className="custom-switch-slider"></span>
                                        </label>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <div className="notification-accordion">
                <div>
                    <div
                        onClick={toggleFinancialSettingsAccordion}
                        className={isFinancialSettingsOpen ? "accordion-trigger active" : "accordion-trigger"}
                        style={{ cursor: "pointer" }}
                    >
                        <div className="accordion-head">
                            <span className="accordion-arrow">
                                <svg xmlns="http://www.w3.org/2000/svg" width="19" height="8" viewBox="0 0 21 10" fill="none">
                                    <path d="M1 1L10.6 9L20.2 1" stroke="#282828" stroke-width="1.5" />
                                </svg>
                            </span>
                            Financial Settings Notification
                        </div>
                    </div>
                    {isFinancialSettingsOpen && (
                        <div className="accordion-content">
                            <div className="acoordion-inner">
                                <div className="accordion-left">
                                    <div className="accordion-title">
                                        Bank Account Updates
                                    </div>
                                    <div className="accordion-subtitle">
                                        Notify the user and admin when a new bank account is added or removed.
                                    </div>
                                </div>
                                <div className="accordion-right">
                                    {isUserBankAccountStatusLoading === true && (
                                        <ThreeDots
                                            height="18"
                                            width="20"
                                            radius="9"
                                            color="#6479f9"
                                            ariaLabel="three-dots-loading"
                                            wrapperStyle={{}}
                                            wrapperClassName=""
                                            visible={true}
                                        />
                                    )}
                                    {userSettingsLoading === true ? (
                                        <ThreeDots
                                            height="18"
                                            width="20"
                                            radius="9"
                                            color="#6479f9"
                                            ariaLabel="three-dots-loading"
                                            wrapperStyle={{}}
                                            wrapperClassName=""
                                            visible={true}
                                        />
                                    ) : (
                                        <label className="custom-switch">
                                            <input
                                                type="checkbox"
                                                className="custom-switch-input"
                                                id="userbankaccountnotification"
                                                name="enable_userbankaccountnotification"
                                                value="1"
                                                checked={userSettingView && userSettingView?.user_setting_bank_account_updates ? userSettingView.user_setting_bank_account_updates === true : false}
                                                onChange={() => handleEnableUserBankAccountStatusChange(props.id)}
                                            />
                                            <span style={{ cursor: "pointer" }} className="custom-switch-slider"></span>
                                        </label>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default UserNotificationCenter;