const register = async (masterUserData) => {
    localStorage.setItem('masterUser', JSON.stringify(masterUserData))
}

const logout = async () => {
}

const login = async (masterUserData) => {
    localStorage.setItem('masterUser', JSON.stringify(masterUserData));
}

const authService = {
    register: register,
    logout: logout,
    login: login
}

export default authService;