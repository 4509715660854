import axios from "axios";
// import "./CheckEmail.css";
import { toast } from "react-toastify";
import React, { useEffect, useState } from "react";

import { validate } from "react-email-validator";
import CryptoJS from "crypto-js";
import Logo from "../../../images/logo/to-logo.svg";
import { ThreeDots } from "react-loader-spinner";
import LoginImage from "../../../images/login/lg-img.png";
import LoginImageMobile from "../../../images/login/lg-img-mob.png";
import Login from "../../../pages/auth/Login.jsx";
import { GoogleOAuthProvider, GoogleLogin, googleLogout, useGoogleLogin } from '@react-oauth/google';
import { useNavigate, useParams } from "react-router-dom";
import { AES, enc } from "crypto-js";



const GoogleLoginTeams = () => {


        const { encodedEmail } = useParams();
        const decryptedBytes = AES.decrypt(encodedEmail, "encryptionKey");
        const email = decryptedBytes.toString(enc.Utf8);
        const [isLoading, setIsLoading] = React.useState(false);

        console.log('email', email);

        const navigate = useNavigate();

        const [loading, setLoading] = useState(false);
        const domainName = window.location.hostname;


        const [teams, setTeams] = useState([]);

        useEffect(() => {
                setIsLoading(true);
            
                const getTeam = async () => {
                    try {
                        await new Promise(resolve => setTimeout(resolve, 500)); // 3-second delay
                        const getTeam = await axios.get(`/api/tenent/teams/${encodeURIComponent(AES.encrypt(email, "generatesecurepasswordstalenton").toString().replace(/\//g, "%2F"))}`, {
                            headers: {}
                        });
                        console.log('googleProfile', getTeam);
                        setTeams(getTeam.data);
                        
                        if(getTeam.data.length===0){
                                navigate("/");
                                toast.dismiss(); // Clears all current toasts
                                toast.error("User not registered", {
                                  position: "bottom-right",
                                  autoClose: 2000,
                                  hideProgressBar: false,
                                  closeOnClick: true,
                                  pauseOnHover: true,
                                  draggable: true,
                                  progress: undefined,
                                });
                               
                        }
                    } catch (error) {
                        console.log(error);
                    } finally {
                        setIsLoading(false);
                    }
                };
            
                getTeam();
            }, []);


       


      


        return (



                domainName === "app.talenton.io" || domainName === "talenton.app" || domainName === "localhost" ?
                        (
                                <>
                                        <div className="login-outer-forget auth-divide">
                                                <div className="login-inner">
                                                        <div className="top-logo-mobile">
                                                                <a href="/"><img src={Logo} alt="Talent On" /></a>
                                                        </div>
                                                        <div className="lg-lft">
                                                                <img src={LoginImage} alt="desktop" className="desktop-lg-img" />
                                                                <img
                                                                        src={LoginImageMobile}
                                                                        alt="mobile"
                                                                        className="mobile-lg-img"
                                                                />
                                                        </div>
                                                        <div className="lg-rgt">
                                                                <div className="top-logo">
                                                                        <a href="/"><img src={Logo} alt="Talent On" /></a>
                                                                </div>
                                                                <div className="login-form-sec">


                                                                        {isLoading ?


                                                                                (<> 
                                                                                <ThreeDots
                                                                                        height="38"
                                                                                        width="100"
                                                                                        radius="9"
                                                                                        color="blue"
                                                                                        ariaLabel="three-dots-loading"
                                                                                        wrapperStyle={{}}
                                                                                        wrapperClassName=""
                                                                                        visible={true}
                                                                                />
                                                                                </>)
                                                                                : (

                                                                                        <form >
                                                                                                <h3>Enter to your team</h3>
                                                                                                <div className="form-outer">
                                                                                                        <label>Choose your team</label>

                                                                                                        {teams.length > 0 &&

                                                                                                                teams.map((item) => (
                                                                                                                        <a  className="button-link" href={`https://${item.subDomain}.${domainName}/app/google/auth/team/verify/${localStorage.getItem('googleToken')}`}  >
                                                                                                                       {item.subDomain}.{domainName}
                                                                                                                        </a>
                                                                                                                ))
                                                                                                        }
                                                                                                </div>
                                                                                              
                                                                                               
                                                                                        </form>

                                                                                )}

                                                                </div>
                                                        </div>
                                                </div>
                                        </div>
                                </>)
                        :

                        (<> <>   <Login /> </></>)



        );
};

export default GoogleLoginTeams;