import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import typeOfContract from '../../../../constants/typeOfContract';
import { clientTotalRoi } from '../../Services/reportingOverview';

// Functional component
function ReportOverviewTotalRoi(props) {
  const [totalRoi, setTotalRoi] = useState(null);
  const reportingFinancialOverview = useSelector((state) => state.reportingFinancialOverview);
  const { forex } = useSelector((state) => state.forex);
  const settings = JSON.parse(localStorage.getItem("settings"));
  const trackReport = props.trackReport;
  const clientInvoices = props.clientInvoices;
  const projectCost = props.projectCost;
  const projects = props.projects;
  const sprints = props.sprints;
  const expenses = props.expenses;

  useEffect(() => {
    // const highestClient = calculateTotalRoi(props.trackReport, reportingOverview, settings.currency, forex);
    const totalRoiClient = clientTotalRoi(trackReport, projectCost, clientInvoices, projects, sprints, reportingFinancialOverview, settings.currency, forex, typeOfContract, expenses);
    setTotalRoi(totalRoiClient);
  }, [reportingFinancialOverview, forex, settings.currency, trackReport, projectCost, clientInvoices, projects, expenses]);
  return (
    <div>
      <div className="overview-item">
        <div className="overview-title-outer">
          <div className="overview-title">
            ROI
          </div>
          <div className="overview-icon">
            <svg width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g filter="url(#filter0_d_2951_120)">
                <circle cx="24.5" cy="24.5" r="20.5" fill="white" />
              </g>
              <path d="M25.4734 23.8733C24.4351 23.364 23.9927 23.0279 24.111 22.1824C24.1231 21.9416 24.2023 21.712 24.3367 21.5282C24.471 21.3444 24.6532 21.2165 24.8557 21.1638C25.0277 21.1161 25.2049 21.0989 25.3814 21.1129H25.4076C25.7653 21.1255 26.1116 21.2621 26.4021 21.505C26.4762 21.578 26.5692 21.6202 26.6662 21.625C26.7633 21.6298 26.859 21.5969 26.9382 21.5316C27.0175 21.4663 27.0758 21.3722 27.1041 21.2641C27.1323 21.1561 27.1288 21.0402 27.0942 20.9346C26.7603 20.5208 26.3124 20.256 25.8282 20.1859V19.4322C25.8282 19.2971 25.782 19.1675 25.6999 19.072C25.6177 18.9765 25.5063 18.9229 25.3901 18.9229C25.2739 18.9229 25.1625 18.9765 25.0804 19.072C24.9982 19.1675 24.952 19.2971 24.952 19.4322V20.1605C24.8518 20.1713 24.7522 20.19 24.6542 20.2165C24.29 20.3132 23.9611 20.5401 23.7136 20.8653C23.4661 21.1905 23.3125 21.5977 23.2742 22.0296C23.0421 23.7154 24.2029 24.3062 25.1317 24.7799C26.0604 25.2536 26.551 25.554 26.5992 26.2773C26.638 26.5437 26.598 26.8176 26.4859 27.0544C26.3737 27.2911 26.196 27.4768 25.9815 27.5811C25.7943 27.6758 25.594 27.7309 25.3901 27.7441C25.0855 27.7764 24.7784 27.7342 24.4887 27.6201C24.199 27.5061 23.933 27.3228 23.7079 27.082C23.6253 27.0221 23.5279 26.9959 23.4311 27.0075C23.3343 27.0192 23.2436 27.068 23.1733 27.1462C23.1029 27.2245 23.057 27.3277 23.0428 27.4397C23.0286 27.5516 23.0468 27.6659 23.0946 27.7644C23.6011 28.3338 24.2663 28.6735 24.9696 28.7219V29.5674C24.9696 29.7025 25.0157 29.832 25.0979 29.9275C25.18 30.023 25.2915 30.0767 25.4076 30.0767C25.5238 30.0767 25.6353 30.023 25.7174 29.9275C25.7996 29.832 25.8457 29.7025 25.8457 29.5674V28.6506C26.0249 28.6102 26.1997 28.547 26.367 28.4622C26.7412 28.2746 27.0526 27.9505 27.2542 27.5388C27.4557 27.1271 27.5364 26.6503 27.4841 26.1805C27.3527 24.8308 26.3101 24.3011 25.4734 23.8733Z" fill="#6A7988" stroke="#6A7988" stroke-width="0.3" />
              <path d="M25.2696 16.6924C23.7254 16.6924 22.2159 17.1503 20.9319 18.0082C19.6479 18.8661 18.6472 20.0855 18.0562 21.5122C17.4653 22.9389 17.3107 24.5087 17.6119 26.0233C17.9132 27.5378 18.6568 28.929 19.7487 30.0209C20.8407 31.1129 22.2319 31.8565 23.7464 32.1577C25.2609 32.459 26.8308 32.3044 28.2575 31.7134C29.6842 31.1225 30.9035 30.1218 31.7615 28.8378C32.6194 27.5538 33.0773 26.0443 33.0773 24.5001C33.0748 22.4301 32.2514 20.4456 30.7877 18.982C29.324 17.5183 27.3396 16.6949 25.2696 16.6924ZM25.2696 31.4005C23.9048 31.4005 22.5707 30.9958 21.4359 30.2376C20.3011 29.4794 19.4167 28.4017 18.8944 27.1408C18.3721 25.8799 18.2355 24.4924 18.5017 23.1539C18.768 21.8153 19.4252 20.5857 20.3902 19.6207C21.3553 18.6557 22.5848 17.9985 23.9234 17.7322C25.262 17.4659 26.6494 17.6026 27.9103 18.1249C29.1712 18.6472 30.2489 19.5316 31.0071 20.6664C31.7654 21.8012 32.1701 23.1353 32.1701 24.5001C32.1676 26.3294 31.4398 28.0831 30.1462 29.3767C28.8527 30.6703 27.099 31.3981 25.2696 31.4005Z" fill="#6A7988" stroke="#6A7988" stroke-width="0.3" />
              <path d="M13 24.334C12.9966 25.9761 13.3056 27.6026 13.9093 29.1196C14.5129 30.6365 15.3992 32.0138 16.5169 33.1718C16.5532 33.2381 16.6036 33.2949 16.6643 33.3377C16.725 33.3805 16.7944 33.4081 16.867 33.4185C16.9397 33.4288 17.0136 33.4216 17.0831 33.3974C17.1526 33.3732 17.2159 33.3326 17.2679 33.2788C17.3199 33.225 17.3593 33.1594 17.3831 33.0872C17.4069 33.015 17.4144 32.938 17.4049 32.8623C17.3955 32.7865 17.3695 32.7141 17.3289 32.6505C17.2883 32.587 17.2341 32.534 17.1707 32.4958C15.6205 30.8826 14.5645 28.8267 14.1363 26.5881C13.7082 24.3496 13.9271 22.0291 14.7655 19.9203C15.6038 17.8114 17.0238 16.0089 18.8459 14.741C20.6679 13.473 22.8101 12.7966 25.0014 12.7972L23.8084 14.1243C23.7532 14.1947 23.7175 14.2796 23.7054 14.3696C23.6932 14.4597 23.705 14.5514 23.7394 14.635C23.7739 14.7185 23.8297 14.7906 23.9007 14.8435C23.9718 14.8963 24.0554 14.9278 24.1424 14.9345C24.2054 14.935 24.2678 14.9215 24.3254 14.895C24.383 14.8684 24.4345 14.8294 24.4765 14.7804L26.2659 12.7922C26.3424 12.7015 26.3846 12.5849 26.3846 12.4641C26.3846 12.3434 26.3424 12.2268 26.2659 12.1361L24.4812 10.1478C24.3958 10.0577 24.2806 10.005 24.1591 10.0003C24.0376 9.99571 23.919 10.0396 23.8275 10.123C23.7423 10.2117 23.6925 10.3307 23.6881 10.4561C23.6837 10.5815 23.725 10.704 23.8036 10.799L24.758 11.8428C21.6198 11.9116 18.6323 13.2574 16.4347 15.592C14.2372 17.9265 13.0043 21.0644 13 24.334Z" fill="#6A7988" stroke="#6A7988" stroke-width="0.3" />
              <path d="M37.5389 24.8548C37.538 23.1219 37.1745 21.4089 36.4724 19.8289C35.7703 18.2489 34.7455 16.8378 33.4658 15.6887C33.3714 15.6096 33.2508 15.5701 33.1286 15.5782C33.0064 15.5863 32.8919 15.6415 32.8085 15.7325C32.7313 15.8272 32.6923 15.9481 32.6994 16.0708C32.7065 16.1935 32.7592 16.3089 32.8469 16.3938C34.5298 17.901 35.7238 19.8888 36.272 22.0962C36.8203 24.3037 36.6971 26.6275 35.9188 28.7627C35.1405 30.8979 33.7434 32.7447 31.9109 34.0606C30.0783 35.3765 27.8961 36.1 25.6505 36.1361L26.7204 34.9837C26.804 34.8935 26.8506 34.7744 26.8506 34.6506C26.8506 34.5268 26.804 34.4077 26.7204 34.3175C26.6317 34.2341 26.5151 34.1878 26.3942 34.1878C26.2732 34.1878 26.1566 34.2341 26.0679 34.3175L24.2736 36.2626C24.1967 36.3513 24.1543 36.4654 24.1543 36.5835C24.1543 36.7016 24.1967 36.8157 24.2736 36.9044L26.0679 38.8495C26.112 38.8963 26.1648 38.9338 26.2232 38.9596C26.2817 38.9855 26.3447 38.9993 26.4085 39.0002C26.5258 38.9987 26.6384 38.9537 26.7252 38.8738C26.8075 38.7839 26.8532 38.6658 26.8532 38.5431C26.8532 38.4205 26.8075 38.3024 26.7252 38.2125L25.6457 37.0843C28.8146 37.0346 31.8373 35.7247 34.062 33.437C36.2868 31.1492 37.5355 28.067 37.5389 24.8548Z" fill="#6A7988" stroke="#6A7988" stroke-width="0.3" />
              <defs>
                <filter id="filter0_d_2951_120" x="0" y="0" width="49" height="49" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                  <feOffset />
                  <feGaussianBlur stdDeviation="2" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix type="matrix" values="0 0 0 0 0.468733 0 0 0 0 0.51145 0 0 0 0 0.554167 0 0 0 0.39 0" />
                  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2951_120" />
                  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2951_120" result="shape" />
                </filter>
              </defs>
            </svg>
          </div>
        </div>

        <div className="overview-rice">
          {totalRoi ? `${totalRoi} %` : 'N/A'}
        </div>
      </div>

    </div>
  );
}

export default ReportOverviewTotalRoi;
