import React from 'react';
import moment from 'moment';

const TimestampConverter = (timestamp) => {
    const localTime = moment.utc(timestamp).tz(global.config.getTimeZone());
    const formattedTime = localTime.format('hh:mm A');

    return formattedTime;
};

export default TimestampConverter;
