import React from 'react';
import { useSelector } from 'react-redux';
import { calculateLowestExpense } from './Services/reportExpense';

// Functional component
function LowestExpense({ expenseData }) {
  const reportingFinancialExpense = useSelector((state) => state.reportingExpense);
  const { forex } = useSelector((state) => state.forex);
  const settings = JSON.parse(localStorage.getItem("settings"));

  const setReportingCurrency = useSelector((state) => state.reportingExpense.expCurrency);
  var currencies = "USD";
  if (setReportingCurrency && setReportingCurrency.length === 1) {
    currencies = setReportingCurrency[0];
  } else {
    currencies = settings.currency;
  }


  const { lowestExpense, lowestExpenseCategoryName } = calculateLowestExpense(expenseData, reportingFinancialExpense, currencies, forex);

  return (
    <div>
      <div className="overview-item">
        <div className="overview-title-outer">
          <div className="overview-title">
            Lowest Expenses
            <div className='client-name-report'>{lowestExpenseCategoryName}</div>
          </div>
          <div className="overview-icon">
            <svg width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g filter="url(#filter0_d_2991_17)">
                <circle cx="24.5" cy="24.5" r="20.5" fill="white" />
              </g>
              <path d="M32.9999 19.8224V36.4708C32.9999 36.6825 32.8875 36.859 32.6625 36.9648C32.5874 37 32.5126 37 32.4375 37C32.2877 37 32.1749 36.9648 32.0624 36.859L30.7501 35.7651L29.4374 36.859C29.2125 37.0354 28.9125 37.0354 28.6875 36.859L27.3749 35.7651L26.0623 36.859C25.8373 37.0354 25.5373 37.0354 25.3123 36.859L24 35.7651L22.6873 36.859C22.4624 37.0354 22.1624 37.0354 21.9374 36.859L20.6251 35.7651L19.3124 36.859C19.0875 37.0354 18.7874 37.0354 18.5625 36.859L17.2499 35.7651L15.9372 36.859C15.7873 37 15.5247 37.0354 15.3372 36.9296C15.1125 36.859 15 36.6825 15 36.4708V14.5292C15 14.2469 15.2626 14 15.5624 14H26.8123H26.9248C26.9622 14 26.9622 14 26.9999 14.0352H27.0373C27.0747 14.0352 27.0747 14.0704 27.1124 14.0704H27.1498C27.1872 14.1056 27.2249 14.1056 27.2249 14.141L30.0373 16.7876C31.6497 18.3049 32.3623 18.9754 32.7 19.3284C32.8499 19.399 32.9626 19.5401 33 19.7165C32.9997 19.7517 32.9999 19.7872 32.9999 19.8224ZM27.375 15.7996V19.2932H31.0875C30.0375 18.3051 28.4249 16.7876 27.375 15.7996ZM31.8751 35.3063V20.3517H26.8125C26.5125 20.3517 26.2501 20.1046 26.2501 19.8225V15.0585H16.1252V35.3063L16.8751 34.6712C16.9876 34.5654 17.1001 34.5302 17.2502 34.5302C17.4001 34.5302 17.5128 34.5654 17.6253 34.6712L18.9379 35.7651L20.2506 34.6712C20.4755 34.4947 20.7756 34.4947 21.0005 34.6712L22.3131 35.7651L23.6258 34.6712C23.8507 34.4947 24.1507 34.4947 24.3757 34.6712L25.6883 35.7651L27.001 34.6712C27.2259 34.4947 27.5259 34.4947 27.7509 34.6712L29.0635 35.7651L30.3761 34.6712C30.6011 34.4947 30.9011 34.4947 31.1261 34.6712L31.8751 35.3063ZM29.1809 29.8668H18.8784C18.5783 29.8668 18.316 30.114 18.316 30.3961C18.316 30.6784 18.5786 30.9253 18.8784 30.9253H29.1809C29.481 30.9253 29.7433 30.6781 29.7433 30.3961C29.7433 30.114 29.481 29.8668 29.1809 29.8668Z" fill="#6A7988" />
              <path d="M18.8783 26.8799H29.1809C29.4809 26.8799 29.7433 27.127 29.7433 27.4091C29.7433 27.6912 29.4809 27.9383 29.1809 27.9383H18.8783C18.5785 27.9383 18.3159 27.6915 18.3159 27.4091C18.3159 27.127 18.5783 26.8799 18.8783 26.8799Z" fill="#6A7988" />
              <path d="M18.8783 23.9814H29.1809C29.4809 23.9814 29.7433 24.2285 29.7433 24.5106C29.7433 24.7927 29.4809 25.0398 29.1809 25.0398H18.8783C18.5785 25.0398 18.3159 24.7929 18.3159 24.5106C18.3159 24.2285 18.5783 23.9814 18.8783 23.9814Z" fill="#6A7988" />
              <path d="M30 22C32.7614 22 35 19.7614 35 17C35 14.2386 32.7614 12 30 12C27.2386 12 25 14.2386 25 17C25 19.7614 27.2386 22 30 22Z" fill="white" stroke="#6A7988" />
              <path d="M28.9758 19.5569C29.5445 20.1476 30.4673 20.1478 31.0354 19.5569L33 17.513L32.2203 16.702L30.5467 18.443V14H29.444V18.422L27.7797 16.6914L27 17.5025L28.9758 19.5569Z" fill="#6A7988" />
              <defs>
                <filter id="filter0_d_2991_17" x="0" y="0" width="49" height="49" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                  <feOffset />
                  <feGaussianBlur stdDeviation="2" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix type="matrix" values="0 0 0 0 0.468733 0 0 0 0 0.51145 0 0 0 0 0.554167 0 0 0 0.39 0" />
                  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2991_17" />
                  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2991_17" result="shape" />
                </filter>
              </defs>
            </svg>

          </div>
        </div>

        <div className="overview-rice">
          {new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: currencies,
          }).format(lowestExpense)}
        </div>
      </div>

    </div>
  );
}

export default LowestExpense;
