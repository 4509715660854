import sideBarTextService from "./sideBarTextService";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const sideBarTextLocal = JSON.parse(localStorage.getItem('sideBarTextLocal'));
const initialState = {
  sideBarTextState: sideBarTextLocal ? sideBarTextLocal : "Home",
};

export const setSideBarText = createAsyncThunk(
  "sideBarText/setSideBarText",
  async (sideBarText) => {
    localStorage.setItem('sideBarTextLocal',JSON.stringify(sideBarText))
    return sideBarText;
  }
);

export const sideBarTextSlice = createSlice({
  name: "sideBarText",
  initialState,
  reducers: {
    reset: (state) => {
      state.sideBarTextState = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setSideBarText.fulfilled, (state, action) => {
      state.sideBarTextState = action.payload;
    });
  },
});

export const { reset } = sideBarTextSlice.actions;
export default sideBarTextSlice.reducer;