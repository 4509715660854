import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  clientDetails: null,
  clientAccountManager: null,
  clientStatus: null,
};

export const setReportingClientDetails = createAsyncThunk(
  "reporting/client/details",
  async (clientDetails) => {
    return clientDetails;
  }
);

export const setReportingClientAccountManager = createAsyncThunk(
  "reporting/client/accountManager",
  async (clientAccountManager) => {
    return clientAccountManager;
  }
);

export const setReportingClientStatus = createAsyncThunk(
  "reporting/client/status",
  async (clientStatus) => {
    return clientStatus;
  }
);

export const reportingClientSlice = createSlice({
  name: "reportingClient",
  initialState,
  reducers: {
    reset: (state) => {
      state.clientDetails = null;
      state.clientAccountManager = null;
      state.clientStatus = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setReportingClientDetails.fulfilled, (state, action) => {
      state.clientDetails = action.payload;
    });
    builder.addCase(setReportingClientAccountManager.fulfilled, (state, action) => {
      state.clientAccountManager = action.payload;
    });
    builder.addCase(setReportingClientStatus.fulfilled, (state, action) => {
      state.clientStatus = action.payload;
    });
  },
});

export const { reset } = reportingClientSlice.actions;
export default reportingClientSlice.reducer;
