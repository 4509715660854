const userType = {
  Super_Admin: "6422838e10eb123479ecbe1f",
  Admin: "642283b5f719557301e9bf38",
  User: "642283f4619b062ea04ca997",
  Business: "642283fe619b062ea04ca99a",
  Collaborator: "6422ccd1dbbc21fd93684955",
  Project_Manager:"654ca34afd6d1bdbd1168f41",
  Team_Manager:"65546442163f0fb6ac91ef0e"
};
export default userType;

