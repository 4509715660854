import { useSelector, useDispatch } from "react-redux";
import React, { useEffect, useRef } from 'react';
import { AES, enc } from 'crypto-js';
import { useParams } from "react-router-dom";
import VendorDetails from "../../components/Vendors/VendorOverviewListingSingle/VendorOverviewListingSingleDetails";

import RecentInvoices from '../../components/Vendors/VendorOverviewListingSingle/VendorOverviewListingRecentInvoices';
import { setHeaderText } from "../../features/headerText/headerTextSlice";

export default function Dashboard() {
  const dispatch = useDispatch();
  const { encryptedId } = useParams();
  const decodedId = decodeURIComponent(encryptedId.replace(/%2F/g, '/'));
  const decryptedBytes = AES.decrypt(decodedId, 'encryptionKey');
  const id = decryptedBytes.toString(enc.Utf8);

  const user = useSelector(
    (state) => state.auth.user
  )
  const sum = useSelector(
    (state) => state.invoice.vendorDashboardCurrencySum
  )
  const componentRef = useRef(null);
  useEffect(() => {
    dispatch(setHeaderText("Vendor Overview"));
    componentRef.current.scrollTop = 0;

  }, [dispatch, user]);

  return (
    <div ref={componentRef}>
      <div className="client-inner-details">
        <div className="clint-managment">
          <VendorDetails id={id} />
        </div>
        <div className="client-activity">
        </div>
        <div className='client-recent-invoice'>
          <RecentInvoices id={id} />
        </div>
      </div>
    </div>
  )
}