import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  reimbursmentUser: null,
  reimbursmentInvStatus: null,
  reimbursmentInvPaymentStatus: null,
  reimbursmentDateRange: null,
};

export const setReportingReimbursmentUser = createAsyncThunk(
  "reporting/reimbursment/user",
  async (reimbursmentUser) => {
    return reimbursmentUser;
  }
);

export const setReportingReimbursmentInvStatus = createAsyncThunk(
  "reporting/reimbursment/invStatus",
  async (reimbursmentInvStatus) => {
    return reimbursmentInvStatus;
  }
);

export const setReportingReimbursmentInvPaymentStatus = createAsyncThunk(
  "reporting/reimbursment/invPaymentStatus",
  async (reimbursmentInvPaymentStatus) => {
    return reimbursmentInvPaymentStatus;
  }
);

export const setReportingReimbursmentDateRange = createAsyncThunk(
  "reporting/reimbursment/dateRange",
  async (reimbursmentDateRange) => {
    if (reimbursmentDateRange !== null && Array.isArray(reimbursmentDateRange) && reimbursmentDateRange.length === 2) {
      //   const date = startOfDateRange(new Date(dateRange, 0, 1));
      //   date.setHours(0, 0, 0, 0);
      let [start, end] = reimbursmentDateRange;

      start = new Date(start);
      // start.setDate(start.getDate() + 1);

      end = new Date(end);
      // end.setDate(end.getDate() + 1);

      const startOfDay = new Date(start);
      startOfDay.setHours(0, 0, 0, 0);

      const endOfDay = new Date(end);
      endOfDay.setHours(23, 59, 59, 999);

      const adjustedDateRange = [startOfDay, endOfDay];

      return adjustedDateRange;
    } else {
      return null;
    }
  }
);

export const reportingReimbursmentSlice = createSlice({
  name: "reportingReimbursment",
  initialState,
  reducers: {
    reset: (state) => {
      state.reimbursmentUser = null;
      state.reimbursmentInvStatus = null;
      state.reimbursmentInvPaymentStatus = null;
      state.reimbursmentDateRange = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setReportingReimbursmentUser.fulfilled, (state, action) => {
      state.reimbursmentUser = action.payload;
    });
    builder.addCase(setReportingReimbursmentInvStatus.fulfilled, (state, action) => {
      state.reimbursmentInvStatus = action.payload;
    });
    builder.addCase(setReportingReimbursmentInvPaymentStatus.fulfilled, (state, action) => {
      state.reimbursmentInvPaymentStatus = action.payload;
    });
    builder.addCase(setReportingReimbursmentDateRange.fulfilled, (state, action) => {
      state.reimbursmentDateRange = action.payload;
    });
  },
});

export const { reset } = reportingReimbursmentSlice.actions;
export default reportingReimbursmentSlice.reducer;
