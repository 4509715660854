import React from "react";
import VendorBanks from "../../../components/Vendors/VendorBankAccount/VendorAccount.jsx";

function VendorBankSection()  {
    return(
        <div className="react-outer">
            <VendorBanks />
        </div>
    )
}

export default VendorBankSection;