import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useSendData from "../../../hooks/useFetchData.js";

import { emailPatternVerification } from "../../../helpers/emailPatternCheck.js";
import { sendRegisterEmail } from "../../../../src/services/emailRegister/emailRegisterServices.js";
import { AES, enc } from "crypto-js";
import "../../../../src/pages/home/home.css";
import Logo from "../../../images/logo/to-logo.svg";
import LoginImageMobile from "../../../images/login/lg-img-mob.png";
import LoginImage from "../../../images/login/lg-img.png";

import { GoogleOAuthProvider, GoogleLogin, googleLogout, useGoogleLogin } from '@react-oauth/google';
import { otpVerification, registerUser, checkGoogleUser } from "../../../../src/services/emailRegister/emailRegisterServices.js";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { login, loginTemp } from '../../../features/auth/authSlice';
import { setForex } from "../../../features/forex/forexSlice.js";
import { setAppSettings } from "../../../features/appSettings/appSettingsSlice.js";
import { setDashboardCurrencyRx } from "../../../features/invoice/invoiceSlice";
import axios from "axios";
import moment from "moment";
import { ThreeDots } from "react-loader-spinner";
import Login from "../../auth/Login.jsx";





// google button


const GoogleLoading = ({ size = 'medium' }) => {
        // Size variants
        const sizes = {
          small: {
            wrapper: 'h-4 gap-1',
            dot: 'w-1 h-1',
          },
          medium: {
            wrapper: 'h-6 gap-1.5',
            dot: 'w-1.5 h-1.5',
          },
          large: {
            wrapper: 'h-8 gap-2',
            dot: 'w-2 h-2',
          }
        };
      
        return (
          <div className={`flex items-center ${sizes[size].wrapper}`}>
            <div 
              className={`${sizes[size].dot} rounded-full bg-blue-500 animate-[googleDot_1.4s_infinite]`}
              style={{
                animation: 'googleDot 1.4s infinite',
                animationDelay: '0.2s'
              }}
            />
            <div 
              className={`${sizes[size].dot} rounded-full bg-red-500 animate-[googleDot_1.4s_infinite]`}
              style={{
                animation: 'googleDot 1.4s infinite',
                animationDelay: '0.4s'
              }}
            />
            <div 
              className={`${sizes[size].dot} rounded-full bg-yellow-500 animate-[googleDot_1.4s_infinite]`}
              style={{
                animation: 'googleDot 1.4s infinite',
                animationDelay: '0.6s'
              }}
            />
            <div 
              className={`${sizes[size].dot} rounded-full bg-green-500 animate-[googleDot_1.4s_infinite]`}
              style={{
                animation: 'googleDot 1.4s infinite',
                animationDelay: '0.8s'
              }}
            />
            
            <style jsx>{`
              @keyframes googleDot {
                0% {
                  transform: scale(1);
                  opacity: 0.6;
                }
                20% {
                  transform: scale(1);
                  opacity: 0.6;
                }
                30% {
                  transform: scale(1.2);
                  opacity: 1;
                }
                50% {
                  transform: scale(1);
                  opacity: 0.6;
                }
                100% {
                  transform: scale(1);
                  opacity: 0.6;
                }
              }
            `}</style>
          </div>
        );
      };


const CustomGoogleButton = ({ onSuccess, onError }) => {
        const login = useGoogleLogin({
                onSuccess: credentialResponse => {
                        onSuccess(credentialResponse);
                },
                onError: errorResponse => {
                        onError(errorResponse);
                }
        });

        return (



                <button
                        onClick={() => login()}
                        className="google-btn"
                >
                        {/* Google Logo */}
                        <svg
                                className="w-5 h-5"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                        >
                                <path
                                        d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
                                        fill="#4285F4"
                                />
                                <path
                                        d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
                                        fill="#34A853"
                                />
                                <path
                                        d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
                                        fill="#FBBC05"
                                />
                                <path
                                        d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
                                        fill="#EA4335"
                                />
                        </svg>

                        <span  >
                                Continue with Google
                        </span>
                </button>
        );
};


// end google button

const EmailLogin = () => {
        const [email, setEmail] = useState(null);
        const [formData, setFormData] = useState({ email: "" });
        const [emailSent, setEmailSent] = useState(false);

        const [loginMethod, setLoginMethod] = useState();
        const GOOGLE = 1;

        const [otpDig1, setOtpDig1] = useState(null);
        const [otpDig2, setOtpDig2] = useState(null);
        const [otpDig3, setOtpDig3] = useState(null);
        const [otpDig4, setOtpDig4] = useState(null);
        const navigate = useNavigate();
        console.log(global.config.getSubdomain());
        const emailSubmitHandler = async (e) => {
                if (emailPatternVerification(formData.email)) {
                        const emailResponse = await sendRegisterEmail(
                                formData.email,
                                global.config.getSubdomain()
                        );
                        console.log(emailResponse);
                        if (emailResponse.status === "success") {
                                navigate(
                                        `/app/otp/verification/${encodeURIComponent(
                                                AES.encrypt(formData.email, "encryptionKey")
                                                        .toString()
                                                        .replace(/\//g, "%2F")
                                        )}`
                                );
                                setEmailSent(true);
                        } else {

                                toast.error(emailResponse.message, {
                                        position: "bottom-right",
                                        autoClose: 2500,
                                });
                        }
                } else {
                        toast.error("Please enter a valid email.", {
                                position: "bottom-right",
                                autoClose: 2500,
                        });
                }
        };

        const validateOtp = async (e) => {
                if (otpDig1 && otpDig2 && otpDig3 && otpDig4) {
                }
        };

        // Login section

        const [isLoggedIn, setIsLoggedIn] = useState(false);
        const dispatch = useDispatch();

        const SUPER_ADMIN = 1;
        const ADMIN = 2;
        const USER = 3;
        // BUSINESS: 4,
        const COLLABERATOR = 5;
        const PROJECT_MANAGER = 6;
        const TEAM_MANAGER = 7;


        const domainName = window.location.hostname;
        const currentPort = window.location.port;

        const isCompanyEmail = (email) => {
                // List of common public email domains
                const publicDomains = [
                        "gmail.com",
                        "yahoo.com",
                        "outlook.com",
                        "hotmail.com",
                        "aol.com",
                        "icloud.com",
                        "protonmail.com",
                        "zoho.com",
                        "yandex.com"
                ];

                // Extract the domain part of the email
                const emailDomain = email.split('@')[1].toLowerCase();

                // Check if the email domain is NOT in the public domains list
                return !publicDomains.includes(emailDomain);
        };

        const fetchForex = async () => {

                var settingsData = await axios.post(`api/forex/get`, { base_currency: "USD" }, {
                        headers: {

                        }
                });
                await dispatch(setForex(settingsData.data.json));
                return settingsData.data.json;

        }

        const [googleSuccess, setGoogleSuccess] = useState(false);
        const handleLoginSuccess = async (response) => {
                // console.log("Login Success:", response);

                localStorage.setItem('googleToken', response.credential);


                try {

                        const googleUser = await checkGoogleUser(response.credential);

                        let companyName = null;

                        console.log('googleUser', googleUser);

                        if (googleUser) {

                                setGoogleSuccess(true);

                                if (isCompanyEmail(googleUser.email)) {
                                        companyName = googleUser.hd;
                                } else {

                                        companyName = googleUser.name;
                                }
                                const result = await registerUser(googleUser.email, companyName, googleUser.jti, companyName, true);
                                console.log(result);

                                if (result.status === "success") {

                                        if (result.data.subDomain) {

                                                if (currentPort === "3000") {
                                                        window.location.href = `http://${result.data.subDomain}.${domainName}:3000/app/google/auth/${googleUser.jti}`;
                                                } else {
                                                        window.location.href = `https://${result.data.subDomain}.${domainName}/app/google/auth/${googleUser.jti}`;
                                                }


                                                // Login part


                                                try {

                                                        const res = await fetch(`https://${result.data.subDomain}.${domainName}/api/user/google/login`, {
                                                                method: "POST",
                                                                headers: {
                                                                        "Content-Type": "application/json",
                                                                },
                                                                body: JSON.stringify({ email: googleUser.email, password: googleUser.jti }),
                                                        });
                                                        const data = await res.json();

                                                        if (data.token) {

                                                                await dispatch(loginTemp(data));
                                                                fetchForex();
                                                                if (data.userType === 'x' || data.userType === 'Y') {

                                                                        var settingsData = await axios.get(`https://${result.data.subDomain}.${domainName}/api/get/app-settings/${data.appId}`, {
                                                                                headers: {
                                                                                        Authorization: `Bearer ${data.token}`,
                                                                                }
                                                                        });

                                                                        var favIconData = await axios.get(`https://${result.data.subDomain}.${domainName}/api/view/owner/by/appid/${data.appId}`, {
                                                                                headers: {
                                                                                        Authorization: `Bearer ${data.token}`,
                                                                                },
                                                                        });

                                                                        let favIcon_fileName;
                                                                        let app_owner_name;
                                                                        let app_owner_id;
                                                                        if (favIconData && favIconData.data && favIconData.data.fav_icon) {
                                                                                favIcon_fileName = favIconData.data.fav_icon;
                                                                                app_owner_name = favIconData.data.commercial_name;
                                                                                app_owner_id = favIconData.data._id;
                                                                        } else {
                                                                                favIcon_fileName = "fav_icon_default.jpeg";
                                                                                app_owner_name = "TalenTon";
                                                                                app_owner_id = favIconData.data._id;
                                                                        }

                                                                        // await dispatch(setAppSettings(settingsData.data));
                                                                        await dispatch(setAppSettings({ ...settingsData.data, favIcon: favIcon_fileName, app_ownername: app_owner_name, app_ownerid: app_owner_id }));
                                                                } else {

                                                                        var userSettingsData = await axios.get(`https://${result.data.subDomain}.${domainName}/api/get/user-settings/${data._id}`, {
                                                                                headers: {
                                                                                        Authorization: `Bearer ${data.token}`,
                                                                                }
                                                                        });

                                                                        // await dispatch(setAppSettings(settingsData2.data));
                                                                        var favIconData = await axios.get(`https://${result.data.subDomain}.${domainName}/api/view/owner/by/appid/${data.appId}`, {
                                                                                headers: {
                                                                                        Authorization: `Bearer ${data.token}`,
                                                                                },
                                                                        });
                                                                        let favIcon_fileName;
                                                                        let app_owner_name;
                                                                        let app_owner_id;
                                                                        if (favIconData && favIconData.data && favIconData.data.fav_icon) {
                                                                                favIcon_fileName = favIconData.data.fav_icon;
                                                                                app_owner_name = favIconData.data.commercial_name;
                                                                                app_owner_id = favIconData.data._id;
                                                                        } else {
                                                                                favIcon_fileName = "fav_icon_default.jpeg";
                                                                                app_owner_name = "TalenTon";
                                                                                app_owner_id = favIconData.data._id;
                                                                        }

                                                                        var settingsData = await axios.get(`https://${result.data.subDomain}.${domainName}/api/get/app-settings/${data.appId}`, {
                                                                                headers: {
                                                                                        Authorization: `Bearer ${data.token}`,
                                                                                }
                                                                        });
                                                                        // settingsData.data.timeFormat = userSettingsData?.data?.timeFormat ? userSettingsData?.data?.timeFormat : 12;
                                                                        if (settingsData.data !== null && (data.userType !== SUPER_ADMIN && data.userType !== ADMIN)) {
                                                                                settingsData.data.currency = userSettingsData?.data?.currency ? userSettingsData?.data?.currency : "USD";
                                                                                settingsData.data.timeZone = userSettingsData?.data?.timeZone ? userSettingsData?.data?.timeZone : null;
                                                                                settingsData.data.timeFormat = userSettingsData?.data?.timeFormat ? userSettingsData?.data?.timeFormat : 12;
                                                                        }
                                                                        // await dispatch(setAppSettings(settingsData.data));
                                                                        await dispatch(setAppSettings({ ...settingsData.data, favIcon: favIcon_fileName, app_ownername: app_owner_name, app_ownerid: app_owner_id }));
                                                                }

                                                                const response = await axios.get(`https://${result.data.subDomain}.${domainName}/api/dashboard/invoice-currency`, {
                                                                        headers: {
                                                                                Authorization: `Bearer ${data.token}`,
                                                                        }
                                                                });

                                                                if (response.data.length > 0) {
                                                                        localStorage.setItem('DashboardCurrency', JSON.stringify(response.data[0]));
                                                                        dispatch(setDashboardCurrencyRx(response.data[0]));
                                                                }
                                                                if (settingsData && settingsData.data?.twoFa === true) {
                                                                        var twoFactorEnabled = true;
                                                                        var twoFactorEnabledAt = moment.tz(global.config.getTimeZone()).format();
                                                                } else {
                                                                        var twoFactorEnabled = localStorage.getItem('twoFactorEnabled');
                                                                        var twoFactorEnabledAt = localStorage.getItem('twoFactorEnabledAt');
                                                                }

                                                                if (twoFactorEnabled && twoFactorEnabledAt) {
                                                                        if (twoFactorEnabled && Date.now() - twoFactorEnabledAt < 30 * 24 * 60 * 60 * 1000) {
                                                                                // 2FA is enabled and it's within the 30-day timeframe
                                                                                dispatch(login(data));

                                                                                window.location.href = `https://${result.data.subDomain}.${domainName}/user/dashboard`;
                                                                                // navigate('/user/dashboard');
                                                                        } else {
                                                                                // Redirect user to enable 2FA
                                                                                // navigate("/2fa");
                                                                                window.location.href = `https://${result.data.subDomain}.${domainName}/2fa`;
                                                                        }
                                                                } else {
                                                                        dispatch(login(data));
                                                                        // navigate('/user/dashboard');
                                                                        window.location.href = `https://${result.data.subDomain}.${domainName}/user/dashboard`;
                                                                }
                                                        } else {
                                                                // setErrorMessage(data);
                                                                toast.error(data.message, {
                                                                        position: "bottom-right",
                                                                        autoClose: 1500,
                                                                        hideProgressBar: true,
                                                                        closeOnClick: true,
                                                                        pauseOnHover: true,
                                                                        draggable: true,
                                                                        progress: undefined,
                                                                        theme: "light",
                                                                });
                                                        }
                                                        // setLoading(false);
                                                } catch (err) {
                                                        console.error(err.message);
                                                        global.config.slackMessage(err.toString());
                                                        toast.error(err.message, {
                                                                position: "bottom-right",
                                                                autoClose: 1500,
                                                                hideProgressBar: true,
                                                                closeOnClick: true,
                                                                pauseOnHover: true,
                                                                draggable: true,
                                                                progress: undefined,
                                                                theme: "light",
                                                        });
                                                        // setLoading(false);
                                                }


                                                // Login part end


                                        }
                                        toast.success(result.data.message || "App owner registered successfully.", {
                                                position: "bottom-right",
                                                autoClose: 2000,
                                                hideProgressBar: false,
                                                closeOnClick: true,
                                                pauseOnHover: true,
                                                draggable: true,
                                                progress: undefined,
                                        });
                                } else if (result.status === "fail") {
                                        setGoogleSuccess(false);
                                        handleLogout();
                                        toast.error(result.message || "Something went wrong.", {
                                                position: "bottom-right",
                                                autoClose: 1500,
                                                hideProgressBar: false,
                                                closeOnClick: true,
                                                pauseOnHover: true,
                                                draggable: true,
                                                progress: undefined,
                                                theme: "light",
                                        });
                                } else if (result.status === "failed") {
                                        setGoogleSuccess(false);
                                        handleLogout();
                                        toast.error(result.message || "Company name or email already exists.", {
                                                position: "bottom-right",
                                                autoClose: 1500,
                                                hideProgressBar: false,
                                                closeOnClick: true,
                                                pauseOnHover: true,
                                                draggable: true,
                                                progress: undefined,
                                                theme: "light",
                                        });
                                }

                        }
                } catch (error) {
                        console.error("Error:", error);
                } finally {

                }



                setIsLoggedIn(true);
        };

        const handleLoginFailure = (error) => {
                console.log("Login Failed:", error);
        };

        // Check for stored token on component mount
        useEffect(() => {
                const token = localStorage.getItem('googleToken');
                if (token) {
                        setIsLoggedIn(true);
                }
        }, []);

        const handleLogout = () => {
                googleLogout(); // Logs out from Google session
                localStorage.removeItem('googleToken');
                setIsLoggedIn(false);
        };


        // end login section


        const [isLoading, setIsLoading] = React.useState(false);

        const getGooglePrifileFromToken = async (googleToken) => {

                try {

                        var googleProfile = await axios.get(`https://www.googleapis.com/oauth2/v3/userinfo`, {
                                headers: {
                                        Authorization: `Bearer ${googleToken}`,
                                }
                        });

                        return googleProfile.data;

                } catch (error) {

                        console.log(error);
                }


        }
        const handleSuccess = async (credentialResponse) => {
                setIsLoading(true);
                try {
                        console.log('Login Success:', credentialResponse);
                        localStorage.setItem('googleToken', credentialResponse);
                        const googleProfile = await getGooglePrifileFromToken(credentialResponse.access_token);

                        // login part

                        localStorage.setItem('googleToken', credentialResponse.access_token);

                        try {
        
                                let companyName = null;
        
                                if (credentialResponse.access_token) {
        
                                        setGoogleSuccess(true);
        
                                        // if (isCompanyEmail(googleUser.email)) {
                                        //         companyName = googleUser.hd;
                                        // } else {
        
                                        //         companyName = googleUser.name;
                                        // }
                                        const result = await registerUser(googleProfile.email, googleProfile.email.split("@")[0], credentialResponse.access_token, googleProfile.email.split("@")[0], true);
                                        console.log('result',result);
        
                                        if (result.status === "success") {
        
                                                if (result.data.subDomain) {
        
                                                        if (currentPort === "3000") {
                                                                window.location.href = `http://${result.data.subDomain}.${domainName}:3000/app/google/auth/${credentialResponse.access_token}`;
                                                        } else {
                                                                window.location.href = `https://${result.data.subDomain}.${domainName}/app/google/auth/${credentialResponse.access_token}`;
                                                        }
        
        
                                                        // Login part
        
        
                                                        try {
        
                                                                const res = await fetch(`https://${result.data.subDomain}.${domainName}/api/user/google/login`, {
                                                                        method: "POST",
                                                                        headers: {
                                                                                "Content-Type": "application/json",
                                                                        },
                                                                        body: JSON.stringify({ email: googleProfile.email, password: credentialResponse.access_token }),
                                                                });
                                                                const data = await res.json();
        
                                                                if (data.token) {
        
                                                                        await dispatch(loginTemp(data));
                                                                        fetchForex();
                                                                        if (data.userType === 'x' || data.userType === 'Y') {
        
                                                                                var settingsData = await axios.get(`https://${result.data.subDomain}.${domainName}/api/get/app-settings/${data.appId}`, {
                                                                                        headers: {
                                                                                                Authorization: `Bearer ${data.token}`,
                                                                                        }
                                                                                });
        
                                                                                var favIconData = await axios.get(`https://${result.data.subDomain}.${domainName}/api/view/owner/by/appid/${data.appId}`, {
                                                                                        headers: {
                                                                                                Authorization: `Bearer ${data.token}`,
                                                                                        },
                                                                                });
        
                                                                                let favIcon_fileName;
                                                                                let app_owner_name;
                                                                                let app_owner_id;
                                                                                if (favIconData && favIconData.data && favIconData.data.fav_icon) {
                                                                                        favIcon_fileName = favIconData.data.fav_icon;
                                                                                        app_owner_name = favIconData.data.commercial_name;
                                                                                        app_owner_id = favIconData.data._id;
                                                                                } else {
                                                                                        favIcon_fileName = "fav_icon_default.jpeg";
                                                                                        app_owner_name = "TalenTon";
                                                                                        app_owner_id = favIconData.data._id;
                                                                                }
        
                                                                                // await dispatch(setAppSettings(settingsData.data));
                                                                                await dispatch(setAppSettings({ ...settingsData.data, favIcon: favIcon_fileName, app_ownername: app_owner_name, app_ownerid: app_owner_id }));
                                                                        } else {
        
                                                                                var userSettingsData = await axios.get(`https://${result.data.subDomain}.${domainName}/api/get/user-settings/${data._id}`, {
                                                                                        headers: {
                                                                                                Authorization: `Bearer ${data.token}`,
                                                                                        }
                                                                                });
        
                                                                                // await dispatch(setAppSettings(settingsData2.data));
                                                                                var favIconData = await axios.get(`https://${result.data.subDomain}.${domainName}/api/view/owner/by/appid/${data.appId}`, {
                                                                                        headers: {
                                                                                                Authorization: `Bearer ${data.token}`,
                                                                                        },
                                                                                });
                                                                                let favIcon_fileName;
                                                                                let app_owner_name;
                                                                                let app_owner_id;
                                                                                if (favIconData && favIconData.data && favIconData.data.fav_icon) {
                                                                                        favIcon_fileName = favIconData.data.fav_icon;
                                                                                        app_owner_name = favIconData.data.commercial_name;
                                                                                        app_owner_id = favIconData.data._id;
                                                                                } else {
                                                                                        favIcon_fileName = "fav_icon_default.jpeg";
                                                                                        app_owner_name = "TalenTon";
                                                                                        app_owner_id = favIconData.data._id;
                                                                                }
        
                                                                                var settingsData = await axios.get(`https://${result.data.subDomain}.${domainName}/api/get/app-settings/${data.appId}`, {
                                                                                        headers: {
                                                                                                Authorization: `Bearer ${data.token}`,
                                                                                        }
                                                                                });
                                                                                // settingsData.data.timeFormat = userSettingsData?.data?.timeFormat ? userSettingsData?.data?.timeFormat : 12;
                                                                                if (settingsData.data !== null && (data.userType !== SUPER_ADMIN && data.userType !== ADMIN)) {
                                                                                        settingsData.data.currency = userSettingsData?.data?.currency ? userSettingsData?.data?.currency : "USD";
                                                                                        settingsData.data.timeZone = userSettingsData?.data?.timeZone ? userSettingsData?.data?.timeZone : null;
                                                                                        settingsData.data.timeFormat = userSettingsData?.data?.timeFormat ? userSettingsData?.data?.timeFormat : 12;
                                                                                }
                                                                                // await dispatch(setAppSettings(settingsData.data));
                                                                                await dispatch(setAppSettings({ ...settingsData.data, favIcon: favIcon_fileName, app_ownername: app_owner_name, app_ownerid: app_owner_id }));
                                                                        }
        
                                                                        const response = await axios.get(`https://${result.data.subDomain}.${domainName}/api/dashboard/invoice-currency`, {
                                                                                headers: {
                                                                                        Authorization: `Bearer ${data.token}`,
                                                                                }
                                                                        });
        
                                                                        if (response.data.length > 0) {
                                                                                localStorage.setItem('DashboardCurrency', JSON.stringify(response.data[0]));
                                                                                dispatch(setDashboardCurrencyRx(response.data[0]));
                                                                        }
                                                                        if (settingsData && settingsData.data?.twoFa === true) {
                                                                                var twoFactorEnabled = true;
                                                                                var twoFactorEnabledAt = moment.tz(global.config.getTimeZone()).format();
                                                                        } else {
                                                                                var twoFactorEnabled = localStorage.getItem('twoFactorEnabled');
                                                                                var twoFactorEnabledAt = localStorage.getItem('twoFactorEnabledAt');
                                                                        }
        
                                                                        if (twoFactorEnabled && twoFactorEnabledAt) {
                                                                                if (twoFactorEnabled && Date.now() - twoFactorEnabledAt < 30 * 24 * 60 * 60 * 1000) {
                                                                                        // 2FA is enabled and it's within the 30-day timeframe
                                                                                        dispatch(login(data));
        
                                                                                        window.location.href = `https://${result.data.subDomain}.${domainName}/user/dashboard`;
                                                                                        // navigate('/user/dashboard');
                                                                                } else {
                                                                                        // Redirect user to enable 2FA
                                                                                        // navigate("/2fa");
                                                                                        window.location.href = `https://${result.data.subDomain}.${domainName}/2fa`;
                                                                                }
                                                                        } else {
                                                                                dispatch(login(data));
                                                                                // navigate('/user/dashboard');
                                                                                window.location.href = `https://${result.data.subDomain}.${domainName}/user/dashboard`;
                                                                        }
                                                                } else {
                                                                        // setErrorMessage(data);
                                                                        toast.error(data.message, {
                                                                                position: "bottom-right",
                                                                                autoClose: 1500,
                                                                                hideProgressBar: true,
                                                                                closeOnClick: true,
                                                                                pauseOnHover: true,
                                                                                draggable: true,
                                                                                progress: undefined,
                                                                                theme: "light",
                                                                        });
                                                                }
                                                                // setLoading(false);
                                                        } catch (err) {
                                                                console.error(err.message);
                                                                global.config.slackMessage(err.toString());
                                                                toast.error(err.message, {
                                                                        position: "bottom-right",
                                                                        autoClose: 1500,
                                                                        hideProgressBar: true,
                                                                        closeOnClick: true,
                                                                        pauseOnHover: true,
                                                                        draggable: true,
                                                                        progress: undefined,
                                                                        theme: "light",
                                                                });
                                                                // setLoading(false);
                                                        }
        
        
                                                        // Login part end
        
        
                                                }
                                                toast.success(result.data.message || "App owner registered successfully.", {
                                                        position: "bottom-right",
                                                        autoClose: 3000,
                                                        hideProgressBar: false,
                                                        closeOnClick: true,
                                                        pauseOnHover: true,
                                                        draggable: true,
                                                        progress: undefined,
                                                });
                                        } else if (result.status === "fail") {
                                                setGoogleSuccess(false);
                                                handleLogout();
                                                toast.error(result.message || "Something went wrong.", {
                                                        position: "bottom-right",
                                                        autoClose: 2500,
                                                        hideProgressBar: false,
                                                        closeOnClick: true,
                                                        pauseOnHover: true,
                                                        draggable: true,
                                                        progress: undefined,
                                                        theme: "light",
                                                });
                                        } else if (result.status === "failed") {
                                                setGoogleSuccess(false);
                                                handleLogout();
                                                toast.error(result.message || "Company name or email already exists.", {
                                                        position: "bottom-right",
                                                        autoClose: 2500,
                                                        hideProgressBar: false,
                                                        closeOnClick: true,
                                                        pauseOnHover: true,
                                                        draggable: true,
                                                        progress: undefined,
                                                        theme: "light",
                                                });
                                        }
        
                                }
                        } catch (error) {
                                console.error("Error:", error);
                        } finally {
        
                        }
        
        
        
                        setIsLoggedIn(true);



                        // login part end




                } catch (error) {
                        console.error('Error processing login:', error);
                } finally {
                        setIsLoading(false);
                }
        };

        const handleError = () => {
                console.error('Login Failed');
        };



        return (


                domainName === "app.talenton.io" || domainName === "talenton.app" || domainName === "localhost" ?
                        (<>
                                <div className="top-logo-mobile">
                                <a href="/"><img src={Logo} alt="Talent On" /></a>
                                </div>
                                <div className="home-login-inner">
                                        <div className="lg-lft">
                                                <img src={LoginImage} alt="desktop" className="desktop-lg-img" />
                                                <img src={LoginImageMobile} alt="mobile" className="mobile-lg-img" />
                                        </div>
                                        <div className="login-container">
                                                <div className="top-logo">
                                                <a href="/"><img src={Logo} alt="Talent On" /></a>
                                                </div>

                                                {googleSuccess === true ?

                                                        <>  
                                                        <ThreeDots
                                                        height="38"
                                                        width="100"
                                                        radius="9"
                                                        color="blue"
                                                        ariaLabel="three-dots-loading"
                                                        wrapperStyle={{}}
                                                        wrapperClassName=""
                                                        visible={true}
                                                /></>
                                                        :
                                                        (
                                                                <>
                                                                        <GoogleOAuthProvider clientId="150723980220-pd771tque2fo4nao94qeid76fc1guuhq.apps.googleusercontent.com">
                                                                                <div className="login-header">Hi! Please enter your details</div>
                                                                                <div className="g-outer">

                                                                                        {/* <GoogleLogin
                                                                                                onSuccess={handleLoginSuccess}
                                                                                                onError={handleLoginFailure}
                                                                                                render={(renderProps) => (
                                                                                                        <button
                                                                                                                onClick={() => {
                                                                                                                        setLoginMethod("GOOGLE");
                                                                                                                        renderProps.onClick();
                                                                                                                }}
                                                                                                                disabled={renderProps.disabled}
                                                                                                                className="flex items-center justify-center gap-3 w-full px-6 py-3 rounded-lg transition-all duration-200 
                                                                                                          bg-white border-2 border-gray-200 hover:bg-gray-50 hover:border-gray-300 
                                                                                                          disabled:opacity-70 disabled:cursor-not-allowed"
                                                                                                        >
                                                                                                               
                                                                                                                <svg
                                                                                                                        className="w-5 h-5"
                                                                                                                        viewBox="0 0 24 24"
                                                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                                                >
                                                                                                                        <path
                                                                                                                                d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
                                                                                                                                fill="#4285F4"
                                                                                                                        />
                                                                                                                        <path
                                                                                                                                d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
                                                                                                                                fill="#34A853"
                                                                                                                        />
                                                                                                                        <path
                                                                                                                                d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
                                                                                                                                fill="#FBBC05"
                                                                                                                        />
                                                                                                                        <path
                                                                                                                                d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
                                                                                                                                fill="#EA4335"
                                                                                                                        />
                                                                                                                </svg>

                                                                                                               
                                                                                                                <span className="text-gray-600 font-medium text-base">
                                                                                                                        Continue with Google
                                                                                                                </span>
                                                                                                        </button>
                                                                                                )}
                                                                                        /> */}

                                                                                        <CustomGoogleButton
                                                                                                onSuccess={handleSuccess}
                                                                                                onError={handleError}
                                                                                        />


                                                                                        {/* <div className="g-btn-outer">
                                                                                                <button
                                                                                                        className="google-btn"
                                                                                                // onClick={() => setLoginMethod(GOOGLE)}
                                                                                                >
                                                                                                        <span className="google-icon" /> Continue with Google
                                                                                                </button>
                                                                                        </div> */}
                                                                                </div>
                                                                                {!emailSent ? (
                                                                                        <>
                                                                                                {" "}
                                                                                                <div className="divider">
                                                                                                        Or sign up with your email address
                                                                                                </div>
                                                                                                <input
                                                                                                        className="email-input-btn"
                                                                                                        type="email"
                                                                                                        placeholder="name@company.com"
                                                                                                        onChange={(e) =>
                                                                                                                setFormData({ ...formData, email: e.target.value })
                                                                                                        }
                                                                                                />
                                                                                                <div className="submit-button">
                                                                                                        <button type="submit" onClick={() => emailSubmitHandler()}>
                                                                                                                Continue
                                                                                                        </button>
                                                                                                </div>
                                                                                        </>
                                                                                ) : (
                                                                                        <>
                                                                                                1
                                                                                                <input
                                                                                                        type="number"
                                                                                                        onChange={(e) => setOtpDig1(e.target.value)}
                                                                                                />
                                                                                                2
                                                                                                <input
                                                                                                        type="number"
                                                                                                        onChange={(e) => setOtpDig2(e.target.value)}
                                                                                                />
                                                                                                3
                                                                                                <input
                                                                                                        type="number"
                                                                                                        onChange={(e) => setOtpDig3(e.target.value)}
                                                                                                />
                                                                                                4
                                                                                                <input
                                                                                                        type="number"
                                                                                                        onChange={(e) => setOtpDig4(e.target.value)}
                                                                                                />
                                                                                                <button onClick={validateOtp}>Submit</button>
                                                                                        </>
                                                                                )}
                                                                        </GoogleOAuthProvider>

                                                                        <div className="have-account">Already have an account?
                                                                                <a href="/">Log in</a>
                                                                        </div>
                                                                </>

                                                        )}



                                        </div>
                                </div>
                        </>)

                        :

                        (

                                <>   <Login /> </>
                        )

        );
};

export default EmailLogin;