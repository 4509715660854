import axios from 'axios';
import { useQuery, useMutation } from 'react-query';

const fetchDeactivatedProfiles = async (token) => {
  const response = await axios.get('/api/bank/profile/deactive-list', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  const inactiveProfiles = response.data.map((item) => parseInt(item.profileId));
  return inactiveProfiles;
};

const deactivateBankProfile = async (profileId, token) => {
  await axios.get(`/api/bank/profile/deactivate/${profileId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const activateBankProfile = async (profileId, token) => {
  await axios.get(`/api/bank/profile/activate/${profileId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const useDeactivatedProfiles = (token) => {
  return useQuery('deactivatedProfiles', () => fetchDeactivatedProfiles(token));
};

export const useDeactivateBankProfile = (token) => {
  return useMutation((profileId) => deactivateBankProfile(profileId, token));
};

export const useActivateBankProfile = (token) => {
  return useMutation((profileId) => activateBankProfile(profileId, token));
};

export const useAllProfile = (token, appId) => {
  return useQuery('useAllProfile', async () => {

    try {
      const response = await axios.post('/api/wise/all-wise-profile', { appId }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;

    } catch (e) {

      console.log(e);

    }


  });



}