import React, { useEffect, useState } from "react";
import "./CompanyDetailsSidebar.css";
import { Link } from "react-router-dom";
import logo from "../../../images/logo/to-logo.svg";
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

function CompanyDetailsSidebar({
  activeIndex,
  handleItemClick,
  submittedIndices = [],
}) {




  const uType = useParams();


  const page1 = useSelector((state) => state.onBoard.page1);
  const page2 = useSelector((state) => state.onBoard.page2);
  const page3 = useSelector((state) => state.onBoard.page3);
  const page4 = useSelector((state) => state.onBoard.page4);

  const page = useSelector((state) => state.onBoard.page);
  const pageB = useSelector((state) => state.onBoard.pageB);
  const pageV = useSelector((state) => state.onBoard.pageV);




  return (
    <div className="onboarding-sidebar-outer">
      <div className="onboard-logo">
        <Link to="dashboard">
          <img src={logo} alt="Logo" />
        </Link>
      </div>

      <div className="sidebar-contents">


        {uType.usertype === "personal" && (
          <>
            <div
              key="1"
              className={`sidebar-contents-inner ${(page1 === 0) ? "active" : ""
                } ${page1 === 1 ? "activated" : ""}`}
            >
              <div className="sidebar-single-point">Your personal details</div>
              <div className="sidebar-single-point-content">
                Please provide your profile details.
              </div>
            </div>

            <div
              key="2"
              className={`sidebar-contents-inner ${(page === 2) ? "active" : ""
                } ${(page2 === 1) ? "activated" : ""}`}
            >
              <div className="sidebar-single-point">Account information</div>
              <div className="sidebar-single-point-content">
                Please provide your account details.
              </div>
            </div>

            <div
              key="3"
              className={`sidebar-contents-inner ${(page === 3) ? "active" : ""
                } ${page3 === 1 ? "activated" : ""}`}
            >
              <div className="sidebar-single-point">Security</div>
              <div className="sidebar-single-point-content">
                Select your security preferences
              </div>
            </div>
          </>
        )}



        {uType.usertype === "business" && (
          <>
            <div
              key="1"
              className={`sidebar-contents-inner ${(page1 === 0) ? "active" : ""
                } ${page1 === 1 ? "activated" : ""}`}
            >
              <div className="sidebar-single-point">Company details</div>
              <div className="sidebar-single-point-content">
                Please provide your company details.
              </div>
            </div>

            <div
              key="2"
              className={`sidebar-contents-inner ${(pageB === 2) ? "active" : ""
                } ${(page2 === 1) ? "activated" : ""}`}
            >
              <div className="sidebar-single-point">Company account information</div>
              <div className="sidebar-single-point-content">
                Please provide your company account details.
              </div>
            </div>

            <div
              key="3"
              className={`sidebar-contents-inner ${(pageB === 3) ? "active" : ""
                } ${(page3 === 1) ? "activated" : ""}`}
            >
              <div className="sidebar-single-point">Your personal details</div>
              <div className="sidebar-single-point-content">
                Please provide your profile details.
              </div>
            </div>

            <div
              key="4"
              className={`sidebar-contents-inner ${(pageB === 4) ? "active" : ""
                } ${page4 === 1 ? "activated" : ""}`}
            >
              <div className="sidebar-single-point">Security</div>
              <div className="sidebar-single-point-content">
                Select your security preferences
              </div>
            </div>
          </>
        )}


        {uType.usertype === "vendor" && (
          <>
            <div
              key="1"
              className={`sidebar-contents-inner ${(page1 === 0) ? "active" : ""
                } ${page1 === 1 ? "activated" : ""}`}
            >
              <div className="sidebar-single-point">Company details</div>
              <div className="sidebar-single-point-content">
                Please provide your company details.
              </div>
            </div>

            <div
              key="2"
              className={`sidebar-contents-inner ${(pageV === 2) ? "active" : ""
                } ${(page2 === 1) ? "activated" : ""}`}
            >
              <div className="sidebar-single-point">Company account information</div>
              <div className="sidebar-single-point-content">
                Please provide your company account details.
              </div>
            </div>
            <div
              key="3"
              className={`sidebar-contents-inner ${(pageV === 3) ? "active" : ""
                } ${page3 === 1 ? "activated" : ""}`}
            >
              <div className="sidebar-single-point">Security</div>
              <div className="sidebar-single-point-content">
                Select your security preferences
              </div>
            </div>
          </>
        )}


      </div>
    </div>
  );
}

export default CompanyDetailsSidebar;
