import axios from 'axios';
import { useQuery } from 'react-query';

export function useMasterTenantView(encodedSubDomain, token, profileEdit) {
  return useQuery(
    ['masterTenantView', encodedSubDomain],
    async () => {
      const response = await axios.post(
        `/api/master/tenant/by/appid/`,
        { encodedSubDomain },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    },
    {
      enabled: !profileEdit,
    }
  );
}
