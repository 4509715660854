import React, { PureComponent, useMemo } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { ThreeDots } from "react-loader-spinner";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ReferenceLine, ResponsiveContainer, LabelList } from 'recharts';
import { processedTimeOffRequestByUser } from './Services/reportTimeOffRequest';

const data = [
  { name: 'Martijn van Dooren', requestCount: 44 },
  { name: 'Martijn van Dooren', requestCount: 55 },
  { name: 'Martijn van Dooren', requestCount: 13 },
  { name: 'Martijn van Dooren', requestCount: 43 },
  { name: 'Martijn van Dooren', requestCount: 46 },
  { name: 'Martijn van Dooren', requestCount: 55 },
  { name: 'Martijn van Dooren', requestCount: 32 },
];

const TimeOffRequestPerUserGraph = ({ timeoffReq, timeoffReqLoading, timeoffReqError }) => {
  const reportingTimeoffRequest = useSelector((state) => state.reportingTimeoffRequest);

  const processedData = useMemo(() => {
    return processedTimeOffRequestByUser(
      timeoffReq,
      reportingTimeoffRequest,
    );
  }, [timeoffReq, reportingTimeoffRequest]);

  if (timeoffReqLoading) {
    return (
      <div className="outter-load-table">
        <ThreeDots
          height="80"
          width="80"
          radius="9"
          color="#6479f9"
          ariaLabel="three-dots-loading"
          visible={true}
        />
      </div>
    );
  }

  if (timeoffReqError) {
    return <div>Error loading data.</div>;
  }

  const calculateXTicks = () => {
    const dataValues = processedData.map(item => item.requestCount);
    const maxDataValue = Math.max(...dataValues);
    const minDataValue = Math.min(...dataValues);
    const tickInterval = Math.ceil(maxDataValue / 10);
    const ticks = [];

    for (let i = 0; i <= maxDataValue; i += tickInterval) {
      ticks.push(i);
    }

    return ticks;
  };

  const xAxisTicks = calculateXTicks();

  const calculateChartHeight = (dataLength) => {
    const rowHeight = 50;
    const baseHeight = 100;
    return dataLength * rowHeight + baseHeight;
  };

  const calculateLeftMargin = (data) => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    context.font = '12px Arial';

    const longestWordLength = data.reduce((maxLength, item) => {
      const firstWord = item.name.split(' ')[0];
      const wordWidth = context.measureText(firstWord).width;
      return Math.max(maxLength, wordWidth);
    }, 0);

    return Math.max(10, longestWordLength - 150);
  };

  const leftMargin = calculateLeftMargin(processedData);

  return (
    <div className='graph-project-per-hour' style={{ width: '100%', height: Math.max(542, calculateChartHeight(processedData.length)), }}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          layout="vertical"
          data={processedData}
          barSize={13}
          margin={{
            top: 20,
            right: 42,
            left: leftMargin,
            bottom: 5
          }}
        >
          <CartesianGrid strokeDasharray="3 3" horizontal={false} vertical={true} />
          <XAxis
            type="number"
            domain={['auto', 'auto']}
            ticks={xAxisTicks}
          />
          <YAxis
            type="category"
            dataKey="name"
            tickMargin={10}
          />
          <Tooltip formatter={(value) => `${value} ${value <= 1 ? 'request' : 'requests'}`} />
          <ReferenceLine x={0} stroke="#7393B3" />
          <Bar dataKey="requestCount" name="Number of Requests" fill="#C7B6F6">
            <LabelList dataKey="requestCount" position="right" fill="#282828" formatter={(value) => `${value} ${value <= 1 ? 'request' : 'requests'}`} />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}

export default TimeOffRequestPerUserGraph;
