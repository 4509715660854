import React from "react";

const Tooltip = ({ content }) => {
  return (
    <div className="tooltip">
      {content.map((item, index) => (
        <div key={index}>
          <h2 className={item.headingClass}>{item.heading}</h2>
          <div className={item.contentClass}>{item.content}</div>
        </div>
      ))}
    </div>
  );
};

export default Tooltip;