import axios from "axios";
import "./ForgetPassword.css";
import Modal from "react-modal";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Logo from "../../images/logo/to-logo.svg";
import Tick from "../../images/icons/green-tick.svg";
import LoginImage from "../../images/login/lg-img.png";
import Spinner from "../../components/Animations/Spinner";
import { loginTemp } from "../../features/auth/authSlice";
import React, { useState, useRef, useEffect } from "react";
import LoginImageMobile from "../../images/login/lg-img-mob.png";

function ResetPass() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [isLoadingModal, setIsLoadingModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const { email, token } = useParams();
  const decodedEmail = decodeURIComponent(
    atob(email.replace(/\-/g, "+").replace(/_/g, "/"))
  );
  const [passwordMatchError, setPasswordMatchError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const passwordRef = useRef(null);
  const passwordConfirmationRef = useRef(null);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  useEffect(() => {
    const verifyResetPassLink = async () => {
      const { email, currentTime } = { email: decodedEmail, currentTime: new Date().toISOString() };
      try {
        const response = await axios.post("/api/user/check/reset-password/validity", { email, token, currentTime, statusCheck: false });

        if (response.status === 204) {
          // Reset link expired, redirect to forget-password page with alert message
          toast.error("Sorry!.Reset link expired", {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          navigate("/forget-password");
        }
      } catch (error) {
        console.error(error);
        global.config.slackMessage(error.toString());
        toast.error("Something went wrong while verifying the reset link");
      }
    };

    verifyResetPassLink();
  }, [decodedEmail,navigate,token]);

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setIsLoadingModal(true);
    if (password !== passwordConfirmation) {
      setPasswordMatchError("Passwords do not match");
      passwordConfirmationRef.current.focus();
      setIsLoadingModal(false);
      setLoading(false);
      return;
    }
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    if (!passwordRegex.test(password)) {
      setPasswordError("Invalid password format");
      toast.error(
        "Password must be at least 8 charachters long and include atleast one uppercase letter ,one lowercase letter,one digit and one special character from the set !@#$%^&*()_+,-/:;<=>?@_'[\]{|}~"
      );
      passwordRef.current.focus();
      setIsLoadingModal(false);
      setLoading(false);
      return;
    }
    const requestData = {
      email: decodedEmail,
      password: password,
    };

    const requestVerifyData = {
      email: decodedEmail,
      token: token,
      currentTime: new Date().toISOString(), // Get the current time
      statusCheck: true,
    };

    try {
      const verifyResponse = await axios.post(
        "/api/user/check/reset-password/validity",
        requestVerifyData
      );

      if (verifyResponse.status === 204) {
        // Reset link expired, redirect to forget-password page with alert message
        toast.error("Reset link expired", {
          position: "bottom-right",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        navigate("/forget-password");
        return;
      }
      const res = await axios.post("/api/user/reset-password", requestData);
      const data = res.data;

      if (data.status === "success") {
        toast.success("Your password is reset successfully", {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setShowSuccessMessage(true); // Show the success message
        setTimeout(async () => {
          const res = await fetch("/api/user/login", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ email: decodedEmail, password }),
          });
          const dataPassword = await res.json();
          if (dataPassword.token) {
            dispatch(loginTemp(dataPassword));
            navigate("/2fa");

          } else {

            toast.error(data.message, {
              position: "bottom-right",
              autoClose: 1500,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
        }, 3000);

      } else {
        toast.error("Something went wrong while sending password reset mail ", {
          position: "bottom-right",
          autoClose: 3500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        console.log(
          global.config.activityLog(
            window.location.href,
            "Reset Password",
            "Reset mail sending failed"
          )
        );
      }
      setIsLoadingModal(false);
      setLoading(false);
    } catch (err) {
      console.error(err.message);
      global.config.slackMessage(err.toString());
      toast.error("Error occurred", {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setLoading(false);
    }
  };

  return (
    <>
      <div className="login-outer-forget">
        <div className="login-inner">
          <div className="top-logo-mobile">
            <img src={Logo} alt="Talent On" />
          </div>
          <div className="lg-lft">
            <img src={LoginImage} alt="desktop" className="desktop-lg-img" />
            <img
              src={LoginImageMobile}
              alt="mobile"
              className="mobile-lg-img"
            />
          </div>
          <div className="lg-rgt">
            <div className="top-logo">
              <img src={Logo} alt="Talent On" />
            </div>
            <div className="login-form-sec">
              {showSuccessMessage ? (
                <div className="success-message-reset">
                  <div className="tick-icon-reset">
                    <img src={Tick} alt="Tick" />
                  </div>
                  <div className="success-for">
                    <h3>Password changed!</h3>
                    <p>Your password has been changed successfully.</p>
                  </div>

                </div>
              ) : (
                <form onSubmit={onSubmit}>
                  <h3 className="reset-title">Create a new password</h3>
                  <input
                    type="hidden"
                    placeholder="email"
                    value={decodedEmail}
                    readOnly
                  />
                  <div className="form-outer">
                    <label>Password *</label>
                    <input
                      type="password"
                      name="password"
                      id="password"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      ref={passwordRef}
                      required
                    />
                    {passwordError && (
                      <span className="text-red-500 text-xs italic">
                        {passwordError}
                      </span>
                    )}
                  </div>
                  <div className="form-outer">
                    <label>
                      Confirm Password *
                    </label>
                    <input
                      type="password"
                      placeholder="Confirm Password"
                      value={passwordConfirmation}
                      onChange={(e) => setPasswordConfirmation(e.target.value)}
                      ref={passwordConfirmationRef}
                      required
                    />
                    {passwordMatchError && (
                      <span className="text-red-500 text-xs italic">
                        {passwordMatchError}
                      </span>
                    )}
                  </div>
                  <div className="text-center mt-6">
                    <button
                      className="reset-pass"
                      type="submit"
                    >
                      Reset Password
                    </button>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>

      {loading && (
        <Modal
          isOpen={isLoadingModal}
          style={{
            content: {
              height: "20%",
              width: "17%",
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            },
          }}
        >
          <Spinner />
          Reseting Password
        </Modal>
      )}
    </>
  );
}

export default ResetPass;